import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
  Alternative,
  WorldCountry,
} from "@lysaab/ui-2";

export const DisallowedCountry = {
  UNITED_STATES_OF_AMERICA: WorldCountry.UNITED_STATES_OF_AMERICA,
};
export class UsaValidator extends BaseValidator {
  protected name = "UsaValidator";

  validate(
    alternative: Alternative<WorldCountry>
  ): Promise<ValidationResponse> {
    if (alternative) {
      if (alternative.value === DisallowedCountry.UNITED_STATES_OF_AMERICA) {
        return Promise.resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    }
    return Promise.resolve({ status: VALIDATION_STATE.SUCCESS });
  }
}
