import { Card, CardList } from "@lysaab/ui-2";
import React from "react";
import cx from "classnames";

import "./AccountPageCard.scss";

export const AccountPageCard = ({
  children,
  bottomLink,
  isCardList,
  className,
}: {
  children: React.ReactNode;
  bottomLink?: React.ReactNode;
  isCardList?: boolean;
  className?: string;
}) => {
  return (
    <div className={cx("account-page-card", className)}>
      {isCardList ? (
        <CardList
          className={cx({
            "account-page-card__flex": typeof bottomLink !== "undefined",
          })}
        >
          <div className="account-page-card__body">{children}</div>
          {bottomLink && (
            <div className="account-page-card__bottom">{bottomLink}</div>
          )}
        </CardList>
      ) : (
        <Card
          className={cx({
            "account-page-card__flex": typeof bottomLink !== "undefined",
          })}
        >
          <div className="account-page-card__body">{children}</div>
          {bottomLink && (
            <div className="account-page-card__bottom">{bottomLink}</div>
          )}
        </Card>
      )}
    </div>
  );
};
