import {
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { VoidFunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  dataLegalEntity,
  EntityContactDetails,
  EntityDetailsResponse,
} from "../../../data/dataLegalEntity";
import { defineMessages } from "react-intl";

export const alternativeMessages = defineMessages({
  commercial: {
    id: "settings.marketing.alternatives.commercial",
  },
  notifications: {
    id: "settings.marketing.alternatives.notifications",
  },
  alternativeYes: {
    id: "settings.marketing.yes",
  },
  alternativeNo: {
    id: "settings.marketing.no",
  },
});

interface Props {
  marketingSettings: EntityContactDetails;
  updateMarketingSettings: (details: EntityDetailsResponse) => void;
  error?: boolean;
}

export const Marketing: VoidFunctionComponent<Props> = ({
  marketingSettings,
  updateMarketingSettings,
  error,
}) => {
  const [commercialSelected, setCommercialSelected] = useState<boolean>();
  const [notificationSelected, setNotificationSelected] = useState<boolean>();
  const [postSettingsErrror, setPostSettingsErrror] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  const formRef = React.useRef<LysaFormRef>();

  const commercialAlts = [
    {
      text: intl.formatMessage(alternativeMessages.alternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(alternativeMessages.alternativeNo),
      value: false,
    },
  ];

  const notificationAlts = [
    {
      text: intl.formatMessage(alternativeMessages.alternativeYes),
      value: true,
    },
    {
      text: intl.formatMessage(alternativeMessages.alternativeNo),
      value: false,
    },
  ];

  useEffect(() => {
    if (marketingSettings) {
      setCommercialSelected(marketingSettings.commercialEmail);
      setNotificationSelected(marketingSettings.notificationEmail);
    }
  }, [marketingSettings]);

  if (error || postSettingsErrror) {
    return (
      <section>
        <h2>
          <TranslatedText id="settings.marketing.header" />
        </h2>
        <Card>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="settings.marketing.error" />
          </Snackbar>
        </Card>
      </section>
    );
  }

  const saveMarketingSettings = (
    commercialSelectedPreference: boolean,
    notificationSelectedPreference: boolean
  ) => {
    const settingsRequest = {
      commercialEmail: commercialSelectedPreference,
      notificationEmail: notificationSelectedPreference,
    };

    const hasSettingsChanged =
      commercialSelectedPreference !== marketingSettings.commercialEmail ||
      notificationSelectedPreference !== marketingSettings.notificationEmail;
    if (hasSettingsChanged) {
      setIsLoading(true);
      dataLegalEntity
        .postProfileSettings(settingsRequest)
        .then(updateMarketingSettings)
        .catch((error: ServerError<unknown>) => {
          setPostSettingsErrror(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <section>
      <h2>
        <TranslatedText id="settings.marketing.header" />
      </h2>
      <Card>
        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            if (
              formRef.current?.isValid &&
              commercialSelected !== undefined &&
              notificationSelected !== undefined
            ) {
              saveMarketingSettings(commercialSelected, notificationSelected);
              return;
            }
          }}
        >
          <RadioGroup
            header={intl.formatMessage(alternativeMessages.commercial)}
            alternatives={commercialAlts}
            value={commercialAlts.find(
              (alt) => alt.value === commercialSelected
            )}
            onChange={(event) => {
              setCommercialSelected(event.value);
            }}
          />
          <RadioGroup
            header={
              <>
                {intl.formatMessage(alternativeMessages.notifications)}
                <br />
                <p>
                  <FormattedMessage id="settings.marketing.notifications.info" />
                </p>
              </>
            }
            alternatives={notificationAlts}
            value={notificationAlts.find(
              (alt) => alt.value === notificationSelected
            )}
            onChange={(event) => {
              setNotificationSelected(event.value);
            }}
          />
          <p>
            <TranslatedText id="settings.marketing.information" />
          </p>
          <Button
            type="submit"
            label={<TranslatedText id="settings.marketing.save" />}
            block
          />
        </Form>
      </Card>
    </section>
  );
};
