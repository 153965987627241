import { Story } from "@lysaab/ui-2";
import React, { useEffect, useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, matchPath, generatePath, useHistory } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { KlarnaIntegrationClient } from "../../../utils/KlarnaIntegrationClient";
import { PageStripped } from "../../PageStripped";
import { KlarnaContextProvider } from "./KlarnaContext";
import { AccountSelection } from "./accountSelection/AccountSelection";
import { Done } from "./done/Done";
import { LysaAccountSelection } from "./lysaAccountSelection/LysaAccountSelection";
import { Route } from "../../../components/route/Route";
import { Switch } from "../../../components/route/Switch";
import { BankSelectionWrapper } from "./bankSelection/BankSelectionWrapper";
import { AccountLoadingWrapper } from "./accountLoading/AccountLoadingWrapper";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { PaymentInitiationWrapper } from "./paymentInitiation/PaymentInitiationWrapper";
import { OnCompleteData } from "../../../components/klarna/paymentInitiation/PaymentInititation";
import { DEPOSITS_OVERVIEW_URL } from "../overview/Recommendation";
import * as H from "history";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { useIsPerson } from "../../../hooks/useIsPerson";

export const KLARNA_DEPOSITS_URL = "/deposits/klarna";

export const BASE_ROUTES = {
  LYSA_ACCOUNT: `${KLARNA_DEPOSITS_URL}`,
  BANK_SELECTION: `${KLARNA_DEPOSITS_URL}/bank-selection`,
  ACCOUNT_LOADING: `${KLARNA_DEPOSITS_URL}/account-loading`,
  ACCOUNT_SELECTION: `${KLARNA_DEPOSITS_URL}/account-selection`,
  AMOUNT: `${KLARNA_DEPOSITS_URL}/amount`,
  PAYMENT_INITIALIZATION: `${KLARNA_DEPOSITS_URL}/payment`,
  DONE: `${KLARNA_DEPOSITS_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "deposits.klarna.story.header",
    defaultMessage: "Bank deposit",
  },
  ariaProgressLabel: {
    id: "deposits.klarna.story.ariaProgressLabel",
  },
});

type fallbackLocationDescriptorState = {
  accountId?: CompoundAccountId;
  amount?: number;
};

export type fallbackLocationDescriptorFunction = (
  state: fallbackLocationDescriptorState
) => H.LocationDescriptor<fallbackLocationDescriptorState>;

export interface Props {
  getFallbackDepositLocationDescriptor?: fallbackLocationDescriptorFunction;
}

export function KlarnaStory({ getFallbackDepositLocationDescriptor }: Props) {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [currentIndex, ROUTES, , storyLength] = useStoryValues(BASE_ROUTES);
  const isPerson = useIsPerson();
  const history = useHistory();

  useEffect(() => {
    if (!isPerson) {
      console.error("Company reached Klarna Story");
      history.replace(OVERVIEW_PAGE_URL);
    }
  }, [history, isPerson]);

  useEffect(() => {
    KlarnaIntegrationClient.preLoad();
  }, []);

  const PAGE_PROGRESS = 100 / Object.keys(ROUTES).length;

  const PROGRESS = {
    [ROUTES.LYSA_ACCOUNT]: PAGE_PROGRESS,
    [ROUTES.BANK_SELECTION]: PAGE_PROGRESS * 2,
    [ROUTES.ACCOUNT_LOADING]: PAGE_PROGRESS * 3,
    [ROUTES.ACCOUNT_SELECTION]: PAGE_PROGRESS * 4,
    [ROUTES.PAYMENT_INITIALIZATION]: PAGE_PROGRESS * 5,
    [ROUTES.DONE]: PAGE_PROGRESS * 6,
  };

  const onBack = (currentIndex: number) => {
    if (
      currentIndex >=
      Object.values(ROUTES).indexOf(ROUTES.PAYMENT_INITIALIZATION)
    ) {
      return;
    } else if (
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.LYSA_ACCOUNT)
    ) {
      safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
      return;
    } else if (
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.ACCOUNT_LOADING) ||
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.ACCOUNT_SELECTION)
    ) {
      safeNavigation(ROUTES.BANK_SELECTION);
      return;
    } else {
      safeNavigation(generatePath(Object.values(ROUTES)[currentIndex - 1]));
      return;
    }
  };

  const accountLoadingNext = useCallback(() => {
    safeNavigation(ROUTES.ACCOUNT_SELECTION);
  }, [ROUTES.ACCOUNT_SELECTION, safeNavigation]);

  const onComplete = useCallback(
    (data: OnCompleteData) => {
      safeNavigation(ROUTES.DONE);
    },
    [ROUTES.DONE, safeNavigation]
  );

  return (
    <PageStripped>
      <div className="klarna-deposits">
        <KlarnaContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={getProgress(PROGRESS, location.pathname)}
            showBack={currentIndex < 5}
            showClose={true}
            transitionKey={currentIndex.toString()}
            onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            onBack={() => onBack(currentIndex)}
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route
                path={ROUTES.LYSA_ACCOUNT}
                exact
                render={() => (
                  <LysaAccountSelection
                    next={() => safeNavigation(ROUTES.BANK_SELECTION)}
                  />
                )}
              />
              <Route
                path={ROUTES.BANK_SELECTION}
                exact
                render={() => (
                  <BankSelectionWrapper
                    getMissingBankLocation={
                      getFallbackDepositLocationDescriptor
                    }
                    next={() => safeNavigation(ROUTES.ACCOUNT_LOADING)}
                  />
                )}
              />
              <Route
                path={ROUTES.ACCOUNT_LOADING}
                exact
                render={() => (
                  <AccountLoadingWrapper
                    next={accountLoadingNext}
                    getErrorLoadingAccountsLocation={
                      getFallbackDepositLocationDescriptor
                    }
                  />
                )}
              />

              <Route
                path={ROUTES.ACCOUNT_SELECTION}
                exact
                render={() => (
                  <AccountSelection
                    next={() => safeNavigation(ROUTES.PAYMENT_INITIALIZATION)}
                    getFallbackDepositLocationDescriptor={
                      getFallbackDepositLocationDescriptor
                    }
                  />
                )}
              />
              <Route
                path={ROUTES.PAYMENT_INITIALIZATION}
                exact
                render={() => (
                  <PaymentInitiationWrapper
                    next={onComplete}
                    getPaymentInitiationFailedLocation={
                      getFallbackDepositLocationDescriptor
                    }
                  />
                )}
              />
              <Route path={ROUTES.DONE} exact render={() => <Done />} />
            </Switch>
          </Story>
        </KlarnaContextProvider>
      </div>
    </PageStripped>
  );
}

function getProgress(routes: { [key: string]: number }, route: string) {
  return (
    Object.entries(routes).find(([key]) => {
      return matchPath(route, {
        path: key,
        exact: true,
      });
    })?.[1] || 0
  );
}
