import { Story } from "@lysaab/ui-2";
import React, { useEffect } from "react";
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  matchPath,
  generatePath,
} from "react-router";
import { InvestmentAccountId } from "../../../../../../data/dataAccounts";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { HideNav } from "../../../../../../pages/HideNav";

import { Location } from "history";
import { Transaction } from "./Transaction";
import { KfWithdrawalContextProvider } from "./KfWithdrawalContext";
import { Confirm } from "./Confirm";
import { Signers } from "./Signers";
import { Sign } from "./Sign";
import { Done } from "./Done";
import { Ongoing } from "./Ongoing";
import { defineMessages, useIntl } from "react-intl";
import { From } from "./From";
import { To } from "./To";
import "./WithdrawalKfRequestPage.scss";
import { dataDanica } from "../../../../../../data/dataDanica";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";

export interface QueryParams {
  from: InvestmentAccountId;
  to: string;
}

export const WITHDRAWAL_REQUEST_KF_PAGE_URL = "/withdrawal/kf/request";

export const ROUTES = {
  FROM: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/`,
  TO: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/to`,
  TRANSACTION: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/transaction`,
  CONFIRM: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/confirm`,
  SIGNERS: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/signers/:signingId`,
  SIGN: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/sign/:signingId/:userId`,
  ONGOING: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/ongoing`,
  DONE: `${WITHDRAWAL_REQUEST_KF_PAGE_URL}/done`,
};

function getCurrentRouteIndex(location: Location) {
  return Object.values(ROUTES).findIndex((path) => {
    const match = matchPath(location.pathname, {
      path: getNavLink(path),
      exact: true,
    });
    return match !== null;
  });
}

const messages = defineMessages({
  title: {
    id: "withdrawalRequest.kf.story.header",
    description: "Withdrawal request kf - story header",
    defaultMessage: "Withdrawal",
  },
  ariaProgressLabel: {
    id: "withdrawalRequest.kf.story.ariaProgressLabel",
  },
});

export function WithdrawalKfRequestPageInner() {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const currentIndex = getCurrentRouteIndex(location);
  const storyLength = Object.values(ROUTES).length;
  let signersIndex;
  Object.keys(ROUTES).forEach((key, idx) => {
    if (key === "SIGNERS") {
      signersIndex = idx;
    }
  });

  useEffect(() => {
    dataDanica.getIsWithdrawalsClosed().then((activeResponse) => {
      if (activeResponse.closed) {
        history.replace(getNavLink(WITHDRAWAL_PAGE_URL));
      }
    });
  }, [history]);

  return (
    <Story
      ariaLabelProgress={() =>
        intl.formatMessage(messages.ariaProgressLabel, {
          current: currentIndex + 1,
          total: storyLength,
        })
      }
      header={intl.formatMessage(messages.title)}
      onExit={() => {
        const match = matchPath<{ signingId: string; userId: string }>(
          location.pathname,
          {
            path: getNavLink(ROUTES.SIGN),
            exact: true,
          }
        );
        if (match) {
          history.replace(
            generatePath(getNavLink(ROUTES.SIGNERS), {
              signingId: match.params.signingId,
            })
          );
        } else {
          history.push(getNavLink(WITHDRAWAL_PAGE_URL));
        }
      }}
      showClose={true}
      showBack={currentIndex > 0 && currentIndex < (signersIndex || 1)}
      onBack={history.goBack}
      transitionKey={currentIndex.toString()}
      progress={(100 / Object.keys(ROUTES).length) * (currentIndex + 1)}
    >
      <Switch {...{ order: currentIndex }} location={location}>
        <Route path={getNavLink(ROUTES.FROM)} exact>
          <From />
        </Route>

        <Route path={getNavLink(ROUTES.TO)}>
          <To />
        </Route>

        <Route path={getNavLink(ROUTES.TRANSACTION)}>
          <Transaction />
        </Route>

        <Route path={getNavLink(ROUTES.CONFIRM)}>
          <Confirm />
        </Route>

        <Route path={getNavLink(ROUTES.SIGNERS)}>
          <Signers />
        </Route>

        <Route path={getNavLink(ROUTES.SIGN)}>
          <Sign />
        </Route>

        <Route path={getNavLink(ROUTES.ONGOING)}>
          <Ongoing />
        </Route>

        <Route path={getNavLink(ROUTES.DONE)}>
          <Done />
        </Route>
      </Switch>
    </Story>
  );
}

export function WithdrawalKfRequestPage() {
  return (
    <div className="withdrawal-kf-request-page">
      <HideNav />
      <KfWithdrawalContextProvider>
        <WithdrawalKfRequestPageInner />
      </KfWithdrawalContextProvider>
    </div>
  );
}
