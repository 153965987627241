import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { UserContext } from "../../../../../../../context/UserContext";
import {
  Consent,
  dataAutogiro,
  getConsentBankText,
} from "../../../../../../../data/dataAutogiro";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../../hooks/useSafeNavigation";
import {
  AutogiroDepositContext,
  AutogiroDepositState,
} from "../AutogiroDepositContext";
import { BASE_ROUTES } from "../AutogiroDepositPage";
import { banks } from "../../../../../../../data/dataBanks";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../../pages/deposits/overview/Recommendation";
import {
  AddDepositAutogiroAccountLocationState,
  ADD_DEPOSIT_AUTOGIRO_URL,
} from "../../addAccount/AddDepositAccountAutogiroStory";
import { useIsPerson } from "../../../../../../../hooks/useIsPerson";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  externalLabel: {
    id: "sweden.deposits.autogiro.story.external-account-selection.external-account.label",
  },
  externalRequired: {
    id: "sweden.deposits.autogiro.story.external-account-selection.external-account.required",
  },
});

export interface AutogiroContextState {
  returnState?: AutogiroDepositState;
}

export function ExternalAccountSelection({ next }: Props) {
  const [consents, setConsents] = useState<Consent[]>([]);
  const [error, setError] = useState(false);
  const [accountAlternatives, setAccountAlternatives] = useState<
    Alternative<string>[]
  >([]);
  const autogiroDepositContext = useContext(AutogiroDepositContext);
  const userContext = useContext(UserContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const location = useLocation<AutogiroContextState>();
  const [loading, setLoading] = useState(false);
  const isPerson = useIsPerson();

  const isAutogiroDepositAvailableForBank =
    autogiroDepositContext.state.externalAccount?.bank !== banks.AVANZA.key &&
    autogiroDepositContext.state.externalAccount?.bank !== banks.NORDNET.key;

  useEffect(() => {
    setLoading(true);
    dataAutogiro
      .getConsents()
      .then((consents) => {
        setConsents(consents);
        setAccountAlternatives(
          consents.map((consent) => ({
            text: getConsentBankText(consent),
            value: consent.externalBankAccount,
          }))
        );
        if (location.state?.returnState) {
          autogiroDepositContext.setState(location.state.returnState);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    /**
     * Ignore the location prop. Only trigger this once
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText
          id={"sweden.deposits.autogiro.story.external-account-selection.error"}
        />
      </Snackbar>
    );
  }

  if (!consents || loading) {
    return <Spinner />;
  }

  return (
    <div className="autogiro-deposit-account-selection">
      <h2>
        <TranslatedText id="sweden.deposits.autogiro.story.external-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (userContext.state.readOnly) {
            safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
          }
          if (
            formRef.current?.isInvalid ||
            !autogiroDepositContext.state.externalAccount ||
            !isAutogiroDepositAvailableForBank
          ) {
            return;
          }
          next();
        }}
      >
        <Card>
          {accountAlternatives.length > 0 ? (
            <RadioGroup
              alternatives={accountAlternatives}
              value={
                typeof autogiroDepositContext.state.externalAccount !==
                "undefined"
                  ? accountAlternatives.find(
                      (alternative) =>
                        alternative.value ===
                        autogiroDepositContext.state.externalAccount
                          ?.externalBankAccount
                    )
                  : undefined
              }
              header={intl.formatMessage(messages.externalLabel)}
              onChange={(alt) =>
                autogiroDepositContext.setState({
                  externalAccount: consents.find(
                    (account) => account.externalBankAccount === alt.value
                  ),
                })
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.externalRequired)
                ),
              ]}
            />
          ) : (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="sweden.deposits.autogiro.story.external-account-selection.no-consents" />
            </Snackbar>
          )}
          <Link<AddDepositAutogiroAccountLocationState>
            to={
              isPerson
                ? {
                    pathname: getNavLink(BASE_ROUTES.BANK_SELECTION),
                    state: {
                      returnUrl: getNavLink(
                        BASE_ROUTES.EXTERNAL_ACCOUNT_SELECTION
                      ),
                    },
                  }
                : {
                    pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
                    state: {
                      returnUrl: getNavLink(
                        BASE_ROUTES.EXTERNAL_ACCOUNT_SELECTION
                      ),
                      returnState: autogiroDepositContext.state,
                    },
                  }
            }
          >
            <b>
              <TranslatedText id="sweden.deposits.autogiro.story.account-selection.add-consent" />
            </b>
          </Link>
        </Card>
        {!isAutogiroDepositAvailableForBank && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.deposits.autogiro.story.external-account-selection.bank-not-allowed" />
          </Snackbar>
        )}

        <Button
          type="submit"
          block
          label={
            userContext.state.readOnly ? (
              <TranslatedText id="sweden.deposits.autogiro.story.external-account-selection.read-only-button" />
            ) : (
              <TranslatedText id="sweden.deposits.autogiro.story.external-account-selection.button" />
            )
          }
        />
      </Form>
    </div>
  );
}
