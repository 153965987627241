import { Icon, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import * as H from "history";
import { matchPath, useHistory } from "react-router-dom";
import {
  InvestmentAccount,
  AccountType,
  dataAccounts,
} from "../../data/dataAccounts";
import { UserContext, UserState } from "../../context/UserContext";
import { getBrowser, getDevice, Browser, Device } from "./Device";
import "./SystemMessages.scss";

const MESSAGE_URL = process.env.REACT_APP_SYSTEM_STATUS_URL;

enum MessageEntityType {
  VISITOR = "visitor",
  PERSON = "person",
  CORPORATION = "corporation",
}

interface Message {
  id: number;
  updated?: number;
  paths?: string[];
  status: SNACKBAR_TYPES;
  enabled: boolean;
  message: string;
  disableClose: boolean;
  filter?: {
    accountTypes?: AccountType[];
    types?: MessageEntityType[];
    devices?: Device[];
    browsers?: Browser[];
  };
}

function onClick(
  e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  history: H.History
) {
  let source = e.target as HTMLAnchorElement;
  if (source.tagName.toLowerCase() !== "a") {
    return;
  }
  let href = source.getAttribute("href") || "";
  if (href.indexOf("://") > -1) {
    return;
  }
  if (href.indexOf("mailto:") > -1) {
    return;
  }
  if (source.getAttribute("target") === "_blank") {
    return;
  }
  e.preventDefault();
  let pathname = source.pathname;
  history.push(pathname);
}

function contains(location: Location, message: Message) {
  let { pathname } = location;

  pathname = decodeURIComponent(pathname);

  return message.paths?.some((path) => {
    const match = matchPath(pathname, {
      path,
      exact: false,
      strict: false,
    });

    return match !== null;
  });
}

function isPathActive(message: Message) {
  if (!message.paths) {
    return true;
  }

  // Empty paths prop implies all urls
  if (message.paths.length === 0) {
    return true;
  }

  if (contains(window.location, message)) {
    return true;
  }

  return false;
}

function isCustomerActive(message: Message, user: UserState) {
  if (!message?.filter?.types) {
    return true;
  }
  if (!user.tin) {
    return message.filter.types.indexOf(MessageEntityType.VISITOR) > -1;
  }

  return (
    message.filter.types.indexOf(
      user.legalEntityType?.toString().toLowerCase() as MessageEntityType
    ) > -1
  );
}

function hasAccountActive(
  message: Message,
  user: UserState,
  accounts: InvestmentAccount[]
) {
  const accountTypes = message?.filter?.accountTypes;

  if (!accountTypes || !user.tin) {
    return true;
  }

  return accounts.some((account) => accountTypes.indexOf(account.type) > -1);
}

function hasActivePlatform(message: Message, platform: Device) {
  const devices = message?.filter?.devices;

  return !devices || devices.indexOf(platform) > -1;
}

function hasActiveBrowser(message: Message, browser: Browser) {
  const browsers = message.filter?.browsers;

  return !browsers || browsers.indexOf(browser) > -1;
}

function isActive(
  message: Message,
  user: UserState,
  accounts: InvestmentAccount[],
  platform: Device,
  browser: Browser
) {
  if (!message.filter) {
    return isPathActive(message);
  }

  return (
    isPathActive(message) &&
    isCustomerActive(message, user) &&
    hasAccountActive(message, user, accounts) &&
    hasActivePlatform(message, platform) &&
    hasActiveBrowser(message, browser)
  );
}

export const SystemMessages = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const user = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    getSystemMessages().then((messages) => setMessages(messages));
  }, []);

  useEffect(() => {
    if (!user.state.name) {
      return;
    }
    dataAccounts.getAccounts().then((accounts) => setAccounts(accounts));
    return () => {
      setAccounts([]);
    };
  }, [user.state.name]);

  if (messages.length === 0) {
    return null;
  }

  const platform = getDevice();
  const browser = getBrowser();

  const remove = (message: Message) => {
    setMessages(messages.filter((msg) => msg.id !== message.id));
  };

  const messagesToDisplay = messages.filter((message) =>
    isActive(message, user.state, accounts, platform, browser)
  );

  if (!messagesToDisplay.length || messagesToDisplay.length === 0) {
    return null;
  }

  return (
    <div className="system-messages-wrapper">
      <div className="system-messages">
        <ul>
          {messagesToDisplay.map((message) => (
            <Snackbar type={message.status} icon={true} key={message.id}>
              <div className="system-message">
                <div className="system-message-content">
                  {typeof message.message === "string" ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: message.message }}
                      onClick={(e) => onClick(e, history)}
                    />
                  ) : (
                    message.message
                  )}
                </div>
                <button type="button" onClick={() => remove(message)}>
                  <Icon.Close color="black" size={14} />
                </button>
              </div>
            </Snackbar>
          ))}
        </ul>
      </div>
    </div>
  );
};

function getSystemMessages() {
  return new Promise<Message[]>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.addEventListener("load", () => {
      if (request.status >= 200 && request.status < 300) {
        const messages = JSON.parse(request.responseText) as Message[];
        resolve(messages.filter((message) => message.enabled));
      } else if (request.status >= 300) {
        reject();
      }
    });
    request.open("GET", MESSAGE_URL + `?version=${Date.now()}`);
    request.send();
  });
}
