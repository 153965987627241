import React from "react";
import { Amount } from "../../../../../../../components/amount/Amount";
import { BankLogo } from "../../../../../../../components/bankLogo/BankLogo";
import { dataPerformance } from "../../../../../../../data/dataPerformance";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { banks, dataBanks } from "../../../../../../../data/dataBanks";
import "../../../../../../../components/withdrawalItem/WithdrawalItem.scss";

interface Props {
  accountName: string;
  accountWorth: number;
  initiatorName?: string;
  externalBankAccount: string;
  bank: keyof typeof banks;
  amount?: string;
  date?: Date;
}

export const PartialWithdrawalItem: React.FunctionComponent<Props> = ({
  initiatorName,
  accountName,
  accountWorth,
  externalBankAccount,
  bank,
  amount = "0",
  date = new Date(),
}) => {
  const bankItem = dataBanks.getBank(bank);

  return (
    <div className="withdrawal-item-wrapper">
      {initiatorName && (
        <TranslatedText
          id="withdrawalRequest.kf.partialWithdrawalItem.initiator"
          defaultMessage="Initiated by:"
          values={{
            initiator: initiatorName,
          }}
        />
      )}
      <div className="withdrawal-item">
        <div className="withdrawal-item-partial-name">
          <b>{accountName}</b> -&nbsp;
          <Amount amount={accountWorth} decimals={2} />
        </div>

        <div className="amount-tag">
          <div>
            <Amount amount={parseFloat(amount || "0")} decimals={2} />
          </div>
        </div>
        <div className="arrow-wrapper">
          <span>
            <TranslatedText
              id="generic.from"
              description="Withdrawal item - from"
              defaultMessage="From"
            />
          </span>
          <div className="transaction-arrow">
            <div className="arrow-start" />
            <div className="arrow-body" />
            <svg
              className="arrow-top"
              height="20px"
              width="20px"
              viewBox="0 0 20 20"
            >
              <path d="M10 5 L20 10 L10 15 Z" />
            </svg>
          </div>
          <span>
            <TranslatedText
              id="generic.to"
              description="Withdrawal item - to"
              defaultMessage="To"
            />
          </span>
        </div>
        <div className="withdrawal-item-to">
          <div>{dataPerformance.toStringDate(date)}</div>
          <div className="withdrawal-item-to__account-and-logo">
            {externalBankAccount}, <b>{bankItem.short}</b>
            <BankLogo bank={bankItem} />
          </div>
        </div>
      </div>
    </div>
  );
};
