import React, {
  useCallback,
  useContext,
  useState,
  VoidFunctionComponent,
} from "react";
import { EditAllocation } from "../../../../pageComponents/editAllocation/EditAllocation";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/countries";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../../ReviewAccountContext";

const defaultInvestment: Record<LysaCountry, number> = {
  [LysaCountry.SWEDEN]: 10000,
  [LysaCountry.DENMARK]: 10000,
  [LysaCountry.FINLAND]: 1000,
  [LysaCountry.GERMANY]: 1000,
  [LysaCountry.SPAIN]: 1000,
};

interface Props {
  next: () => void;
  nextRiskWarning: () => void;
}

export const EdditAllocationWrapper: VoidFunctionComponent<Props> = ({
  next,
  nextRiskWarning,
}) => {
  const [reviewAccountState, setReviewAccountState] = useReviewAccountContext();
  const localizationContext = useContext(LocalizationContext);
  const [allocationInvestment, setAllocationInvestment] = useState<number>(
    defaultInvestment[localizationContext.state.country || LysaCountry.SWEDEN]
  );
  const [risk, setRisk] = useState(reviewAccountState?.reviewAccount?.risk);

  const onNext = useCallback(() => {
    if (typeof reviewAccountState.reviewAccount === "undefined") {
      throw new Error("onNext - Missing reviewAccount in reviewAccountState");
    }

    if (typeof risk === "undefined") {
      throw new Error("onNext - Missing risk");
    }

    let action: AccountUpdateAction;

    if (risk === reviewAccountState.reviewAccount?.newAdvice.advice) {
      action = AccountUpdateAction.ADVICE;
    } else if (risk === reviewAccountState.reviewAccount?.newAdvice.takenRisk) {
      action = AccountUpdateAction.KEEP;
    } else {
      action = AccountUpdateAction.CUSTOM;
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        action,
        risk,
      },
    });
    next();
  }, [reviewAccountState.reviewAccount, risk, next, setReviewAccountState]);

  const onNextRiskWarning = useCallback(() => {
    if (!reviewAccountState.reviewAccount) {
      throw new Error(
        "onNextRiskWarning - Missing reviewAccount in reviewAccountState"
      );
    }

    if (typeof risk === "undefined") {
      throw new Error(
        "onNextRiskWarning - Missing risk in EdditAllocationWrapper"
      );
    }

    let action: AccountUpdateAction;

    if (risk === reviewAccountState.reviewAccount?.newAdvice.advice) {
      action = AccountUpdateAction.ADVICE;
    } else if (risk === reviewAccountState.reviewAccount?.newAdvice.takenRisk) {
      action = AccountUpdateAction.KEEP;
    } else {
      action = AccountUpdateAction.CUSTOM;
    }

    setReviewAccountState({
      reviewAccount: {
        ...reviewAccountState.reviewAccount,
        action,
        risk,
      },
    });
    nextRiskWarning();
  }, [
    risk,
    reviewAccountState.reviewAccount,
    setReviewAccountState,
    nextRiskWarning,
  ]);

  if (!reviewAccountState.reviewAccount?.newAdvice.investmentType) {
    throw new Error("EdditAllocationWrapper - missing investmentType");
  }

  return (
    <EditAllocation
      advisedRisk={reviewAccountState.reviewAccount.newAdvice.advice}
      savingsHorizon={reviewAccountState.reviewAccount.newAdvice.savingsHorizon}
      allocationSelectedRisk={risk}
      setAllocationSelectedRisk={setRisk}
      allocationInvestment={parseInt(
        Math.round(allocationInvestment).toFixed(),
        10
      )}
      setAllocationInvestment={setAllocationInvestment}
      investmentType={reviewAccountState.reviewAccount.newAdvice.investmentType}
      next={onNext}
      nextRiskWarning={onNextRiskWarning}
    />
  );
};
