import {
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2/hooks/useValidation";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";

const INVALID_CHARS = "<>'\"+\\%";

export class InvalidCharsValidator extends BaseValidator {
  name = "InvalidCharsValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (!value.split("").every((chr) => INVALID_CHARS.indexOf(chr) === -1)) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: `${this.error} (${INVALID_CHARS.split("").join(" ")})`,
        });
        return;
      }
      resolve({ status: VALIDATION_STATE.SUCCESS });
    });
  }
}
