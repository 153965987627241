import React, { VoidFunctionComponent } from "react";
import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { COMPANY_SETTINGS_PAGE_URL } from "../../companySettings/CompanySettingsPage";
import "./UserManagementDone.scss";

interface Props {
  home: () => void;
}

export const UserManagementDone: VoidFunctionComponent<Props> = ({ home }) => {
  const history = useHistory();
  return (
    <div className="user-management-done">
      <h3>
        <FormattedMessage id="sweden.userManagement.done.title" />
      </h3>
      <p>
        <FormattedMessage id="sweden.userManagement.done.text" />
      </p>
      <Button
        block
        onClick={() => history.push(getNavLink(COMPANY_SETTINGS_PAGE_URL))}
        label={<FormattedMessage id="sweden.userManagement.done.button.home" />}
      />

      <Button
        variant="secondary"
        block
        onClick={home}
        label={
          <FormattedMessage id="sweden.userManagement.done.button.start" />
        }
      />
    </div>
  );
};
