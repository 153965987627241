import React, { VoidFunctionComponent } from "react";
import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../hooks/useStoryValues";
import {
  WithAccountId,
  FallbackTransferState,
} from "../../../../../pages/deposits/overview/Recommendation";
import { PageStripped } from "../../../../../pages/PageStripped";
import { SwishDepositAccountSelection } from "./accountSelection/SwishDepositAccountSelection";
import { SwishDepositDone } from "./done/SwishDepositDone";
import { Error, ErrorLocationState } from "./error/Error";
import { SwishDepositQR } from "./qr/SwishDepositQR";
import { SwishDepositContextProvider } from "./SwishDepositContext";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";

export const SWISH_DEPOSIT_PAGE_URL = "/deposits/swish";

export const BASE_ROUTES = {
  ACCOUNT_SELECTION: `${SWISH_DEPOSIT_PAGE_URL}`,
  ERROR: `${SWISH_DEPOSIT_PAGE_URL}/error`,
  QR: `${SWISH_DEPOSIT_PAGE_URL}/qr`,
  DONE: `${SWISH_DEPOSIT_PAGE_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "sweden.deposits.swish.story.header",
  },
  ariaProgressLabel: {
    id: "sweden.deposits.swish.story.ariaProgressLabel",
  },
});

interface Props {
  fallbackUrl: string;
}

export const SwishDepositPage: VoidFunctionComponent<Props> = ({
  fallbackUrl,
}) => {
  const location = useLocation<WithAccountId | undefined>();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const history = useHistory();

  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  return (
    <PageStripped>
      <SwishDepositContextProvider accountId={location.state?.accountId}>
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          showBack={
            currentIndex === 0 ||
            currentIndex === Object.values(ROUTES).indexOf(ROUTES.ERROR)
          }
          onBack={history.goBack}
          showClose={true}
          onExit={() => {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }}
          transitionKey={currentIndex.toString()}
          progress={storyProgress}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.ACCOUNT_SELECTION} exact>
              <SwishDepositAccountSelection
                next={() => {
                  safeNavigation(ROUTES.QR);
                }}
              />
            </Route>

            <Route path={ROUTES.ERROR} exact>
              <Error
                tryAgain={() => {
                  safeNavigation(ROUTES.QR);
                }}
                changeAmount={() => {
                  safeNavigation(ROUTES.ACCOUNT_SELECTION);
                }}
                fallbackMethod={(amount, accountId) => {
                  safeNavigation<FallbackTransferState>({
                    pathname: fallbackUrl,
                    state: { amount, accountId },
                  });
                }}
              />
            </Route>

            <Route path={ROUTES.QR} exact>
              <SwishDepositQR
                next={() => {
                  safeNavigation(ROUTES.DONE);
                }}
                onError={(error) => {
                  safeNavigation<ErrorLocationState>({
                    pathname: ROUTES.ERROR,
                    state: { error },
                  });
                }}
              />
            </Route>
            <Route path={ROUTES.DONE} exact>
              <SwishDepositDone />
            </Route>
          </Switch>
        </Story>
      </SwishDepositContextProvider>
    </PageStripped>
  );
};
