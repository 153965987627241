import { Story } from "@lysaab/ui-2";
import React, { useCallback, VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Route, Switch, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { PageStripped } from "../../PageStripped";
import { AmountPeriodConfiguration } from "./amountPeriodConfiguration/AmountPeriodConfiguration";
import { ExternalAccounts } from "./externalAccounts/ExternalAccounts";
import { LysaAccounts } from "./lysaAccounts/LysaAccounts";
import { ConfirmPeriodicWithdrawal } from "./confirmPeriodicWithdrawal/ConfirmPeriodicWithdrawal";
import { WithdrawalPeriodicContextProvider } from "./WithdrawalPeriodicContext";
import { DonePeriodicWithdrawal } from "./donePeriodicWithdrawal/DonePeriodicWithdrawal";
import { WITHDRAWAL_PAGE_URL } from "../overview/WithdrawalPage";

const messages = defineMessages({
  header: {
    id: "withdrawalPeriodic.header",
  },
  ariaProgressLabel: {
    id: "withdrawalPeriodic.ariaProgressLabel",
  },
});

export const WITHDRAWALS_PERIODIC_STORY_URL = "/withdrawal/periodic";

export const BASE_ROUTES = {
  FROM_ACCOUNT: WITHDRAWALS_PERIODIC_STORY_URL,
  TO_ACCOUNT: `${WITHDRAWALS_PERIODIC_STORY_URL}/to-account`,
  AMOUNT_PERIOD_CONFIGURATION: `${WITHDRAWALS_PERIODIC_STORY_URL}/amount-and-period-configuration`,
  CONFIRM: `${WITHDRAWALS_PERIODIC_STORY_URL}/confirm`,
  DONE: `${WITHDRAWALS_PERIODIC_STORY_URL}/done`,
};

interface Props {
  addWithdrawalAccountUrl?: string;
}

export const WithdrawalPeriodicStory: VoidFunctionComponent<Props> = ({
  addWithdrawalAccountUrl,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const next = useCallback(
    (path: string) => () => safeNavigation(path),
    [safeNavigation]
  );

  return (
    <PageStripped>
      <div className="withdrawal-periodic-story">
        <WithdrawalPeriodicContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={storyProgress}
            showBack={
              currentIndex > 0 &&
              currentIndex < Object.values(ROUTES).length - 1
            }
            showClose={true}
            transitionKey={currentIndex.toString()}
            onExit={() => {
              safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
            }}
            onBack={() => {
              safeNavigation(Object.values(ROUTES)[currentIndex - 1]);
            }}
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route
                path={ROUTES.FROM_ACCOUNT}
                exact
                render={() => <LysaAccounts next={next(ROUTES.TO_ACCOUNT)} />}
              />
              <Route
                path={ROUTES.TO_ACCOUNT}
                exact
                render={() => (
                  <ExternalAccounts
                    next={next(ROUTES.AMOUNT_PERIOD_CONFIGURATION)}
                    addWithdrawalAccountUrl={addWithdrawalAccountUrl}
                  />
                )}
              />
              <Route
                path={ROUTES.AMOUNT_PERIOD_CONFIGURATION}
                exact
                render={() => (
                  <AmountPeriodConfiguration next={next(ROUTES.CONFIRM)} />
                )}
              />
              <Route
                path={ROUTES.CONFIRM}
                exact
                render={() => (
                  <ConfirmPeriodicWithdrawal next={next(ROUTES.DONE)} />
                )}
              />
              <Route
                path={ROUTES.DONE}
                exact
                render={() => <DonePeriodicWithdrawal />}
              />
            </Switch>
          </Story>
        </WithdrawalPeriodicContextProvider>
      </div>
    </PageStripped>
  );
};
