import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { InvestmentAccount, dataAccounts } from "../data/dataAccounts";
import {
  dataMonthlyPayments,
  MonthlyPayment,
} from "../data/dataMonthlyPayments";
import { Dimension, Metric, Tracker } from "../utils/Tracker";

export function useTrackingCategories() {
  const userContext = useContext(UserContext);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();
  const [monthlyPayments, setMonthlyPayments] = useState<MonthlyPayment[]>();

  useEffect(() => {
    if (!userContext.state.tin) {
      return;
    }
    setAccounts(undefined);
    setMonthlyPayments(undefined);

    dataAccounts
      .getAccounts()
      .then(setAccounts)
      .catch((error) => {});
    dataMonthlyPayments
      .getMonthlyPayments()
      .then(setMonthlyPayments)
      .catch((error) => {});
  }, [userContext.state.tin]);

  useEffect(() => {
    if (
      !userContext.state.country ||
      !userContext.state.legalEntityType ||
      !userContext.state.created
    ) {
      return;
    }
    Tracker.setDimension({
      dimension: Dimension.COUNTRY,
      value: userContext.state.country,
    });
    Tracker.setDimension({
      dimension: Dimension.CUSTOMER_TYPE,
      value: userContext.state.legalEntityType,
    });
    Tracker.setMetric({
      metric: Metric.REGISTERED,
      value: userContext.state.created,
    });
  }, [
    userContext.state.country,
    userContext.state.created,
    userContext.state.legalEntityType,
  ]);

  useEffect(() => {
    if (!accounts) {
      return;
    }
    Tracker.setDimension({
      dimension: Dimension.INVESTED,
      value: accounts.reduce((acc, curr) => (acc += curr.worth), 0) > 0,
    });
    Tracker.setMetric({
      metric: Metric.ASSETS,
      value: accounts.reduce((acc, curr) => (acc += curr.worth), 0),
    });
    Tracker.setMetric({
      metric: Metric.NUMBER_OF_ACCOUNTS,
      value: accounts.length,
    });
  }, [accounts]);

  useEffect(() => {
    if (!monthlyPayments) {
      return;
    }
    Tracker.setDimension({
      dimension: Dimension.MONTHLY_SAVINGS,
      value: monthlyPayments.length > 0,
    });
  }, [monthlyPayments]);
}
