import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  CashTransaction,
  CashTransactionType,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

const messages = defineMessages({
  insuranceFee: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.feeTransactionRow.insuranceFee",
  },
  managementFee: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.feeTransactionRow.managementFee",
  },
  savingsAccountFee: {
    id: "historicTransactionsPage.historicTransactions.transactionCard.feeTransactionRow.savingsAccountFee",
  },
});

export const FeeTransactionRows = ({
  transaction,
}: {
  transaction: CashTransaction;
}) => {
  const intl = useIntl();
  if (transaction.type !== CashTransactionType.FEE) return null;
  return (
    <div className="transaction-card-row ">
      <dt>
        <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.taxType" />
      </dt>
      <dd>
        {transaction.depositChannel === "DANICA-FEE"
          ? intl.formatMessage(messages.insuranceFee)
          : transaction.depositChannel === "SAVINGS_ACCOUNT_FEE"
          ? intl.formatMessage(messages.savingsAccountFee)
          : intl.formatMessage(messages.managementFee)}
      </dd>
    </div>
  );
};
