import React, { MutableRefObject } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../components/TranslatedText";
import { Button, InvestmentType } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import {
  EditAccounrRiskParams,
  EditAccountActionType,
} from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";

interface Props {
  values: MutableRefObject<EditAccounrRiskParams | undefined>;
  exitRoute: string;
}

const messages = defineMessages({
  broad: {
    id: "update-account.investmentType.broad",
  },
  sustainable: {
    id: "update-account.investmentType.sustainable",
  },
});

export const Done: React.FunctionComponent<Props> = ({ values, exitRoute }) => {
  const intl = useIntl();

  if (!values.current) {
    return null;
  }

  const {
    takenRisk,
    investmentType,
    accountName,
    updateType: actionType,
  } = values.current;
  const investmentTypeText =
    investmentType === InvestmentType.BROAD
      ? intl.formatMessage(messages.broad)
      : intl.formatMessage(messages.sustainable);

  return (
    <div className="update-account-page-done">
      <h2>
        <TranslatedText id="updateAccountPage.done.header" />
      </h2>
      <p>
        {actionType === EditAccountActionType.ALLOCATION ? (
          <TranslatedText
            id="updateAccountPage.done.allocation.text"
            values={{
              stocks: parseInt(takenRisk, 10),
              bonds: 100 - parseInt(takenRisk, 10),
              accountName,
            }}
          />
        ) : (
          <TranslatedText
            id="updateAccountPage.done.investmentType.text"
            values={{
              investmentType: investmentTypeText,
              accountName,
            }}
          />
        )}
      </p>
      <Button
        component={Link}
        to={exitRoute}
        block
        label={<TranslatedText id="updateAccountPage.done.back" />}
      />
    </div>
  );
};
