import { useCallback, useEffect, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { dataAccounts, InvestmentAccountId } from "../../../data/dataAccounts";
import { FormattedMessage } from "react-intl";
import { dataInvestments } from "../../../data/dataInvestments";
import { Probability } from "../../../pageComponents/horizon/questions/Probability";
import { Length } from "../../../pageComponents/horizon/questions/Length";
import { useAccountContext } from "../context/AccountContext";
import { useAccountSituationContext } from "../context/AccountSituationContext";

interface Props {
  accountId: InvestmentAccountId;
  next: () => void;
}

export const AccountHorizon: VoidFunctionComponent<Props> = ({
  accountId,
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const [accountState, setAccountState] = useAccountContext();
  const [, setAccountSituationState] = useAccountSituationContext();

  useEffect(() => {
    if (accountState.account) {
      return;
    }

    Promise.all([
      dataAccounts.getAccounts(),
      dataInvestments.getAdviseAccounts(),
    ]).then(([accounts, adviseAccounts]) => {
      const adviseAccount = adviseAccounts.find(
        (acc) => acc.accountId === accountId
      );

      const investmentAccount = accounts.find(
        (acc) => acc.accountId === accountId
      );

      if (
        typeof adviseAccount !== "undefined" &&
        typeof investmentAccount !== "undefined"
      ) {
        setAccountState({
          account: {
            ...adviseAccount,
            ...investmentAccount,
          },
        });
      }
    });
  }, [accountState.account, accountId, setAccountState]);

  const onSubmit = useCallback(() => {
    if (formRef.current?.isValid) {
      setAccountSituationState({ directAccessGuard: true });
      next();
    }
  }, [next, setAccountSituationState]);

  if (!accountState.account) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h3>
        <FormattedMessage id="accountHorizon.title" />
      </h3>
      <Length
        savingsHorizonLength={accountState.account.savingsHorizon}
        setSavingsHorizonLength={({ value }) => {
          if (!accountState.account) {
            return;
          }

          setAccountState({
            account: {
              ...accountState.account,
              savingsHorizon: value,
            },
          });
        }}
      />
      <Probability
        needEarlierProbability={accountState.account.needEarlier}
        setNeedEarlierProbability={({ value }) => {
          if (!accountState.account) {
            return;
          }

          setAccountState({
            account: {
              ...accountState.account,
              needEarlier: value,
            },
          });
        }}
      />
      <Button
        block
        type="submit"
        label={<FormattedMessage id="accountHorizon.button.next" />}
      />
    </Form>
  );
};
