import { useEffect, useState } from "react";
import { InvestmentAccountId } from "../data/dataAccounts";
import { AdviseAccount, dataInvestments } from "../data/dataInvestments";

export function useAccountAllocation(accountId?: InvestmentAccountId) {
  const [accountAdvice, setAccountAdvice] = useState<AdviseAccount>();

  useEffect(() => {
    if (typeof accountId === "undefined") {
      return;
    }
    dataInvestments.getAdviseAccounts().then((response) => {
      setAccountAdvice(
        response.find((allocation) => {
          return allocation.accountId === accountId;
        })
      );
    });
  }, [accountId]);

  return accountAdvice;
}
