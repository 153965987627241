import {
  Button,
  CardList,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AnimateHeight from "react-animate-height";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { CardListActionItem } from "../../../components/cardListItemWithAction/CardListActionItem";
import { CopyButton } from "../../../components/copyButton/CopyButton";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  Currency,
  LocalizationContext,
} from "../../../context/LocalizationContext";
import {
  InvestmentAccount as LysaAccount,
  InvestmentAccountId,
  dataAccounts,
} from "../../../data/dataAccounts";
import {
  dataDepositAccount,
  DepositAccount,
} from "../../../data/dataDepositAccount";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { MONTHLY_DEPOSITS_URL } from "../monthly/create/MonthlyStory";
import { DEPOSITS_OVERVIEW_URL } from "../overview/Recommendation";
import "./VirtualAccountInformationPage.scss";

interface Match {
  accountId: InvestmentAccountId;
}

const messages = defineMessages({
  informationText: {
    id: "deposits.virtual-account.account.text",
  },
  bankCodeLabel: {
    id: "deposits.virtual-account.account.bankCode",
  },
  accountNumberLabel: {
    id: "deposits.virtual-account.account.accno",
  },
  receiverLabel: {
    id: "deposits.virtual-account.account.receiver",
  },
  addressLabel: {
    id: "deposits.virtual-account.account.address",
  },
  bicLabel: {
    id: "deposits.virtual-account.account.bic",
  },
});

const RECEIVER = "Lysa AB";
const ADDRESS = "Löjtnantsgatan 21, 115 50 Stockholm";
const BIC = "DABASESX";

export const VirtualAccountInformationPage: React.VFC = () => {
  const match = useRouteMatch<Match>();
  const [depositAccount, setDepositAccount] = useState<DepositAccount>();
  const [lysaAccounts, setLysaAccounts] = useState<LysaAccount[]>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  const showSEPATransferDetails = useMemo(
    () => localizationContext.state.currency === Currency.EUR,
    [localizationContext.state.currency]
  );

  const accountName = useMemo(
    () =>
      lysaAccounts?.find((acc) => acc.accountId === depositAccount?.accountId)
        ?.name ?? "-",
    [depositAccount?.accountId, lysaAccounts]
  );

  useEffect(() => {
    setError(false);
    dataDepositAccount
      .getDepositAccount(match.params.accountId)
      .then((response) => setDepositAccount(response))
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [match.params.accountId]);

  useEffect(() => {
    dataAccounts.getAccounts().then((accounts) => setLysaAccounts(accounts));
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="virtual-account-information-page">
      <h2>
        <FormattedMessage
          id="deposits.virtual-account.account.header"
          defaultMessage="Deposit account"
        />
      </h2>
      <div className="info-texts">
        <p>
          <FormattedMessage
            id="deposits.virtual-account.account.text"
            values={{
              accountName,
              bold: (...parts: string[]) => <b>{parts}</b>,
            }}
          />
        </p>
        <p>
          <FormattedMessage id="deposits.virtual-account.account.text.bank-days" />
        </p>
      </div>
      <CardList>
        {depositAccount?.bankCode && (
          <CardListActionItem
            label={intl.formatMessage(messages.bankCodeLabel)}
            text={depositAccount.bankCode}
            action={<CopyButton text={depositAccount.bankCode} />}
          />
        )}
        {depositAccount?.accountNumber && (
          <CardListActionItem
            label={intl.formatMessage(messages.accountNumberLabel)}
            text={depositAccount.accountNumber}
            action={<CopyButton text={depositAccount.accountNumber} />}
          />
        )}
      </CardList>
      <AnimateHeight height={showAdditionalInfo ? "auto" : 0} animateOpacity>
        <p>
          <FormattedMessage id="deposits.virtual-account.account.additionalText" />
        </p>
        <CardList>
          <CardListActionItem
            label={intl.formatMessage(messages.receiverLabel)}
            text={RECEIVER}
            action={<CopyButton text={RECEIVER} />}
          />
          <CardListActionItem
            label={intl.formatMessage(messages.addressLabel)}
            text={ADDRESS}
            action={<CopyButton text={ADDRESS} />}
          />
          <CardListActionItem
            label={intl.formatMessage(messages.bicLabel)}
            text={BIC}
            action={<CopyButton text={BIC} />}
          />
        </CardList>
      </AnimateHeight>
      {showSEPATransferDetails && (
        <AnimateHeight height={!showAdditionalInfo ? "auto" : 0} animateOpacity>
          <div className="more-button-container">
            <Button
              variant="secondary"
              size="small"
              onClick={() => setShowAdditionalInfo(true)}
              className="more-button"
              label={
                <FormattedMessage id="deposits.virtual-account.account.more" />
              }
            />
          </div>
        </AnimateHeight>
      )}
      {error && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <FormattedMessage
              id="deposits.virtual-account.account.error"
              defaultMessage="An unexpected error occured. Please try again later"
            />
          </div>
        </Snackbar>
      )}

      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <TranslatedText id="deposits.virtual-account.account.back-button" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={
          <TranslatedText id="deposits.virtual-account.account.back-button-deposit" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={
          <TranslatedText id="deposits.virtual-account.account.back-button-monthly" />
        }
      />
    </div>
  );
};
