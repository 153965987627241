import React, { useReducer, createContext, FunctionComponent } from "react";
import { CompoundAccountId } from "../../../../../data/dataAccounts";
import type { InitiateSwishSuccessResponse } from "../../../../../data/dataSwish";
import { WithAccountId } from "../../../../../pages/deposits/overview/Recommendation";

export interface SwishDepositState {
  accountId?: CompoundAccountId;
  amount?: number;
  swishSuccessResponse?: InitiateSwishSuccessResponse;
}

export interface SwishDepositContextProps {
  state: SwishDepositState;
  setState: (newState: Partial<SwishDepositState>) => void;
}

export const SwishDepositContext = createContext<SwishDepositContextProps>(
  {} as SwishDepositContextProps
);

function stateReducer(
  state: SwishDepositState,
  newState: Partial<SwishDepositState>
) {
  return { ...state, ...newState };
}

export const SwishDepositContextProvider: FunctionComponent<WithAccountId> = ({
  children,
  accountId,
}) => {
  const [state, setState] = useReducer(stateReducer, { accountId: accountId });

  return (
    <SwishDepositContext.Provider value={{ state, setState }}>
      {children}
    </SwishDepositContext.Provider>
  );
};
