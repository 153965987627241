import React from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { Amount } from "../../../components/amount/Amount";
import { getFundName } from "../../../components/fundName/FundName";
import { TranslatedText } from "../../../components/TranslatedText";
import { ContractNoteTrade } from "../../../data/dataInvoice";
import { LysaTable } from "../../../components/lysaTable/LysaTable";
import { transactionTranslations } from "../../historicTransactions/historicTransactions/TransactionTranslations";
import cx from "classnames";

import "./TradeTable.scss";

export const TradeTable = ({
  trades,
  className,
}: {
  trades: ContractNoteTrade[];
  className?: string;
}) => {
  const intl = useIntl();
  return (
    <LysaTable className={cx("trade-table", className)} zebraStriped={false}>
      <thead>
        <tr>
          <th className="trade-table--text-left">
            <TranslatedText id="invoicePage.headerFund" />
          </th>
          <th className="trade-table--text-left">
            <TranslatedText id="invoicePage.tradeTable.headerType" />
          </th>
          <th className="trade-table--text-left">
            <TranslatedText id="invoicePage.headerIsin" />
          </th>
          <th>
            <TranslatedText id="invoicePage.headerVolume" />
          </th>
          <th>
            <TranslatedText id="invoicePage.headerNav" />
          </th>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade) => {
          return (
            <tr key={trade.isin + trade.type}>
              <td data-label="FOND" className="trade-table--text-left">
                {getFundName(trade.isin, intl)}
              </td>
              <td data-label="AFFÄRSTYP" className="trade-table--text-left">
                {intl.formatMessage(transactionTranslations[trade.type])}
              </td>
              <td data-label="ISIN" className="trade-table--text-left">
                {trade.isin}
              </td>
              <td data-label="ANDELAR">
                <FormattedNumber
                  value={trade.volume}
                  minimumFractionDigits={4}
                  maximumFractionDigits={4}
                />
              </td>
              <td data-label="NAVKURS">
                <Amount amount={trade.price} decimals={2} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </LysaTable>
  );
};
