import { LysaCountry } from "@lysaab/countries";
import React, { useCallback, useContext } from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { BankSelection } from "../../../../components/klarna/bankSelection/BankSelection";
import { TranslatedText } from "../../../../components/TranslatedText";
import { useCountry } from "../../../../context/LocalizationContext";
import { AvailableBank } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { KlarnaContext } from "../KlarnaContext";
import {
  BASE_ROUTES,
  fallbackLocationDescriptorFunction,
} from "../KlarnaStory";

interface Props {
  next: () => void;
  getMissingBankLocation?: fallbackLocationDescriptorFunction;
}

interface Message {
  id: string;
}

const KlarnaIntroText = defineMessages<
  LysaCountry,
  Message,
  Record<LysaCountry, Message>
>({
  [LysaCountry.SWEDEN]: {
    id: "sweden.klarna.bank-selection-single-deposit.intro",
  },
  [LysaCountry.DENMARK]: {
    id: "klarna.bank-selection-single-deposit.intro",
  },
  [LysaCountry.FINLAND]: {
    id: "klarna.bank-selection-single-deposit.intro",
  },
  [LysaCountry.GERMANY]: {
    id: "klarna.bank-selection-single-deposit.intro",
  },
  [LysaCountry.SPAIN]: {
    id: "klarna.bank-selection-single-deposit.intro",
  },
});

export function BankSelectionWrapper({ next, getMissingBankLocation }: Props) {
  const country = useCountry();
  const intl = useIntl();
  const klarnaContext = useContext(KlarnaContext);
  const setState = klarnaContext.setState;
  const history = useHistory();

  const onComplete = useCallback(
    (bank: AvailableBank) => {
      setState({ bank });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  useEffect(() => {
    if (!klarnaContext.state.selectedLysaAccount) {
      return history.replace(getNavLink(BASE_ROUTES.LYSA_ACCOUNT));
    }
  }, [history, klarnaContext.state.selectedLysaAccount]);

  if (!klarnaContext.state.selectedLysaAccount) {
    return null;
  }

  return (
    <>
      <BankSelection
        onComplete={onComplete}
        missingBank={getMissingBankLocation?.({
          accountId: klarnaContext.state.selectedLysaAccount.accountId,
        })}
        intro={intl.formatMessage(KlarnaIntroText[country])}
        missingBankHeader={
          <TranslatedText id="klarna.bank-selection.missing-bank.header" />
        }
        missingBankDescription={
          <TranslatedText id="klarna.bank-selection-single-deposit.missing-bank.description" />
        }
      />
    </>
  );
}
