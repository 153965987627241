import React, { VoidFunctionComponent, useState, useEffect } from "react";
import { FundFees } from "../../../../../../data/dataFees";
import { FundFeeDetailsTable } from "./fundFeeDetailsTable/FundFeeDetailsTable";
import { ExpandableTable } from "../../components/expandableTable/ExpandableTable";
import { defineMessages, useIntl } from "react-intl";
import { FeeHeaderCell } from "../../../components/feeHeaderCell/FeeHeaderCell";
import { CostHeaderCell } from "../../../components/costHeaderCell/CostHeaderCell";
import { CostBodyCell } from "../../../components/costBodyCell/CostBodyCell";
import { FeeBodyCell } from "../../../components/feeBodyCell/FeeBodyCell";
import { ShowMoreButton } from "../../../components/showMoreButton/ShowMoreButton";

const messages = defineMessages({
  managementFee: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeesTable.managementFee",
  },
  underlyingAssetsFee: {
    id:
      "fees.feeTables.activeAccount.accountRow.fundFeesTable.underlyingAssetsFee",
  },
  underlyingTransactionsFee: {
    id:
      "fees.feeTables.activeAccount.accountRow.fundFeesTable.underlyingTransactionsFee",
  },
  details: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeesTable.details",
  },
  total: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeesTable.total",
  },
  averageManagementCost: {
    id:
      "fees.feeTables.activeAccount.accountRow.fundFeesTable.averageManagementCost",
  },
  averageUnderlyingAssetsCost: {
    id:
      "fees.feeTables.activeAccount.accountRow.fundFeesTable.averageUnderlyingAssetsCost",
  },
  underlyingTransactionsCost: {
    id:
      "fees.feeTables.activeAccount.accountRow.fundFeesTable.underlyingTransactionsCost",
  },
  openButtonText: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeesTable.openButtonText",
  },
  closeButtonText: {
    id: "fees.feeTables.activeAccount.accountRow.fundFeesTable.closeButtonText",
  },
});

interface Props {
  accountData: FundFees;
  isOpen: boolean;
  columns: number;
}

export const FundFeesTable: VoidFunctionComponent<Props> = ({
  accountData,
  isOpen,
  columns,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    setShowDetails(false);
  }, [accountData]);

  const intl = useIntl();

  return (
    <ExpandableTable isOpen={isOpen} parentCells={columns}>
      <thead>
        <tr>
          <FeeHeaderCell text={intl.formatMessage(messages.managementFee)} />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingAssetsFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsFee)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageManagementCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
          />
          <CostHeaderCell
            text={intl.formatMessage(messages.underlyingTransactionsCost)}
          />
          <th>{intl.formatMessage(messages.details)}</th>
          <th>{intl.formatMessage(messages.total)}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <FeeBodyCell
            value={accountData.managementFee}
            label={intl.formatMessage(messages.managementFee)}
          />
          <FeeBodyCell
            value={accountData.underlyingAssetsFee}
            label={intl.formatMessage(messages.underlyingAssetsFee)}
          />
          <FeeBodyCell
            value={accountData.underlyingTransactionsFee}
            label={intl.formatMessage(messages.underlyingTransactionsFee)}
          />
          <CostBodyCell
            value={accountData.averageManagementCost}
            label={intl.formatMessage(messages.averageManagementCost)}
          />
          <CostBodyCell
            value={accountData.averageUnderlyingAssetsCost}
            label={intl.formatMessage(messages.averageUnderlyingAssetsCost)}
          />
          <CostBodyCell
            value={accountData.underlyingTransactionsCost}
            label={intl.formatMessage(messages.underlyingTransactionsCost)}
          />
          <td data-label={intl.formatMessage(messages.details)}>
            <ShowMoreButton
              isOpen={showDetails}
              onClick={() => {
                setShowDetails(!showDetails);
              }}
              openText={intl.formatMessage(messages.openButtonText)}
              closedText={intl.formatMessage(messages.closeButtonText)}
            />
          </td>
          <CostBodyCell
            value={accountData.averageTotalCost}
            label={intl.formatMessage(messages.total)}
          />
          <FeeBodyCell
            value={accountData.total}
            label={intl.formatMessage(messages.total)}
          />
        </tr>
        {showDetails && (
          <tr>
            <FundFeeDetailsTable
              fundFeeDetails={accountData.details}
              isOpen={showDetails}
            />
          </tr>
        )}
      </tbody>
    </ExpandableTable>
  );
};
