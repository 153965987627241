import { API, cache, encode } from "@lysaab/ui-2";
import {
  InvestmentAccountId,
  AccountType,
  SavingsAccountId,
} from "./dataAccounts";
import { Eligibility } from "./dataInvestments";
import { LegalEntityType } from "./dataLogin";
import { dataTransactions, Filter, Transaction } from "./dataTransactions";
import { LegalEntityId } from "./dataUser";

enum CustomerType {
  PRIVATE = "PRIVATE",
  COMPANY = "COMPANY",
}

export interface ClosedAccount {
  accountId: InvestmentAccountId;
  closed: string;
  name: string;
  legalEntityId: LegalEntityId;
  type: AccountType;
  created: string;
  country: string;
  customerType: CustomerType;
}

export enum CloseCustomerScenario {
  YEARLY_REVIEW = "YEARLY_REVIEW",
  CLOSE = "CLOSE",
}

export type CloseDownToken = string & { readonly isCloseDownToken: true };

interface CloseDownTokenResponse {
  token: CloseDownToken;
}

export type EligibilityResponse = {
  legalEntityType: LegalEntityType;
  saved: string;
} & Eligibility;

export const dataProfile = {
  closeInvestmentAccount: (accountId: InvestmentAccountId) => {
    return API.delete<EligibilityResponse>(
      encode`/profile/close/account/${accountId}`
    ).then((response) => {
      cache.delete("/profile/close/account");
      cache.delete("/accounts");
      cache.delete("/transactions");
      cache.delete("/transfer/transactions");
      return response;
    });
  },
  closeSavingsAccount: (
    accountId: SavingsAccountId,
    externalBankAccount?: string
  ) => {
    return API.delete<void>(encode`/profile/close/savings-account`, {
      accountId,
      externalBankAccount,
    }).then((response) => {
      cache.delete("/profile/close/account");
      cache.delete("/accounts");
      cache.delete("/transactions");
      cache.delete("/transfer/transactions");
      return response;
    });
  },

  getClosedAccounts: function () {
    return API.get<ClosedAccount[]>("/profile/close/accounts");
  },

  getClosedAccountsTransactions: (filter: Filter) => {
    const params = dataTransactions.filterToQueryParameters(filter);
    return API.get<Transaction[]>(
      `/profile/close/transaction?${params.toString()}`
    );
  },

  closeCustomer: (
    emailTaxInfo: boolean,
    token: CloseDownToken,
    scenario: CloseCustomerScenario,
    externalBankAccount?: string
  ) => {
    return API.delete(`/profile/close/customer`, {
      emailTaxInfo,
      externalBankAccount,
      token,
      scenario,
    });
  },

  getClosedownToken: () => {
    return API.get<CloseDownTokenResponse>(
      `/profile/close/customer/closedown-token`
    );
  },
};
