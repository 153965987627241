import { useCallback, useRef, useState, VoidFunctionComponent } from "react";
import { Button, Form, FormErrors, LysaFormRef, Spinner } from "@lysaab/ui-2";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AccountAdviceCard,
  UpdateInvestmentAccountComposition,
} from "../../../../pageComponents/accountsAllocation/accountAdviseCard/AccountAdviceCard";
import { defineMessages } from "react-intl";
import {
  dataInvestments,
  getAccountQuestions,
  isValidRecalculateAdvicesWithRiskRequest,
  isValidUpdateInvestmentProfilesRequest,
  getEligibility,
  getEligibilityRisk,
} from "../../../../data/dataInvestments";
import { useEligibilityContext } from "../../../../context/EligibilityContext";
import { useAccountsContext } from "../../../../context/AccountsContext";
import { useSuitabilityAssessmentContext } from "../../contexts/SuitabilityAssessmentContext";
import {
  AccountUpdateAction,
  useReviewAccountContext,
  useUpdateReviewAccounts,
} from "../../../reviewAccount/ReviewAccountContext";
import { InvestmentAccountId } from "../../../../data/dataAccounts";

const messages = defineMessages({
  errorTitle: {
    id: "summary.error.title",
  },
});

interface Props {
  next: () => void;
  navigateReviewRiskWarning: (
    reviewAccount: UpdateInvestmentAccountComposition
  ) => void;
  navigateReview: (reviewAccount: UpdateInvestmentAccountComposition) => void;
}

export const AccountsAllocation: VoidFunctionComponent<Props> = ({
  next,
  navigateReviewRiskWarning,
  navigateReview,
}) => {
  const intl = useIntl();
  const [eligibilityState] = useEligibilityContext();
  const [accountsState] = useAccountsContext();
  const [reviewAccountState] = useReviewAccountContext();
  const updateReviewAccounts = useUpdateReviewAccounts();
  const [suitabilityAssessmentState, setSuitabilityAssessmentState] =
    useSuitabilityAssessmentContext();
  const [loading, setLoading] = useState<boolean>(true);
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (accountsState.preferenceAccounts.length === 0) {
      return;
    }

    const recalculateRequest = {
      risk: getEligibilityRisk(eligibilityState.risk),
      investmentProfiles: accountsState.preferenceAccounts.map((account) => ({
        accountId: account.accountId as InvestmentAccountId,
        ...getAccountQuestions(account),
      })),
    };

    if (!isValidRecalculateAdvicesWithRiskRequest(recalculateRequest)) {
      throw new Error("AccountsAllocation - data missmatches request");
    }

    Promise.all([
      dataInvestments.recalculateAdvicesWithRisk(recalculateRequest),
      dataInvestments.getAdviseAccounts(),
    ])
      .then(([recalculatedAdvices, adviseAccounts]) =>
        recalculatedAdvices.reduce(
          (
            mergedAccounts: UpdateInvestmentAccountComposition[],
            recalculated
          ) => {
            const adviseAccount = adviseAccounts.find(
              (adviceAccount) =>
                adviceAccount.accountId === recalculated.accountId
            );

            if (adviseAccount) {
              return [
                ...mergedAccounts,
                {
                  accountId: recalculated.accountId,
                  risk: recalculated.takenRisk,
                  newAdvice: recalculated,
                  oldAdvice: adviseAccount,
                },
              ];
            }

            return mergedAccounts;
          },
          []
        )
      )
      .then(updateReviewAccounts)
      .finally(() => setLoading(false));
  }, [
    accountsState.preferenceAccounts,
    eligibilityState,
    updateReviewAccounts,
  ]);

  const onSubmit = useCallback(() => {
    const investmentRequest = {
      eligibility: getEligibility(eligibilityState),
      investmentProfiles: reviewAccountState.accounts?.map((account) => ({
        accountId: account.accountId,
        takenRisk: account.risk,
        ...getAccountQuestions(account.newAdvice),
      })),
    };

    if (
      formRef.current?.isValid &&
      isValidUpdateInvestmentProfilesRequest(investmentRequest)
    ) {
      setLoading(true);

      dataInvestments
        .updateInvestmentProfiles(investmentRequest)
        .then(next)
        .catch(() => setLoading(false));
    } else {
      setSuitabilityAssessmentState({ forceErrorsAccountsAllocation: true });
    }
  }, [
    eligibilityState,
    reviewAccountState.accounts,
    next,
    setSuitabilityAssessmentState,
  ]);

  const handleAccountAction = useCallback(
    (
      action: AccountUpdateAction,
      account: UpdateInvestmentAccountComposition
    ) => {
      const reviewAccount: UpdateInvestmentAccountComposition = JSON.parse(
        JSON.stringify({ ...account, action })
      );

      if (action === AccountUpdateAction.KEEP) {
        navigateReviewRiskWarning(reviewAccount);
      } else {
        navigateReview(reviewAccount);
      }
    },
    [navigateReview, navigateReviewRiskWarning]
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={onSubmit}
      forceValidation={suitabilityAssessmentState.forceErrorsAccountsAllocation}
    >
      <h2>
        <FormattedMessage id="summary.header" />
      </h2>
      <p>
        <FormattedMessage id="summary.description" />
      </p>

      <FormErrors
        title={intl.formatMessage(messages.errorTitle)}
        lysaFormRef={formRef}
      />

      {reviewAccountState.accounts.map((account) => (
        <AccountAdviceCard
          key={account.accountId}
          account={account}
          onAccountAction={(action) => handleAccountAction(action, account)}
        />
      ))}

      <Button
        block
        type="submit"
        label={<FormattedMessage id="summary.button.next" />}
      />
    </Form>
  );
};
