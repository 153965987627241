import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  VoidFunctionComponent,
} from "react";
import {
  StatusLabel,
  STATUS_TYPE,
  Typography,
  InvestmentType,
  RiskIndicator,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import {
  AlignValues,
  GridRow,
  JustifyContentValues,
} from "../../../components/grid/gridRow/GridRow";
import { CardPaneList } from "../../../components/cardPane/CardPaneList";
import { CardPaneListItem } from "../../../components/cardPane/CardPaneListItem";
import {
  AdviseAccount,
  SavingsHorizonLength,
  RecalculateAdvicesWithRiskResponse,
} from "../../../data/dataInvestments";
import "./AccountAdviceCard.scss";
import { useHistory } from "react-router";
import { useAccountName } from "../../../hooks/useAccountName";
import { CardButtons } from "./cardButtons/CardButtons";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import {
  AccountUpdateAction,
  useReviewAccountContext,
} from "../../../pages/reviewAccount/ReviewAccountContext";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";

const messages = defineMessages({
  investmentTypeTitle: {
    id: "summary.accountCard.investmentType",
  },
});

const SHORT_INVESTMENT_MAX_RISK = 50;

interface MessageWithId {
  id: string;
}

const alternativesInvesetmentType = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: { id: "summary.investmentType.BROAD" },
  [InvestmentType.SUSTAINABLE]: { id: "summary.investmentType.SUSTAINABLE" },
});

export type UpdateInvestmentAccountComposition = {
  accountId: InvestmentAccountId;
  risk: number;
  action?: AccountUpdateAction;
  newAdvice: RecalculateAdvicesWithRiskResponse;
  oldAdvice: AdviseAccount;
};

interface Props {
  account: UpdateInvestmentAccountComposition;
  onAccountAction: (action: AccountUpdateAction) => void;
}

export const AccountAdviceCard: VoidFunctionComponent<Props> = ({
  account,
  onAccountAction,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const accountName = useAccountName(account.accountId, true);
  const [reviewAccountState, setReviewAccountState] = useReviewAccountContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const updateRisk = useCallback(() => {
    const accounts = reviewAccountState.accounts;
    const index = accounts.findIndex((a) => a.accountId === account.accountId);

    accounts[index] = {
      ...accounts[index],
      risk: accounts[index].newAdvice.takenRisk,
      action: AccountUpdateAction.KEEP,
    };

    setReviewAccountState({ accounts });
    EventTracker.track({
      event: TrackerEvent.REVIEW_ACCOUNT_RISK,
      message: {
        action: AccountUpdateAction.KEEP,
        accountId: accounts[index].accountId,
        risk: accounts[index].newAdvice.takenRisk,
      },
    });
  }, [account.accountId, reviewAccountState.accounts, setReviewAccountState]);

  const updateAccount = useCallback(
    (accountAction: AccountUpdateAction) => {
      if (
        accountAction === AccountUpdateAction.KEEP &&
        Math.abs(account.newAdvice.takenRisk - account.newAdvice.advice) < 15
      ) {
        updateRisk();
      } else {
        onAccountAction(accountAction);
      }
    },
    [
      account.newAdvice.takenRisk,
      account.newAdvice.advice,
      updateRisk,
      onAccountAction,
    ]
  );

  useEffect(() => {
    if (
      document &&
      history.location.hash &&
      history.location.hash === `#${account.accountId}`
    ) {
      const element = document.getElementById(history.location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ block: "center" });
        history.replace(history.location.pathname);
      }
    }
  }, [account.accountId, history, history.location.hash]);

  const [currentRisk, currentInvestmentType] = useMemo(() => {
    const currentRisk: number = account.risk;

    const currentInvestmentType =
      typeof account.action === "undefined"
        ? account.oldAdvice.investmentType
        : account.newAdvice.investmentType;

    return [currentRisk, currentInvestmentType];
  }, [
    account.action,
    account.newAdvice.investmentType,
    account.oldAdvice.investmentType,
    account.risk,
  ]);

  const isAdviceInvestmentTypeUpdated =
    account.newAdvice.investmentType !== account.oldAdvice.investmentType;
  const isAdviceRiskUpdated =
    account.newAdvice.advice !== account.oldAdvice.advisedRisk;
  const isAdviceUpdated = isAdviceInvestmentTypeUpdated || isAdviceRiskUpdated;
  const isShortInvestmentHorizon =
    account.newAdvice.savingsHorizon === SavingsHorizonLength.SHORT;
  const isTakenRiskNotSameAsAdvice = currentRisk !== account.newAdvice.advice;
  const isInvestmenTypeNotSameAsAdvice =
    currentInvestmentType !== account.newAdvice.investmentType;

  return (
    <div
      id={account.accountId}
      className="summary-account-card"
      ref={containerRef}
    >
      <CardPaneList title={accountName}>
        <CardPaneListItem>
          {isAdviceRiskUpdated &&
            isShortInvestmentHorizon &&
            account.risk > SHORT_INVESTMENT_MAX_RISK && (
              <StatusLabel type={STATUS_TYPE.ERROR}>
                <FormattedMessage id="summary.accountCard.allocation.warning" />
              </StatusLabel>
            )}
          <GridRow className="column-row">
            <GridCol className="item-title">
              <FormattedMessage id="summary.accountCard.takenRisk.title" />
            </GridCol>
            <GridCol>
              <FormattedMessage
                id="summary.accountCard.takenRisk.subtitle"
                values={{
                  stock: intl.formatNumber(currentRisk / 100, {
                    style: "percent",
                  }),
                  bonds: intl.formatNumber(1 - currentRisk / 100, {
                    style: "percent",
                  }),
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow
            className="investment-type-info"
            justifyContent={JustifyContentValues.SPACE_BETWEEN}
            alignItems={AlignValues.CENTER}
          >
            <GridCol>
              <Typography type="label">
                {intl.formatMessage(messages.investmentTypeTitle)}
              </Typography>
            </GridCol>
            <GridCol disabledFlexGrow>
              <Typography type="label">
                {intl.formatMessage(
                  alternativesInvesetmentType[currentInvestmentType]
                )}{" "}
              </Typography>
              <RiskIndicator
                size={20}
                risk={currentRisk}
                investmentType={currentInvestmentType}
              />
            </GridCol>
          </GridRow>
        </CardPaneListItem>

        {(isTakenRiskNotSameAsAdvice || isInvestmenTypeNotSameAsAdvice) && (
          <CardPaneListItem>
            {isAdviceUpdated && (
              <StatusLabel type={STATUS_TYPE.WARNING}>
                <FormattedMessage id="summary.accountCard.advice.updated" />
              </StatusLabel>
            )}
            <GridRow className="column-row">
              <GridCol className="item-title">
                <FormattedMessage id="summary.accountCard.proposedRisk.title" />
              </GridCol>
              <GridCol>
                <FormattedMessage
                  id="summary.accountCard.proposedRisk.subtitle"
                  values={{
                    stock: intl.formatNumber(account.newAdvice.advice / 100, {
                      style: "percent",
                    }),
                    bonds: intl.formatNumber(
                      1 - account.newAdvice.advice / 100,
                      {
                        style: "percent",
                      }
                    ),
                  }}
                />
              </GridCol>
            </GridRow>

            <GridRow
              className="investment-type-info"
              justifyContent={JustifyContentValues.SPACE_BETWEEN}
              alignItems={AlignValues.CENTER}
            >
              <GridCol>
                <Typography type="label">
                  {intl.formatMessage(messages.investmentTypeTitle)}
                </Typography>
              </GridCol>
              <GridCol disabledFlexGrow>
                <Typography type="label">
                  {intl.formatMessage(
                    alternativesInvesetmentType[
                      account.newAdvice.investmentType
                    ]
                  )}{" "}
                </Typography>
                <RiskIndicator
                  size={20}
                  risk={account.newAdvice.advice}
                  investmentType={account.newAdvice.investmentType}
                />
              </GridCol>
            </GridRow>
          </CardPaneListItem>
        )}

        <CardButtons
          containerRef={containerRef}
          account={account}
          updateAccount={updateAccount}
        />
      </CardPaneList>
    </div>
  );
};
