import React, { useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { LayoutContext } from "../../../context/LayoutContext";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../pages/overview/OverviewPage";
import { EventBar } from "../EventBar";

const messages = defineMessages({
  headerText: {
    id: "eventBars.seeNoEvil.header",
  },
  actionText: {
    id: "eventBars.seeNoEvil.actionText",
  },
});

export const SeeNoEvilBar: React.VFC = () => {
  const intl = useIntl();
  const { setState: setLayoutState } = useContext(LayoutContext);
  const [hidden, setHidden] = useState(false);
  const location = useLocation();

  const hiddenOnRoute =
    location.pathname !== getNavLink(OVERVIEW_PAGE_URL) &&
    location.pathname !== getNavLink("");

  if (hidden || hiddenOnRoute) {
    return null;
  }

  return (
    <EventBar
      action={() => setLayoutState({ seeNoEvil: false })}
      headerText={
        <div>
          <span style={{ fontSize: "1.6em", marginRight: 14 }}>🙈</span>{" "}
          <span>{intl.formatMessage(messages.headerText)}</span>
        </div>
      }
      actionText={intl.formatMessage(messages.actionText)}
      onClose={() => setHidden(true)}
    />
  );
};
