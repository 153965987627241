import React, { VoidFunctionComponent } from "react";
import { Page, PageHeader } from "../Page";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../components/TranslatedText";
import { FeeTables } from "./feeTables/FeeTables";

export const FEES_PAGE_URL = "/fees";

export const FeesPage: VoidFunctionComponent = () => (
  <Page>
    <PageHeader>
      <h1>
        <TranslatedText id="fees.header" />
      </h1>
    </PageHeader>
    <GridRow>
      <GridCol xsmall={12}>
        <FeeTables />
      </GridCol>
    </GridRow>
  </Page>
);
