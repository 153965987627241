import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { RouteProps, useParams, useHistory, matchPath } from "react-router-dom";
import { Route } from "../../components/route/Route";
import {
  EligibilityState,
  useEligibilityContext,
} from "../../context/EligibilityContext";
import { InvestmentAccountId } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useReviewAccountContext } from "../reviewAccount/ReviewAccountContext";
import { ReviewAccountReturnState } from "../reviewAccount/ReviewAccountStory";
import { BASE_ROUTES } from "./AccountSituationStory";
import { AccountState, useAccountContext } from "./context/AccountContext";
import {
  AccountSituationState,
  useAccountSituationContext,
} from "./context/AccountSituationContext";

export type AccountSituationStartState =
  | {
      returnUrl: string;
    }
  | undefined;

export type AccountSituationReturnState = ReviewAccountReturnState<{
  accountState: AccountState;
  eligibilityState: EligibilityState;
  accountSituationState: AccountSituationState;
}>;

interface Props {
  children: ReactNode;
}

const AccessGuard: FunctionComponent<Props> = ({ children }) => {
  const history = useHistory();
  const [, setAccountState] = useAccountContext();
  const [, setEligibilityState] = useEligibilityContext();
  const [, setReviewAccountState] = useReviewAccountContext();
  const [accountSituationState, setAccountSituationState] =
    useAccountSituationContext();
  const { accountId } = useParams<{ accountId: InvestmentAccountId }>();
  const historyState = history.location.state;

  useEffect(() => {
    const isFirstPage =
      matchPath(history.location.pathname, {
        path: getNavLink(BASE_ROUTES.ACCOUNT_SITUATION),
        exact: true,
      }) !== null;

    /**
     * If firstPage then only check for returnUrl if user want to exit/is done
     * If not first page then check if history state contain context data and if it does then set it
     * If not first page and directAccessGuard is active then jump to first page
     */
    if (historyState) {
      if (
        isFirstPage &&
        (historyState as AccountSituationStartState)?.returnUrl
      ) {
        const startState = historyState as AccountSituationStartState;
        if (startState?.returnUrl) {
          setAccountSituationState({ returnUrl: startState.returnUrl });
        }
      } else {
        const returnState = historyState as AccountSituationReturnState;
        if (returnState?.accountState) {
          setAccountState(returnState.accountState);
        }
        if (returnState?.eligibilityState) {
          setEligibilityState(returnState.eligibilityState);
        }
        if (returnState?.reviewAccountState) {
          setReviewAccountState(returnState.reviewAccountState);
        }
        if (returnState?.accountSituationState) {
          setAccountSituationState(returnState.accountSituationState);
        }
      }
    } else if (!isFirstPage && !accountSituationState.directAccessGuard) {
      history.replace(getNavLink(BASE_ROUTES.ACCOUNT_SITUATION));
    }
  }, [
    accountSituationState.directAccessGuard,
    accountId,
    history,
    historyState,
    setAccountSituationState,
    setAccountState,
    setEligibilityState,
    setReviewAccountState,
  ]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const AccessGuardRoute: FunctionComponent<RouteProps> = ({
  children,
  ...props
}) => (
  <Route {...props}>
    <AccessGuard>{children}</AccessGuard>
  </Route>
);
