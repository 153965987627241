import React, { useContext, VoidFunctionComponent } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { DepositInterval } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";
import { UserContext } from "../../../context/UserContext";

export const messages = defineMessages({
  header: {
    id: "kyc.depositinterval.header",
  },
  required: {
    id: "kyc.depositinterval.required",
  },
});

interface MessageWithId {
  id: string;
}

export const depositIntervalMessages = defineMessages<
  DepositInterval,
  MessageWithId,
  Record<DepositInterval, MessageWithId>
>({
  [DepositInterval.MONTHLY]: {
    id: "kyc.depositinterval.alt.MONTHLY",
  },
  [DepositInterval.MULTIPLE_YEARLY]: {
    id: "kyc.depositinterval.alt.MULTIPLE_YEARLY",
  },
  [DepositInterval.ONCE_YEARLY]: {
    id: "kyc.depositinterval.alt.ONCE_YEARLY",
  },
  [DepositInterval.SELDOM]: {
    id: "kyc.depositinterval.alt.SELDOM",
  },
});

interface Props {
  value?: DepositInterval;
  setValue: (depositInterval: DepositInterval) => void;
  display?: boolean;
}

export const DepositIntervalQuestion: VoidFunctionComponent<Props> = ({
  value,
  setValue,
  display,
}) => {
  const intl = useIntl();
  const {
    state: { legalEntityType },
  } = useContext(UserContext);

  const alternatives = Object.values(DepositInterval).map((purpose) => {
    return {
      text: intl.formatMessage(depositIntervalMessages[purpose]),
      value: purpose,
    } as Alternative<DepositInterval>;
  });
  const valueAlternative = alternatives.find((alt) => alt.value === value);

  if (display) {
    return (
      <DataDisplay
        hasContainer
        type="input"
        title={intl.formatMessage(messages.header, { legalEntityType })}
        text={valueAlternative?.text}
      />
    );
  }

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        value={valueAlternative}
        header={intl.formatMessage(messages.header, { legalEntityType })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        onChange={(depositInterval) => setValue(depositInterval.value)}
      />
    </Card>
  );
};
