import React from "react";
import { Redirect } from "react-router";
import { countryRoutesMap } from "./TopLevelRouter";
import { COUNTRY_SELECT_PAGE_URL } from "./pages/countrySelect/CountrySelectPage";
import { Store, STORE_PREFERENCES_COUNTRY } from "./Store";
import { LysaCountry } from "@lysaab/countries";

export function CountryRedirect() {
  const country = Store.getValue(STORE_PREFERENCES_COUNTRY) as LysaCountry;
  if (country) {
    return <Redirect to={countryRoutesMap[country]} />;
  } else {
    return <Redirect to={COUNTRY_SELECT_PAGE_URL} />;
  }
}
