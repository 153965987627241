import React from "react";
import { Page, PageHeader } from "../Page";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../components/TranslatedText";
import { PersonalData } from "../../components/personalData/PersonalData";
import { ActiveAccountsData } from "../../components/activeAccountsData/ActiveAccountsData";
import { ClosedAccountsData } from "../../components/closedAccountsData/ClosedAccountsData";
import { CardList, CardListItem, LysaLink } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { MONTHLY_CONSENTS_PAGE_URL } from "../../countries/sweden/pages/monthlyAgreements/MonthlyAgreements";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../../countries/sweden/pages/deposits/Autogiro/deposit/AutogiroDepositPage";
import { getNavLink } from "../../hooks/useCountryUrls";

import "./YourDataPage.scss";

export const YOUR_DATA_PAGE_URL = "/your-data";

export const YourDataPage = () => {
  return (
    <Page className="your-data">
      <PageHeader>
        <h1>
          <TranslatedText id="yourData.header" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={6}>
          <PersonalData />
          <RouteAwareToggle path={getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL)}>
            <div>
              <h2>
                <TranslatedText id="yourData.monthlyAgreementsHeader" />
              </h2>
              <CardList>
                <CardListItem>
                  <LysaLink
                    component={Link}
                    to={getNavLink(MONTHLY_CONSENTS_PAGE_URL)}
                  >
                    <TranslatedText id="yourData.monthlyAgreements" />
                  </LysaLink>
                </CardListItem>
              </CardList>
            </div>
          </RouteAwareToggle>
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <ActiveAccountsData />
          <ClosedAccountsData />
        </GridCol>
      </GridRow>
    </Page>
  );
};
