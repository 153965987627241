import React, { FunctionComponent, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { LysaTable } from "../../../../../../components/lysaTable/LysaTable";

import "./ExpandableTable.scss";

interface Props {
  isOpen: boolean;
  parentCells: number;
}

export const ExpandableTable: FunctionComponent<Props> = ({
  children,
  parentCells,
  isOpen,
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(!isOpen);

  useEffect(() => {
    setIsOpenInternal(isOpen);
  }, [isOpen]);

  return (
    <td
      colSpan={parentCells}
      className="expandable-table expandable-table--height-auto"
    >
      <AnimateHeight
        height={isOpenInternal ? "auto" : 0}
        animateOpacity
        className="expandable-table-marker"
      >
        <LysaTable>{children}</LysaTable>
      </AnimateHeight>
    </td>
  );
};
