import { Card, StatusLabel, STATUS_TYPE } from "@lysaab/ui-2";
import React from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";

import "./SavingsAccountIntroCard.scss";

interface Props {
  navigationButton: React.ReactNode;
}

export const SavingsAccountIntroCard: React.VoidFunctionComponent<Props> = ({
  navigationButton,
}) => {
  return (
    <Card className="savings-account-card">
      <h2>
        <TranslatedText id="sweden.createAccount.savingsAccountIntroCard.createSavingsHeader" />
      </h2>
      <React.Fragment>
        <StatusLabel type={STATUS_TYPE.WARNING} icon>
          <TranslatedText id="sweden.createAccount.savingsAccountIntroCard.newsFlag" />
        </StatusLabel>

        <p>
          <TranslatedText id="sweden.createAccount.savingsAccountIntroCard.createSavingsIntro" />
        </p>
        {navigationButton}
      </React.Fragment>
    </Card>
  );
};
