import React, { useContext, FunctionComponent } from "react";
import { KFAccountContext } from "../KFAccountContext";
import { Card, Icon, Button } from "@lysaab/ui-2";
import { useHistory } from "react-router";
import "./Warning.scss";

export const ROUTE = "/investment-type";

interface Props {
  candidateRoute: string;
  editAllocationRoute: string;
}

export const Warning: FunctionComponent<Props> = ({
  candidateRoute,
  editAllocationRoute,
}) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const history = useHistory();
  const risk = kfState.takenRisk || 0;
  const advisedRisk = kfState.advicedRisk || 0;

  return (
    <div className="create-account-corporation-warning">
      <h1>Val av fördelning</h1>

      <Card>
        <div className="center">
          <Icon.Error size={48} color="#FDDE4A" />
        </div>

        <p className="risk-warning-message">
          Målfördelningen{" "}
          <b>
            {risk} % aktier, {100 - risk} % räntor
          </b>
          , avviker från vårt investeringsförslag om{" "}
          <b>
            {advisedRisk} % aktier, {100 - advisedRisk} % räntor
          </b>
          . Även om din valda fördelning är lämplig för dig anser vi att det är
          bättre om du väljer en fördelning närmare investeringsförslaget.
        </p>
      </Card>

      <div className="bottom-nav">
        <Button
          block
          onClick={() => {
            history.push(candidateRoute);
          }}
          label="Gå vidare"
        />

        <Button
          variant="secondary"
          block
          onClick={() => {
            setKFState({
              takenRisk: kfState.advicedRisk,
            });
            history.push(candidateRoute);
          }}
          label="Gå vidare med Lysas förslag"
        />

        <Button
          variant="secondary"
          block
          onClick={() => {
            history.push(editAllocationRoute);
          }}
          label="Tillbaka"
        />
      </div>
    </div>
  );
};
