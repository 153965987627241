import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { Spinner, Story, WorldCountry } from "@lysaab/ui-2";
import {
  EMPTY_PEP_STATE,
  EntityType,
  IsPepPage,
  PepContext,
  PepContextProvider,
  PepRolePage,
  PepState,
  PepStatusPage,
  PepType,
  RelationPage,
  requestToState,
} from "@lysaab/lysa-pep";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { PageStripped } from "../../../PageStripped";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { Switch } from "../../../../components/route/Switch";
import { RouteGuard } from "./RouteGuard";
import { PepOverview } from "./PepOverview";
import { dataKyc } from "../../../../data/dataKyc";
import { UserContext } from "../../../../context/UserContext";
import { LegalEntityType } from "../../../../data/dataLogin";
import { dataCorporate } from "../../../../data/dataCorporate";

export const KYC_UPDATE_PEP_STORY = "/kyc/pep";

export const BASE_ROUTES = {
  PEP_IS_PEP: `${KYC_UPDATE_PEP_STORY}`,
  PEP_RELATION: `${KYC_UPDATE_PEP_STORY}/pep-relation`,
  PEP_STATUS: `${KYC_UPDATE_PEP_STORY}/pep-status`,
  PEP_ROLE: `${KYC_UPDATE_PEP_STORY}/pep-role`,
  PEP_OVERVIEW: `${KYC_UPDATE_PEP_STORY}/pep-overview`,
};

const messages = defineMessages({
  header: {
    id: "kyc.pep.edit.header",
  },
  ariaProgressLabel: {
    id: "kyc.pep.edit.ariaProgressLabel",
  },
});

const PepUpdateInstance: VoidFunctionComponent = () => {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const history = useHistory();
  const pepContext = useContext(PepContext);
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const search = new URLSearchParams(history.location.search);
  const [loading, setLoading] = useState(true);
  const country = localizationContext.state.country;
  const legalEntityType = userContext.state.legalEntityType;

  let tin: string | undefined;
  let returnPath = getNavLink(OVERVIEW_PAGE_URL);
  if (search.has("returnUrl")) {
    returnPath = search.get("returnUrl") ?? returnPath;
  }
  if (search.has("tin")) {
    tin = search.get("tin") ?? undefined;
  }

  const next = (pathname: string) =>
    safeNavigation({ pathname, search: `?${search.toString()}` });

  useEffect(() => {
    if (!country) {
      setLoading(false);
      return;
    }

    dataKyc.getPep().then((pepResponseList) => {
      if (pepContext.state.legalEntityType) {
        return;
      }

      if (legalEntityType === LegalEntityType.PERSON) {
        if (pepResponseList.length === 1) {
          pepContext.setState({
            ...requestToState(pepResponseList[0].answers, country),
            legalEntityType: EntityType.PERSON,
            locked: pepResponseList[0].locked,
          });
          return setLoading(false);
        } else {
          pepContext.setState({
            ...EMPTY_PEP_STATE,
            legalEntityType: EntityType.PERSON,
          });
        }
      }

      if (legalEntityType === LegalEntityType.CORPORATION) {
        dataCorporate.getCompanyData().then((companyData) => {
          const pepStatesList = companyData.owners.reduce(
            (pepStateList, owner) => {
              const ownerPepAnswers = pepResponseList.find(
                (answer) => answer.tin === owner.tin
              );

              if (ownerPepAnswers) {
                return [
                  ...pepStateList,
                  {
                    ...requestToState(ownerPepAnswers.answers, country),
                    tin: owner.tin,
                    ownerName: owner.name,
                    legalEntityType: EntityType.CORPORATION,
                    locked: ownerPepAnswers.locked,
                  },
                ];
              }

              return [
                ...pepStateList,
                {
                  ...EMPTY_PEP_STATE,
                  tin: owner.tin,
                  ownerName: owner.name,
                  legalEntityType: EntityType.CORPORATION,
                },
              ];
            },
            [] as PepState[]
          );

          const pepState = pepStatesList.find(
            (pepState) => pepState.tin === tin
          );

          if (pepState) {
            pepContext.setState(pepState);
          } else {
            safeNavigation(returnPath);
          }
          setLoading(false);
        });
      }
    });
  }, [country, legalEntityType, pepContext, returnPath, safeNavigation, tin]);

  if (!localizationContext.state.country || loading) {
    return <Spinner />;
  }

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex > 0}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => {
          safeNavigation(returnPath);
        }}
        onBack={() => history.goBack()}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <RouteGuard exact path={ROUTES.PEP_IS_PEP}>
            <IsPepPage
              next={() => {
                if (pepContext.state.type === PepType.NOT_PEP) {
                  next(ROUTES.PEP_OVERVIEW);
                } else if (pepContext.state.type === PepType.ME) {
                  next(ROUTES.PEP_STATUS);
                } else {
                  next(ROUTES.PEP_RELATION);
                }
              }}
            />
          </RouteGuard>
          <RouteGuard exact path={ROUTES.PEP_RELATION}>
            <RelationPage next={() => next(ROUTES.PEP_STATUS)} />
          </RouteGuard>
          <RouteGuard exact path={ROUTES.PEP_STATUS}>
            <PepStatusPage
              countryCode={
                localizationContext.state.country as unknown as WorldCountry
              }
              countryName={
                intl.formatDisplayName(localizationContext.state.country, {
                  type: "region",
                }) || ""
              }
              next={() => next(ROUTES.PEP_ROLE)}
              language={localizationContext.state.language}
            />
          </RouteGuard>
          <RouteGuard exact path={ROUTES.PEP_ROLE}>
            <PepRolePage
              next={() => next(ROUTES.PEP_OVERVIEW)}
              language={localizationContext.state.language}
            />
          </RouteGuard>
          <RouteGuard exact path={ROUTES.PEP_OVERVIEW}>
            <PepOverview next={() => safeNavigation(returnPath)} />
          </RouteGuard>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const PepUpdateStory: VoidFunctionComponent = () => (
  <PepContextProvider>
    <PepUpdateInstance />
  </PepContextProvider>
);
