import { Card, Button, Spinner } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { InvestmentAccount } from "../../../../../../data/dataAccounts";
import {
  dataLegalEntity,
  LegalEntityResponse,
} from "../../../../../../data/dataLegalEntity";
import { ClosedAccount } from "../../../../../../data/dataProfile";
import { dataTax, IskTaxResponse } from "../../../../../../data/dataTax";
import NumberFormat from "react-number-format";

interface Props {
  account?: InvestmentAccount | ClosedAccount;
  year?: number;
}

export function InvestmentAccountTaxDetails({ account, year }: Props) {
  const [legalEntity, setLegalEntity] = useState<LegalEntityResponse>();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [tax, setTax] = useState<IskTaxResponse>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!year || !account) {
      return;
    }
    setIsLoading(true);
    dataLegalEntity.getLegalEntity().then(setLegalEntity);
    dataTax
      .getIskTax(year, account.accountId)
      .then((response) => {
        setTax(response);
      })
      .finally(() => setIsLoading(false));
  }, [account, year]);

  if (
    typeof year === "undefined" ||
    typeof account === "undefined" ||
    typeof tax === "undefined" ||
    typeof legalEntity === "undefined" ||
    isLoading
  ) {
    return (
      <>
        <h2>
          <span>
            <TranslatedText id="sweden.tax.details-header" />
          </span>
        </h2>
        <Card>
          <Spinner />
        </Card>
      </>
    );
  }

  return (
    <>
      {account && tax && year && legalEntity && (
        <>
          <h2>
            <TranslatedText id="sweden.tax.details-header.investmentAccount" />
          </h2>
          <Card>
            <dl>
              <dt>
                <TranslatedText
                  id="sweden.tax.year"
                  values={{ year: year, account: account.name }}
                />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.name"
                  values={{
                    name: legalEntity.name,
                  }}
                />
              </dd>
              <dd>
                <b>
                  <TranslatedText id="sweden.tax.tin" />
                </b>
                <span>
                  {legalEntity.tin.length === 12 ? (
                    <NumberFormat
                      displayType="text"
                      format={"########-####"}
                      value={legalEntity.tin}
                    />
                  ) : (
                    legalEntity.tin
                  )}
                </span>
              </dd>
              <dt>
                <TranslatedText
                  id="sweden.tax.quarter.label"
                  values={{ year: year }}
                />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.quarter.value"
                  values={{
                    quarter: 1,
                    amount: intl.formatNumber(tax.q1, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dd>
                <TranslatedText
                  id="sweden.tax.quarter.value"
                  values={{
                    quarter: 2,
                    amount: intl.formatNumber(tax.q2, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dd>
                <TranslatedText
                  id="sweden.tax.quarter.value"
                  values={{
                    quarter: 3,
                    amount: intl.formatNumber(tax.q3, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dd>
                <TranslatedText
                  id="sweden.tax.quarter.value"
                  values={{
                    quarter: 4,
                    amount: intl.formatNumber(tax.q4, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dt>
                <TranslatedText
                  id="sweden.tax.deposits.label"
                  values={{ year: year }}
                />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.deposits.value"
                  values={{
                    amount: intl.formatNumber(tax.deposits, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dt>
                <TranslatedText
                  id="sweden.tax.slr.label"
                  values={{ year: Number(year) - 1 }}
                />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.slr.value"
                  values={{
                    slr: intl.formatNumber(tax.slr, {
                      style: "percent",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }),
                  }}
                />
              </dd>
              <dt>
                <TranslatedText id="sweden.tax.taxable-capital.label" />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.taxable-capital.value"
                  values={{
                    amountq1: intl.formatNumber(tax.q1, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    amountq2: intl.formatNumber(tax.q2, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    amountq3: intl.formatNumber(tax.q3, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    amountq4: intl.formatNumber(tax.q4, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    deposits: intl.formatNumber(tax.deposits, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }),
                    taxableCapital: intl.formatNumber(tax.taxableCapital, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
              <dt>
                <TranslatedText id="sweden.tax.taxable-income.label" />
              </dt>
              <dd>
                <TranslatedText
                  id="sweden.tax.taxable-income.value"
                  values={{
                    taxableCapital: intl.formatNumber(tax.taxableCapital, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }),
                    taxMultiplier: intl.formatNumber(tax.taxMultiplier, {
                      style: "decimal",
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4,
                    }),
                    taxableIncome: intl.formatNumber(tax.taxableIncome, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }),
                  }}
                />
              </dd>
            </dl>
            <p>
              <TranslatedText
                id="sweden.tax.body"
                values={{
                  taxMultiplierIncrement: intl.formatNumber(
                    tax.taxMultiplierIncrement,
                    {
                      style: "percent",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  ),
                }}
              />
            </p>
            <div className="tax-print-button">
              <Button
                variant="primary"
                size="small"
                onClick={window.print}
                icon="Print"
                label={<TranslatedText id="sweden.tax.print-button" />}
              />
            </div>
          </Card>
        </>
      )}
    </>
  );
}
