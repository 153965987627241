import React, { useEffect, useState } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  SavingsAccountId,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  dataTransactions,
  Filter,
  instanceOfCashTransaction,
} from "../../../../../data/dataTransactions";
import { dataUser } from "../../../../../data/dataUser";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";
import cx from "classnames";
import { Amount } from "../../../../../components/amount/Amount";
import { FormattedDate } from "react-intl";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { Spinner } from "@lysaab/ui-2";

import "./TransactionsCard.scss";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import {
  HistoricTransactionsPageLocationState,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../../../../historicTransactions/HistoricTransactionsPage";

const MAX_TRANSACTIONS_SHOWN = 4;

export const TransactionsCard = ({
  accountId,
}: {
  accountId: SavingsAccountId;
}) => {
  const { accounts } = useAccounts();
  const [account, setAccount] = useState<SavingsAccount>();
  const [transactions, setTransactions] = useState<CashTransaction[]>([]);
  const { isLoading, setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }
    const filter: Filter = {
      ...dataTransactions.getDefaultFilter(),
      start: dataUser.getCreated([
        ...accounts.savingsAccounts,
        ...accounts.sharedSavingsAccounts,
      ]),
      accountIds: [accountId],
    };
    setAccount(
      [...accounts.savingsAccounts, ...accounts.sharedSavingsAccounts].find(
        (account) => account.accountId === accountId
      )
    );
    dataTransactions
      .getTransactions(filter)
      .then((data) => {
        const items = data.filter(
          (item): item is CashTransaction =>
            instanceOfCashTransaction(item) &&
            item.accountId === accountId &&
            (item.type === CashTransactionType.DEPOSIT ||
              item.type === CashTransactionType.WITHDRAWAL ||
              item.type === CashTransactionType.MOVE_IN ||
              item.type === CashTransactionType.MOVE_OUT)
        );
        setTransactions(items);
      })
      .finally(() => setIsLoading(false));
  }, [accountId, accounts, setIsLoading]);

  if (isLoading || typeof account === "undefined") {
    return <Spinner />;
  }

  const hasAdditionalTransactions =
    transactions.length > MAX_TRANSACTIONS_SHOWN;
  return (
    <AccountPageCard
      className="transactions-card"
      bottomLink={
        hasAdditionalTransactions && (
          <Link<HistoricTransactionsPageLocationState>
            to={{
              pathname: getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL),
              state: { accountId: account.accountId },
            }}
          >
            <TranslatedText id="savingsAccountPage.transactionsCard.showMore" />
          </Link>
        )
      }
    >
      <p className="transactions-card__ingress">
        {hasAdditionalTransactions ? (
          <TranslatedText
            id="savingsAccountPage.transactionsCard.ingressRecentTransactions"
            values={{ name: account.name }}
          />
        ) : (
          <TranslatedText
            id="savingsAccountPage.transactionsCard.ingressAllTransactions"
            values={{ name: account.name }}
          />
        )}
      </p>
      {transactions.length > 0 ? (
        <div className="transaction-card__list">
          <ul>
            {transactions
              .slice(0, MAX_TRANSACTIONS_SHOWN)
              .map((transaction) => {
                return (
                  <li key={transaction.booked}>
                    <div className="transaction-card__entry">
                      <div className="transaction-card__entry-left">
                        <span className="transaction-card__entry-header">
                          <FormattedDate value={transaction.booked} />
                        </span>
                        <div>
                          <span
                            className={cx("transaction-card__entry-indicator", {
                              "transaction-card__deposit-indicator":
                                transaction.type ===
                                CashTransactionType.DEPOSIT,
                              "transaction-card__withdrawal-indicator":
                                transaction.type ===
                                CashTransactionType.WITHDRAWAL,
                              "transaction-card__internal-transfer-indicator":
                                transaction.type ===
                                  CashTransactionType.MOVE_IN ||
                                transaction.type ===
                                  CashTransactionType.MOVE_OUT,
                            })}
                          />
                          {transaction.type === CashTransactionType.DEPOSIT && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.deposit" />
                          )}
                          {transaction.type ===
                            CashTransactionType.WITHDRAWAL && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.withdrawal" />
                          )}
                          {(transaction.type === CashTransactionType.MOVE_IN ||
                            transaction.type ===
                              CashTransactionType.MOVE_OUT) && (
                            <TranslatedText id="savingsAccountPage.transactionsCard.internalTransfer" />
                          )}
                        </div>
                      </div>
                      <div className="transaction-card__entry-right">
                        <span className="transaction-card__entry-header">
                          <TranslatedText id="savingsAccountPage.transactionsCard.amount" />
                        </span>
                        <Amount
                          amount={
                            transaction.type === CashTransactionType.MOVE_OUT
                              ? transaction.amount * -1
                              : transaction.amount
                          }
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
          {transactions.length > MAX_TRANSACTIONS_SHOWN && (
            <div className="transaction-card__list-fade" />
          )}
        </div>
      ) : (
        <TranslatedText id="savingsAccountPage.transactionsCard.noTransactions" />
      )}
    </AccountPageCard>
  );
};
