import React, { useContext, useEffect, useState } from "react";
import { Page, PageHeader } from "../Page";
import {
  Card,
  ServerError,
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { Marketing } from "./marketing/Marketing";
import {
  dataLegalEntity,
  EntityDetailsResponse,
} from "../../data/dataLegalEntity";
import { UserContext } from "../../context/UserContext";
import { ReadOnlyMarketing } from "./readOnlyMarketing/ReadOnlyMarketing";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../components/TranslatedText";
import { ProfileLinks } from "./profileLinks/ProfileLinks";
import { ChangePrimaryLogin } from "./changePrimaryLogin/ChangePrimaryLogin";
import { LanguageSelection } from "./languageSelection/LanguageSelection";

export const SETTINGS_PAGE_URL = "/settings";

export const SettingsPage = () => {
  const [settings, setSettings] = useState<EntityDetailsResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadSettingsError, setLoadSettingsError] = useState(false);
  const userContext = useContext(UserContext);
  const user = userContext.state;

  useEffect(() => {
    dataLegalEntity
      .getProfileSettings()
      .then((data) => {
        setSettings(data);
      })
      .catch((error: ServerError<unknown>) => {
        setLoadSettingsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const updateMarketingSettings = (details: EntityDetailsResponse) => {
    setSettings(details);
  };

  if (isLoading) return <Spinner />;
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="settings.header" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={6}>
          {loadSettingsError && (
            <React.Fragment>
              <h2>
                <TranslatedText id="settings.marketing.header" />
              </h2>
              <Card>
                <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                  <TranslatedText id="settings.error" />
                </Snackbar>
              </Card>
            </React.Fragment>
          )}
          {settings && (
            <React.Fragment>
              {user.readOnly ? (
                <ReadOnlyMarketing
                  commercialEmail={settings.contactDetails.commercialEmail}
                  notificationEmail={settings.contactDetails.notificationEmail}
                />
              ) : (
                <Marketing
                  marketingSettings={settings.contactDetails}
                  error={loadSettingsError}
                  updateMarketingSettings={updateMarketingSettings}
                />
              )}
            </React.Fragment>
          )}
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <ProfileLinks />
          {user.multipleRights && <ChangePrimaryLogin />}
        </GridCol>
        <GridCol xsmall={12} medium={6}>
          <LanguageSelection />
        </GridCol>
      </GridRow>
    </Page>
  );
};
