import React, {
  useCallback,
  useContext,
  useEffect,
  VoidFunctionComponent,
} from "react";
import { Spinner } from "@lysaab/ui-2";
import { useHistory } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { EditAllocation } from "../../../pageComponents/editAllocation/EditAllocation";
import { CreateAccountContext } from "../../../pages/createAccount/CreateAccountContext";
import { OVERVIEW_PAGE_URL } from "../../../pages/overview/OverviewPage";

interface Props {
  next: () => void;
  nextRiskWarning: () => void;
}

export const EditAllocationWrapper: VoidFunctionComponent<Props> = ({
  next,
  nextRiskWarning,
}) => {
  const createAccountContext = useContext(CreateAccountContext);
  const history = useHistory();

  useEffect(() => {
    if (typeof createAccountContext.state.advise?.advisedRisk === "undefined") {
      history.replace(getNavLink(OVERVIEW_PAGE_URL));
    }
  }, [
    history,
    createAccountContext.state.advise?.advisedRisk,
    createAccountContext.state.allocationSelectedRisk,
  ]);

  const onNext = useCallback(() => {
    createAccountContext.setState({
      takenRisk: createAccountContext.state.allocationSelectedRisk,
    });
    next();
  }, [createAccountContext, next]);

  const onNextRiskWarning = useCallback(() => {
    createAccountContext.setState({
      takenRisk: createAccountContext.state.allocationSelectedRisk,
    });
    nextRiskWarning();
  }, [createAccountContext, nextRiskWarning]);

  if (!createAccountContext.state.advise) {
    return <Spinner />;
  }

  return (
    <EditAllocation
      advisedRisk={createAccountContext.state.advise?.advisedRisk}
      savingsHorizon={createAccountContext.state.savingsHorizon}
      allocationSelectedRisk={createAccountContext.state.allocationSelectedRisk}
      setAllocationSelectedRisk={(allocationSelectedRisk: number) =>
        createAccountContext.setState({
          allocationSelectedRisk,
        })
      }
      allocationInvestment={
        (typeof createAccountContext.state.allocationInvestment !==
          "undefined" &&
          parseInt(createAccountContext.state.allocationInvestment, 10)) ||
        undefined
      }
      setAllocationInvestment={(allocationInvestment: number) =>
        createAccountContext.setState({
          allocationInvestment: allocationInvestment.toString(),
        })
      }
      investmentType={createAccountContext.state.advise.investmentType}
      next={onNext}
      nextRiskWarning={onNextRiskWarning}
    />
  );
};
