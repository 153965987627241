import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { LayoutGroup, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { ShareAccountInviteCard } from "../../components/shareAccountInviteCard/ShareAccountInviteCard";
import {
  dataAttorney,
  isShareAccountInvitationInFinalState,
  ShareAccountInvitation,
  ShareAccountInvitationId,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { Page } from "../Page";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "../shareAccountInvitations/ShareAccountInvitationsPage";
import "./ShareAccountSentInvitationPage.scss";

export const SHARE_ACCOUNT_SENT_INVITATION_URL =
  "/share-account-invitation/sent/:invitationId";

interface Match {
  invitationId?: ShareAccountInvitationId;
}

export const ShareAccountSentInvitationPage: React.VFC = () => {
  const [invitation, setInvitation] = useState<
    ShareAccountInvitation | ShareAccountSentInvitation
  >();
  const match = useRouteMatch<Match>();
  const [error, setError] = useState(false);
  const history = useHistory();
  const timer = useRef<NodeJS.Timeout>();

  const getInvitation = useCallback(
    (invitationId: ShareAccountInvitationId) => {
      return dataAttorney
        .getSentInvitation(invitationId)
        .then((invitation) => {
          setInvitation(invitation);
          return invitation;
        })
        .catch((error) => {
          setError(true);
          throw new Error(error);
        });
    },
    []
  );

  const poll = useCallback(
    (invitationId: ShareAccountInvitationId) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        getInvitation(invitationId).then((invitation) => {
          if (!isShareAccountInvitationInFinalState(invitation)) {
            poll(invitationId);
          }
        });
      }, 3000);
    },
    [getInvitation]
  );

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (typeof match.params.invitationId === "undefined") {
      setError(true);
      return;
    }
    getInvitation(match.params.invitationId).then((invitation) => {
      if (!isShareAccountInvitationInFinalState(invitation)) {
        poll(invitation.invitationId);
      }
    });
  }, [getInvitation, match.params.invitationId, poll]);

  if (error) {
    return (
      <ShareAccountSentInvitationPageWrapper>
        <Snackbar type={SNACKBAR_TYPES.WARNING}>
          <FormattedMessage id="shareAccountSentInvitationPage.errorText" />
        </Snackbar>
        <Button
          block
          onClick={() => history.goBack()}
          label={
            <FormattedMessage id="shareAccountSentInvitationPage.returnButtonText" />
          }
        />
      </ShareAccountSentInvitationPageWrapper>
    );
  }

  if (typeof invitation === "undefined") {
    return (
      <ShareAccountSentInvitationPageWrapper>
        <Spinner />
      </ShareAccountSentInvitationPageWrapper>
    );
  }

  const buttonVariant = isShareAccountInvitationInFinalState(invitation)
    ? "primary"
    : "secondary";

  return (
    <LayoutGroup>
      <ShareAccountSentInvitationPageWrapper>
        <ShareAccountInviteCard
          invitation={invitation}
          refreshInvitation={getInvitation}
        />
        <motion.div layout>
          <Button
            variant={buttonVariant}
            block
            component={Link}
            to={getNavLink(
              SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                ShareAccountInvitationsView.SENT
              )
            )}
            label={
              <FormattedMessage id="shareAccountSentInvitationPage.returnButtonText" />
            }
          />
        </motion.div>
      </ShareAccountSentInvitationPageWrapper>
    </LayoutGroup>
  );
};

interface PageProps {
  accountName?: string;
}

const ShareAccountSentInvitationPageWrapper: React.FC<PageProps> = ({
  children,
}) => {
  return (
    <Page>
      <div className="share-account-sent-invitation-page">{children}</div>
    </Page>
  );
};
