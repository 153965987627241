import React, { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { useInView } from "react-intersection-observer";
import "./PositionsData.scss";
import { Status, Retry } from "../../../../components/retry/Retry";
import { FundType, Fund, dataFunds } from "../../../../data/dataFunds";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedMessage } from "react-intl";
import { Disclaimer } from "../../Disclaimer";
import { PositionsList } from "../components/PositionsList";
import { PositionsExpandableCard } from "../components/PositionsExpandableCard";

interface Props {
  account: InvestmentAccount | undefined;
}

function checkPositions(account?: InvestmentAccount) {
  if (!account) {
    return {
      hasStocks: false,
      hasBonds: false,
    };
  }
  let hasStocks = false;
  let hasBonds = false;
  Object.values(account.positions).forEach((item) => {
    hasStocks = hasStocks || item.type === FundType.STOCKS;
    hasBonds = hasBonds || item.type === FundType.BONDS;
  });
  return {
    hasStocks,
    hasBonds,
  };
}

export const PositionsData: React.FC<Props> = ({ account }: Props) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [holdings, setHoldings] = useState<Fund[]>();
  const { hasBonds, hasStocks } = checkPositions(account);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const load = useCallback(() => {
    dataFunds
      .getHoldings()
      .then((data) => {
        setHoldings(data);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(load, 600);
  }, [load]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    load();
  }, [inView, load]);

  if (account && account.worth === 0) {
    return null;
  }

  if (
    account &&
    Object.entries(account.positions).length === 0 &&
    account.positions.constructor === Object
  ) {
    return null;
  }

  const cashElem = account?.uninvestedMoney ? (
    <div className="cash-assets">
      <FormattedMessage id="accountPage.positions.data.cashAssets" />
      : <Amount amount={account.uninvestedMoney} />
    </div>
  ) : null;

  return (
    <section
      className={cx("account-page-positions-data", {
        "single-asset": !(hasStocks && hasBonds),
      })}
      ref={ref}
    >
      <h2>
        <FormattedMessage id="accountPage.positions.data.holdings" />
      </h2>
      <Retry retry={retry} status={status}>
        <div className="account-page-positions-data-funds-wrapper">
          {holdings && account && hasStocks && (
            <div className="account-page-positions-data-stocks">
              {cashElem}
              <PositionsExpandableCard>
                <h4>
                  <FormattedMessage id="accountPage.positions.data.stocks" />
                </h4>
                <PositionsList
                  funds={holdings}
                  account={account}
                  fundType={FundType.STOCKS}
                />
              </PositionsExpandableCard>
            </div>
          )}
          {holdings && account && hasBonds && (
            <div className="account-page-positions-data-bonds">
              {cashElem}
              <PositionsExpandableCard>
                <h4>
                  <FormattedMessage id="accountPage.positions.data.bonds" />
                </h4>
                <PositionsList
                  funds={holdings}
                  account={account}
                  fundType={FundType.BONDS}
                />
              </PositionsExpandableCard>
            </div>
          )}
        </div>
      </Retry>

      <Disclaimer>
        <FormattedMessage id="accountPage.positions.data.disclaimer" />
      </Disclaimer>
    </section>
  );
};
