import React, { VoidFunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";

export enum CorporationExpenses {
  more,
  less,
}

const messages = defineMessages({
  header: {
    id: "situation.expenses.header",
  },
  required: {
    id: "situation.expenses.required",
  },
  yes: {
    id: "situation.expenses.alt.1",
  },
  no: {
    id: "situation.expenses.alt.2",
  },
});

interface MessageWithId {
  id: string;
}

const alternativeMessages = defineMessages<
  CorporationExpenses,
  MessageWithId,
  Record<CorporationExpenses, MessageWithId>
>({
  [CorporationExpenses.more]: {
    id: "situation.expenses.alt.1",
  },
  [CorporationExpenses.less]: {
    id: "situation.expenses.alt.2",
  },
});

interface Props {
  value?: CorporationExpenses;
  setValue: (newValue: CorporationExpenses) => void;
}

export const Expenses: VoidFunctionComponent<Props> = ({ value, setValue }) => {
  const intl = useIntl();

  const alternatives = Object.values(CorporationExpenses)
    .filter((val) => typeof val === "number")
    .map((value) => {
      return {
        text: intl.formatMessage(
          alternativeMessages[Number(value) as CorporationExpenses]
        ),
        value: Number(value),
      };
    });

  return (
    <Card>
      <RadioGroup
        header={intl.formatMessage(messages.header)}
        alternatives={alternatives}
        onChange={(riskReaction) => setValue(riskReaction.value)}
        value={alternatives.find((alt) => alt.value === value)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
