import {
  Card,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  WorldCountry,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, VoidFunctionComponent } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import {
  dataCrs,
  instanceOfCrsSwedishResponseWithAddress,
  mapCrsSwedishResponseToRequest,
} from "../../../../../data/dataCrs";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { NOT_FOUND_URL } from "../../../../../pages/NotFoundPage";
import { getCountryFromCountryCode } from "../../../../../utils/getCountryFromCountryCode";
import { CrsContext } from "../context/CrsContext";

import "./ConfirmCrsInformation.scss";

interface Props {
  next: () => void;
}

export const ConfirmCrsInformation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const crsInformation = useContext(CrsContext).state.crsInformation;
  const language = useContext(LocalizationContext).state.language;
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (typeof crsInformation === "undefined") {
      return history.replace(getNavLink(NOT_FOUND_URL));
    }
  }, [crsInformation, history]);

  const postCrsInformation = () => {
    if (crsInformation) {
      setIsLoading(true);
      dataCrs
        .postUpdateCrsSwedish(mapCrsSwedishResponseToRequest(crsInformation))
        .then(() => {
          next();
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  };

  if (isLoading || typeof crsInformation === "undefined") return <Spinner />;

  return (
    <div className="confirm-crs-information">
      <h1>
        <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.header" />
      </h1>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.error" />
          </Snackbar>
        )}
        <p>
          <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.ingress" />
        </p>
        <dl>
          <div className="list-row">
            <dt>
              <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.primaryTaxResidence" />
            </dt>
            <dd>
              {getCountryFromCountryCode({
                country: WorldCountry.SWEDEN,
                language,
              }) + " "}
              ({WorldCountry.SWEDEN})
            </dd>
          </div>
          {crsInformation.countries.length > 0 && <hr />}
          {crsInformation.countries.map((country) => (
            <React.Fragment key={country.country}>
              <div className="list-row">
                <dt>
                  <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.taxResidence" />
                </dt>
                <dd>
                  {getCountryFromCountryCode({
                    country: country.country,
                    language,
                  }) + " "}
                  ({country.country})
                </dd>
              </div>
              <div className="list-row">
                <dt>
                  <TranslatedText
                    id="sweden.updateCrsStory.confirmCrsInformation.tin"
                    values={{ country: country.country }}
                  />
                </dt>
                <dd>{country.tin}</dd>
              </div>
            </React.Fragment>
          ))}
          {crsInformation.residentCountry !== undefined && (
            <div className="list-row">
              <dt>
                <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.countryOfResidence" />
              </dt>
              <dd>
                {getCountryFromCountryCode({
                  country:
                    crsInformation.residentCountry || WorldCountry.SWEDEN,
                  language,
                }) + " "}
                ({crsInformation.residentCountry})
              </dd>
            </div>
          )}

          {instanceOfCrsSwedishResponseWithAddress(crsInformation) && (
            <React.Fragment>
              <div className="list-row">
                <dt>
                  <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.street" />
                </dt>
                <dd>{crsInformation.street}</dd>
              </div>

              <div className="list-row">
                <dt>
                  <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.postalCode" />
                </dt>
                <dd>{crsInformation.zipCode}</dd>
              </div>

              <div className="list-row">
                <dt>
                  <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.city" />
                </dt>
                <dd>{crsInformation.city}</dd>
              </div>
            </React.Fragment>
          )}
        </dl>
        <p>
          <TranslatedText id="sweden.updateCrsStory.confirmCrsInformation.endText" />
        </p>
      </Card>
      <Button
        onClick={postCrsInformation}
        block
        label={
          <TranslatedText id="sweden.updateCrsStory.updateCrsInformation.button.submit" />
        }
      />
    </div>
  );
};
