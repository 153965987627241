import React, { FunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { InvestmentType as type } from "../../data/dataAccounts";

interface Props {
  investmentType?: type;
  capitalize?: boolean;
}

const messages = defineMessages({
  [type.BROAD]: {
    id: "investmentType.broad",
    description: "Investment type - broad",
    defaultMessage: "broad",
  },
  [type.SUSTAINABLE]: {
    id: "investmentType.sustainable",
    description: "Investment type - sustainable",
    defaultMessage: "sustainable",
  },
});

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const InvestmentType: FunctionComponent<Props> = ({
  investmentType = type.BROAD,
  capitalize = false,
}) => {
  const intl = useIntl();
  if (capitalize) {
    return (
      <>{capitalizeFirstLetter(intl.formatMessage(messages[investmentType]))}</>
    );
  }
  return <>{intl.formatMessage(messages[investmentType])}</>;
};
