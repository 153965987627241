import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from "react";

import {
  BankIDStatus,
  PendingHintCode,
  Status,
  BankidResponse,
} from "@lysaab/ui-2";
import {
  KFAccountContext,
  getBankIDMessages,
  pendingBankIDMessages,
  failedBankIDMessages,
} from "../KFAccountContext";
import { dataCreateAccount } from "../../../../../../data/dataCreateAccount";
import { useParams, useHistory } from "react-router-dom";
import {
  Retry,
  Status as RetryStatus,
} from "../../../../../../components/retry/Retry";
import "./LysaSign.scss";
import { OrderRef } from "../../../../../../data/dataBankid";
import { ROUTES, ORDER_REF_IDENTIFIER } from "../CreateAccountCorporationPage";

interface Props {
  futurSummaryRoute: string;
}

interface Params {
  orderRef?: OrderRef;
  signingId: string;
}

const POLL_TIMER = 3000;

const pendingResponse = {
  status: Status.PENDING,
  hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
};

export const LysaSign: React.FunctionComponent<Props> = ({
  futurSummaryRoute,
}) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [response, setResponse] = useState<BankidResponse>(pendingResponse);
  const [retryStatus, setRetryStatus] = useState<RetryStatus>(
    RetryStatus.PENDING
  );
  const params = useParams<Params>();
  const started = useRef<boolean>(false);
  const history = useHistory();

  const { signingId, orderRef } = params;

  const poll = useCallback(
    (signingId, orderId) => {
      dataCreateAccount
        .kfLysaSignPoll(signingId, orderId)
        .then((response) => {
          setResponse(response);
          if (response.status === Status.PENDING) {
            setTimeout(() => {
              poll(signingId, orderId);
            }, POLL_TIMER);
          } else if (response.status === Status.COMPLETE) {
            history.push(futurSummaryRoute.replace(":signingId", signingId));
            setKFState({
              lysaSignResponse: undefined,
            });
          }
        })
        .catch(() => {
          setRetryStatus(RetryStatus.ERROR);
          history.replace({
            pathname: ROUTES.LYSA_SIGN.replace(":signingId", signingId),
          });
        });
    },
    [setKFState, history, futurSummaryRoute]
  );

  const init = useCallback(
    (setKFState, poll, signingId) => {
      dataCreateAccount
        .kfLysaSign(signingId)
        .then((data) => {
          setKFState({
            lysaSignResponse: data,
          });

          history.replace({
            pathname: ROUTES.LYSA_SIGN.replace(
              ORDER_REF_IDENTIFIER,
              data.orderRef || ""
            ).replace(":signingId", signingId),
          });

          setTimeout(() => {
            poll(signingId, data.orderRef);
          }, 2 * POLL_TIMER);
          setRetryStatus(RetryStatus.SUCCESS);
        })
        .catch((err) => {
          console.log("err", err);
          setRetryStatus(RetryStatus.ERROR);
        });
    },
    [history]
  );

  useEffect(() => {
    if (started.current) {
      return;
    }
    started.current = true;
    if (orderRef !== ORDER_REF_IDENTIFIER) {
      poll(signingId, orderRef);
      setRetryStatus(RetryStatus.SUCCESS);
    } else {
      init(setKFState, poll, signingId);
    }
  }, [signingId, orderRef, init, poll, setKFState]);

  return (
    <div className="create-account-corporation-lysa-sign">
      <Retry
        status={retryStatus}
        retry={() => {
          init(setKFState, poll, signingId);
        }}
      >
        <BankIDStatus
          retry={() => {
            setResponse(pendingResponse);
            init(setKFState, poll, signingId);
          }}
          response={response}
          getMessages={getBankIDMessages()}
          getPendingMessages={(hintCode) => pendingBankIDMessages[hintCode]}
          getFailedMessages={(failedHintCode) =>
            failedBankIDMessages[failedHintCode]
          }
          autostarttoken={kfState.lysaSignResponse?.autoStartToken}
        />
      </Retry>
    </div>
  );
};
