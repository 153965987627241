import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import {
  dataWithdrawals,
  PeriodicWithdrawal,
  WithdrawalAccount,
} from "../../../../data/dataWithdrawals";
import {
  WithdrawalPeriodicContext,
  WithdrawalPeriodicState,
} from "../WithdrawalPeriodicContext";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  InvestmentAccount,
  AccountType,
  dataAccounts,
} from "../../../../data/dataAccounts";
import { LysaAccountSelectionCard } from "../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";

const messages = defineMessages({
  selectAccount: {
    id: "withdrawalPeriodic.lysaAccounts.select.header",
  },
  selectAccountErrorRequired: {
    id: "withdrawalPeriodic.lysaAccounts.select.error.required",
  },
});

interface Props {
  next: () => void;
}

export const LysaAccounts: VoidFunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const withdrawalPeriodicContext = useContext(WithdrawalPeriodicContext);

  const [loading, setLoading] = useState(true);
  const [withdrawalAccounts, setWithdrawalAccounts] =
    useState<WithdrawalAccount[]>();
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();
  const [pendingPeriodicWithdrawals, setPendingPeriodicWithdrawals] = useState<
    PeriodicWithdrawal[]
  >([]);

  const onSubmit = useCallback(() => {
    if (
      formRef.current?.isValid &&
      !accountNotSelected(withdrawalPeriodicContext.state) &&
      !accountHasWithdrawal(
        pendingPeriodicWithdrawals,
        withdrawalPeriodicContext.state.lysaAccount
      ) &&
      !accountHasNoWorth(withdrawalPeriodicContext.state.lysaAccount) &&
      !accountIsKf(withdrawalPeriodicContext.state.lysaAccount)
    ) {
      next();
    }
  }, [next, pendingPeriodicWithdrawals, withdrawalPeriodicContext.state]);

  useEffect(() => {
    Promise.all([
      dataAccounts.getAccounts().then(setAccounts),
      dataWithdrawals.getWithdrawalAccounts().then(setWithdrawalAccounts),
      dataWithdrawals
        .getPendingPeriodicWithdrawals()
        .then(setPendingPeriodicWithdrawals),
    ])
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <h2>
        <FormattedMessage id="withdrawalPeriodic.lysaAccounts.header" />
      </h2>

      <LysaAccountSelectionCard
        accounts={accounts || []}
        selectedAccount={accounts?.find(
          (account) =>
            account.accountId ===
            withdrawalPeriodicContext.state.withdrawalAccount?.accountId
        )}
        onChange={(lysaAccount) => {
          const withdrawalAccount = (withdrawalAccounts || []).find(
            (withdrawalAccount) =>
              withdrawalAccount.accountId === lysaAccount.accountId
          );
          if (withdrawalAccount) {
            withdrawalPeriodicContext.setState({
              withdrawalAccount,
              lysaAccount,
            });
          }
        }}
        legend={intl.formatMessage(messages.selectAccount)}
      />
      {accountHasWithdrawal(
        pendingPeriodicWithdrawals,
        withdrawalPeriodicContext.state.lysaAccount
      ) && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="withdrawalPeriodic.lysaAccounts.snackbar.error.has-withdrawal" />
        </Snackbar>
      )}
      {accountHasNoWorth(withdrawalPeriodicContext.state.lysaAccount) && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="withdrawalPeriodic.lysaAccounts.snackbar.error.empty" />
        </Snackbar>
      )}
      {accountIsKf(withdrawalPeriodicContext.state.lysaAccount) && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage id="withdrawalPeriodic.lysaAccounts.snackbar.error.accountType" />
        </Snackbar>
      )}
      <Button
        block
        type="submit"
        label={<FormattedMessage id="withdrawalPeriodic.lysaAccounts.next" />}
      />
    </Form>
  );
};

function accountNotSelected(withdrawalPeriodicState: WithdrawalPeriodicState) {
  return (
    !withdrawalPeriodicState.lysaAccount ||
    !withdrawalPeriodicState.withdrawalAccount
  );
}

function accountHasWithdrawal(
  pendingPeriodicWithdrawals: PeriodicWithdrawal[],
  account?: InvestmentAccount
) {
  return !!pendingPeriodicWithdrawals.find(
    (withdrawal) => withdrawal.accountId === account?.accountId
  );
}

function accountHasNoWorth(account?: InvestmentAccount) {
  return account?.worth === 0;
}

function accountIsKf(account?: InvestmentAccount) {
  return account?.type === AccountType.DANICA_KF;
}
