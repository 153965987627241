import { LysaCountry } from "@lysaab/countries";
import { Card, Checkbox, DocModalLink, RequiredValidator } from "@lysaab/ui-2";
import React, { useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { UserContext } from "../../../../../../context/UserContext";
import { useMonthlyContext } from "../../MonthlyContext";

import "./MandateConfirm.scss";

const messages = defineMessages({
  acceptTerms: {
    id: "deposits.monthly.story.confirm.mandateConfirm.terms-accept",
  },
  termsRequired: {
    id: "deposits.monthly.story.confirm.mandateConfirm.terms-required",
  },
  modalAnnouncement: {
    id: "deposits.monthly.story.confirm.mandateConfirm.modalAnnouncement",
  },
});

const mandateDocument: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "",
  [LysaCountry.DENMARK]: "",
  [LysaCountry.FINLAND]: "legal/all/en/sepa-direct-debit-mandate.md",
  [LysaCountry.GERMANY]: "legal/de/de/sepa-direct-debit-mandate-GE.md",
  [LysaCountry.SPAIN]: "",
};

export const MandateConfirm = () => {
  const intl = useIntl();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const monthlyContext = useMonthlyContext();
  const { state: user } = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);

  if (typeof monthlyContext.state.externalAccount === "undefined") {
    return null;
  }

  const varValues: Record<string, string> = {
    CUSTOMER_NAME: user.name ?? "",
    CUSTOMER_TIN: user.tin ?? "",
    ACCOUNT_NUMBER: monthlyContext.state.externalAccount,
  };

  return (
    <Card className="mandate-confirm">
      <p>
        <TranslatedText
          id="deposits.monthly.story.confirm.mandateConfirm.details"
          values={{
            link: (text: string) => (
              <DocModalLink
                document={
                  mandateDocument[
                    localizationContext.state.country || LysaCountry.FINLAND
                  ]
                }
                modalAnnouncement={intl.formatMessage(
                  messages.modalAnnouncement
                )}
                variableValues={varValues}
                className="mandate-confirm__doc-link"
              >
                <span className="lysa-link">{text}</span>
              </DocModalLink>
            ),
          }}
        />
      </p>
      <Checkbox
        alternative={{
          text: intl.formatMessage(messages.acceptTerms),
          value: true,
        }}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.termsRequired)),
        ]}
        checked={acceptedTerms}
        onChange={() => {
          setAcceptedTerms(!acceptedTerms);
        }}
      />
    </Card>
  );
};
