import { Button } from "@lysaab/ui-2";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { getNavLink } from "../../../hooks/useCountryUrls";
import {
  AccountSituationInstanceLocationState,
  ACCOUNT_SITUATION_EDIT_PAGE,
} from "../../accountSituation/AccountSituationStory";

export const UpdateSavingsHorizonButton: React.VFC = () => {
  const params = useParams<{ accountId: InvestmentAccountId }>();
  const { accountId } = params;

  const link = generatePath(getNavLink(ACCOUNT_SITUATION_EDIT_PAGE), {
    accountId,
  });

  return (
    <Link<AccountSituationInstanceLocationState>
      to={{
        pathname: link,
        state: { returnUrl: window.location.pathname },
      }}
    >
      <Button
        block
        label={
          <TranslatedText id="edit-allocation.investmentBasis.horizon.update.label" />
        }
      />
    </Link>
  );
};
