import React, { useContext, useCallback, useState, useEffect } from "react";
import { KFAccountContext } from "../KFAccountContext";
import {
  dataCreateAccount,
  CorporateKyc,
} from "../../../../../../data/dataCreateAccount";
import {
  Card,
  InvestmentType,
  Icon,
  AllocationBarIntl,
  Button,
  RiskIndicator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { countries } from "../../../../countries";
import { savingsHorizonAlternatives } from "../horizon/HorizonLength";
import { useHistory, Redirect } from "react-router-dom";
import { Docs } from "../docs/Docs";
import "./LysaSummary.scss";
import { UserContext } from "../../../../../../context/UserContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import {
  dataDocuments,
  DocumentInformation,
} from "../../../../../../data/dataDocuments";
import {
  AccountQuestions,
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../../../../../data/dataInvestments";

interface Props {
  lysaSignRoute: string;
}

interface MessageWithId {
  id: string;
}

const messages = defineMessages({
  kfAccountName: {
    id: "sweden.kf.summary.account-name",
  },
});

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: {
    id: "sweden.kf.summary.broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "sweden.kf.summary.sustainable",
  },
});

const KYC_VERSION = "3";

export const LysaSummary: React.FC<Props> = ({ lysaSignRoute }) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const { state: userState } = useContext(UserContext);
  const [showError, setShowError] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();
  const [documentsInformation, setDocumentsInformation] =
    useState<DocumentInformation[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then((information) => {
      setDocumentsInformation(information);
      setIsLoading(false);
    });
  }, []);

  const onClick = useCallback(() => {
    if (kfState.signingId) {
      const nextRoute = lysaSignRoute.replace(":signingId", kfState.signingId);
      history.push(nextRoute);
      return;
    }

    setShowError(false);

    const kyc: CorporateKyc = {
      insurancePurpose: kfState.insurancePurpose?.value,
      insurancePurposeDescription: kfState.insurancePurposeDescription,
      initialPremiumAmount: kfState.initialPremiumAmount?.value,
      initialPremiumApproximateAmount: kfState.initialPremiumApproximateAmount,
      yearlyPremiumAmount: kfState.yearlyPremiumAmount?.value,
      yearlyPremiumApproximateAmount: kfState.yearlyPremiumApproximateAmount,
      sourceOfFunds: kfState.sourceOfFunds.map((item) => item.value),
      sourceOfFundsOtherDescription: kfState.sourceOfFundsOtherDescription,
      typeOfBusiness: kfState.typeOfBusiness?.value,
      corporateIsPassive: kfState.corporateIsPassive?.value,
      legalEntityWith25PercentControl:
        kfState.legalEntityWith25PercentControl?.value,
      holderIsListedCorporation: kfState.holderIsListedCorporation?.value,
      holderIsMajorityOwnedByListedCorporation:
        kfState.holderIsMajorityOwnedByListedCorporation?.value,
    };

    if (kfState.legalEntityWith25PercentControl?.value) {
      kyc.controllingEntityHasSoleNaturalOwner =
        kfState.controllingEntityHasSoleNaturalOwner?.value;
    }

    const request = {
      takenRisk: kfState.takenRisk,
      needEarlier: kfState.needEarlier,
      savingsHorizon: kfState.savingsHorizon,
      investmentType: kfState.investmentType,
      insured: {
        identificationNumber: kfState.identificationNumber,
        citizenships: kfState.citizenships,
      },
      corporateKyc: kyc,
      lysaKyc: {
        version: KYC_VERSION,
        questionAnswers: [
          {
            question: "PURPOSE",
            answers: kfState.purpose.map((item) => item.value),
          },
          {
            question: "DEPOSIT_INTERVAL",
            answers: [kfState.depositInterval?.value ?? ""],
          },
          {
            question: "WITHDRAWAL_INTERVAL",
            answers: [kfState.withdrawalInterval?.value ?? ""],
          },
        ],
      },
      accountName: intl.formatMessage(messages.kfAccountName),
    };

    const data: Partial<AccountQuestions> = getAccountQuestions(kfState);

    if (!isValidAccountQuestions(data)) {
      throw new Error("LysaSummary - Invalid ESG data");
    }

    dataCreateAccount
      .createKFAccount({ ...request, ...data })
      .then((data) => {
        setKFState({ signingId: data.signingId });
        const nextRoute = lysaSignRoute.replace(":signingId", data.signingId);
        history.push(nextRoute);
      })
      .catch(() => {
        setShowError(true);
      });
  }, [kfState, setKFState, lysaSignRoute, history, intl]);

  if (typeof kfState.takenRisk === "undefined") {
    return (
      <Redirect
        to={{ pathname: getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL) }}
      />
    );
  }

  if (isLoading || typeof documentsInformation === "undefined") {
    return <Spinner />;
  }

  return (
    <div className="create-account-corporation-lysa-summary">
      <h1>
        <TranslatedText id="sweden.kf.summary.header" />
      </h1>
      <Docs
        name={userState.name}
        investmentType={kfState.investmentType || InvestmentType.BROAD}
        risk={kfState.takenRisk || 0}
        documentsInformation={documentsInformation}
      />
      <Card>
        <h4>
          <Icon.Summary />
          <TranslatedText id="sweden.kf.summary.card-header" />
        </h4>

        <dl>
          <dt>
            <TranslatedText id="sweden.kf.summary.account-type-label" />
          </dt>
          <dd>
            <TranslatedText id="sweden.kf.summary.account-type-value" />
          </dd>
          <dt>
            <TranslatedText id="sweden.kf.summary.investment-type-label" />
          </dt>
          <dd className="create-account-corporation-lysa-summary-risk-indicator">
            {kfState.investmentType &&
              intl.formatMessage(
                investmentTypeMessages[kfState.investmentType]
              )}
            <RiskIndicator
              risk={kfState.takenRisk || 0}
              investmentType={kfState.investmentType || InvestmentType.BROAD}
            />
          </dd>
          {kfState.ongoing ? null : (
            <>
              <dt>
                <TranslatedText id="sweden.kf.summary.horizon-label" />
              </dt>
              <dd>
                {
                  savingsHorizonAlternatives.find(
                    (item) => item.value === kfState.savingsHorizon
                  )?.text
                }
              </dd>
            </>
          )}
          <dt>
            <TranslatedText id="sweden.kf.summary.risk-label" />
          </dt>
          <dd className="risk-indicator-container">
            <AllocationBarIntl
              messages={{
                stocks: (
                  <TranslatedText
                    id="sweden.kf.summary.stocks-label"
                    values={{
                      stocks: intl.formatNumber(kfState.takenRisk / 100, {
                        style: "percent",
                      }),
                    }}
                  />
                ),
                bonds: (
                  <TranslatedText
                    id="sweden.kf.summary.bonds-label"
                    values={{
                      bonds: intl.formatNumber(1 - kfState.takenRisk / 100, {
                        style: "percent",
                      }),
                    }}
                  />
                ),
              }}
              risk={kfState.takenRisk || 0}
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText id="sweden.kf.summary.provider-label" />
          </dt>
          <dd>
            <TranslatedText id="sweden.kf.summary.provider-value" />
          </dd>
          <dt>
            <TranslatedText id="sweden.kf.summary.fee-label" />
          </dt>
          <dd>
            <TranslatedText id="sweden.kf.summary.fee-value" />
          </dd>
          {kfState.ongoing ? null : (
            <>
              <dt>
                <TranslatedText id="sweden.kf.summary.insured" />
              </dt>
              <dd>{kfState.identificationNumber}</dd>
              {kfState.citizenships.map((country, idx) => {
                return (
                  <React.Fragment key={country}>
                    <dt>
                      {idx === 0 ? (
                        <TranslatedText id="sweden.kf.summary.citizenship" />
                      ) : null}
                    </dt>
                    <dd>
                      {countries.find((item) => item.value === country)?.label}
                    </dd>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </dl>
      </Card>

      {kfState.ongoing ? null : (
        <Card>
          <p>
            <TranslatedText id="sweden.kf.summary.text-1" />
          </p>
          <p>
            <TranslatedText id="sweden.kf.summary.text-2" />
          </p>
        </Card>
      )}

      {showError ? (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <TranslatedText id="sweden.kf.summary.error" />
          </div>
        </Snackbar>
      ) : null}

      <Button
        block
        onClick={onClick}
        label={
          showError ? (
            <TranslatedText id="sweden.kf.summary.retry" />
          ) : (
            <TranslatedText id="sweden.kf.summary.sign" />
          )
        }
      />
    </div>
  );
};
