import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { dataBanks } from "../../../../../../data/dataBanks";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Disclaimer } from "./Disclaimer";
import { ROUTES } from "./WithdrawalKfRequestPage";
import { Retry, Status } from "../../../../../../components/retry/Retry";
import { KfWithdrawalContext } from "./KfWithdrawalContext";
import { UserContext } from "../../../../../../context/UserContext";
import {
  dataDanica,
  ExternalKFAccount,
} from "../../../../../../data/dataDanica";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { dataWithdrawals } from "../../../../../../data/dataWithdrawals";

const messages = defineMessages({
  required: {
    id: "withdrawalRequest.kf.to.required",
  },
});

export const To: React.FunctionComponent = () => {
  const formRef = React.useRef<LysaFormRef>();
  const history = useHistory();
  const intl = useIntl();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [externalKFAccounts, setExternalKFAccounts] = useState<
    ExternalKFAccount[]
  >([]);
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const { state: kfWithdrawalState, setState: setKfWithdrawalState } =
    useContext(KfWithdrawalContext);
  const [hasUnapprovedKfExternalAccount, setHasUnapprovedKfExternalAccount] =
    useState(false);

  const load = useCallback(() => {
    Promise.all([
      dataDanica.getExternalKFAccounts(),
      dataWithdrawals.getExternalAccounts(),
    ])
      .then(([requestExternalKfAccounts, externalAccounts]) => {
        const hasUnapprovedWithdrawalAccount = externalAccounts.some(
          (externalAccount) => {
            const foundExternalKfAccount = requestExternalKfAccounts.find(
              (externalKfAccount) =>
                externalAccount.externalBankAccount ===
                externalKfAccount.externalBankAccount
            );
            return typeof foundExternalKfAccount === "undefined" ? true : false;
          }
        );
        /* Futur must approve an external account before a withdrawal from a Kf can be made */
        setHasUnapprovedKfExternalAccount(hasUnapprovedWithdrawalAccount);
        setExternalKFAccounts(requestExternalKfAccounts);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  const next = useCallback(
    (event) => {
      event.preventDefault();

      if (!formRef.current?.isValid) {
        return;
      }

      history.push(getNavLink(ROUTES.TRANSACTION));
    },
    [history]
  );

  if (!kfWithdrawalState.from) {
    return <Redirect to={getNavLink(ROUTES.FROM)} />;
  }

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  const alternatives: Alternative<string>[] = [];
  let selectedWithdrawalAccount;

  externalKFAccounts.forEach((externalKFAccount) => {
    const alt = {
      value: externalKFAccount.externalBankAccount,
      text: `${dataBanks.getBank(externalKFAccount.bank).short} - ${
        externalKFAccount.externalBankAccount
      }`,
    };

    if (
      externalKFAccount.externalBankAccount ===
      kfWithdrawalState.to?.externalBankAccount
    ) {
      selectedWithdrawalAccount = alt;
    }

    alternatives.push(alt);
  });

  return (
    <div className="withdrawal-request-page-from">
      <h1>
        <TranslatedText id="withdrawalRequest.to.header" />
      </h1>
      {hasUnapprovedKfExternalAccount && (
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <TranslatedText id="withdrawalRequest.kf.to.hasUnapprovedKfExternalAccount" />
        </Snackbar>
      )}
      <Card>
        <Retry status={status} retry={retry}>
          <TranslatedText id="withdrawalRequest.to.accountSelection" />
          <Form lysaFormRef={formRef} onSubmit={next}>
            <RadioGroup
              header=""
              onChange={(ev) => {
                setKfWithdrawalState({
                  to: externalKFAccounts.find(
                    (item) => item.externalBankAccount === ev.value
                  ),
                });
              }}
              value={selectedWithdrawalAccount}
              alternatives={alternatives}
              validators={[
                new RequiredValidator(intl.formatMessage(messages.required)),
              ]}
            />

            <Button
              block
              type="submit"
              label={<TranslatedText id="withdrawalRequest.kf.to.next" />}
            />
          </Form>
        </Retry>
      </Card>
      <Disclaimer />
    </div>
  );
};
