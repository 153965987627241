import React, { useContext } from "react";
import { InvestmentAccount } from "../../data/dataAccounts";
import { CardList } from "@lysaab/ui-2";
import { CardBottom } from "./CardBottom";
import { FormattedMessage } from "react-intl";
import { AccountType } from "../../components/accountType/AccountType";
import "./Docs.scss";
import { InvestmentType } from "../../components/investmentType/InvestmentType";
import { Link } from "react-router-dom";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  getAccountAgreementLink,
  getAccountDocumentRoute,
  getInvestmentGuidelinesLink,
} from "../documents/DocumentLists";
import { useAccountAllocation } from "../../hooks/useAccountAllocation";

interface Props {
  account?: InvestmentAccount;
}

export function Docs({ account }: Props) {
  const localizationContext = useContext(LocalizationContext);
  const accountAllocation = useAccountAllocation(account?.accountId);

  if (!accountAllocation) {
    return null;
  }
  return (
    <section className="account-page-docs">
      <h2>
        <FormattedMessage
          id="accountPage.docs.header"
          description="Account page docs - header"
          defaultMessage="Documents"
        />
      </h2>
      <CardList>
        {account && localizationContext.state.country ? (
          <React.Fragment>
            <div className="account-page-card-body">
              <>
                <div className="account-page-doc">
                  <Link
                    to={getAccountAgreementLink(
                      account.accountId,
                      account.type,
                      localizationContext.state.country
                    )}
                  >
                    <FormattedMessage
                      id="accountPage.docs.customer"
                      description="Account page docs - customer agreement ingress"
                      defaultMessage="Agreement for"
                    />{" "}
                    <AccountType account={account} long />
                  </Link>
                </div>
                <div className="account-page-doc">
                  <Link
                    to={getInvestmentGuidelinesLink(
                      account.accountId,
                      account.type,
                      localizationContext.state.country
                    )}
                  >
                    <FormattedMessage
                      id="accountPage.docs.guidelines"
                      description="Account page docs - customer guidelines"
                      defaultMessage="Guidelines"
                    />{" "}
                    - {accountAllocation.takenRisk} %{" "}
                    <FormattedMessage
                      id="accountPage.docs.stocks"
                      description="Account page docs - stocks"
                      defaultMessage="stocks"
                    />
                    , {100 - accountAllocation.takenRisk} %{" "}
                    <FormattedMessage
                      id="accountPage.docs.bonds"
                      description="Account page docs - bonds"
                      defaultMessage="bonds"
                    />
                    ,{" "}
                    <InvestmentType
                      capitalize
                      investmentType={accountAllocation.investmentType}
                    />
                  </Link>
                </div>
              </>
            </div>
            <CardBottom>
              <Link
                to={getAccountDocumentRoute(
                  account.accountId,
                  account.type,
                  localizationContext.state.country
                )}
              >
                <FormattedMessage
                  id="accountPage.docs.show"
                  description="account page docs - show all docs"
                  defaultMessage="Show all docs"
                />
              </Link>
            </CardBottom>
          </React.Fragment>
        ) : null}
      </CardList>
    </section>
  );
}
