import React, { ElementType, FunctionComponent } from "react";
import { AlignValues } from "../gridRow/GridRow";
import cx from "classnames";
import "./GridCol.scss";

type allowedColSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface Props {
  /**
   * @description Starts at 0px width
   */
  xsmall?: allowedColSize;
  /**
   * @description Matches landscape scss variable
   */
  small?: allowedColSize;
  /**
   * @description Matches tablet scss variable
   */
  medium?: allowedColSize;
  /**
   * @description Matches desktop scss variable
   */
  large?: allowedColSize;
  /**
   * @description Matches desktop-plus scss variable
   */
  xlarge?: allowedColSize;
  print?: allowedColSize;
  noPrint?: boolean;
  as?: ElementType;
  alignSelf?: AlignValues;
  className?: string;
  disabledFlexGrow?: boolean;
}

export const GridCol: FunctionComponent<Props> = ({
  xsmall,
  small,
  medium,
  large,
  xlarge,
  print,
  noPrint,
  children,
  as: Component = "div",
  alignSelf,
  className,
  disabledFlexGrow,
}) => {
  return (
    <Component
      className={cx(
        "lysa-ui-col",
        "col",
        className,
        {
          [`col-${xsmall}`]: xsmall,
          [`col-sm-${small}`]: small,
          [`col-md-${medium}`]: medium,
          [`col-lg-${large}`]: large,
          [`col-xl-${xlarge}`]: xlarge,
          [`disabled-flex-grow`]: !!disabledFlexGrow,
          [`col-print-${print}`]: print,
          [`no-print`]: noPrint,
        },
        {
          "align-self-start": alignSelf === AlignValues.START,
          "align-self-end": alignSelf === AlignValues.END,
          "align-self-center": alignSelf === AlignValues.CENTER,
          "align-self-baseline": alignSelf === AlignValues.BASELINE,
          "align-self-stretch": alignSelf === AlignValues.STRETCH,
        }
      )}
    >
      {children}
    </Component>
  );
};
