import { API } from "@lysaab/ui-2/network/API";
import { cache } from "@lysaab/ui-2/network/Cache";
import { LegalEntityId } from "./dataUser";
import { FundType, Isin } from "./dataFunds";
import { AccountsUsed } from "../Store";
import {
  AccountQuestions,
  AccountUpdatableStatus,
  isValidAccountQuestions,
  RiskImportance,
  RiskReaction,
  RiskPropensity,
} from "./dataInvestments";
import { encode } from "@lysaab/ui-2";
import { Language } from "../context/LocalizationContext";
import { DepositInterval, Purpose, WithdrawalInterval } from "./dataKyc";
import { IntlShape } from "react-intl";
import { ClosedAccount } from "./dataProfile";

export type InvestmentAccountId = string & {
  readonly isInvestmentAccountId: true;
};
export type SavingsAccountId = string & { readonly isSavingsAccountId: true };
export type CreationId = string & { readonly isCreationId: true };

export type CompoundAccountId = InvestmentAccountId | SavingsAccountId;
export type CompoundAccount = InvestmentAccount | SavingsAccount;

export enum AccountType {
  ISK_SWE = "ISK_SWE",
  VP_SWE = "VP_SWE",
  DANICA_KF = "DANICA_KF",
  VP = "VP",
  TJP_SWE = "TJP_SWE",
  KF_SWE = "KF_SWE",
}

export enum InvestmentType {
  SUSTAINABLE = "SUSTAINABLE",
  BROAD = "BROAD",
}

export enum AccountOrder {
  CREATED = "CREATED",
  NAME = "NAME",
  USED = "USED",
}

export enum AccountOrderDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}
export interface AccountKyc {
  version: "3";
  questionAnswers: [
    {
      question: "PURPOSE";
      answers: Purpose[];
    },
    {
      question: "DEPOSIT_INTERVAL";
      answers: [DepositInterval];
    },
    {
      question: "WITHDRAWAL_INTERVAL";
      answers: [WithdrawalInterval];
    }
  ];
}

export interface BaseAccount {
  legalEntityId: LegalEntityId;
  created: string;
  name: string;
  isSharedAccount?: boolean;
}

export interface InvestmentAccount extends BaseAccount {
  accountId: InvestmentAccountId;
  uninvestedMoney: number;
  type: AccountType;
  worth: number;
  actualRisk?: number;
  positions: Record<Isin, AccountPosition>;
}

export interface AccountPosition {
  worth: number;
  gav: number;
  volume: number;
  isin: Isin;
  type: FundType;
}

export interface SavingsAccount extends BaseAccount {
  accountId: SavingsAccountId;
  customerType: "PRIVATE" | "COMPANY";
  totalBalance: number;
  savingsAccountDistributions: SavingsAccountDistributionResponse[];
}

interface SavingsAccountDistributionResponse {
  externalSavingsAccountId: ExternalSavingsAccountId;
  amount: number;
}

export type AddAccountRequest = {
  accountName: string;
  kyc: {
    accountKyc: AccountKyc;
  };
  advice: {
    language: Language;
    takenRisk: number;
  } & AccountQuestions;
};

export function isValidAddAccountAdvice(
  data: Partial<AddAccountRequest["advice"]>
): data is AddAccountRequest["advice"] {
  if (typeof data.language === "undefined") {
    return false;
  }

  if (typeof data.takenRisk === "undefined") {
    return false;
  }

  if (!isValidAccountQuestions(data)) {
    return false;
  }

  return true;
}

export interface RecalculateRequest {
  financial: {
    liquidAssets: number;
    otherAssets: number;
    debts: number;
    monthlyEarnings: number;
    monthlyPayments: number;
  };
  risk: [RiskImportance, RiskReaction, RiskPropensity];
}

export interface RecalcuateResponse {
  advice: number;
  updatable: AccountUpdatableStatus;
  accountId: InvestmentAccountId;
  takenRisk: number;
  oldAdvice: number;
  /**
   * ISO timestamp */
  oldAdviceCreated: string;
}

export enum ExternalSavingsAccountId {
  COLLECTOR = "COLLECTOR",
  BORGO = "BORGO",
  DANSKE_CLIENT_FUNDS = "DANSKE_CLIENT_FUNDS",
}

export interface AllAccountResponse {
  investmentAccounts: InvestmentAccount[];
  savingsAccounts: SavingsAccount[];
  sharedInvestmentAccounts: InvestmentAccount[];
  sharedSavingsAccounts: SavingsAccount[];
}

export const GET_ACCOUNTS_URL = "/accounts";

export const dataAccounts = {
  getAccounts: () => {
    return dataAccounts
      .getAllAccounts()
      .then((response) => response.investmentAccounts);
  },

  getAllAccounts: () => {
    return API.get<AllAccountResponse>("/accounts/all").then((accounts) => {
      return {
        ...accounts,
        sharedInvestmentAccounts: accounts.sharedInvestmentAccounts.map(
          (account) => ({
            ...account,
            isSharedAccount: true,
          })
        ),
        sharedSavingsAccounts: accounts.sharedSavingsAccounts.map(
          (account) => ({
            ...account,
            isSharedAccount: true,
          })
        ),
      };
    });
  },

  clearGetAccounts: () => {
    cache.delete(GET_ACCOUNTS_URL);
  },

  isInsuranceAccount: (account?: InvestmentAccount) => {
    if (!account) {
      return false;
    }
    return account.type === AccountType.DANICA_KF;
  },

  updateAccountName: (accountId: CompoundAccountId, name: string) => {
    return API.post(encode`/accounts/all/${accountId}/rename`, { name }).then(
      (response) => {
        cache.delete("/accounts/all");
        return response;
      }
    );
  },

  accountSort: (
    accounts: CompoundAccount[],
    sortType: AccountOrder,
    accountOrderDirection: AccountOrderDirection,
    accountsUsed: AccountsUsed = {}
  ) => {
    switch (sortType) {
      case AccountOrder.NAME: {
        return accounts.sort((a, b) => {
          if (accountOrderDirection === AccountOrderDirection.ASCENDING) {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        });
      }
      case AccountOrder.USED: {
        return accounts.sort((a, b) => {
          const dateA = accountsUsed[a.accountId];
          const dateB = accountsUsed[b.accountId];

          if (accountOrderDirection === AccountOrderDirection.ASCENDING) {
            if (!dateB) {
              return -1;
            }
            if (!dateA) {
              return 1;
            }
            return new Date(dateB).getTime() - new Date(dateA).getTime();
          } else {
            if (!dateB) {
              return 1;
            }
            if (!dateA) {
              return -1;
            }
            return new Date(dateA).getTime() - new Date(dateB).getTime();
          }
        });
      }
      default: {
        return accounts.sort((a, b) => {
          if (accountOrderDirection === AccountOrderDirection.ASCENDING) {
            return (
              new Date(a.created).getTime() - new Date(b.created).getTime()
            );
          } else {
            return (
              new Date(b.created).getTime() - new Date(a.created).getTime()
            );
          }
        });
      }
    }
  },

  addAccount: (data: AddAccountRequest) => {
    return API.post<{ creationId: CreationId }>("/accounts/advice/new", data);
  },

  pollAddAccount: (creationId: CreationId) => {
    return API.get<{ complete: boolean }>(
      encode`/accounts/advice/new/${creationId}`
    ).then((resp) => {
      /**
       * This call will generate a new JWT with the new account.
       * Because of this, calls in almost all services could change
       * their responses to contain the new account and therefore we
       * clear the entire cache.
       */
      cache.clear();
      return resp;
    });
  },
};

export function getAccountLabel(
  account: InvestmentAccount,
  intl: IntlShape,
  currency: string
) {
  return `${account.name} - ${intl.formatNumber(account.worth, {
    currency: currency,
    style: "currency",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}

export const isInvestmentAccount = (
  account: unknown
): account is InvestmentAccount => {
  return (
    typeof account === "object" &&
    account !== null &&
    typeof (account as InvestmentAccount).type !== "undefined" &&
    typeof (account as ClosedAccount).closed === "undefined"
  );
};

export const isSavingsAccount = (
  account: unknown
): account is SavingsAccount => {
  return (
    typeof account === "object" &&
    account !== null &&
    typeof (account as SavingsAccount).savingsAccountDistributions !==
      "undefined"
  );
};

export const getAccountAmount = (account: CompoundAccount) => {
  return isInvestmentAccount(account) ? account.worth : account.totalBalance;
};
