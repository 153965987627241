import { CardListItem, Spinner } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Amount } from "../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  ExternalSavingsAccountId,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import { useLoadingContext } from "../../../../../context/LoadingContext";
import { dataSavingsAccountDistribution } from "../../../../../data/dataSavingsAccountDistribution";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";

import "./InstitutionAllocationCard.scss";

interface MessageWithId {
  id: string;
}

type ExternalSavingsAccountIdsWithoutDanske = Exclude<
  ExternalSavingsAccountId,
  ExternalSavingsAccountId.DANSKE_CLIENT_FUNDS
>;

const messages = defineMessages<
  ExternalSavingsAccountIdsWithoutDanske,
  MessageWithId,
  Record<ExternalSavingsAccountIdsWithoutDanske, MessageWithId>
>({
  [ExternalSavingsAccountId.COLLECTOR]: {
    id: "savingsAccountPage.institutionAllocationCard.collector",
  },
  [ExternalSavingsAccountId.BORGO]: {
    id: "savingsAccountPage.institutionAllocationCard.borgo",
  },
});

const transactionMessages = defineMessages({
  ongoingDeposit: {
    id: "savingsAccountPage.transaction.deposit",
  },
});

export const InstitutionAllocationCard = ({
  account,
}: {
  account: SavingsAccount;
}) => {
  const intl = useIntl();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [pendingDepositAmount, setPendingDepositAmount] = useState(0);

  useEffect(() => {
    dataSavingsAccountDistribution
      .getPendingTransactions(account.accountId)
      .then((transactions) => {
        const accumulatedDeposits = transactions.reduce(
          (accumulatedAmounts, transaction) => {
            if (
              transaction.sendingAccount ===
              ExternalSavingsAccountId.DANSKE_CLIENT_FUNDS
            ) {
              return accumulatedAmounts + transaction.amount;
            }
            return accumulatedAmounts;
          },
          0
        );
        setPendingDepositAmount(accumulatedDeposits);
      })
      .finally(() => setIsLoading(false));
  }, [account.accountId, setIsLoading]);

  if (isLoading) {
    return <Spinner />;
  }
  if (typeof account === "undefined") return null;

  const isMoneyOnCollector = account.savingsAccountDistributions.find(
    (institution) =>
      institution.externalSavingsAccountId ===
      ExternalSavingsAccountId.COLLECTOR
  );
  const isMoneyOnBorgo = account.savingsAccountDistributions.find(
    (institution) =>
      institution.externalSavingsAccountId === ExternalSavingsAccountId.BORGO
  );
  return (
    <AccountPageCard isCardList={true} className="institution-allocation-card">
      <p className="institution-allocation-card__header">
        <TranslatedText id="savingsAccountPage.institutionAllocationCard.header" />
      </p>
      <dl>
        {account.savingsAccountDistributions.map((institution) => {
          if (
            institution.externalSavingsAccountId ===
            ExternalSavingsAccountId.DANSKE_CLIENT_FUNDS
          ) {
            return null;
          }
          return (
            <CardListItem key={institution.externalSavingsAccountId}>
              <div className="institution-allocation-card__list-item">
                <dt>
                  {intl.formatMessage(
                    messages[institution.externalSavingsAccountId]
                  )}
                </dt>
                <dd>
                  <Amount amount={institution.amount} decimals={2} />
                </dd>
              </div>
            </CardListItem>
          );
        })}
        {!isMoneyOnCollector && (
          <CardListItem key={ExternalSavingsAccountId.COLLECTOR}>
            <div className="institution-allocation-card__list-item">
              <dt>
                {intl.formatMessage(
                  messages[ExternalSavingsAccountId.COLLECTOR]
                )}
              </dt>
              <dd>
                <Amount amount={0} decimals={2} />
              </dd>
            </div>
          </CardListItem>
        )}
        {!isMoneyOnBorgo && (
          <CardListItem key={ExternalSavingsAccountId.BORGO}>
            <div className="institution-allocation-card__list-item">
              <dt>
                {intl.formatMessage(messages[ExternalSavingsAccountId.BORGO])}
              </dt>
              <dd>
                <Amount amount={0} decimals={2} />
              </dd>
            </div>
          </CardListItem>
        )}
        {pendingDepositAmount > 0 && (
          <CardListItem>
            <div className="institution-allocation-card__list-item">
              <dt>{intl.formatMessage(transactionMessages.ongoingDeposit)}</dt>
              <dd>
                <Amount amount={pendingDepositAmount} decimals={2} />
              </dd>
            </div>
          </CardListItem>
        )}
        <CardListItem>
          <div className="institution-allocation-card__list-item">
            <dt className="institution-allocation-card__bold-term">
              <TranslatedText id="savingsAccountPage.institutionAllocationCard.total" />
            </dt>
            <dd>
              <Amount amount={account.totalBalance} decimals={2} />
            </dd>
          </div>
        </CardListItem>
      </dl>
    </AccountPageCard>
  );
};
