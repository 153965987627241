import React from "react";
import { InvestmentAccount, CompoundAccount } from "../../data/dataAccounts";
import { Button } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import "./AccountActions.scss";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { WITHDRAWAL_PAGE_URL } from "../withdrawal/overview/WithdrawalPage";
import { Link } from "react-router-dom";
import { INTERNAL_TRANSFER_PAGE_URL } from "../internalTransfer/InternalTransferPage";
import { AnalyticsEvent, Tracker } from "../../utils/Tracker";
import { DEPOSITS_OVERVIEW_URL } from "../deposits/overview/Recommendation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../data/dataCustomerTracking";

interface Props {
  account: InvestmentAccount | undefined;
  accounts: CompoundAccount[];
}

const messages = defineMessages({
  deposits: {
    id: "account.cta.deposits",
    defaultMessage: "Deposit",
  },
  internal: {
    id: "account.cta.internal",
    defaultMessage: "Internal transfer",
  },
  withdrawal: {
    id: "account.cta.withdrawal",
    defaultMessage: "Withdrawal",
  },
});

export const AccountActions: React.FC<Props> = ({ account, accounts }) => {
  const intl = useIntl();

  return (
    <div className={"account-actions"}>
      <div className="account-actions-buttons">
        <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
          <Button
            component={Link}
            className="action-deposit"
            to={{
              pathname: getNavLink(DEPOSITS_OVERVIEW_URL),
              state: {
                accountId: account?.accountId,
                returnUrl: window.location.pathname,
              },
            }}
            onClick={() => {
              Tracker.event({
                event: AnalyticsEvent.BUTTON_EVENT,
                buttonName: "ACCOUNT_PAGE_DEPOSIT_LINK",
              });
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.INVESTMENT_ACCOUNT,
                eventName: "clickedAccountDepositButton",
                subDomain: SubDomain.ACCOUNT_DETAILS,
              });
            }}
            label={intl.formatMessage(messages.deposits)}
            icon="Deposit"
            block
          />
        </RouteAwareToggle>

        {accounts.length > 1 ? (
          <RouteAwareToggle path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
            <Button
              component={Link}
              variant="secondary"
              to={{
                pathname: getNavLink(INTERNAL_TRANSFER_PAGE_URL),
                state: { accountId: account?.accountId },
              }}
              className="action-internal"
              onClick={() => {
                Tracker.event({
                  event: AnalyticsEvent.BUTTON_EVENT,
                  buttonName: "ACCOUNT_PAGE_INTERNAL_TRANSFER_LINK",
                });
              }}
              label={intl.formatMessage(messages.internal)}
              icon="Transfer"
              block
            />
          </RouteAwareToggle>
        ) : null}
        <RouteAwareToggle path={getNavLink(WITHDRAWAL_PAGE_URL)}>
          <Button
            component={Link}
            className="action-withdrawal"
            variant="secondary"
            to={{
              pathname: getNavLink(WITHDRAWAL_PAGE_URL),
              state: { accountId: account?.accountId },
            }}
            onClick={() => {
              Tracker.event({
                event: AnalyticsEvent.BUTTON_EVENT,
                buttonName: "ACCOUNT_PAGE_WITHDRAWAL_LINK",
              });
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.INVESTMENT_ACCOUNT,
                eventName: "clickedAccountWithdrawalButton",
                subDomain: SubDomain.ACCOUNT_DETAILS,
              });
            }}
            label={intl.formatMessage(messages.withdrawal)}
            icon="Withdraw"
            block
          />
        </RouteAwareToggle>
      </div>
    </div>
  );
};
