import React, { useCallback } from "react";
import { PageStripped } from "./PageStripped";
import { OVERVIEW_PAGE_URL } from "./overview/OverviewPage";
import { PageHeader } from "./Page";
import { FormattedMessage } from "react-intl";
import { Button } from "@lysaab/ui-2";
import { getNavLink } from "../hooks/useCountryUrls";
import "./OfflinePage.scss";

export const OFFLINE_URL = "/offline";

export function OfflinePage() {
  const onReload = useCallback(() => {
    window.location.href = getNavLink(OVERVIEW_PAGE_URL);
  }, []);

  return (
    <PageStripped>
      <div className="offline-page">
        <PageHeader>
          <h1>
            <FormattedMessage
              id="offline.header"
              description="headline to offline page"
              defaultMessage="Oops!"
            />
          </h1>
        </PageHeader>
        <p>
          <FormattedMessage
            id="offline.body"
            description="body for offline page"
            defaultMessage="It's seems like your connection is down"
          />
        </p>

        <Button
          size="small"
          onClick={onReload}
          label={
            <FormattedMessage
              id="offline.reload"
              description="offline page reload"
              defaultMessage="Try again"
            />
          }
        />
      </div>
    </PageStripped>
  );
}
