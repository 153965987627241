import {
  API,
  PendingHintCode,
  FailedHintCode,
  Status,
  encode,
  cache,
} from "@lysaab/ui-2";

const BASE_URL = "/bankid";

export type OrderRef = string & { readonly isOrderRef: true };

export interface BankidInitResponse {
  status: Status;
  hintCode?: PendingHintCode | FailedHintCode;
  orderRef?: OrderRef;
  autoStartToken?: string;
}
export interface QrCode {
  code: Status;
}

export interface BankidPollResponse {
  status: Status;
  hintCode?: PendingHintCode | FailedHintCode;
}

export const dataBankid = {
  login: () => {
    return API.post<BankidInitResponse>(
      BASE_URL + encode`/login?hash=${process.env.REACT_APP_GIT_HASH}`
    );
  },
  qrCode: (orderRef: OrderRef) => {
    return API.get<QrCode>(BASE_URL + encode`/login/qr/${orderRef}`).then(
      (resp) => {
        cache.clear();
        return resp;
      }
    );
  },
  loginPoll: (orderRef: OrderRef) => {
    return API.get<BankidPollResponse>(
      BASE_URL +
        encode`/login/${orderRef}?hash=${process.env.REACT_APP_GIT_HASH}`,
      true
    );
  },
};
