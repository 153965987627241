import {
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2/hooks/useValidation";
import { BaseValidator } from "@lysaab/ui-2/validators/BaseValidator";

const MAX_LENGTH = 50;

export class TooLongValidator extends BaseValidator {
  name = "TooLongValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length > MAX_LENGTH) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
        return;
      }
      resolve({ status: VALIDATION_STATE.SUCCESS });
    });
  }
}
