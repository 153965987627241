import React, { useState, useEffect, useContext, useRef } from "react";
import {
  dataAccounts,
  SavingsAccountId,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import {
  Form,
  TextInput,
  Snackbar,
  SNACKBAR_TYPES,
  LysaFormRef,
  RequiredValidator,
  Button,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { UserContext } from "../../../../../context/UserContext";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { AccountPageCard } from "../accountPageCard/AccountPageCard";
import { AnimatePresence, motion } from "framer-motion";
import { TooLongValidator } from "../../../TooLongValidator";
import { InvalidCharsValidator } from "../../../InvalidCharsValidator";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import { useLoadingContext } from "../../../../../context/LoadingContext";

import "./ChangeNameCard.scss";

interface Props {
  account: SavingsAccount;
  updateAccount: (accountId: SavingsAccountId) => void;
}

enum Status {
  DEFAULT = "DEFAULT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

const messages = defineMessages({
  empty: {
    id: "savingsAccountPage.changeName.error.empty",
  },
  long: {
    id: "savingsAccountPage.changeName.error.long",
  },
  invalid: {
    id: "savingsAccountPage.changeName.error.invalid",
  },
  name: {
    id: "savingsAccountPage.changeName.name",
  },
});

export function ChangeNameCard({ account, updateAccount: onUpdate }: Props) {
  const formRef = React.useRef<LysaFormRef>();
  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState(Status.DEFAULT);
  const { state: user } = useContext(UserContext);
  const intl = useIntl();
  const isPending = useRef(false);
  const { isLoading } = useLoadingContext(false);

  useEffect(() => {
    if (!account) {
      return;
    }
    setName(account.name);
  }, [account]);

  const setTimedStatus = (status: Status) => {
    setStatus(status);
    setTimeout(() => {
      setStatus(Status.DEFAULT);
    }, 3000);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <AccountPageCard className="change-name-card">
      <AnimatePresence exitBeforeEnter>
        {status === Status.DEFAULT && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={1}
          >
            <Form
              lysaFormRef={formRef}
              onSubmit={(event) => {
                event.preventDefault();
                if (user.readOnly || !account || isPending.current === true) {
                  return;
                }

                if (name.trim() === account.name) {
                  setTimedStatus(Status.SUCCESS);
                  return;
                }

                if (formRef.current && formRef.current.isValid) {
                  isPending.current = true;
                  dataAccounts
                    .updateAccountName(account.accountId, name)
                    .then(() => {
                      EventTracker.track({
                        event: TrackerEvent.ACCOUNT_NAME_CHANGE,
                        message: name,
                      });
                      setName(name);
                      onUpdate(account.accountId);
                      setTimedStatus(Status.SUCCESS);
                    })
                    .catch(() => {
                      setTimedStatus(Status.ERROR);
                    })
                    .finally(() => {
                      isPending.current = false;
                    });
                }
              }}
            >
              <TextInput
                value={name}
                label={intl.formatMessage(messages.name)}
                onChange={(event) => setName(event)}
                validators={[
                  new RequiredValidator(intl.formatMessage(messages.empty)),
                  new InvalidCharsValidator(
                    intl.formatMessage(messages.invalid)
                  ),
                  new TooLongValidator(intl.formatMessage(messages.long)),
                ]}
                disabled={user.readOnly ? true : false}
              />
              <Button
                size="small"
                block
                type="submit"
                label={
                  <TranslatedText id="savingsAccountPage.changeName.save" />
                }
              ></Button>
            </Form>
          </motion.div>
        )}
        {status !== Status.DEFAULT && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={2}
          >
            {status === Status.SUCCESS ? (
              <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                <TranslatedText id="savingsAccountPage.changeName.saved" />
              </Snackbar>
            ) : (
              <Snackbar type={SNACKBAR_TYPES.ERROR}>
                <TranslatedText id="savingsAccountPage.changeName.error" />
              </Snackbar>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </AccountPageCard>
  );
}
