import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import React, { useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { LysaAccountSelectionCard } from "../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { useMonthlyContext } from "../MonthlyContext";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "deposits.monthly.story.lysa-account-selection.lysa.label",
  },
  lysaAccountRequired: {
    id: "deposits.monthly.story.lysa-account-selection.lysa.required",
  },
});

export function LysaAccountSelection({ next }: Props) {
  const { accounts } = useAccounts();
  const formRef = useRef<LysaFormRef>();
  const monthlyContext = useMonthlyContext();
  const selectedLysaAccount = monthlyContext.state.selectedLysaAccount;
  const intl = useIntl();

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="deposits.monthly.story.lysa-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_ACCOUNT_CHOICE,
            message: monthlyContext.state.selectedLysaAccount?.name,
          });
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts.investmentAccounts}
          selectedAccount={selectedLysaAccount}
          onChange={(account) =>
            monthlyContext.setState({
              selectedLysaAccount: account,
            })
          }
          legend={intl.formatMessage(messages.lysaAccountLabel)}
        />
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.monthly.story.lysa-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
