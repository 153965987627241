import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  BankIDStatus,
  PendingHintCode,
  FailedHintCode,
  Status,
  BankidResponse,
} from "@lysaab/ui-2";
import { IskTransferContext } from "./IskTransferContext";
import { BASE_ROUTES, ISK_TRANSFER_URL } from "./IskTransferStory";
import { useHistory } from "react-router-dom";
import { dataIsk } from "../../../../data/dataIsk";
import { OrderRef } from "../../../../data/dataBankid";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useIntl, IntlShape } from "react-intl";
import {
  signingMessages,
  signPendingMessages,
  signFailedMessages,
} from "./BankIdMessages";
import { parse, stringify } from "query-string";
import { DateTime } from "luxon";
import { Location } from "history";

interface SearchParams {
  orderRef?: OrderRef;
  transferId?: number;
  t?: string;
}

interface Props {
  next: () => void;
}

const POLL_TIMER = 3000;
const RELOAD_TIMER = 3; // Minutes

export const IskSign: FunctionComponent<Props> = ({ next }) => {
  const iskTransferContext = useContext(IskTransferContext);
  const [bankidResponse, setBankidResponse] = useState<BankidResponse>({
    status: Status.PENDING,
    hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
  });
  const orderRef = useRef<OrderRef>();
  const transferId = useRef<number>();
  const [autostartToken, setAutostartToken] = useState<string>();
  const [started, setStarted] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const timer = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    transferId.current = iskTransferContext.state.transferId;
  }, [iskTransferContext.state.transferId]);

  const poll = useCallback(() => {
    const order = orderRef.current;
    const transfer = transferId.current;
    if (!order || !transfer) {
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      dataIsk.pollSigning(order, transfer).then((resp) => {
        setBankidResponse(resp);
        if (resp.status === Status.PENDING) {
          poll();
        } else if (resp.status === Status.COMPLETE) {
          history.replace(getUrlWithoutParams(history.location));
          next();
        }
      });
    }, POLL_TIMER);
  }, [history, next]);

  const start = useCallback(() => {
    if (!iskTransferContext.state.transferId) {
      history.push(getNavLink(ISK_TRANSFER_URL));
      return;
    }

    dataIsk.startSigning(iskTransferContext.state.transferId).then((resp) => {
      setBankidResponse(resp);
      orderRef.current = resp.orderRef;
      setAutostartToken(resp.autoStartToken);
      history.replace(
        getUrlWithParams(
          history.location,
          orderRef.current,
          iskTransferContext.state.transferId
        )
      );
      poll();
    });
  }, [history, iskTransferContext.state.transferId, poll]);

  useEffect(() => {
    const search = parse(history.location.search) as SearchParams;
    if (started) {
      return;
    }
    const diff = DateTime.local().diff(
      DateTime.fromMillis(Number(search.t)),
      "minutes"
    ).minutes;
    if (
      search.t &&
      search.orderRef &&
      search.transferId &&
      !orderRef.current &&
      diff < RELOAD_TIMER
    ) {
      setStarted(true);
      iskTransferContext.setState({ transferId: search.transferId });
      orderRef.current = search.orderRef;
      setTimeout(poll, 1000);
    } else {
      setStarted(true);
      start();
    }
  }, [history.location.search, iskTransferContext, poll, start, started]);

  return (
    <div>
      <BankIDStatus
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        retry={() => start()}
        response={bankidResponse}
        autostarttoken={autostartToken}
      />
    </div>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      divider: intl.formatMessage(signingMessages.divider),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  transferId: number | undefined
) {
  const search = parse(location.search) as SearchParams;
  search.t = DateTime.local().toMillis().toString();
  search.orderRef = orderRef;
  search.transferId = transferId;

  return {
    pathname: getNavLink(BASE_ROUTES.SIGN),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getUrlWithoutParams(location: Location) {
  const search = parse(location.search) as SearchParams;
  delete search.t;
  delete search.orderRef;
  delete search.transferId;

  return {
    pathname: getNavLink(BASE_ROUTES.SIGN),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}
