import { Icon } from "@lysaab/ui-2";
import { CountrySelect } from "../../components/countrySelect/CountrySelect";
import "./CountrySelectPage.scss";

export const COUNTRY_SELECT_PAGE_URL = "/country-select";

export const CountrySelectPage = () => (
  <div className="country-select-page">
    <header>
      <Icon.Logo />
    </header>
    <div className="center-wrapper">
      <CountrySelect />
    </div>
  </div>
);
