import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import { usePendingDeposits } from "../../../../../hooks/usePendingDeposits";
import { useSavingsAccount } from "../../../../../hooks/useSavingsAccount";
import { BASE_ROUTES as CLOSE_ACCOUNT_ROUTES } from "../../../../../countries/sweden/pages/closeSavingsAccountStory/CloseSavingsAccountStory";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  Button,
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { generatePath, Link } from "react-router-dom";
import { useLoadingContext } from "../../../../../context/LoadingContext";

interface Props {
  accountId?: SavingsAccountId;
}

export const CloseSavingsAccount: VoidFunctionComponent<Props> = ({
  accountId,
}) => {
  const [hasOngoingDeposit, setHasOngoingDeposit] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const loadPendingDeposits = usePendingDeposits();
  const { account } = useSavingsAccount(accountId);
  const { isLoading: isLoadingSync } = useLoadingContext(false);

  useEffect(() => {
    if (
      typeof account === "undefined" ||
      typeof loadPendingDeposits === "undefined"
    ) {
      return;
    }
    loadPendingDeposits()
      .then((pendingDeposits) => {
        setHasOngoingDeposit(
          pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          )
        );
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLocalLoading(false));
  }, [account, loadPendingDeposits]);

  if (isError) {
    return null;
  }

  if (isLoadingSync || isLocalLoading || typeof account === "undefined") {
    return <Spinner />;
  }

  const isDeleteAllowed = account.totalBalance === 0 && !hasOngoingDeposit;

  return (
    <Card>
      {isDeleteAllowed ? (
        <React.Fragment>
          <Typography type="body">
            <TranslatedText id="savingsAccountPage.delete-account.text" />
          </Typography>
          <Button
            component={Link}
            variant="negative"
            size="small"
            to={getNavLink(
              generatePath(
                CLOSE_ACCOUNT_ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION,
                {
                  accountId: account.accountId,
                }
              )
            )}
            block
            label={
              <TranslatedText id="savingsAccountPage.delete-account.link" />
            }
          />
        </React.Fragment>
      ) : (
        <Snackbar type={SNACKBAR_TYPES.INFO}>
          <Typography type="body">
            <TranslatedText id="savingsAccountPage.delete-account.snackbar.information" />
          </Typography>
          <ul className="account-page-close-account__list">
            {account.totalBalance > 0 && (
              <Typography component="li">
                <TranslatedText id="savingsAccountPage.delete-account.snackbar.money-on-account" />
              </Typography>
            )}
            {hasOngoingDeposit && (
              <Typography component="li">
                <TranslatedText id="savingsAccountPage.delete-account.snackbar.ongoing-deposit-error" />
              </Typography>
            )}
          </ul>
        </Snackbar>
      )}
    </Card>
  );
};
