import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Amount } from "../../../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { getAccountAmount } from "../../../../../../../data/dataAccounts";
import { banks } from "../../../../../../../data/dataBanks";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../../pages/deposits/overview/Recommendation";
import { OVERVIEW_PAGE_URL } from "../../../../../../../pages/overview/OverviewPage";
import {
  AutogiroDepositContext,
  AutogiroDepositState,
} from "../AutogiroDepositContext";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../AutogiroDepositPage";

import "./AutogiroDepositDone.scss";

export function AutogiroDepositDone() {
  const autogiroDepositContext = useContext(AutogiroDepositContext);
  const setAutogiroState = autogiroDepositContext.setState;
  const [depositData, setDepositData] = useState<AutogiroDepositState>();
  const history = useHistory();

  useEffect(() => {
    if (!autogiroDepositContext.state.amount && !depositData?.amount) {
      history.replace(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
    }
  }, [autogiroDepositContext.state, depositData, history]);

  if (!depositData) {
    setDepositData(autogiroDepositContext.state);
  }

  useEffect(() => {
    if (depositData) {
      setAutogiroState({
        amount: undefined,
        externalAccount: undefined,
        lysaAccount: undefined,
        selectedKlarnaAccount: undefined,
        bank: undefined,
        klarnaAccounts: undefined,
      });
    }
  }, [setAutogiroState, depositData]);

  if (
    !depositData ||
    !depositData.amount ||
    !(depositData.externalAccount || depositData.selectedKlarnaAccount) ||
    !depositData.lysaAccount
  ) {
    return null;
  }

  return (
    <div className="autogiro-deposit-done">
      <h2>
        <TranslatedText id="sweden.deposits.autogiro.story.done.header" />
      </h2>
      <Card>
        <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
          <TranslatedText id="sweden.deposits.autogiro.story.done.success" />
        </Snackbar>
        <dl>
          {depositData.externalAccount && (
            <div className="done-row">
              <dt>
                <TranslatedText id="sweden.deposits.autogiro.story.done.external-account.label" />
              </dt>
              <dd>
                <b>{depositData.externalAccount.externalBankAccount}</b>
                {` – `}
                {banks[depositData.externalAccount.bank]?.long ?? (
                  <TranslatedText id="sweden.deposits.autogiro.story.done.unknown-bank" />
                )}
              </dd>
            </div>
          )}
          {depositData.lysaAccount && (
            <div className="done-row">
              <dt>
                <TranslatedText id="sweden.deposits.autogiro.story.done.lysa-account.label" />
              </dt>
              <dd>
                <b>{depositData.lysaAccount.name}</b>
                {` – `}
                <Amount amount={getAccountAmount(depositData.lysaAccount)} />
              </dd>
            </div>
          )}
          {depositData.amount && (
            <div className="done-row">
              <dt>
                <TranslatedText id="sweden.deposits.autogiro.story.done.amount.label" />
              </dt>
              <dd>
                <b>
                  <Amount amount={depositData.amount} />
                </b>
              </dd>
            </div>
          )}
        </dl>
      </Card>
      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <TranslatedText id="sweden.deposits.autogiro.story.done.button" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.autogiro.story.done.deposits-button" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.autogiro.story.done.monthly-button" />
        }
      />
    </div>
  );
}
