import React, { FunctionComponent, MutableRefObject } from "react";
import { InvestmentType } from "@lysaab/ui-2";
import { useHistory } from "react-router-dom";
import { ROUTES } from "./UpdateAccountPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import { TranslatedText } from "../../components/TranslatedText";
import { InvestmentTypeCardBroad } from "../../components/investmentType/InvestmentTypeCardBroad";
import { InvestmentTypeCardSustainable } from "../../components/investmentType/InvestmentTypeCardSustainable";
import { EditAccounrRiskParams } from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";

interface Props {
  values: MutableRefObject<EditAccounrRiskParams | undefined>;
}

export const InvestmentTypeSelection: FunctionComponent<Props> = ({
  values,
}) => {
  const history = useHistory();

  if (!values.current) {
    return null;
  }

  return (
    <div className="update-account-page-investment-type-selection">
      <h1>
        <TranslatedText
          id="updateAccountPage.investmentType.header"
          description="update account page - investment type header"
          defaultMessage="Investment type"
        />
      </h1>
      <section>
        <InvestmentTypeCardBroad
          onClick={() => {
            if (!values.current) {
              return;
            }

            values.current = {
              ...values.current,
              investmentType: InvestmentType.BROAD,
            };

            history.push(getNavLink(ROUTES.SUMMARY));
          }}
          buttonText={
            <TranslatedText
              id="investmentType.broad.button"
              description="update account page - investment type broad button"
              defaultMessage="Continue with a broad type"
            />
          }
        />

        <InvestmentTypeCardSustainable
          onClick={() => {
            if (!values.current) {
              return;
            }

            values.current = {
              ...values.current,
              investmentType: InvestmentType.SUSTAINABLE,
            };

            history.push(getNavLink(ROUTES.SUMMARY));
          }}
          buttonText={
            <TranslatedText
              id="investmentType.sustainable.button"
              description="update account page - investment type sustainable button"
              defaultMessage="Continue with a sustainable type"
            />
          }
        />
      </section>
    </div>
  );
};
