import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import "@lysaab/ui-2/components/bankid-token/BankID.scss";
import {
  PendingHintCode,
  FailedHintCode,
  Status,
  Spinner,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { BankIDLogo } from "@lysaab/ui-2/components/bankid-token/BankIDLogo";
import {
  Device,
  openBankidApp,
} from "@lysaab/ui-2/components/bankid-token/DeviceDetection";
import { OrderRef } from "../../../../data/dataBankid";
import { QrCode } from "./QrCode";

export interface BankidResponse {
  hintCode: PendingHintCode | FailedHintCode;
  status: Status;
}

export interface Messages {
  qrInfo1: string;
  qrInfo2: string;
  qrInfo3: string;
  divider: string;
  buttonOpen: string;
  buttonErrorHeader: string;
  buttonRetry: string;
}

interface Props {
  orderRef?: OrderRef;
  autostartToken?: string;
  response: BankidResponse;
  retry: () => void;
  getMessages: () => Messages;
  getPendingMessages: (hintCode: PendingHintCode) => string | ReactNode;
  getFailedMessages: (hintCode: FailedHintCode) => string | ReactNode;
}

export const BankIdLogin: FunctionComponent<Props> = ({
  response,
  orderRef,
  autostartToken,
  retry,
  getMessages,
  getFailedMessages,
  getPendingMessages,
}) => {
  const messages = getMessages();
  const isMobile = Device.isMobile();
  const [maxLimitReached, setMaxLimitReached] = useState(false);

  const timeoutCallbackFunction = useCallback(
    () => setMaxLimitReached(true),
    []
  );

  useEffect(() => {
    setMaxLimitReached(false);
  }, [orderRef]);

  return (
    <div className="lysa-ui-new-bankid">
      {response.status === Status.PENDING && !maxLimitReached && (
        <React.Fragment>
          <div className="top-alignment">
            <div style={{ maxWidth: isMobile ? "100px" : "40px" }}>
              <Spinner />
            </div>
            <p>
              <span className="lysa-ui-bankid-grey-text">
                {getPendingMessages(response.hintCode as PendingHintCode)}
              </span>
            </p>
          </div>
          <div className="bottom-alignment">
            <React.Fragment>
              {!isMobile && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "48px",
                  }}
                >
                  {orderRef && (
                    <QrCode
                      orderRef={orderRef}
                      timoutCallback={timeoutCallbackFunction}
                    />
                  )}
                  <ol style={{ marginLeft: "48px" }}>
                    <li style={{ marginBottom: "20px" }}>{messages.qrInfo1}</li>
                    <li style={{ marginBottom: "20px" }}>{messages.qrInfo2}</li>
                    <li>{messages.qrInfo3}</li>
                  </ol>
                </div>
              )}
              {autostartToken && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => openBankidApp(autostartToken)}
                    label={messages.buttonOpen}
                  />
                </div>
              )}
            </React.Fragment>
          </div>
        </React.Fragment>
      )}
      {((response && response.status === Status.FAILED) || maxLimitReached) && (
        <React.Fragment>
          <div className="top-alignment">
            <BankIDLogo size={80} />
          </div>
          <div className="bottom-alignment">
            <Snackbar icon type={SNACKBAR_TYPES.ERROR}>
              <div>
                <h5>{messages.buttonErrorHeader}</h5>
                {response.status === Status.FAILED &&
                response &&
                response.hintCode &&
                getFailedMessages(response.hintCode as FailedHintCode)
                  ? getFailedMessages(response.hintCode as FailedHintCode)
                  : maxLimitReached
                  ? getFailedMessages(FailedHintCode.CANCELLED)
                  : console.error(
                      `Unknown failed hint code ${response.hintCode}`
                    )}
              </div>
            </Snackbar>
            <Button
              onClick={() => {
                retry();
              }}
              block
              label={messages.buttonRetry}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
