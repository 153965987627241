import { Snackbar, SNACKBAR_TYPES, NewIcon } from "@lysaab/ui-2";
import React, { FunctionComponent } from "react";
import { IS_NEWSBANNER_CLOSED_SESSION_KEY } from "../newsBanner/NewsBanner";

interface Props {
  onClose: () => void;
  primaryButton?: React.ReactNode;
}

export const NewsBannerContent: FunctionComponent<Props> = ({
  children,
  onClose,
  primaryButton,
}) => {
  return (
    <Snackbar type={SNACKBAR_TYPES.WARNING}>
      <div className="news-banner__content-wrapper">
        <div className="news-banner__content">{children}</div>
        <button
          onClick={() => {
            onClose();
            try {
              window.sessionStorage.setItem(
                IS_NEWSBANNER_CLOSED_SESSION_KEY,
                "true"
              );
            } catch (e) {
              console.error("Session Storage is disabled");
            }
          }}
          className="news-banner__close-button"
          type="button"
        >
          <NewIcon.Cancel />
        </button>
      </div>
      {typeof primaryButton !== "undefined" && primaryButton}
    </Snackbar>
  );
};
