import React from "react";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../TranslatedText";
import { getNavLink } from "../../hooks/useCountryUrls";
import { AnalyticsEvent, Tracker } from "../../utils/Tracker";
import { ACCOUNTING_PAGE_URL } from "../../countries/sweden/pages/corporation/accounting/AccountingPage";
import { Link } from "react-router-dom";

export const DeclarationCorporation = () => {
  return (
    <div className="compressed-snackbar">
      <Snackbar type={SNACKBAR_TYPES.WARNING}>
        <div>
          <p>
            <TranslatedText id="declaration-corporation.banner.text" />
          </p>
        </div>
        <div>
          <Button
            component={Link}
            to={getNavLink(ACCOUNTING_PAGE_URL)}
            onClick={() =>
              Tracker.event({
                event: AnalyticsEvent.BANNER,
                buttonName: "ACCOUNTING_PAGE_URL",
              })
            }
            size="small"
            label={<TranslatedText id="declaration-corporation.banner.cta" />}
          />
        </div>
      </Snackbar>
    </div>
  );
};
