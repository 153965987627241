import React, { useRef, useState } from "react";

import "./Video.scss";

export const Video = ({ src, poster }: { src: string; poster?: string }) => {
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayClick = () => {
    if (videoRef.current) {
      setShowControls(true);
      videoRef.current.play();
    }
  };

  return (
    <div className="video-wrapper">
      {!showControls && (
        <div className="video-player" onClick={handlePlayClick}>
          <span className="play-button"></span>
        </div>
      )}

      <video
        controls={showControls}
        poster={poster ? poster : undefined}
        ref={videoRef}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};
