import React, { FunctionComponent, useReducer } from "react";
import { Alternative } from "@lysaab/ui-2";
import { InvestmentAccount } from "../../../../../../data/dataAccounts";
import {
  WithdrawalKFAccount,
  ExternalKFAccount,
  GetKfWithdrawalStatus,
  WithdrawalSignResponse,
} from "../../../../../../data/dataDanica";
import { WithdrawalReason } from "../../../../../../components/withdrawal/WithdrawalReasons/Reasons";

export enum WithdrawalType {
  AMOUNT,
  DRAIN,
}

/**
 * This is the state for all generic sweden data.
 */
export interface KfWithdrawalState {
  amount?: string;
  account?: InvestmentAccount;
  from?: WithdrawalKFAccount;
  to?: ExternalKFAccount;
  reason?: Alternative<WithdrawalReason>;
  signingStatus?: GetKfWithdrawalStatus;
  signResponse?: WithdrawalSignResponse;
  withdrawalType?: Alternative<WithdrawalType>;
}

// All types for the context. This includes setter methods
export interface KfWithdrawalContextProps {
  state: KfWithdrawalState;
  setState: (newState: Partial<KfWithdrawalState>) => void;
}

// Create the context. (This will just create a template)
export const KfWithdrawalContext =
  React.createContext<KfWithdrawalContextProps>({} as KfWithdrawalContextProps);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withKfWithdrawal =
  <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<Omit<P, keyof KfWithdrawalContextProps>> =>
  (props) =>
    (
      <KfWithdrawalContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </KfWithdrawalContext.Consumer>
    );

function stateReducer(
  state: KfWithdrawalState,
  newState: Partial<KfWithdrawalState>
) {
  return { ...state, ...newState };
}

export const KfWithdrawalContextProvider: FunctionComponent = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <KfWithdrawalContext.Provider value={{ state, setState }}>
      {children}
    </KfWithdrawalContext.Provider>
  );
};
