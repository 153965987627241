import React, { FunctionComponent, useState, useRef, useContext } from "react";
import {
  Button,
  Card,
  LysaFormRef,
  Form,
  RequiredValidator,
  RadioGroup,
  CheckboxGroup,
} from "@lysaab/ui-2";
import {
  KFAccountContext,
  DepositInterval,
  WithdrawalInterval,
  Purpose,
} from "../KFAccountContext";
import { useHistory } from "react-router";

interface Props {
  horizonRoute: string;
}

const purposeAlternatives = [
  {
    text: "Förvaltning av överskottslikviditet",
    value: Purpose.SURPLUS_LIQUIDITY,
  },
  {
    text: "Investera i värdpapper",
    value: Purpose.LONGTERM_INVESTMENTS,
  },
];

const depositIntervalAlternatives = [
  {
    text: "Månadsvis",
    value: DepositInterval.MONTHLY,
  },
  {
    text: "Flera gånger årligen",
    value: DepositInterval.MULTIPLE_YEARLY,
  },
  {
    text: "En gång om året",
    value: DepositInterval.ONCE_YEARLY,
  },
  {
    text: "Endas vid ett fåtal tillfällen/engångsinsättning",
    value: DepositInterval.SELDOM,
  },
];

const withdrawalIntervalAlternatives = [
  {
    text: "Månadsvis",
    value: WithdrawalInterval.MONTHLY,
  },
  {
    text: "Flera gånger årligen",
    value: WithdrawalInterval.MULTIPLE_YEARLY,
  },
  {
    text: "En gång om året",
    value: WithdrawalInterval.ONCE_YEARLY,
  },
  {
    text: "Endas vid ett fåtal tillfällen eller mer sällan",
    value: WithdrawalInterval.SELDOM,
  },
];

export const LysaKyc: FunctionComponent<Props> = ({ horizonRoute }) => {
  const formRef = useRef<LysaFormRef>();
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [forceValidation, setForceValidation] = useState<boolean>(false);
  const history = useHistory();

  return (
    <div className="create-account-corporation-kyc">
      <h1>Kundkännedom</h1>
      <Form
        lysaFormRef={formRef}
        forceValidation={forceValidation}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            history.push(horizonRoute);
          } else {
            setForceValidation(true);
          }
        }}
      >
        <Card>
          <CheckboxGroup
            header="Vad är syftet med detta konto?"
            onChange={(ev) => {
              setKFState({ purpose: ev });
            }}
            values={kfState.purpose}
            alternatives={purposeAlternatives}
            validators={[
              new RequiredValidator("Du måste välja åtminstone ett alternativ"),
            ]}
          />

          <RadioGroup
            header="Hur ofta kommer företaget att göra insättningar?"
            onChange={(ev) => {
              setKFState({ depositInterval: ev });
            }}
            value={kfState.depositInterval}
            alternatives={depositIntervalAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          <RadioGroup
            header="Hur ofta kommer företaget att göra uttag?"
            onChange={(ev) => {
              setKFState({ withdrawalInterval: ev });
            }}
            value={kfState.withdrawalInterval}
            alternatives={withdrawalIntervalAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />
        </Card>
        <div className="bottom-nav">
          <Button block type="submit" label="Nästa" />
        </div>
      </Form>
    </div>
  );
};
