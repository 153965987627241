import React, { VoidFunctionComponent } from "react";
import {
  Card,
  RequiredValidator,
  CheckboxGroup,
  Alternative,
} from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { MoneyOriginPerson } from "../../../../data/dataKyc";

export const messages = defineMessages({
  moneyOriginHeader: {
    id: "kyc.moneyOrigin.header",
  },
  moneyOriginRequired: {
    id: "kyc.moneyOrigin.required",
  },
});

interface MessageWithId {
  id: string;
}

export const moneyOriginMessages = defineMessages<
  MoneyOriginPerson,
  MessageWithId,
  Record<MoneyOriginPerson, MessageWithId>
>({
  [MoneyOriginPerson.SAVINGS]: {
    id: "kyc.MoneyOrigin.alt.SAVINGS",
  },
  [MoneyOriginPerson.INHERITANCE]: {
    id: "kyc.MoneyOrigin.alt.INHERITANCE",
  },
  [MoneyOriginPerson.INCOME]: {
    id: "kyc.MoneyOrigin.alt.INCOME",
  },
  [MoneyOriginPerson.PROPERTY_SALE]: {
    id: "kyc.MoneyOrigin.alt.PROPERTY_SALE",
  },
  [MoneyOriginPerson.CORPORATION_SALE]: {
    id: "kyc.MoneyOrigin.alt.CORPORATION_SALE",
  },
  [MoneyOriginPerson.TRADE]: {
    id: "kyc.MoneyOrigin.alt.TRADE",
  },
  [MoneyOriginPerson.DIVIDEND]: {
    id: "kyc.MoneyOrigin.alt.DIVIDEND",
  },
});

interface Props {
  moneyOrigin?: MoneyOriginPerson[];
  setMoneyOrigin: (moneyOrigin: MoneyOriginPerson[]) => void;
}

export const MoneyOriginQuestion: VoidFunctionComponent<Props> = ({
  moneyOrigin,
  setMoneyOrigin,
}) => {
  const intl = useIntl();

  const alternatives = Object.values(MoneyOriginPerson).map((moneyOrigin) => {
    return {
      text: intl.formatMessage(moneyOriginMessages[moneyOrigin]),
      value: moneyOrigin,
    } as Alternative<MoneyOriginPerson>;
  });

  return (
    <Card>
      <CheckboxGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.moneyOriginHeader)}
        validators={[
          new RequiredValidator(
            intl.formatMessage(messages.moneyOriginRequired)
          ),
        ]}
        onChange={(moneyOrigin) =>
          setMoneyOrigin(moneyOrigin.map((origin) => origin.value))
        }
        values={alternatives.filter((alt) =>
          (moneyOrigin ?? []).includes(alt.value)
        )}
      />
    </Card>
  );
};
