import { API } from "@lysaab/ui-2/network/API";
import { encode } from "@lysaab/ui-2/network/Encode";
import { CompoundAccount, CompoundAccountId } from "./dataAccounts";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { dataUser } from "./dataUser";

export enum IndexId {
  F00000VPET = "F00000VPET",
  SSEACT = "SSEACT",
  F000011PMT = "F000011PMT",
  F00000VPF2 = "F00000VPF2",
  F00000T62Q = "F00000T62Q",
  F00000VPER = "F00000VPER",
  F000011P2R = "F000011P2R",
  F000011C20 = "F000011C20",
  F00000VN9H = "F00000VN9H",
  F000011P2V = "F000011P2V",
  F000011C24 = "F000011C24",
}

export interface DateChangePoint {
  date: string;
  change: number;
}

export interface IndexPoint extends DateChangePoint {
  price: number;
}

interface IndexGraphWithoutName {
  indexId: IndexId;
  graph: IndexPoint[];
}

export interface IndexGraph {
  indexId: IndexId;
  name: string;
  graph: IndexPoint[];
}

export interface PerformancePoint extends DateChangePoint {
  worth: number;
  growth: number;
}

export interface PerformanceAccount {
  earnings: number;
  change: number;
}

export interface PerformanceData {
  graph: PerformancePoint[];
  totalEarnings: number;
  totalChange: number;
  accounts: {
    [key: string]: PerformanceAccount;
  };
}

export interface GraphDataPoint {
  date: Date;
  change: number;
}

export interface GraphDataIndex {
  id: IndexId;
  name: string;
  points: GraphDataPoint[];
  color?: string;
}

export interface GraphDataIndexes {
  indexes: GraphDataIndex[];
  yDomain: number[];
}

export interface GraphData {
  points: GraphDataPoint[];
  xDomain: Date[];
  yDomain: number[];
}

export enum Interval {
  LAST_THREE_MONTHS = "LAST_THREE_MONTHS",
  LAST_SIX_MONTHS = "LAST_SIX_MONTHS",
  LAST_YEAR = "LAST_YEAR",
  CURRENT_YEAR = "CURRENT_YEAR",
  SINCE_REGISTRATION = "SINCE_REGISTRATION",
  CUSTOM = "CUSTOM",
}

export interface Market {
  name: string;
  id: IndexId;
  color: string;
}

export interface Markets {
  [key: string]: Market;
}

const marketColors = [
  "#ffc802",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
];

const ComparisonMarkets: Record<IndexId, string> = {
  [IndexId.F00000VPET]: "performance.graph.index.F00000VPET",
  [IndexId.SSEACT]: "performance.graph.index.SSEACT",
  [IndexId.F000011PMT]: "performance.graph.index.F000011PMT",
  [IndexId.F00000VPF2]: "performance.graph.index.F00000VPF2",
  [IndexId.F00000T62Q]: "performance.graph.index.F00000T62Q",
  [IndexId.F00000VPER]: "performance.graph.index.F00000VPER",
  [IndexId.F000011P2R]: "performance.graph.index.F000011P2R",
  [IndexId.F000011C20]: "performance.graph.index.F000011C20",
  [IndexId.F00000VN9H]: "performance.graph.index.F00000VN9H",
  [IndexId.F000011P2V]: "performance.graph.index.F000011P2V",
  [IndexId.F000011C24]: "performance.graph.index.F000011C24",
};

export const dataPerformance = {
  getAccountPerformance(start: Date, end: Date, accountId: CompoundAccountId) {
    return API.get<PerformanceData>(
      encode`/accounts/performance/${accountId}?start=${this.toStringDate(
        start
      )}&end=${this.toStringDate(end)}`
    );
  },

  getOverviewPerformance(start: Date, end: Date) {
    return API.get<PerformanceData>(
      encode`/accounts/performance?start=${this.toStringDate(
        start
      )}&end=${this.toStringDate(end)}`
    );
  },

  getMarketPerformances(
    start: Date,
    end: Date,
    indexes: IndexId[]
  ): Promise<IndexGraph[]> {
    return API.get<IndexGraphWithoutName[]>(
      encode`/market/index/graphs?indexIds=${indexes.join(
        ","
      )}&from=${this.toStringDate(start)}&to=${this.toStringDate(end)}`
    ).then((markets) => {
      return markets.map((market) => {
        return {
          ...market,
          name: ComparisonMarkets[market.indexId],
        };
      }, {} as IndexGraph[]);
    });
  },

  getComparisonMarkets(): Promise<Markets> {
    return API.get<IndexId[]>(encode`/market/index/comparison`).then((res) => {
      return res.reduce((markets, value, index) => {
        markets[`id_${value}`] = {
          id: value,
          name: ComparisonMarkets[value],
          color: marketColors[index % marketColors.length],
        } as Market;
        return markets;
      }, {} as Markets);
    });
  },

  getComparisonMarketsForAccount(accountId: string): Promise<Markets> {
    return API.get<IndexId[]>(
      encode`/market/index/comparison/${accountId}`
    ).then((res) => {
      return res.reduce((markets, value, index) => {
        markets[`id_${value}`] = {
          id: value,
          name: ComparisonMarkets[value],
          color: marketColors[index % marketColors.length],
        } as Market;
        return markets;
      }, {} as Markets);
    });
  },

  isValidDate(d: Date | null) {
    return d instanceof Date && !isNaN(d.getTime());
  },

  toJsDate(date: string) {
    return new Date(date);
  },

  toStringDate(date: Date, options?: DateTimeFormatOptions | string) {
    if (!options) {
      return DateTime.fromJSDate(date).toISODate();
    }

    if (typeof options === "string") {
      return DateTime.fromJSDate(date).toFormat(options);
    } else {
      return DateTime.fromJSDate(date).toLocaleString(options);
    }
  },

  getEndDate() {
    return new Date();
  },

  getStartDateFromAccount(interval: Interval, account: CompoundAccount) {
    if (interval === Interval.SINCE_REGISTRATION) {
      /**
       * Adjusted for the performance data being aggregated on UTC dates
       */
      return DateTime.fromISO(
        DateTime.fromISO(account.created).toUTC().toFormat("yyyy-MM-dd")
      ).toJSDate();
    }

    return this.getStartDate(interval);
  },

  getStartDateFromUser(interval: Interval, accounts: CompoundAccount[]) {
    if (interval === Interval.SINCE_REGISTRATION) {
      /**
       * Adjusted for the performance data being aggregated on UTC dates
       */
      return DateTime.fromISO(
        DateTime.fromJSDate(dataUser.getCreated(accounts))
          .toUTC()
          .toFormat("yyyy-MM-dd")
      ).toJSDate();
    }

    return this.getStartDate(interval);
  },

  getStartDate(interval: Interval) {
    switch (interval) {
      case Interval.CURRENT_YEAR: {
        const date = new Date();
        date.setMonth(0);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        return date;
      }

      case Interval.LAST_SIX_MONTHS: {
        let dt = DateTime.local();
        dt = dt.startOf("day");
        dt = dt.minus({ months: 6 });
        return dt.toJSDate();
      }

      case Interval.LAST_THREE_MONTHS: {
        let dt = DateTime.local();
        dt = dt.startOf("day");
        dt = dt.minus({ months: 3 });
        return dt.toJSDate();
      }

      case Interval.LAST_YEAR:
      default: {
        let dt = DateTime.local();
        dt = dt.startOf("day");
        dt = dt.minus({ years: 1 });
        return dt.toJSDate();
      }
    }
  },
};
