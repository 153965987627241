import { useKlarnaAlternative } from "../../../../components/deposits/depositCard/items/useKlarnaAlternative";
import { useMonthlyDepositItem } from "../../../../components/deposits/depositCard/items/useMonthlyDepositAlternative";
import { useVirtualAccountAlternative } from "../../../../components/deposits/depositCard/items/useVirtualAccountAlternative";
import { Recommendation } from "../../../../pages/deposits/overview/Recommendation";

export function DepositsOverviewWrapper() {
  const virtualAccountAlt = useVirtualAccountAlternative();
  const klarnaAlt = useKlarnaAlternative(true);
  const monthlyDepositAlt = useMonthlyDepositItem();

  return (
    <div className="deposits-overview-wrapper-germany">
      <Recommendation
        alternatives={[klarnaAlt, virtualAccountAlt, monthlyDepositAlt]}
      />
    </div>
  );
}
