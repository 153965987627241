import { InvestmentAccount } from "../data/dataAccounts";
import { AccountQuestions, AdviseAccount } from "../data/dataInvestments";
import { createContext } from "./CreateContext";

export type AccountPreferences = InvestmentAccount &
  Omit<AdviseAccount, keyof AccountQuestions> &
  Partial<AccountQuestions>;

export type AccountsState = {
  preferenceAccounts: AccountPreferences[];
};

export const [AccountsContextProvider, useAccountsContext] =
  createContext<AccountsState>("AccountsContext", {
    preferenceAccounts: [],
  });
