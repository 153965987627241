import React, {
  FunctionComponent,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import {
  OtpInput,
  Form,
  LysaFormRef,
  RequiredValidator,
  Card,
  Spinner,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  dataLogin,
  VerifyOtpResponse,
  VerificationResult,
  onUserBootstrap,
} from "../../data/dataLogin";
import { setUserState, UserContext } from "../../context/UserContext";
import { OtpType } from "./LoginUsernamePasswordPage";
import { normalizeTin } from "../../utils/TinNormalizer";

interface Props {
  otp: string;
  setOtp: (otp: string) => void;
  onSubmit: () => void;
  otpType: OtpType;
  username: string;
  onReset: () => void;
}

const messages = defineMessages({
  emailDescription: {
    id: "login.otp.email",
    description: "Login otp email description",
    defaultMessage: "A code has been sent to your email.",
  },
  totpDescription: {
    id: "login.otp.totp",
    description: "Login otp totp description",
    defaultMessage: "A code can be found in your code generation app.",
  },
  otpLabel: {
    id: "login.otp.label",
    description: "Login otp input label",
    defaultMessage: "One time password",
  },
  otpRequired: {
    id: "login.otp.required",
    description: "Login otp input required error",
    defaultMessage: "One time password is required",
  },
  nextButton: {
    id: "login.otp.button.next",
    description: "Login otp step next button",
    defaultMessage: "Next",
  },
  abortButton: {
    id: "login.otp.button.abort",
    description: "Login otp step abort button",
    defaultMessage: "Abort",
  },
  errorHeader: {
    id: "login.otp.error.header",
    description: "Login otp step error header",
    defaultMessage: "Error",
  },
  errorFailed: {
    id: "login.otp.error.failed",
    description: "Login otp step error: failed",
    defaultMessage: "Login failed, please try again",
  },
  errorRateLimit: {
    id: "login.otp.error.rate.limit",
    description: "Login otp step error: rate limit",
    defaultMessage:
      "You've made too many failed tries. Please wait a while before trying again.",
  },
  errorServerError: {
    id: "login.otp.error.server",
    description: "Login otp step error: server error",
    defaultMessage:
      "An unexpected server error occurred. Please try again later.",
  },
});

export const OtpStep: FunctionComponent<Props> = ({
  otp,
  setOtp,
  onSubmit,
  otpType,
  username,
  onReset,
}) => {
  const intl = useIntl();
  const lysaForm = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<VerificationResult>();

  const requestHandler = useCallback(
    (response: VerifyOtpResponse) => {
      if (response.result === VerificationResult.SUCCESS) {
        onSubmit();
        onUserBootstrap().then(([bootstrap, legalEntity]) => {
          setUserState(bootstrap, legalEntity, userContext.setState);
        });
      } else {
        setIsLoading(false);
        setOtp("");
        setError(response.result);
        lysaForm.current?.setForceErrors(false);
      }
    },
    [onSubmit, setOtp, userContext]
  );

  const errorHandler = useCallback(() => {
    setIsLoading(false);
    setOtp("");
    setError(VerificationResult.ERROR);
    lysaForm.current?.setForceErrors(false);
  }, [setOtp]);

  return (
    <div>
      <Form
        lysaFormRef={lysaForm}
        onSubmit={() => {
          if (lysaForm.current?.isValid) {
            setIsLoading(true);
            const country = localizationContext.state.country;
            if (!country) {
              return;
            }

            const normalizedTin = normalizeTin(username, country);
            if (!normalizedTin) {
              return;
            }

            if (otpType === OtpType.EMAIL) {
              dataLogin
                .loginEmail(normalizedTin, country, otp)
                .then(requestHandler)
                .catch(errorHandler);
            } else if (otpType === OtpType.TOTP) {
              dataLogin
                .loginTotp(normalizedTin, country, otp)
                .then(requestHandler)
                .catch(errorHandler);
            }
          }
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Card>
              {otpType === OtpType.EMAIL && (
                <p>{intl.formatMessage(messages.emailDescription)}</p>
              )}
              {otpType === OtpType.TOTP && (
                <p>{intl.formatMessage(messages.totpDescription)}</p>
              )}
              <OtpInput
                label={intl.formatMessage(messages.otpLabel)}
                value={otp}
                onChange={setOtp}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.otpRequired)
                  ),
                ]}
              />
            </Card>
            {error === VerificationResult.FAILED && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <h3>{intl.formatMessage(messages.errorHeader)}</h3>
                  <p>{intl.formatMessage(messages.errorFailed)}</p>
                </div>
              </Snackbar>
            )}
            {error === VerificationResult.RATE_LIMIT && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <h3>{intl.formatMessage(messages.errorHeader)}</h3>
                  <p>{intl.formatMessage(messages.errorFailed)}</p>
                </div>
              </Snackbar>
            )}
            {error === VerificationResult.ERROR && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <h3>{intl.formatMessage(messages.errorHeader)}</h3>
                  <p>{intl.formatMessage(messages.errorServerError)}</p>
                </div>
              </Snackbar>
            )}
            <Button
              block
              type="submit"
              label={intl.formatMessage(messages.nextButton)}
            />

            <Button
              variant="secondary"
              block
              type="button"
              onClick={onReset}
              label={intl.formatMessage(messages.abortButton)}
            />
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};
