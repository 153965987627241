import React, { VoidFunctionComponent, useContext } from "react";
import { EntityType, PepAnswers, stateToRequest } from "@lysaab/lysa-pep";
import { Button, Card } from "@lysaab/ui-2";
import { UserContext } from "../../../../context/UserContext";
import { FormattedMessage } from "react-intl";
import { useMultiPepContext } from "../../contexts/MultiPepContext";

interface Props {
  next: () => void;
  navigateIsPep: (index: number) => void;
}

export const PepOverview: VoidFunctionComponent<Props> = ({
  next,
  navigateIsPep,
}) => {
  const [multiPepState] = useMultiPepContext();
  const userContext = useContext(UserContext);

  return (
    <>
      {multiPepState.pepStates?.map((pepState, index) => (
        <div key={index}>
          <h2 className="pep-card-header">
            {pepState.legalEntityType === EntityType.CORPORATION
              ? pepState?.ownerName
              : userContext.state.name}
          </h2>
          <Card key={pepState.tin}>
            <PepAnswers answer={stateToRequest(pepState)} />
            <Button
              block
              variant="secondary"
              size="small"
              onClick={() => navigateIsPep(index)}
              label={<FormattedMessage id="pep.overview.edit" />}
            />
          </Card>
        </div>
      ))}

      <Button
        block
        onClick={next}
        label={<FormattedMessage id="pep.overview.next" />}
      />
    </>
  );
};
