import React, { useContext, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { isSustainabilityImportantSpecific } from "../../../../../../../data/dataInvestments";
import { SFDRQuestion } from "../../../../../../../pageComponents/sustainability/SFDRQuestion";
import { TaxonomyQuestion } from "../../../../../../../pageComponents/sustainability/TaxonomyQuestion";
import { PAIQuestion } from "../../../../../../../pageComponents/sustainability/PAIQuestion";
import { KFAccountContext } from "../../KFAccountContext";

interface Props {
  next(): void;
}

export const SustainabilityImportantQuestions: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const accountContext = useContext(KFAccountContext);

  if (!isSustainabilityImportantSpecific(accountContext.state)) {
    return null;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <h2>
        <FormattedMessage id="createAccount.sustainability.importantQuestions.title" />
      </h2>
      <SFDRQuestion
        sfdr={accountContext.state.sfdr}
        setSfdr={(sfdr) => accountContext.setState({ sfdr: sfdr.value })}
      />
      <TaxonomyQuestion
        taxonomy={accountContext.state.taxonomy}
        setTaxonomy={(taxonomy) =>
          accountContext.setState({ taxonomy: taxonomy.value })
        }
      />
      <PAIQuestion
        pai={accountContext.state.pai}
        setPai={(pai) => accountContext.setState({ pai: pai.value })}
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="createAccount.sustainability.importantQuestions.next" />
        }
      />
    </Form>
  );
};
