import React, { ReactNode, useEffect } from "react";
import { createContext } from "../../../context/CreateContext";
import { useHistory } from "react-router-dom";

export interface YearlyReviewState {
  email: string;
  fatca: boolean;
  forceErrorsAccountsAllocation: boolean;
}

export const [YearlyReviewContextProvider, useYearlyReviewContext] =
  createContext<YearlyReviewState>("YearlyReviewContext", {
    email: "",
    fatca: false,
    forceErrorsAccountsAllocation: false,
  });

/**
 * Access guard for state, should be used to prevent skipping steps with browser navigation.
 * Mostly used on critical areas such as validating that the user is not FATCA obliged
 */
interface Props {
  children: ReactNode;
  fallbackRoute: string;
  isValid: (state: YearlyReviewState) => boolean;
}

export const YearlyReviewAccessGuard: React.FC<Props> = ({
  children,
  fallbackRoute,
  isValid,
}) => {
  const [yearlyReviewState] = useYearlyReviewContext();
  const history = useHistory();

  useEffect(() => {
    if (!isValid(yearlyReviewState)) {
      history.replace(fallbackRoute);
    }
  }, [history, yearlyReviewState, isValid, fallbackRoute]);

  return <>{children}</>;
};

export function isValidFatca(state: YearlyReviewState) {
  return state.fatca === false;
}
