import { Button } from "@lysaab/ui-2";
import React from "react";
import ReactModal from "react-modal";
import { TranslatedText } from "../../../../components/TranslatedText";

export const ConfirmModal = ({
  showModal,
  onConfirm,
  onDeny,
}: {
  showModal: boolean;
  onConfirm: () => void;
  onDeny: () => void;
}) => {
  return (
    <ReactModal
      isOpen={showModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(242, 242, 242, 0.9)",
          display: "flex",
          zIndex: 2,
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          padding: 30,
          position: "initial",
          backgroundColor: "#fcfcfc",
          width: 400,
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <h3>
        <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmModalHeader" />
      </h3>
      <p>
        <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmModaltext" />
      </p>
      <Button
        variant="negative"
        onClick={onConfirm}
        block
        label={
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmModalYes" />
        }
      />

      <Button
        variant="secondary"
        onClick={onDeny}
        block
        label={
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.confirmModalNo" />
        }
      />
    </ReactModal>
  );
};
