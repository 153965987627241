import {
  Form,
  LysaFormRef,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserContext } from "../../../context/UserContext";
import {
  InvestmentAccountId,
  dataAccounts,
  CompoundAccount,
} from "../../../data/dataAccounts";
import {
  dataTransfer,
  isDanicaKF,
  isMergedInternalTransferInvestmentAccount,
  MergedInternalTransferAccount,
  TransferableGeneral,
  TransferableInvestment,
  TransferableSavings,
} from "../../../data/dataTransfer";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { InternalTransferContext } from "../InternalTransferContext";

interface Props {
  next: () => void;
}

interface WithAccountId {
  accountId?: InvestmentAccountId;
}

export function InternalTransferFromAccount({ next }: Props) {
  const [accounts, setAccounts] = useState<CompoundAccount[]>();
  const [mergedInternalTransferAccounts, setMergedInternalTransferAccounts] =
    useState<MergedInternalTransferAccount[]>();
  const {
    state: internalTransferContextState,
    setState: setInternalTransferContextState,
  } = useContext(InternalTransferContext);
  const selectedAccount = internalTransferContextState.fromAccount;
  const formRef = useRef<LysaFormRef>();
  const userContext = useContext(UserContext);
  const location = useLocation<WithAccountId | undefined>();

  useEffect(() => {
    if (accounts) {
      return;
    }
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataTransfer.getInternalTransferAccounts(),
    ]).then(([accounts, internalTransferAccounts]) => {
      const investmentAndSavingsAccounts = [
        ...accounts.investmentAccounts,
        ...accounts.savingsAccounts,
      ];
      const mergedAccounts = investmentAndSavingsAccounts.map((account) => {
        const internalTransferAccount = internalTransferAccounts.find(
          (internalTransferAccount) =>
            internalTransferAccount.accountId === account.accountId
        );
        return {
          ...account,
          ...internalTransferAccount,
        } as MergedInternalTransferAccount;
      });
      setMergedInternalTransferAccounts(mergedAccounts);
      setAccounts(investmentAndSavingsAccounts);
    });
  }, [accounts]);

  useEffect(() => {
    if (
      typeof location.state?.accountId === "undefined" ||
      typeof mergedInternalTransferAccounts === "undefined"
    ) {
      return;
    }
    setInternalTransferContextState({
      fromAccount: mergedInternalTransferAccounts.find(
        (account) => account.accountId === location.state?.accountId
      ),
    });
  }, [
    setInternalTransferContextState,
    location.state,
    mergedInternalTransferAccounts,
  ]);

  if (!mergedInternalTransferAccounts || !accounts) {
    return (
      <div className="internal-transfer-from-account">
        <Spinner />
      </div>
    );
  }

  if (mergedInternalTransferAccounts.length === 1) {
    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="internal-transfer.story.from-account.error.mergedInternalTransferAccounts" />
        </Snackbar>
      </div>
    );
  }

  return (
    <div className="internal-transfer-from-account">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isInvalid ||
            selectedAccount?.transferable !== TransferableGeneral.OK ||
            isDanicaKF(selectedAccount) ||
            userContext.state.readOnly
          ) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={accounts.find(
            ({ accountId }) => accountId === selectedAccount?.accountId
          )}
          onChange={({ accountId }) => {
            setInternalTransferContextState({
              fromAccount: mergedInternalTransferAccounts.find(
                (account) => account.accountId === accountId
              ),
            });
          }}
          legend={
            <h2>
              <TranslatedText id="internal-transfer.story.from-account.header" />
            </h2>
          }
        />

        {isDanicaKF(selectedAccount) ? (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="internal-transfer.story.from-account.error.kf" />
          </Snackbar>
        ) : (
          <>
            {selectedAccount?.transferable ===
              TransferableGeneral.LACKS_FUNDS && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                {isMergedInternalTransferInvestmentAccount(selectedAccount) &&
                selectedAccount.worth > 0 &&
                selectedAccount.uninvestedMoney === selectedAccount.worth ? (
                  <TranslatedText id="internal-transfer.story.from-account.error.lacks-funds-with-cash" />
                ) : (
                  <TranslatedText id="internal-transfer.story.from-account.error.lacks-funds" />
                )}
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableInvestment.PENDING_ORDER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-order" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_WITHDRAWAL && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-withdrawal" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableGeneral.PENDING_INTERNAL_TRANSFER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-internal-transfer" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableSavings.PENDING_CASH_TRANSFER && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-cash-transfer" />
              </Snackbar>
            )}
            {selectedAccount?.transferable ===
              TransferableSavings.PENDING_REBALANCING && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="internal-transfer.story.from-account.error.pending-rebalancing" />
              </Snackbar>
            )}
          </>
        )}

        {userContext.state.readOnly ? (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="internal-transfer.story.from-account.error.read-only" />
            </Snackbar>
            <Button
              component={Link}
              to={getNavLink(OVERVIEW_PAGE_URL)}
              block
              label={
                <TranslatedText id="internal-transfer.story.from-account.back" />
              }
            />
          </>
        ) : (
          <Button
            type="submit"
            block
            label={
              <TranslatedText id="internal-transfer.story.from-account.button" />
            }
          />
        )}
      </Form>
    </div>
  );
}
