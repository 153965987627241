import React, { useEffect, useState } from "react";
import { Card } from "@lysaab/ui-2";
import { dataLegalEntity } from "../../data/dataLegalEntity";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../hooks/useCountryUrls";
import { CHANGE_EMAIL_PAGE_URL } from "../changeEmail/ChangeEmailPage";

export const TwoFaEmailSection: React.VFC = () => {
  const [currentEmail, setCurrentEmail] = useState("");

  useEffect(() => {
    dataLegalEntity.getProfileSettings().then((settings) => {
      setCurrentEmail(settings.contactDetails.email);
    });
  });

  return (
    <Card>
      <h2>
        <FormattedMessage
          id="change_twofa.email.header"
          defaultMessage="Using email"
        />
      </h2>
      <p>
        <FormattedMessage
          id="change_twofa.page.change_email"
          defaultMessage={
            "Currently you receive your login codes to your email, " +
            "<b>{email}</b>. If you want to use another email " +
            "address, you need to use the <emailpage>change email " +
            "page</emailpage>."
          }
          values={{
            email: currentEmail,
            b: (text: string) => <strong>{text}</strong>,
            emailpage: (text: string) => (
              <a href={getNavLink(CHANGE_EMAIL_PAGE_URL)}>{text}</a>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="change_twofa.email.app"
          defaultMessage={
            "If you wish to switch to using an authentication app on your " +
            "phone, you should follow the directions below."
          }
        />
      </p>
    </Card>
  );
};
