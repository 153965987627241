import { Story } from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Switch, useLocation } from "react-router";
import { Route } from "../../../../../components/route/Route";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { PageStripped } from "../../../../../pages/PageStripped";
import { Intro } from "./intro/Intro";
import { useStoryValues } from "../../../../../hooks/useStoryValues";

export const CREATE_ACCOUNT_INTRO_URL = "/create-account-intro";

export const BASE_ROUTES = {
  INTRO: `${CREATE_ACCOUNT_INTRO_URL}/`,
};

const messages = defineMessages({
  header: {
    id: "sweden.createAccountIntroStory.header",
  },
  ariaProgressLabel: {
    id: "sweden.createAccountIntroStory.ariaProgressLabel",
  },
});

export const CreateAccountIntroStory: VoidFunctionComponent = () => {
  const [currentIndex, ROUTES, , storyLength] = useStoryValues(BASE_ROUTES);
  const location = useLocation();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={0.1}
        showBack={false}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => {
          safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
        }}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route exact path={ROUTES.INTRO} render={() => <Intro />} />
        </Switch>
      </Story>
    </PageStripped>
  );
};
