import { defineMessages } from "react-intl";

export const intervalMessages = defineMessages({
  LAST_THREE_MONTHS: {
    id: "overview.accounts.interval.lastThreeMonths",
  },
  LAST_SIX_MONTHS: {
    id: "overview.accounts.interval.lastSixMonths",
  },
  LAST_YEAR: {
    id: "overview.accounts.interval.lastYear",
  },
  CURRENT_YEAR: {
    id: "overview.accounts.interval.currentYear",
  },
  SINCE_REGISTRATION: {
    id: "overview.accounts.interval.sinceRegistration",
  },
  CUSTOM: {
    id: "overview.accounts.interval.custom",
  },
});
