import { useCallback, useEffect, useMemo, VoidFunctionComponent } from "react";
import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, matchPath, useHistory } from "react-router";
import { Switch } from "../../components/route/Switch";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { AccountsAllocation } from "./pages/accountsAllocation/AccountsAllocation";
import { AccountSituation } from "../../pageComponents/accountSituation/AccountSituation";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { PageStripped } from "../PageStripped";
import {
  AccessGuardRoute,
  SuitabilityAssessmentReturnState,
} from "./AccessGuardRoute";
import { SituationCorporation } from "./pages/situation/SituationCorporation";
import { SituationPerson } from "./pages/situation/SituationPerson";
import { Done } from "./pages/done/Done";
import { BASE_ROUTES as REVIEW_ACCOUNT_ROUTES } from "../reviewAccount/ReviewAccountStory";
import { dataAccounts } from "../../data/dataAccounts";
import {
  dataInvestments,
  isSavingsHorizonLength,
  isNeedEarlierProbability,
  isEsgQuestionsUpdate,
} from "../../data/dataInvestments";
import "./SuitabilityAssessmentStory.scss";
import { useIsPerson } from "../../hooks/useIsPerson";
import {
  AccountPreferences,
  AccountsContextProvider,
  useAccountsContext,
} from "../../context/AccountsContext";
import { useNavigateToReviewAccount } from "./hooks/useNavigateToReviewAccount";
import { EligibilityContextProvider } from "../../context/EligibilityContext";
import { ReviewAccountContextProvider } from "../reviewAccount/ReviewAccountContext";
import {
  SuitabilityAssessmentContextProvider,
  useSuitabilityAssessmentContext,
} from "./contexts/SuitabilityAssessmentContext";
import {
  EligibilityAccessGuard,
  isValidEconomy,
} from "../../components/eligibilityAccessGuard/EligibilityAccessGuard";

const messages = defineMessages({
  header: {
    id: "situation.edit.header",
  },
  ariaProgressLabel: {
    id: "situation.edit.ariaProgressLabel",
  },
});

export const SUITABILITY_ASSESSMENT_EDIT_PAGE = "/suitability-assessment";

export const BASE_ROUTES = {
  SITUATION: `${SUITABILITY_ASSESSMENT_EDIT_PAGE}/`,
  ACCOUNT_SITUATION: `${SUITABILITY_ASSESSMENT_EDIT_PAGE}/accounts-situation/:accountId`,
  ACCOUNTS_ALLOCATION: `${SUITABILITY_ASSESSMENT_EDIT_PAGE}/accounts-allocation`,
  DONE: `${SUITABILITY_ASSESSMENT_EDIT_PAGE}/done`,
};

const SuitabilityAssessmentStoryInstance: VoidFunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory<SuitabilityAssessmentReturnState | undefined>();
  const safeNavigation = useSafeNavigation();
  const isPerson = useIsPerson();
  const [accountsState, setAccountsState] = useAccountsContext();
  const [suitabilityAssessmentState] = useSuitabilityAssessmentContext();
  const navigateToReviewAccount = useNavigateToReviewAccount();

  // Summarises the usable routes
  const ROUTE_PATHS = Object.entries(BASE_ROUTES).reduce(
    (ROUTES, [key, path]) => ({ ...ROUTES, [key]: getNavLink(path) }),
    {} as typeof BASE_ROUTES
  );

  // Contains all used routes (paths can be used multiple times)
  const ACTUAL_ROUTES = useMemo(() => {
    return Object.entries(BASE_ROUTES).reduce((routes, [key, path]) => {
      if (path === BASE_ROUTES.ACCOUNT_SITUATION) {
        return {
          ...routes,
          ...accountsState.preferenceAccounts.reduce(
            (situationRoutes, account, index) => {
              return {
                ...situationRoutes,
                [`${key}_${index}`]: generatePath(path, {
                  accountId: account.accountId,
                }),
              };
            },
            {}
          ),
        };
      }
      return { ...routes, [key]: path };
    }, {} as { [key: string]: string });
  }, [accountsState.preferenceAccounts]);

  const currentIndex = Object.values(ACTUAL_ROUTES).findIndex((path) => {
    return (
      matchPath(history.location.pathname, {
        path: getNavLink(path),
        exact: true,
      }) !== null
    );
  });

  const storyProgress =
    (100 / Object.values(ACTUAL_ROUTES).length) * (currentIndex + 1);

  const storyLength = Object.values(ACTUAL_ROUTES).length;

  const onBack = useCallback(() => {
    const currentRoute = getNavLink(Object.values(ACTUAL_ROUTES)[currentIndex]);
    const previousRoute = getNavLink(
      Object.values(ACTUAL_ROUTES)[currentIndex - 1]
    );

    switch (currentRoute) {
      case ROUTE_PATHS.ACCOUNT_SITUATION: {
        safeNavigation(
          generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
            accountId:
              accountsState.preferenceAccounts[
                accountsState.preferenceAccounts.length - 1
              ].accountId,
          })
        );
        return;
      }
      case ROUTE_PATHS.ACCOUNTS_ALLOCATION: {
        safeNavigation(
          generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
            accountId:
              accountsState.preferenceAccounts[
                accountsState.preferenceAccounts.length - 1
              ].accountId,
          })
        );
        return;
      }
    }
    safeNavigation(previousRoute);
  }, [
    ACTUAL_ROUTES,
    ROUTE_PATHS.ACCOUNTS_ALLOCATION,
    ROUTE_PATHS.ACCOUNT_SITUATION,
    currentIndex,
    accountsState.preferenceAccounts,
    safeNavigation,
  ]);

  useEffect(() => {
    // Here we fetch all accounts
    // When entering a substory we pass the accounts into that substory
    // When returning back here we don't want to fetch the accounts again,
    // because that would override any modified data
    // The accounts that get modified in the substory will be returned back to
    // this story using history state. This is handled in DirectAccessGuard
    if (
      accountsState.preferenceAccounts.length ||
      history.location.state?.accountsState
    ) {
      return;
    }

    Promise.all([
      dataAccounts.getAccounts(),
      dataInvestments.getAdviseAccounts(),
    ]).then(([accounts, adviseAccounts]) => {
      const preferenceAccounts = accounts.reduce<AccountPreferences[]>(
        (accounts, account) => {
          const adviseAccount = adviseAccounts.find(
            (adviceAccount) => adviceAccount.accountId === account.accountId
          );
          if (adviseAccount) {
            return [
              ...accounts,
              {
                ...account,
                ...adviseAccount,
                needEarlier: isNeedEarlierProbability(adviseAccount.needEarlier)
                  ? adviseAccount.needEarlier
                  : undefined,
                savingsHorizon: isSavingsHorizonLength(
                  adviseAccount.savingsHorizon
                )
                  ? adviseAccount.savingsHorizon
                  : undefined,
              },
            ];
          }
          return accounts;
        },
        []
      );
      setAccountsState({ preferenceAccounts });
    });
  }, [
    setAccountsState,
    accountsState.preferenceAccounts,
    history.location.state,
  ]);

  return (
    <PageStripped className="suitability-assessment-story">
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex !== 0 && currentIndex !== storyLength - 1}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onBack={onBack}
        onExit={() =>
          safeNavigation(
            suitabilityAssessmentState.returnUrl ??
              getNavLink(OVERVIEW_PAGE_URL)
          )
        }
      >
        <Switch
          location={history.location}
          {...{
            order: currentIndex,
          }}
        >
          <AccessGuardRoute exact path={ROUTE_PATHS.SITUATION}>
            {isPerson ? (
              <SituationPerson
                next={() =>
                  // navigate to first account situation
                  safeNavigation(
                    generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                      accountId: accountsState.preferenceAccounts[0].accountId,
                    })
                  )
                }
              />
            ) : (
              <SituationCorporation
                next={() =>
                  // navigate to first account situation
                  safeNavigation(
                    generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                      accountId: accountsState.preferenceAccounts[0].accountId,
                    })
                  )
                }
              />
            )}
          </AccessGuardRoute>
          {accountsState.preferenceAccounts.map((account, index, arr) => {
            function setAccount(account: AccountPreferences) {
              const accounts = arr;
              accounts.splice(index, 1, account);
              setAccountsState({ preferenceAccounts: accounts });
            }

            return (
              <AccessGuardRoute
                key={account.accountId}
                path={generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                  accountId: account.accountId,
                })}
              >
                <EligibilityAccessGuard
                  fallbackRoute={ROUTE_PATHS.SITUATION}
                  isValid={isValidEconomy}
                >
                  <AccountSituation
                    account={account}
                    setAccount={setAccount}
                    next={() => {
                      // navigate to next account situation or go to accounts allocation
                      if (index < accountsState.preferenceAccounts.length - 1) {
                        return safeNavigation(
                          generatePath(ROUTE_PATHS.ACCOUNT_SITUATION, {
                            accountId:
                              accountsState.preferenceAccounts[index + 1]
                                .accountId,
                          })
                        );
                      }
                      safeNavigation(ROUTE_PATHS.ACCOUNTS_ALLOCATION);
                    }}
                  />
                </EligibilityAccessGuard>
              </AccessGuardRoute>
            );
          })}
          <AccessGuardRoute exact path={ROUTE_PATHS.ACCOUNTS_ALLOCATION}>
            <EligibilityAccessGuard
              fallbackRoute={ROUTE_PATHS.SITUATION}
              isValid={isValidEconomy}
            >
              <AccountsAllocation
                next={() => safeNavigation(ROUTE_PATHS.DONE)}
                navigateReviewRiskWarning={(reviewAccount) =>
                  navigateToReviewAccount(
                    REVIEW_ACCOUNT_ROUTES.RISK_WARNING,
                    reviewAccount
                  )
                }
                navigateReview={(reviewAccount) => {
                  const hasChangedEsgValues =
                    typeof reviewAccount.newAdvice.esgResult.esgBestMatch !==
                    "undefined";

                  const isAnswersUpdated =
                    typeof reviewAccount.oldAdvice.esgResult !== "undefined" &&
                    typeof reviewAccount.oldAdvice.esgResult.esgAnswers !==
                      "undefined"
                      ? isEsgQuestionsUpdate(
                          reviewAccount.newAdvice.esgResult.esgAnswers,
                          reviewAccount.oldAdvice.esgResult.esgAnswers
                        )
                      : true;

                  if (hasChangedEsgValues && isAnswersUpdated) {
                    navigateToReviewAccount(
                      REVIEW_ACCOUNT_ROUTES.CONFIRM_ESG_UPDATE,
                      reviewAccount
                    );
                  } else {
                    navigateToReviewAccount(
                      REVIEW_ACCOUNT_ROUTES.REVIEW_HORIZON_ADVICE,
                      reviewAccount
                    );
                  }
                }}
              />
            </EligibilityAccessGuard>
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTE_PATHS.DONE}>
            <Done />
          </AccessGuardRoute>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const SuitabilityAssessmentStory: VoidFunctionComponent = () => {
  return (
    <SuitabilityAssessmentContextProvider>
      <ReviewAccountContextProvider>
        <EligibilityContextProvider>
          <AccountsContextProvider>
            <SuitabilityAssessmentStoryInstance />
          </AccountsContextProvider>
        </EligibilityContextProvider>
      </ReviewAccountContextProvider>
    </SuitabilityAssessmentContextProvider>
  );
};
