import React, { VoidFunctionComponent } from "react";
import { useIntl } from "react-intl";
import { Isin } from "../../data/dataFunds";
import { isTranslatedFund, holdingsMessages } from "../fundName/FundName";

interface Props {
  isin: Isin;
}

export const InstrumentName: VoidFunctionComponent<Props> = ({ isin }) => {
  const intl = useIntl();
  const isinKey = isin.toString();

  return (
    <React.Fragment>
      {isTranslatedFund(isinKey) &&
        intl.formatMessage(holdingsMessages[isinKey])}
    </React.Fragment>
  );
};
