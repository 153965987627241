import React, { FunctionComponent } from "react";
import { FormattedNumberParts } from "react-intl";
import "./FormattedStyledPercentage.scss";

interface Props {
  value: number;
  decimals?: number;
  showSign?: boolean;
}

export const FormattedStyledPercentage: FunctionComponent<Props> = ({
  value,
  decimals = 0,
  showSign = false,
}) => {
  return (
    <div className="styled-percent">
      <span className="percent-value">
        <FormattedNumberParts
          value={value / 100}
          // eslint-disable-next-line react/style-prop-object
          style="percent"
          minimumFractionDigits={decimals}
          maximumFractionDigits={decimals}
          signDisplay={showSign ? "exceptZero" : "auto"}
        >
          {(parts) => (
            <>
              {parts.map((part, idx) => {
                if (part.type === "percentSign") {
                  return (
                    <span
                      className="percent-sign"
                      key={part.type + part.value + idx}
                    >
                      {part.value}
                    </span>
                  );
                } else {
                  return (
                    <span key={part.type + part.value + idx}>{part.value}</span>
                  );
                }
              })}
            </>
          )}
        </FormattedNumberParts>
      </span>
    </div>
  );
};
