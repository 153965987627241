import {
  Card,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { AccountType } from "../../../../components/accountType/AccountType";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { InvestmentAccount, dataAccounts } from "../../../../data/dataAccounts";
import {
  AdviseAccount,
  dataInvestments,
} from "../../../../data/dataInvestments";
import { ClosedAccount, dataProfile } from "../../../../data/dataProfile";
import { dataTax, TaxAccountYears } from "../../../../data/dataTax";
import { Page, PageHeader } from "../../../../pages/Page";
import { ListItem } from "./components/listItem/ListItem";
import { SavingsAccountTaxDetails } from "./components/savingsAccountTaxDetails/SavingsAccountTaxDetails";
import { InvestmentAccountTaxDetails } from "./components/investmentAccountTaxDetails/InvestmentAcountTaxDetails";

import "./TaxInformation.scss";
import "./TaxPrint.scss";

const messages = defineMessages({
  yearsLabel: {
    id: "sweden.tax.years.label",
  },
  accountsLabel: {
    id: "sweden.tax.accounts.label",
  },
});

export enum SELECTED {
  SAVINGS_ACCOUNT = "SAVINGS_ACCOUNT",
}

const SCROLL_ID = "declaration-wrapper";

export function Tax() {
  const [accounts, setAccounts] =
    useState<(InvestmentAccount | ClosedAccount)[]>();
  const [declarationToShow, setDeclarationToShow] = useState<
    InvestmentAccount | ClosedAccount | SELECTED.SAVINGS_ACCOUNT
  >();
  const [year, setYear] = useState<number>();
  const [accountsAndYears, setAccountsAndYears] = useState<{
    investmentAccounts: TaxAccountYears[];
    savingsAccounts: number[];
  }>({ investmentAccounts: [], savingsAccounts: [] });
  const [filteredInvestmentAccounts, setFilteredInvestmentAccounts] = useState<
    (InvestmentAccount | ClosedAccount)[]
  >([]);
  const [error, setError] = useState(false);
  const [advise, setAdvise] = useState<AdviseAccount[]>();
  const intl = useIntl();

  const years = useMemo(
    () =>
      Array.from(
        new Set([
          ...accountsAndYears.investmentAccounts
            .map((entry) => entry.taxYears)
            .flat(),
          ...accountsAndYears.savingsAccounts,
        ])
      ).sort((a, b) => b - a),
    [accountsAndYears]
  );

  const getFilteredAccountsForYear = useCallback(
    (year: number) => {
      if (typeof accounts === "undefined" || typeof year === "undefined") {
        return [];
      }

      return accounts.filter(({ accountId }) =>
        accountsAndYears.investmentAccounts.some(
          (entry) =>
            entry.accountId === accountId && entry.taxYears.includes(year)
        )
      );
    },
    [accounts, accountsAndYears.investmentAccounts]
  );

  useEffect(() => {
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataProfile.getClosedAccounts(),
      dataInvestments.getAdviseAccounts(),
      dataTax.getIskTaxYears(),
      dataTax.getSavingsAccountTaxYears(),
    ])
      .then(
        ([
          accounts,
          closedAccounts,
          advise,
          iskTaxResponse,
          savingsAccountTaxResponse,
        ]) => {
          setAccounts([...accounts.investmentAccounts, ...closedAccounts]);
          setAdvise(advise);
          setAccountsAndYears({
            investmentAccounts: iskTaxResponse,
            savingsAccounts: savingsAccountTaxResponse,
          });
        }
      )
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (typeof year === "undefined" && years.length > 0) {
      setYear(years[0]);
      setFilteredInvestmentAccounts(getFilteredAccountsForYear(years[0]));
    }
  }, [getFilteredAccountsForYear, year, years]);

  const onListItemClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    declaration: InvestmentAccount | ClosedAccount | SELECTED.SAVINGS_ACCOUNT
  ) => {
    event.preventDefault();
    setDeclarationToShow(declaration);
    if (event.button === 0) {
      document
        .getElementById(SCROLL_ID)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (error) {
    return (
      <Page>
        <Typography type="h1">
          <TranslatedText id="sweden.tax.page-header" />
        </Typography>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="sweden.tax.error" />
        </Snackbar>
      </Page>
    );
  }

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <Page className="tax-page">
      <PageHeader>
        <Typography type="h1">
          <TranslatedText id="sweden.tax.page-header" />
        </Typography>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={4} className="select-card">
          <Typography type="h2">
            <TranslatedText id="sweden.tax.select-header" />
          </Typography>
          <Card>
            <Select
              alternatives={years.map((year) => {
                return { value: year, text: year.toString() };
              })}
              placeholder={intl.formatMessage(messages.yearsLabel)}
              label={intl.formatMessage(messages.yearsLabel)}
              onChange={({ value }) => {
                setYear(value);
                const filteredAccounts = getFilteredAccountsForYear(value);
                setFilteredInvestmentAccounts(filteredAccounts);
                const isSelectedAccountInFilteredAccounts =
                  filteredAccounts.some(
                    (filteredAccount) =>
                      declarationToShow !== SELECTED.SAVINGS_ACCOUNT &&
                      filteredAccount.accountId === declarationToShow?.accountId
                  );
                if (
                  (declarationToShow === SELECTED.SAVINGS_ACCOUNT &&
                    !accountsAndYears.savingsAccounts.includes(value)) ||
                  !isSelectedAccountInFilteredAccounts
                ) {
                  setDeclarationToShow(undefined);
                }
              }}
              value={year ? { value: year, text: year.toString() } : undefined}
            />
            <ul>
              {year && accountsAndYears.savingsAccounts.includes(year) && (
                <ListItem
                  key={SELECTED.SAVINGS_ACCOUNT}
                  onClick={(event) => {
                    onListItemClick(event, SELECTED.SAVINGS_ACCOUNT);
                  }}
                  selected={declarationToShow === SELECTED.SAVINGS_ACCOUNT}
                  primaryText={
                    <TranslatedText id="sweden.tax.savingsAccountLabel.primary" />
                  }
                  secondaryText={
                    <TranslatedText id="sweden.tax.savingsAccountLabel.secondary" />
                  }
                  trailing={{ icon: "ChevronRight" }}
                />
              )}
              {filteredInvestmentAccounts &&
                filteredInvestmentAccounts.map((account) => {
                  const accountAdvise = advise?.find(
                    (advise) => advise.accountId === account.accountId
                  );

                  return (
                    <ListItem
                      key={account.accountId}
                      onClick={(event) => {
                        onListItemClick(event, account);
                      }}
                      primaryText={account.name}
                      trailing={{ icon: "ChevronRight" }}
                      selected={
                        declarationToShow !== SELECTED.SAVINGS_ACCOUNT &&
                        declarationToShow?.accountId === account.accountId
                      }
                      secondaryText={
                        <>
                          <AccountType account={account} />
                          {" • "}
                          <AnimatePresence>
                            <motion.span
                              transition={{ duration: 0.15 }}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                            >
                              {accountAdvise ? (
                                <TranslatedText
                                  id="sweden.tax.investmentAccountLabel.secondary.allocation"
                                  values={{
                                    rawRisk: accountAdvise.takenRisk,
                                    stocks: intl.formatNumber(
                                      accountAdvise.takenRisk / 100,
                                      {
                                        style: "percent",
                                      }
                                    ),
                                    bonds: intl.formatNumber(
                                      (100 - accountAdvise.takenRisk) / 100,
                                      {
                                        style: "percent",
                                      }
                                    ),
                                  }}
                                />
                              ) : (
                                <TranslatedText id="sweden.tax.investmentAccountLabel.secondary.closedAccount" />
                              )}
                            </motion.span>
                          </AnimatePresence>
                        </>
                      }
                    />
                  );
                })}
            </ul>
          </Card>
        </GridCol>
        <GridCol xsmall={12} medium={8} print={12}>
          <div id={SCROLL_ID}>
            {declarationToShow ? (
              declarationToShow === SELECTED.SAVINGS_ACCOUNT ? (
                <SavingsAccountTaxDetails year={year} />
              ) : (
                <InvestmentAccountTaxDetails
                  account={declarationToShow}
                  year={year}
                />
              )
            ) : (
              <>
                <Typography type="h2">
                  <TranslatedText id="sweden.tax.details-header" />
                </Typography>
                <Card>
                  <Typography type="body">
                    <TranslatedText id="sweden.tax.placeholder" />
                  </Typography>
                </Card>
              </>
            )}
          </div>
        </GridCol>
      </GridRow>
    </Page>
  );
}
