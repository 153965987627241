import { LysaCountry } from "@lysaab/countries";
import { useContext } from "react";
import {
  PendingDeposit,
  PendingDepositStatus,
} from "../components/deposits/pendingDeposits/PendingDepositCard";
import { LocalizationContext } from "../context/LocalizationContext";
import {
  AutogiroPaymentResponse,
  dataAutogiro,
  isAutogiroCorporateFeeDeductionResponse,
  PaymentStatus,
} from "../data/dataAutogiro";
import { dataIsk } from "../data/dataIsk";
import { dataKlarna } from "../data/dataKlarna";

const countryPendingDepositMap: Record<
  LysaCountry,
  () => Promise<PendingDeposit[]>
> = {
  [LysaCountry.DENMARK]: () => {
    return Promise.resolve<PendingDeposit[]>([]);
  },
  [LysaCountry.FINLAND]: () => {
    return dataKlarna
      .getPending()
      .then((pendingKlarnaTransfers): PendingDeposit[] => {
        return pendingKlarnaTransfers.map((pendingKlarnaTransfer) => ({
          status: PaymentStatus.SENT,
          amount: pendingKlarnaTransfer.amount,
          accountId: pendingKlarnaTransfer.accountId,
          dateAdded: pendingKlarnaTransfer.created,
          from: pendingKlarnaTransfer.bankAccount,
        }));
      });
  },
  [LysaCountry.SWEDEN]: () => {
    return Promise.all([
      dataKlarna.getPending(),
      dataAutogiro.getPendingAutogiroPayments(),
      dataIsk.getTransferHistory(),
    ]).then(
      ([
        pendingKlarnaTransfers,
        pendingAutogiros,
        iskTransfers,
      ]): PendingDeposit[] => {
        return [
          ...pendingKlarnaTransfers.map((pendingKlarnaTransfer) => {
            return {
              status: PaymentStatus.SENT,
              amount: pendingKlarnaTransfer.amount,
              accountId: pendingKlarnaTransfer.accountId,
              dateAdded: pendingKlarnaTransfer.created,
              from: pendingKlarnaTransfer.bankAccount,
            };
          }),
          ...pendingAutogiros
            .filter(
              (pendingAutogiro): pendingAutogiro is AutogiroPaymentResponse =>
                !isAutogiroCorporateFeeDeductionResponse(pendingAutogiro)
            )
            .map((pendingAutogiro) => {
              return {
                status: pendingAutogiro.paymentStatus,
                abort: () => {
                  return dataAutogiro.removePayment(
                    pendingAutogiro.transactionId
                  );
                },
                accountId: pendingAutogiro.accountId,
                amount: pendingAutogiro.amount,
                transactionId: pendingAutogiro.transactionId,
                dateAdded: pendingAutogiro.created,
              };
            }),
          ...iskTransfers
            .filter(
              (iskTransfer) => typeof iskTransfer.completed === "undefined"
            )
            .map((pendingIskTransfer) => {
              return {
                status: PendingDepositStatus.ISK_TRANSFER,
                accountId: pendingIskTransfer.accountId,
                amount: pendingIskTransfer.amount
                  ? pendingIskTransfer.amount
                  : 0,
                transactionId: String(pendingIskTransfer.transferId),
                dateAdded: pendingIskTransfer.created,
              };
            }),
        ];
      }
    );
  },
  [LysaCountry.GERMANY]: () => {
    return dataKlarna
      .getPending()
      .then((pendingKlarnaTransfers): PendingDeposit[] => {
        return pendingKlarnaTransfers.map((pendingKlarnaTransfer) => {
          return {
            status: PaymentStatus.SENT,
            amount: pendingKlarnaTransfer.amount,
            accountId: pendingKlarnaTransfer.accountId,
            dateAdded: pendingKlarnaTransfer.created,
            from: pendingKlarnaTransfer.bankAccount,
          };
        });
      });
  },
  [LysaCountry.SPAIN]: () => {
    return dataKlarna
      .getPending()
      .then((pendingKlarnaTransfers): PendingDeposit[] => {
        return pendingKlarnaTransfers.map((pendingKlarnaTransfer) => {
          return {
            status: PaymentStatus.SENT,
            amount: pendingKlarnaTransfer.amount,
            accountId: pendingKlarnaTransfer.accountId,
            dateAdded: pendingKlarnaTransfer.created,
            from: pendingKlarnaTransfer.bankAccount,
          };
        });
      });
  },
};

export function usePendingDeposits() {
  const localizationContext = useContext(LocalizationContext);

  if (typeof localizationContext.state.country === "undefined") {
    return;
  }
  return countryPendingDepositMap[localizationContext.state.country];
}
