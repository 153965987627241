import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import cx from "classnames";
import { KFAccountContext } from "../KFAccountContext";
import { useHistory } from "react-router";
import {
  LysaFormRef,
  Form,
  Card,
  CheckboxGroup,
  RequiredValidator,
  RadioGroup,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import {
  KFPrecondition,
  dataCreateAccount,
  KFPresaleInfo,
  KFPreconditionState,
  KFAccountCreationState,
  KFSigningStatusPendingResponse,
} from "../../../../../../data/dataCreateAccount";
import { KfError } from "./KfError";
import { Retry, Status } from "../../../../../../components/retry/Retry";
import AnimateHeight from "react-animate-height";
import { TaxQuestion } from "./TaxQuestion";
import { BeneficiaryQuestion } from "./BeneficiaryQuestion";
import { OwnershipQuestion } from "./OwnershipQuestion";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";

interface Props {
  exitRoute: string;
  lysaKycRoute: string;
  lysaSummaryRoute: string;
  futurSummaryRoute: string;
}

const INSURANCE_FEE = "0,2";

export const messages = defineMessages({
  positiveConfirmation: {
    id: "sweden.kf.confirm.yes",
    defaultMessage: "Yes",
  },
  negativeConfirmation: {
    id: "sweden.kf.confirm.no",
    defaultMessage: "No",
  },
  taxConfirmation: {
    id: "sweden.kf.genericTax",
    defaultMessage:
      "I want to invest the corporations liquid assets in an account with generic tax.",
  },
  taxConfirmationRequiredValidator: {
    id: "sweden.kf.genericTax.validator",
    defaultMessage:
      "You need to confirm that your corporation has a need for such an account",
  },
  experienceConfirmation: {
    id: "sweden.kf.experience",
    defaultMessage:
      "Do you, representing the corporation, have experience in such an account?",
  },
  genericRequiredValidator: {
    id: "generic.validation.required",
    defaultMessage: "You must select an option",
  },
});

export const KfBody: React.FunctionComponent<Props> = ({
  exitRoute,
  lysaKycRoute,
  lysaSummaryRoute,
  futurSummaryRoute,
}) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const history = useHistory();
  const [status, setStatus] = useState(Status.PENDING);
  const [error, setError] = useState<KFPrecondition | undefined>();
  const [checkRegistrationError, setCheckRegistrationError] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  const taxConfirmationAlternatives = [
    {
      value: true,
      text: intl.formatMessage(messages.positiveConfirmation),
    },
  ];

  const continueRegistration = useCallback(() => {
    setStatus(Status.PENDING);
    if (!kfState.signingId) {
      return;
    }
    dataCreateAccount
      .getKFSigningStatus(kfState.signingId)
      .then((data) => {
        if (
          data.accountCreationState === KFAccountCreationState.PENDING_LYSA_SIGN
        ) {
          const castedData = data as KFSigningStatusPendingResponse;
          setKFState({
            takenRisk: castedData.takenRisk,
            investmentType: castedData.investmentType,
            identificationNumber: castedData.tin,
          });

          history.push(lysaSummaryRoute);
        } else {
          const nextRoute = futurSummaryRoute.replace(
            ":signingId",
            kfState.signingId || ""
          );
          history.push(nextRoute);
        }
      })
      .catch(() => {
        setCheckRegistrationError(true);
      });
  }, [
    kfState.signingId,
    history,
    lysaSummaryRoute,
    futurSummaryRoute,
    setKFState,
  ]);

  const load = useCallback(() => {
    const promiseKFPreconditions = dataCreateAccount.getKFPreconditions();
    const promiseKFPresaleInfo = dataCreateAccount.getKFPresaleInfo();

    Promise.all([promiseKFPreconditions, promiseKFPresaleInfo])
      .then((data) => {
        const [preconditionsData, presaleInfoData]: [
          KFPrecondition[],
          KFPresaleInfo
        ] = data;

        setKFState({
          presaleInfo: presaleInfoData,
        });

        if (preconditionsData.length > 0) {
          let ongoing: KFPrecondition | undefined;
          let errors: KFPrecondition[] = [];

          preconditionsData.forEach((item: any) => {
            if (
              item.failedPrecondition ===
              KFPreconditionState.PENDING_INSURANCE_APPLICATION
            ) {
              ongoing = item;
            } else {
              errors.push(item);
            }
          });

          if (ongoing) {
            const { ongoingSigningId: signingId } = ongoing;
            setKFState({
              ongoing: true,
              signingId,
            });
          } else {
            setError(errors[0]); // Unable to continue - show first error
          }
        }
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [setKFState]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    let timer: number;
    if (kfState.signingId) {
      timer = window.setTimeout(() => continueRegistration, 1000);
    } else {
      timer = window.setTimeout(() => load, 1000);
    }
    return () => clearTimeout(timer);
  }, [load, continueRegistration, kfState.signingId]);

  useEffect(load, [load]);

  useEffect(() => {
    if (showInfo) {
      return;
    }

    if (kfState.taxConfirmation.length) {
      setShowInfo(true);
    }
  }, [kfState.taxConfirmation, showInfo]);

  if (error) {
    return <KfError error={error} exitRoute={exitRoute} />;
  }

  if (kfState.signingId) {
    return (
      <Card>
        <div className="relative">
          <h2>
            <TranslatedText
              id="sweden.kf.futur"
              defaultMessage="Insurance account"
            />
          </h2>

          <div
            className={cx("error-wrapper", {
              "has-error": checkRegistrationError,
            })}
          >
            <Snackbar icon={true} type={SNACKBAR_TYPES.ERROR}>
              <div>
                <TranslatedText
                  id="generic.error"
                  description="kf body - generic preload error"
                  defaultMessage="Ouch, something went wrong."
                />
                <br />
                <Button
                  size="small"
                  block
                  onClick={() => {
                    setCheckRegistrationError(false);
                    continueRegistration();
                  }}
                  label={<TranslatedText id="generic.retry" />}
                />
              </div>
            </Snackbar>
          </div>
          <p>
            <TranslatedText
              id="sweden.kf.offer"
              values={{
                fee: INSURANCE_FEE,
              }}
            />
          </p>

          <div className="compressed-snackbar">
            <Snackbar type={SNACKBAR_TYPES.INFO}>
              <p>
                <TranslatedText
                  id="sweden.kf.ongoing"
                  description="kf body - lysa already ongoing registration"
                  defaultMessage="Looks like there is already an ongoing registration."
                />
              </p>
              <div>
                <Button
                  block
                  onClick={continueRegistration}
                  label={<TranslatedText id="sweden.kf.ongoing.continue" />}
                />
              </div>
            </Snackbar>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Retry retry={retry} status={status}>
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid && kfState.taxConfirmation.length) {
            history.push(lysaKycRoute);
            return;
          }

          if (
            kfState.taxConfirmation &&
            kfState.hasInvested?.value &&
            kfState.taxConfirmation.length
          ) {
            history.push(lysaKycRoute);
            return;
          }
        }}
      >
        <Card>
          <h2>
            <TranslatedText
              id="sweden.kf"
              description="kf body - insurance account header"
              defaultMessage="Insurance account"
            />
          </h2>
          <p>
            <TranslatedText
              id="sweden.kf.offer"
              values={{
                fee: INSURANCE_FEE,
              }}
            />
          </p>

          <CheckboxGroup
            header={intl.formatMessage(messages.taxConfirmation)}
            onChange={(values) => {
              setKFState({
                taxConfirmation: values,
              });
            }}
            values={kfState.taxConfirmation}
            alternatives={taxConfirmationAlternatives}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.taxConfirmationRequiredValidator)
              ),
            ]}
          />

          <AnimateHeight height={showInfo ? "auto" : 0} animateOpacity={true}>
            <div className="intro-divider">
              <p>
                <TranslatedText
                  id="sweden.kf.legal"
                  description="kf body - legal"
                  defaultMessage="By law Lysa is required to confirm your experience and knowledge before opening the insurance account"
                />
              </p>
              <RadioGroup
                header={intl.formatMessage(messages.experienceConfirmation)}
                alternatives={[
                  {
                    text: intl.formatMessage(messages.positiveConfirmation),
                    value: true,
                  },
                  {
                    text: intl.formatMessage(messages.negativeConfirmation),
                    value: false,
                  },
                ]}
                value={kfState.hasInvested}
                onChange={(value) =>
                  setKFState({
                    hasInvested: value,
                  })
                }
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.genericRequiredValidator)
                  ),
                ]}
              />

              <AnimateHeight
                height={kfState.hasInvested?.value === false ? "auto" : 0}
                animateOpacity={true}
              >
                <div className="question-wrapper">
                  <div className="experience-questions">
                    <TaxQuestion />
                    <BeneficiaryQuestion />
                    <OwnershipQuestion />
                  </div>
                </div>
              </AnimateHeight>
            </div>
          </AnimateHeight>

          <Button
            block
            type="submit"
            label={<TranslatedText id="sweden.kf.link" />}
          />
        </Card>
      </Form>
    </Retry>
  );
};
