import React from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  CashTransaction,
  CashTransactionType,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

export const InterestTransactionRow = ({
  transaction,
}: {
  transaction: CashTransaction;
}) => {
  if (transaction.type !== CashTransactionType.INTEREST) return null;
  return (
    <React.Fragment>
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.interestTransactionTypeHeader" />
        </dt>
        <dd>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.interestTransactionType" />
        </dd>
      </div>
    </React.Fragment>
  );
};
