import React from "react";
import { FormattedNumber } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  FundTransactionType,
  FundTransaction,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

export const SwitchSellTransactionRows = ({
  transaction,
}: {
  transaction: FundTransaction;
}) => {
  if (transaction.type !== FundTransactionType.SWITCH_SELL) return null;
  return (
    <div className="transaction-card-row ">
      <dt>
        <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.shares.SwitchSellTransactionRows" />
      </dt>
      <dd>
        <FormattedNumber
          value={transaction.volume}
          minimumFractionDigits={4}
          maximumFractionDigits={4}
        />
      </dd>
    </div>
  );
};
