import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import { Page, PageHeader } from "../../Page";
import { NOT_FOUND_URL } from "../../NotFoundPage";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { Retry, Status } from "../../../components/retry/Retry";
import { dataAccounts } from "../../../data/dataAccounts";
import { GraphCard } from "../../overview/GraphCard";
import { Allocation } from "../Allocation";
import { Transactions } from "../Transactions";
import { AccountName } from "../../../components/accountName/AccountName";
import { Monthly } from "../Monthly";
import { Fees } from "../fees/Fees";
import { Positions } from "../positions/Positions";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { MONTHLY_OVERVIEW_PAGE } from "../../deposits/monthly/overview/MonthlyOverview";
import { useSharedInvestmentAccount } from "../../../hooks/useSharedInvestmentAccount";
import { useAccounts } from "../../../hooks/useAccounts";
import { TranslatedText } from "../../../components/TranslatedText";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import "./SharedAccountPage.scss";
import { FollowedAccount } from "../FollowedAccount";
import { AccountShareWithMe, dataAttorney } from "../../../data/dataAttorney";
import { CheckForMissingAccountWrapper } from "../CheckForMissingAccountWrapper";
import { NewIcon } from "@lysaab/ui-2";

export const SHARED_ACCOUNT_PAGE_URL = "/shared-account/:accountId";

interface Match {
  accountId: InvestmentAccountId;
}

const SharedAccountPageInner: React.VFC = () => {
  const match = useRouteMatch<Match>({
    path: getNavLink(SHARED_ACCOUNT_PAGE_URL),
  });
  const { account, getAccount, error, accountNotFound } =
    useSharedInvestmentAccount(match?.params.accountId);
  const { accounts: allAccounts } = useAccounts();

  const [accountShare, setAccountShare] = useState<AccountShareWithMe>();

  const accounts = useMemo(
    () => allAccounts?.sharedInvestmentAccounts ?? [],
    [allAccounts]
  );

  useEffect(() => {
    if (typeof account === "undefined") {
      return;
    }

    dataAttorney
      .getAccountShareWithMeForAccount(account.accountId)
      .then((shares) => shares.length > 0 && setAccountShare(shares[0]));
  }, [account]);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(getAccount, 500);
  }, [getAccount]);

  if (accountNotFound) {
    return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
  }

  return (
    <Page className="shared-account-page">
      <Retry
        retry={retry}
        status={
          error
            ? Status.ERROR
            : typeof account !== "undefined"
            ? Status.SUCCESS
            : Status.PENDING
        }
      >
        <PageHeader>
          <h1 className="shared-account-page__header">
            {account ? (
              <AccountName
                account={account}
                showAccountType={true}
                showAmount={false}
              />
            ) : null}
          </h1>
          <div className="shared-account-page__subheader">
            <NewIcon.Accounts className="icon" size={20} />
            <span>
              <TranslatedText
                id="sharedAccountPage.sharedBy"
                values={{ name: accountShare?.ownerName }}
              />
            </span>
          </div>
        </PageHeader>
        <GraphCard accounts={accounts} account={account} />
      </Retry>

      <div className="shared-account-page__grid">
        <GridRow>
          <GridCol
            xsmall={12}
            medium={4}
            className="shared-account-page__section-wrapper"
          >
            <div className="shared-account-page__section">
              <Allocation account={account} showAction={false} />
            </div>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="shared-account-page__section-wrapper"
          >
            <div className="shared-account-page__section">
              <Transactions account={account} accounts={accounts} />
            </div>
          </GridCol>

          <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
            <GridCol
              xsmall={12}
              medium={4}
              className="shared-account-page__section-wrapper"
            >
              <div className="shared-account-page__section">
                <Monthly account={account} showAction={false} />
              </div>
            </GridCol>
          </RouteAwareToggle>
        </GridRow>

        <GridRow>
          <GridCol
            xsmall={12}
            medium={8}
            className="shared-account-page__section-wrapper"
          >
            <div className="shared-account-page__section">
              <Positions account={account} accounts={accounts} />
            </div>
          </GridCol>
          <GridCol
            xsmall={12}
            medium={4}
            className="shared-account-page__section-wrapper"
          >
            <div className="shared-account-page__section">
              <Fees account={account} accounts={accounts} showAction={false} />
            </div>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol
            xsmall={12}
            medium={4}
            className="shared-account-page__section-wrapper"
          >
            <section className="shared-account-page__section">
              <h2>
                <TranslatedText id="sharedAccountPage.followedAccountHeader" />
              </h2>
              <FollowedAccount accountShare={accountShare} />
            </section>
          </GridCol>
        </GridRow>
      </div>
    </Page>
  );
};

export const SharedAccountPage: React.VFC = () => {
  return (
    <CheckForMissingAccountWrapper>
      <SharedAccountPageInner />
    </CheckForMissingAccountWrapper>
  );
};
