import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  dataInvestments,
  getAccountQuestions,
  GetSuitabilityAssessmentRequest,
  GetSuitabilityAssessmentResponse,
  isValidGetSuitabilityAssessmentRequest,
} from "../../../../../../data/dataInvestments";
import { ConfirmEsgUpdate } from "../../../../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";
import { CreateAccountContext } from "../../../../../../pages/createAccount/CreateAccountContext";
import { Spinner } from "@lysaab/ui-2";

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdateWrapper: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();
  const localizationContext = useContext(LocalizationContext);
  const createAccountContext = useContext(CreateAccountContext);

  useEffect(() => {
    const data: Partial<GetSuitabilityAssessmentRequest> = {
      language: localizationContext.state.language,
      ...getAccountQuestions(createAccountContext.state),
    };

    if (!isValidGetSuitabilityAssessmentRequest(data)) {
      throw new Error("ConfirmEsgUpdateWrapper - Missing data object");
    }

    dataInvestments.getNewAccountSuitability(data).then((advise) => {
      if (!advise.esgResult.esgBestMatch) {
        return next();
      }
      setAdvise(advise);
    });
  }, [createAccountContext.state, localizationContext.state.language, next]);

  if (!advise) {
    return <Spinner />;
  }

  return (
    <ConfirmEsgUpdate
      esg={advise.esgResult.esgAnswers}
      updatedEsg={advise.esgResult.esgBestMatch!}
      next={next}
    />
  );
};
