import React, { FunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TaxonomyImportance } from "../../data/dataInvestments";
import { TaxonomyQuestionInfoModal } from "./modals/TaxonomyQuestionInfoModal";
import { useIsPerson } from "../../hooks/useIsPerson";

const messages = defineMessages({
  required: { id: "sustainability.taxonomy.required" },
});

export const taxonomyAltMessages = defineMessages<TaxonomyImportance>({
  [TaxonomyImportance.NONE]: {
    id: "sustainability.taxonomy.alt.NONE",
  },
  [TaxonomyImportance.LEAST_25]: {
    id: "sustainability.taxonomy.alt.LEAST_25",
  },
  [TaxonomyImportance.LEAST_50]: {
    id: "sustainability.taxonomy.alt.LEAST_50",
  },
  [TaxonomyImportance.LEAST_75]: {
    id: "sustainability.taxonomy.alt.LEAST_75",
  },
});

interface Props {
  taxonomy?: TaxonomyImportance | Alternative<TaxonomyImportance>;
  setTaxonomy: (SFDR: Alternative<TaxonomyImportance>) => void;
}

export const TaxonomyQuestion: FunctionComponent<Props> = ({
  taxonomy,
  setTaxonomy,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(TaxonomyImportance).map((key) => ({
    text: intl.formatMessage(taxonomyAltMessages[key]),
    value: key,
  }));

  const value =
    typeof taxonomy === "string"
      ? {
          text: intl.formatMessage(taxonomyAltMessages[taxonomy]),
          value: taxonomy,
        }
      : taxonomy;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            {isPerson ? (
              <FormattedMessage id="sustainability.taxonomy.header" />
            ) : (
              <FormattedMessage id="sustainability.corp.taxonomy.header" />
            )}
            <br />
            <p>
              <TaxonomyQuestionInfoModal />
            </p>
          </>
        }
        onChange={setTaxonomy}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
