import { SegmentedSelect } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { Tab } from "../../components/tab/tab/Tab";
import { TabView } from "../../components/tab/tabView/TabView";
import { CompoundAccountId } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useQuery } from "../../hooks/useQuery";
import { Page, PageHeader } from "../Page";
import { ReceivedInvitations } from "./ReceivedInvitations";
import { SentInvitations } from "./SentInvitations";
import "./ShareAccountInvitationsPage.scss";

export const SHARE_ACCOUNT_INVITATIONS_URL = "/share-account-invitation";

export enum ShareAccountInvitationsView {
  RECEIVED = "received",
  SENT = "sent",
}

export const SHARE_ACCOUNT_INVITATIONS_VIEW_URL = (
  view = ShareAccountInvitationsView.RECEIVED,
  accountId?: CompoundAccountId
) => {
  const search = new URLSearchParams();
  search.set("view", view);
  if (accountId) {
    search.set("accountId", accountId);
  }
  return `${SHARE_ACCOUNT_INVITATIONS_URL}?${search.toString()}`;
};

const messages = defineMessages({
  receivedButtonText: {
    id: "shareAccountInvitationsPage.receivedButtonText",
  },
  sentButtonText: {
    id: "shareAccountInvitationsPage.sentButtonText",
  },
});

interface QueryParams {
  view?: ShareAccountInvitationsView;
  accountId?: CompoundAccountId;
}

export const ShareAccountInvitationsPageInternal: React.VFC = React.memo(() => {
  const query = useQuery<QueryParams>();
  const history = useHistory();
  const intl = useIntl();

  const view = query.view || ShareAccountInvitationsView.RECEIVED;
  const selectedAccount = query.accountId;

  const alternatives = [
    {
      text: intl.formatMessage(messages.receivedButtonText),
      value: ShareAccountInvitationsView.RECEIVED,
    },
    {
      text: intl.formatMessage(messages.sentButtonText),
      value: ShareAccountInvitationsView.SENT,
    },
  ];

  const handleViewChange = (value: ShareAccountInvitationsView) => {
    const search = new URLSearchParams(history.location.search);
    search.set("view", value);
    history.replace({
      pathname: getNavLink(SHARE_ACCOUNT_INVITATIONS_URL),
      search: search.toString(),
    });
  };

  return (
    <div className="share-account-invitations-page">
      <GridRow>
        <GridCol medium={6} large={4}>
          <SegmentedSelect
            alternatives={alternatives}
            value={{
              text: "",
              value: view,
            }}
            onChange={({ value }) => handleViewChange(value)}
          />
        </GridCol>
      </GridRow>
      <TabView activeTab={view} onTabChange={handleViewChange}>
        <Tab tabroute={ShareAccountInvitationsView.RECEIVED}>
          <ReceivedInvitations />
        </Tab>
        <Tab tabroute={ShareAccountInvitationsView.SENT}>
          <SentInvitations selectedAccount={selectedAccount} />
        </Tab>
      </TabView>
    </div>
  );
});

export const ShareAccountInvitationsPage: React.VFC = React.memo(() => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <FormattedMessage id="shareAccountInvitationsPage.header" />
        </h1>
      </PageHeader>
      <ShareAccountInvitationsPageInternal />
    </Page>
  );
});
