import React from "react";
import { Amount } from "../../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  FundTransactionType,
  FundTransaction,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

export const SellTransactionRows = ({
  transaction,
}: {
  transaction: FundTransaction;
}) => {
  if (transaction.type !== FundTransactionType.SELL) return null;
  return (
    <div className="transaction-card-row ">
      <dt>
        <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.amount.SellTransactionRows" />
      </dt>
      <dd>
        <Amount amount={transaction.amount} decimals={2} />
      </dd>
    </div>
  );
};
