import React, { VoidFunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { NeedEarlierProbability } from "../../../data/dataInvestments";
import { useIsPerson } from "../../../hooks/useIsPerson";

const messages = defineMessages({
  header: {
    id: "horizon.probability.header",
  },
  headerCorp: {
    id: "horizon.corp.probability.header",
  },
  required: {
    id: "horizon.probability.required",
  },
});

interface MessageWithId {
  id: string;
}

const alternativeMessages = defineMessages<
  NeedEarlierProbability,
  MessageWithId,
  Record<NeedEarlierProbability, MessageWithId>
>({
  [NeedEarlierProbability.VERY_UNLIKELY]: {
    id: "horizon.probability.alt.veryunlikely",
  },
  [NeedEarlierProbability.UNLIKELY]: {
    id: "horizon.probability.alt.unlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_UNLIKELY]: {
    id: "horizon.probability.alt.somewhatunlikely",
  },
  [NeedEarlierProbability.SOMEWHAT_LIKELY]: {
    id: "horizon.probability.alt.somewhatlikely",
  },
  [NeedEarlierProbability.LIKELY]: {
    id: "horizon.probability.alt.likely",
  },
  [NeedEarlierProbability.VERY_LIKELY]: {
    id: "horizon.probability.alt.verylikely",
  },
});

interface Props {
  needEarlierProbability?:
    | NeedEarlierProbability
    | Alternative<NeedEarlierProbability>;
  setNeedEarlierProbability: (
    horizonProbability: Alternative<NeedEarlierProbability>
  ) => void;
}

export const Probability: VoidFunctionComponent<Props> = ({
  needEarlierProbability,
  setNeedEarlierProbability,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(NeedEarlierProbability).map((key) => ({
    text: intl.formatMessage(alternativeMessages[key]),
    value: key,
  }));

  const value =
    typeof needEarlierProbability === "string"
      ? {
          text: intl.formatMessage(alternativeMessages[needEarlierProbability]),
          value: needEarlierProbability,
        }
      : needEarlierProbability;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          isPerson
            ? intl.formatMessage(messages.header)
            : intl.formatMessage(messages.headerCorp)
        }
        onChange={setNeedEarlierProbability}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="horizon-probability"
      />
    </Card>
  );
};
