import React, { useCallback, useRef, FC } from "react";
import cx from "classnames";
import { CircleButton } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { dataMessages } from "../../data/dataMessages";
import "./AttachFile.scss";

export interface FileState {
  state: string;
  total: string;
  percentage: number;
  id?: number;
}

interface Props {
  onChange: (data: Record<string, FileState>) => void;
  fileStatuses: Record<string, FileState>;
  id: string;
  iconSize?: number;
}

function returnFileSize(fileSize: number): string {
  if (fileSize < 1024) {
    return fileSize + " byte";
  } else if (fileSize >= 1024 && fileSize < 1048576) {
    return (fileSize / 1024).toFixed(0) + " KB";
  } else if (fileSize >= 1048576) {
    return (fileSize / 1048576).toFixed(0) + " MB";
  }
  return "";
}

const fileTypes = [
  "pdf",
  "jpg",
  "jpeg",
  "bmp",
  "png",
  "xls",
  "xlsx",
  "doc",
  "docx",
];

export const AttachFile: FC<Props> = ({
  onChange,
  fileStatuses,
  id,
  iconSize,
}) => {
  const fileElemRef = useRef<HTMLInputElement | null>(null);
  const attachInputName = useRef(
    "attach_" + Math.random().toString(36).substr(2, 9)
  );

  const tryUpload = useCallback(
    (file: File) => {
      const req = new XMLHttpRequest();
      const copy = { ...fileStatuses };
      copy[file.name] = {
        state: "file-pending",
        total: returnFileSize(file.size),
        percentage: 0,
      };
      onChange(copy);

      req.upload.addEventListener("progress", (event) => {
        const copy = { ...fileStatuses };
        if (event.lengthComputable) {
          copy[file.name] = {
            state: "file-pending",
            total: returnFileSize(file.size),
            percentage: (event.loaded / event.total) * 100,
          };
        } else {
          copy[file.name] = {
            state: "file-error",
            total: returnFileSize(file.size),
            percentage: 0,
          };
        }
        onChange(copy);
      });

      req.onload = function () {
        const copy = { ...fileStatuses };
        if (req.status === 200) {
          var res = JSON.parse(req.responseText);
          copy[file.name] = {
            state: "file-done",
            total: returnFileSize(file.size),
            percentage: 100,
            id: res.id,
          };
        } else {
          copy[file.name] = {
            state: "file-error",
            total: returnFileSize(file.size),
            percentage: 0,
          };
          setTimeout(() => {
            const copyRef = { ...fileStatuses };
            delete copyRef[file.name];
            onChange(copyRef);
          }, 4000);
        }
        onChange(copy);
      };

      req.upload.addEventListener("error", (ev) => {
        const copy = { ...fileStatuses };
        copy[file.name] = {
          state: "file-error",
          total: returnFileSize(file.size),
          percentage: 0,
        };
        setTimeout(() => {
          const copyRef = { ...fileStatuses };
          delete copyRef[file.name];
          onChange(copyRef);
        }, 4000);
        onChange(copy);
      });

      const formData = new FormData();
      formData.append("attachment", file);
      formData.append("filename", file.name);
      formData.append("name", "attachment");
      req.open("POST", `${process.env.REACT_APP_API}/messages/attachment`);
      req.withCredentials = true;
      req.send(formData);
    },
    [fileStatuses, onChange]
  );

  const onAttachFile = useCallback(
    (ev) => {
      const len = ev.target.files.length;
      let i = 0;
      for (i = 0; i < len; i++) {
        const file = ev.target.files[i];
        if (!fileStatuses[file.name]) {
          tryUpload(file);
        }
      }
      if (fileElemRef.current) {
        fileElemRef.current.value = "";
      }
    },
    [tryUpload, fileStatuses]
  );

  const removeFile = useCallback(
    (filename) => {
      const copy = { ...fileStatuses };
      const id = copy[filename].id;
      if (id) {
        dataMessages.deleteAttachment(`${id}`);
      }
      delete copy[filename];
      if (fileElemRef.current) {
        fileElemRef.current.value = "";
      }
      onChange(copy);
    },
    [fileStatuses, onChange]
  );

  let noOfAttachedFiles = 0;
  const fileStatusElem: React.ReactNode[] = [];
  Object.keys(fileStatuses).forEach((key) => {
    noOfAttachedFiles++;
    const file = fileStatuses[key];
    fileStatusElem.push(
      <div key={key} className={cx("messages-attached-file", file.state)}>
        <div className="messages-attached-file-name">{key}</div>

        <div className="file-pending-elem">
          <div className="file-pending-elem-bg" />
          <div
            className="file-pending-elem-progress"
            style={{
              transform: `translateX(${-100 + file.percentage}%)`,
            }}
          />
        </div>

        <div className="file-done-elem">
          {file.total}{" "}
          <CircleButton
            variant="outlined"
            onClick={() => {
              removeFile(key);
            }}
            icon="Cancel"
          />
        </div>

        <div className="file-error-elem">
          <FormattedMessage id="messages.files.error" />
        </div>
      </div>
    );
  });

  const classes = `label-file-input-${noOfAttachedFiles}`;

  return (
    <>
      <input
        type="file"
        name={attachInputName.current}
        id={attachInputName.current}
        onChange={onAttachFile}
        ref={fileElemRef}
        disabled={noOfAttachedFiles > 2}
        accept="image/*, .pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/msword"
      />

      <CircleButton
        component={"label"}
        htmlFor={attachInputName.current}
        icon="Attachment"
        className={cx("label-file-input", classes)}
      />

      <div
        className={cx("messages-attached-files", classes, {
          "has-attached-files": noOfAttachedFiles > 0,
        })}
      >
        <div className="messages-attached-files-types">
          <FormattedMessage id="messages.files.allowed" />
          {fileTypes.map((fileType, idx) => {
            return idx === 0 ? (
              <React.Fragment key={fileType}>
                {" "}
                <i>{fileType}</i>
              </React.Fragment>
            ) : (
              <React.Fragment key={fileType}>
                , <i>{fileType}</i>
              </React.Fragment>
            );
          })}
          .
        </div>
        {fileStatusElem}
      </div>
    </>
  );
};
