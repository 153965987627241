import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../../../components/withdrawalItem/WithdrawalItem";
import {
  AccountWorthComplete,
  dataDanica,
} from "../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

import { KfWithdrawalContext, WithdrawalType } from "./KfWithdrawalContext";
import { PartialWithdrawalItem } from "./SigningStates/PartialWithdrawalItem";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";

export const Done: React.FunctionComponent = () => {
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);
  const { account, from, to, signingStatus } = kfWithdrawalState;
  const [accountWorth, setAccountWorth] = useState<AccountWorthComplete>();

  useEffect(() => {
    if (!account?.accountId) {
      return;
    }
    dataDanica.getWithdrawableAmount(account?.accountId).then(setAccountWorth);
  }, [account?.accountId]);

  if (!signingStatus) {
    return null;
  }

  if (!accountWorth) {
    return <Spinner />;
  }

  return (
    <div className="withdrawal-request-page-done">
      <h1>
        <TranslatedText
          id="withdrawalRequest.kf.done.header"
          defaultMessage="Receipt"
          description="withdrawal request kf - done header"
        />
      </h1>
      <Snackbar type={SNACKBAR_TYPES.SUCCESS} icon>
        <div>
          <TranslatedText
            id="withdrawalRequest.kf.done.paragraph"
            defaultMessage="Thanks, we have received your withdrawal"
            description="withdrawal request kf - done paragraph"
          />
        </div>
      </Snackbar>

      {account && from && to ? (
        <WithdrawalItem
          moneyOnAccount={account.worth}
          name={account.name}
          externalBank={to.bank}
          externalBankAccount={to.externalBankAccount}
          withdrawalAmount={
            kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN
              ? account.worth.toString()
              : kfWithdrawalState.amount
          }
        />
      ) : (
        signingStatus.withdrawal && (
          <PartialWithdrawalItem
            accountName={signingStatus.withdrawal.accountName}
            initiatorName={signingStatus.withdrawal.initiatorName}
            accountWorth={signingStatus.withdrawal.accountWorth}
            externalBankAccount={signingStatus.withdrawal.externalBankAccount}
            amount={`${signingStatus.withdrawal.amount}`}
            bank={signingStatus.withdrawal.bank}
            date={new Date(signingStatus.withdrawal.requested)}
          />
        )
      )}
      <Button
        variant="secondary"
        component={Link}
        block
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        label={<TranslatedText id="withdrawalRequest.kf.done.back" />}
      />
    </div>
  );
};
