import React, { FunctionComponent, useContext } from "react";
import { Helmet } from "react-helmet";
import { LocalizationContext } from "./context/LocalizationContext";

export const Metadata: FunctionComponent = () => {
  const localizationContext = useContext(LocalizationContext);
  return (
    <Helmet
      htmlAttributes={{
        lang: localizationContext.state.language,
      }}
    />
  );
};
