import React from "react";
import { formatNumber } from "@lysaab/ui-2";
import { Amount } from "../../../../components/amount/Amount";
import {
  FundType,
  Fund,
  PositionWithFund,
  Isin,
} from "../../../../data/dataFunds";
import {
  InvestmentAccount,
  AccountPosition,
} from "../../../../data/dataAccounts";
import { FormattedMessage } from "react-intl";
import "./PositionsList.scss";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { InstrumentName } from "../../../../components/instrumentName/InstrumentName";
import { isTranslatedFund } from "../../../../components/fundName/FundName";
import { RemainingPositions } from "./RemainingPositions";

interface Props {
  funds: Fund[];
  account: InvestmentAccount;
  fundType: FundType;
}

export const PositionsList: React.VFC<Props> = ({
  funds,
  account,
  fundType,
}) => {
  const positions: AccountPosition[] = Object.entries(account.positions)
    .filter(([, { type }]) => type === fundType)
    .map(([isin, position]) => ({ ...position, isin: isin as Isin }));

  const fundPositions = positions
    .reduce((fundPositions, position) => {
      const fund = funds.find((fund) =>
        fund.fundShareClasses.map(({ isin }) => isin).includes(position.isin)
      );
      const shareClass = fund?.fundShareClasses.find(
        ({ isin }) => isin === position.isin
      );

      if (fund && shareClass) {
        fundPositions.push({
          fund,
          ...position,
          name: shareClass.name,
        });
      }

      return fundPositions;
    }, [] as PositionWithFund[])
    .sort((a, b) => b.worth - a.worth);

  if (!fundPositions.length) {
    return null;
  }

  return (
    <div>
      {fundPositions.map(({ isin, worth, volume, fund, name }) => (
        <React.Fragment key={isin}>
          {fund?.largePositions.map((position) => {
            const translatedInstrument = position.instruments.find(
              (instrument) => isTranslatedFund(instrument.isin)
            );
            return (
              <React.Fragment key={position.instruments[0].isin}>
                <dt>
                  <b>
                    {translatedInstrument ? (
                      <InstrumentName isin={translatedInstrument.isin} />
                    ) : (
                      position.instruments.map(({ name }) => name).join(", ")
                    )}
                  </b>
                </dt>
                <dd>
                  <span>
                    <FormattedPercentage
                      value={position.percent || 0}
                      decimals={2}
                    />
                  </span>
                  <Amount
                    amount={(worth / 100) * position.percent}
                    decimals={2}
                  />
                </dd>
              </React.Fragment>
            );
          })}
          {fund && (
            <RemainingPositions
              positions={fund.remainingPositions}
              percent={fund.remainingPositionsPercent}
              worth={worth}
            />
          )}
          <dt className="summary-cell">
            <b>
              {isTranslatedFund(isin) ? <InstrumentName isin={isin} /> : name}
            </b>
            <Amount amount={worth} />
          </dt>
          <dd className="summary-cell">
            {formatNumber(volume, 4)}{" "}
            <FormattedMessage id="accountPage.positions.shares" />
          </dd>
        </React.Fragment>
      ))}
    </div>
  );
};
