import React, { FunctionComponent, ReactNode } from "react";
import { InvestmentType } from "./InvestmentType";
import { Card, Button, InvestmentType as type, Icon } from "@lysaab/ui-2";
import { messages } from "./InvestmentTypeMessages";
import "./InvestmentTypeCard.scss";
import { useIntl } from "react-intl";

interface Props {
  onClick: () => void;
  buttonText: string | ReactNode;
}

export const InvestmentTypeCardBroad: FunctionComponent<Props> = ({
  onClick,
  buttonText,
}) => {
  const intl = useIntl();

  return (
    <Card className="investment-type-card">
      <div className="earth investment-type-selection-bg">
        <Icon.Earth />
      </div>
      <div className="investment-type-selection-fg">
        <div>
          <h3>
            <InvestmentType investmentType={type.BROAD} capitalize={true} />
          </h3>
          <p>{intl.formatMessage(messages.investmentTypeBroadDesc)}</p>
          <p>{intl.formatMessage(messages.investmentTypeBroadAdditional)}</p>
        </div>

        <Button variant="primary" block onClick={onClick} label={buttonText} />
      </div>
    </Card>
  );
};
