import { Card, Button } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { useHistory } from "react-router";
import { WITHDRAWAL_PAGE_URL } from "../../overview/WithdrawalPage";

export function Done() {
  const history = useHistory();

  return (
    <div>
      <h1>
        <FormattedMessage
          id="widthdrawals.account.story.done.header"
          defaultMessage="Account added"
        />
      </h1>
      <Card>
        <p>
          <FormattedMessage
            id="widthdrawals.account.story.done.text"
            defaultMessage="Your withdrawal account has been added"
          />
        </p>
      </Card>
      <Button
        block
        onClick={() => history.push(getNavLink(WITHDRAWAL_PAGE_URL))}
        label={
          <FormattedMessage id="widthdrawals.account.story.done.button.withdrawal" />
        }
      />

      <Button
        variant="secondary"
        block
        onClick={() => history.push(getNavLink(OVERVIEW_PAGE_URL))}
        label={
          <FormattedMessage id="widthdrawals.account.story.done.button.home" />
        }
      />
    </div>
  );
}
