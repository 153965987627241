import React, { useContext } from "react";
import { Card, Button } from "@lysaab/ui-2";
import { UserContext } from "../../../../../../context/UserContext";
import { TranslatedText } from "../../../../../../components/TranslatedText";

interface Props {
  next: () => void;
}

export function Intro({ next }: Props) {
  const userContext = useContext(UserContext);
  return (
    <div>
      <h1>
        <TranslatedText id="sweden.deposits.monthly.story.intro.header" />
      </h1>
      <Card>
        <p>
          <TranslatedText id="sweden.deposits.monthly.story.intro.text" />
        </p>
        {userContext.state.readOnly && (
          <p>
            <TranslatedText id="sweden.deposits.monthly.story.intro.textReadOnly" />
          </p>
        )}
      </Card>
      {!userContext.state.readOnly && (
        <Button
          block
          onClick={next}
          label={
            <TranslatedText id="sweden.deposits.monthly.story.intro.next" />
          }
        />
      )}
    </div>
  );
}
