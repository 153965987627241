import { Card } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserState, useUser } from "../../../context/UserContext";
import { InvestmentAccount } from "../../../data/dataAccounts";
import {
  AdviseAccount,
  dataInvestments,
  EligibilityRiskAnswers,
  RiskReaction,
  EligibilityFinancials,
  isEligibilityPerson,
} from "../../../data/dataInvestments";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { messages } from "./EditAllocationInvestmentBasisMessages";
import { UpdateSuitabilityButton } from "./UpdateSuitabilityButton";
import { LocalizationContext } from "../../../context/LocalizationContext";
import "./EditAllocationInvestmentBasisSuitability.scss";

interface Props {
  adviseAccount?: AdviseAccount;
  account?: InvestmentAccount;
  user?: UserState;
  riskAnswers?: EligibilityRiskAnswers;
}

export const EditAllocationInvestmentBasisSuitability: React.VFC<Props> = ({
  user,
  account,
  adviseAccount,
  riskAnswers,
}) => {
  const intl = useIntl();
  const isReadOnly = useIsReadOnly();
  const [financials, setFinancials] = useState<EligibilityFinancials>();
  const localizationContext = useContext(LocalizationContext);
  const { legalEntityType } = useUser();

  useEffect(() => {
    dataInvestments.getEligibility().then((response) => {
      if (isEligibilityPerson(response)) {
        setFinancials(response.financial);
      }
    });
  }, []);

  if (typeof legalEntityType === "undefined") {
    throw new Error(
      "EditAllocationInvestmentBasisSuitability - Missing legalEntityType"
    );
  }

  if (!account || !adviseAccount || !user || !riskAnswers) {
    return <div className="edit-allocation-investment-basis-suitability" />;
  }

  return (
    <div className="edit-allocation-investment-basis-suitability">
      <h3>
        <TranslatedText id="edit-allocation.investmentBasis.suitability.header" />
      </h3>
      <Card>
        <div className="edit-allocation-investment-basis-suitability">
          {typeof financials !== "undefined" &&
            financials.monthlyEarnings !== 0 && (
              <>
                <strong>{intl.formatMessage(messages.monthlyEarnings)}</strong>
                <p>
                  {intl.formatNumber(financials.monthlyEarnings, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </>
            )}
          <strong>{intl.formatMessage(messages.riskImportant)}</strong>
          <p>
            {intl.formatMessage(messages[`riskImportant${riskAnswers[1]}`])}
          </p>
          {/*
            Value can be 0 here for really old accounts. 0 is no longer a
            valid value
          */}
          {riskAnswers[2] !== (0 as RiskReaction) && (
            <>
              <strong>{intl.formatMessage(messages.riskReaction)}</strong>
              <p>
                {intl.formatMessage(messages[`riskReaction${riskAnswers[2]}`])}
              </p>
            </>
          )}
          <span className="additional-text">
            {intl.formatMessage(messages.seeMore)}
          </span>
        </div>
        {!isReadOnly && (
          <UpdateSuitabilityButton
            label={intl.formatMessage(messages[legalEntityType])}
          />
        )}
      </Card>
    </div>
  );
};
