import React from "react";
import {
  AllocationBarIntl,
  CardList,
  InvestmentType,
  RiskIndicator,
} from "@lysaab/ui-2";
import { InvestmentAccount } from "../../data/dataAccounts";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { CardBottom } from "./CardBottom";
import { Link } from "react-router-dom";
import { getDynamicLink } from "../../hooks/useCountryUrls";
import { EDIT_ALLOCATION_PAGE_URL } from "../editAllocation/EditAllocationPage";
import { useAccountAllocation } from "../../hooks/useAccountAllocation";
import { SavingsHorizonLength } from "../../data/dataInvestments";
import "./Allocation.scss";

interface Props {
  account: InvestmentAccount | undefined;
  showAction?: boolean;
}

const messages = defineMessages({
  [InvestmentType.BROAD]: {
    id: "accountPage.allocation.type.BROAD",
    description: "Account page allocaction investment type - BROAD",
    defaultMessage: "Broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "accountPage.allocation.type.SUSTAINABLE",
    description: "Account page allocaction investment type - SUSTAINABLE",
    defaultMessage: "Sustainable",
  },
  [SavingsHorizonLength.VERY_LONG]: {
    id: "accountPage.allocation.horizon.veryLong",
  },
  [SavingsHorizonLength.LONG]: {
    id: "accountPage.allocation.horizon.long",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "accountPage.allocation.horizon.kindaLong",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "accountPage.allocation.horizon.middle",
  },
  [SavingsHorizonLength.SHORT]: {
    id: "accountPage.allocation.horizon.short",
  },
});

const defaultValues = {
  takenRisk: 50,
  stocks: "-",
  bonds: "-",
  investmentType: undefined,
  savingsHorizon: undefined,
};

export function Allocation({ account, showAction = true }: Props) {
  const intl = useIntl();
  const accountAllocation = useAccountAllocation(account?.accountId);

  let elem = null;
  let takenRisk;
  let stocksText;
  let bondsText;
  let investmentType;
  let savingsHorizon;

  if (account && accountAllocation) {
    ({ takenRisk, investmentType, savingsHorizon } = accountAllocation);
  } else {
    ({ takenRisk, investmentType, savingsHorizon } = defaultValues);
  }

  const stocks = Math.round((takenRisk || 0) * 100) / 100;
  const bonds = Math.round((100 - stocks) * 100) / 100;

  stocksText = (
    <FormattedMessage
      id="accountPage.allocation.stocks"
      description="Account page stocks allocaction"
      defaultMessage="Stocks allocation"
      values={{ stocks: intl.formatNumber(stocks / 100, { style: "percent" }) }}
    />
  );

  bondsText = (
    <FormattedMessage
      id="accountPage.allocation.bonds"
      description="Account page bonds allocaction"
      defaultMessage="Bonds allocation"
      values={{ bonds: intl.formatNumber(bonds / 100, { style: "percent" }) }}
    />
  );

  if (
    account &&
    typeof account.actualRisk !== "undefined" &&
    account.actualRisk !== takenRisk
  ) {
    const actualStocks = Math.round(account.actualRisk * 100) / 100;
    const actualBonds = Math.round((100 - actualStocks) * 100) / 100;
    const stocksText = (
      <FormattedMessage
        id="accountPage.allocation.stocks"
        description="Account page stocks allocaction"
        defaultMessage="Stocks allocation"
        values={{
          stocks: intl.formatNumber(actualStocks / 100, {
            style: "percent",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        }}
      />
    );
    const bondsText = (
      <FormattedMessage
        id="accountPage.allocation.bonds"
        description="Account page bonds allocaction"
        defaultMessage="Bonds allocation"
        values={{
          bonds: intl.formatNumber(actualBonds / 100, {
            style: "percent",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        }}
      />
    );

    elem = (
      <>
        <strong className="account-page-card-subheader">
          <FormattedMessage
            id="accountPage.allocation.actual"
            description="Account page actual allocaction"
            defaultMessage="Current allocation"
          />
        </strong>
        <AllocationBarIntl
          messages={{
            stocks: stocksText,
            bonds: bondsText,
          }}
          risk={account.actualRisk}
        />
      </>
    );
  }

  return (
    <section className="account-page-allocation">
      <h2>
        <FormattedMessage
          id="accountPage.allocation"
          description="Account page allocaction Header"
          defaultMessage="Account allocation"
        />
      </h2>
      <CardList>
        <div className="account-page-padder">
          <div className="account-page-card-body">
            <div>
              <strong>
                <FormattedMessage
                  id="accountPage.allocation.target"
                  description="Account page target allocaction"
                  defaultMessage="Target allocation"
                />
              </strong>
              <AllocationBarIntl
                risk={takenRisk}
                messages={{
                  stocks: stocksText,
                  bonds: bondsText,
                }}
              />

              {elem}

              {savingsHorizon && (
                <div className="account-page-investment-horizon">
                  <div>
                    <strong>
                      <FormattedMessage id="accountPage.allocation.horizon" />
                    </strong>
                  </div>
                  <div>{intl.formatMessage(messages[savingsHorizon])}</div>
                </div>
              )}
              <div className="account-page-investment-type">
                <div>
                  <strong>
                    <FormattedMessage
                      id="accountPage.allocation.type"
                      description="Account page allocaction investment type header"
                      defaultMessage="Investment type"
                    />
                  </strong>
                </div>
                <div>
                  {investmentType &&
                    intl.formatMessage(messages[investmentType])}
                  <RiskIndicator
                    size={20}
                    risk={takenRisk}
                    investmentType={investmentType}
                  />
                </div>
              </div>
            </div>
          </div>

          {showAction && (
            <CardBottom>
              <Link
                to={getDynamicLink(EDIT_ALLOCATION_PAGE_URL, {
                  accountId: account?.accountId as string,
                })}
                data-test-id="update-allocation-card-bottom-button"
              >
                <FormattedMessage
                  id="accountPage.allocation.link"
                  description="account page allocation page link"
                  defaultMessage="Update allocation"
                />
              </Link>
            </CardBottom>
          )}
        </div>
      </CardList>
    </section>
  );
}
