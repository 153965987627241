import {
  CircleButton,
  CountrySelect,
  RequiredValidator,
  WorldCountry,
} from "@lysaab/ui-2";
import React, { useContext, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";

import "./CitizenshipFormFields.scss";

const messages = defineMessages({
  citizenshipLabel: {
    id: "citizenshipFormFields.citizenshipLabel",
  },
  citizenshipPlaceholder: {
    id: "citizenshipFormFields.citizenshipPlaceholder",
  },
  citizenshipRequired: {
    id: "citizenshipFormFields.citizenshipRequired",
  },
});

interface Props {
  citizenships?: (WorldCountry | undefined)[];
  setCitizenships: (citizenships: (WorldCountry | undefined)[]) => void;
}

export const CitizenshipFormFields = ({
  citizenships,
  setCitizenships,
}: Props) => {
  const intl = useIntl();
  const { language, country } = useContext(LocalizationContext).state;

  useEffect(() => {
    if (typeof citizenships === "undefined" || citizenships.length > 0) {
      return;
    }

    const defaultCitizenship = [
      (country?.toString() as WorldCountry) || WorldCountry.SWEDEN,
    ];
    setCitizenships(defaultCitizenship);
  }, [citizenships, country, setCitizenships]);

  return (
    <div className="citizenship-form-fields">
      {citizenships?.map((citizenship, idx) => (
        <div className="repeat-country" key={idx}>
          <CountrySelect
            language={language}
            label={intl.formatMessage(messages.citizenshipLabel)}
            placeholder={intl.formatMessage(messages.citizenshipPlaceholder)}
            value={citizenship}
            onChange={(value) => {
              const newCitizenships = [...(citizenships || [])];
              newCitizenships[idx] = value.value;
              setCitizenships(newCitizenships);
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.citizenshipRequired)
              ),
            ]}
            omitList={[
              ...citizenships
                .filter(isWorldCountry)
                .filter((country) => country !== citizenship),
              WorldCountry.UNITED_STATES_OF_AMERICA,
            ]}
          />
          <div className="action-buttons">
            {citizenships.length > 1 && (
              <CircleButton
                type="button"
                onClick={() => {
                  const newCitizenships = [...(citizenships || [])];
                  newCitizenships.splice(idx, 1);
                  setCitizenships(newCitizenships);
                }}
                icon="Minus"
              />
            )}
            {idx === citizenships.length - 1 && citizenships.length < 3 && (
              <CircleButton
                type="button"
                onClick={() => {
                  setCitizenships([...(citizenships || []), undefined]);
                }}
                icon="Plus"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const isWorldCountry = (
  country: WorldCountry | undefined
): country is WorldCountry => {
  return typeof country !== "undefined";
};
