import React from "react";
import { BANK_ICON_CLASS } from "../../BankIcon";
import Icon from "./swedbank.svg";

export function Swedbank() {
  return (
    <div className={BANK_ICON_CLASS}>
      <img src={Icon} alt="Swedbank icon" />
    </div>
  );
}
