import { Card, RiskIndicator } from "@lysaab/ui-2";
import React from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserState } from "../../../context/UserContext";
import { InvestmentAccount } from "../../../data/dataAccounts";
import {
  AdviseAccount,
  EligibilityRiskAnswers,
} from "../../../data/dataInvestments";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import "./EditAllocationInvestmentBasisHorizon.scss";
import { messages } from "./EditAllocationInvestmentBasisMessages";
import { UpdateSavingsHorizonButton } from "./UpdateSavingsHorizonButton";

interface Props {
  adviseAccount?: AdviseAccount;
  account?: InvestmentAccount;
  user?: UserState;
  riskAnswers?: EligibilityRiskAnswers;
}

export const EditAllocationInvestmentBasisHorizon: React.VFC<Props> = ({
  user,
  account,
  adviseAccount,
  riskAnswers,
}) => {
  const intl = useIntl();
  const isReadOnly = useIsReadOnly();

  if (!account || !adviseAccount || !user || !riskAnswers) {
    return <div className="edit-allocation-investment-basis-horizon" />;
  }

  return (
    <div className="edit-allocation-investment-basis-horizon">
      <h3>
        <TranslatedText id="edit-allocation.investmentBasis.horizon.header" />
      </h3>
      <Card>
        <dl>
          <div>
            <dt>{intl.formatMessage(messages.horizon)}</dt>
            <dd data-test-id="account-savings-horizon">
              {intl.formatMessage(messages[adviseAccount.savingsHorizon])}
            </dd>
          </div>
          <div>
            <dt>{intl.formatMessage(messages.probability)}</dt>
            <dd data-test-id="account-probability-need-earlier">
              {intl.formatMessage(messages[adviseAccount.needEarlier])}
            </dd>
          </div>
          <div>
            <dt>{intl.formatMessage(messages.investmentType)}</dt>
            <dd data-test-id="account-investment-type">
              {intl.formatMessage(messages[adviseAccount.investmentType])}
              <RiskIndicator
                size={20}
                risk={adviseAccount.takenRisk}
                investmentType={adviseAccount.investmentType}
              />
            </dd>
          </div>
        </dl>

        {!isReadOnly && <UpdateSavingsHorizonButton />}
      </Card>
    </div>
  );
};
