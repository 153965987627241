import React from "react";
import { Redirect, RouteProps, Route, matchPath } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { YEARLY_REVIEW_URL } from "../../pages/yearlyReview/YearlyReviewStory";
import { CLOSE_LYSA_CUSTOMER_URL } from "../../pages/closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import { ADD_EXTERNAL_KLARNA_URL } from "../../pages/withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { useFeatureContext } from "../../context/FeatureContext";
import { MESSAGES_PAGE_URL } from "../../pages/messages/MessagesPage";
import { CONTACT_PAGE_URL } from "../../pages/contact/ContactPage";
import { REVIEW_ACCOUNT_URL } from "../../pages/reviewAccount/ReviewAccountStory";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { loginPages } from "./loginPages";
import { SWITCH_USER_SELECTION_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUserSelection";
import { SWITCH_USER_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUser";

interface Props {
  location: {
    search: string;
    pathname: string;
  };
}

export const RedirectLogin: React.VFC<Props> = ({ location }) => {
  /**
   * We want to keep all our query params here because they can contain
   * configuration or ga tags that are needed on the login page as well
   */
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("redirect", location.pathname + location.search);

  const localizationContext = useLocalizationContext();
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: country ? getNavLink(loginPages[country]) : "/",
        search:
          location.pathname.length > 4 ? searchParams.toString() : undefined,
      }}
    />
  );
};

const RedirectHome = () => {
  return <Redirect to={{ pathname: getNavLink(OVERVIEW_PAGE_URL) }} />;
};

export const allowedPathsForYearlyReviewForce = [
  OVERVIEW_PAGE_URL,
  CLOSE_LYSA_CUSTOMER_URL,
  ADD_EXTERNAL_KLARNA_URL,
  YEARLY_REVIEW_URL,
  MESSAGES_PAGE_URL,
  CONTACT_PAGE_URL,
  REVIEW_ACCOUNT_URL,
  LOGOUT_PAGE_URL,
  SWITCH_USER_SELECTION_PAGE_URL,
  SWITCH_USER_PAGE_URL,
];

/**
 * This function returns a specialized route that runs some additional code
 * before returning the actual route
 *
 * This function shouldn't be used directly. It's only for implementing routes
 *
 * @argument shouldRedirect - Takes a boolean saying whether the user is logged
 *                            in or not. And depending on that returns a boolean
 *                            stating whether the route should redirect or not
 */
const redirectRoute =
  (shouldRedirect: (isSignedIn: boolean) => boolean) =>
  ({ children, ...otherProps }: Omit<RouteProps, "component" | "render">) => {
    const isSignedIn = useIsSignedIn();
    const [featureState] = useFeatureContext();

    let isAllowedPath = true;

    if (featureState.yearlyReviewLockRoutes) {
      const pathIsIncludedInAllowedPaths =
        allowedPathsForYearlyReviewForce.some(
          (allowedPath) =>
            !!matchPath(getNavLink(allowedPath), otherProps) === true
        );
      isAllowedPath = pathIsIncludedInAllowedPaths;
    }

    return (
      <Route
        {...otherProps}
        render={(props) => {
          if (shouldRedirect(isSignedIn) && !isAllowedPath) {
            return <RedirectHome />;
          } else if (shouldRedirect(isSignedIn)) {
            return children;
          } else if (isSignedIn) {
            return <RedirectHome />;
          } else {
            return <RedirectLogin location={props.location} />;
          }
        }}
      />
    );
  };

/** Route with access guard, only allowing signed in users */
export const PrivateRoute = redirectRoute((isSignedIn) => !!isSignedIn);
/** Route allowing all users */
export const PublicRoute = Route;
/** Route with access guard, only allowing signed out users */
export const NonPrivateRoute = redirectRoute((isSignedIn) => !isSignedIn);
