import {
  Card,
  LysaLink,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { FormattedPercentage } from "../../../../../../../components/formattedPercentage/FormattedPercentage";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { TransparentButton } from "../../../../../../../components/transparentButton/TransparentButton";
import { client as elevioClient } from "elevio";

import "./FeesCard.scss";
import { EffectiveInterestRateResponse } from "../../../../../../../data/dataSavingsAccount";

const FEES_ELEVIO_ARTICLE_ID = "195";

interface Props {
  interestRate: EffectiveInterestRateResponse | undefined;
  isError: boolean;
  isLoading?: boolean;
}

export const FeesCard: VoidFunctionComponent<Props> = ({
  interestRate,
  isError,
  isLoading,
}) => {
  return (
    <Card className="fees-card" showLoading={isLoading}>
      {isError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography type="body" component="span">
            <TranslatedText id="sweden.createSavingsAccount.feesCard.error" />
          </Typography>
        </Snackbar>
      )}
      <div className="fees-card__row-wrapper">
        <div className="fees-card__row">
          <Typography type="body">
            <TranslatedText id="sweden.createSavingsAccount.feesCard.subheader" />
          </Typography>
          <span className="fees-card__row-text fees-card__row-text--bold">
            {interestRate ? (
              <FormattedPercentage
                value={-(interestRate.fee * 100)}
                decimals={2}
              />
            ) : (
              "-"
            )}
          </span>
        </div>
      </div>
      <LysaLink
        component={TransparentButton}
        onClick={() => elevioClient.openArticle(FEES_ELEVIO_ARTICLE_ID)}
      >
        <TranslatedText id="sweden.createSavingsAccount.feesCard.elevioLink" />
      </LysaLink>
    </Card>
  );
};
