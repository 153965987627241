import { Card } from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { Page, PageHeader } from "../Page";

export const GRID_PAGE_URL = "/grid";

export const GridPage: VoidFunctionComponent = () => {
  return (
    <Page>
      <PageHeader>
        <h1>Grid</h1>
      </PageHeader>
      <h2>Auto-layout</h2>
      <GridRow>
        <GridCol>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
      </GridRow>
      <h2>Grid med varierat antal kolumner i olika breakpoints</h2>
      <p>(X-small: 1, Small: 2, Medium: 3, Large: 4, X-large: 6)</p>
      <GridRow>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol xsmall={12} small={6} medium={4} large={3} xlarge={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
      </GridRow>
      <h2>Grid med varierade kolumnstorlekar i olika breakpoints</h2>
      <GridRow>
        <GridCol small={4} medium={2} large={5}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol small={4} medium={7} large={2}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol small={4} medium={3} large={5}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
      </GridRow>
      <h2>Grid där row är span istället för div</h2>
      <GridRow as={"span"}>
        <GridCol as={"span"} small={6}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol as={"span"} small={6}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
        <GridCol as={"span"} small={6}>
          <Card>
            <p>lorem ipsum dolor sit amet</p>
          </Card>
        </GridCol>
      </GridRow>
    </Page>
  );
};
