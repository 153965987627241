import React from "react";
import { Card, Button } from "@lysaab/ui-2";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Link } from "react-router-dom";

export function Done() {
  return (
    <div>
      <h2>
        <TranslatedText id="deposits.monthly.story.done.header" />
      </h2>
      <Card>
        <p>
          <TranslatedText id="deposits.monthly.story.done.text" />
        </p>
      </Card>
      <Button
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<TranslatedText id="deposits.monthly.story.done.button" />}
        block
      />
    </div>
  );
}
