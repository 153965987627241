import React from "react";
import { Button } from "@lysaab/ui-2";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../components/TranslatedText";
import { DEPOSITS_OVERVIEW_URL } from "../../../../pages/deposits/overview/Recommendation";
import { MONTHLY_DEPOSITS_URL } from "../../../../pages/deposits/monthly/create/MonthlyStory";

export function IskReceipt() {
  return (
    <div>
      <h2>
        <FormattedMessage id="sweden.isk.move.receipt.header" />
      </h2>
      <p>
        <FormattedMessage id="sweden.isk.move.receipt.text" />
      </p>
      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<TranslatedText id="sweden.isk.move.receipt.button" />}
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={<TranslatedText id="sweden.isk.move.receipt.button-deposit" />}
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={<TranslatedText id="sweden.isk.move.receipt.button-monthly" />}
      />
    </div>
  );
}
