import React from "react";
import { Page, PageHeader } from "../../../../pages/Page";
import { TranslatedText } from "../../../../components/TranslatedText";
import { AutogiroConsents } from "./autogiroConsents/AutogiroConsents";

export const MONTHLY_CONSENTS_PAGE_URL = "/monthly-consents";

export const MonthlyAgreements = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="sweden.monthlyAgreements.header" />
        </h1>
      </PageHeader>
      <AutogiroConsents />
    </Page>
  );
};
