import { Button, Card } from "@lysaab/ui-2";
import React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { END_INVESTMENT_URL } from "../../endInvestment/EndInvestment";
import "./Done.scss";

export const Done: React.VFC = () => {
  return (
    <div className="close-account-story-done">
      <h1>
        <TranslatedText id="endInvestment.closeAccount.story.done.header" />
      </h1>
      <Card>
        <p>
          <TranslatedText id="endInvestment.closeAccount.story.done.text" />
        </p>
      </Card>
      <Button
        component={Link}
        to={getNavLink(END_INVESTMENT_URL)}
        block
        label={
          <TranslatedText id="endInvestment.closeAccount.story.done.back" />
        }
      />
    </div>
  );
};
