import { Button, Card, CardList, Spinner } from "@lysaab/ui-2";
import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { BankgiroQrCode } from "./components/BankgiroQrCode";
import "./BankgiroInformationPage.scss";
import { CardListActionItem } from "../../../../../../components/cardListItemWithAction/CardListActionItem";
import { CopyButton } from "../../../../../../components/copyButton/CopyButton";
import { BANKGIRO_DEPOSIT_PAGE_URL } from "../BankgiroDepositStory";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";

interface Props {
  accountName?: string;
  referenceNumber?: string;
}

const BANKGIRO_NUMBER = "307-6445";

const messages = defineMessages({
  referenceLabel: {
    id: "sweden.deposits.bankgiro.story.information.reference.label",
  },
  bankgiroLabel: {
    id: "sweden.deposits.bankgiro.story.information.bankgiro.label",
  },
});

export const BankgiroInformationPage: React.VFC<Props> = ({
  accountName,
  referenceNumber,
}) => {
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();

  const formattedReferenceNumber = useMemo(() => {
    if (typeof referenceNumber === "undefined") {
      return;
    }
    return (
      referenceNumber.substring(0, 4) +
      " " +
      referenceNumber.substring(4, 8) +
      " " +
      referenceNumber.substring(8, 12) +
      " " +
      referenceNumber.substring(12, referenceNumber.length)
    );
  }, [referenceNumber]);

  if (typeof accountName === "undefined") {
    safeNavigation(getNavLink(BANKGIRO_DEPOSIT_PAGE_URL));
  }

  if (typeof referenceNumber === "undefined") {
    return <Spinner />;
  }

  return (
    <div className="bankgiro-information-page">
      <h2>
        <TranslatedText id="sweden.deposits.bankgiro.story.information.title" />
      </h2>

      <div className="desktop-view">
        <p>
          <TranslatedText id="sweden.deposits.bankgiro.story.information.informationQr" />
        </p>

        <div className="qr-code-wrapper">
          <Card>
            <BankgiroQrCode
              referenceNumber={referenceNumber}
              bgNumber={BANKGIRO_NUMBER}
            />
          </Card>
        </div>
        <p>
          <TranslatedText id="sweden.deposits.bankgiro.story.information.informationTextDesktop" />
        </p>
      </div>

      <div className="mobile-view">
        <p>
          <TranslatedText id="sweden.deposits.bankgiro.story.information.informationTextMobile" />
        </p>
      </div>

      <CardList>
        <CardListActionItem
          label={intl.formatMessage(messages.bankgiroLabel)}
          text={BANKGIRO_NUMBER}
          action={<CopyButton text={BANKGIRO_NUMBER} />}
        />
        <CardListActionItem
          label={intl.formatMessage(messages.referenceLabel, { accountName })}
          text={formattedReferenceNumber}
          action={<CopyButton text={referenceNumber} />}
        />
      </CardList>

      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={
          <TranslatedText id="sweden.deposits.bankgiro.story.information.back-button" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.bankgiro.story.information.back-button-deposit" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={
          <TranslatedText id="sweden.deposits.bankgiro.story.information.back-button-monthly" />
        }
      />
    </div>
  );
};
