import { LysaCountry } from "@lysaab/countries";
import { DocModal } from "@lysaab/ui-2";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import { CompoundAccountId } from "../../data/dataAccounts";

interface DocumentVariables {
  accountId: CompoundAccountId;
  accountName: string;
}

interface Props {
  documentVariables: DocumentVariables;
  setDocument: (document?: string) => void;
  document?: string;
}

const messages = defineMessages({
  header: {
    id: "shareAccountConfirm.docs.header",
  },
  placeholder: {
    id: "shareAccountConfirm.docs.placeholder",
  },
  modalAnnouncement: {
    id: "shareAccountConfirm.docs.modalAnnouncement",
  },
});

export const shareAccountConfirmDocumentList: Record<LysaCountry, string[]> = {
  [LysaCountry.DENMARK]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.FINLAND]: [
    "legal/all/en/shared-account.md",
    "legal/all/en/privacy-note.md",
  ],
  [LysaCountry.GERMANY]: [
    "legal/de/de/shared-account.md",
    "legal/de/de/privacy-note.md",
  ],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [
    "legal/se/sv/shared-account.md",
    "legal/se/sv/personuppgiftspolicy.md",
  ],
};

export const ShareAccountConfirmDocs: React.FC<Props> = ({
  documentVariables,
  setDocument,
  document,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const {
    state: { name },
  } = useContext(UserContext);
  const { country } = localizationContext.state;

  if (typeof country === "undefined" || typeof name === "undefined") {
    return null;
  }

  const variables = {
    SIGNING_DATE: intl.formatDate(new Date()),
    ACCOUNT_NAME: documentVariables.accountName,
    ACCOUNT_ID: documentVariables.accountId,
    OWNER_NAME: name,
  };

  return (
    <>
      <DocModal
        header={intl.formatMessage(messages.header)}
        active={!!document}
        onRequestClose={() => setDocument(undefined)}
        variableValues={variables}
        docName={document}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={shareAccountConfirmDocumentList[country]}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />
      <button
        className="as-link"
        type="button"
        onClick={() => {
          setDocument(shareAccountConfirmDocumentList[country][0]);
        }}
      >
        <FormattedMessage id="shareAccountConfirm.docs.documentLink" />
      </button>
    </>
  );
};
