import React, { useContext } from "react";
import {
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Checkbox,
} from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";

const alternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

export const OwnershipQuestion: React.FC = () => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);

  return (
    <div className="experience-question">
      <RadioGroup
        header="De medel som investeras i en kapitalförsäkring ägs inte direkt av företaget (försäkringstagaren) utan av försäkringsbolaget. Stämmer detta?"
        alternatives={alternatives}
        value={kfState.hasOwnershipKnowledge}
        onChange={(value) =>
          setKFState({
            hasOwnershipKnowledge: value,
          })
        }
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
      {kfState.hasOwnershipKnowledge?.value === false && (
        <>
          <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
            Observera att de medel som investeras i en kapitalförsäkring inte
            ägs direkt av försäkringstagaren utan av försäkringsbolaget.
          </Snackbar>
          <Checkbox
            alternative={{
              text: "Jag förstår.",
              value: true,
            }}
            checked={!!kfState.hasOwnershipKnowledgeCheckbox}
            onChange={() =>
              setKFState({
                hasOwnershipKnowledgeCheckbox: !kfState.hasOwnershipKnowledgeCheckbox,
              })
            }
            validators={[
              new RequiredValidator("Du måste bekräfta att du förstår"),
            ]}
          />
        </>
      )}
    </div>
  );
};
