import React, { VoidFunctionComponent } from "react";
import { Button } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { FormattedMessage } from "react-intl";
import { useSuitabilityAssessmentContext } from "../../contexts/SuitabilityAssessmentContext";

export const Done: VoidFunctionComponent = () => {
  const [suitabilityAssessmentState] = useSuitabilityAssessmentContext();
  return (
    <>
      <h2>
        <FormattedMessage id="economicSituation.done.title" />
      </h2>

      <p>
        <FormattedMessage id="economicSituation.done.description" />
      </p>

      <Button
        block
        component={Link}
        to={
          suitabilityAssessmentState.returnUrl ?? getNavLink(OVERVIEW_PAGE_URL)
        }
        label={<FormattedMessage id="economicSituation.done.button.navigate" />}
      />
    </>
  );
};
