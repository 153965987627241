import { LysaCountry } from "@lysaab/countries";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  userterms: {
    id: "footer.legal.userterms",
  },
  privacypolicy: {
    id: "footer.legal.privacypolicy",
  },
  moneylaundry: {
    id: "footer.legal.moneylaundry",
  },
  cookies: {
    id: "footer.legal.cookies",
  },
  legalinfo: {
    id: "footer.legal.legalinfo",
  },
});

export const docLinks: Record<
  LysaCountry,
  { doc: string; name: { id: string } }[]
> = {
  [LysaCountry.SWEDEN]: [
    { doc: "legal/se/sv/legal-information.md", name: messages.userterms },
    {
      doc: "legal/se/sv/personuppgiftspolicy.md",
      name: messages.privacypolicy,
    },
    { doc: "legal/se/sv/penningtvätt.md", name: messages.moneylaundry },
    { doc: "legal/se/sv/kakor-1.md", name: messages.cookies },
    { doc: "legal/se/sv/legal-information-1.md", name: messages.legalinfo },
  ],
  [LysaCountry.DENMARK]: [
    { doc: "legal/all/en/terms-of-use.md", name: messages.userterms },
    { doc: "legal/all/en/privacy-note.md", name: messages.privacypolicy },
    {
      doc: "legal/all/en/anti-money-laundering.md",
      name: messages.moneylaundry,
    },
    { doc: "legal/all/en/cookies.md", name: messages.cookies },
    { doc: "legal/all/en/legal-information.md", name: messages.legalinfo },
  ],
  [LysaCountry.FINLAND]: [
    { doc: "legal/all/en/terms-of-use.md", name: messages.userterms },
    { doc: "legal/all/en/privacy-note.md", name: messages.privacypolicy },
    {
      doc: "legal/all/en/anti-money-laundering.md",
      name: messages.moneylaundry,
    },
    { doc: "legal/all/en/cookies.md", name: messages.cookies },
    { doc: "legal/all/en/legal-information.md", name: messages.legalinfo },
  ],
  [LysaCountry.GERMANY]: [
    { doc: "legal/de/de/terms-of-use.md", name: messages.userterms },
    { doc: "legal/de/de/privacy-note.md", name: messages.privacypolicy },
    {
      doc: "legal/de/de/anti-money-laundering.md",
      name: messages.moneylaundry,
    },
    { doc: "legal/de/de/cookies.md", name: messages.cookies },
    { doc: "legal/de/de/legal-information.md", name: messages.legalinfo },
  ],
  [LysaCountry.SPAIN]: [
    { doc: "legal/es/en/terms-of-use.md", name: messages.userterms },
    { doc: "legal/es/en/privacy-note.md", name: messages.privacypolicy },
    {
      doc: "legal/es/en/anti-money-laundering.md",
      name: messages.moneylaundry,
    },
    { doc: "legal/es/en/cookies.md", name: messages.cookies },
    { doc: "legal/es/en/legal-information.md", name: messages.legalinfo },
  ],
};
