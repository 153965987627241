import React from "react";
import { KFPrecondition } from "../../../../../../data/dataCreateAccount";
import { Card, Button } from "@lysaab/ui-2";
import { useHistory } from "react-router";

interface Props {
  error: KFPrecondition | undefined;
  exitRoute: string;
}

const PRECONDITIONS = {
  NOT_SUPPORTED_CORPORATION_TYPE: (
    <>
      <p>
        Det är enbart företag av typen <b>Aktiebolag</b> som kan öppna en
        kapitalförsäkring.
      </p>
      <p>
        Vänligen kontakta oss på <a href="tel:010 551 32 30">010 551 32 30</a>{" "}
        eller <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a> för
        ytterligare information.
      </p>
    </>
  ),
};

export const KfError: React.FunctionComponent<Props> = ({
  error,
  exitRoute,
}) => {
  const history = useHistory();

  if (!error) {
    return null;
  }

  return (
    <Card>
      <h2>Kapitalförsäkring</h2>
      {PRECONDITIONS[error.failedPrecondition as keyof typeof PRECONDITIONS]}
      <div className="bottom-nav">
        <Button
          variant="secondary"
          block
          onClick={() => {
            history.push(exitRoute);
          }}
          label="Tillbaka"
        />
      </div>
    </Card>
  );
};
