import React from "react";
import { Icon } from "@lysaab/ui-2";
import { MessagesAuthor } from "../../data/dataMessages";
import "./Signature.scss";
import { UserState } from "../../context/UserContext";

interface Props {
  user?: UserState | MessagesAuthor.ADMIN;
}

export function Signature({ user }: Props) {
  if (user === MessagesAuthor.ADMIN) {
    return (
      <span className="signature admin">
        <Icon.Logo />
      </span>
    );
  } else {
    const signatureArray = user?.name?.split(" ") ?? ["-", ""];
    const firstName = signatureArray[0]; // there is always at least one name, right?
    const lastName = signatureArray.pop();
    const signature = `${firstName[0]}${lastName?.[0] || ""}`;
    return <span className="signature customer">{signature}</span>;
  }
}
