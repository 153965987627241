import { Alternative } from "@lysaab/ui-2";
import React, { useReducer } from "react";

export interface FeesState {
  isPercent: Alternative<boolean>;
}

export interface FeesContextProps {
  state: FeesState;
  setState: (newState: Partial<FeesState>) => void;
}

export const FeesContext = React.createContext<FeesContextProps>(
  {} as FeesContextProps
);

function stateReducer(state: FeesState, newState: Partial<FeesState>) {
  return { ...state, ...newState };
}

export const FeesContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    isPercent: {
      text: "",
      value: false,
    },
  });

  return (
    <FeesContext.Provider value={{ state, setState }}>
      {children}
    </FeesContext.Provider>
  );
};
