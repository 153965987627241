import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  BankidResponse,
  BankIDStatus,
  SwedishTin,
  PendingHintCode,
  Status,
  FailedHintCode,
} from "@lysaab/ui-2";
import { useHistory, useParams } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { BASE_ROUTES, UserManagementBasePaths } from "../UserManagementStory";
import { UserManagementContext } from "../UserManagementContext";
import { IntlShape, useIntl } from "react-intl";
import { messages, signFailedMessages, signPendingMessages } from "../Messages";
import { dataCorporate } from "../../../../../../data/dataCorporate";

interface Match {
  action: UserManagementBasePaths;
}

interface Props {
  next: (action: UserManagementBasePaths) => void;
}

const POLL_TIME = 3000;

export const UserManagementSign: VoidFunctionComponent<Props> = ({ next }) => {
  const history = useHistory();
  const intl = useIntl();

  const params = useParams<Match>();
  const userManagementContext = useContext(UserManagementContext);
  const updateUser = userManagementContext.state.updatedUser;

  const [bankidResponse, setBankidResponse] = useState<BankidResponse>({
    status: Status.PENDING,
    hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
  });
  const [autostartToken, setAutostartToken] = useState<string>();

  const transactionidRef = useRef<string>();
  const timer = useRef<NodeJS.Timeout | undefined>();

  const pollBankidResponse = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (!transactionidRef.current) {
        return;
      }
      dataCorporate
        .upsertCompanyUserPoll(transactionidRef.current)
        .then((bankidResponse) => {
          setBankidResponse(bankidResponse);
          if (bankidResponse.status === Status.PENDING) {
            pollBankidResponse();
          } else if (bankidResponse.status === Status.COMPLETE) {
            next(params.action);
          }
        });
    }, POLL_TIME);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [next, params.action]);

  const startSign = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const transactionId = searchParams.get("transactionId");

    if (transactionId) {
      transactionidRef.current = transactionId;
      pollBankidResponse();
      return;
    }

    if (!updateUser) {
      history.push(getNavLink(BASE_ROUTES.HOME));
      return;
    }

    let promise;
    if (params.action === UserManagementBasePaths.DELETE) {
      promise = dataCorporate.deleteCompanyUser(
        updateUser.identificationNumber
      );
    } else {
      promise = dataCorporate.upsertCompanyUser(
        new SwedishTin(updateUser.identificationNumber).getNormalizedTin(),
        updateUser.email,
        updateUser.admin
      );
    }

    promise.then(({ autostartToken, transactionId, status, hintCode }) => {
      transactionidRef.current = transactionId;
      history.replace({
        ...history.location,
        search: `transactionId=${transactionId}`,
      });
      setAutostartToken(autostartToken);
      setBankidResponse({ status, hintCode });
      pollBankidResponse();
    });
  }, [history, params.action, pollBankidResponse, updateUser]);

  useEffect(() => {
    startSign();
  }, [startSign]);

  return (
    <BankIDStatus
      getMessages={getMessages(intl)}
      getPendingMessages={getPendingMessages(intl)}
      getFailedMessages={getFailedMessages(intl)}
      retry={() => startSign()}
      response={bankidResponse}
      autostarttoken={autostartToken}
    />
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(messages.signQrInfo1),
      qrInfo2: intl.formatMessage(messages.signQrInfo2),
      qrInfo3: intl.formatMessage(messages.signQrInfo3),
      divider: intl.formatMessage(messages.signDivider),
      buttonOpen: intl.formatMessage(messages.signButtonOpen),
      buttonErrorHeader: intl.formatMessage(messages.signButtonErrorHeader),
      buttonRetry: intl.formatMessage(messages.signButtonRetry),
    };
  };
}
function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}
