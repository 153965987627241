import { Story } from "@lysaab/ui-2";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  useLocation,
  generatePath,
  Route,
  Switch,
  useHistory,
} from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../../hooks/useStoryValues";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { PageStripped } from "../../../../../../pages/PageStripped";
import { CreateAgreement } from "./createAgreement/CreateAgreement";
import { Done } from "./done/Done";
import { LysaAccountSelection } from "./lysaAccountSelection/LysaAccountSelection";

import { MonthlyContextProvider } from "./MonthlyContext";

export const BASE_ROUTES = {
  CREATE_AGREEMENT: `${MONTHLY_DEPOSITS_URL}/`,
  LYSA_ACCOUNT: `${MONTHLY_DEPOSITS_URL}/lysa-account/:accountId?`,
  ACCOUNT_SELECTION: `${MONTHLY_DEPOSITS_URL}/account-selection`,
  DONE: `${MONTHLY_DEPOSITS_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "denmark.mobilepay.story.header",
    defaultMessage: "Monthly deposit",
  },
  ariaProgressLabel: {
    id: "denmark.mobilepay.story.ariaProgressLabel",
  },
});

export function MonthlyStory() {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const history = useHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = () => {
    history.goBack();
  };

  const agreementExists = useCallback(() => {
    safeNavigation(generatePath(ROUTES.LYSA_ACCOUNT));
  }, [ROUTES.LYSA_ACCOUNT, safeNavigation]);

  return (
    <PageStripped>
      <div className="mobilepay-monthly-deposits">
        <MonthlyContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={storyProgress}
            showBack={
              currentIndex > 0 &&
              currentIndex < Object.values(ROUTES).length - 1
            }
            showClose={true}
            transitionKey={currentIndex.toString()}
            onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            onBack={onBack}
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route
                path={ROUTES.CREATE_AGREEMENT}
                exact
                render={() => (
                  <CreateAgreement
                    agreementExists={agreementExists}
                    userRedirect={
                      window.location.origin + ROUTES.CREATE_AGREEMENT
                    }
                  />
                )}
              />
              <Route
                path={ROUTES.LYSA_ACCOUNT}
                exact
                render={() => (
                  <LysaAccountSelection
                    next={() => safeNavigation(ROUTES.DONE)}
                  />
                )}
              />
              <Route path={ROUTES.DONE} exact render={() => <Done />} />
            </Switch>
          </Story>
        </MonthlyContextProvider>
      </div>
    </PageStripped>
  );
}
