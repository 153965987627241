import { API } from "@lysaab/ui-2";

interface InviteResponse {
  inviteId: string;
}

export const dataInvite = {
  getId: () => {
    return API.get<InviteResponse>("/invite/invite-id");
  },
};
