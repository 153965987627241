import React, { FunctionComponent } from "react";
import { TransparentButton } from "../../../../../../../../../components/transparentButton/TransparentButton";

export const LysaLinkButton: FunctionComponent<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}> = ({ children, onClick, className }) => {
  return (
    <TransparentButton onClick={onClick} className={className}>
      <span className="lysa-link">{children}</span>
    </TransparentButton>
  );
};
