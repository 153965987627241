import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
} from "@lysaab/ui-2";

export class BooleanValidator<Boolean> extends BaseValidator {
  protected name = "BooleanValidator";

  validate(value: Boolean): Promise<ValidationResponse> {
    return new Promise((resolve) => {
      if (value) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
      }
    });
  }
}
