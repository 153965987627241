import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import { useParams, useHistory } from "react-router";
import {
  PendingHintCode,
  Status,
  BankidResponse,
  FailedHintCode,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
  Spinner,
} from "@lysaab/ui-2";
import {
  dataCreateAccount,
  KFExtendedStatus,
} from "../../../../../../data/dataCreateAccount";
import {
  Retry,
  Status as RetryStatus,
} from "../../../../../../components/retry/Retry";
import { KFAccountContext } from "../KFAccountContext";
import { BankIDLogo } from "@lysaab/ui-2/components/bankid-token/BankIDLogo";
import { openBankidApp } from "@lysaab/ui-2/components/bankid-token/DeviceDetection";
import cx from "classnames";
import "./FuturSign.scss";

export const ROUTE = "/sign";
interface Props {
  futurSummaryRoute: string;
}

interface Params {
  signingId: string;
  userId: string;
}

const POLL_TIMER = 3000;

const pendingResponse = {
  status: Status.PENDING,
  hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
};

export const FuturSign: FunctionComponent<Props> = ({ futurSummaryRoute }) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [response, setResponse] = useState<BankidResponse>(pendingResponse);
  const started = useRef<number | undefined>();
  const params = useParams<Params>();
  const history = useHistory();
  const [retryStatus, setRetryStatus] = useState<RetryStatus>(
    RetryStatus.PENDING
  );

  const { signingId, userId } = params;

  const poll = useCallback(
    (signingId: string, transactionId: string) => {
      dataCreateAccount
        .kfFuturSignPoll(signingId, transactionId)
        .then((response) => {
          if (
            response.status === KFExtendedStatus.ERROR ||
            response.status === Status.FAILED
          ) {
            setResponse({
              status: Status.FAILED,
              hintCode: FailedHintCode.CANCELLED,
            });
          } else {
            setResponse(response as BankidResponse);
            if (response.status === Status.PENDING) {
              setTimeout(() => {
                poll(signingId, transactionId);
              }, POLL_TIMER);
            } else if (response.status === Status.COMPLETE) {
              history.push(futurSummaryRoute.replace(":signingId", signingId));
              setKFState({
                futurSignResponse: undefined,
              });
            }
          }
        })
        .catch(() => {
          setResponse({
            status: Status.FAILED,
            hintCode: FailedHintCode.CANCELLED,
          });
        });
    },
    [setKFState, history, futurSummaryRoute]
  );

  const init = useCallback((setKFState, poll, signingId, userId) => {
    dataCreateAccount
      .kfFuturSign(signingId, userId)
      .then((data) => {
        setKFState({
          futurSignResponse: data,
        });
        setTimeout(() => {
          poll(signingId, data.transactionId);
        }, POLL_TIMER);
        setRetryStatus(RetryStatus.SUCCESS);
      })
      .catch((err) => {
        setRetryStatus(RetryStatus.ERROR);
      });
  }, []);

  useEffect(() => {
    if (started.current) {
      return;
    }

    let timerId = started.current;
    init(setKFState, poll, signingId, userId);

    return () => {
      window.clearTimeout(timerId);
    };
  }, [signingId, userId, setKFState, poll, init]);

  const autostarttoken = kfState.futurSignResponse?.autostartToken ?? "";

  return (
    <div
      className={cx("create-account-corporation-futur-sign", {
        "has-token": !!autostarttoken,
      })}
    >
      <Retry
        status={retryStatus}
        retry={() => {
          setRetryStatus(RetryStatus.PENDING);
          window.setTimeout(() => {
            init(setKFState, poll, signingId, userId);
          }, 800);
        }}
      >
        {response.status === Status.PENDING && (
          <>
            <Spinner />
            <p>Väntar på signering...</p>
            <div className="bankid-logo">
              <BankIDLogo size={80} />
            </div>
            <div>Öppna BankID för att signera</div>

            <div className="bankid-button">
              <Button
                onClick={() => openBankidApp(autostarttoken)}
                label="Öppna BankID på denna enhet"
              />
            </div>
          </>
        )}

        {response.status === Status.FAILED && (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <div>
                <b>Ajdå!</b> Något gick fel.
              </div>
            </Snackbar>

            <Button
              onClick={() => {
                setResponse(pendingResponse);
                init(setKFState, poll, signingId, userId);
              }}
              label="Försök igen?"
            />

            <div className="return-button">
              <Button
                variant="secondary"
                onClick={() => {
                  history.push(
                    futurSummaryRoute.replace(":signingId", signingId)
                  );
                }}
                label="Tillbaka"
              />
            </div>
          </>
        )}
      </Retry>
    </div>
  );
};
