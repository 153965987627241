import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  ExpandableCard,
  VisibleContent,
  HiddenContent,
  useDebounceValue,
  InvestmentType,
} from "@lysaab/ui-2";
import {
  MAX_ALLOCATION_INVESTMENT,
  MIN_ALLOCATION_INVESTMENT,
} from "./EditAllocation";
import { dataFunds, Fund, FundType } from "../../../../../../data/dataFunds";
import { Status, Retry } from "../../../../../../components/retry/Retry";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  dataInvestments,
  GetAllocationResponse,
} from "../../../../../../data/dataInvestments";
import { PositionsList } from "./PositionsList";

interface Props {
  risk: number;
  investment: string;
  investmentType: InvestmentType;
}

const DEBOUNCE_TIMER = 500;

export const EditAllocationPositions: React.FC<Props> = ({
  risk,
  investment,
  investmentType,
}) => {
  const [positions, setPositions] = useState<GetAllocationResponse>();
  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [funds, setFunds] = useState<Fund[]>();
  const localizationContext = useContext(LocalizationContext);

  useEffect(() => {
    if (!localizationContext.state.country) {
      return;
    }

    const amount = parseInt(investment, 10);

    if (
      !investment ||
      amount < MIN_ALLOCATION_INVESTMENT ||
      amount > MAX_ALLOCATION_INVESTMENT
    ) {
      setPositions(undefined);
      return;
    }

    dataInvestments
      .getAllocation(
        debouncedRisk,
        investmentType,
        localizationContext.state.country
      )
      .then((data) => {
        setPositions(data);
      })
      .catch(() => {
        setPositions(undefined);
      });
  }, [
    debouncedRisk,
    investmentType,
    investment,
    localizationContext.state.country,
  ]);

  const load = useCallback(() => {
    dataFunds
      .getHoldings()
      .then((data) => {
        setFunds(data);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(load, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 1000);
  }, [load]);

  return (
    <div className="create-account-corporation-positions">
      <Retry retry={retry} status={status}>
        <ExpandableCard
          buttonOpenText="Visa innehav"
          buttonCloseText="Dölj innehav"
        >
          <VisibleContent>
            <div className="create-account-corporation-fees-top">
              <h4>Fondinnehav</h4>
            </div>
          </VisibleContent>
          <HiddenContent>
            {risk === 0 ? null : <h3>Aktier</h3>}
            <PositionsList
              holdings={funds}
              positions={positions}
              type={FundType.STOCKS}
              investment={investment}
            ></PositionsList>
            {risk === 100 ? null : <h3>Räntor</h3>}
            <PositionsList
              holdings={funds}
              positions={positions}
              type={FundType.BONDS}
              investment={investment}
            ></PositionsList>
          </HiddenContent>
        </ExpandableCard>
      </Retry>
    </div>
  );
};
