import { Story } from "@lysaab/ui-2";
import React, { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Route, Switch, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { PageStripped } from "../../PageStripped";

import { Amount, WithdrawalType } from "./amount/Amount";
import { Done } from "./done/Done";
import { SavingsAccountAmount } from "./savingsAccountAmount/SavingsAccountAmount";
import { SelectExternalAccount } from "./selectExternalAccount/SelectExternalAccount";
import { SelectLysaAccount } from "./selectLysaAccount/SelectLysaAccount";
import {
  useWithdrawalContext,
  WithdrawalContextProvider,
} from "./WithdrawalContext";
import "./WithdrawalStory.scss";
import { WITHDRAWAL_PAGE_URL } from "../overview/WithdrawalPage";

export const WITHDRAWALS_REQUEST_PAGE = "/withdrawal/request";

export const BASE_ROUTES = {
  LYSA_ACCOUNT_SELECTION: `${WITHDRAWALS_REQUEST_PAGE}/`,
  EXTERNAL_ACCOUNT_SELECTION: `${WITHDRAWALS_REQUEST_PAGE}/external`,
  AMOUNT: `${WITHDRAWALS_REQUEST_PAGE}/amount`,
  DONE: `${WITHDRAWALS_REQUEST_PAGE}/done`,
};

const messages = defineMessages({
  header: {
    id: "withdrawal.request.story.header",
  },
  ariaProgressLabel: {
    id: "withdrawal.request.story.ariaProgressLabel",
  },
});

interface Props {
  addWithdrawalAccountUrl?: string;
  waitingAccountsComponent?: ReactNode;
}

export function InnerWithdrawalStory({
  addWithdrawalAccountUrl,
  waitingAccountsComponent,
}: Props) {
  const withdrawalContext = useWithdrawalContext();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();

  return (
    <PageStripped>
      <div className="withdrawal-story">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 1
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
          }}
          onBack={() => safeNavigation(Object.values(ROUTES)[currentIndex - 1])}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route
              path={ROUTES.LYSA_ACCOUNT_SELECTION}
              exact
              render={() => (
                <SelectLysaAccount
                  next={() => safeNavigation(ROUTES.EXTERNAL_ACCOUNT_SELECTION)}
                  waitingAccountsComponent={waitingAccountsComponent}
                />
              )}
            />
            <Route
              path={ROUTES.EXTERNAL_ACCOUNT_SELECTION}
              exact
              render={() => (
                <SelectExternalAccount
                  next={() => safeNavigation(ROUTES.AMOUNT)}
                  addWithdrawalAccountUrl={addWithdrawalAccountUrl}
                />
              )}
            />
            <Route
              path={ROUTES.AMOUNT}
              exact
              render={() =>
                withdrawalContext.state.selectedLysaSavingsAccount ? (
                  <SavingsAccountAmount
                    next={(withdrawalType: WithdrawalType) =>
                      safeNavigation({
                        pathname: ROUTES.DONE,
                        state: { withdrawalType },
                      })
                    }
                  />
                ) : (
                  <Amount
                    next={(withdrawalType: WithdrawalType) =>
                      safeNavigation({
                        pathname: ROUTES.DONE,
                        state: { withdrawalType },
                      })
                    }
                  />
                )
              }
            />
            <Route path={ROUTES.DONE} exact render={() => <Done />} />
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
}

export const WithdrawalStory = ({
  addWithdrawalAccountUrl,
  waitingAccountsComponent,
}: Props) => {
  return (
    <WithdrawalContextProvider>
      <InnerWithdrawalStory
        addWithdrawalAccountUrl={addWithdrawalAccountUrl}
        waitingAccountsComponent={waitingAccountsComponent}
      />
    </WithdrawalContextProvider>
  );
};
