import React, { useReducer, createContext, FunctionComponent } from "react";
import { CompoundAccount } from "../../../data/dataAccounts";
import { AvailableBank, KlarnaAccount } from "../../../data/dataKlarna";

export interface KlarnaState {
  bank?: AvailableBank;
  klarnaAccounts?: KlarnaAccount[];
  selectedKlarnaAccount?: KlarnaAccount;
  selectedLysaAccount?: CompoundAccount;
  amount?: string;
}

export interface KlarnaContextProps {
  state: KlarnaState;
  setState: (newState: Partial<KlarnaState>) => void;
}

export interface withKlarnaProps {
  Klarna: KlarnaContextProps;
}

export const KlarnaContext = createContext<KlarnaContextProps>(
  {} as KlarnaContextProps
);

function stateReducer(state: KlarnaState, newState: Partial<KlarnaState>) {
  return { ...state, ...newState };
}

export const KlarnaContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <KlarnaContext.Provider value={{ state, setState }}>
      {children}
    </KlarnaContext.Provider>
  );
};
