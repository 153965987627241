import React, { FunctionComponent, useState, useRef, useContext } from "react";
import {
  Button,
  Card,
  LysaFormRef,
  Form,
  RequiredValidator,
  RadioGroup,
  MoneyInput,
  MinValidator,
  CheckboxGroup,
  TextInput,
} from "@lysaab/ui-2";
import futur from "./futur.png";
import {
  InsurancePurpose,
  KFAccountContext,
  InitialPremiumAmount,
  YearlyPremiumAmount,
  SourceOfFunds,
  TypeOfBusiness,
} from "../KFAccountContext";
import { TextLengthValidator } from "../TextLengthValidator";
import { PassiveCorpValidator } from "../PassiveCorpValidator";
import "./FuturKyc.scss";
import { useHistory } from "react-router-dom";
import { Modal } from "../../../../../../components/modal/Modal";
import { UserContext } from "../../../../../../context/UserContext";

export const KYC = "/kyc";
interface Props {
  lysaSummaryRoute: string;
}

const MIN_CHARS = 3;
const MAX_CHARS = 100;

const insurancePurposeAlternatives = [
  {
    text: "Pensionssparande",
    value: InsurancePurpose.PENSION,
  },
  {
    text: "Målsparande",
    value: InsurancePurpose.OBJECTIVE,
  },
  {
    text: "Placering",
    value: InsurancePurpose.INVESTMENTS,
  },
  {
    text: "Annat:",
    value: InsurancePurpose.OTHER,
  },
];

const initialPremiumAmountAlternatives = [
  {
    text: "0 – 100 000 kr",
    value: InitialPremiumAmount.AMOUNT0TO100K,
  },
  {
    text: "100 001 – 500 000 kr",
    value: InitialPremiumAmount.AMOUNT100KTO500K,
  },
  {
    text: "500 001 – 1 000 000 kr",
    value: InitialPremiumAmount.AMOUNT500KTO1M,
  },
  {
    text: "1 000 001 – 5 000 000 kr",
    value: InitialPremiumAmount.AMOUNT1MTO5M,
  },
  {
    text: "Över 5 000 000 kr. Ange estimat:",
    value: InitialPremiumAmount.AMOUNTABOVE5M,
  },
];

const yearlyPremiumAmountAlternatives = [
  {
    text: "0 – 100 000 kr",
    value: YearlyPremiumAmount.AMOUNT0TO100K,
  },
  {
    text: "100 001 – 500 000 kr",
    value: YearlyPremiumAmount.AMOUNT100KTO500K,
  },
  {
    text: "500 001 – 1 000 000 kr",
    value: YearlyPremiumAmount.AMOUNT500KTO1M,
  },
  {
    text: "1 000 001 – 5 000 000 kr",
    value: YearlyPremiumAmount.AMOUNT1MTO5M,
  },
  {
    text: "Över 5 000 000 kr. Ange estimat:",
    value: YearlyPremiumAmount.AMOUNTABOVE5M,
  },
];

const sourceOfFundsAlternatives = [
  {
    text: "Företagets löpande verksamhet",
    value: SourceOfFunds.ONGOINGBUSINESS,
  },
  {
    text: "Bolagsförsäljning",
    value: SourceOfFunds.CORPORATIONSALE,
  },
  {
    text: "Avkastning från investering",
    value: SourceOfFunds.RETURNONINVESTMENT,
  },
  {
    text: "Utdelningar från företaget",
    value: SourceOfFunds.DIVIDENDS,
  },
  {
    text: "Fastighetsförsäljning",
    value: SourceOfFunds.PROPERTYSALE,
  },
  {
    text: "Annat, ange från var:",
    value: SourceOfFunds.OTHER,
  },
];

const typeOfBusinessAlternatives = [
  {
    text: "Bedriver aktiv näringsverksamhet",
    value: TypeOfBusiness.ACTIVEBUSINESS,
  },
  {
    text: "Finansiellt institut",
    value: TypeOfBusiness.FINANCIALINSTITUTE,
  },
  {
    text: "Börsnoterat bolag",
    value: TypeOfBusiness.LISTEDCOMPANY,
  },
  {
    text: "Nystartat företag",
    value: TypeOfBusiness.NEWLYSTARTED,
  },
  {
    text: "Välgörenhetsorganisation",
    value: TypeOfBusiness.CHARITY,
  },
  {
    text: "Annat bolag:",
    value: TypeOfBusiness.OTHER,
  },
];

const corporateIsPassiveAlternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

const legalEntityWith25PercentControlAlternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

const holderIsListedCorporationAlternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

const holderIsMajorityOwnedByListedCorporationAlternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

const controllingEntityHasSoleNaturalOwnerAlternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

export const FuturKyc: FunctionComponent<Props> = ({ lysaSummaryRoute }) => {
  const formRef = useRef<LysaFormRef>();
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [forceValidation, setForceValidation] = useState<boolean>(false);
  const [showCorporationDefinitions, setShowCorporationDefinitions] =
    useState<boolean>(false);
  const history = useHistory();
  const userContext = useContext(UserContext);
  const user = userContext.state;

  return (
    <div className="create-account-corporation-kyc">
      <div className="create-account-corporation-kyc-top">
        <h1>Kundkännedom</h1>
        <div>
          <img src={futur} alt="Futur Pension logo" />
        </div>
      </div>
      <p className="futur-kyc-subtitle">
        Information som delas med Futur Pension – vår samarbetspartner för
        kapitalförsäkring.
      </p>
      <Form
        lysaFormRef={formRef}
        forceValidation={forceValidation}
        onSubmit={(event) => {
          event.preventDefault();
          if (
            formRef.current?.isValid &&
            kfState.typeOfBusiness?.value === TypeOfBusiness.OTHER &&
            kfState.corporateIsPassive?.value !== true
          ) {
            return;
          } else if (formRef.current?.isValid) {
            history.push(lysaSummaryRoute);
          } else {
            setForceValidation(true);
          }
        }}
      >
        <Card>
          <RadioGroup
            header="Syftet med försäkringen"
            onChange={(ev) => {
              setKFState({ insurancePurpose: ev });
            }}
            value={kfState.insurancePurpose}
            alternatives={insurancePurposeAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          {kfState.insurancePurpose?.value === InsurancePurpose.OTHER ? (
            <TextInput
              label=""
              value={kfState.insurancePurposeDescription}
              onChange={(event) =>
                setKFState({ insurancePurposeDescription: event })
              }
              placeholder=""
              validators={[
                new RequiredValidator("Du måste fylla i det här fältet"),
                new TextLengthValidator(
                  MIN_CHARS,
                  MAX_CHARS,
                  `Texten måste vara mellan ${MIN_CHARS} och ${MAX_CHARS}`
                ),
              ]}
            />
          ) : null}

          <RadioGroup
            header="Vilken är den planerade insättningen i samband med tecknandet av försäkringen?"
            onChange={(ev) => {
              setKFState({ initialPremiumAmount: ev });
            }}
            value={kfState.initialPremiumAmount}
            alternatives={initialPremiumAmountAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          {kfState.initialPremiumAmount?.value ===
          InitialPremiumAmount.AMOUNTABOVE5M ? (
            <MoneyInput
              currency="SEK"
              value={kfState.initialPremiumApproximateAmount}
              onChange={(event) =>
                setKFState({ initialPremiumApproximateAmount: event })
              }
              placeholder="Ange belopp"
              defaultValue="5000000"
              validators={[
                new RequiredValidator("Du måste ange ett belopp"),
                new MinValidator(5000000, ">= 5 000 000 SEK"),
              ]}
            />
          ) : null}

          <RadioGroup
            header="Vilken är den planerade insättningen per år?"
            onChange={(ev) => {
              setKFState({ yearlyPremiumAmount: ev });
            }}
            value={kfState.yearlyPremiumAmount}
            alternatives={yearlyPremiumAmountAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          {kfState.yearlyPremiumAmount?.value ===
          YearlyPremiumAmount.AMOUNTABOVE5M ? (
            <MoneyInput
              currency="SEK"
              value={kfState.yearlyPremiumApproximateAmount}
              onChange={(event) =>
                setKFState({ yearlyPremiumApproximateAmount: event })
              }
              placeholder="Ange belopp"
              defaultValue="5000000"
              validators={[
                new RequiredValidator("Du måste ange ett belopp"),
                new MinValidator(5000000, ">= 5 000 000 SEK"),
              ]}
            />
          ) : null}

          <CheckboxGroup
            header="Varifrån kommer inbetalningarna till försäkringen?"
            onChange={(ev) => {
              setKFState({ sourceOfFunds: ev });
            }}
            values={kfState.sourceOfFunds}
            alternatives={sourceOfFundsAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          {kfState.sourceOfFunds.find(
            (item) => item.value === SourceOfFunds.OTHER
          ) ? (
            <TextInput
              value={kfState.sourceOfFundsOtherDescription}
              label=""
              onChange={(event) =>
                setKFState({ sourceOfFundsOtherDescription: event })
              }
              placeholder=""
              validators={[
                new RequiredValidator("Du måste fylla i det här fältet"),
                new TextLengthValidator(
                  MIN_CHARS,
                  MAX_CHARS,
                  `Texten måste vara mellan ${MIN_CHARS} och ${MAX_CHARS}`
                ),
              ]}
            />
          ) : null}

          <Modal
            showModal={showCorporationDefinitions}
            onClose={() => setShowCorporationDefinitions(false)}
            header="Definition: Företagstyp"
            printEnabled
          >
            {DefinitionCorporationTypes()}
          </Modal>

          <div className="corporation-type">
            <button
              className="as-link"
              onClick={(ev) => {
                ev.preventDefault();
                setShowCorporationDefinitions(true);
              }}
            >
              <b>Läs mer</b>
            </button>

            <RadioGroup
              header="Typ av företag?"
              onChange={(ev) => {
                setKFState({ typeOfBusiness: ev });
              }}
              value={kfState.typeOfBusiness}
              alternatives={typeOfBusinessAlternatives}
              validators={[
                new RequiredValidator("Du måste välja ett alternativ"),
              ]}
            />

            {kfState.typeOfBusiness?.value === TypeOfBusiness.OTHER ? (
              <div className="type-of-business-additional">
                <RadioGroup
                  header="Är företaget ett Passivt företag (Mer än 50 % av enhetens inkomster är kapitalinkomster [räntor, kapitalvinster etc])?"
                  onChange={(ev) => {
                    setKFState({ corporateIsPassive: ev });
                  }}
                  value={kfState.corporateIsPassive}
                  alternatives={corporateIsPassiveAlternatives}
                  validators={[
                    new RequiredValidator("Du måste välja ett alternativ"),
                    new PassiveCorpValidator(
                      "Enbart företag som i dagsläget räknas som Passiva kan öppna en kapitalförsäkring"
                    ),
                  ]}
                />
              </div>
            ) : null}
          </div>

          <RadioGroup
            header={`Äger eller kontrollerar ett företag eller en organisation (en så kallad juridisk person), direkt eller indirekt, mer än 25 procent av ${user.name}?`}
            onChange={(ev) => {
              setKFState({ legalEntityWith25PercentControl: ev });
            }}
            value={kfState.legalEntityWith25PercentControl}
            alternatives={legalEntityWith25PercentControlAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          {kfState.legalEntityWith25PercentControl?.value ? (
            <div className="type-of-business-additional">
              <RadioGroup
                header="Ägs den juridiska personen [som åsyftas ovan] till 100 % genom direktägande av endast en fysisk person?"
                onChange={(ev) => {
                  setKFState({ controllingEntityHasSoleNaturalOwner: ev });
                }}
                value={kfState.controllingEntityHasSoleNaturalOwner}
                alternatives={controllingEntityHasSoleNaturalOwnerAlternatives}
                validators={[
                  new RequiredValidator("Du måste välja ett alternativ"),
                ]}
              />
            </div>
          ) : null}

          <RadioGroup
            header="Är bolaget börsnoterat?"
            onChange={(ev) => {
              setKFState({ holderIsListedCorporation: ev });
            }}
            value={kfState.holderIsListedCorporation}
            alternatives={holderIsListedCorporationAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />

          <RadioGroup
            header="Är bolaget ett dotterbolag majoritetsägt av ett börsnoterat bolag?"
            onChange={(ev) => {
              setKFState({ holderIsMajorityOwnedByListedCorporation: ev });
            }}
            value={kfState.holderIsMajorityOwnedByListedCorporation}
            alternatives={holderIsMajorityOwnedByListedCorporationAlternatives}
            validators={[
              new RequiredValidator("Du måste välja ett alternativ"),
            ]}
          />
        </Card>
        <div className="bottom-nav">
          <Button block type="submit" label="Nästa" />
        </div>
      </Form>
    </div>
  );
};

function DefinitionCorporationTypes() {
  return (
    <div className="corp-definitions">
      <img src={futur} alt="Futur Pension logo" />
      <h4>Finansiellt institut</h4>
      Ett företag som erbjuder finansiella tjänster till allmänheten, företag
      och offentliga sektor. Ett finansiellt institut kan till exempel vara:
      <ul>
        <li>Bank- och kreditverksamhet</li>
        <li>
          Administration och förvaltning av och handel med värde-papper för
          annans räkning (enskilt/kollektivt) i form av enskilda värdepapper,
          portföljer, fonder, men även alternativa investeringsfonder
        </li>
        <li>
          Livförsäkringsbolag som tillhandahåller livförsäkringar med sparmoment
        </li>
      </ul>
      <p>
        Ett finansiellt institut är ofta en tillståndpliktig verksamhet som
        kräver ett tillstånd från Finansinspektionen.
      </p>
      <h4>Börsnoterat bolag</h4>
      <p>
        Ett företag vars aktier är noterade på en reglerad marknad alternativt
        en handelsplattform. Ett företag klassificeras också som ett börsnoterat
        bolag om det ägs direkt eller indirekt till mer än 50 procent av röster
        eller kapital av ett marknadsnoterat företag.
      </p>
      <h4>Bedriver aktiv näringsverksamhet</h4>
      <p>
        Ett företag där mindre än 50 procent av företagets inkomst under närmast
        föregående räkenskapsår eller motsvarande period utgörs av passiv
        inkomst och mindre än 50 procent av företagets tillgångar, under samma
        period, utgörs av tillgångar som innehas i syfte att ge upphov till
        passiv inkomst.
      </p>
      Passiv inkomst kan vara:{" "}
      <ul>
        <li>Utdelning och annan avkastning som kan jämställas med utdelning</li>
        <li>Ränta och annan avkastning som kan jämställas med ränta</li>
        <li>
          Hyror och royalties som inte härrör från aktiv näringsverksamhet•
          Livräntor
        </li>
        <li>
          Överskott från kapitalvinster/förluster på tillgångar som ger upphov
          till passiv inkomst (Värdepapper)
        </li>
        <li>
          Överskott på kapitalvinster/förluster på råvaror och råvaruderivat
        </li>
        <li>Överskott på kapitalvinster/förluster på valutaaffärer</li>
        <li>
          Utbetalningar från försäkringar så som kapitalförsäkringar m.fl.
        </li>
      </ul>
      <p>
        Ett holdingbolag vars verksamhet helt eller nästan uteslutande består i
        att äga hela eller delar av det utestående innehavet i, eller
        tillhandahålla finansiering eller tjänster till dotterbolag (som
        bedriver aktiv näringsverksamhet).
      </p>
      <h4>Nystartat företag</h4>
      <p>
        Ett företag som startades för mindre än 24 månader sedan och ännu inte
        varit aktivt men som investerat i tillgångar i syfte att bedriva annan
        verksamhet än det ett finansiellt institut gör.
      </p>
      <h4>Välgörenhetsorganisation</h4>
      Ett företag eller en organisation som uppfyller samtliga krav:
      <ol>
        <li>
          En organisation som har allmännyttiga eller ideella ändamål
          (allmännyttiga stiftelser och ideella föreningar m.m.) som är
          organiserade i Sverige.
        </li>
        <li>Organisationen är befriad från inkomstskatt i Sverige.</li>
        <li>
          Organisationen har inga ägare eller medlemmar som får del av
          organisationens inkomster eller tillgångar.
        </li>
        <li>
          Organisationen får inte (enligt lag eller stadgar) dela ut inkomster
          eller tillgångar till privatpersoner eller icke allmännyttiga
          organisationer/företag annat än vad som sker i enlighet med det
          allmännyttiga ändamålet eller för att betala för tjänster och
          tillgångar som organisationen köper.
        </li>
        <li>
          Organisationen får inte (enligt lag och stadgar) i samband med
          likvidation eller upplösning skifta ut tillgångar till annat än
          särskilda regeringsorgan eller andra allmännyttiga organisationer.
        </li>
      </ol>
      <h4>Annat bolag</h4>
      <p>Företaget tillhör inte någon av ovanstående kategorier.</p>
      <h4>Passivt företag</h4>
      <p>
        Ett företag som varken är ett finansiellt institut eller ett aktivt
        icke-finansiellt företag.
      </p>
    </div>
  );
}
