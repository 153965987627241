import React from "react";
import { Amount } from "../amount/Amount";
import { BankLogo } from "../bankLogo/BankLogo";
import { banks, dataBanks } from "../../data/dataBanks";
import { dataPerformance } from "../../data/dataPerformance";
import "./WithdrawalItem.scss";
import { TranslatedText } from "../TranslatedText";

interface Props {
  name: string;
  moneyOnAccount: number;
  externalBankAccount: string;
  externalBank?: keyof typeof banks;
  withdrawalAmount?: string;
  date?: Date;
}

export const WithdrawalItem: React.FunctionComponent<Props> = ({
  name,
  externalBankAccount,
  externalBank,
  withdrawalAmount = "0",
  moneyOnAccount,
  date = new Date(),
}) => {
  const bank =
    typeof externalBank !== "undefined" && dataBanks.getBank(externalBank);

  return (
    <div className="withdrawal-item">
      <div className="withdrawal-item-name">
        <span className="withdrawal-item-name--bold">{name}</span>
        &nbsp;-&nbsp;
        <Amount amount={moneyOnAccount} decimals={2} />
      </div>
      <div className="amount-tag">
        <div>
          <Amount amount={parseFloat(withdrawalAmount || "0")} decimals={2} />
        </div>
      </div>
      <div className="arrow-wrapper">
        <span>
          <TranslatedText
            id="withdrawal-item.from"
            description="Withdrawal item - from"
            defaultMessage="From"
          />
        </span>
        <div className="transaction-arrow">
          <div className="arrow-start" />
          <div className="arrow-body" />
          <svg
            className="arrow-top"
            height="20px"
            width="20px"
            viewBox="0 0 20 20"
          >
            <path d="M10 5 L20 10 L10 15 Z" />
          </svg>
        </div>
        <span>
          <TranslatedText
            id="withdrawal-item.to"
            description="Withdrawal item - to"
            defaultMessage="To"
          />
        </span>
      </div>
      <div className="withdrawal-item-to">
        <div>{dataPerformance.toStringDate(date)}</div>
        <div className="withdrawal-item-to__account-and-logo">
          {externalBankAccount}
          {bank && (
            <React.Fragment>
              , <b>{bank.short}</b>
              <BankLogo bank={bank} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
