import {
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TransferItem } from "../../../components/internalTransferItem/InternalTransferItem";
import { TranslatedText } from "../../../components/TranslatedText";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../data/dataCustomerTracking";
import {
  AddTransferAmountArguments,
  dataTransfer,
  getMaxTransferableAmount,
  TransferType,
} from "../../../data/dataTransfer";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../hooks/useIsReadOnly";
import { ToInternalTransfer } from "../../withdrawal/request/selectExternalAccount/SelectExternalAccount";
import { InternalTransferContext } from "../InternalTransferContext";
import {
  BASE_ROUTES,
  INTERNAL_TRANSFER_PAGE_URL,
} from "../InternalTransferPage";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  amountRequired: {
    id: "internal-transfer.story.amount.required",
  },
  amountMax: {
    id: "internal-transfer.story.amount.max",
  },
  amountMin: {
    id: "internal-transfer.story.amount.min",
  },
  amountLabel: {
    id: "internal-transfer.story.savings-account-amount.label",
  },
});

interface Props {
  next: () => void;
}

export function InternalTransferSavingsAccountAmount({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const {
    state: internalTransferContext,
    setState: setInternalTransferContext,
  } = useContext(InternalTransferContext);
  const history = useHistory();
  const location = useLocation<ToInternalTransfer>();
  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    if (
      internalTransferContext.fromAccount ||
      internalTransferContext.toAccount
    ) {
      return;
    }
    if (location.state?.fromAccount && location.state?.toAccount) {
      setInternalTransferContext({
        fromAccount: location.state.fromAccount,
        toAccount: location.state.toAccount,
      });
    } else if (
      history.location.pathname.endsWith(BASE_ROUTES.SAVINGS_ACCOUNT_AMOUNT)
    ) {
      history.push(getNavLink(INTERNAL_TRANSFER_PAGE_URL));
    }
  }, [
    history,
    internalTransferContext.fromAccount,
    internalTransferContext.toAccount,
    location.state,
    setInternalTransferContext,
  ]);

  const onSubmit = () => {
    if (
      formRef.current?.isInvalid ||
      isReadOnly ||
      !internalTransferContext.fromAccount ||
      !internalTransferContext.toAccount ||
      typeof internalTransferContext.amount === "undefined"
    ) {
      return;
    }

    const transferParameters: AddTransferAmountArguments = {
      toAccountId: internalTransferContext.toAccount.accountId,
      fromAccountId: internalTransferContext.fromAccount.accountId,
      requestId: new Date().getTime(),
      transferType: TransferType.AMOUNT,
      amount: internalTransferContext.amount,
    };
    setIsLoading(true);
    dataTransfer
      .addTransfer(transferParameters)
      .then(() => {
        EventTracker.track({
          event: TrackerEvent.INTERNAL_TRANSFER,
          message: `${transferParameters.amount} from ${transferParameters.fromAccountId} to ${transferParameters.toAccountId}`,
        });
        dataCustomerTrackingService.postEvent({
          eventName: "clickedConfirmInternalTransfer",
          domain: FeatureDomain.TRANSFERS,
          subDomain: SubDomain.INTERNAL_TRANSFERS,
          payload: {
            amount: internalTransferContext.amount
              ? internalTransferContext.amount.toString()
              : "",
            is_drain: "False",
          },
        });
        next();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setIsLoading(false));
  };

  if (
    !localizationContext.state.country ||
    !internalTransferContext.fromAccount ||
    !internalTransferContext.toAccount
  ) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="internal-transfer-amount">
      <h2>
        <TranslatedText id="internal-transfer.story.amount.header" />
      </h2>
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="internal-transfer.story.amount.error" />
            </Snackbar>
          )}
          <MoneyInput
            label={intl.formatMessage(messages.amountLabel)}
            currency={localizationContext.state.currency}
            value={internalTransferContext.amount?.toString()}
            onChange={(value) => {
              error && setError(false);
              setInternalTransferContext({ amount: Number(value) });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                0.01,
                intl.formatMessage(messages.amountMin, {
                  amount: intl.formatNumber(0, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
              new MaxValidator(
                getMaxTransferableAmount(
                  internalTransferContext.fromAccount,
                  internalTransferContext.toAccount
                ) || 0,
                intl.formatMessage(messages.amountMax, {
                  amount: intl.formatNumber(
                    getMaxTransferableAmount(
                      internalTransferContext.fromAccount,
                      internalTransferContext.toAccount
                    ) || 0,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                    }
                  ),
                })
              ),
            ]}
          />
        </Card>
        <TransferItem
          toAccount={internalTransferContext.toAccount}
          fromAccount={internalTransferContext.fromAccount}
          amount={internalTransferContext.amount?.toString()}
        />
        <Button
          type="submit"
          block
          label={<TranslatedText id="internal-transfer.story.amount.button" />}
        />
      </Form>
    </div>
  );
}
