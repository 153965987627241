import { LysaCountry } from "@lysaab/countries";
import { WorldCountry } from "@lysaab/ui-2";
import { API } from "@lysaab/ui-2/network/API";
import { cache } from "@lysaab/ui-2/network/Cache";
import { LegalEntityId } from "./dataUser";

export interface ReportingCountry {
  tin: string;
  country?: WorldCountry | LysaCountry;
}

export interface CrsResponse {
  firstName: string;
  lastName: string;
  legalEntityId: LegalEntityId;
  countries: ReportingCountry[];
  street: string;
  zipCode: string;
  city: string;
  residentCountry: WorldCountry;
  birthday: string;
  birthCountry?: WorldCountry;
  birthCity?: string;
  saved?: string;
}

export interface AddCrsRequest {
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
  city: string;
  residentCountry: WorldCountry;
  birthday: string;
  reportingCountries: ReportingCountry[];
  birthCountry?: WorldCountry;
  birthCity?: string;
}

export interface CrsSwedishResponseWithAddress {
  street: string;
  zipCode: string;
  city: string;
  residentCountry: WorldCountry;
  countries: ReportingCountry[];
  saved?: string;
}

export interface CrsSwedishResponseWithoutAddress {
  residentCountry?: WorldCountry;
  countries: ReportingCountry[];
  saved?: string;
}

export type CrsSwedishResponse =
  | CrsSwedishResponseWithAddress
  | CrsSwedishResponseWithoutAddress;

export function instanceOfCrsSwedishResponseWithAddress(
  response: CrsSwedishResponse
): response is CrsSwedishResponseWithAddress {
  return (
    typeof response.residentCountry !== "undefined" &&
    response.residentCountry !== WorldCountry.SWEDEN
  );
}

export interface AddCrsSwedishRequestWithAddress {
  street: string;
  zipCode: string;
  city: string;
  residentCountry: Exclude<WorldCountry, WorldCountry.SWEDEN>;
  reportingCountries: ReportingCountry[];
}

export interface AddCrsSwedishRequestWithoutAddress {
  residentCountry?: WorldCountry.SWEDEN;
  reportingCountries: ReportingCountry[];
}

export type AddCrsSwedishRequest =
  | AddCrsSwedishRequestWithAddress
  | AddCrsSwedishRequestWithoutAddress;

export const dataCrs = {
  getLatestCrs: () => {
    return API.get<CrsResponse>("/crs/latest");
  },
  getAllCrs: () => {
    return API.get<CrsResponse[]>("/crs/all");
  },
  postUpdateCrs: (crsData: AddCrsRequest) => {
    return API.post("/crs", crsData).then((response) => {
      cache.delete("/crs");
      return response;
    });
  },
  getLatestCrsSwedish: () => {
    return API.get<CrsSwedishResponse>("/crs/swedish/latest").then(
      (response) => {
        if (response) {
          return response;
        } else {
          return {
            countries: [],
          };
        }
      }
    );
  },
  postUpdateCrsSwedish: (crsData: AddCrsSwedishRequest) => {
    return API.post("/crs/swedish", crsData).then((response) => {
      cache.delete("/crs/swedish");
      return response;
    });
  },
};

export const mapCrsResponseToRequest = (
  crs: CrsResponse,
  country: LysaCountry
) => {
  return {
    firstName: crs.firstName,
    lastName: crs.lastName,
    street: crs.street,
    zipCode: crs.zipCode,
    city: crs.city,
    residentCountry: crs.residentCountry,
    birthday: crs.birthday,
    reportingCountries: crs.countries.filter(
      (reportingCountry) =>
        reportingCountry.country !== (country as unknown as WorldCountry) ||
        (reportingCountry.country === (country as unknown as WorldCountry) &&
          reportingCountry.tin !== "")
    ),
    birthCountry: crs.birthCountry,
    birthCity: crs.birthCity,
  };
};

export const mapCrsSwedishResponseToRequest = (
  crs: CrsSwedishResponse
): AddCrsSwedishRequest => {
  if (instanceOfCrsSwedishResponseWithAddress(crs)) {
    return {
      street: crs.street,
      zipCode: crs.zipCode,
      city: crs.city,
      residentCountry: crs.residentCountry,
      reportingCountries: [...crs.countries],
    } as AddCrsSwedishRequestWithAddress;
  }
  return {
    reportingCountries: [...crs.countries],
    residentCountry: crs.residentCountry,
  } as AddCrsSwedishRequestWithoutAddress;
};
