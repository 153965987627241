import React from "react";
import { useIntl } from "react-intl";
import { getFundName } from "../../../../../components/fundName/FundName";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  FundTransaction,
  FundTransactionMove,
  FundTransactionType,
} from "../../../../../data/dataTransactions";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { BuyTransactionRows } from "./components/BuyTransactionRows";
import { MoveInTransactionRows } from "./components/MoveInTransactionRows";
import { MoveOutTransactionRows } from "./components/MoveOutTransactionRows";
import { SellTransactionRows } from "./components/SellTransactionRows";
import { SwitchBuyTransactionRows } from "./components/SwitchBuyTransactionRows";
import { SwitchSellTransactionRows } from "./components/SwitchSellTransactionRows";
import { LysaLink } from "@lysaab/ui-2";
import { generatePath } from "react-router";
import { INVOICE_PAGE_URL } from "../../../../invoice/InvoicePage";

export const FundTransationRows = ({
  transaction,
}: {
  transaction: FundTransaction;
}) => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <SellTransactionRows transaction={transaction} />
      <BuyTransactionRows transaction={transaction} />
      <SwitchBuyTransactionRows transaction={transaction} />
      <SwitchSellTransactionRows transaction={transaction} />
      <MoveInTransactionRows transaction={transaction} />
      <MoveOutTransactionRows transaction={transaction} />
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.name" />
        </dt>
        <dd>{getFundName(transaction.isin, intl)}</dd>
      </div>
      {!isMoveTransaction(transaction) &&
        typeof transaction.contractNoteId !== "undefined" && (
          <LysaLink
            href={getNavLink(
              generatePath(INVOICE_PAGE_URL, {
                contractNoteId: transaction.contractNoteId,
              })
            )}
            target="_blank"
            rel="noopener noreferrer"
            external={true}
          >
            <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.note" />
          </LysaLink>
        )}
    </React.Fragment>
  );
};

const isMoveTransaction = (
  transaction: FundTransaction
): transaction is FundTransactionMove => {
  return (
    transaction.type === FundTransactionType.MOVE_IN ||
    transaction.type === FundTransactionType.MOVE_OUT
  );
};
