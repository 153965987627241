import { useState, useEffect, useRef, useContext } from "react";
import { Page, PageHeader } from "../Page";
import { Retry, Status } from "../../components/retry/Retry";
import { TranslatedText } from "../../components/TranslatedText";
import { useCallback } from "react";
import {
  InvestmentAccount,
  InvestmentAccountId,
  dataAccounts,
} from "../../data/dataAccounts";
import { UserContext } from "../../context/UserContext";
import { EditAllocationGraph } from "./EditAllocationGraph";
import { Autogiro, dataAutogiro } from "../../data/dataAutogiro";
import { EditAllocationUpdateButtons } from "./EditAllocationUpdateButtons";
import "./EditAllocationPage.scss";
import { Redirect, useParams } from "react-router";
import { LocalizationContext } from "../../context/LocalizationContext";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  AdviseAccount,
  dataInvestments,
  EligibilityResponse,
} from "../../data/dataInvestments";
import { EditAllocationSummary } from "./EditAllocationSummary";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { Card } from "@lysaab/ui-2";
import { EditAllocationInvestmentBasisHorizon } from "./EditAllocationInvestmentBasis/EditAllocationInvestmentBasisHorizon";
import { EditAllocationInvestmentBasisSuitability } from "./EditAllocationInvestmentBasis/EditAllocationInvestmentBasisSuitability";
import { LysaCountry } from "@lysaab/countries";
import { NOT_FOUND_URL } from "../NotFoundPage";
import { SuitabilityDownloadLazy } from "../../pageComponents/advise/SuitabilityDownload";

export const EDIT_ALLOCATION_PAGE_URL = "/edit-allocation/:accountId?";

function placeholderAutogiro(): Promise<Autogiro[]> {
  return new Promise<Autogiro[]>((resolve) => {
    resolve([]);
  });
}

function getPromises(country?: LysaCountry): Promise<any>[] {
  return [
    dataAccounts.getAccounts(),
    dataInvestments.getAdviseAccounts(),
    dataInvestments.getEligibility(),
    country === LysaCountry.SWEDEN
      ? dataAutogiro.getAutogiros()
      : placeholderAutogiro(),
  ];
}

export function EditAllocationPage() {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [adviseAccounts, setAdviseAccounts] = useState<AdviseAccount[]>([]);
  const [profile, setProfile] = useState<EligibilityResponse>();
  const [autogiros, setAutogiros] = useState<Autogiro[]>([]);
  const [risk, setRisk] = useState(0);
  const accountElem = useRef<HTMLDivElement>(null);
  const userContext = useContext(UserContext);
  const user = userContext.state;

  const params = useParams<{ accountId: InvestmentAccountId }>();
  const { accountId } = params;
  const l10nContext = useContext(LocalizationContext);

  const load = useCallback(() => {
    const promises = getPromises(l10nContext.state.country);
    Promise.all(promises)
      .then((data) => {
        const [
          requestedAccounts,
          requestedAdviseAccounts,
          requestedProfile,
          requestedAutogiros,
        ] = data;
        setAccounts(requestedAccounts);
        setAdviseAccounts(requestedAdviseAccounts);
        setProfile(requestedProfile);
        setAutogiros(requestedAutogiros);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [l10nContext.state.country]);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(load, 500);
  }, [load]);

  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  useEffect(load, [load]);

  useEffect(() => {
    if (!accounts) {
      return;
    }
    if (!accountId) {
      return;
    }
    setTimeout(() => {
      if (!accountElem.current) {
        return;
      }
      accountElem.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  }, [accounts, accountId]);

  const account = accounts.find((item) => item.accountId === accountId);
  const adviseAccount = adviseAccounts.find(
    (item) => item.accountId === accountId
  );

  if (accounts.length && !account) {
    return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
  }

  const allocationGraphElement = (
    <div>
      <h3>
        <TranslatedText id="edit-allocation.graph.header" />
      </h3>
      <Card>
        <EditAllocationGraph
          account={account}
          adviseAccount={adviseAccount}
          autogiros={autogiros}
          risk={risk}
          setRisk={setRisk}
        />

        <EditAllocationUpdateButtons
          user={user}
          account={account}
          adviseAccount={adviseAccount}
          risk={risk}
          setRisk={setRisk}
        />
      </Card>
    </div>
  );

  return (
    <Page className="edit-allocation-page">
      <PageHeader>
        <h1>
          <span className="header-account-title">
            <TranslatedText
              id="allocation.header"
              defaultMessage="Edit allocation"
              description="allocation page - header"
            />
          </span>
          <span className="header-account-name">{account && account.name}</span>
        </h1>
      </PageHeader>

      <Retry retry={retry} status={status}>
        <GridRow>
          <GridCol large={6} xlarge={4}>
            <EditAllocationSummary
              adviseAccount={adviseAccount}
              account={account}
              profile={profile}
            />
          </GridCol>
          <GridCol large={6} xlarge={4}>
            <EditAllocationInvestmentBasisHorizon
              user={user}
              account={account}
              adviseAccount={adviseAccount}
              riskAnswers={profile?.risk.answers}
            />
          </GridCol>
          <GridCol large={12} xlarge={4}>
            <EditAllocationInvestmentBasisSuitability
              user={user}
              account={account}
              adviseAccount={adviseAccount}
              riskAnswers={profile?.risk.answers}
            />
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol>{allocationGraphElement}</GridCol>
        </GridRow>
      </Retry>
    </Page>
  );
}
