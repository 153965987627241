import React, { VoidFunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Amount } from "../../components/amount/Amount";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { isTranslatedFund } from "../../components/fundName/FundName";
import { InstrumentName } from "../../components/instrumentName/InstrumentName";
import { Fund, FundType, Isin } from "../../data/dataFunds";
import { RemainingPositions } from "../../pages/account/positions/components/RemainingPositions";

import "./PositionsList.scss";

export type Positions = {
  [key: Isin]: {
    worth: number;
  };
};

interface Props {
  holdings?: Fund[];
  positions: Positions;
  header: string;
  type: FundType;
}

interface FundWithWorth extends Fund {
  worth: number;
  isin: Isin;
}

export const PositionsList: VoidFunctionComponent<Props> = ({
  holdings,
  positions,
  header,
  type,
}) => {
  if (!holdings || !positions) {
    return null;
  }

  const positionIsins = Object.keys(positions) as Isin[];

  const funds: FundWithWorth[] = positionIsins
    .map((isin) => {
      const fund = holdings.find((fund) =>
        fund.fundShareClasses.map(({ isin }) => isin).includes(isin)
      );
      return {
        ...fund,
        isin: isin,
        worth: positions[isin].worth,
      };
    })
    .filter(({ depotId }) => Boolean(depotId))
    .filter(({ fundType }) => fundType === type)
    .sort((a, b) => b.worth - a.worth) as FundWithWorth[];

  return (
    <div className="positions-list">
      <h4>{header}</h4>
      {funds.map((fund) => {
        const shareClass = fund.fundShareClasses.find(
          (shareClass) => shareClass.isin === fund.isin
        );
        const fundname = isTranslatedFund(fund.isin) ? (
          <InstrumentName isin={fund.isin} />
        ) : (
          shareClass?.name
        );

        return (
          <React.Fragment key={fund.isin}>
            {fund.largePositions.map((position) => {
              const translatedInstrument = position.instruments.find(
                (instrument) => isTranslatedFund(instrument.isin)
              );

              return (
                <React.Fragment key={position.instruments[0].isin}>
                  <dt>
                    <span className="name">
                      {translatedInstrument ? (
                        <InstrumentName isin={translatedInstrument.isin} />
                      ) : (
                        position.instruments.map(({ name }) => name).join(", ")
                      )}
                    </span>
                  </dt>
                  <dd className="position-value-cell">
                    <span className="positions-percent">
                      <FormattedPercentage
                        value={position.percent || 0}
                        decimals={2}
                      />
                    </span>
                    <span className="positions-currency">
                      <Amount
                        amount={(fund.worth / 100) * position.percent}
                        decimals={2}
                      />
                    </span>
                  </dd>
                </React.Fragment>
              );
            })}
            {fund && (
              <RemainingPositions
                positions={fund.remainingPositions}
                percent={fund.remainingPositionsPercent}
                worth={fund.worth}
              />
            )}
            <dt className="summary-cell">
              <FormattedMessage
                id="positionslist.text.total"
                values={{ fundname }}
              />
            </dt>
            <dd className="summary-cell text-right">
              <strong>
                <Amount amount={fund.worth} />
              </strong>
            </dd>
          </React.Fragment>
        );
      })}
    </div>
  );
};
