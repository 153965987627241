import React, {
  FunctionComponent,
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from "react";
import {
  Button,
  PendingHintCode,
  Status,
  FailedHintCode,
  Spinner,
  ServerError,
  Icon,
} from "@lysaab/ui-2";
import { setUserState, UserContext } from "../../../context/UserContext";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
  OrderRef,
  dataBankid,
  BankidPollResponse,
} from "../../../data/dataBankid";
import { onUserBootstrap } from "../../../data/dataLogin";
import {
  loginMessages,
  loginPendingMessages,
  loginFailedMessages,
} from "./LoginMessages";
import "./LoginPage.scss";
import { useHistory, useRouteMatch } from "react-router";
import { LoginTimeoutModal } from "../../../pages/login/LoginTimeoutModal";
import { parse, stringify } from "query-string";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { Location } from "history";
import { DateTime } from "luxon";
import { COUNTRY_SELECT_PAGE_URL } from "../../../pages/countrySelect/CountrySelectPage";
import { Link } from "react-router-dom";
import { FlagIcon } from "../../../components/flagIcon/FlagIcon";
import { BankIdLogin } from "../components/BankIdLogin/BankIdLogin";
import { useUserStorage } from "../../../context/UserStorageContext";
import { useRefreshPageReturningUsers } from "../../../hooks/useRefreshPageReturningUsers";

interface Props {}

enum LoginState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  DONE = "DONE",
}

interface HistoryState {
  from?: string;
}

interface SearchParams {
  redirect?: string;
  t?: string;
  feature?: string;
}

interface Match {
  orderRef?: OrderRef;
}

export const LOGIN_SWEDEN_PAGE_URL = "/login/:orderRef?";
export const LOGIN_PAGE_URL_VARIABLE = "/:orderRef?";
const POLL_TIMER = 3000;
const RELOAD_TIMER = 3; // Minutes

export const LoginPage: FunctionComponent<Props> = () => {
  const [loginState, setLoginState] = useState(LoginState.INITIAL);
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const orderRef = useRef<OrderRef>();
  const [response, setResponse] = useState<BankidPollResponse>();
  const [autostartToken, setAutostartToken] = useState<string>();
  const history = useHistory<HistoryState>();
  const match = useRouteMatch<Match>();
  const timer = useRef<NodeJS.Timeout | undefined>();
  const prevVisibility = useRef<DocumentVisibilityState>(
    document.visibilityState
  );
  const outstandingPoll = useRef(false);
  const statusRef = useRef<Status>();
  const { setUserStorage } = useUserStorage();

  useRefreshPageReturningUsers();

  const poll = useCallback(() => {
    if (!orderRef.current || outstandingPoll.current) {
      return;
    }
    outstandingPoll.current = true;
    dataBankid
      .loginPoll(orderRef.current)
      .then((response) => {
        outstandingPoll.current = false;
        setResponse(response);
        if (response.status === Status.PENDING) {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          timer.current = setTimeout(poll, POLL_TIMER);
        } else if (response.status === Status.COMPLETE) {
          orderRef.current = undefined;
          onUserBootstrap().then(([bootstrap, legalEntity, userStates]) => {
            const rawRedirect = (parse(history.location.search) as SearchParams)
              .redirect;
            setUserState(bootstrap, legalEntity, userContext.setState);
            setUserStorage(userStates);
            if (!rawRedirect) {
              return;
            }
            const redirect = decodeURIComponent(rawRedirect);
            if (redirect.startsWith(process.env.REACT_APP_OLD_SITE)) {
              window.location.href = redirect;
            } else {
              history.replace(redirect);
            }
          });
        }
      })
      .catch((error: ServerError<unknown>) => {
        setLoginState(LoginState.INITIAL);
        history.replace(getUrlWithoutParams(history.location));
      });
  }, [history, setUserStorage, userContext.setState]);

  const initLogin = useCallback(() => {
    dataBankid.login().then((response) => {
      /* Assign of ref has to be done before state change, othw components will rerender with old orderRef */
      orderRef.current = response.orderRef;
      setResponse({ status: response.status, hintCode: response.hintCode });
      setAutostartToken(response.autoStartToken);
      history.replace(getUrlWithParams(history.location, response.orderRef));
      setLoginState(LoginState.LOADING);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(poll, POLL_TIMER * 2);
    });
  }, [history, poll]);

  useEffect(() => {
    const search = parse(history.location.search);
    if (!search.t) {
      return;
    }
    const diff = DateTime.local().diff(
      DateTime.fromMillis(Number(search.t)),
      "minutes"
    ).minutes;

    if (
      !orderRef.current &&
      match.params.orderRef &&
      loginState === LoginState.INITIAL &&
      diff < RELOAD_TIMER
    ) {
      orderRef.current = match.params.orderRef;
      statusRef.current = Status.PENDING;
      setLoginState(LoginState.LOADING);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(poll, POLL_TIMER);
    }
  }, [history.location.search, loginState, match.params.orderRef, poll]);

  useEffect(() => {
    const onVisibilityChanged = () => {
      if (document.visibilityState !== prevVisibility.current) {
        prevVisibility.current = document.visibilityState;

        if (
          outstandingPoll.current ||
          response?.status === Status.COMPLETE ||
          response?.status === Status.FAILED
        ) {
          return;
        }

        if (timer.current) {
          clearTimeout(timer.current);
        }
        if (document.visibilityState !== "hidden") {
          timer.current = setTimeout(poll, POLL_TIMER);
        }
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChanged);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChanged);
    };
  }, [poll, response]);

  useEffect(() => {
    statusRef.current = response?.status;
  }, [response]);

  if (loginState === LoginState.INITIAL) {
    return (
      <div className="sweden-login-page">
        <LoginTimeoutModal />
        <Header />
        <div className="center-wrapper">
          <h1>
            <FormattedMessage id="sweden.login.header" />
          </h1>
          <div className="button-wrapper">
            <Button
              block
              onClick={() => initLogin()}
              label={<FormattedMessage id="sweden.login.button" />}
            />
          </div>
          <div className="login-footer">
            <p>
              <FormattedMessage
                id="sweden.login.footer"
                values={{
                  link: (...parts: string[]) => (
                    <a href={process.env.REACT_APP_SIGNUP_SITE}>{parts}</a>
                  ),
                }}
              />
            </p>
            <p>
              <FlagIcon code="se" />
              <FormattedMessage
                id="sweden.login.country-select"
                values={{
                  link: (...parts: string[]) => (
                    <Link to={COUNTRY_SELECT_PAGE_URL}>{parts}</Link>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    );
  } else if (loginState === LoginState.LOADING && response) {
    return (
      <div className="sweden-login-page">
        <Header />
        <div className="center-wrapper">
          <h1>
            <FormattedMessage id="sweden.login.bankid.header" />
          </h1>
          <BankIdLogin
            retry={() => initLogin()}
            response={{
              status: response.status,
              hintCode:
                response.hintCode || PendingHintCode.OUTSTANDINGTRANSACTION,
            }}
            getMessages={getMessages(intl)}
            getPendingMessages={getPendingMessages(intl)}
            getFailedMessages={getFailedMessages(intl)}
            orderRef={orderRef.current}
            autostartToken={autostartToken}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="sweden-login-page">
        <div className="pending-spinner-wrapper">
          <Spinner />
        </div>
      </div>
    );
  }
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(loginMessages.qrInfo1),
      qrInfo2: intl.formatMessage(loginMessages.qrInfo2),
      qrInfo3: intl.formatMessage(loginMessages.qrInfo3),
      divider: intl.formatMessage(loginMessages.divider),
      buttonOpen: intl.formatMessage(loginMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(loginMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(loginMessages.buttonRetry),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(loginPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(loginFailedMessages[hintCode]);
}

function Header() {
  return (
    <header>
      <Icon.Logo />
    </header>
  );
}

function getUrlWithParams(location: Location, orderRef: OrderRef | undefined) {
  const search = parse(location.search) as SearchParams;
  search.t = DateTime.local().toMillis().toString();

  return {
    pathname: getNavLink(LOGIN_SWEDEN_PAGE_URL).replace(
      ":orderRef?",
      orderRef || ""
    ),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getUrlWithoutParams(location: Location) {
  const search = parse(location.search) as SearchParams;
  delete search.t;

  return {
    pathname: getNavLink(LOGIN_SWEDEN_PAGE_URL).replace(":orderRef?", ""),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}
