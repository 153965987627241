import React from "react";
import { Swedbank } from "./banks/swedbank/Swedbank";
import { Nordea } from "./banks/nordea/Nordea";
import { DanskeBank } from "./banks/danskeBank/DanskeBank";
import { Skandiabanken } from "./banks/skandiabanken/Skandiabanken";
import { Seb } from "./banks/seb/Seb";
import { Handelsbanken } from "./banks/handelsbanken/Handelsbanken";
import { Lansforsakringar } from "./banks/lansforsakringar/Lansforsakringar";
import { IcaBanken } from "./banks/icaBanken/IcaBanken";
import { Bank } from "../../data/dataKlarna";
import { Jyske } from "./banks/jyske/Jyske";
import { Sydbank } from "./banks/sydbank/Sydbank";
import { GenericBankIcon } from "./banks/genericBankIcon/GenericBankIcon";

import "./BankIcon.scss";

export const BANK_ICON_CLASS = "bank-icon";

export const BankMapping: Partial<Record<Bank, () => JSX.Element>> = {
  [Bank.SKANDIABANKEN]: Skandiabanken,
  [Bank.SEB]: Seb,
  [Bank.HANDELSBANKEN]: Handelsbanken,
  [Bank.NORDEA]: Nordea,
  [Bank.SWEDBANK]: Swedbank,
  [Bank.LANSFORSAKRINGAR]: Lansforsakringar,
  [Bank.DANSKE_BANK]: DanskeBank,
  [Bank.ICA]: IcaBanken,
  [Bank.JYSKE]: Jyske,
  [Bank.SYDBANK]: Sydbank,
};

interface Props {
  bank: Bank;
}

export function BankIcon({ bank }: Props) {
  const BankComponent = BankMapping[bank];
  if (typeof BankComponent === "undefined") {
    return <GenericBankIcon />;
  }
  return <BankComponent />;
}
