import React from "react";
import { BANK_ICON_CLASS } from "../../BankIcon";
import Icon from "./sydbank.svg";
export function Sydbank() {
  return (
    <div className={BANK_ICON_CLASS}>
      <img src={Icon} alt="SYDBANK icon" />
    </div>
  );
}
