import { defineMessages } from "react-intl";

export const messages = defineMessages({
  transfersButton: {
    id: "menu.button.transfers",
    defaultMessage: "Transfers",
  },
  overviewButton: {
    id: "menu.button.overview",
    defaultMessage: "Overview",
  },
  profileButton: {
    id: "menu.button.profile",
    defaultMessage: "Profile",
  },
  messagesButton: {
    id: "menu.button.messages",
    defaultMessage: "Messages",
  },
  contactButton: {
    id: "menu.button.contact",
    defaultMessage: "Contact",
  },
  helpButton: {
    id: "menu.button.help",
    defaultMessage: "Help",
  },
  closeButton: {
    id: "menu.button.close",
    defaultMessage: "Close",
  },
  logoutButton: {
    id: "menu.button.logout",
    defaultMessage: "Logout",
  },
  inviteButton: {
    id: "menu.button.invite",
    defaultMessage: "Invite",
  },
  switchButton: {
    id: "menu.button.switch",
    defaultMessage: "Switch user",
  },
  currentUser: {
    id: "menu.button.current-user",
    defaultMessage: "Signed in as:",
  },
});
