import { InvestmentType, Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { InvestmentAccount, AccountType } from "../../data/dataAccounts";
import { stringify } from "query-string";
import { UPDATE_ACCOUNT_PAGE_URL } from "../updateAccount/UpdateAccountPage";
import { LocalizationContext } from "../../context/LocalizationContext";
import { getNavLink } from "../../hooks/useCountryUrls";
import { UserState } from "../../context/UserContext";
import AnimateHeight from "react-animate-height";
import { TranslatedText } from "../../components/TranslatedText";
import { MIN_RECOMMENDED_ALLOCATION } from "../../countries/sweden/pages/corporation/kf/KFAccountContext";
import {
  AdviseAccount,
  SavingsHorizonLength,
} from "../../data/dataInvestments";
import {
  EditAccounrRiskParams,
  EditAccountActionType,
} from "../../pageComponents/editAccountRiskConfirm/EditAccountRiskConfirm";

interface Props {
  user?: UserState;
  account?: InvestmentAccount;
  adviseAccount?: AdviseAccount;
  risk: number;
  setRisk: (risk: number) => void;
}

const messages = defineMessages({
  save: {
    id: "allocation.button.save",
    description: "Edit allocation page - save button label",
    defaultMessage: "Save this allocation",
  },
  reset: {
    id: "allocation.button.reset",
    description: "Edit allocation page - reset button label",
    defaultMessage: "Reset to Lysa's propsal",
  },
});

function showRiskWarning(adviseAccount: AdviseAccount, risk: number) {
  return (
    adviseAccount.savingsHorizon === SavingsHorizonLength.SHORT && risk >= 50
  );
}

function showKFWarning(account: InvestmentAccount, risk: number) {
  return (
    account.type === AccountType.DANICA_KF && risk < MIN_RECOMMENDED_ALLOCATION
  );
}

export const EditAllocationUpdateButtons: React.FunctionComponent<Props> = ({
  account,
  adviseAccount,
  risk,
  setRisk,
  user,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const l10nContext = useContext(LocalizationContext);
  const isReadOnly = user && user.readOnly;

  if (!account || !adviseAccount || !user) {
    return <div className="edit-allocation-update-buttons" />;
  }

  return (
    <div className="edit-allocation-update-buttons">
      <AnimateHeight
        height={showRiskWarning(adviseAccount, risk) ? "auto" : 0}
        animateOpacity={true}
      >
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <TranslatedText
              id="allocation.warning.shortHorizon"
              description="Edit allocation page - warning short horizon, high risk"
              defaultMessage="This account has a short savings horizon. It's not recommended to select a high allocation of stocks for shorter horizons."
            />
          </div>
        </Snackbar>
      </AnimateHeight>

      <AnimateHeight
        height={showKFWarning(account, risk) ? "auto" : 0}
        animateOpacity={true}
      >
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <div>
            <TranslatedText
              id="allocation.warning.kfRisk"
              description="Edit allocation page - warning short horizon, kf account"
              defaultMessage="This account is an insurance. It's not recommended to select a low allocation of stocks."
              values={{
                MIN_RECOMMENDED_ALLOCATION,
              }}
            />
          </div>
        </Snackbar>
      </AnimateHeight>

      <div className="update-button">
        <Button
          size="small"
          onClick={() => {
            if (!adviseAccount || !account) {
              return;
            }

            const params: EditAccounrRiskParams = {
              accountId: account.accountId,
              advisedRisk: adviseAccount.advisedRisk.toString(),
              takenRisk: risk.toString(),
              language: l10nContext.state.language,
              investmentType:
                adviseAccount.investmentType || InvestmentType.BROAD,
              updateType: EditAccountActionType.ALLOCATION,
              accountName: account.name,
              name: user.name || "",
            };

            history.push(
              `${getNavLink(UPDATE_ACCOUNT_PAGE_URL)}?${stringify(
                params as Record<string, any>
              )}`
            );
          }}
          block
          disabled={risk === adviseAccount?.takenRisk || isReadOnly}
          label={intl.formatMessage(messages.save)}
        />
      </div>

      <div className="reset-button">
        <Button
          variant="secondary"
          size="small"
          onClick={() => {
            if (!adviseAccount) {
              return;
            }
            setRisk(adviseAccount.advisedRisk);
          }}
          block
          disabled={risk === adviseAccount?.advisedRisk}
          label={intl.formatMessage(messages.reset)}
        />
      </div>
    </div>
  );
};
