import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import cx from "classnames";
import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../../../components/withdrawalItem/WithdrawalItem";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  AccountWorthComplete,
  dataDanica,
} from "../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { Disclaimer } from "./Disclaimer";
import { KfWithdrawalContext, WithdrawalType } from "./KfWithdrawalContext";
import { ROUTES } from "./WithdrawalKfRequestPage";
import { UserContext } from "../../../../../../context/UserContext";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";

const messages = defineMessages({
  amountLabel: {
    id: "withdrawal.story.amount.label",
  },
  placeholder: {
    id: "withdrawalRequest.kf.transaction.placeholder",
  },
  min: {
    id: "withdrawalRequest.kf.transaction.min",
  },
  max: {
    id: "withdrawalRequest.kf.transaction.max",
  },
  empty: {
    id: "withdrawalRequest.kf.transaction.empty",
  },
  required: {
    id: "withdrawalRequest.kf.transaction.required",
  },
  alternativeDrain: {
    id: "withdrawalRequest.kf.transaction.type.drain",
  },
  alternativeAmount: {
    id: "withdrawalRequest.kf.transaction.type.amount",
  },
  alternativeHeader: {
    id: "withdrawalRequest.kf.transaction.type.header",
  },
  alternativeRequired: {
    id: "withdrawalRequest.kf.transaction.type.required",
  },
});

const MIN_PERCENTAGE = 0.8;

export const Transaction: React.FunctionComponent = () => {
  const history = useHistory();
  const formRef = React.useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const { state: kfWithdrawalState, setState: setKfWithdrawalState } =
    useContext(KfWithdrawalContext);
  const { account, from, to } = kfWithdrawalState;
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const [withdrawableAmount, setWithdrawableAmount] =
    useState<AccountWorthComplete>();

  useEffect(() => {
    if (!account) {
      return;
    }
    dataDanica
      .getWithdrawableAmount(account.accountId)
      .then(setWithdrawableAmount);
  }, [account]);

  const next = useCallback(() => {
    if (!formRef.current?.isValid) {
      return;
    }
    history.push(getNavLink(ROUTES.CONFIRM));
  }, [history]);

  if (!account || !from || !to) {
    return <Redirect to={getNavLink(ROUTES.FROM)} />;
  }

  if (!user.name) {
    return <Redirect to={generatePath(getNavLink(LOGIN_SWEDEN_PAGE_URL))} />;
  }

  if (!withdrawableAmount) {
    return <Spinner />;
  }

  const forceDrain =
    withdrawableAmount.maxTransfer < withdrawableAmount.minTransfer;

  const alternatives: Alternative<WithdrawalType>[] = [
    {
      text: intl.formatMessage(messages.alternativeDrain),
      value: WithdrawalType.DRAIN,
    },
    {
      text: intl.formatMessage(messages.alternativeAmount),
      value: WithdrawalType.AMOUNT,
      disabled: forceDrain,
    },
  ];

  return (
    <div className={cx("withdrawal-request-page-kf-transaction")}>
      <h1>
        <TranslatedText
          id="withdrawalRequest.kf.transaction.header"
          defaultMessage="Select amount"
          description="withdrawal request kf - transaction header"
        />
      </h1>
      <Form lysaFormRef={formRef} onSubmit={next}>
        <Card>
          {forceDrain && (
            <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
              <div>
                <TranslatedText
                  id="withdrawal.amount-amount.force-drain-snackbar"
                  values={{
                    amount: intl.formatNumber(5000, {
                      style: "currency",
                      currency: localizationContext.state.currency,
                    }),
                  }}
                />
              </div>
            </Snackbar>
          )}
          <RadioGroup
            alternatives={alternatives}
            value={kfWithdrawalState.withdrawalType}
            header={intl.formatMessage(messages.alternativeHeader)}
            onChange={(alt) => setKfWithdrawalState({ withdrawalType: alt })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.alternativeRequired)
              ),
            ]}
          />
          {kfWithdrawalState.withdrawalType?.value ===
            WithdrawalType.AMOUNT && (
            <React.Fragment>
              <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                <div>
                  <TranslatedText
                    id="withdrawal.amount-amount.amount-snackbar"
                    values={{
                      minPercentage: intl.formatNumber(MIN_PERCENTAGE, {
                        style: "percent",
                      }),
                      maxTransfer: intl.formatNumber(
                        withdrawableAmount.maxTransfer,
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    }}
                  />
                </div>
              </Snackbar>
              <MoneyInput
                currency={localizationContext.state.currency}
                value={kfWithdrawalState.amount}
                onChange={(value) => setKfWithdrawalState({ amount: value })}
                placeholder={intl.formatMessage(messages.placeholder)}
                label={intl.formatMessage(messages.amountLabel)}
                validators={[
                  new RequiredValidator(intl.formatMessage(messages.required)),
                  new MinValidator(
                    withdrawableAmount.minTransfer,
                    intl.formatMessage(messages.min, {
                      minTransfer: intl.formatNumber(
                        withdrawableAmount.minTransfer,
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    })
                  ),
                  new MaxValidator(
                    withdrawableAmount.maxTransfer,
                    intl.formatMessage(messages.max, {
                      maxTransfer: intl.formatNumber(
                        withdrawableAmount.maxTransfer,
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    })
                  ),
                ]}
              />
            </React.Fragment>
          )}
        </Card>

        <WithdrawalItem
          moneyOnAccount={account.worth}
          name={account.name}
          externalBank={to.bank}
          externalBankAccount={to.externalBankAccount}
          withdrawalAmount={
            kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN
              ? account.worth.toString()
              : kfWithdrawalState.amount
          }
        />

        <Button
          block
          type="submit"
          label={<TranslatedText id="withdrawalRequest.kf.transaction.next" />}
        />
      </Form>

      <Disclaimer />
    </div>
  );
};
