import {
  Alternative,
  Card,
  SegmentedSelect,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import {
  dataFees,
  HistoricFeeAccountResponse,
  HistoricFeeClosedAccountResponse,
  HistoricFeeSummary,
  RebatePeriodResponse,
} from "../../../data/dataFees";
import { defineMessages, useIntl } from "react-intl";
import {
  Currency,
  LocalizationContext,
} from "../../../context/LocalizationContext";
import {
  GridRow,
  JustifyContentValues,
} from "../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { FeesContext, FeesContextProvider } from "./context/FeesContext";
import { TranslatedText } from "../../../components/TranslatedText";
import { dataLegalEntity } from "../../../data/dataLegalEntity";
import { RebateTable } from "./rebate/RebateTable";
import { AccountTable } from "./accountTables/AccountTable";
import { SummaryTable } from "./summary/SummaryTable";
import { DateTime, Interval } from "luxon";
import { dataAccounts } from "../../../data/dataAccounts";
import AnimateHeight from "react-animate-height";
import { ShowMoreButton } from "./components/showMoreButton/ShowMoreButton";

import "./FeeTables.scss";

interface MessageWithId {
  id: string;
}

const CurrencyMessages = defineMessages<
  Currency,
  MessageWithId,
  Record<Currency, MessageWithId>
>({
  [Currency.DKK]: {
    id: "fees.feeTables.currency.DKK",
  },
  [Currency.EUR]: {
    id: "fees.feeTables.currency.EUR",
  },
  [Currency.SEK]: {
    id: "fees.feeTables.currency.SEK",
  },
});

export const messages = defineMessages({
  selectPlaceholder: {
    id: "fees.feeTables.selectPlaceholder",
  },
  selectLabel: {
    id: "fees.feeTables.selectLabel",
  },
  percent: {
    id: "fees.feeTables.percent",
  },
  openButtonText: {
    id: "fees.feeTables.openButtonText",
  },
  closeButtonText: {
    id: "fees.feeTables.closeButtonText",
  },
});

export const FeesInternal = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const feesContext = useContext(FeesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<Alternative<number>>();
  const [summaryData, setSummaryData] = useState<HistoricFeeSummary>();
  const [activeAccountsData, setActiveAccountsData] =
    useState<HistoricFeeAccountResponse[]>();
  const [closedAccountsData, setClosedAccountsData] =
    useState<HistoricFeeClosedAccountResponse[]>();
  const [rebatesData, setRebatesData] = useState<RebatePeriodResponse[]>();
  const [yearsAlts, setYearsAlts] = useState<Alternative<number>[]>([]);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAlts = [
    {
      text: intl.formatMessage(
        CurrencyMessages[localizationContext.state.currency]
      ),
      value: false,
    },
    {
      text: intl.formatMessage(messages.percent),
      value: true,
    },
  ];

  useEffect(() => {
    dataLegalEntity.getLegalEntity().then((data) => {
      const alts: Alternative<number>[] = [];
      const createdYear = DateTime.fromISO(data.created).year;
      const nowYear = DateTime.local().year;
      for (let year = createdYear; year < nowYear; year++) {
        alts.push({ text: year.toString(), value: year });
      }
      setYearsAlts(alts);
    });
  }, [selectedYear]);

  useEffect(() => {
    if (typeof selectedYear !== "undefined") {
      const selectedYearDateTime = DateTime.fromFormat(
        selectedYear.value.toString(),
        "yyyy"
      );
      setIsLoading(true);
      dataAccounts.getAccounts().then((accountData) => {
        dataFees
          .getHistoricFees(selectedYear.value)
          .then((feeData) => {
            setSummaryData(feeData.summary);
            setActiveAccountsData(
              feeData.accounts
                .filter((accountFee) => {
                  const accountCreatedDate = accountData.find(
                    (account) => account.accountId === accountFee.accountId
                  )?.created;
                  return accountCreatedDate
                    ? DateTime.fromISO(accountCreatedDate).year <=
                        selectedYearDateTime.year
                    : false;
                })
                .sort((accountA, accountB) => accountB.total - accountA.total)
            );
            setClosedAccountsData(
              feeData.closedAccounts
                .filter((accountFee) => {
                  const createdClosedInterval = Interval.fromDateTimes(
                    DateTime.fromISO(accountFee.created),
                    DateTime.fromISO(accountFee.closed)
                  );
                  const selectedYearInterval = Interval.fromDateTimes(
                    selectedYearDateTime,
                    DateTime.fromObject({
                      year: selectedYearDateTime.year,
                      month: 12,
                      day: 24,
                      hour: 23,
                      minute: 59,
                      second: 59,
                    })
                  );
                  return createdClosedInterval.overlaps(selectedYearInterval);
                })
                .sort(
                  (accountA, accountB) =>
                    DateTime.fromISO(accountB.created).diff(
                      DateTime.fromISO(accountA.created)
                    ).milliseconds
                )
            );
            setRebatesData(feeData.rebates);
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => setIsLoading(false));
      });
    }
  }, [selectedYear]);

  if (isLoading) return <Spinner />;

  return (
    <div className="fee-tables">
      <Card>
        <GridRow justifyContent={JustifyContentValues.SPACE_BETWEEN}>
          <GridCol xsmall={12} medium={6}>
            <Select
              placeholder={intl.formatMessage(messages.selectPlaceholder)}
              alternatives={yearsAlts}
              value={selectedYear}
              onChange={(value) => setSelectedYear(value)}
              label={intl.formatMessage(messages.selectLabel)}
            />
          </GridCol>
          <GridCol xsmall={3}>
            <SegmentedSelect
              alternatives={toggleAlts}
              value={feesContext.state.isPercent}
              onChange={(value) => feesContext.setState({ isPercent: value })}
            />
          </GridCol>
        </GridRow>

        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="fees.feeTables.error" />
          </Snackbar>
        )}

        {summaryData && (
          <div className="summary-table">
            <h2>
              <TranslatedText id="fees.feeTables.summaryHeader" />
            </h2>
            <SummaryTable summary={summaryData} />
          </div>
        )}

        {selectedYear && (
          <p>
            <ShowMoreButton
              isOpen={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              openText={intl.formatMessage(messages.openButtonText)}
              closedText={intl.formatMessage(messages.closeButtonText)}
            />
          </p>
        )}
        <AnimateHeight height={isOpen ? "auto" : 0} animateOpacity>
          <p>
            <TranslatedText id="fees.feeTables.ingress" />
          </p>
          <p>
            <TranslatedText id="fees.feeTables.discretionaryFee" />
          </p>
          <p>
            <TranslatedText id="fees.feeTables.managementfee" />
          </p>
          <p>
            <TranslatedText id="fees.feeTables.underlyingManagementFee" />
          </p>
          <p>
            <TranslatedText id="fees.feeTables.transactionFee" />
          </p>
        </AnimateHeight>
      </Card>

      {activeAccountsData && activeAccountsData.length > 0 && (
        <React.Fragment>
          <h2>
            <TranslatedText id="fees.feeTables.activeAccountHeader" />
          </h2>
          <Card>
            <AccountTable accountsData={activeAccountsData} />
          </Card>
        </React.Fragment>
      )}
      {closedAccountsData && closedAccountsData.length > 0 && (
        <React.Fragment>
          <h2>
            <TranslatedText id="fees.feeTables.closedAccountHeader" />
          </h2>
          <Card>
            <AccountTable accountsData={closedAccountsData} />
          </Card>
        </React.Fragment>
      )}
      {rebatesData && rebatesData.length > 0 && (
        <React.Fragment>
          <h2>
            <TranslatedText id="fees.feeTables.rebateHeader" />
          </h2>
          <Card>
            <RebateTable rebates={rebatesData} />
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};

export const FeeTables = () => (
  <FeesContextProvider>
    <FeesInternal />
  </FeesContextProvider>
);
