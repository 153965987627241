import React, { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
  className?: string;
}

export const GenericBankIcon: VoidFunctionComponent<Props> = ({
  size = 40,
  className,
}) => {
  return (
    <svg
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7035_11856)">
        <circle cx="20" cy="20" r="20" fill="#F1F1F1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 15.2361L20.4472 11.4597C20.1657 11.3189 19.8343 11.3189 19.5528 11.4597L12 15.2361V16H28V15.2361ZM10.5528 13.7236C10.214 13.893 10 14.2393 10 14.618V17C10 17.5523 10.4477 18 11 18H29C29.5523 18 30 17.5523 30 17V14.618C30 14.2393 29.786 13.893 29.4472 13.7236L21.3416 9.67081C20.4971 9.24852 19.5029 9.24852 18.6584 9.67081L10.5528 13.7236Z"
          fill="black"
        />
        <path
          d="M10 29C10 28.4477 10.4477 28 11 28H29C29.5523 28 30 28.4477 30 29C30 29.5523 29.5523 30 29 30H11C10.4477 30 10 29.5523 10 29Z"
          fill="black"
        />
        <path
          d="M13 21C13 20.4477 13.4477 20 14 20C14.5523 20 15 20.4477 15 21V25C15 25.5523 14.5523 26 14 26C13.4477 26 13 25.5523 13 25V21Z"
          fill="black"
        />
        <path
          d="M25 21C25 20.4477 25.4477 20 26 20C26.5523 20 27 20.4477 27 21V25C27 25.5523 26.5523 26 26 26C25.4477 26 25 25.5523 25 25V21Z"
          fill="black"
        />
        <path
          d="M19 21C19 20.4477 19.4477 20 20 20C20.5523 20 21 20.4477 21 21V25C21 25.5523 20.5523 26 20 26C19.4477 26 19 25.5523 19 25V21Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7035_11856">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
