import { Story } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Route, Switch, useLocation } from "react-router";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../../hooks/useStoryValues";
import { PageStripped } from "../../../../../../pages/PageStripped";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../pages/withdrawal/overview/WithdrawalPage";
import { AccountInformation } from "./accountInformation/AccountInformation";
import { Done } from "./done/Done";

export const ADD_DEPOSIT_AUTOGIRO_URL = "/deposits/add/autogiro";

export const BASE_ROUTES = {
  ACCOUNT_INFORMATION: `${ADD_DEPOSIT_AUTOGIRO_URL}/`,
  DONE: `${ADD_DEPOSIT_AUTOGIRO_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "sweden.deposits.add.autogiro.story.header",
  },
  ariaProgressLabel: {
    id: "sweden.deposits.add.autogiro.story.ariaProgressLabel",
  },
});

export interface AddDepositAutogiroAccountLocationState {
  returnUrl?: string;
  returnState?: {};
}

export function AddDepositAccountAutogiroStory() {
  const location = useLocation<AddDepositAutogiroAccountLocationState>();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [returnUrl, setReturnUrl] = useState<string>();
  const [returnState, setReturnState] = useState<{}>();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  useEffect(() => {
    if (!returnUrl && location.state?.returnUrl) {
      setReturnUrl(location.state.returnUrl);
    }
    if (!returnState && location.state?.returnState) {
      setReturnState(location.state.returnState);
    }
  }, [location.state, returnState, returnUrl]);

  return (
    <PageStripped>
      <div className="add-autogiro-account">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={false}
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            if (returnUrl) {
              safeNavigation({
                pathname: returnUrl,
                state: {
                  returnState: location.state.returnState,
                },
              });
            } else {
              safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
            }
          }}
          onBack={() => {}}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.ACCOUNT_INFORMATION} exact>
              <AccountInformation
                next={() => {
                  if (returnUrl) {
                    returnState
                      ? safeNavigation({
                          pathname: returnUrl,
                          state: {
                            returnState: location.state.returnState,
                          },
                        })
                      : safeNavigation(returnUrl);
                  } else {
                    safeNavigation(ROUTES.DONE);
                  }
                }}
              />
            </Route>
            <Route path={ROUTES.DONE}>
              <Done />
            </Route>
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
}
