import { useEffect, useState } from "react";
import { InvestmentAccountId } from "../data/dataAccounts";
import { SavingsHorizonLength, dataInvestments } from "../data/dataInvestments";

export function useAccountInvestmentHorizon(accountId?: InvestmentAccountId) {
  const [accountHorizon, setAccountHorizon] = useState<SavingsHorizonLength>();

  useEffect(() => {
    if (typeof accountId === "undefined") {
      return;
    }
    dataInvestments.getAdviseAccount(accountId).then((accountAdvice) => {
      setAccountHorizon(accountAdvice.savingsHorizon);
    });
  }, [accountId]);

  return accountHorizon;
}
