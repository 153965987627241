import React, { FunctionComponent, useContext } from "react";
import { Button, Card, Icon } from "@lysaab/ui-2";
import { KfBody } from "./KfBody";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import "./Intro.scss";
import { UserContext } from "../../../../../../context/UserContext";
import { Link, Redirect } from "react-router-dom";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../../../../../../pages/createAccount/CreateAccountStory";
import { LOGIN_SWEDEN_PAGE_URL } from "../../../LoginPage";
import { RouteAwareToggle } from "../../../../../../components/route/RouteAwareToggle";
import { SavingsAccountIntroCard } from "../../../createAccount/components/savingsAccountIntroCard/SavingsAccountIntroCard";
import {
  CREATE_SAVINGS_ACCOUNT_URL,
  SAVINGS_ACCOUNT_BASE_ROUTES,
} from "../../../createAccount/savingsAccount/CreateSavingsAccountStory";

interface Props {
  exitRoute: string;
  lysaKycRoute: string;
  lysaSummaryRoute: string;
  futurSummaryRoute: string;
}

export const Intro: FunctionComponent<Props> = ({
  lysaKycRoute,
  exitRoute,
  lysaSummaryRoute,
  futurSummaryRoute,
}) => {
  const userContext = useContext(UserContext);
  const user = userContext.state;

  if (!user.name) {
    return <Redirect to={LOGIN_SWEDEN_PAGE_URL} />;
  }

  return (
    <div className="create-account-corporation-intro">
      <h1>
        <TranslatedText id="sweden.kf.new" />
      </h1>
      <div className="intro-ingress">
        <Icon.Checkmark />
        <TranslatedText id="sweden.kf.ingress" />
      </div>

      <KfBody
        exitRoute={exitRoute}
        lysaKycRoute={lysaKycRoute}
        lysaSummaryRoute={lysaSummaryRoute}
        futurSummaryRoute={futurSummaryRoute}
      />

      <Card>
        <h2>
          <TranslatedText id="sweden.vp" />
        </h2>
        <p>
          <TranslatedText id="sweden.vp.ingress" />
        </p>
        <div className="bottom-nav">
          <Button
            component={Link}
            block
            to={getNavLink(CREATE_ACCOUNT_URL)}
            label={<TranslatedText id="sweden.vp.link" />}
          />
        </div>
      </Card>
      <RouteAwareToggle path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}>
        <SavingsAccountIntroCard
          navigationButton={
            <Button
              component={Link}
              to={getNavLink(SAVINGS_ACCOUNT_BASE_ROUTES.INFO)}
              block
              label={
                <TranslatedText id="sweden.createAccountIntroStory.intro.createSavingsButton" />
              }
            />
          }
        />
      </RouteAwareToggle>
    </div>
  );
};
