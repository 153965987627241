import React, { VoidFunctionComponent } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { DataDisplay } from "../../dataDisplay/DataDisplay";
import { SavingsAccountDepositInterval } from "../../../data/dataKyc";

export const messages = defineMessages({
  header: {
    id: "kyc.savingsAccountDepositInterval.header.savingsAccount",
  },
  required: {
    id: "kyc.savingsAccountDepositInterval.required",
  },
});

interface MessageWithId {
  id: string;
}

export const depositIntervalMessages = defineMessages<
  SavingsAccountDepositInterval,
  MessageWithId,
  Record<SavingsAccountDepositInterval, MessageWithId>
>({
  [SavingsAccountDepositInterval.MORE_THAN_FIVE_TIMES_MONTHLY]: {
    id: "kyc.savingsAccountDepositInterval.alt.MORE_THAN_FIVE_TIMES_MONTHLY",
  },
  [SavingsAccountDepositInterval.ONE_TO_FIVE_TIMES_MONTHLY]: {
    id: "kyc.savingsAccountDepositInterval.alt.ONE_TO_FIVE_TIMES_MONTHLY",
  },
  [SavingsAccountDepositInterval.LESS_THAN_ONE_TIME_MONTHLY]: {
    id: "kyc.savingsAccountDepositInterval.alt.LESS_THAN_ONE_TIME_MONTHLY",
  },
});

interface Props {
  value?: SavingsAccountDepositInterval;
  setValue: (depositInterval: SavingsAccountDepositInterval) => void;
  display?: boolean;
}

export const SavingsAccountDepositIntervalQuestion: VoidFunctionComponent<Props> =
  ({ value, setValue, display }) => {
    const intl = useIntl();

    const alternatives = Object.values(SavingsAccountDepositInterval).map(
      (purpose) => {
        return {
          text: intl.formatMessage(depositIntervalMessages[purpose]),
          value: purpose,
        } as Alternative<SavingsAccountDepositInterval>;
      }
    );
    const valueAlternative = alternatives.find((alt) => alt.value === value);

    if (display) {
      return (
        <DataDisplay
          hasContainer
          type="input"
          title={intl.formatMessage(messages.header)}
          text={valueAlternative?.text}
        />
      );
    }

    return (
      <Card>
        <RadioGroup
          alternatives={alternatives}
          value={valueAlternative}
          header={intl.formatMessage(messages.header)}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.required)),
          ]}
          onChange={(depositInterval) => setValue(depositInterval.value)}
        />
      </Card>
    );
  };
