import React, { useCallback } from "react";
import { Redirect } from "react-router";
import { AccountLoading } from "../../../../../components/klarna/accountLoading/AccountLoading";
import { KlarnaAccount } from "../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../../../../../pages/deposits/monthly/create/MonthlyStory";
import { useMonthlyContext } from "../MonthlyContext";

interface Props {
  next: () => void;
}

export function AccountLoadingWrapper({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const bank = monthlyContext.state.bank;
  const setMonthlyContext = monthlyContext.setState;

  const onComplete = useCallback(
    (accounts: KlarnaAccount[]) => {
      setMonthlyContext({ klarnaAccounts: accounts });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setMonthlyContext]
  );

  if (!bank) {
    return <Redirect to={getNavLink(BASE_ROUTES.INTRO)} />;
  }
  return <AccountLoading bank={bank} onComplete={onComplete} />;
}
