import { Button, Spinner } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { ActiveAccountsData } from "../../../components/activeAccountsData/ActiveAccountsData";
import { ClosedAccountsData } from "../../../components/closedAccountsData/ClosedAccountsData";
import { PersonalData } from "../../../components/personalData/PersonalData";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  ExternalAccount,
  dataWithdrawals,
} from "../../../data/dataWithdrawals";

interface Props {
  next: () => void;
}

export const YourData = ({ next }: Props) => {
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dataWithdrawals
      .getExternalAccounts()
      .then((resp) => setExternalAccounts(resp))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <React.Fragment>
      <h2>
        <TranslatedText id="closeLysaCustomerAccountStory.yourData.header" />
      </h2>
      {isLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <PersonalData />
          <ActiveAccountsData />
          <ClosedAccountsData />
          {externalAccounts.length > 0 && (
            <Button
              block
              onClick={next}
              label={
                <TranslatedText id="closeLysaCustomerAccountStory.yourData.next" />
              }
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
