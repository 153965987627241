import React, { useContext, VoidFunctionComponent } from "react";
import { FormattedPercentageFraction } from "../../../../../components/formattedPercentage/FormattedPercentageFraction";
import { FeesContext } from "../../context/FeesContext";

interface Props {
  value: number;
  label: string;
}

export const CostBodyCell: VoidFunctionComponent<Props> = ({
  value,
  label,
}) => {
  const feeContext = useContext(FeesContext);

  if (!feeContext.state.isPercent.value) {
    return null;
  }
  return (
    <td data-label={label}>
      <FormattedPercentageFraction value={value} decimals={3} />
    </td>
  );
};
