import {
  Card,
  EmailInput,
  EmailValidator,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Icon,
} from "@lysaab/ui-2";
import cx from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../../../../components/withdrawalItem/WithdrawalItem";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { KfWithdrawalContext, WithdrawalType } from "../KfWithdrawalContext";
import { ROUTES } from "../WithdrawalKfRequestPage";
import "./SigningPending.scss";
import { PartialWithdrawalItem } from "./PartialWithdrawalItem";
import { dataPerformance } from "../../../../../../../data/dataPerformance";
import { DateTime } from "luxon";
import {
  dataDanica,
  KF_SIGN_STATUS,
  KF_WITHDRAWAL_STATUS,
} from "../../../../../../../data/dataDanica";

const messages = defineMessages({
  emailFormat: {
    id: "withdrawalRequest.kf.signing.pending.email.format",
    defaultMessage: "Incorrect format",
  },
  emailMissing: {
    id: "withdrawalRequest.kf.signing.pending.email.missing",
    defaultMessage: "Email is missing",
  },
  emailLabel: {
    id: "withdrawalRequest.kf.signing.pending.email.label",
    defaultMessage: "Email",
  },
});

function copyToClipboard(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }

  const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    selection.removeAllRanges();
    selection.addRange(selected);
  }

  return true;
}

export const SigningPending: React.FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const [copySuccess, setCopySuccess] = useState<boolean | undefined>();
  const [emailSuccess, setEmailSuccess] = useState<boolean | undefined>();
  const formRef = useRef<LysaFormRef>();
  const [email, setEmail] = useState<string>("");
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);
  const { signingStatus } = kfWithdrawalState;

  const copyLink = useCallback(() => {
    if (copyToClipboard(window.location.href)) {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 2000);
    } else {
      setCopySuccess(false);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 5000);
    }
  }, []);

  if (!signingStatus) {
    return null;
  }

  if (signingStatus.status !== KF_WITHDRAWAL_STATUS.PENDING_SIGNING) {
    return null;
  }

  const hasMultipleSignees = signingStatus.signRequests.length > 1;
  const { from, to, account } = kfWithdrawalState;
  const { signingId } = signingStatus.withdrawal;

  let elem;
  let dt = DateTime.local();
  const expDate = DateTime.fromISO(signingStatus.lastSigningDateTime);
  dt = dt.endOf("day");
  const diff = dt.diff(expDate, ["hours"]);

  // tomorrow
  if ((diff.toObject().hours || 0) < 0) {
    elem = (
      <TranslatedText
        id="withdrawalRequest.kf.signing.pending.deadline.tomorrow"
        defaultMessage="{expDate} tomorrow"
        values={{
          expDate: dataPerformance.toStringDate(
            new Date(signingStatus.lastSigningDateTime),
            DateTime.TIME_24_SIMPLE
          ),
        }}
      />
    );
  } else {
    elem = dataPerformance.toStringDate(
      new Date(signingStatus.lastSigningDateTime),
      DateTime.TIME_24_SIMPLE
    );
  }

  return (
    <div
      className={cx("withdrawal-request-page-kf-signing-pending", {
        "multiple-signees": hasMultipleSignees,
      })}
    >
      {from && to && account ? (
        <WithdrawalItem
          moneyOnAccount={account.worth}
          name={account.name}
          externalBank={to.bank}
          externalBankAccount={to.externalBankAccount}
          withdrawalAmount={
            kfWithdrawalState.withdrawalType?.value === WithdrawalType.DRAIN
              ? account.worth.toString()
              : kfWithdrawalState.amount
          }
        />
      ) : (
        <PartialWithdrawalItem
          accountName={signingStatus.withdrawal.accountName}
          initiatorName={signingStatus.withdrawal.initiatorName}
          accountWorth={signingStatus.withdrawal.accountWorth}
          externalBankAccount={signingStatus.withdrawal.externalBankAccount}
          amount={`${signingStatus.withdrawal.amount}`}
          bank={signingStatus.withdrawal.bank}
          date={new Date(signingStatus.withdrawal.requested)}
        />
      )}

      <Card>
        <h4>
          <TranslatedText
            id="withdrawalRequest.kf.signing.pending.subheader"
            description="withdrawal request kf - status pending header"
            defaultMessage="Withdrawal with Mobilt BankID"
          />
        </h4>
        <p>
          <TranslatedText
            id="withdrawalRequest.kf.signing.pending.ingress"
            description="withdrawal request kf - status pending ingress"
            defaultMessage="One or more signatories must sign in order to execute the withdrawal"
          />
        </p>

        <ul className="signing-list">
          {signingStatus.signRequests.map((signer) => {
            return (
              <li key={signer.userId}>
                <span>{signer.fullName}</span>
                {signer.signStatus === KF_SIGN_STATUS.COMPLETED ? (
                  <Icon.Checkmark />
                ) : (
                  <Button
                    size="small"
                    inline
                    onClick={() => {
                      history.push(
                        getNavLink(
                          ROUTES.SIGN.replace(":signingId", signingId).replace(
                            ":userId",
                            `${signer.userId}`
                          )
                        )
                      );
                    }}
                    label={
                      <TranslatedText id="withdrawalRequest.kf.signing.pending.sign" />
                    }
                  />
                )}
              </li>
            );
          })}
        </ul>

        <div className="expire-warning">
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            <div>
              <TranslatedText
                id="withdrawalRequest.kf.signing.pending.deadline"
                description="withdrawal request kf - status pending deadline"
                defaultMessage="Due to market movements all signees must have signed before <bold>{expirationTime}</bold>, otherwise a new withdrawal has to be issued."
                values={{
                  expirationTime: elem,
                }}
              />
            </div>
          </Snackbar>
        </div>
      </Card>

      <div className="additional-actions">
        <h4>
          <TranslatedText
            id="withdrawalRequest.kf.signing.pending.identify"
            description="withdrawal request kf - pending sign identify"
            defaultMessage="Signing"
          />
        </h4>
        <div className="signers-copy">
          {copySuccess === false ? (
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              <TranslatedText
                id="withdrawalRequest.kf.signing.pending.copy.error"
                description="withdrawal request kf - pending sign copy error"
                defaultMessage="<bold>Error!</bold> Link was not copied."
              />
            </Snackbar>
          ) : null}
          {copySuccess === true ? (
            <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
              <TranslatedText
                id="withdrawalRequest.kf.signing.pending.copy.success"
                description="withdrawal request kf - pending sign copy success"
                defaultMessage="Link was copied"
              />
            </Snackbar>
          ) : null}
          <p>
            <TranslatedText
              id="withdrawalRequest.kf.signing.pending.copy.info"
              description="withdrawal request kf - pending sign copy info"
              defaultMessage="In order to execute the withdrawal we need the signatures of the corporation. You can either copy the link and send it yourself or fill out the email below and we'll notify the emails owner."
            />
          </p>
        </div>

        <Button
          size="small"
          onClick={copyLink}
          label={
            <TranslatedText id="withdrawalRequest.kf.signing.pending.copy.link" />
          }
        />

        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();

            if (formRef.current?.isValid) {
              dataDanica
                .kfShareByEmail(signingId, email)
                .then(() => {
                  setEmailSuccess(true);
                  setTimeout(() => {
                    setEmailSuccess(undefined);
                  }, 2000);
                })
                .catch(() => {
                  setEmailSuccess(false);
                  setTimeout(() => {
                    setEmailSuccess(undefined);
                  }, 5000);
                });
            }
          }}
        >
          <div className="email-wrapper">
            {emailSuccess === false ? (
              <Snackbar type={SNACKBAR_TYPES.ERROR}>
                <div>
                  <TranslatedText
                    id="withdrawalRequest.kf.signing.pending.email.error"
                    description="withdrawal request kf - pending sign email error"
                    defaultMessage="<bold>Ouch!</bold> We couldn't save the email"
                  />
                </div>
              </Snackbar>
            ) : null}
            {emailSuccess === true ? (
              <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                <div>
                  <TranslatedText
                    id="withdrawalRequest.kf.signing.pending.email.success"
                    description="withdrawal request kf - pending sign email success"
                    defaultMessage="The link was sent"
                  />
                </div>
              </Snackbar>
            ) : null}
            <EmailInput
              value={email}
              label={intl.formatMessage(messages.emailLabel)}
              placeholder=""
              onChange={(event) => setEmail(event)}
              validators={[
                new EmailValidator(intl.formatMessage(messages.emailFormat)),
                new RequiredValidator(
                  intl.formatMessage(messages.emailMissing)
                ),
              ]}
              forceValidation={false}
              suggestionMessage={
                "Det ser ut som att du kanske skrev in en felaktig " +
                "emailadress. Menade du {suggestion}?"
              }
            />

            <Button
              size="small"
              type="submit"
              label={
                <TranslatedText id="withdrawalRequest.kf.signing.pending.email.send" />
              }
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
