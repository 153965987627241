import React, { FunctionComponent } from "react";
import { RadioGroupCard, RequiredValidator, Typography } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { SustainabilityPreference } from "../../data/dataInvestments";
import { ExpandableContent } from "../../components/expandableContent/ExpandableContent";
import { useIsPerson } from "../../hooks/useIsPerson";
import { ElevioLink } from "../../components/elevio/ElevioLink";

interface MessageWithId {
  id: string;
}

const messages = defineMessages({
  header: { id: "sustainability.preference.header" },
  headerCorp: { id: "sustainability.corp.preference.header" },
  required: { id: "sustainability.preference.required" },
});

/** Title **/
export const sustainabilityPreferenceAltMessages = defineMessages<
  SustainabilityPreference,
  MessageWithId,
  Record<SustainabilityPreference, MessageWithId>
>({
  [SustainabilityPreference.NONE]: {
    id: "sustainability.preference.alt.NONE.header",
  },
  [SustainabilityPreference.SPECIFIC]: {
    id: "sustainability.preference.alt.SPECIFIC.header",
  },
});

export const sustainabilityPreferenceAltCorpMessages = defineMessages<
  SustainabilityPreference,
  MessageWithId,
  Record<SustainabilityPreference, MessageWithId>
>({
  [SustainabilityPreference.NONE]: {
    id: "sustainability.preference.corp.alt.NONE.header",
  },
  [SustainabilityPreference.SPECIFIC]: {
    id: "sustainability.preference.corp.alt.SPECIFIC.header",
  },
});

/** Description **/
const alternativeDescriptionMessages = defineMessages<
  SustainabilityPreference,
  MessageWithId,
  Record<SustainabilityPreference, MessageWithId>
>({
  [SustainabilityPreference.NONE]: {
    id: "sustainability.preference.alt.NONE.description",
  },
  [SustainabilityPreference.SPECIFIC]: {
    id: "sustainability.preference.alt.SPECIFIC.description",
  },
});

const alternativeDescriptionCorpMessages = defineMessages<
  SustainabilityPreference,
  MessageWithId,
  Record<SustainabilityPreference, MessageWithId>
>({
  [SustainabilityPreference.NONE]: {
    id: "sustainability.preference.corp.alt.NONE.description",
  },
  [SustainabilityPreference.SPECIFIC]: {
    id: "sustainability.preference.corp.alt.SPECIFIC.description",
  },
});

/** Expanded description **/

const alternativeExpandDescriptionNoneMessage = defineMessages({
  item1: {
    id: "sustainability.preference.alt.NONE.expandDescription.item1",
  },
  item2: {
    id: "sustainability.preference.alt.NONE.expandDescription.item2",
    values: {
      link: (...parts: string[]) => (
        <ElevioLink articleId="322" linkText={parts.join("")} />
      ),
    },
  },
});

const alternativeExpandDescriptionSpecificMessage = defineMessages({
  item1: {
    id: "sustainability.preference.alt.SPECIFIC.expandDescription.item1",
  },
});

const alternativeExpandDescriptionMessages = {
  [SustainabilityPreference.NONE]: alternativeExpandDescriptionNoneMessage,
  [SustainabilityPreference.SPECIFIC]:
    alternativeExpandDescriptionSpecificMessage,
};

const alternativeExpandDescriptionCorpNoneMessage = defineMessages({
  item1: {
    id: "sustainability.preference.corp.alt.NONE.expandDescription.item1",
  },
  item2: {
    id: "sustainability.preference.corp.alt.NONE.expandDescription.item2",
    values: {
      link: (...parts: string[]) => (
        <ElevioLink articleId="322" linkText={parts.join("")} />
      ),
    },
  },
});

const alternativeExpandDescriptionCorpSpecificMessage = defineMessages({
  item1: {
    id: "sustainability.preference.corp.alt.SPECIFIC.expandDescription.item1",
  },
});

const alternativeExpandDescriptionCorpMessages = {
  [SustainabilityPreference.NONE]: alternativeExpandDescriptionCorpNoneMessage,
  [SustainabilityPreference.SPECIFIC]:
    alternativeExpandDescriptionCorpSpecificMessage,
};

interface Props {
  sustainabilityPreference?: SustainabilityPreference;
  setSustainabilityPreference: (
    sustainabilityPreference: SustainabilityPreference
  ) => void;
}

export const SustainabilityPreferenceQuestion: FunctionComponent<Props> = ({
  sustainabilityPreference,
  setSustainabilityPreference,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(SustainabilityPreference).map((key) => {
    return {
      header: intl.formatMessage(
        (isPerson
          ? sustainabilityPreferenceAltMessages
          : sustainabilityPreferenceAltCorpMessages)[key]
      ),
      description: (
        <Typography type="body-small" component="span">
          {intl.formatMessage(
            (isPerson
              ? alternativeDescriptionMessages
              : alternativeDescriptionCorpMessages)[key]
          )}
        </Typography>
      ),
      expandableContent: (
        <ExpandableContent>
          <ul>
            {Object.values(
              (isPerson
                ? alternativeExpandDescriptionMessages
                : alternativeExpandDescriptionCorpMessages)[key]
            ).map(
              (item: { id: string; values?: Record<string, any> }, index) => {
                return (
                  <li key={`selection-expandable-content-${index}`}>
                    {intl.formatMessage(item, item.values)}
                  </li>
                );
              }
            )}
          </ul>
        </ExpandableContent>
      ),
      value: key,
    };
  });

  return (
    <RadioGroupCard
      legend={
        <h2>
          {isPerson
            ? intl.formatMessage(messages.header)
            : intl.formatMessage(messages.headerCorp)}
        </h2>
      }
      alternatives={alternatives}
      value={sustainabilityPreference}
      onChange={setSustainabilityPreference}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.required)),
      ]}
    />
  );
};
