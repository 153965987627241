import React, { useContext } from "react";
import {
  AllocationBarIntl,
  Card,
  DocModalLink,
  InvestmentType,
  Button,
  RiskIndicator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import {
  Language,
  LocalizationContext,
} from "../../context/LocalizationContext";
import { TranslatedText } from "../../components/TranslatedText";
import { InvestmentAccountId } from "../../data/dataAccounts";
import "./EditAccountRiskConfirm.scss";
import { LysaCountry } from "@lysaab/countries";

export enum EditAccountActionType {
  ALLOCATION = "ALLOCATION",
  INVESTMENT_TYPE = "INVESTMENT_TYPE",
}

export interface EditAccounrRiskParams {
  accountId: InvestmentAccountId;
  advisedRisk: string;
  takenRisk: string;
  investmentType: InvestmentType;
  language: Language;
  updateType: EditAccountActionType;
  accountName: string;
  name: string;
}

interface Props {
  values?: EditAccounrRiskParams;
  loading: boolean;
  hasError: boolean;
  next: () => void;
}

const messages = defineMessages({
  modalHeader: {
    id: "updateAccountPage.docs.header",
  },
  bonds: {
    id: "updateAccountPage.allocation.bonds",
  },
  stocks: {
    id: "updateAccountPage.allocation.stocks",
  },
  validator: {
    id: "updateAccountPage.summary.confirm.validation",
  },
  yes: {
    id: "updateAccountPage.yes",
  },
  announcement: {
    id: "updateAccountPage.summary.announcement",
  },
});

interface MessageWithId {
  id: string;
}

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageWithId,
  Record<InvestmentType, MessageWithId>
>({
  [InvestmentType.BROAD]: { id: "updateAccountPage.investmentType.broad" },
  [InvestmentType.SUSTAINABLE]: {
    id: "updateAccountPage.investmentType.sustainable",
  },
});

const documentsLanguageMap: Record<LysaCountry, string> = {
  [LysaCountry.DENMARK]: "legal/all/en/investment-guidelines.md",
  [LysaCountry.FINLAND]: "legal/all/en/investment-guidelines.md",
  [LysaCountry.GERMANY]: "legal/de/de/investment-guidelines.md",
  [LysaCountry.SWEDEN]: "legal/se/sv/placeringsriktlinjer.md",
  [LysaCountry.SPAIN]: "legal/es/en/placeringsriktlinjer.md",
};

export const EditAccountRiskConfirm: React.FunctionComponent<Props> = ({
  values,
  loading,
  hasError,
  next,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  if (!values) {
    return null;
  }

  const {
    takenRisk,
    investmentType,
    accountName,
    updateType: actionType,
    name,
  } = values;

  const risk = parseInt(takenRisk, 10);
  const stocks = intl.formatNumber(risk / 100, {
    style: "percent",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const bonds = intl.formatNumber(1 - risk / 100, {
    style: "percent",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="edit-account-risk-confirm">
      <h2>
        <TranslatedText id="updateAccountPage.summary.header" />
      </h2>
      <p>
        <TranslatedText id="updateAccountPage.summary.text" />
      </p>
      <p>
        {localizationContext.state.country && (
          <DocModalLink
            document={documentsLanguageMap[localizationContext.state.country]}
            modalAnnouncement={intl.formatMessage(messages.announcement)}
            variableValues={{
              SIGNING_DATE: intl.formatDate(new Date()),
              CUSTOMER_NAME: name,
              ACCOUNT_NAME: accountName,
              STOCKS: stocks,
              BONDS: bonds,
              INVESTMENT_TYPE: intl.formatMessage(
                investmentTypeMessages[investmentType]
              ),
            }}
          >
            <TranslatedText
              id="updateAccountPage.summary.guidelines"
              values={{
                stocks: stocks,
                bonds: bonds,
                investmentType: intl.formatMessage(
                  investmentTypeMessages[investmentType]
                ),
              }}
            />
          </DocModalLink>
        )}
      </p>
      <Card>
        <dl>
          <dt>
            <TranslatedText id="updateAccountPage.summary.accountName" />
          </dt>
          <dd>{accountName}</dd>
          <dt>
            {actionType === EditAccountActionType.INVESTMENT_TYPE ? (
              <TranslatedText id="updateAccountPage.investmentType.new" />
            ) : (
              <TranslatedText id="updateAccountPage.investmentType" />
            )}
          </dt>
          <dd>
            <span>
              {intl.formatMessage(investmentTypeMessages[investmentType])}
            </span>
            <RiskIndicator
              risk={parseInt(takenRisk, 10)}
              investmentType={investmentType}
            />
          </dd>
        </dl>

        <strong>
          {actionType === EditAccountActionType.ALLOCATION ? (
            <TranslatedText id="updateAccountPage.summary.allocation.new" />
          ) : (
            <TranslatedText id="updateAccountPage.summary.allocation" />
          )}
        </strong>
        <AllocationBarIntl
          messages={{
            stocks: intl.formatMessage(messages.stocks, {
              stocks: parseInt(takenRisk, 10),
            }),
            bonds: intl.formatMessage(messages.bonds, {
              bonds: 100 - parseInt(takenRisk, 10),
            }),
          }}
          risk={parseInt(takenRisk, 10)}
        />
      </Card>
      <p>
        <TranslatedText id="updateAccountPage.summary.confirm" />
      </p>

      {hasError && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <div>
            <TranslatedText id="updateAccountPage.summary.error" />
          </div>
        </Snackbar>
      )}
      <Button
        block
        onClick={next}
        label={<TranslatedText id="updateAccountPage.summary.next" />}
      />
    </div>
  );
};
