import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CardList,
  CardListItem,
  encode,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import {
  DocumentResponse,
  DocumentType,
  getDocuments,
} from "../../data/dataOnfido";
import { TranslatedText } from "../TranslatedText";
import { dataGdprExport } from "../../data/dataGdprExport";

import "./PersonalData.scss";

export const PersonalData = () => {
  const [onfidoDocuments, setOnfidoDocuments] = useState<DocumentResponse[]>(
    []
  );
  const [error, setError] = useState(false);
  const isDownloadInProgress = useRef(false);
  const downloadDataLink = useRef<HTMLAnchorElement>(null);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    getDocuments()
      .then((data) => {
        setOnfidoDocuments(data);
      })
      .catch(() => {
        setError(true);
      });
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const pollCustomerData = () => {
    dataGdprExport
      .getCustomerData()
      .then((response) => {
        if (response.status === "COMPLETE") {
          downloadDataLink.current?.click();
          isDownloadInProgress.current = false;
        } else {
          timer.current = setTimeout(pollCustomerData, 1000);
        }
      })
      .catch(() => {
        isDownloadInProgress.current = false;
      });
  };

  return (
    <React.Fragment>
      <h2>
        <TranslatedText id="yourData.personalData.personalHeader" />
      </h2>
      <CardList>
        <CardListItem>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="yourData.personalData.error" />
            </Snackbar>
          )}
          <div className="flex">
            <TranslatedText id="yourData.personalData.personal" />
            <Button
              variant={"secondary"}
              size="small"
              inline
              onClick={() => {
                if (isDownloadInProgress.current) {
                  return;
                }
                isDownloadInProgress.current = true;
                pollCustomerData();
              }}
              label={<TranslatedText id="yourData.personalData.download" />}
            />
            <a
              download
              className="personal-data__hidden-download-link"
              href={`${process.env.REACT_APP_API}/gdpr-export/customer/download`}
              ref={downloadDataLink}
            >
              <TranslatedText id="yourData.personalData.download" />
            </a>
          </div>
        </CardListItem>
        {onfidoDocuments
          .sort((documentA, documentB) =>
            documentA.documentType.localeCompare(documentB.documentType)
          )
          .map((document) => {
            return (
              <CardListItem key={document.id}>
                <div className="flex">
                  {document.documentType === DocumentType.LIVE_PHOTO && (
                    <TranslatedText id="yourData.personalData.onfidoLivePhoto" />
                  )}
                  {document.documentType === DocumentType.PASSPORT && (
                    <TranslatedText id="yourData.personalData.onfidoPassport" />
                  )}
                  {document.documentType ===
                    DocumentType.NATIONAL_IDENTITY_CARD && (
                    <TranslatedText id="yourData.personalData.onfidoNationalIdentityCard" />
                  )}
                  {document.documentType === DocumentType.UTILITY_BILL && (
                    <TranslatedText id="yourData.personalData.onfidoUtilityBill" />
                  )}
                  <Button
                    component="a"
                    variant="secondary"
                    size="small"
                    href={
                      process.env.REACT_APP_API +
                      encode`/onfido/document?documentId=${document.id}`
                    }
                    download
                    inline
                    label={
                      <TranslatedText id="yourData.personalData.download" />
                    }
                  />
                </div>
              </CardListItem>
            );
          })}
      </CardList>
    </React.Fragment>
  );
};
