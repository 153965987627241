import React, { useEffect, useState, useCallback, useRef } from "react";
import cx from "classnames";
import { CardList } from "@lysaab/ui-2";
import { InvestmentAccount, CompoundAccount } from "../../data/dataAccounts";
import {
  CashTransaction,
  CashTransactionType,
  dataTransactions,
  instanceOfCashTransaction,
} from "../../data/dataTransactions";
import { dataUser } from "../../data/dataUser";
import { Retry, Status } from "../../components/retry/Retry";
import { TransactionEntry } from "./TransactionEntry";
import { CardBottom } from "./CardBottom";
import { FormattedMessage } from "react-intl";
import "./Transactions.scss";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  HistoricTransactionsPageLocationState,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../historicTransactions/HistoricTransactionsPage";

interface Props {
  account: InvestmentAccount | undefined;
  accounts: CompoundAccount[];
}

const MAX_TRANSACTIONS_SHOWN = 4;

export const Transactions: React.FC<Props> = ({ account, accounts }) => {
  const [transactions, setTransactions] = useState<CashTransaction[]>([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  const load = useCallback((accounts, account) => {
    const filter = { ...dataTransactions.getDefaultFilter() };
    filter.start = dataUser.getCreated(accounts);
    dataTransactions
      .getTransactions(filter)
      .then((data) => {
        const items = data.filter(
          (item): item is CashTransaction =>
            instanceOfCashTransaction(item) &&
            item.accountId === account.accountId &&
            (item.type === CashTransactionType.DEPOSIT ||
              item.type === CashTransactionType.WITHDRAWAL)
        );
        setTransactions(items);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    load(accounts, account);
  }, [accounts, account, load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => {
      load(accounts, account);
    }, 500);
  }, [accounts, account, load]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  let elem = null;
  let hasAdditionalTransactions = transactions.length > MAX_TRANSACTIONS_SHOWN;
  if (transactions.length === 0) {
    elem = (
      <div>
        <FormattedMessage
          id="accountPage.transactions.none"
          description="No transactions found for this account"
          defaultMessage="There are no transactions to/from this account"
        />
      </div>
    );
  } else {
    const items = transactions.slice(0, MAX_TRANSACTIONS_SHOWN);
    elem = (
      <div className="account-page-transactions-list">
        <ul>
          {items.map((item) => (
            <li key={item.booked}>
              <TransactionEntry transaction={item} />
            </li>
          ))}
        </ul>
        <div className="additional-transactions-indicator" />
      </div>
    );
  }

  return (
    <section
      className={cx("account-page-transactions", {
        "has-additional-transactions": hasAdditionalTransactions,
      })}
    >
      <h2>
        <FormattedMessage
          id="accountPage.transactions.header"
          description="account page transactions header"
          defaultMessage="Transactions"
        />
      </h2>
      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              <strong>
                {hasAdditionalTransactions ? (
                  <FormattedMessage
                    id="accountPage.transactions.recent"
                    description="account page transactions recent header"
                    defaultMessage={`Recent transactions to/from {name}`}
                    values={{ name: account?.name }}
                  />
                ) : (
                  <FormattedMessage
                    id="accountPage.transactions.all"
                    description="account page transactions all header"
                    defaultMessage={`Transactions to/from {name}`}
                    values={{ name: account?.name }}
                  />
                )}
              </strong>
              {elem}
            </div>
            <CardBottom>
              <Link<HistoricTransactionsPageLocationState>
                to={{
                  pathname: getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL),
                  state: { accountId: account?.accountId },
                }}
              >
                <FormattedMessage id="accountPage.transactions.link.text" />
              </Link>
            </CardBottom>
          </div>
        </Retry>
      </CardList>
    </section>
  );
};
