import React, { FunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { SFDRImportance } from "../../data/dataInvestments";
import { SFDRQuestionInfoModal } from "./modals/SFDRQuestionInfoModal";
import { useIsPerson } from "../../hooks/useIsPerson";

const messages = defineMessages({
  required: { id: "sustainability.sfdr.required" },
});

export const sfdrAltMessages = defineMessages<SFDRImportance>({
  [SFDRImportance.NONE]: {
    id: "sustainability.sfdr.alt.NONE",
  },
  [SFDRImportance.LEAST_25]: {
    id: "sustainability.sfdr.alt.LEAST_25",
  },
  [SFDRImportance.LEAST_50]: {
    id: "sustainability.sfdr.alt.LEAST_50",
  },
  [SFDRImportance.LEAST_75]: {
    id: "sustainability.sfdr.alt.LEAST_75",
  },
});

interface Props {
  sfdr?: SFDRImportance | Alternative<SFDRImportance>;
  setSfdr: (sfdr: Alternative<SFDRImportance>) => void;
}

export const SFDRQuestion: FunctionComponent<Props> = ({ sfdr, setSfdr }) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(SFDRImportance).map((key) => ({
    text: intl.formatMessage(sfdrAltMessages[key]),
    value: key,
  }));

  const value =
    typeof sfdr === "string"
      ? {
          text: intl.formatMessage(sfdrAltMessages[sfdr]),
          value: sfdr,
        }
      : sfdr;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          <>
            {isPerson ? (
              <FormattedMessage id="sustainability.sfdr.header" />
            ) : (
              <FormattedMessage id="sustainability.corp.sfdr.header" />
            )}
            <br />
            <p>
              <SFDRQuestionInfoModal />
            </p>
          </>
        }
        onChange={setSfdr}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
