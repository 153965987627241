import { ReactNode, useCallback, useEffect, useState } from "react";
import { Retry, Status } from "../../../components/retry/Retry";
import {
  InvestmentAccount,
  InvestmentAccountId,
  AccountType,
  dataAccounts,
} from "../../../data/dataAccounts";
import {
  dataWithdrawals,
  ExternalAccount,
  ExternalPendingSavingsWithdrawalResponse,
  PeriodicWithdrawal,
  Withdrawal,
} from "../../../data/dataWithdrawals";
import { Page } from "../../Page";
import { ExternalAccounts } from "./ExternalAccounts";
import { MissingExternalAccount } from "./MissingExternalAccount";
import { PeriodicWithdrawalCard } from "./PeriodicWithdrawalCard";
import { TranslatedText } from "../../../components/TranslatedText";
import { PendingWithdrawals } from "./PendingWithdrawals";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { getNavLink } from "../../../hooks/useCountryUrls";
import {
  dataDanica,
  PendingKfWithdrawalResponse,
} from "../../../data/dataDanica";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../withdrawalPeriodic/WithdrawalPeriodicStory";
import { PendingAccountWithdrawals } from "./components/pendingWithdrawals/PendingAccountWithdrawals";

import HorizontalDivider from "../../../components/horizontalDivider/HorizontalDivider";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { useUser } from "../../../context/UserContext";
import { Typography } from "@lysaab/ui-2";
import {
  CreateSavingsAccountWithdrawalSelectTypeCard,
  ExternalWithdrawalSelectTypeCard,
  InternalWithdrawalSelectTypeCard,
  KFWithdrawalSelectTypeCard,
  VPWithdrawalSelectTypeCard,
} from "./components/withdrawalSelectType/WithdrawalSelectTypeCard";
import { defineMessages, useIntl } from "react-intl";
import { WITHDRAWAL_ISK_TO_SAVINGS_PAGE } from "../internalRequest/WithdrawalIskToSavingsStory";

import "./WithdrawalPage.scss";
import { CREATE_SAVINGS_ACCOUNT_URL } from "../../../countries/sweden/pages/createAccount/savingsAccount/CreateSavingsAccountStory";

export const WITHDRAWAL_PAGE_URL = "/withdrawals";

const messages = defineMessages({
  dividerText: {
    id: "withdrawals.horizontalDivider",
  },
});

interface Props {
  waitingAccountsComponent?: ReactNode;
}

export function WithdrawalPage({ waitingAccountsComponent }: Props) {
  const intl = useIntl();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const user = useUser();
  const isPerson = useIsPerson();
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>(
    []
  );
  const [pendingPeriodicWithdrawals, setPendingPeriodicWithdrawals] = useState<
    PeriodicWithdrawal[]
  >([]);
  const [accounts, setAccounts] = useState<InvestmentAccount[]>([]);
  const [periodicError, setPeriodicError] = useState<
    InvestmentAccountId | string
  >("");
  const [kfPendingWithdrawals, setKfPendingWithdrawals] = useState<
    PendingKfWithdrawalResponse[]
  >([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);
  const [hasSavingsAccount, setHasSavingsAccount] = useState<boolean>(false);

  const load = useCallback(() => {
    Promise.all([
      dataWithdrawals.getExternalAccounts(),
      dataWithdrawals.getPendingPeriodicWithdrawals(),
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
      !isPerson ? dataDanica.getPendingKFWithdrawals() : Promise.resolve([]),
      dataAccounts.getAllAccounts(),
    ])
      .then(
        ([
          requestExternalAccounts,
          requestPendingPeriodicWithdrawal,
          pendingWithdrawals,
          pendingSavingsWithdrawals,
          requestPendingKFWithdrawals,
          allAccounts,
        ]) => {
          setExternalAccounts(requestExternalAccounts);
          setPendingPeriodicWithdrawals(requestPendingPeriodicWithdrawal);
          setPendingWithdrawals(pendingWithdrawals);
          setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
          setKfPendingWithdrawals(requestPendingKFWithdrawals);
          setAccounts(allAccounts.investmentAccounts);
          setStatus(Status.SUCCESS);
          setHasSavingsAccount(allAccounts.savingsAccounts.length > 0);
        }
      )
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [isPerson]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  const removeAccount = useCallback((externalBankAccount: string) => {
    dataWithdrawals
      .deleteExternalAccount(externalBankAccount)
      .then((data) => {
        setExternalAccounts(data);
      })
      .catch(() => {});
    // we're lazy, maybe not the end of the world if an account cannot be removed
  }, []);

  const removePeriodic = useCallback(
    (accountId: InvestmentAccountId) => {
      setPeriodicError("");
      dataWithdrawals
        .deletePeriodicWithdrawal(accountId)
        .then(() => {
          dataWithdrawals.clearGetPendingPeriodicWithdrawals();
          setPendingPeriodicWithdrawals(
            pendingPeriodicWithdrawals.filter(
              (item) => item.accountId !== accountId
            )
          );
        })
        .catch(() => {
          setPeriodicError(accountId);
          setTimeout(() => setPeriodicError(""), 4000);
        });
    },
    [pendingPeriodicWithdrawals]
  );

  const hasKf = accounts.some(
    (account) => account.type === AccountType.DANICA_KF
  );

  const hasPendingWithdrawal =
    pendingWithdrawals.length > 0 ||
    pendingSavingsAccountWithdrawals.length > 0 ||
    pendingPeriodicWithdrawals.length > 0 ||
    kfPendingWithdrawals.length > 0;

  return (
    <Page className="withdrawal-page-wrapper">
      <div className="withdrawal-page-boundary">
        <Typography type="h1">
          <TranslatedText id="withdrawals.header" />
        </Typography>

        <Retry retry={retry} status={status}>
          <div className="withdrawal-page">
            {isPerson ? (
              <>
                <ExternalWithdrawalSelectTypeCard />
                <RouteAwareToggle
                  path={getNavLink(WITHDRAWAL_ISK_TO_SAVINGS_PAGE)}
                >
                  <HorizontalDivider
                    text={intl.formatMessage(messages.dividerText)}
                  />
                  {hasSavingsAccount ? (
                    <InternalWithdrawalSelectTypeCard />
                  ) : (
                    <RouteAwareToggle
                      path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}
                    >
                      <CreateSavingsAccountWithdrawalSelectTypeCard />
                    </RouteAwareToggle>
                  )}
                </RouteAwareToggle>
              </>
            ) : (
              <>
                <VPWithdrawalSelectTypeCard />
                {hasKf && (
                  <>
                    <HorizontalDivider
                      text={intl.formatMessage(messages.dividerText)}
                    />
                    <KFWithdrawalSelectTypeCard />
                  </>
                )}
              </>
            )}

            <HorizontalDivider />
            <div>
              <ExternalAccounts
                externalAccounts={externalAccounts}
                removeAccount={removeAccount}
                waitingAccountsComponent={waitingAccountsComponent}
              />

              <MissingExternalAccount
                externalAccounts={externalAccounts}
                user={user}
              />
            </div>

            {hasPendingWithdrawal && (
              <>
                <HorizontalDivider />
                <div>
                  <Typography type="h3">
                    <TranslatedText id="withdrawals.pending.header" />
                  </Typography>
                  <PendingAccountWithdrawals />
                  <PendingWithdrawals
                    accounts={accounts}
                    pendingPeriodicWithdrawals={pendingPeriodicWithdrawals}
                    removePeriodic={removePeriodic}
                    periodicError={periodicError}
                    kfPendingWithdrawals={kfPendingWithdrawals}
                  />
                </div>
              </>
            )}

            <RouteAwareToggle path={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}>
              <HorizontalDivider />
              <PeriodicWithdrawalCard />
            </RouteAwareToggle>
          </div>
        </Retry>
      </div>
    </Page>
  );
}
