import {
  Alternative,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  ExternalAccount,
  dataWithdrawals,
  getBankText,
} from "../../../../data/dataWithdrawals";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AddKlarnaAccountLocationState } from "../../../withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { BASE_ROUTES, CloseAccountState } from "../../CloseLysaCustomerStory";

const messages = defineMessages({
  lysaAccountLabel: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.externalAccounts.externalAccountlabel",
  },
  lysaAccountRequired: {
    id: "closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.externalAccounts.externalAccountRequired",
  },
});

export const ExternalAccounts = ({
  addWithdrawalAccountUrl,
  selectedExternalAccount,
  setSelectedExternalAccount,
  returnState,
}: {
  addWithdrawalAccountUrl?: string;
  selectedExternalAccount?: Alternative<string>;
  setSelectedExternalAccount: React.Dispatch<
    React.SetStateAction<Alternative<string> | undefined>
  >;
  returnState?: CloseAccountState;
}) => {
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setIsLoading(true);
    dataWithdrawals
      .getExternalAccounts()
      .then(setExternalAccounts)
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Spinner />;
  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.externalAccounts.error" />
      </Snackbar>
    );
  }
  return (
    <React.Fragment>
      {externalAccounts.length > 0 ? (
        <RadioGroup
          alternatives={externalAccounts.map((account): Alternative<string> => {
            return {
              text: getBankText(account),
              value: account.externalBankAccount,
            };
          })}
          value={selectedExternalAccount}
          header={intl.formatMessage(messages.lysaAccountLabel)}
          onChange={setSelectedExternalAccount}
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.lysaAccountRequired)
            ),
          ]}
        />
      ) : (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.externalAccounts.noExternalAccount" />
        </Snackbar>
      )}
      {addWithdrawalAccountUrl && (
        <Link<AddKlarnaAccountLocationState>
          to={{
            pathname: addWithdrawalAccountUrl,
            state: {
              returnUrl: getNavLink(
                BASE_ROUTES.CLOSE_LYSA_CUSTOMER_CONFIRMATION
              ),
              returnState,
            },
          }}
          className="lysa-link"
        >
          <TranslatedText id="closeLysaCustomerAccountStory.closeLysaCustomerConfirmation.externalAccounts.addExternalAccount" />
        </Link>
      )}
    </React.Fragment>
  );
};
