import React, { useReducer, createContext, FunctionComponent } from "react";
import { AvailableBank, KlarnaAccount } from "../../../../data/dataKlarna";
import { InvestmentAccount } from "../../../../data/dataAccounts";

interface MonthlyState {
  bank?: AvailableBank;
  selectedLysaAccount?: InvestmentAccount;
  klarnaAccounts?: KlarnaAccount[];
  amount?: string;
  selectedKlarnaAccount?: KlarnaAccount;
  withdrawalDay: number;
  externalAccount?: string;
}

interface MonthlyContextProps {
  state: MonthlyState;
  setState: (newState: Partial<MonthlyState>) => void;
}

const DEFAULT_MONTHLY_WITHDRAWAL_DAY = 29;

const MonthlyContext = createContext<MonthlyContextProps | undefined>(
  undefined
);

function stateReducer(state: MonthlyState, newState: Partial<MonthlyState>) {
  return { ...state, ...newState };
}

export const MonthlyContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    withdrawalDay: DEFAULT_MONTHLY_WITHDRAWAL_DAY,
  });

  return (
    <MonthlyContext.Provider value={{ state, setState }}>
      {children}
    </MonthlyContext.Provider>
  );
};

export const useMonthlyContext = () => {
  const monthlyContext = React.useContext(MonthlyContext);
  if (monthlyContext === undefined) {
    throw new Error(
      "useMonthlyContext must be used within a MonthlyContextProvider"
    );
  }

  return monthlyContext;
};
