import React, { useEffect, useRef, useContext } from "react";
import {
  Currency,
  Language,
  LocalizationContext,
} from "../../context/LocalizationContext";
import {
  OverviewPage,
  OVERVIEW_PAGE_URL,
} from "../../pages/overview/OverviewPage";
import { ProfilePage, PROFILE_PAGE_URL } from "../../pages/profile/ProfilePage";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import { AccountPage, ACCOUNT_PAGE_URL } from "../../pages/account/AccountPage";
import {
  PublicRoute,
  PrivateRoute,
  NonPrivateRoute,
} from "../../components/router/Routes";
import {
  LoginTinPasswordPage,
  LOGIN_TIN_PASSWORD_PAGE_DEPRECATED_URL,
  LOGIN_TIN_PASSWORD_PAGE_URL,
} from "./pages/loginTinPassword/LoginTinPasswordPage";
import {
  LoginResetStory,
  LOGIN_RESET_PAGE_URL,
} from "../../pages/loginReset/LoginResetStory";
import {
  MessagesPage,
  MESSAGES_PAGE_URL,
} from "../../pages/messages/MessagesPage";
import { ContactPage, CONTACT_PAGE_URL } from "../../pages/contact/ContactPage";
import { StorePage, STORE_PAGE_URL } from "../../pages/StorePage";
import { OfflinePage, OFFLINE_URL } from "../../pages/OfflinePage";
import { useWrongCountryRedirect } from "../../hooks/useWrongCountryRedirect";
import {
  KlarnaStory,
  KLARNA_DEPOSITS_URL,
} from "../../pages/deposits/klarna/KlarnaStory";
import {
  EDIT_ALLOCATION_PAGE_URL,
  EditAllocationPage,
} from "../../pages/editAllocation/EditAllocationPage";
import { LogoutPage, LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { Switch } from "../../components/route/Switch";
import { generatePath, Redirect, useLocation } from "react-router";
import {
  ADD_EXTERNAL_KLARNA_URL,
  AddAccountKlarnaStory,
} from "../../pages/withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import {
  ADD_EXTERNAL_MANUAL_URL,
  AddAccountManualStory,
} from "../../pages/withdrawal/addAccountManual/AddAccountManualStory";
import {
  WithdrawalStory,
  WITHDRAWALS_REQUEST_PAGE,
} from "../../pages/withdrawal/request/WithdrawalStory";
import { WITHDRAWAL_PAGE_URL } from "../../pages/withdrawal/overview/WithdrawalPage";
import {
  TransfersMenuPage,
  TRANSFERS_MENU_PAGE_URL,
} from "../../pages/transfersMenu/TransfersMenuPage";
import {
  VirtualAccountStory,
  VIRTUAL_ACCOUNT_PAGE_URL,
  BASE_ROUTES as VIRTUAL_ACCOUNT_ROUTES,
} from "../../pages/deposits/virtualAccount/VirtualAccountStory";
import {
  EndInvestment,
  END_INVESTMENT_URL,
} from "../../pages/endInvestment/EndInvestment";
import {
  CloseAccountStory,
  CLOSE_ACCOUNT_URL,
} from "../../pages/closeAccountStory/CloseAccountStory";
import {
  YOUR_DATA_PAGE_URL,
  YourDataPage,
} from "../../pages/yourData/YourDataPage";
import {
  UpdateAccountPage,
  UPDATE_ACCOUNT_PAGE_URL,
} from "../../pages/updateAccount/UpdateAccountPage";
import { CRS_PAGE_URL, CrsPage } from "../../pages/crs/CrsPage";
import {
  UPDATE_CRS_URL,
  UpdateCrsStory,
} from "../../pages/updateCrsStory/UpdateCrsStory";
import {
  SettingsPage,
  SETTINGS_PAGE_URL,
} from "../../pages/settings/SettingsPage";
import { FeesPage, FEES_PAGE_URL } from "../../pages/fees/FeesPage";
import {
  CreateAccountStory,
  CREATE_ACCOUNT_URL,
} from "../../pages/createAccount/CreateAccountStory";
import {
  ChangePasswordPage,
  CHANGE_PASSWORD_PAGE_URL,
} from "../../pages/changePassword/ChangePasswordPage";
import { INVITE_PAGE_URL, InvitePage } from "../../pages/invite/InvitePage";
import {
  DocumentsPage,
  DOCUMENT_PAGE_URL,
} from "../../pages/documents/DocumentsPage";
import {
  ChangeEmailPage,
  CHANGE_EMAIL_PAGE_URL,
} from "../../pages/changeEmail/ChangeEmailPage";
import {
  ChangeTwoFaPage,
  CHANGE_TWO_FA_PAGE_URL,
} from "../../pages/changeTwoFa/ChangeTwoFaPage";
import { DepositsOverviewWrapper } from "./pages/deposits/DepositsOverviewWrapper";
import { KycViewPage, KYC_PAGE_URL } from "../../pages/kyc/KycViewPage";
import {
  HistoricTransactionsPage,
  HISTORIC_TRANSACTIONS_PAGE_URL,
} from "../../pages/historicTransactions/HistoricTransactionsPage";
import { InvoicePage, INVOICE_PAGE_URL } from "../../pages/invoice/InvoicePage";
import { usePreferredCountry } from "../../hooks/usePreferredCountry";
import { TAX_INFORMATION_PAGE_URL } from "../../pages/taxInformation/TaxInformation";
import {
  CloseLysaCustomerAccountStory,
  CLOSE_LYSA_CUSTOMER_URL,
} from "../../pages/closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import {
  AccountStatementPage,
  ACCOUNT_STATEMENT_PAGE,
} from "../../pages/accountStatement/AccountStatementPage";
import {
  YearlyReviewStory,
  YEARLY_REVIEW_URL,
} from "../../pages/yearlyReview/YearlyReviewStory";
import {
  AccountKycUpdate,
  KYC_ACCOUNT_UPDATE_PAGE_URL,
} from "../../pages/kyc/accountsKyc/accountKycUpdate/AccountKycUpdate";
import {
  KYC_UPDATE_PEP_STORY,
  PepUpdateStory,
} from "../../pages/kyc/pep/pepUpdate/PepUpdateStory";
import {
  SUITABILITY_ASSESSMENT_EDIT_PAGE,
  SuitabilityAssessmentStory,
} from "../../pages/suitabilityAssessment/SuitabilityAssessmentStory";
import { DEPOSITS_OVERVIEW_URL } from "../../pages/deposits/overview/Recommendation";
import { Crs } from "../../pages/yearlyReview/pages/crs/Crs";
import { CREATE_ACCOUNT_INTRO_URL } from "../sweden/pages/createAccount/createAccountIntroStory/CreateAccountIntroStory";
import {
  AccountSituationStory,
  ACCOUNT_SITUATION_EDIT_PAGE,
} from "../../pages/accountSituation/AccountSituationStory";
import {
  ReviewAccountStory,
  REVIEW_ACCOUNT_URL,
} from "../../pages/reviewAccount/ReviewAccountStory";
import { CompoundAccountId } from "../../data/dataAccounts";
import { TaxInformation } from "./pages/taxInformation/TaxInformation";
import {
  LoginNetsPage,
  LOGIN_NETS_PAGE_URL,
} from "../../pages/login/LoginNetsPage";
import {
  WithdrawalPeriodicStory,
  WITHDRAWALS_PERIODIC_STORY_URL,
} from "../../pages/withdrawal/withdrawalPeriodic/WithdrawalPeriodicStory";
import {
  MonthlyOverview,
  MONTHLY_OVERVIEW_PAGE,
} from "../../pages/deposits/monthly/overview/MonthlyOverview";
import {
  MonthlyStory,
  MONTHLY_DEPOSITS_URL,
} from "../../pages/deposits/monthly/create/MonthlyStory";
import { PendingDirectDebitTransactions } from "../../pages/deposits/monthly/overview/components/pendingTransaction/PendingDirectDebitTransactions";
import {
  MonthlyAgreements,
  MONTHLY_AGREEMENTS_PAGE_URL,
} from "../../pages/deposits/monthly/agreements/MonthlyAgreements";
import { LysaCountry } from "@lysaab/countries";
import { UserContext } from "../../context/UserContext";
import {
  SHARED_SAVINGS_ACCOUNT_PAGE_URL,
  SharedSavingsAccountPage,
} from "../../pages/account/savingsAccountPage/SharedSavingsAccountPage";
import {
  SHARED_ACCOUNT_PAGE_URL,
  SharedAccountPage,
} from "../../pages/account/sharedAccountPage/SharedAccountPage";
import {
  SHARE_ACCOUNT_INVITATION_URL,
  ShareAccountInvitationPage,
} from "../../pages/shareAccountInvitation/ShareAccountInvitationPage";
import {
  SHARE_ACCOUNT_INVITATIONS_URL,
  ShareAccountInvitationsPage,
} from "../../pages/shareAccountInvitations/ShareAccountInvitationsPage";
import {
  SHARE_ACCOUNT_SENT_INVITATION_URL,
  ShareAccountSentInvitationPage,
} from "../../pages/shareAccountSentInvitation/ShareAccountSentInvitationPage";
import {
  SHARE_ACCOUNT_URL,
  ShareAccountStory,
} from "../../pages/shareAccountStory/ShareAccountStory";
import {
  SHARED_ACCOUNTS_URL,
  SharedAccountsPage,
} from "../../pages/sharedAccountsPage/SharedAccountsPage";
import {
  CHUNK_LOAD_ERROR_PAGE_URL,
  ChunkLoadErrorPage,
} from "../../pages/chunkLoadError/ChunkLoadErrorPage";
import { WithdrawalPage } from "../../pages/withdrawal/overview/WithdrawalPage";

const CURRENCY = Currency.EUR;

interface Props {
  netsDonePath: string;
}

export const Router: React.VFC<Props> = ({ netsDonePath }) => {
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);
  useWrongCountryRedirect(LysaCountry.FINLAND);
  usePreferredCountry(LysaCountry.FINLAND);
  const loaded = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (!loaded.current) {
      localizationContext.setState({
        currency: CURRENCY,
        language: userContext.state.language ?? Language.ENGLISH,
        country: LysaCountry.FINLAND,
      });
    }
    loaded.current = true;
  }, [localizationContext, userContext.state.language]);

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <Switch>
      <Redirect
        from={getNavLink(LOGIN_TIN_PASSWORD_PAGE_URL)}
        to={{
          pathname: getNavLink(LOGIN_NETS_PAGE_URL),
          search: location.search,
        }}
      />
      <NonPrivateRoute
        path={getNavLink(LOGIN_TIN_PASSWORD_PAGE_DEPRECATED_URL)}
        exact
      >
        <LoginTinPasswordPage />
      </NonPrivateRoute>
      <NonPrivateRoute path={getNavLink(LOGIN_NETS_PAGE_URL)} exact>
        <LoginNetsPage donePath={netsDonePath} country={LysaCountry.FINLAND} />
      </NonPrivateRoute>
      <NonPrivateRoute path={getNavLink(LOGIN_RESET_PAGE_URL)}>
        <LoginResetStory />
      </NonPrivateRoute>
      <PrivateRoute path={getNavLink(OVERVIEW_PAGE_URL)} exact>
        <OverviewPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(TRANSFERS_MENU_PAGE_URL)} exact>
        <TransfersMenuPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(PROFILE_PAGE_URL)} exact>
        <ProfilePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHANGE_EMAIL_PAGE_URL)} exact>
        <ChangeEmailPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHANGE_PASSWORD_PAGE_URL)} exact>
        <ChangePasswordPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHANGE_TWO_FA_PAGE_URL)} exact>
        <ChangeTwoFaPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_PAGE_URL)} exact>
        <AccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MESSAGES_PAGE_URL)} exact>
        <MessagesPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CONTACT_PAGE_URL)} exact>
        <ContactPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(STORE_PAGE_URL)} exact>
        <StorePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(EDIT_ALLOCATION_PAGE_URL)} exact>
        <EditAllocationPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(UPDATE_ACCOUNT_PAGE_URL)}>
        <UpdateAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(OFFLINE_URL)} exact>
        <OfflinePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KLARNA_DEPOSITS_URL)}>
        <KlarnaStory
          getFallbackDepositLocationDescriptor={(state: {
            accountId?: CompoundAccountId;
          }) => {
            if (state.accountId) {
              return {
                pathname: generatePath(
                  getNavLink(VIRTUAL_ACCOUNT_ROUTES.ACCOUNT),
                  {
                    accountId: state.accountId,
                  }
                ),
              };
            } else {
              return {
                pathname: getNavLink(VIRTUAL_ACCOUNT_PAGE_URL),
              };
            }
          }}
        />
      </PrivateRoute>
      <PrivateRoute exact path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
        <DepositsOverviewWrapper />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWAL_PAGE_URL)} exact>
        <WithdrawalPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWALS_PERIODIC_STORY_URL)}>
        <WithdrawalPeriodicStory
          addWithdrawalAccountUrl={getNavLink(ADD_EXTERNAL_KLARNA_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ADD_EXTERNAL_KLARNA_URL)}>
        <AddAccountKlarnaStory
          missingBank={getNavLink(ADD_EXTERNAL_MANUAL_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ADD_EXTERNAL_MANUAL_URL)}>
        <AddAccountManualStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(VIRTUAL_ACCOUNT_PAGE_URL)}>
        <VirtualAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(END_INVESTMENT_URL)} exact>
        <EndInvestment />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_ACCOUNT_URL)}>
        <CloseAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(YOUR_DATA_PAGE_URL)}>
        <YourDataPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CRS_PAGE_URL)} exact>
        <CrsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(UPDATE_CRS_URL)}>
        <UpdateCrsStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SETTINGS_PAGE_URL)}>
        <SettingsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(FEES_PAGE_URL)}>
        <FeesPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(INVITE_PAGE_URL)}>
        <InvitePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(WITHDRAWALS_REQUEST_PAGE)}>
        <WithdrawalStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CREATE_ACCOUNT_URL)}>
        <CreateAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(DOCUMENT_PAGE_URL)}>
        <DocumentsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(YEARLY_REVIEW_URL)}>
        <YearlyReviewStory crsComponent={Crs} />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KYC_PAGE_URL)} exact>
        <KycViewPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KYC_ACCOUNT_UPDATE_PAGE_URL)} exact>
        <AccountKycUpdate />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}>
        <SuitabilityAssessmentStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(REVIEW_ACCOUNT_URL)}>
        <ReviewAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_SITUATION_EDIT_PAGE)}>
        <AccountSituationStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(KYC_UPDATE_PEP_STORY)}>
        <PepUpdateStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL)}>
        <HistoricTransactionsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(INVOICE_PAGE_URL)}>
        <InvoicePage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(TAX_INFORMATION_PAGE_URL)}>
        <TaxInformation />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CLOSE_LYSA_CUSTOMER_URL)}>
        <CloseLysaCustomerAccountStory
          addWithdrawalAccountUrl={getNavLink(ADD_EXTERNAL_KLARNA_URL)}
        />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(ACCOUNT_STATEMENT_PAGE)}>
        <AccountStatementPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_OVERVIEW_PAGE)} exact>
        <MonthlyOverview>
          <PendingDirectDebitTransactions />
        </MonthlyOverview>
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_DEPOSITS_URL)}>
        <MonthlyStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(MONTHLY_AGREEMENTS_PAGE_URL)}>
        <MonthlyAgreements />
      </PrivateRoute>

      <PrivateRoute path={getNavLink(SHARED_ACCOUNT_PAGE_URL)} exact>
        <SharedAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARED_SAVINGS_ACCOUNT_PAGE_URL)} exact>
        <SharedSavingsAccountPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARE_ACCOUNT_URL)}>
        <ShareAccountStory />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARE_ACCOUNT_SENT_INVITATION_URL)}>
        <ShareAccountSentInvitationPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARE_ACCOUNT_INVITATION_URL)}>
        <ShareAccountInvitationPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARE_ACCOUNT_INVITATIONS_URL)} exact>
        <ShareAccountInvitationsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(SHARED_ACCOUNTS_URL)}>
        <SharedAccountsPage />
      </PrivateRoute>
      <PrivateRoute path={getNavLink(CHUNK_LOAD_ERROR_PAGE_URL)}>
        <ChunkLoadErrorPage />
      </PrivateRoute>

      <Redirect
        from={getNavLink(CREATE_ACCOUNT_INTRO_URL)}
        to={getNavLink(CREATE_ACCOUNT_URL)}
      />
      <PublicRoute path={getNavLink(LOGOUT_PAGE_URL)} exact>
        <LogoutPage />
      </PublicRoute>
      <PublicRoute>
        <NotFoundPage />
      </PublicRoute>
    </Switch>
  );
};
