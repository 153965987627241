import React from "react";
import cx from "classnames";
import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import "./Page.scss";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
}

export interface PageHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export function Page({ children, className }: PageProps) {
  return (
    <main className={cx("page", className)}>
      <Header />
      <article>{children}</article>
      <Footer />
    </main>
  );
}

export function PageHeader({ children, className }: PageHeaderProps) {
  return <div className={cx("page-header", className)}>{children}</div>;
}
