import { defineMessages } from "react-intl";
import { InvestmentType } from "../../../data/dataAccounts";
import {
  SavingsHorizonLength,
  NeedEarlierProbability,
} from "../../../data/dataInvestments";
import { LegalEntityType } from "../../../data/dataLogin";

export const messages = defineMessages({
  investmentType: {
    id: "edit-allocation.summary.investmentType",
  },
  [InvestmentType.BROAD]: {
    id: "edit-allocation.summary.investmentType.alt.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "edit-allocation.summary.investmentType.alt.SUSTAINABLE",
  },
  horizon: {
    id: "edit-allocation.summary.horizon",
  },
  probability: {
    id: "edit-allocation.summary.probability",
  },
  [SavingsHorizonLength.VERY_LONG]: {
    id: "edit-allocation.advice.veryLong",
  },
  [SavingsHorizonLength.LONG]: {
    id: "edit-allocation.advice.long",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "edit-allocation.advice.kindaLong",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "edit-allocation.advice.middle",
  },
  [SavingsHorizonLength.SHORT]: {
    id: "edit-allocation.advice.short",
  },
  [NeedEarlierProbability.VERY_LIKELY]: {
    id: "edit-allocation.advice.veryLikely",
  },
  [NeedEarlierProbability.LIKELY]: {
    id: "edit-allocation.advice.likely",
  },
  [NeedEarlierProbability.SOMEWHAT_LIKELY]: {
    id: "edit-allocation.advice.somewhatLikely",
  },
  [NeedEarlierProbability.SOMEWHAT_UNLIKELY]: {
    id: "edit-allocation.advice.somewhatUnlikely",
  },
  [NeedEarlierProbability.UNLIKELY]: {
    id: "edit-allocation.advice.unlikely",
  },
  [NeedEarlierProbability.VERY_UNLIKELY]: {
    id: "edit-allocation.advice.veryUnlikely",
  },
  accountError: {
    id: "edit-allocation.summary.accountError",
  },
  risk: {
    id: "edit-allocation.investmentBasis.risk.header",
  },
  riskImportant0: {
    id: "edit-allocation.investmentBasis.risk.important.minimize",
  },
  riskImportant5: {
    id: "edit-allocation.investmentBasis.risk.important.both",
  },
  riskImportant10: {
    id: "edit-allocation.investmentBasis.risk.important.maximize",
  },
  riskPropensity2: {
    id: "edit-allocation.investmentBasis.risk.propensity.toorisky",
  },
  riskPropensity4: {
    id: "edit-allocation.investmentBasis.risk.propensity.prettygood",
  },
  riskPropensity6: {
    id: "edit-allocation.investmentBasis.risk.propensity.good",
  },
  riskPropensity8: {
    id: "edit-allocation.investmentBasis.risk.propensity.prettybad",
  },
  riskPropensity10: {
    id: "edit-allocation.investmentBasis.risk.propensity.bad",
  },
  riskReaction3: {
    id: "edit-allocation.investmentBasis.risk.reaction.sell",
  },
  riskReaction7: {
    id: "edit-allocation.investmentBasis.risk.reaction.keep",
  },
  riskReaction10: {
    id: "edit-allocation.investmentBasis.risk.reaction.buy",
  },
  riskImportant: {
    id: "edit-allocation.investmentBasis.risk.important",
  },
  riskReaction: {
    id: "edit-allocation.investmentBasis.risk.reaction",
  },
  monthlyEarnings: {
    id: "edit-allocation.investmentBasis.risk.monthlyEarnings",
  },
  [LegalEntityType.CORPORATION]: {
    id: "edit-allocation.investmentBasis.corporation.label",
  },
  [LegalEntityType.PERSON]: {
    id: "edit-allocation.investmentBasis.person.label",
  },
  seeMore: {
    id: "edit-allocation.investmentBasis.seeMore",
  },
});
