import { Story } from "@lysaab/ui-2";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router";
import { InvestmentAccountId } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { PageStripped } from "../PageStripped";
import {
  AccountSituationContextProvider,
  useAccountSituationContext,
} from "./context/AccountSituationContext";
import { AccessGuardRoute } from "./AccessGuardRoute";
import { AccountAllocation } from "./pages/AccountAllocation";
import { Done } from "./pages/Done";
import {
  dataInvestments,
  isEsgQuestionsUpdate,
  isSustainabilityImportantSpecific,
  SustainabilityImportance,
  isValidEligibilityPerson,
  isValidEligibilityCorporation,
} from "../../data/dataInvestments";
import { Sustainability } from "./pages/Sustainability";
import { SustainabilityQuestions } from "./pages/SustainabilityQuestions";
import { SustainabilityPreference } from "./pages/SustainabilityPreference";
import { SuitabilityDownloadLazy } from "../../pageComponents/advise/SuitabilityDownload";
import {
  AccountContextProvider,
  useAccountContext,
} from "./context/AccountContext";
import { ReviewAccountContextProvider } from "../reviewAccount/ReviewAccountContext";
import { AccountHorizon } from "./pages/AccountHorizon";
import { useNavigateToReviewAccount } from "./hooks/useNavigateToReviewAccount";
import {
  EligibilityContextProvider,
  isValidEligibilityStateCorporation,
  isValidEligibilityStatePerson,
  useEligibilityContext,
} from "../../context/EligibilityContext";
import { BASE_ROUTES as REVIEW_ACCOUNT_ROUTES } from "../reviewAccount/ReviewAccountStory";
import { CorporationExpenses } from "../../pageComponents/economicSituation/economicSituation/corporation/Expenses";
import { CorporationInsolvencyRisk } from "../../pageComponents/economicSituation/economicSituation/corporation/InsolvencyRisk";
import { isSustainabilityImportantNone } from "../../data/dataInvestments";
import { isSustainabilityNotImportant } from "../../data/dataInvestments";

const messages = defineMessages({
  header: {
    id: "accountSituation.header",
  },
  ariaProgressLabel: {
    id: "accountSituation.ariaProgressLabel",
  },
});

export const ACCOUNT_SITUATION_EDIT_PAGE = "/account-situation/:accountId";

export const BASE_ROUTES = {
  ACCOUNT_SITUATION: `${ACCOUNT_SITUATION_EDIT_PAGE}/`,
  SUSTAINABILITY: `${ACCOUNT_SITUATION_EDIT_PAGE}/sustainability`,
  PREFERENCE: `${ACCOUNT_SITUATION_EDIT_PAGE}/preference`,
  SUSTAINABILITY_QUESTIONS: `${ACCOUNT_SITUATION_EDIT_PAGE}/sustainability-questions`,
  ACCOUNT_ALLOCATION: `${ACCOUNT_SITUATION_EDIT_PAGE}/account-allocation`,
  DONE: `${ACCOUNT_SITUATION_EDIT_PAGE}/done`,
};

export interface AccountSituationInstanceLocationState {
  returnUrl: string;
}

const AccountSituationStoryInstance: React.VFC = () => {
  const intl = useIntl();
  const history = useHistory<
    AccountSituationInstanceLocationState | undefined
  >();
  const { accountId } = useParams<{ accountId: InvestmentAccountId }>();
  const safeNavigation = useSafeNavigation();
  const navigateToReviewAccount = useNavigateToReviewAccount();
  const [accountState] = useAccountContext();
  const [eligibilityState, setEligibilityState] = useEligibilityContext();
  const [accountSituationState] = useAccountSituationContext();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const [locationState, setLocationState] =
    useState<AccountSituationInstanceLocationState>();

  useEffect(() => {
    if (
      typeof locationState === "undefined" &&
      typeof history.location.state !== "undefined"
    ) {
      setLocationState(history.location.state);
    }
  }, [history.location.state, locationState]);

  useEffect(() => {
    if (
      isValidEligibilityStatePerson(eligibilityState) ||
      isValidEligibilityStateCorporation(eligibilityState)
    ) {
      return;
    }

    dataInvestments.getEligibility().then((response) => {
      if (isValidEligibilityPerson(response)) {
        setEligibilityState(response);
      } else if (isValidEligibilityCorporation(response)) {
        setEligibilityState({
          ...response,
          expenses: CorporationExpenses.more,
          insolvencyRisk: CorporationInsolvencyRisk.yes,
        });
      } else {
        throw new Error("AccountSituationStory - Invalid eligibility response");
      }
    });
  }, [eligibilityState, history.location.state, setEligibilityState]);

  const navigate = useCallback(
    (url: string) => {
      safeNavigation(
        generatePath(url, {
          accountId,
        })
      );
    },
    [accountId, safeNavigation]
  );

  const goBack = () => {
    const current = Object.values(ROUTES)[currentIndex];

    switch (current) {
      case ROUTES.ACCOUNT_ALLOCATION: {
        let route: string | undefined;
        if (isSustainabilityImportantSpecific(accountState.account)) {
          route = generatePath(ROUTES.SUSTAINABILITY_QUESTIONS, {
            accountId,
          });
        }

        if (isSustainabilityImportantNone(accountState.account)) {
          route = generatePath(ROUTES.PREFERENCE, {
            accountId,
          });
        }

        if (isSustainabilityNotImportant(accountState.account)) {
          route = generatePath(ROUTES.SUSTAINABILITY, {
            accountId,
          });
        }

        if (typeof route === "string") {
          safeNavigation(route);
        }

        break;
      }
      default: {
        const previousRoute = generatePath(
          Object.values(ROUTES)[currentIndex - 1],
          {
            accountId,
          }
        );
        safeNavigation(previousRoute);
      }
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={currentIndex !== 0 && currentIndex !== storyLength - 1}
        showClose={true}
        onBack={goBack}
        onExit={() =>
          safeNavigation(
            locationState?.returnUrl || getNavLink(OVERVIEW_PAGE_URL)
          )
        }
        transitionKey={currentIndex.toString()}
      >
        <Switch
          location={history.location}
          {...{
            order: currentIndex,
          }}
        >
          <AccessGuardRoute exact path={ROUTES.ACCOUNT_SITUATION}>
            <AccountHorizon
              accountId={accountId}
              next={() => navigate(ROUTES.SUSTAINABILITY)}
            />
          </AccessGuardRoute>
          <Route exact path={ROUTES.SUSTAINABILITY}>
            <Sustainability
              next={() => {
                if (!accountState.account) {
                  return;
                }
                if (
                  accountState.account.sustainability ===
                  SustainabilityImportance.IMPORTANT
                ) {
                  navigate(ROUTES.PREFERENCE);
                } else {
                  navigate(ROUTES.ACCOUNT_ALLOCATION);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.PREFERENCE}>
            <SustainabilityPreference
              next={() => {
                if (!accountState.account) {
                  return;
                }
                if (isSustainabilityImportantSpecific(accountState.account)) {
                  navigate(ROUTES.SUSTAINABILITY_QUESTIONS);
                } else {
                  navigate(ROUTES.ACCOUNT_ALLOCATION);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
            <SustainabilityQuestions
              next={() => navigate(ROUTES.ACCOUNT_ALLOCATION)}
            />
          </Route>
          <AccessGuardRoute exact path={ROUTES.ACCOUNT_ALLOCATION}>
            <AccountAllocation
              next={() => navigate(ROUTES.DONE)}
              navigateReviewRiskWarning={(reviewAccount) =>
                navigateToReviewAccount(
                  REVIEW_ACCOUNT_ROUTES.RISK_WARNING,
                  reviewAccount
                )
              }
              navigateReview={(reviewAccount) => {
                const hasChangedEsgValues =
                  typeof reviewAccount.newAdvice.esgResult.esgBestMatch !==
                  "undefined";

                const isAnswersUpdated =
                  typeof reviewAccount.oldAdvice.esgResult !== "undefined" &&
                  typeof reviewAccount.oldAdvice.esgResult.esgAnswers !==
                    "undefined"
                    ? isEsgQuestionsUpdate(
                        reviewAccount.newAdvice.esgResult.esgAnswers,
                        reviewAccount.oldAdvice.esgResult.esgAnswers
                      )
                    : true;

                if (hasChangedEsgValues && isAnswersUpdated) {
                  navigateToReviewAccount(
                    REVIEW_ACCOUNT_ROUTES.CONFIRM_ESG_UPDATE,
                    reviewAccount
                  );
                } else {
                  navigateToReviewAccount(
                    REVIEW_ACCOUNT_ROUTES.REVIEW_HORIZON_ADVICE,
                    reviewAccount
                  );
                }
              }}
            />
          </AccessGuardRoute>
          <AccessGuardRoute exact path={ROUTES.DONE}>
            <Done
              next={() =>
                navigate(
                  accountSituationState?.returnUrl ??
                    getNavLink(OVERVIEW_PAGE_URL)
                )
              }
            />
          </AccessGuardRoute>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const AccountSituationStory: React.VFC = () => {
  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  return (
    <ReviewAccountContextProvider>
      <AccountContextProvider>
        <EligibilityContextProvider>
          <AccountSituationContextProvider>
            <AccountSituationStoryInstance />
          </AccountSituationContextProvider>
        </EligibilityContextProvider>
      </AccountContextProvider>
    </ReviewAccountContextProvider>
  );
};
