import React, { useContext, useState, useRef, useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import {
  Card,
  OtpInput,
  RequiredValidator,
  OtpValidator,
  Button,
  Form,
  LysaFormRef,
  TotpQrcode,
  Snackbar,
  SNACKBAR_TYPES,
  useFlash,
} from "@lysaab/ui-2";
import { dataLogin } from "../../data/dataLogin";
import "./ChangeTwoFaPage.scss";
import { UserContext } from "../../context/UserContext";

const messages = defineMessages({
  totpError: {
    id: "change_twofa.totp.error",
    defaultMessage: "Invalid TOTP code",
  },
  totpInputLabel: {
    id: "change_twofa.totp.input.label",
    defaultMessage: "Code input",
  },
  totpRequired: {
    id: "change_twofa.totp.required",
    description: "Required verification message",
    defaultMessage: "Code is required",
  },
  totpInvalid: {
    id: "change_twofa.totp.invalid",
    description: "Message to show when code is invalid",
    defaultMessage: "You must enter a valid code",
  },
  linkDescription: {
    id: "change_twofa.totp.linkDescription",
  },
  manualSetupDescription: {
    id: "change_twofa.totp.manualSetupDescription",
  },
  errorFinalize: {
    id: "change_twofa.totp.error.finalize",
    defaultMessage:
      "We could not finalize your 2fa setup. Please start over by reloading the page.",
  },
  changeTwoFaSuccess: {
    id: "change_twofa.totp.success",
    defaultMessage: "Two-factor authentication successfully setup",
  },
});

export const TwoFaAppSection: React.VFC = () => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [secret, setSecret] = useState("");
  const [signature, setSignature] = useState("");
  const formRef = useRef<LysaFormRef>();
  const pushFlash = useFlash();

  useEffect(() => {
    dataLogin.changeTotp().then((resp) => {
      setSecret(resp.secret);
      setSignature(resp.signature);
    });
  }, []);

  const next = () => {
    if (!formRef.current?.isValid) {
      return;
    }

    dataLogin
      .changeTotpConfirm({ code, secret, signature })
      .then((resp) => {
        dataLogin
          .changeTotpFinalize({ signedTotpSecret: resp.signedSecret })
          .then(() => {
            pushFlash({
              text: intl.formatMessage(messages.changeTwoFaSuccess),
              type: SNACKBAR_TYPES.SUCCESS,
            });
          })
          .catch((e) => {
            pushFlash({
              text: intl.formatMessage(messages.errorFinalize),
              type: SNACKBAR_TYPES.ERROR,
              timer: 3000,
            });

            console.error(e);
          })
          .finally(() => {
            setCode("");
          });
      })
      .catch(() => {
        setError(intl.formatMessage(messages.totpError));
      });
  };

  return (
    <div className="app-section">
      <Card>
        <h2>
          <FormattedMessage
            id="change_twofa.totp.setup.header"
            defaultMessage="Set up One-time Password on Your Device"
          />
        </h2>
        <p>
          <FormattedMessage
            id="change_twofa.totp.setup.desc"
            defaultMessage="Scan the QR-code below with your phone to continue"
          />
        </p>
        <TotpQrcode
          linkDescription={intl.formatMessage(messages.linkDescription)}
          manualSetupDescription={intl.formatMessage(
            messages.manualSetupDescription
          )}
          tin={userContext.state.tin || ""}
          secret={secret}
        />
      </Card>
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            next();
          }
        }}
      >
        <Card>
          <h2>
            <FormattedMessage
              id="change_twofa.verification.header"
              description="Header for verification section"
              defaultMessage="Verify your TOTP code"
            />
          </h2>
          <p>
            <FormattedMessage
              id="change_twofa.verification.info"
              description="Info for verification section"
              defaultMessage="Once you've set up your authentication app it will generate a code for you. Please input that code below."
            />
          </p>
          <OtpInput
            value={code}
            label={intl.formatMessage(messages.totpInputLabel)}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.totpRequired)),
              new OtpValidator(intl.formatMessage(messages.totpInvalid)),
            ]}
            onChange={(totp) => {
              setCode(totp);
              setError("");
            }}
          />

          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              {error}
            </Snackbar>
          )}
        </Card>
        <Button
          block
          type="submit"
          label={
            <FormattedMessage id="change_twofa.save" defaultMessage="Save" />
          }
        />
      </Form>
    </div>
  );
};
