import React from "react";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../components/TranslatedText";
import { Page, PageHeader } from "../Page";
import { InviteInformation } from "./InviteInformation";

export const INVITE_PAGE_URL = "/invite";

export const InvitePage = () => {
  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="invite.header" />
        </h1>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={7}>
          <InviteInformation />
        </GridCol>
      </GridRow>
    </Page>
  );
};
