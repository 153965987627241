import React, {
  FunctionComponent,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  LysaFormRef,
  Form,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { Length } from "./HorizonLength";
import { Probability } from "./HorizonProbability";
import {
  defaultKFState,
  KFAccountContext,
  MIN_RECOMMENDED_ALLOCATION,
} from "../KFAccountContext";
import { Link, useHistory } from "react-router-dom";
import { SavingsHorizonLength } from "../../../../../../data/dataInvestments";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";

export const ROUTE = "/horizon";
interface Props {
  investmentTypeSelectionRoute: string;
}

export const Horizon: FunctionComponent<Props> = ({
  investmentTypeSelectionRoute,
}) => {
  const formRef = useRef<LysaFormRef>();
  const kfContext = useContext(KFAccountContext);
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);

  const submit = useCallback(() => {
    setError(false);

    if (!formRef.current?.isValid) {
      return;
    }
    history.push(investmentTypeSelectionRoute);
  }, [investmentTypeSelectionRoute, history]);

  return (
    <div className="create-account-corporation-horizon">
      <Form lysaFormRef={formRef} onSubmit={submit}>
        <div>
          <h1>Din investeringhorisont</h1>

          <section>
            <Length />
            <Probability />
          </section>

          <div className="relative">
            {kfContext.state.savingsHorizon === SavingsHorizonLength.SHORT ? (
              <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
                <div>
                  <b>
                    Avrådan på grund av Lysas roll som försäkringsförmedlare:
                  </b>{" "}
                  eftersom kapitalförsäkring är en schablonbeskattad sparform
                  bör spararen ha en investering som över tid kan förväntas
                  leverera en avkastning som innebär att beskattningsformens
                  fördel tillgodogörs. Din investeringhorisont innebär att du
                  sannolikt kommer få ett aktieinnehav som understiger{" "}
                  <b>{MIN_RECOMMENDED_ALLOCATION} %</b>. En värdepappersdepå kan
                  därför vara mer fördelaktig än en kapitalförsäkring.
                  <br />
                  <br />
                  <b>
                    <Link
                      to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}
                      onClick={() => {
                        kfContext.setState(defaultKFState);
                      }}
                    >
                      Öppna värdepappersdepå
                    </Link>
                  </b>
                </div>
              </Snackbar>
            ) : null}

            {error ? (
              <Snackbar icon={true} type={SNACKBAR_TYPES.ERROR}>
                <div>
                  <b>Ajdå!</b> Något gick fel vi kunde inte spara uppgifterna.
                </div>
              </Snackbar>
            ) : null}

            <Button
              block
              type="submit"
              label={error ? "Försök igen" : "Nästa"}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
