// If you expect a yearly yield of 12% the monthly yield is *not* 12/12=1%.
// Adding 1% each month would make the total yield too great because you're
// adding the 1% on top of the 1% from last month. To get the correct monthly
// yield you can use the compound interest formula "in reverse".
// https://en.wikipedia.org/wiki/Compound_interest
// A = P(1 + r/n)^(n*t)
// Given a yearly yield of x (where x is a percentage expressed as a decimal,
// like 0.07 for 7%), an initial amount of 1 and yield payments once per month
// for one year (12 months) we get
// 1 + x = 1(1 + r/1)^(1*12) => 1 + x = (1 + r)^12
// => (1 + x)^(1/12) = 1 + r => r = (1 + x)^(1/12) - 1

import { LysaCountry } from "@lysaab/countries";
import { DateTime } from "luxon";

/**
 * @param yearlyPercentage expressed as a percentage, like 7 (7%, 0.07)
 * @return average monthly yield, expressed as a decimal, like 0.005 (0.5%)
 */
export function yearlyToMonthly(yearlyPercentage: number) {
  return Math.pow(1 + yearlyPercentage / 100, 1 / 12) - 1;
}

//                          J   F   M   A   M   J   J   A   S   O   N   D
export const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

interface MonthlyFeeArgs {
  initialValue: number;
  yearlyFundFeePercentage: number;
  yearlyTransactionFeePercentage: number;
  yearlyLysaFundManagementFeePercentage: number;
  days: number;
  country: LysaCountry;
  daysWithDiscount?: number;
  customerAum?: number;
}

/**
 * The fee compounds over the month
 *
 * I tried breaking this up into separate functions for the different fees,
 * but mathematically that doesn't work. If you don't deduct all fees every
 * day you will end up with a too big fee. So, if you just calculate the
 * transaction fee for example, on the second day you have too much left of
 * the initial value and the fee becomes too big if you don't also deduct
 * all other fees for the first day.
 *
 * @param baseYearlyFee Total fee without the discretionary fee, expressed as a
 *   percentage, like 0.3, for 0.3%
 * @returns fee cost (for example in SEK or EUR)
 */
export function monthlyFee({
  initialValue,
  yearlyFundFeePercentage,
  yearlyTransactionFeePercentage,
  yearlyLysaFundManagementFeePercentage,
  days,
  country,
  daysWithDiscount = 0,
  customerAum = 0,
}: MonthlyFeeArgs) {
  if (days <= 0) {
    return {
      fundFee: 0,
      transactionFee: 0,
      lysaFundManagementFee: 0,
      discretionaryFee: 0,
    };
  }

  // fee/365 isn't mathematically correct, but it's how BE does it, so we do the
  // same here
  const dailyFf = yearlyFundFeePercentage / 365;
  const dailyTf = yearlyTransactionFeePercentage / 365;
  const dailyLfmf = yearlyLysaFundManagementFeePercentage / 365;
  const dailyDf = getDiscretionaryDailyFeePercentage(customerAum, country);
  const dailyDdf = getDiscountedDiscretionaryDailyFeePercentage(
    customerAum,
    country
  );

  // daysWithDiscount can be negative. We should just treat that as 0 days with
  // discount. It can also be something big, like 92. We should treat that as
  // "full month"
  const daysWithDiscountInMonth = Math.min(Math.max(0, daysWithDiscount), days);
  const regularDaysInMonth = days - daysWithDiscountInMonth;

  const discountedDailyFeePercentage = dailyFf + dailyTf + dailyLfmf + dailyDdf;
  const discountedDaysFee =
    initialValue -
    initialValue *
      Math.pow(1 - discountedDailyFeePercentage / 100, daysWithDiscountInMonth);

  const dailyFeePercentage = dailyFf + dailyTf + dailyLfmf + dailyDf;
  const regularDaysFee =
    initialValue -
    initialValue * Math.pow(1 - dailyFeePercentage / 100, regularDaysInMonth);

  const totalMonthlyFee = discountedDaysFee + regularDaysFee;

  const avgDailyDf =
    (dailyDf / days) * regularDaysInMonth +
    (dailyDdf / days) * daysWithDiscountInMonth;
  const totAvgDailyFee = dailyFf + dailyTf + dailyLfmf + avgDailyDf;

  if (totAvgDailyFee === 0) {
    return {
      fundFee: 0,
      transactionFee: 0,
      lysaFundManagementFee: 0,
      discretionaryFee: 0,
    };
  }

  const ffPart = dailyFf / totAvgDailyFee;
  const tfPart = dailyTf / totAvgDailyFee;
  const lfmfPart = dailyLfmf / totAvgDailyFee;
  const dfPart = avgDailyDf / totAvgDailyFee;

  return {
    fundFee: ffPart * totalMonthlyFee,
    transactionFee: tfPart * totalMonthlyFee,
    lysaFundManagementFee: lfmfPart * totalMonthlyFee,
    discretionaryFee: dfPart * totalMonthlyFee,
  };
}

// Values and limits from BE
// /fee-storage/src/main/java/se/lysa/app/fee/service/FeeCalculator.java
const feeLimitsSekDkk = [
  {
    limit: 200_000,
    fee: 0.12,
  },
  {
    limit: 500_000,
    fee: 0.11,
  },
  {
    limit: 1_000_000,
    fee: 0.1,
  },
  {
    limit: 5_000_000,
    fee: 0.09,
  },
  {
    limit: 30_000_000,
    fee: 0.08,
  },
  {
    limit: Number.MAX_VALUE,
    fee: 0.03,
  },
];
const feeLimitsEur = [
  {
    limit: 20_000,
    fee: 0.12,
  },
  {
    limit: 50_000,
    fee: 0.11,
  },
  {
    limit: 100_000,
    fee: 0.1,
  },
  {
    limit: 500_000,
    fee: 0.09,
  },
  {
    limit: 3_000_000,
    fee: 0.08,
  },
  {
    limit: Number.MAX_VALUE,
    fee: 0.03,
  },
];

const discountedFeeLimitsSekDkk = [
  {
    limit: 30_000_000,
    fee: 0.07,
  },
  {
    limit: Number.MAX_VALUE,
    fee: 0.02,
  },
];

const discountedFeeLimitsEur = [
  {
    limit: 3_000_000,
    fee: 0.07,
  },
  {
    limit: Number.MAX_VALUE,
    fee: 0.02,
  },
];

const feeLimits: Record<LysaCountry, Array<{ limit: number; fee: number }>> = {
  [LysaCountry.DENMARK]: feeLimitsSekDkk,
  [LysaCountry.FINLAND]: feeLimitsEur,
  [LysaCountry.GERMANY]: feeLimitsEur,
  [LysaCountry.SPAIN]: feeLimitsEur,
  [LysaCountry.SWEDEN]: feeLimitsSekDkk,
};

const discountedFeeLimits: Record<
  LysaCountry,
  Array<{ limit: number; fee: number }>
> = {
  [LysaCountry.DENMARK]: discountedFeeLimitsSekDkk,
  [LysaCountry.FINLAND]: discountedFeeLimitsEur,
  [LysaCountry.GERMANY]: discountedFeeLimitsEur,
  [LysaCountry.SPAIN]: discountedFeeLimitsEur,
  [LysaCountry.SWEDEN]: discountedFeeLimitsSekDkk,
};

/**
 * @param customerAum total amount customer has invested with Lysa
 * @param discounted Should use discounted fee
 * @returns
 */
export function getDiscretionaryDailyFeePercentage(
  customerAum: number,
  country: LysaCountry,
  discounted?: boolean
) {
  const limits = discounted ? discountedFeeLimits[country] : feeLimits[country];
  const fee = limits.find(({ limit }) => customerAum < limit)?.fee;

  if (typeof fee === "undefined") {
    throw new Error("Could not find the correct fee to use");
  }

  return fee / 365;
}

export function getDiscountedDiscretionaryDailyFeePercentage(
  customerAum: number,
  country: LysaCountry
) {
  return getDiscretionaryDailyFeePercentage(customerAum, country, true);
}

interface SimulationArgs {
  initialInvestment: number;
  monthlyInvestment: number;
  years: number;
  expectedYearlyYield: number;
  yearlyFundFeePercentage: number;
  yearlyTransactionFeePercentage: number;
  yearlyLysaFundManagementFeePercentage: number;
  country: LysaCountry;
  customerAum?: number;
  discountExpiry?: Date;
}

/**
 * All calculations are done on a monthly basis. So for three years we really
 * calculate everything for 36 months
 *
 * The general idea is to take the expected yearly yield and convert that to a
 * monthly yield. Then take the yearly fee and convert that to a daily fee.
 * Every month we calculate the total fee for the month, deduct that, then add
 * the monthly yield.
 *
 * The fee is adjusted according to the customer's aum, and is continuously
 * adjusted as the value of the account increases
 *
 * One final adjustment is made to the fee to take into consideration any
 * discount the user might have
 *
 * @param expectedYearlyYield expressed as a percentage, like 7, for 7%
 * @param baseYearlyFee expressed as a percentage, like 0.3, for 0.3%.
 *   Only used for tests. If you don't supply this the default/standard fees
 *   will be used
 * @param customerTotalAmount total amount customer has invested with Lysa.
 *   This affects the fee (unless one is supplied, in which case the supplied
 *   fee will always be used)
 */
export function simulateFutureWorthAndFee({
  initialInvestment,
  monthlyInvestment,
  years,
  expectedYearlyYield,
  yearlyFundFeePercentage,
  yearlyTransactionFeePercentage,
  yearlyLysaFundManagementFeePercentage,
  country,
  customerAum = 0,
  discountExpiry,
}: SimulationArgs) {
  const averageMonthlyYield = yearlyToMonthly(expectedYearlyYield);
  const date = DateTime.now();
  let daysWithDiscount = discountExpiry
    ? date.diff(DateTime.fromJSDate(discountExpiry), "days").days
    : 0;

  // daysWithDiscount shouldn't be negative, but you never know, so let's
  // protect ourselves against that just to be sure
  // Dividing by 30 isn't 100% correct. But I'm making a call that it's good
  // enough for our purposes
  const monthsWithDiscount = Math.max(0, Math.round(daysWithDiscount / 30));

  let totalFee = 0;
  let totalFees = {
    fundFee: 0,
    transactionFee: 0,
    lysaFundManagementFee: 0,
    discretionaryFee: 0,
  };

  // When calculating the value, deduct the fee first. Then add yield, and
  // finally any monthly investments

  const monthlyValues = [initialInvestment];

  for (let i = 0; i < years * 12; i++) {
    if (i > 0) {
      monthlyValues[i] = monthlyValues[i - 1];
    }

    const fee = monthlyFee({
      initialValue: monthlyValues[i],
      yearlyFundFeePercentage,
      yearlyTransactionFeePercentage,
      yearlyLysaFundManagementFeePercentage,
      days: daysInMonth[i % 12],
      country,
      daysWithDiscount,
      customerAum: customerAum + monthlyValues[i],
    });

    const totalMonthlyFee =
      fee.fundFee +
      fee.transactionFee +
      fee.lysaFundManagementFee +
      fee.discretionaryFee;

    totalFee += totalMonthlyFee;
    totalFees.fundFee += fee.fundFee;
    totalFees.transactionFee += fee.transactionFee;
    totalFees.lysaFundManagementFee += fee.lysaFundManagementFee;
    totalFees.discretionaryFee += fee.discretionaryFee;

    // Deduct the fee
    monthlyValues[i] -= totalMonthlyFee;
    // Add monthly yield
    monthlyValues[i] += monthlyValues[i] * averageMonthlyYield;
    // Add monthly investment
    monthlyValues[i] += monthlyInvestment;

    daysWithDiscount -= daysInMonth[i % 12];
  }

  const endWorth = monthlyValues[monthlyValues.length - 1];

  if (endWorth === undefined) {
    throw new Error("Could not calculate an end worth");
  }

  // If the fee changes over time, it'll get lower and lower. So the lowest fee
  // is at the end. Unless the user starts off with a discount, then the
  // discount may make it even lower
  let minFee =
    getDiscretionaryDailyFeePercentage(customerAum + endWorth, country) * 365;
  const maxLysaFee =
    getDiscretionaryDailyFeePercentage(
      customerAum + monthlyValues[monthsWithDiscount],
      country
    ) * 365;
  if (discountExpiry) {
    const discountFee =
      getDiscountedDiscretionaryDailyFeePercentage(
        customerAum + monthlyValues[0],
        country
      ) * 365;
    minFee = Math.min(minFee, discountFee);
  }

  return {
    worth: endWorth,
    fee: totalFee,
    totalFees,
    minLysaFee: minFee,
    maxLysaFee,
  };
}

// Backend gives us
// estimatedFees = {
//   future: {
//     discretionary: number;
//     fundManagement: number;
//     fundAssets: number;
//     transactionFees: number;
//     insurancePremium?: number;
//     total: number;
//   },
//   cost: {
//     discretionary: number;
//     fundManagement: number;
//     fundAssets: number;
//     transactionFees: number;
//     insurancePremium?: number;
//     total: number;
//   },
//   rebate: number,
//   rebaseExpiry: string
// }
//
//
// {
//   "future": {
//     "discretionary": 1.2,
//     "fundManagement": 1.2,
//     "fundAssets": 1.01,
//     "transactionFees": 0.08,
//     "total": 3.49
//   },
//   "cost": {
//     "discretionary": 0.08,
//     "fundManagement": 0.12,
//     "fundAssets": 0.101,
//     "transactionFees": 0.008,
//     "total": 0.309
//   }
// }
//
// {
//   "future": {
//     "discretionary": 12,
//     "fundManagement": 12,
//     "fundAssets": 9.98,
//     "transactionFees": 0.83,
//     "total": 34.81
//   },
//   "cost": {
//     "discretionary": 0.02,
//     "fundManagement": 0.12,
//     "fundAssets": 0.1,
//     "transactionFees": 0.008,
//     "total": 0.248
//   },
//   "rebateExpiry": "2023-09-02",
//   "rebate": 7
// }
//
// {
//   "future": {
//     "discretionary": 12,
//     "fundManagement": 12,
//     "fundAssets": 9.92,
//     "transactionFees": 0.82,
//     "total": 34.74
//   },
//   "cost": {
//     "discretionary": 0.02,
//     "fundManagement": 0.12,
//     "fundAssets": 0.099,
//     "transactionFees": 0.008,
//     "total": 0.247
//   },
//   "rebateExpiry": "2023-09-02",
//   "rebate": 7
// }
