import React, { useContext, VoidFunctionComponent } from "react";
import { useIntl, defineMessages } from "react-intl";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { WithdrawalInterval } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";
import { UserContext } from "../../../context/UserContext";

export const messages = defineMessages({
  header: {
    id: "kyc.withdrawalinterval.header",
  },
  required: {
    id: "kyc.withdrawalinterval.required",
  },
});

interface MessageWithId {
  id: string;
}

const alternativeMessages = defineMessages<
  WithdrawalInterval,
  MessageWithId,
  Record<WithdrawalInterval, MessageWithId>
>({
  [WithdrawalInterval.MONTHLY]: {
    id: "kyc.withdrawalinterval.alt.MONTHLY",
  },
  [WithdrawalInterval.MULTIPLE_YEARLY]: {
    id: "kyc.withdrawalinterval.alt.MULTIPLE_YEARLY",
  },
  [WithdrawalInterval.ONCE_YEARLY]: {
    id: "kyc.withdrawalinterval.alt.ONCE_YEARLY",
  },
  [WithdrawalInterval.SELDOM]: {
    id: "kyc.withdrawalinterval.alt.SELDOM",
  },
});

interface Props {
  value?: WithdrawalInterval;
  setValue: (depositInterval: WithdrawalInterval) => void;
  display?: boolean;
}

export const WithdrawalIntervalQuestion: VoidFunctionComponent<Props> = ({
  value,
  setValue,
  display,
}) => {
  const intl = useIntl();
  const {
    state: { legalEntityType },
  } = useContext(UserContext);

  const alternatives = Object.entries(alternativeMessages).map(
    ([withdrawalInterval, message]) => {
      return {
        text: intl.formatMessage(message),
        value: withdrawalInterval,
      } as Alternative<WithdrawalInterval>;
    }
  );
  const valueAlternative = alternatives.find((alt) => alt.value === value);

  if (display) {
    return (
      <DataDisplay
        hasContainer
        type="input"
        title={intl.formatMessage(messages.header, { legalEntityType })}
        text={valueAlternative?.text}
      />
    );
  }

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        value={valueAlternative}
        header={intl.formatMessage(messages.header, { legalEntityType })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        onChange={(withdrawalInterval) => setValue(withdrawalInterval.value)}
      />
    </Card>
  );
};
