import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Qrcode from "qrcode.react";
import { Card, Spinner } from "@lysaab/ui-2";
import { dataBankid, OrderRef } from "../../../../data/dataBankid";
import { useInterval } from "../../../../hooks/useInterval";
import "./BankIdLogin.scss";

interface Props {
  orderRef: OrderRef;
  timoutCallback: () => void;
}

const QR_TIMER = 1000;
const QR_MAX_ITERATIONS = 35;

export const QrCode: FunctionComponent<Props> = ({
  orderRef,
  timoutCallback,
}) => {
  const [qrCode, setQrCode] = useState<string>();
  const maxIterations = useRef(QR_MAX_ITERATIONS);

  const getQrCode = useCallback(() => {
    dataBankid
      .qrCode(orderRef)
      .then((response) => {
        setQrCode(response.code);
      })
      .catch(() => {
        maxIterations.current = 0;
        timoutCallback();
      });
  }, [orderRef, timoutCallback]);

  // Get initial QR code
  useEffect(() => {
    getQrCode();
  }, [orderRef, getQrCode]);

  // Get a new QR code every QR_TIMER
  useInterval(
    () => {
      if (maxIterations.current <= 0) {
        if (maxIterations.current === 0) {
          timoutCallback();
        }
        maxIterations.current--;
      } else {
        getQrCode();
        maxIterations.current--;
      }
    },
    maxIterations.current > -1 ? QR_TIMER : null
  );

  return (
    <Card className="bank-id-qr-wrapper">
      <div className="qr-wrapper">
        {qrCode ? (
          <Qrcode size={172} includeMargin={true} value={qrCode} />
        ) : (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
      </div>
    </Card>
  );
};
