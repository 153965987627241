import { Card, Button } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { UserContext } from "../../../../../../../../context/UserContext";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../../../../data/dataCustomerTracking";
import { LegalEntityType } from "../../../../../../../../data/dataLogin";
import { getNavLink } from "../../../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../../../../CreateAccountStory";

import "./InvestmentAccountCard.scss";

export const InvestmentAccountCard = () => {
  const userContext = useContext(UserContext);
  const isPerson = userContext.state.legalEntityType === LegalEntityType.PERSON;
  return (
    <Card className="investment-account-card">
      <h2>
        {isPerson ? (
          <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createISKHeader" />
        ) : (
          <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createCorporateVpHeader" />
        )}
      </h2>
      <p>
        {isPerson ? (
          <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createISKIntro" />
        ) : (
          <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createCorporateVpIntro" />
        )}
      </p>
      <Button
        component={Link}
        to={getNavLink(CREATE_ACCOUNT_URL)}
        onClick={() =>
          dataCustomerTrackingService.postEvent({
            eventName: "clickedOpenInvestmentAccountButton",
            domain: FeatureDomain.SAVINGS_ACCOUNT,
            subDomain: SubDomain.CREATE_ACCOUNT,
          })
        }
        block
        label={
          isPerson ? (
            <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createISKButton" />
          ) : (
            <TranslatedText id="sweden.createAccountIntroStory.investmentAccountCard.createCorporateVpButton" />
          )
        }
      />
    </Card>
  );
};
