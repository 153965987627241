import { FailedHintCode, PendingHintCode } from "@lysaab/ui-2";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  header: {
    id: "sweden.userManagement.header.title",
  },
  editEmail: {
    id: "sweden.userManagement.edit.email",
  },
  editEmailPlaceholder: {
    id: "sweden.userManagement.edit.email.placeholder",
  },
  editEmailErrorRequired: {
    id: "sweden.userManagement.edit.email.error.required",
  },
  editEmailErrorInvalid: {
    id: "sweden.userManagement.edit.email.error.invalid",
  },
  editName: {
    id: "sweden.userManagement.edit.name",
  },
  editTin: {
    id: "sweden.userManagement.edit.tin",
  },
  editTinPlaceholder: {
    id: "sweden.userManagement.edit.tin.placeholder",
  },
  editTinErrorRequired: {
    id: "sweden.userManagement.edit.tin.error.required",
  },
  editTinErrorInvalid: {
    id: "sweden.userManagement.edit.tin.error.invalid",
  },
  editIsAdmin: {
    id: "sweden.userManagement.edit.isAdmin",
  },
  editIsAdminYes: {
    id: "sweden.userManagement.edit.isAdmin.yes",
  },
  editIsAdminNo: {
    id: "sweden.userManagement.edit.isAdmin.no",
  },

  signQrInfo1: {
    id: "sweden.userManagement.sign.bankid.qrInfo1",
  },
  signQrInfo2: {
    id: "sweden.userManagement.sign.bankid.qrInfo2",
  },
  signQrInfo3: {
    id: "sweden.userManagement.sign.bankid.qrInfo3",
  },
  signDivider: {
    id: "sweden.userManagement.sign.bankid.divider",
  },
  signButtonOpen: {
    id: "sweden.userManagement.sign.bankid.buttonOpen",
  },
  signButtonErrorHeader: {
    id: "sweden.userManagement.sign.bankid.buttonErrorHeader",
  },
  signButtonRetry: {
    id: "sweden.userManagement.sign.bankid.buttonRetry",
  },
  ariaProgressLabel: {
    id: "sweden.userManagement.story.ariaProgressLabel",
  },
});

export const signPendingMessages = defineMessages({
  [PendingHintCode.NOCLIENT]: {
    id: "sweden.userManagement.sign.hint.pending.noclient",
  },
  [PendingHintCode.OUTSTANDINGTRANSACTION]: {
    id: "sweden.userManagement.sign.hint.pending.outstandingtransaction",
  },
  [PendingHintCode.STARTED]: {
    id: "sweden.userManagement.sign.hint.pending.started",
  },
  [PendingHintCode.USERSIGN]: {
    id: "sweden.userManagement.sign.hint.pending.usersign",
  },
});

export const signFailedMessages = defineMessages({
  [FailedHintCode.CANCELLED]: {
    id: "sweden.userManagement.sign.hint.failed.cancelled",
  },
  [FailedHintCode.CERTIFICATEERR]: {
    id: "sweden.userManagement.sign.hint.failed.certificateerr",
  },
  [FailedHintCode.EXPIREDTRANSACTION]: {
    id: "sweden.userManagement.sign.hint.failed.expiredtransaction",
  },
  [FailedHintCode.STARTFAILED]: {
    id: "sweden.userManagement.sign.hint.failed.startfailed",
  },
  [FailedHintCode.USERCANCEL]: {
    id: "sweden.userManagement.sign.hint.failed.usercancel",
  },
});
