import React, { VoidFunctionComponent } from "react";
import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

interface Props {
  next: () => void;
}

export const Done: VoidFunctionComponent<Props> = ({ next }) => {
  return (
    <>
      <h2>
        <FormattedMessage id="accountAllocation.done.title" />
      </h2>

      <p>
        <FormattedMessage id="accountAllocation.done.description" />
      </p>

      <Button
        block
        onClick={next}
        label={<FormattedMessage id="accountAllocation.done.button.navigate" />}
      />
    </>
  );
};
