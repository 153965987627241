import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import cx from "classnames";
import { KFAccountContext } from "../KFAccountContext";
import {
  dataCreateAccount,
  KFAccountCreationState,
  KFSigningStatusPendingResponse,
} from "../../../../../../data/dataCreateAccount";
import {
  Card,
  Spinner,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  EmailInput,
  EmailValidator,
  RequiredValidator,
  LysaFormRef,
  Form,
} from "@lysaab/ui-2";
import { useHistory, useRouteMatch } from "react-router";
import { Signers } from "./Signers";
import { Status, Retry } from "../../../../../../components/retry/Retry";
import "./FuturSummary.scss";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";
import { OVERVIEW_PAGE_URL } from "../../../../../../pages/overview/OverviewPage";
import { NavLink } from "react-router-dom";

interface Props {
  futurSignRoute: string;
  doneRoute: string;
}

interface Params {
  signingId: string;
}

const TIMER = 3000;

function copyToClipboard(str: string) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selection = document.getSelection();
  if (!selection) {
    return false;
  }

  const selected = selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    selection.removeAllRanges();
    selection.addRange(selected);
  }

  return true;
}

export const FuturSummary: React.FC<Props> = ({
  futurSignRoute,
  doneRoute,
}) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [copySuccess, setCopySuccess] = useState<boolean | undefined>();
  const [emailSuccess, setEmailSuccess] = useState<boolean | undefined>();
  const [email, setEmail] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const history = useHistory();
  const timer = useRef<number | undefined>();
  const formRef = useRef<LysaFormRef>();
  const { params } = useRouteMatch<Params>();
  const { signingId } = params;
  const isPending =
    kfState.signingStatus?.accountCreationState ===
    KFAccountCreationState.SIGNING_PENDING;

  const pollStatus = useCallback(
    (activeSigningId) => {
      dataCreateAccount
        .getKFSigningStatus(activeSigningId)
        .then((data) => {
          if (
            data.accountCreationState ===
              KFAccountCreationState.SIGNING_PENDING ||
            data.accountCreationState ===
              KFAccountCreationState.INSURANCE_APPLICATION_PENDING ||
            data.accountCreationState ===
              KFAccountCreationState.ACCOUNT_CREATION_PENDING
          ) {
            timer.current = window.setTimeout(() => {
              pollStatus(activeSigningId);
            }, TIMER);
          } else if (
            data.accountCreationState === KFAccountCreationState.ACCOUNT_CREATED
          ) {
            history.push(doneRoute);
          }

          setKFState({
            signingStatus: data,
          });
        })
        .catch(() => {
          // Just retry
          timer.current = window.setTimeout(() => {
            pollStatus(activeSigningId);
          }, TIMER);
        });
    },
    [setKFState, history, doneRoute]
  );

  useEffect(() => {
    pollStatus(signingId);

    return () => {
      clearTimeout(timer.current);
    };
  }, [pollStatus, signingId]);

  const copyLink = useCallback(() => {
    if (copyToClipboard(window.location.href)) {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 2000);
    } else {
      setCopySuccess(false);
      setTimeout(() => {
        setCopySuccess(undefined);
      }, 5000);
    }
  }, []);

  const load = useCallback(() => {
    dataCreateAccount.getKFPresaleInfo().then((presaleInfo) => {
      setKFState({
        presaleInfo,
      });
      setStatus(Status.SUCCESS);
    });
  }, [setKFState]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => load, 1000);
    return () => clearTimeout(timer.current);
  }, [load]);

  useEffect(load, [load]);

  let body = null;
  let applicationPending = false;

  if (kfState.signingStatus) {
    const { accountCreationState } = kfState.signingStatus;

    if (
      accountCreationState ===
      KFAccountCreationState.INSURANCE_APPLICATION_PENDING
    ) {
      applicationPending = true;
      body = (
        <Card>
          <h4>Validerar uppgifter</h4>
          <Spinner />
        </Card>
      );
    } else if (
      accountCreationState ===
      KFAccountCreationState.INSURANCE_APPLICATION_PENDING_MANUAL_VALIDATION
    ) {
      body = (
        <Card>
          <h4>Manuell hantering</h4>
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <div>
              <b>Futur Pension</b> kommer att manuellt behöva granska
              uppgifterna. Det kan ta upp till två bankdagar. Därefter skickar
              vi dig ett email med en länk för att slutföra registeringen.
              <p>
                <b>
                  <NavLink to={getNavLink(OVERVIEW_PAGE_URL)}>
                    Till översikten
                  </NavLink>
                </b>
              </p>
            </div>
          </Snackbar>
        </Card>
      );
    } else if (
      accountCreationState === KFAccountCreationState.SIGNING_PENDING
    ) {
      body = (
        <Card>
          <h4>Signera avtal med Mobilt BankID</h4>
          <p className="signing-group-description">
            En eller flera firmatecknare behöver signera avtalet
          </p>
          <Signers
            signingId={signingId}
            futurSignRoute={futurSignRoute}
            signingStatusPendingResponse={
              kfState.signingStatus as KFSigningStatusPendingResponse
            }
          />
        </Card>
      );
    } else if (
      accountCreationState ===
        KFAccountCreationState.ACCOUNT_CREATION_PENDING ||
      accountCreationState === KFAccountCreationState.ACCOUNT_CREATED
    ) {
      body = (
        <Card>
          <h4>Skapar konto...</h4>
          <Spinner />
        </Card>
      );
    } else if (accountCreationState === KFAccountCreationState.REJECTED) {
      body = (
        <Card>
          <h4>Kontot kunde inte skapas</h4>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <div>
              <b>Futur Pension</b> eller någon av signatärerna har nekat
              kontoskapandet. För mer information, vänligen kontakta oss genom
              att skicka ett meddelande i inloggat läge eller ring{" "}
              <a href="tel:010 551 32 30">010 551 32 30</a>.
            </div>
          </Snackbar>
        </Card>
      );
    } else if (accountCreationState === KFAccountCreationState.ERROR) {
      body = (
        <Card>
          <h4>Något gick fel!</h4>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <div>
              Futur Pension kunde inte skapa kontot. Vänligen kontakta oss på{" "}
              <a href="tel:010 551 32 30">010 551 32 30</a> eller{" "}
              <a href="mailto:kontakt@lysa.se">kontakt@lysa.se</a> så undersöker
              vi vidare.
            </div>
          </Snackbar>
        </Card>
      );
    } else if (accountCreationState === KFAccountCreationState.EXPIRED) {
      body = (
        <Card>
          <h4>Registreringen har förfallit</h4>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <div>
              <p>
                Tiden för att skapa en kapitalförsäkringen har gått ut. Vi
                städar bort förfallna registeringar för att enbart ha kompletta
                sådana.
              </p>
              <p>Du behöver därför registra kontot igen. Sorry.</p>

              <Button
                size="small"
                block
                onClick={() => {
                  window.location.href = getNavLink(
                    CREATE_ACCOUNT_CORPORATION_PAGE_URL
                  );
                }}
                label="Börja om"
              />
            </div>
          </Snackbar>
        </Card>
      );
    }
  } else {
    body = (
      <Card>
        <h4>Validerar uppgifter</h4>
        <Spinner />
      </Card>
    );
  }

  return (
    <div className="create-account-corporation-futur-summary">
      <h1>Signering</h1>

      <Retry retry={retry} status={status}>
        <div
          className={cx("futur-preinfo", {
            "show-receipt": !applicationPending,
          })}
        >
          <p>
            Genom att signera bekräftar jag att de uppgifter jag har lämnat är
            korrekta och fullständiga samt att jag har tagit del av
            informationen i nedanstående dokument och accepterar avtalsvillkoren
            enligt nedan samt att jag förbinder dig att följa dessa.
          </p>

          <ul>
            <li className="application-receipt">
              <a
                href={`${process.env.REACT_APP_API}/danica/accounts/signing/${signingId}/receipt.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ansökan kapitalförsäkring
              </a>
            </li>
            {kfState.presaleInfo?.documents.map((doc) => (
              <li key={doc.documentId}>
                <a
                  href={`${process.env.REACT_APP_API}/danica/accounts/presale-info/${doc.documentId}/${doc.fileName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {doc.documentName}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {body}

        {(kfState.signingStatus as KFSigningStatusPendingResponse)
          ?.showShareByEmail ? (
          <div className="futur-summary-additional-contact">
            {isPending ? (
              <Card>
                <h4>Signaturer och identifiering</h4>
                <div className="relative">
                  {copySuccess === false ? (
                    <Snackbar type={SNACKBAR_TYPES.ERROR}>
                      <b>Ajdå!</b> Länken blev inte kopierad
                    </Snackbar>
                  ) : null}
                  {copySuccess === true ? (
                    <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                      Länken kopierades
                    </Snackbar>
                  ) : null}
                  <p>
                    För att öppna ett företagskonto måste vi ha signaturer av
                    företagets firmatecknare och verkliga huvudmän. Om det krävs
                    fler firmatecknare utöver dig själv, eller om du endast
                    administrerar kontot, kan du själv kopiera länken till den
                    här sidan och skicka till de firmatecknare och verkliga
                    huvudmän som ska signera. Du kan också skriva in
                    mailadressen här nedan så förmedlar vi länken.
                  </p>
                </div>

                <Button size="small" onClick={copyLink} label="Kopiera länk" />

                <Form
                  lysaFormRef={formRef}
                  onSubmit={(event) => {
                    event.preventDefault();

                    if (formRef.current?.isValid) {
                      dataCreateAccount
                        .shareKFByEmail(email, signingId || "")
                        .then(() => {
                          setEmailSuccess(true);
                          setTimeout(() => {
                            setEmailSuccess(undefined);
                          }, 2000);
                        })
                        .catch(() => {
                          setEmailSuccess(false);
                          setTimeout(() => {
                            setEmailSuccess(undefined);
                          }, 5000);
                        });
                    }
                  }}
                >
                  <div className="relative">
                    {emailSuccess === false ? (
                      <Snackbar type={SNACKBAR_TYPES.ERROR}>
                        <b>Ajdå!</b> Vi kunde inte skicka meddelandet.
                      </Snackbar>
                    ) : null}
                    {emailSuccess === true ? (
                      <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                        Länken skickades
                      </Snackbar>
                    ) : null}
                  </div>
                  <div className="email-wrapper">
                    <EmailInput
                      disabled={!isPending}
                      value={email}
                      label="Email"
                      placeholder=""
                      onChange={(event) => setEmail(event)}
                      validators={[
                        new EmailValidator("Ogiltig email"),
                        new RequiredValidator("Email måste fyllas i"),
                      ]}
                      forceValidation={false}
                      suggestionMessage={
                        "Det ser ut som att du kanske skrev in en felaktig " +
                        "emailadress. Menade du {suggestion}?"
                      }
                    />

                    <Button
                      size="small"
                      type="submit"
                      disabled={!isPending}
                      label="Maila"
                    />
                  </div>
                </Form>
              </Card>
            ) : null}
          </div>
        ) : null}
      </Retry>
    </div>
  );
};
