import {
  BankAccountNumberValidator,
  Form,
  LysaFormRef,
  TextInput,
  accountNumberInfo,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  Card,
  Button,
} from "@lysaab/ui-2";
import React, { useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { dataAutogiro } from "../../../../../../../data/dataAutogiro";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { AccountInformationInputHint } from "../../../../../components/AccountInformationInputHint/AccountInformationInputHint";
import { AutogiroConditions } from "../../../../../components/autogiroConditions/AutogiroConditions";
import {
  isBankSupported,
  UnsupportedBankInformation,
} from "../../../../../components/unsupportedBankInformation/UnsupportedBankInformation";
import { ExistingConsentValidator } from "../../../../../validators/ExistingConsentValidator";
import { ISK_TRANSFER_URL } from "../../../../iskTransfer/IskTransferStory";
import "./AccountInformation.scss";

const messages = defineMessages({
  accountLabel: {
    id: "sweden.deposits.add.autogiro.account-information.account.label",
  },
  accountValid: {
    id: "sweden.deposits.add.autogiro.account-information.account.valid",
  },
  accountRequired: {
    id: "sweden.deposits.add.autogiro.account-information.account.required",
  },
  accountExisting: {
    id: "sweden.deposits.add.autogiro.account-information.account.existing",
  },
  termsLabel: {
    id: "sweden.deposits.add.autogiro.account-information.terms.label",
  },
  termsRequired: {
    id: "sweden.deposits.add.autogiro.account-information.terms.required",
  },
});

interface Props {
  next: () => void;
}

export const AccountInformation: React.VFC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [accountNumber, setAccountNumber] = useState("");
  const parsedAccount = accountNumberInfo(accountNumber);

  return (
    <div className="account-information">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            formRef.current?.isValid &&
            parsedAccount &&
            isBankSupported(parsedAccount)
          ) {
            dataAutogiro.addAutogiro(accountNumber).then(next);
            // Don't catch, let ErrorBoundry handle it
          }
        }}
      >
        <h1>
          <FormattedMessage id="sweden.deposits.add.autogiro.account-information.header" />
        </h1>
        <Card>
          <p>
            <FormattedMessage id="sweden.deposits.add.autogiro.account-information.ingress" />
          </p>
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <FormattedMessage id="sweden.deposits.add.autogiro.account-information.transaction-account-warning" />
          </Snackbar>
          <TextInput
            label={intl.formatMessage(messages.accountLabel)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
              new BankAccountNumberValidator(
                intl.formatMessage(messages.accountValid)
              ),
              new ExistingConsentValidator(
                intl.formatMessage(messages.accountExisting)
              ),
            ]}
            value={accountNumber}
            onChange={setAccountNumber}
          />
          {!parsedAccount && (
            <AccountInformationInputHint value={accountNumber} />
          )}
          <dl>
            <div className="flex">
              <dt>
                <FormattedMessage id="sweden.deposits.add.autogiro.account-information.account.bank-label" />
                :
              </dt>
              <dd>{parsedAccount && parsedAccount.bank_name}</dd>
            </div>
            <div className="flex">
              <dt>
                <FormattedMessage id="sweden.deposits.add.autogiro.account-information.account.account-label" />
                :
              </dt>
              <dd>
                {parsedAccount && parsedAccount.clearing_number}{" "}
                {parsedAccount && parsedAccount.account_number}
              </dd>
            </div>
          </dl>

          {parsedAccount && (
            <UnsupportedBankInformation accountInformation={parsedAccount}>
              {(account) => {
                if (
                  account.bank_name === "Avanza Bank" ||
                  account.bank_name === "Nordnet Bank" ||
                  account.bank_name === "Erik Penser"
                ) {
                  return (
                    <div>
                      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                        <TranslatedText
                          id="sweden.deposits.add.autogiro.account-information.isk-fallback.text"
                          values={{ bankName: account.bank_name }}
                        />
                      </Snackbar>
                      <Button
                        block
                        variant="secondary"
                        component={Link}
                        to={getNavLink(ISK_TRANSFER_URL)}
                        className="isk-fallback-button"
                        label={
                          <TranslatedText id="sweden.deposits.add.autogiro.account-information.isk-fallback.link" />
                        }
                      />
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                        <TranslatedText id="sweden.deposits.add.autogiro.account-information.unsupported" />
                      </Snackbar>
                    </div>
                  );
                }
              }}
            </UnsupportedBankInformation>
          )}

          <AutogiroConditions showConsentConditions hideDescription />
        </Card>
        <Button
          type="submit"
          block
          label={
            <FormattedMessage id="sweden.deposits.add.autogiro.account-information.submit-button" />
          }
        />
      </Form>
    </div>
  );
};
