import { LysaCountry } from "@lysaab/countries";
import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  ContactPhoneNumbers,
  ContactEmailAddresses,
} from "../../../../../../../components/Contact";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../../context/LocalizationContext";
import { KF_WITHDRAWAL_STATUS } from "../../../../../../../data/dataDanica";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { WITHDRAWAL_PAGE_URL } from "../../../../../../../pages/withdrawal/overview/WithdrawalPage";
import { KfWithdrawalContext } from "../KfWithdrawalContext";

interface Props {
  status?: KF_WITHDRAWAL_STATUS;
}

export const Rejected: React.FunctionComponent<Props> = ({ status }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const { state: kfWithdrawalState } = useContext(KfWithdrawalContext);

  if (
    kfWithdrawalState.signingStatus?.status !== KF_WITHDRAWAL_STATUS.REJECTED
  ) {
    return null;
  }

  return (
    <Card>
      <h4>
        <TranslatedText
          id="withdrawalRequest.kf.signing.rejected.header"
          description="withdrawal request kf - status rejected header"
          defaultMessage="Rejected"
        />
      </h4>
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <div>
          <p>
            <TranslatedText
              id="withdrawalRequest.kf.signing.rejected.paragraph"
              description="withdrawal request kf - status rejected paragraph"
              defaultMessage="Futur Pension has rejected this witdrawal. You are welcome to contact us for more information on <phone></phone> or <email></email>"
              values={{
                phone: () => {
                  const phoneNumber = intl.formatMessage(
                    ContactPhoneNumbers[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
                },
                email: (...chunks: string[]) => {
                  const emailAddress = intl.formatMessage(
                    ContactEmailAddresses[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  );
                  return <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
                },
              }}
            />
          </p>
        </div>
      </Snackbar>

      <Button
        component={Link}
        to={getNavLink(WITHDRAWAL_PAGE_URL)}
        block
        label={
          <TranslatedText id="withdrawalRequest.kf.signing.rejected.button" />
        }
      />
    </Card>
  );
};
