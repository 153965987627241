import { LysaCountry } from "@lysaab/countries";
import { Card, Typography, DocModal, DocModalLink } from "@lysaab/ui-2";
import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../../../context/LocalizationContext";
import { UserContext } from "../../../../../../../../context/UserContext";
import {
  dataDocuments,
  DocumentInformation,
  getLatestDocuments,
} from "../../../../../../../../data/dataDocuments";
import { LysaLinkButton } from "./components/LysaLinkButton";
import { CreateSavingsAccountContext } from "../../../CreateSavingsAccountContext";

const messages = defineMessages({
  placeholder: {
    id: "sweden.createSavingsAccount.createSavingsAccount.openAccount.documentsCard.placeholder",
  },
  modalAnnouncement: {
    id: "sweden.createSavingsAccount.createSavingsAccount.openAccount.documentsCard.announcement",
  },
});

const depositGuaranteeDocList: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: () => [],
  [LysaCountry.FINLAND]: () => [],
  [LysaCountry.GERMANY]: () => [],
  [LysaCountry.SPAIN]: () => [],
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(
      information,
      ...[
        ["legal/se/sv/information-till-insättare-borgo.md"],
        ["legal/se/sv/information-till-insättare.md"],
        ["legal/se/sv/depositor-information.md"],
      ]
    ),
};

const depositGuaranteeInformation: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: () => [],
  [LysaCountry.FINLAND]: () => [],
  [LysaCountry.GERMANY]: () => [],
  [LysaCountry.SPAIN]: () => [],
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(information, [
      "legal/se/sv/viktigt-om-insättningsgaranti.md",
    ]),
};

const agreementInformation: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: () => [],
  [LysaCountry.FINLAND]: () => [],
  [LysaCountry.GERMANY]: () => [],
  [LysaCountry.SPAIN]: () => [],
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(information, [
      "legal/se/sv/avtal-och-allmänna-villkor-för-sparkonto.md",
    ]),
};

const distanceInformationDoc: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: () => [],
  [LysaCountry.FINLAND]: () => [],
  [LysaCountry.GERMANY]: () => [],
  [LysaCountry.SPAIN]: () => [],
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(information, [
      "legal/se/sv/distansavtalsinformation-lysa-sparkonto.md",
    ]),
};

export const DocumentsCard: VoidFunctionComponent = () => {
  const intl = useIntl();
  const [documents, setDocuments] = useState<DocumentInformation[]>();
  const userContext = useContext(UserContext);
  const createAccountContext = useContext(CreateSavingsAccountContext);
  const [showDepositGuaranteeDocs, setShowDepositGuaranteeDocs] =
    useState(false);
  const { country } = useContext(LocalizationContext).state;

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then(setDocuments);
  }, []);

  const varValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: userContext.state.name ?? "",
    ACCOUNT_NAME: createAccountContext.state.accountName,
  };

  if (typeof documents === "undefined" || !country) {
    return null;
  }
  return (
    <Card>
      <Typography type="h3">
        <TranslatedText id="sweden.createSavingsAccount.openAccount.documentsCard.documentHeader" />
      </Typography>
      <DocModal
        active={showDepositGuaranteeDocs}
        onRequestClose={() => setShowDepositGuaranteeDocs(false)}
        variableValues={varValues}
        docName={depositGuaranteeDocList[country](documents)[0]}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={depositGuaranteeDocList[country](documents)}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
        printEnabled={true}
      />
      <DocModalLink
        document={depositGuaranteeInformation[country](documents)[0]}
        modalAnnouncement=""
        className="open-account__document-link"
        variableValues={varValues}
      >
        <span className="lysa-link">
          <TranslatedText id="sweden.createSavingsAccount.openAccount.documentsCard.depositGuaranteeLink" />
        </span>
      </DocModalLink>
      <span className="open-account__document-link">
        <LysaLinkButton
          onClick={() => {
            setShowDepositGuaranteeDocs(true);
          }}
        >
          <TranslatedText id="sweden.createSavingsAccount.openAccount.documentsCard.informationDepositorLink" />
        </LysaLinkButton>
      </span>
      <DocModalLink
        document={agreementInformation[country](documents)[0]}
        modalAnnouncement=""
        className="open-account__document-link"
        variableValues={varValues}
      >
        <span className="lysa-link">
          <TranslatedText id="sweden.createSavingsAccount.openAccount.documentsCard.savingsAccountAgreementLink" />
        </span>
      </DocModalLink>
      <DocModalLink
        document={distanceInformationDoc[country](documents)[0]}
        modalAnnouncement=""
        className="open-account__document-link"
        variableValues={varValues}
      >
        <span className="lysa-link">
          <TranslatedText id="sweden.createSavingsAccount.openAccount.documentsCard.savingsAccountDistanceLink" />
        </span>
      </DocModalLink>
    </Card>
  );
};
