import React from "react";
import { DateTime } from "luxon";
import { FormattedMessage, FormattedDate } from "react-intl";
import { NavLink } from "react-router-dom";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { InvestmentAccount } from "../../../../../../data/dataAccounts";
import { ClosedAccount } from "../../../../../../data/dataProfile";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { ACCOUNTING_PAGE_URL, stringifyParams } from "../AccountingPage";
import "./AccountList.scss";

interface Props {
  title?: string;
  accounts?: (InvestmentAccount | ClosedAccount)[];
  fromDate: Date;
  toDate: Date;
}

export const AccountList = ({ title, accounts, fromDate, toDate }: Props) => {
  if (!accounts || !accounts.length) return null;
  return (
    <GridRow className="account-list">
      {title ? (
        <GridCol xsmall={12}>
          <h4>{title}</h4>
        </GridCol>
      ) : null}
      {accounts.map((account) => (
        <GridCol
          xsmall={6}
          small={4}
          large={12}
          className="account-list-item"
          key={`accounts-${account.accountId}`}
        >
          <ul>
            <li>
              <span className="account-list-item-title">
                <b>{account.name}</b>
              </span>
            </li>
            <li>
              <NavLink
                to={{
                  pathname: getNavLink(ACCOUNTING_PAGE_URL),
                  search: stringifyParams(fromDate, toDate, account.accountId),
                }}
              >
                <FormattedMessage id="sweden.accountingPage.account.link" />
              </NavLink>
            </li>
            <li>
              <span className="account-list-item-date">
                (<FormattedDate value={account.created} />
                {" - "}
                {account.hasOwnProperty("closed") ? (
                  <FormattedDate
                    value={DateTime.fromISO(
                      (account as ClosedAccount).closed
                    ).toJSDate()}
                  />
                ) : (
                  <FormattedMessage id="sweden.accountingPage.account.date.now" />
                )}
                )
              </span>
            </li>
          </ul>
        </GridCol>
      ))}
    </GridRow>
  );
};
