import { LysaLink } from "@lysaab/ui-2";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "../../../components/modal/Modal";
import { TransparentButton } from "../../../components/transparentButton/TransparentButton";

export const PAIQuestionInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <LysaLink component={TransparentButton} onClick={() => setIsOpen(true)}>
        <FormattedMessage id="sustainability.pai.header.link" />
      </LysaLink>
      <Modal
        showModal={isOpen}
        onClose={() => setIsOpen(false)}
        header={<FormattedMessage id="sustainability.pai.modal.header" />}
        closeOnOverlayClick
      >
        <FormattedMessage
          id="sustainability.pai.modal.text"
          values={{
            paragraph: (...parts: string[]) => <p>{parts}</p>,
          }}
        />
      </Modal>
    </>
  );
};
