import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  ConfirmCard,
  Button,
  CONFIRM_BUTTON_TYPES,
  MoneyInput,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  MinValidator,
  LysaFormRef,
  Form,
  MaxValidator,
  BaseValidator,
} from "@lysaab/ui-2";
import { Amount } from "../../../../components/amount/Amount";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { FormattedDate, useIntl } from "react-intl";
import {
  CompoundAccount,
  CompoundAccountId,
} from "../../../../data/dataAccounts";
import {
  MonthlyPayment,
  MonthlyPaymentWithBankInfo,
} from "../../../../data/dataMonthlyPayments";
import { DateTime } from "luxon";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { dataBanks } from "../../../../data/dataBanks";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { UserContext } from "../../../../context/UserContext";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";
import { LysaCountry } from "@lysaab/countries";
import { depositMessages } from "./depositMessages";

interface Props {
  account: CompoundAccount;
  payment: MonthlyPaymentWithBankInfo;
  amountLimit?: number;
  skipMonthlyPayment: (
    accountId: CompoundAccountId,
    externalBankAccount: string
  ) => Promise<void>;
  deleteMonthlyPayment: (
    accountId: CompoundAccountId,
    externalBankAccount: string
  ) => Promise<void>;
  resetMonthlyPayment: (
    accountId: CompoundAccountId,
    externalBankAccount: string
  ) => Promise<void>;
  updateMonthlyPayment: (
    accountId: CompoundAccountId,
    externalBankAccount: string,
    amount: number,
    depositDay: number
  ) => Promise<void>;
}

const MIN_AMOUNTS: Record<LysaCountry, number> = {
  [LysaCountry.SWEDEN]: 200,
  [LysaCountry.DENMARK]: 200,
  [LysaCountry.FINLAND]: 20,
  [LysaCountry.GERMANY]: 20,
  [LysaCountry.SPAIN]: 20,
};

const daysOfMonth = Array.from(Array(31).keys()).map((day) => day + 1);

export const Deposit: VoidFunctionComponent<Props> = ({
  account,
  payment,
  amountLimit,
  deleteMonthlyPayment,
  skipMonthlyPayment,
  resetMonthlyPayment,
  updateMonthlyPayment,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [amount, setAmount] = useState(payment.amount.toString());
  const [day, setDay] = useState(payment.depositDay);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const { state: user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDay(payment.depositDay);
    setAmount(payment.amount.toString());
  }, [payment.amount, payment.depositDay]);

  if (!localizationContext.state.country) {
    return null;
  }

  const validators: BaseValidator[] = [
    new RequiredValidator(intl.formatMessage(depositMessages.amountRequired)),
    new MinValidator(
      MIN_AMOUNTS[localizationContext.state.country],
      intl.formatMessage(depositMessages.amountMin, {
        minAmount: intl.formatNumber(
          MIN_AMOUNTS[localizationContext.state.country],
          {
            style: "currency",
            currency: localizationContext.state.currency,
          }
        ),
      })
    ),
  ];

  if (typeof amountLimit !== "undefined") {
    validators.push(
      new MaxValidator(
        amountLimit + payment.amount,
        intl.formatMessage(depositMessages.amountMax, {
          maxAmount: intl.formatNumber(amountLimit + payment.amount, {
            style: "currency",
            currency: localizationContext.state.currency,
          }),
        })
      )
    );
  }

  return (
    <GridCol xsmall={12} small={6} large={4}>
      <ConfirmCard
        cancelButtonText={intl.formatMessage(depositMessages.cancelButtonText)}
        confirmButtonText={intl.formatMessage(
          depositMessages.confirmButtonText
        )}
        confirmText={intl.formatMessage(depositMessages.confirmText)}
        onConfirm={(remove) => {
          if (remove && !user.readOnly) {
            EventTracker.track({
              event: TrackerEvent.DELETE_MONTHLY,
              message: account.name,
            });
            setIsLoading(true);
            deleteMonthlyPayment(
              payment.accountId,
              payment.externalBankAccount
            ).finally(() => setIsLoading(false));
          }
          setShowConfirm(false);
        }}
        showConfirm={showConfirm}
        confirmButtonType={CONFIRM_BUTTON_TYPES.NEGATIVE}
        showLoading={isLoading}
      >
        <Form
          lysaFormRef={formRef}
          onSubmit={() => {
            if (
              !formRef.current?.isValid ||
              user.readOnly ||
              (payment.amount === Number(amount) && payment.depositDay === day)
            ) {
              return;
            }
            EventTracker.track({
              event: TrackerEvent.UPDATE_MONTHLY,
              message: `${account.name} - Amount: ${amount}, Day: ${day}`,
            });
            setIsLoading(true);
            updateMonthlyPayment(
              payment.accountId,
              payment.externalBankAccount,
              Number(amount),
              day
            ).finally(() => setIsLoading(false));
          }}
        >
          <h4>{account.name}</h4>
          <GridRow>
            <GridCol xsmall={12}>
              <GridRow>
                <GridCol xsmall={12}>
                  <div className="static-input">
                    <span className="static-label">
                      <TranslatedText id="monthly.overview.card.deposit-from" />
                    </span>
                    <span className="static-value">
                      {payment.bank === "MOBILEPAY"
                        ? dataBanks.getBank(payment.bank).long
                        : dataBanks.getBank(payment.bank).long +
                          " - " +
                          payment.externalBankAccount}
                    </span>
                  </div>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xsmall={12}>
                  <div className="static-input">
                    <span className="static-label">
                      <TranslatedText id="monthly.overview.card.next-deduction" />
                    </span>
                    <span className="static-value">
                      <FormattedDate
                        value={DateTime.fromISO(
                          payment.nextDeduction
                        ).toJSDate()}
                      />
                    </span>
                  </div>
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol xsmall={12}>
                  {canChangeAmount(payment) ? (
                    <MoneyInput
                      currency={localizationContext.state.currency}
                      value={amount}
                      onChange={setAmount}
                      label={intl.formatMessage(depositMessages.amountLabel)}
                      validators={validators}
                    />
                  ) : (
                    <div className="static-input">
                      <span className="static-label">
                        {intl.formatMessage(depositMessages.amountLabel)}
                      </span>
                      <span className="static-value">
                        <Amount amount={payment.amount} />
                      </span>
                    </div>
                  )}
                </GridCol>
                <GridCol xsmall={12}>
                  {canChangeDay(payment) ? (
                    <Select
                      alternatives={daysOfMonth.map((day) => {
                        return { text: day.toString(), value: day };
                      })}
                      placeholder={intl.formatMessage(
                        depositMessages.preferredDayPlaceholder
                      )}
                      value={{ text: day.toString(), value: day }}
                      onChange={(day) => setDay(day.value)}
                      label={intl.formatMessage(
                        depositMessages.preferredDayLabel
                      )}
                    />
                  ) : (
                    <React.Fragment>
                      <div className="static-input">
                        <span className="static-label">
                          {intl.formatMessage(
                            depositMessages.preferredDayLabel
                          )}
                        </span>
                        <span className="static-value">
                          {payment.depositDay}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </GridCol>
              </GridRow>
            </GridCol>
            <GridCol xsmall={12}>
              {canChangeDay(payment) && (
                <React.Fragment>
                  <Button
                    size="small"
                    block
                    type="submit"
                    label={
                      <TranslatedText id="monthly.overview.card.button.update" />
                    }
                  />

                  {payment.isSkipped ? (
                    <Button
                      variant="secondary"
                      size="small"
                      block
                      onClick={() => {
                        if (user.readOnly) {
                          return;
                        }
                        EventTracker.track({
                          event: TrackerEvent.RESET_MONTHLY,
                          message: account.name,
                        });
                        setIsLoading(true);
                        resetMonthlyPayment(
                          payment.accountId,
                          payment.externalBankAccount
                        ).finally(() => setIsLoading(false));
                      }}
                      label={
                        <TranslatedText id="monthly.overview.card.button.restore" />
                      }
                    />
                  ) : (
                    <Button
                      variant="secondary"
                      size="small"
                      block
                      onClick={() => {
                        if (user.readOnly) {
                          return;
                        }
                        EventTracker.track({
                          event: TrackerEvent.PAUSE_MONTHLY,
                          message: account.name,
                        });
                        setIsLoading(true);
                        skipMonthlyPayment(
                          payment.accountId,
                          payment.externalBankAccount
                        ).finally(() => setIsLoading(false));
                      }}
                      label={
                        <TranslatedText id="monthly.overview.card.button.push" />
                      }
                    />
                  )}

                  <Button
                    variant="negative"
                    size="small"
                    block
                    onClick={() => {
                      if (user.readOnly) {
                        return;
                      }
                      setShowConfirm(true);
                    }}
                    label={
                      <TranslatedText id="monthly.overview.card.button.remove" />
                    }
                  />
                </React.Fragment>
              )}

              {!canChangeAmount(payment) && canChangeDay(payment) && (
                <Snackbar type={SNACKBAR_TYPES.INFO}>
                  <TranslatedText id="monthly.overview.card.snackbar.locked-amount" />
                </Snackbar>
              )}

              {!canChangeDay(payment) && (
                <Snackbar type={SNACKBAR_TYPES.INFO}>
                  <TranslatedText id="monthly.overview.card.snackbar.locked-day" />
                </Snackbar>
              )}
            </GridCol>
          </GridRow>
        </Form>
      </ConfirmCard>
    </GridCol>
  );
};

function canChangeAmount(payment: MonthlyPayment) {
  return DateTime.fromISO(payment.updateAmountCutoff).diffNow().toMillis() > 0;
}

function canChangeDay(payment: MonthlyPayment) {
  return DateTime.fromISO(payment.updateDayCutoff).diffNow().toMillis() > 0;
}
