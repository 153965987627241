import React, { useReducer, createContext, FunctionComponent } from "react";
import { SavingsAccount } from "../../../data/dataAccounts";
import { MergedInternalTransferInvestmentAccount } from "../../../data/dataTransfer";

export interface WithdrawalIskToSavingsState {
  selectedLysaAccount?: MergedInternalTransferInvestmentAccount;
  selectedSavingsAccount?: SavingsAccount;
  amount?: number;
}

export interface WithdrawalIskToSavingsContextProps {
  state: WithdrawalIskToSavingsState;
  setState: (newState: Partial<WithdrawalIskToSavingsState>) => void;
}

export const WithdrawalIskToSavingsContext = createContext<
  WithdrawalIskToSavingsContextProps | undefined
>(undefined);

function stateReducer(
  state: WithdrawalIskToSavingsState,
  newState: Partial<WithdrawalIskToSavingsState>
) {
  return { ...state, ...newState };
}

export const WithdrawalIskToSavingsContextProvider: FunctionComponent = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <WithdrawalIskToSavingsContext.Provider value={{ state, setState }}>
      {children}
    </WithdrawalIskToSavingsContext.Provider>
  );
};

export function useWithdrawalIskToSavingsContext() {
  const context = React.useContext(WithdrawalIskToSavingsContext);
  if (context === undefined) {
    throw new Error(
      "useInternalWithdrawalContext must be used within a WithdrawalIskToSavingsContextProvider"
    );
  }
  return context;
}
