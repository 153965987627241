import React from "react";
import { BANK_ICON_CLASS } from "../../BankIcon";
import Icon from "./danske_bank.svg";

export function DanskeBank() {
  return (
    <div className={BANK_ICON_CLASS}>
      <img src={Icon} alt="Danske Bank icon" />
    </div>
  );
}
