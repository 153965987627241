import React, { VoidFunctionComponent, useContext, useCallback } from "react";
import { PepAnswers, PepContext, stateToRequest } from "@lysaab/lysa-pep";
import { Button, Card } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { dataKyc } from "../../../../data/dataKyc";

interface Props {
  next: () => void;
}

export const PepOverview: VoidFunctionComponent<Props> = ({ next }) => {
  const pepContext = useContext(PepContext);

  const onNext = useCallback(() => {
    dataKyc.updatePep(stateToRequest(pepContext.state)).then(() => {
      next();
    });
  }, [next, pepContext.state]);

  return (
    <>
      <Card>
        <PepAnswers answer={stateToRequest(pepContext.state)} />
      </Card>

      <Button
        block
        onClick={onNext}
        label={<FormattedMessage id="kyc.pep.edit.button.save" />}
      />
    </>
  );
};
