import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { LysaAccountSelectionCard } from "../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../components/TranslatedText";
import { dataAccounts, CompoundAccount } from "../../../../data/dataAccounts";
import { WithAccountId } from "../../overview/Recommendation";
import { KlarnaContext } from "../KlarnaContext";

interface Props {
  next: () => void;
}

export function LysaAccountSelection({ next }: Props) {
  const [accounts, setAccounts] = useState<CompoundAccount[]>();
  const formRef = useRef<LysaFormRef>();
  const klarnaContext = useContext(KlarnaContext);
  const selectedLysaAccount = klarnaContext.state.selectedLysaAccount;
  const location = useLocation<WithAccountId | undefined>();
  const loaded = useRef(false);

  useEffect(() => {
    if (loaded.current) {
      return;
    }
    loaded.current = true;

    dataAccounts.getAllAccounts().then((accounts) => {
      const allAccounts = [
        ...accounts.investmentAccounts,
        ...accounts.savingsAccounts,
      ];

      setAccounts(allAccounts);
      if (location.state?.accountId) {
        const account = allAccounts.find(
          (acc) => acc.accountId === location.state?.accountId
        );
        if (account && !klarnaContext.state.selectedLysaAccount) {
          klarnaContext.setState({ selectedLysaAccount: account });
          next();
        }
      }
    });
  }, [klarnaContext, location.state?.accountId, next]);

  if (!accounts) {
    return <Spinner />;
  }

  return (
    <div>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={selectedLysaAccount}
          onChange={(account) =>
            klarnaContext.setState({
              selectedLysaAccount: account,
            })
          }
          legend={
            <h2>
              <TranslatedText
                id="deposits.klarna.story.lysa-account-selection.header"
                defaultMessage="Selection destination account"
              />
            </h2>
          }
        />
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.klarna.story.lysa-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
