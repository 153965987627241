import { LysaCountry } from "@lysaab/countries";
import { useContext, useMemo } from "react";
import { LocalizationContext } from "../../../context/LocalizationContext";
import {
  generateTranslatedArray,
  Step,
} from "../economicSituation/person/Interval";

const stepData: Record<LysaCountry, Step[]> = {
  [LysaCountry.GERMANY]: [
    {
      step: 100,
      stop: 11000,
    },
  ],
  [LysaCountry.SPAIN]: [
    {
      step: 100,
      stop: 11000,
    },
  ],
  [LysaCountry.DENMARK]: [
    {
      step: 1000,
      stop: 110000,
    },
  ],
  [LysaCountry.FINLAND]: [
    {
      step: 100,
      stop: 11000,
    },
  ],
  [LysaCountry.SWEDEN]: [
    {
      step: 1000,
      stop: 110000,
    },
  ],
};

export function useIncomeSteps() {
  const localizationContext = useContext(LocalizationContext);

  return useMemo(() => {
    if (!localizationContext.state.country) {
      return [];
    }
    return generateTranslatedArray(stepData[localizationContext.state.country]);
  }, [localizationContext.state.country]);
}
