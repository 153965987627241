import { Spinner } from "@lysaab/ui-2";
import { VoidFunctionComponent } from "react";
import { ConfirmEsgUpdate } from "../../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";
import { useReviewAccountContext } from "../../ReviewAccountContext";

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdateWrapper: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [reviewAccountState] = useReviewAccountContext();

  if (
    !reviewAccountState.reviewAccount ||
    !reviewAccountState.reviewAccount.newAdvice.esgResult.esgBestMatch
  ) {
    return <Spinner />;
  }

  return (
    <ConfirmEsgUpdate
      esg={reviewAccountState.reviewAccount.newAdvice.esgResult.esgAnswers}
      updatedEsg={
        reviewAccountState.reviewAccount.newAdvice.esgResult.esgBestMatch
      }
      next={next}
    />
  );
};
