import { useCallback, useContext } from "react";
import { PepContext } from "@lysaab/lysa-pep";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useYearlyReviewContext } from "../contexts/YearlyReviewContext";
import { useMultiPepContext } from "../contexts/MultiPepContext";
import { useAccountsContext } from "../../../context/AccountsContext";
import {
  CloseAccountState,
  CLOSE_LYSA_CUSTOMER_URL,
} from "../../closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import { YearlyReviewReturnState } from "../AccessGuardRoute";
import { CloseCustomerScenario } from "../../../data/dataProfile";
import { useEligibilityContext } from "../../../context/EligibilityContext";
import { useReviewAccountContext } from "../../reviewAccount/ReviewAccountContext";

export const useNavigateToCloseAccount = () => {
  const safeNavigation = useSafeNavigation();
  const history = useHistory<YearlyReviewReturnState | undefined>();
  const pepContext = useContext(PepContext);
  const [yearlyReviewState] = useYearlyReviewContext();
  const [multiPepState] = useMultiPepContext();
  const [accountsState] = useAccountsContext();
  const [eligibilityState] = useEligibilityContext();
  const [reviewAccountState] = useReviewAccountContext();

  const navigateToCloseAccount = useCallback(() => {
    const state: YearlyReviewReturnState = {
      accountsState,
      pepState: pepContext.state,
      multiPepState,
      yearlyReviewState,
      eligibilityState,
      reviewAccountState,
    };

    // Replace current history state with saved contexts so if user uses browser back it doesn't crash
    history.replace({
      pathname: history.location.pathname,
      state: {
        accountsState,
        pepState: pepContext.state,
        multiPepState,
        yearlyReviewState,
      } as YearlyReviewReturnState,
    });

    const returnState: CloseAccountState<YearlyReviewReturnState> = {
      scenario: CloseCustomerScenario.YEARLY_REVIEW,
      returnUrl: history.location.pathname,
      returnState: state,
    };
    safeNavigation({
      pathname: getNavLink(CLOSE_LYSA_CUSTOMER_URL),
      state: returnState,
    });
  }, [
    accountsState,
    pepContext.state,
    multiPepState,
    yearlyReviewState,
    eligibilityState,
    reviewAccountState,
    history,
    safeNavigation,
  ]);

  return navigateToCloseAccount;
};
