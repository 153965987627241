import { Typography, NewIcon } from "@lysaab/ui-2";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  VoidFunctionComponent,
} from "react";
import "./WorthSummaryHeader.scss";
import { ReturnInformationModal } from "./ReturnInformationModal";
import { FormattedMessage, defineMessages } from "react-intl";
import { InvestmentAccount } from "../../data/dataAccounts";
import { Amount } from "../../components/amount/Amount";
import { FormattedStyledPercentage } from "../../components/formattedPercentage/FormattedStyledPercentage";
import cx from "classnames";
import { HolidayAnimations } from "./holidays/valentines/HolidayAnimations";
import { useBalanceAndEarnings } from "./hooks/useBalanceAndEarnings";
import { SavingsAccountDataProps } from "../account/savingsAccountPage/components/overviewCard/OverviewCard";
import { SavingsRelatedInformationModal } from "../account/savingsAccountPage/components/overviewCard/components/informationModal/InformationModal";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../data/dataCustomerTracking";
import { matchPath, useLocation } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SAVINGS_ACCOUNT_PAGE_URL } from "../account/savingsAccountPage/SavingsAccountPage";
import { Store, STORE_SHOW_PERFORMANCE_GRAPH } from "../../Store";
import { SHARED_SAVINGS_ACCOUNT_PAGE_URL } from "../account/savingsAccountPage/SharedSavingsAccountPage";
import { PercentageCurrencySwitch } from "../../components/percentageCurrencySwitch/PercentageCurrencySwitch";
import { useShowCurrency } from "../../context/LayoutContext";
import { useAccountsWorth } from "../../hooks/useAccountsWorth";

interface Props {
  account?: InvestmentAccount;
  savingsAccountData?: SavingsAccountDataProps;
  seeNoEvilMode?: boolean;
  showGraph: boolean;
  setShowGraph: Dispatch<SetStateAction<boolean>>;
}

const messages = defineMessages({
  balanceHeader: {
    id: "overview.summaryCard.balance",
  },
  balanceHeaderAccount: {
    id: "overview.summaryCard.balance.account",
  },
  balanceHeaderSavingsAccount: {
    id: "savingsAccountPage.overviewCard.header",
  },
  availableForWithdrawalSavingsAccount: {
    id: "savingsAccountPage.overviewCard.available",
  },
  LAST_THREE_MONTHS: {
    id: "overview.summaryCard.interval.lastThreeMonths",
  },
  LAST_SIX_MONTHS: {
    id: "overview.summaryCard.interval.lastSixMonths",
  },
  LAST_YEAR: {
    id: "overview.summaryCard.interval.lastYear",
  },
  CURRENT_YEAR: {
    id: "overview.summaryCard.interval.currentYear",
  },
  SINCE_REGISTRATION: {
    id: "overview.summaryCard.interval.sinceRegistration",
  },
  CUSTOM: {
    id: "overview.summaryCard.interval.custom",
  },
});

export const WorthSummaryHeader: VoidFunctionComponent<Props> = ({
  account,
  savingsAccountData,
  seeNoEvilMode,
  showGraph,
  setShowGraph,
}) => {
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [showReturnInformationModal, setShowReturnInformationModal] =
    useState(false);
  const location = useLocation();
  const { showCurrency, toggleCurrency } = useShowCurrency();

  const isSavingsAccount =
    matchPath(location.pathname, {
      path: getNavLink(SAVINGS_ACCOUNT_PAGE_URL),
      exact: true,
    }) ||
    matchPath(location.pathname, {
      path: getNavLink(SHARED_SAVINGS_ACCOUNT_PAGE_URL),
      exact: true,
    });

  const { data, interval } = useBalanceAndEarnings(account);
  const accountsWorth = useAccountsWorth();

  const totalWorth =
    typeof account !== "undefined"
      ? account.isSharedAccount
        ? accountsWorth.sharedInvestmentAccounts?.[account.accountId] ?? 0
        : accountsWorth.investmentAccounts?.[account.accountId] ?? 0
      : accountsWorth.totalWorth;

  const handleGraphToggle = useCallback(() => {
    setShowGraph(!showGraph);
    Store.setValue(STORE_SHOW_PERFORMANCE_GRAPH, !showGraph);
  }, [setShowGraph, showGraph]);

  return (
    <>
      <div
        className={cx("layout", {
          "account-page": typeof account !== "undefined" || isSavingsAccount,
          "savings-account-page": isSavingsAccount,
          "hidden-graph": !showGraph,
          "see-no-evil-mode": seeNoEvilMode,
        })}
      >
        <HolidayAnimations />

        <div className="card-actions">
          <PercentageCurrencySwitch
            value={showCurrency}
            onChange={() => {
              toggleCurrency();
            }}
            variant={account || isSavingsAccount ? "secondary" : "primary"}
          />
          {!isSavingsAccount ? (
            !seeNoEvilMode && (
              <button
                className={cx("header-circle-button", {
                  "showing-graph": showGraph,
                })}
                onClick={handleGraphToggle}
              >
                <NewIcon.Investment size={20} />
              </button>
            )
          ) : (
            <button
              className={cx("header-circle-button")}
              onClick={() => {
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.SAVINGS_ACCOUNT,
                  eventName: "clickedAccountValueHelpButton",
                  subDomain: SubDomain.ACCOUNT_DETAILS,
                });
                setShowInformationModal(true);
              }}
            >
              <NewIcon.Information size={20} />
            </button>
          )}
        </div>

        <div
          className={cx("wrapper", {
            "no-earnings": seeNoEvilMode,
            "is-loaded":
              typeof data !== "undefined" ||
              typeof savingsAccountData !== "undefined",
            "is-savings-account-page": isSavingsAccount,
          })}
        >
          <div
            className={cx("balance-and-earnings", {
              "savings-account-wrapper": isSavingsAccount,
            })}
          >
            <div className="total-worth-wrapper">
              <div className="total-worth-header">
                <Typography className="header" type="label" component="span">
                  {isSavingsAccount ? (
                    <FormattedMessage
                      {...messages.balanceHeaderSavingsAccount}
                    />
                  ) : account ? (
                    <FormattedMessage {...messages.balanceHeaderAccount} />
                  ) : (
                    <FormattedMessage {...messages.balanceHeader} />
                  )}
                </Typography>
              </div>
              <div className="number">
                <Typography type="display-large" component="span">
                  {isSavingsAccount && savingsAccountData ? (
                    <Amount
                      amount={Math.floor(savingsAccountData.balance)}
                      decimals={0}
                    />
                  ) : data ? (
                    <Amount amount={totalWorth} decimals={0} />
                  ) : (
                    "-"
                  )}
                </Typography>
              </div>
            </div>

            {!seeNoEvilMode && (
              <div className="yield-wrapper">
                <div className="yield-header">
                  <Typography className="header" type="label" component="span">
                    <FormattedMessage {...messages[interval]} />
                  </Typography>
                  <button
                    className={cx("yield-circle-button")}
                    onClick={() => setShowReturnInformationModal(true)}
                  >
                    <NewIcon.InformationOutline size={20} />
                  </button>
                </div>

                <div className="yields">
                  {!showCurrency ? (
                    <div className="percentage number">
                      <Typography type="display-small" component="span">
                        {isSavingsAccount && savingsAccountData ? (
                          <FormattedStyledPercentage
                            value={savingsAccountData.totalChange}
                            showSign={true}
                            decimals={2}
                          />
                        ) : data ? (
                          <FormattedStyledPercentage
                            value={data.totalChange}
                            showSign={true}
                            decimals={2}
                          />
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </div>
                  ) : (
                    <div className="absolute-amount number">
                      <Typography type="display-small" component="span">
                        {isSavingsAccount && savingsAccountData ? (
                          <Amount
                            amount={Math.floor(
                              savingsAccountData.totalEarnings
                            )}
                            showSign={true}
                            decimals={0}
                          />
                        ) : data ? (
                          <Amount
                            amount={data.totalEarnings}
                            showSign={true}
                            decimals={0}
                          />
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {isSavingsAccount && savingsAccountData && (
            <div className="savings-account-available">
              <div className="yield-wrapper">
                <div className="yield-header">
                  <Typography className="header" type="label" component="span">
                    <FormattedMessage
                      {...messages.availableForWithdrawalSavingsAccount}
                    />
                  </Typography>
                </div>

                <div className="yields">
                  <div className="absolute-amount number">
                    <Typography type="display-small" component="span">
                      <Amount
                        amount={Math.floor(
                          savingsAccountData.availableForWithdrawal
                        )}
                        decimals={0}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}

          <ReturnInformationModal
            showModal={showReturnInformationModal}
            onClose={() => setShowReturnInformationModal(false)}
          />
          <SavingsRelatedInformationModal
            showModal={showInformationModal}
            onClose={() => setShowInformationModal(false)}
          />
        </div>
      </div>
    </>
  );
};
