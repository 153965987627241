import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { LysaAccountSelectionCard } from "../../../../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { CompoundAccount } from "../../../../../../data/dataAccounts";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import { FallbackTransferState } from "../../../../../../pages/deposits/overview/Recommendation";

interface Props {
  next: (account: CompoundAccount) => void;
  preselectedAccount?: CompoundAccount;
}

export const BankgiroAccountSelectPage: React.VFC<Props> = ({
  next,
  preselectedAccount,
}) => {
  const { accounts: allAccounts } = useAccounts();
  const [selectedAccount, setSelectedAccount] = useState<CompoundAccount>();
  const formRef = useRef<LysaFormRef>();
  const location = useLocation<FallbackTransferState>();

  const accounts = useMemo(
    () =>
      allAccounts
        ? [...allAccounts.investmentAccounts, ...allAccounts.savingsAccounts]
        : undefined,
    [allAccounts]
  );

  useEffect(() => {
    if (preselectedAccount) {
      setSelectedAccount(preselectedAccount);
    }
  }, [preselectedAccount]);

  useEffect(() => {
    if (!selectedAccount && location.state && accounts) {
      const account = accounts.find(
        (account) => account.accountId === location.state.accountId
      );
      if (account) {
        setSelectedAccount(account);
      }
    }
  }, [selectedAccount, accounts, location, next]);

  if (typeof accounts === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (typeof selectedAccount !== "undefined") {
            next(selectedAccount);
          }
        }}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={selectedAccount}
          onChange={(account) => setSelectedAccount(account)}
          legend={
            <h2>
              <TranslatedText id="sweden.deposits.bankgiro.story.select-account.label" />
            </h2>
          }
        />

        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.bankgiro.story.select-account.button" />
          }
        />
      </Form>
    </div>
  );
};
