import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  Currency,
  LocalizationContext,
} from "../../../../context/LocalizationContext";
import { KlarnaAccount } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { FallbackTransferState } from "../../overview/Recommendation";
import { KlarnaContext } from "../KlarnaContext";
import {
  BASE_ROUTES,
  fallbackLocationDescriptorFunction,
  KLARNA_DEPOSITS_URL,
} from "../KlarnaStory";

interface Props {
  next: () => void;
  getFallbackDepositLocationDescriptor?: fallbackLocationDescriptorFunction;
}

const messages = defineMessages({
  accountLabel: {
    id: "deposits.klarna.story.account-selection.account.label",
    defaultMessage: "Select bank account",
  },
  accountRequired: {
    id: "deposits.klarna.story.account-selection.account.required",
    defaultMessage: "You have to select an account",
  },
  amountLabel: {
    id: "deposits.klarna.story.account-selection.amount.label",
    defaultMessage: "Amount to transfer",
  },
  amountRequired: {
    id: "deposits.klarna.story.account-selection.amount.required",
    defaultMessage: "You have to set an amount",
  },
  amountMin: {
    id: "deposits.klarna.story.account-selection.amount.min",
    defaultMessage: "You cannot transfer less than {minAmount}",
  },
  amountMax: {
    id: "deposits.klarna.story.account-selection.amount.max",
    defaultMessage: "You cannot transfer more than {maxAmount}",
  },
});

const DEFAULT_MIN_AMOUNT = 10;
const DEFAULT_MAX_AMOUNT = 150_000;

export function AccountSelection({
  getFallbackDepositLocationDescriptor,
  next,
}: Props) {
  const klarnaContext = useContext(KlarnaContext);
  const localizationContext = useContext(LocalizationContext);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = klarnaContext.state.selectedKlarnaAccount;
  const formRef = useRef<LysaFormRef>();
  const maxAllowed = klarnaContext.state.bank?.maxAllowed || DEFAULT_MAX_AMOUNT;

  useEffect(() => {
    if (!klarnaContext.state.klarnaAccounts) {
      history.push(getNavLink(KLARNA_DEPOSITS_URL));
    }
  }, [history, klarnaContext.state.klarnaAccounts]);

  if (!klarnaContext.state.klarnaAccounts) {
    return <Spinner />;
  }

  if (klarnaContext.state.klarnaAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText
            id="deposits.klarna.story.account-selection.no-accounts.header"
            defaultMessage="No accounts found"
          />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText
              id="deposits.klarna.story.account-selection.no-accounts.text"
              defaultMessage="No accounts found. Try another bank"
            />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="deposits.klarna.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText
          id="deposits.klarna.story.account-selection.header"
          defaultMessage="Account selection"
        />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={klarnaContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) =>
              klarnaContext.setState({
                selectedKlarnaAccount: klarnaContext.state.klarnaAccounts?.find(
                  (account) => account.id === alt.value
                ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
          <MoneyInput
            currency={localizationContext.state.currency}
            value={klarnaContext.state.amount}
            onChange={(amount) => klarnaContext.setState({ amount })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                klarnaContext.state.bank?.minAllowed || DEFAULT_MIN_AMOUNT,
                intl.formatMessage(messages.amountMin, {
                  minAmount: intl.formatNumber(
                    klarnaContext.state.bank?.minAllowed || DEFAULT_MIN_AMOUNT,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                    }
                  ),
                })
              ),
              new MaxValidator(
                maxAllowed,
                intl.formatMessage(messages.amountMax, {
                  maxAmount: intl.formatNumber(maxAllowed, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
            ]}
          />
          {klarnaContext.state.amount &&
            klarnaContext.state.selectedLysaAccount &&
            getFallbackDepositLocationDescriptor &&
            Number.parseInt(klarnaContext.state.amount) >= maxAllowed && (
              <Link<FallbackTransferState>
                to={getFallbackDepositLocationDescriptor({
                  accountId: klarnaContext.state.selectedLysaAccount.accountId,
                  amount: Number.parseInt(klarnaContext.state.amount),
                })}
                className="lysa-ui-button button-secondary"
              >
                <TranslatedText id="deposits.klarna.story.account-selection.max-button" />
              </Link>
            )}
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.klarna.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: Currency
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        style: "currency",
        currency,
      })}`;
}
