import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { InvestmentAccount, dataAccounts } from "../../../data/dataAccounts";
import { useLocation } from "react-router";
import { FallbackTransferState } from "../overview/Recommendation";
import { LysaAccountSelectionCard } from "../../../components/lysaAccountSelectionCard/LysaAccountSelectionCard";

interface Props {
  next: (account: InvestmentAccount) => void;
  preselectedAccount?: InvestmentAccount;
}

const messages = defineMessages({
  label: {
    id: "deposits.virtual-account.selection.select.label",
    defaultMessage: "Select an account",
  },
  placeholder: {
    id: "deposits.virtual-account.selection.select.placeholder",
    defaultMessage: "Select account",
  },
});

export const VirtualAccountSelectPage: FunctionComponent<Props> = ({
  next,
  preselectedAccount,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [selectedAccount, setSelectedAccount] = useState<InvestmentAccount>();
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();
  const location = useLocation<FallbackTransferState>();

  useEffect(() => {
    dataAccounts.getAccounts().then((response) => {
      setAccounts(response);
    });
    if (preselectedAccount) {
      setSelectedAccount(preselectedAccount);
    }
  }, [preselectedAccount]);

  useEffect(() => {
    if (!selectedAccount && location.state && accounts) {
      const account = accounts.find(
        (account) => account.accountId === location.state.accountId
      );
      if (account) {
        setSelectedAccount(account);
      }
    }
  }, [selectedAccount, accounts, location, next]);

  useEffect(() => {
    dataAccounts.getAccounts().then((response) => {
      setAccounts(response);
    });
  }, []);

  if (typeof accounts === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <FormattedMessage
          id="deposits.virtual-account.selection.header"
          defaultMessage="Select account"
        />
      </h2>
      <Form
        onSubmit={() => {
          if (!formRef.current?.isValid || !selectedAccount) {
            return;
          }
          next(selectedAccount);
        }}
        lysaFormRef={formRef}
      >
        <LysaAccountSelectionCard
          accounts={accounts}
          selectedAccount={selectedAccount}
          onChange={(account) => setSelectedAccount(account)}
          legend={intl.formatMessage(messages.label)}
        />
        <Button
          type="submit"
          block
          label={
            <FormattedMessage id="deposits.virtual-account.selection.next" />
          }
        />
      </Form>
    </div>
  );
};
