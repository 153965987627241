import {
  Alternative,
  Card,
  ReactForecast,
  MoneyInput,
  MinValidator,
  MaxValidator,
  ChoiceChipGroup,
  Slider,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
  InvestmentType,
} from "@lysaab/ui-2";
import cx from "classnames";
import React, { useCallback, useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  defaultKFState,
  KFAccountContext,
  MIN_RECOMMENDED_ALLOCATION,
} from "../KFAccountContext";
import { useHistory } from "react-router";
import { EditAllocationFees } from "./EditAllocationFees";
import { EditAllocationPositions } from "./EditAllocationPositions";
import AnimateHeight from "react-animate-height";
import "./EditAllocation.scss";
import { useIntl } from "react-intl";
import { GetForecastGraphTexts } from "../../../../../../components/forecastGraphTexts/GetForecastGraphTexts";
import { SavingsHorizonLength } from "../../../../../../data/dataInvestments";
import { Link } from "react-router-dom";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../CreateAccountCorporationPage";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

interface Props {
  horizonRoute: string;
  warningRoute: string;
  candidateRoute: string;
}

export const MIN_MONTHLY = 0;
export const MIN_ALLOCATION_INVESTMENT = 1000;
export const MAX_ALLOCATION_INVESTMENT = 100000000000;

const yearAlternatives: Alternative<string>[] = [
  {
    text: "5 år",
    value: "5",
  },
  {
    text: "10 år",
    value: "10",
  },
  {
    text: "15 år",
    value: "15",
  },
  {
    text: "20 år",
    value: "20",
  },
];

export const EditAllocation: React.FC<Props> = ({
  horizonRoute,
  warningRoute,
  candidateRoute,
}) => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const {
    allocationSelectedRisk,
    allocationInvestment,
    allocationMonthlyInvestment,
    investmentType,
  } = kfState;
  const l10nContext = useContext(LocalizationContext);
  const history = useHistory();
  const intl = useIntl();
  const allocationSelectedYear =
    kfState.allocationSelectedYear || yearAlternatives[2];
  const [, setShowHorizonWarning] = useState(false);
  const risk = allocationSelectedRisk || 0;

  const monthly =
    parseInt(allocationMonthlyInvestment, 10) > MIN_MONTHLY
      ? allocationMonthlyInvestment
      : 0;

  const parsedInvestment = parseInt(allocationInvestment, 10);
  const investment =
    parsedInvestment > MIN_ALLOCATION_INVESTMENT &&
    parsedInvestment < MAX_ALLOCATION_INVESTMENT
      ? parsedInvestment
      : 0;

  const getTexts = useCallback(
    () => GetForecastGraphTexts(intl, l10nContext.state.currency),
    [intl, l10nContext.state.currency]
  );

  return (
    <div className="create-account-corporation-edit-allocation">
      <Card>
        <h4>Exempelinvestering</h4>
        <MoneyInput
          currency={l10nContext.state.currency}
          value={allocationInvestment.toString()}
          onChange={(value) =>
            setKFState({
              allocationInvestment: value,
            })
          }
          placeholder="10 000"
          validators={[
            new MinValidator(
              MIN_ALLOCATION_INVESTMENT,
              "Prognosberäkningar kan inte göras på investeringar understigande 1 000 kr"
            ),
            new MaxValidator(
              MAX_ALLOCATION_INVESTMENT,
              "Prognosberäkningar kan inte göras för inveteringar på mer än 100 000 000 000 kr"
            ),
          ]}
        />

        <h4>Månadsspar</h4>
        <MoneyInput
          currency={l10nContext.state.currency}
          value={allocationMonthlyInvestment.toString()}
          onChange={(value) =>
            setKFState({
              allocationMonthlyInvestment: value,
            })
          }
          placeholder="t ex 1 000"
          validators={[
            new MinValidator(MIN_MONTHLY, "Måste vara ett positivt heltal"),
          ]}
        />

        <h4>Prognos</h4>
        <ChoiceChipGroup
          onChange={(event) => setKFState({ allocationSelectedYear: event })}
          value={allocationSelectedYear}
          alternatives={yearAlternatives}
        />

        <div
          className={cx("graph-wrapper", {
            disabled: !!investment,
          })}
        >
          <ReactForecast
            initial={investment.toString()}
            interval={allocationSelectedYear.value}
            monthly={monthly.toString()}
            risk={risk.toString()}
            getTexts={getTexts()}
            currency={l10nContext.state.currency}
            locale={`${l10nContext.getLocale()}`}
          />
        </div>

        <div className="relative">
          <h4>Ändra fördelning</h4>
          <div
            className={cx("reset-button", {
              active: risk !== kfState.advicedRisk,
            })}
          >
            <Button
              variant="secondary"
              size="small"
              onClick={() =>
                setKFState({
                  allocationSelectedRisk: kfState.advicedRisk,
                })
              }
              label="Återställ"
            />
          </div>
          <strong>Lysas förslag</strong> -{" "}
          <i>
            {kfState.advicedRisk || 0} % aktier,{" "}
            {100 - (kfState.advicedRisk || 0)} % räntor
          </i>
        </div>

        <Slider
          label={
            <>
              <strong>Målfördelning</strong> -{" "}
              <i>
                {risk} % aktier, {100 - risk} % räntor
              </i>
            </>
          }
          min={0}
          max={100}
          value={risk}
          onChange={(value) => {
            if (
              kfState.savingsHorizon === SavingsHorizonLength.SHORT &&
              value > 50
            ) {
              setShowHorizonWarning(true);
              setKFState({ allocationSelectedRisk: 50 });
            } else {
              setShowHorizonWarning(false);
              setKFState({ allocationSelectedRisk: value });
            }
          }}
          risk
        />

        <AnimateHeight
          animateOpacity={true}
          height={
            risk < MIN_RECOMMENDED_ALLOCATION && risk !== kfState.advicedRisk
              ? "auto"
              : 0
          }
        >
          <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
            <div>
              <b>Avrådan på grund av Lysas roll som försäkringsförmedlare:</b>{" "}
              eftersom kapitalförsäkring är en schablonbeskattad sparform bör
              spararen ha en investering som över tid kan förväntas leverera
              avkastning som innebär att fördelen med schablonbeskattning
              tillgodogörs. Eftersom du valt en målfördelning som understiger{" "}
              <b>{MIN_RECOMMENDED_ALLOCATION} %</b> aktieinnehav är det möjligt
              att företaget inte kan tillgodogöra sig fördelen med en
              schablonbeskattning. Företaget kan istället öppna en
              värdepappersdepå hos Lysa.
              <br />
              <b>
                <Link
                  to={getNavLink(CREATE_ACCOUNT_CORPORATION_PAGE_URL)}
                  onClick={() => {
                    setKFState(defaultKFState);
                  }}
                >
                  Öppna värdepappersdepå
                </Link>
              </b>
            </div>
          </Snackbar>
        </AnimateHeight>

        <AnimateHeight
          animateOpacity={true}
          height={
            kfState.savingsHorizon === SavingsHorizonLength.SHORT && risk >= 50
              ? "auto"
              : 0
          }
        >
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <div>
              <p>
                Med en kort investeringshorisont <b>(1-3 år)</b> är det
                rekommenderat att välja en låg andel aktier. Du kan därför inte
                välja mer än <b>50 %</b> aktier. Om du anger en längre
                investeringshorisont så kan du välja en högre andel aktier.
              </p>
              <Button
                variant="secondary"
                block
                onClick={() => {
                  history.push(horizonRoute);
                }}
                label="Ändra investeringshorisont"
              />
            </div>
          </Snackbar>
        </AnimateHeight>
      </Card>

      <div className="bottom-nav">
        <Button
          block
          onClick={() => {
            setKFState({ takenRisk: kfState.allocationSelectedRisk });
            if (
              Math.abs(
                (kfState.allocationSelectedRisk || 0) -
                  (kfState.advicedRisk || 0)
              ) > 14
            ) {
              history.push(warningRoute);
            } else {
              history.push(candidateRoute);
            }
          }}
          label="Nästa"
        />
      </div>

      <div className="bottom-nav">
        <EditAllocationFees
          risk={risk}
          investmentType={investmentType || InvestmentType.BROAD}
          investment={allocationInvestment}
          monthly={allocationMonthlyInvestment}
        />
      </div>

      <div className="bottom-nav">
        <EditAllocationPositions
          risk={risk}
          investmentType={investmentType || InvestmentType.BROAD}
          investment={allocationInvestment}
        />
      </div>
    </div>
  );
};
