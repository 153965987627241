import { Spinner } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { dataKyc, KycResponse } from "../../data/dataKyc";
import { Page, PageHeader } from "../Page";
import { Pep } from "./pep/Pep";
import { AccountsKyc } from "./accountsKyc/AccountsKyc";
import { CustomerKyc } from "./customerKyc/CustomerKyc";
import "./KycViewPage.scss";

export const KYC_PAGE_URL = "/kyc";

export const KycViewPage = () => {
  const [kycData, setKycData] = useState<KycResponse>();

  useEffect(() => {
    dataKyc.getKyc().then(setKycData);
  }, []);

  if (!kycData) {
    return <Spinner />;
  }

  return (
    <Page>
      <PageHeader>
        <h1>
          <FormattedMessage id="kyc.view.header" />
        </h1>
      </PageHeader>
      <div className="kyc-view-page">
        <GridRow>
          <GridCol xsmall={12} medium={6}>
            <CustomerKyc />
            <Pep />
          </GridCol>
          <GridCol xsmall={12} medium={6}>
            <AccountsKyc />
          </GridCol>
        </GridRow>
      </div>
    </Page>
  );
};
