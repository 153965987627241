import React, { useReducer, createContext, FunctionComponent } from "react";
import { AvailableBank, KlarnaAccount } from "../../../data/dataKlarna";

export interface AddAccountKlarnaState {
  bank?: AvailableBank;
  klarnaAccounts?: KlarnaAccount[];
  selectedAccount?: KlarnaAccount;
}

export interface AddAccountKlarnaContextProps {
  state: AddAccountKlarnaState;
  setState: (newState: Partial<AddAccountKlarnaState>) => void;
}

export interface withAddAccountKlarnaProps {
  AddAccountKlarna: AddAccountKlarnaContextProps;
}

export const AddAccountKlarnaContext = createContext<
  AddAccountKlarnaContextProps
>({} as AddAccountKlarnaContextProps);

function stateReducer(
  state: AddAccountKlarnaState,
  newState: Partial<AddAccountKlarnaState>
) {
  return { ...state, ...newState };
}

export const AddAccountKlarnaContextProvider: FunctionComponent = ({
  children,
}) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <AddAccountKlarnaContext.Provider value={{ state, setState }}>
      {children}
    </AddAccountKlarnaContext.Provider>
  );
};
