import {
  Card,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  Select,
  Spinner,
  TextInput,
} from "@lysaab/ui-2";
import React, { useContext, useRef, VoidFunctionComponent } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { dataMobilepay } from "../../../../../../../data/dataMobilepay";
import { MobilePayLogo } from "../../images/MobilePayLogo";
import { MonthlyContext } from "../MonthlyContext";
import "./CreateAgreement.scss";

interface Props {
  agreementExists: () => void;
  userRedirect: string;
}

const messages = defineMessages({
  phoneLabel: {
    id: "denmark.mobilepay.story.create.phone.label",
  },
  phoneRequired: {
    id: "denmark.mobilepay.story.create.phone.required",
  },
  dayLabel: {
    id: "denmark.mobilepay.story.create.day.label",
  },
  dayRequired: {
    id: "denmark.mobilepay.story.create.day.required",
  },
  dayPlaceholder: {
    id: "denmark.mobilepay.story.create.day.placeholder",
  },
});

const daysOfMonth = Array.from(Array(28).keys()).map((day) => day + 1);

export const CreateAgreement: VoidFunctionComponent<Props> = ({
  agreementExists,
  userRedirect,
}) => {
  const [loading, setLoading] = useState(true);
  const intl = useIntl();
  const monthlyContext = useContext(MonthlyContext);
  const formRef = useRef<LysaFormRef>();
  const [showCancel, setShowCancel] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setShowCancel(true);
    }, 30 * 1000);
    dataMobilepay.pollActiveAgreement().then((resp) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      if (resp.length > 0) {
        agreementExists();
      } else {
        setLoading(false);
      }
    });
  }, [agreementExists]);

  if (loading && showCancel) {
    return (
      <div>
        <Spinner />
        <Button
          variant="secondary"
          onClick={() => {
            dataMobilepay.cancelAgreement();
          }}
          block
          label={<FormattedMessage id="denmark.mobilepay.story.create.abort" />}
        />
      </div>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="create-agreement">
      <div className="logo-center">
        <MobilePayLogo size={250} />
      </div>
      <p>
        <TranslatedText id="denmark.mobilepay.story.create.text" />
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !monthlyContext.state.phone ||
            !monthlyContext.state.withdrawalDay
          ) {
            return;
          }
          dataMobilepay
            .createAgreement({
              phone: monthlyContext.state.phone,
              targetPaymentDay: monthlyContext.state.withdrawalDay,
              userRedirect,
            })
            .then((resp) => {
              window.location.href = resp.signingLink;
            });
        }}
      >
        <Card>
          <TextInput
            label={intl.formatMessage(messages.phoneLabel)}
            value={monthlyContext.state.phone ?? ""}
            onChange={(phone) => monthlyContext.setState({ phone })}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.phoneRequired)),
            ]}
          />
          <Select
            alternatives={daysOfMonth.map((day) => {
              return {
                text: day.toString(),
                value: day,
              };
            })}
            label={intl.formatMessage(messages.dayLabel)}
            placeholder={intl.formatMessage(messages.dayPlaceholder)}
            onChange={(day) =>
              monthlyContext.setState({ withdrawalDay: day.value })
            }
            value={
              monthlyContext.state.withdrawalDay
                ? {
                    text: monthlyContext.state.withdrawalDay.toString(),
                    value: monthlyContext.state.withdrawalDay,
                  }
                : undefined
            }
          />
        </Card>
        <Button
          block
          type="submit"
          label={<TranslatedText id="denmark.mobilepay.story.create.button" />}
        />
      </Form>
    </div>
  );
};
