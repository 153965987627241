import React, { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Story } from "@lysaab/ui-2";
import { IskHome } from "./IskHome";
import { IskTransfer } from "./IskTransfer";
import { IskReceipt } from "./IskReceipt";
import { IskSign } from "./IskSign";
import { IskTransferContextProvider } from "./IskTransferContext";
import { IskConfirm } from "./IskConfirm";
import "./IskTransfer.scss";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { defineMessages, useIntl } from "react-intl";
import { PageStripped } from "../../../../pages/PageStripped";
import { Route } from "../../../../components/route/Route";
import { Switch } from "../../../../components/route/Switch";
import { DEPOSITS_OVERVIEW_URL } from "../../../../pages/deposits/overview/Recommendation";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";

export const ISK_TRANSFER_URL = "/isk-transfer";

export const BASE_ROUTES = {
  HOME: `${ISK_TRANSFER_URL}`,
  ISK: `${ISK_TRANSFER_URL}/info`,
  CONFIRM: `${ISK_TRANSFER_URL}/confirm`,
  SIGN: `${ISK_TRANSFER_URL}/sign`,
  RECEIPT: `${ISK_TRANSFER_URL}/receipt`,
};

const messages = defineMessages({
  header: {
    id: "sweden.isk.move.header",
    defaultMessage: "ISK transfer",
  },
  ariaProgressLabel: {
    id: "sweden.isk.move.ariaProgressLabel",
  },
});

export function IskTransferStory() {
  const location = useLocation();
  const history = useHistory();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [currentIndex, ROUTES, , storyLength] = useStoryValues(BASE_ROUTES);

  const PROGRESS = {
    [ROUTES.HOME]: 100 / 3,
    [ROUTES.ISK]: (100 / 3) * 2,
    [ROUTES.CONFIRM]: (100 / 3) * 3,
    [ROUTES.SIGN]: (100 / 3) * 4,
  };

  const onBack = () => {
    if (location.pathname === ROUTES.HOME) {
      safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
    } else if (location.pathname === ROUTES.ISK) {
      safeNavigation(ROUTES.HOME);
    } else if (location.pathname === ROUTES.CONFIRM) {
      safeNavigation(ROUTES.ISK);
    } else if (location.pathname === ROUTES.SIGN) {
      safeNavigation(ROUTES.CONFIRM);
    } else if (location.pathname === ROUTES.RECEIPT) {
      safeNavigation(ROUTES.RECEIPT);
    }
  };

  const nextSign = useCallback(() => {
    history.push(ROUTES.RECEIPT);
  }, [ROUTES.RECEIPT, history]);

  return (
    <PageStripped>
      <div className="isk-transfer">
        <IskTransferContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={PROGRESS[location.pathname]}
            showBack={
              location.pathname !== ROUTES.RECEIPT &&
              location.pathname !== ROUTES.SIGN
            }
            showClose={true}
            transitionKey={(
              Object.values(ROUTES).indexOf(location.pathname) || 0
            ).toString()}
            onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            onBack={onBack}
          >
            <Switch
              location={location}
              {...{
                order: Object.values(ROUTES).indexOf(location.pathname) || 0,
              }}
            >
              <Route
                path={ROUTES.HOME}
                exact
                render={() => <IskHome next={() => history.push(ROUTES.ISK)} />}
              />
              <Route
                path={ROUTES.ISK}
                exact
                render={() => (
                  <IskTransfer next={() => history.push(ROUTES.CONFIRM)} />
                )}
              />
              <Route
                path={ROUTES.CONFIRM}
                exact
                render={() => (
                  <IskConfirm next={() => history.push(ROUTES.SIGN)} />
                )}
              />
              <Route
                path={ROUTES.SIGN}
                exact
                render={() => <IskSign next={nextSign} />}
              />
              <Route
                path={ROUTES.RECEIPT}
                exact
                render={() => <IskReceipt />}
              />
            </Switch>
          </Story>
        </IskTransferContextProvider>
      </div>
    </PageStripped>
  );
}
