import {
  MaxValidator,
  MinValidator,
  MoneyInput,
  ReactForecast,
  Slider,
} from "@lysaab/ui-2";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { InvestmentAccount } from "../../data/dataAccounts";
import { Autogiro } from "../../data/dataAutogiro";
import "./EditAllocationGraph.scss";
import { GetForecastGraphTexts } from "../../components/forecastGraphTexts/GetForecastGraphTexts";
import {
  AdviseAccount,
  SavingsHorizonLength,
} from "../../data/dataInvestments";
import { useAccountAllocation } from "../../hooks/useAccountAllocation";

interface Props {
  account?: InvestmentAccount;
  adviseAccount?: AdviseAccount;
  autogiros: Autogiro[];
  risk: number;
  setRisk: (risk: number) => void;
}

const messages = defineMessages({
  max: {
    id: "generic.allocation.max-investment",
    description: "Edit allocation page - max validator text",
    defaultMessage: "We cannot calculate a prognosis based on such large sums",
  },
  min: {
    id: "generic.allocation.min-investment",
    description: "Edit allocation page - min validator text",
    defaultMessage: "We cannot calculate a prognosis based on that amount",
  },
  accountWorth: {
    id: "allocation.graph.accountWorth",
    description: "Edit allocation page - account worth",
    defaultMessage: "Worth",
  },
  monthly: {
    id: "allocation.graph.monthly",
    description: "Edit allocation page - account monthly",
    defaultMessage: "Monthly savings",
  },
  placeholder: {
    id: "allocation.validation.placeholder",
    description: "Edit allocation page - initial value example input",
    defaultMessage: "e.g 1,000 EUR",
  },
  positive: {
    id: "allocation.validation.monthly",
    description: "Edit allocation page - monthly min validation text",
    defaultMessage: "Must be a positive integer",
  },
  target: {
    id: "allocation.graph.target",
    description: "Edit allocation page - target allocation header",
    defaultMessage: "Target allocation",
  },
  values: {
    id: "allocation.graph.values",
    description: "Edit allocation page - target allocation values",
    defaultMessage: "{stocks} % stocks, {bonds} % bonds",
  },
});

export const MIN_MONTHLY = 0;
export const MIN_ALLOCATION_INVESTMENT = 1000;
export const MAX_ALLOCATION_INVESTMENT = 100000000000;

export const EditAllocationGraph: React.FunctionComponent<Props> = ({
  account,
  adviseAccount,
  autogiros,
  risk,
  setRisk,
}) => {
  const [initial, setInitial] = useState("0");
  const [monthly, setMonthly] = useState("0");
  const [name, setName] = useState("");
  const l10nContext = useContext(LocalizationContext);
  const intl = useIntl();
  const maxRisk = useRef<number>();
  const accountAllocation = useAccountAllocation(account?.accountId);

  useEffect(() => {
    if (!account || !accountAllocation) {
      return;
    }
    const autogiro = autogiros.find(
      (item) => item.accountId === account.accountId
    );
    setInitial(account?.worth.toString() ?? "10000");
    setRisk(accountAllocation?.takenRisk ?? 0);
    setName(account?.name ?? "");
    setMonthly(autogiro?.amount.toString() ?? "0");
    maxRisk.current = undefined;
  }, [account, autogiros, setRisk, accountAllocation]);

  const getTexts = useCallback(
    () => GetForecastGraphTexts(intl, l10nContext.state.currency),
    [intl, l10nContext.state.currency]
  );

  if (!account || !adviseAccount) {
    return <div className="edit-allocation-graph" />;
  }

  return (
    <div className="edit-allocation-graph">
      <div className="edit-allocation-settings">
        <div className="edit-allocation-settings-worth">
          <h5>
            {intl.formatMessage(messages.accountWorth)} - {name}
          </h5>
          <MoneyInput
            currency={l10nContext.state.currency}
            value={initial}
            onChange={(value) => setInitial(value.toString())}
            placeholder="10 000"
            validators={[
              new MinValidator(
                MIN_ALLOCATION_INVESTMENT,
                intl.formatMessage(messages.min, {
                  min: MIN_ALLOCATION_INVESTMENT,
                })
              ),
              new MaxValidator(
                MAX_ALLOCATION_INVESTMENT,
                intl.formatMessage(messages.max, {
                  max: MAX_ALLOCATION_INVESTMENT,
                })
              ),
            ]}
          />
        </div>

        <div className="edit-allocation-settings-monthly">
          <h5>{intl.formatMessage(messages.monthly)}</h5>
          <MoneyInput
            currency={l10nContext.state.currency}
            value={monthly}
            onChange={(value) => setMonthly(value.toString())}
            placeholder={intl.formatMessage(messages.placeholder)}
            validators={[
              new MinValidator(
                MIN_MONTHLY,
                intl.formatMessage(messages.positive)
              ),
            ]}
          />
        </div>

        <div className="edit-allocation-settings-risk">
          <Slider
            label={
              <>
                <strong>{intl.formatMessage(messages.target)}</strong> -{" "}
                <i>
                  {intl.formatMessage(messages.values, {
                    rawRisk: risk,
                    stocks: intl.formatNumber(risk / 100, {
                      style: "percent",
                    }),
                    bonds: intl.formatNumber((100 - risk) / 100, {
                      style: "percent",
                    }),
                  })}
                </i>
              </>
            }
            min={0}
            max={100}
            value={risk}
            onChange={(value) => {
              if (adviseAccount.savingsHorizon === SavingsHorizonLength.SHORT) {
                setRisk(Math.min(value, 50));
              } else {
                setRisk(Math.min(value, 100));
              }
            }}
            risk
          />
        </div>
      </div>

      <ReactForecast
        initial={initial}
        interval="20"
        monthly={monthly}
        risk={risk.toString()}
        getTexts={getTexts()}
        currency={l10nContext.state.currency}
        locale={l10nContext.getLocale()}
      />
    </div>
  );
};
