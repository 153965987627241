import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { CardList, CardListItem, Spinner, NewIcon, Button } from "@lysaab/ui-2";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import {
  CompanyInformation,
  CoporateUser,
  dataCorporate,
} from "../../../../../../data/dataCorporate";
import { UserManagementContext } from "../UserManagementContext";
import { FormattedMessage } from "react-intl";
import { UserContext } from "../../../../../../context/UserContext";
import "./UserManagementSelectUser.scss";

interface Props {
  add: () => void;
  edit: () => void;
}

export const UserManagementSelectUser: VoidFunctionComponent<Props> = ({
  add,
  edit,
}) => {
  const userManagementContext = useContext(UserManagementContext);
  const userContext = useContext(UserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<CompanyInformation>();

  useEffect(() => {
    setLoading(true);
    dataCorporate
      .getCompanyData()
      .then(setCompanyData)
      .finally(() => setLoading(false));
  }, [companyData]);

  if (loading) {
    return <Spinner />;
  }

  if (!companyData) {
    return null;
  }

  const editUser = (user: CoporateUser) => {
    userManagementContext.setState({ selectedUser: user });
    edit();
  };

  return (
    <GridRow className="user-mangement-select-user">
      <GridCol xsmall={12}>
        <h3>
          <FormattedMessage id="sweden.userManagement.user.list.title" />
        </h3>
        <p>
          <FormattedMessage id="sweden.userManagement.user.list.body" />
        </p>
      </GridCol>
      <GridCol xsmall={12}>
        <CardList>
          {companyData.users.map((user) => (
            <CardListItem key={user.identificationNumber}>
              <button className="list-item" onClick={() => editUser(user)}>
                {user.name}
                <NewIcon.Edit size={22} />
              </button>
            </CardListItem>
          ))}
        </CardList>
      </GridCol>

      {userContext.state.readOnly ? null : (
        <GridCol xsmall={12}>
          <Button
            block
            onClick={() => {
              userManagementContext.setState({
                selectedUser: undefined,
                updatedUser: undefined,
              });
              add();
            }}
            label={<FormattedMessage id="sweden.userManagement.add.user" />}
          />
        </GridCol>
      )}
    </GridRow>
  );
};
