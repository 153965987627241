import { Button } from "@lysaab/ui-2/components/buttons/Button";
import React, { VoidFunctionComponent } from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { SavingsAccountIntroCard } from "../../components/savingsAccountIntroCard/SavingsAccountIntroCard";

interface Props {
  next: () => void;
}

export const Intro: VoidFunctionComponent<Props> = ({ next }) => {
  return (
    <div>
      <SavingsAccountIntroCard
        navigationButton={
          <Button
            onClick={() => next()}
            block
            label={
              <TranslatedText id="sweden.createAccount.savingsAccountIntroCard.createSavingsButton" />
            }
          />
        }
      />
    </div>
  );
};
