import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { Card, Button, SNACKBAR_TYPES, Spinner, useFlash } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Amount } from "../../../../components/amount/Amount";
import { StaticInput } from "../../../../components/staticInput/StaticInput";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  dataWithdrawals,
  getBankText,
  PERIODIC_TYPE,
  PutPeriodicWithdrawalRequest,
} from "../../../../data/dataWithdrawals";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { WithdrawalPeriodicContext } from "../WithdrawalPeriodicContext";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../WithdrawalPeriodicStory";

const messages = defineMessages({
  receiver: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.receiver",
  },
  sender: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.sender",
  },
  paymentMethod: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.paymentMethod",
  },
  paymentMethodAmount: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.paymentMethod.amount",
  },
  paymentMethodPercent: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.paymentMethod.percent",
  },
  paymentMethodTargetDate: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.paymentMethod.targetDate",
  },
  amount: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.amount",
  },
  percent: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.percent",
  },
  targetDate: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.targetDate",
  },
  paymentDate: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.label.paymentDate",
  },
  valuePaymentDate: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.value.paymentDate",
  },
  snackbarError: {
    id: "withdrawalPeriodic.confirmPeriodicWithdrawal.error.snackbar",
  },
});

interface Props {
  next: () => void;
}

export const ConfirmPeriodicWithdrawal: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const intl = useIntl();
  const pushFlash = useFlash();
  const safeNavigation = useSafeNavigation();
  const withdrawalPeriodicContext = useContext(WithdrawalPeriodicContext);
  const localizationContext = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);

  const submit = useCallback(() => {
    if (
      !withdrawalPeriodicContext.state.paymentMethod ||
      !withdrawalPeriodicContext.state.lysaAccount ||
      !withdrawalPeriodicContext.state.externalAccount ||
      !withdrawalPeriodicContext.state.paymentDay
    ) {
      return;
    }

    const withdrawal: PutPeriodicWithdrawalRequest = {
      type: withdrawalPeriodicContext.state.paymentMethod.value,
      accountId: withdrawalPeriodicContext.state.lysaAccount.accountId,
      externalBankAccount:
        withdrawalPeriodicContext.state.externalAccount.externalBankAccount,
      paymentDay: withdrawalPeriodicContext.state.paymentDay.value,
    };
    switch (withdrawalPeriodicContext.state.paymentMethod.value) {
      case PERIODIC_TYPE.FIXED_AMOUNT: {
        if (!withdrawalPeriodicContext.state.FIXED_AMOUNT) {
          return;
        }
        withdrawal.amount = withdrawalPeriodicContext.state.FIXED_AMOUNT;
        break;
      }
      case PERIODIC_TYPE.PERCENTAGE: {
        if (!withdrawalPeriodicContext.state.PERCENTAGE) {
          return;
        }
        withdrawal.amount =
          withdrawalPeriodicContext.state.PERCENTAGE.value.toString();
        break;
      }
      case PERIODIC_TYPE.TARGET_DATE: {
        if (!withdrawalPeriodicContext.state.TARGET_DATE) {
          return;
        }
        withdrawal.endDate = DateTime.fromJSDate(
          withdrawalPeriodicContext.state.TARGET_DATE
        ).toFormat("yyyy-MM-dd");
        break;
      }
      default: {
        return;
      }
    }

    setLoading(true);
    dataWithdrawals
      .putPeriodicWithdrawal(withdrawal)
      .then(next)
      .catch(() => {
        pushFlash({
          text: intl.formatMessage(messages.snackbarError),
          type: SNACKBAR_TYPES.ERROR,
        });
      })
      .finally(() => setLoading(false));
  }, [
    intl,
    next,
    pushFlash,
    withdrawalPeriodicContext.state.FIXED_AMOUNT,
    withdrawalPeriodicContext.state.PERCENTAGE,
    withdrawalPeriodicContext.state.TARGET_DATE,
    withdrawalPeriodicContext.state.externalAccount,
    withdrawalPeriodicContext.state.lysaAccount,
    withdrawalPeriodicContext.state.paymentDay,
    withdrawalPeriodicContext.state.paymentMethod,
  ]);

  useEffect(() => {
    if (
      !withdrawalPeriodicContext.state.lysaAccount ||
      !withdrawalPeriodicContext.state.externalAccount ||
      !withdrawalPeriodicContext.state.paymentMethod
    ) {
      safeNavigation(getNavLink(WITHDRAWALS_PERIODIC_STORY_URL));
    }
  }, [safeNavigation, withdrawalPeriodicContext]);

  if (
    !withdrawalPeriodicContext.state.lysaAccount ||
    !withdrawalPeriodicContext.state.externalAccount ||
    !withdrawalPeriodicContext.state.paymentMethod ||
    loading
  ) {
    return <Spinner />;
  }

  return (
    <>
      <h2>
        <FormattedMessage id="withdrawalPeriodic.confirmPeriodicWithdrawal.header" />
      </h2>
      <Card>
        <StaticInput
          sideBySide
          label={intl.formatMessage(messages.sender)}
          value={`${
            withdrawalPeriodicContext.state.lysaAccount.name
          } - ${intl.formatNumber(
            withdrawalPeriodicContext.state.lysaAccount.worth,
            {
              style: "currency",
              currency: localizationContext.state.currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}`}
        />
        <StaticInput
          sideBySide
          label={intl.formatMessage(messages.receiver)}
          value={getBankText(withdrawalPeriodicContext.state.externalAccount)}
        />

        {withdrawalPeriodicContext.state.FIXED_AMOUNT &&
          withdrawalPeriodicContext.state.paymentMethod.value ===
            PERIODIC_TYPE.FIXED_AMOUNT && (
            <>
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentMethod)}
                value={intl.formatMessage(messages.paymentMethodAmount)}
              />
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.amount)}
              >
                <Amount
                  amount={Number(withdrawalPeriodicContext.state.FIXED_AMOUNT)}
                  decimals={2}
                />
              </StaticInput>
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentDate)}
                value={intl.formatMessage(messages.valuePaymentDate, {
                  date: withdrawalPeriodicContext.state.paymentDay.value,
                })}
              />
            </>
          )}

        {withdrawalPeriodicContext.state.PERCENTAGE &&
          withdrawalPeriodicContext.state.paymentMethod.value ===
            PERIODIC_TYPE.PERCENTAGE && (
            <>
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentMethod)}
                value={intl.formatMessage(messages.paymentMethodPercent)}
              />
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.percent)}
                value={withdrawalPeriodicContext.state.PERCENTAGE.text}
              />
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentDate)}
                value={intl.formatMessage(messages.valuePaymentDate, {
                  date: withdrawalPeriodicContext.state.paymentDay.value,
                })}
              />
            </>
          )}

        {withdrawalPeriodicContext.state.TARGET_DATE &&
          withdrawalPeriodicContext.state.paymentMethod.value ===
            PERIODIC_TYPE.TARGET_DATE && (
            <>
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentMethod)}
                value={intl.formatMessage(messages.paymentMethodTargetDate)}
              />
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.targetDate)}
                value={DateTime.fromJSDate(
                  withdrawalPeriodicContext.state.TARGET_DATE
                ).toISODate()}
              />
              <StaticInput
                sideBySide
                label={intl.formatMessage(messages.paymentDate)}
                value={intl.formatMessage(messages.valuePaymentDate, {
                  date: withdrawalPeriodicContext.state.paymentDay.value,
                })}
              />
            </>
          )}
      </Card>
      <Button
        onClick={submit}
        block
        label={
          <FormattedMessage id="withdrawalPeriodic.confirmPeriodicWithdrawal.next" />
        }
      />
    </>
  );
};
