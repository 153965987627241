import React, { useContext } from "react";
import { Redirect } from "react-router";
import {
  OnCompleteData,
  PaymentInitiation,
} from "../../../../components/klarna/paymentInitiation/PaymentInititation";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { KlarnaContext } from "../KlarnaContext";
import {
  BASE_ROUTES,
  fallbackLocationDescriptorFunction,
} from "../KlarnaStory";

interface Props {
  next: (data: OnCompleteData) => void;
  getPaymentInitiationFailedLocation?: fallbackLocationDescriptorFunction;
}

export function PaymentInitiationWrapper({
  next,
  getPaymentInitiationFailedLocation,
}: Props) {
  const klarnaContext = useContext(KlarnaContext);
  const amount = klarnaContext.state.amount;
  const selectedKlarnaAccount = klarnaContext.state.selectedKlarnaAccount;
  const selectedLysaAccount = klarnaContext.state.selectedLysaAccount;

  if (!amount || !selectedKlarnaAccount || !selectedLysaAccount) {
    return <Redirect to={getNavLink(BASE_ROUTES.LYSA_ACCOUNT)} />;
  }
  return (
    <PaymentInitiation
      accountId={selectedLysaAccount.accountId}
      amount={amount}
      klarnaAccount={selectedKlarnaAccount}
      onComplete={next}
      getPaymentInitiationFailedDescriptor={getPaymentInitiationFailedLocation}
    />
  );
}
