import { useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";
import { Tracker } from "../utils/Tracker";
import { useIsSignedIn } from "./useIsSignedIn";
import { EventTracker } from "../components/eventTracker/EventTracker";
import { TrackerEvent } from "../data/dataCustomerTracking";

export function useActivityTracker() {
  const prevPathname = useRef<string>();
  const location = useLocation();
  const isSignedIn = useIsSignedIn();

  /**
   * Activity tracking
   */
  useEffect(() => {
    if (prevPathname.current !== location.pathname) {
      Tracker.track(location.pathname + location.search);
      EventTracker.track({ event: TrackerEvent.PAGE });
      prevPathname.current = location.pathname;
    }
  }, [isSignedIn, location]);
}
