import React, { useState, useCallback, useEffect, useContext } from "react";
import cx from "classnames";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { useInView } from "react-intersection-observer";
import "./PositionsExample.scss";
import { Status, Retry } from "../../../../components/retry/Retry";
import { FundType, Fund, dataFunds } from "../../../../data/dataFunds";
import { Amount } from "../../../../components/amount/Amount";
import { FormattedMessage } from "react-intl";
import { Disclaimer } from "../../Disclaimer";
import {
  dataInvestments,
  GetAllocationResponse,
} from "../../../../data/dataInvestments";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useAccountAllocation } from "../../../../hooks/useAccountAllocation";
import { PositionsExampleList } from "./PositionsExampleList";
import { PositionsExpandableCard } from "../components/PositionsExpandableCard";

interface Props {
  account: InvestmentAccount | undefined;
}

function checkPositions(allocations?: GetAllocationResponse, funds?: Fund[]) {
  if (!allocations || !funds) {
    return {
      hasStocks: false,
      hasBonds: false,
      funds: [] as Fund[],
    };
  }

  return funds.reduce(
    (data, fund) => {
      const shareClass = fund.fundShareClasses.find(({ isin }) =>
        Object.keys(allocations).includes(isin)
      );
      if (shareClass) {
        data.hasStocks = data.hasStocks || fund.fundType === FundType.STOCKS;
        data.hasBonds = data.hasBonds || fund.fundType === FundType.BONDS;
        data.funds.push({ ...fund, isin: shareClass.isin });
      }
      return data;
    },
    {
      hasStocks: false,
      hasBonds: false,
      funds: [] as Fund[],
    }
  );
}

export const PositionsExample: React.FC<Props> = ({ account }) => {
  const localizationContext = useContext(LocalizationContext);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [holdings, setHoldings] = useState<Fund[]>();
  const [allocations, setAllocations] = useState<GetAllocationResponse>();
  const { hasBonds, hasStocks, funds } = checkPositions(allocations, holdings);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const accountAllocation = useAccountAllocation(account?.accountId);

  const load = useCallback(() => {
    if (!localizationContext.state.country || !account || !accountAllocation) {
      return;
    }

    Promise.all([
      dataInvestments
        .getAllocation(
          accountAllocation.takenRisk,
          accountAllocation.investmentType,
          localizationContext.state.country
        )
        .then(setAllocations),
      dataFunds.getHoldings().then(setHoldings),
    ])
      .then(() => setStatus(Status.SUCCESS))
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [account, accountAllocation, localizationContext.state.country]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);

    setTimeout(load, 600);
  }, [load]);

  useEffect(() => {
    if (!inView || !account || !localizationContext.state.country) {
      return;
    }
    load();
  }, [account, inView, load, localizationContext.state.country]);

  const cashElem = account?.uninvestedMoney ? (
    <div className="cash-assets">
      <FormattedMessage id="accountPage.positions.example.cashAssets" />
      : <Amount amount={account.uninvestedMoney} />
    </div>
  ) : null;

  return (
    <section
      className={cx("account-page-positions-example", {
        "single-asset": !(hasStocks && hasBonds),
      })}
      ref={ref}
    >
      <h2>
        <FormattedMessage id="accountPage.positions.example.holdings" />
      </h2>
      <Retry retry={retry} status={status}>
        <div className="account-page-positions-example-funds-wrapper">
          {account && funds && hasStocks && (
            <div className="account-page-positions-example-stocks">
              {cashElem}
              <PositionsExpandableCard>
                <h4>
                  <FormattedMessage id="accountPage.positions.example.stocks" />
                </h4>
                <PositionsExampleList
                  funds={funds}
                  fundType={FundType.STOCKS}
                />
              </PositionsExpandableCard>
            </div>
          )}
          {account && funds && hasBonds && (
            <div className="account-page-positions-example-bonds">
              {cashElem}
              <PositionsExpandableCard>
                <h4>
                  <FormattedMessage id="accountPage.positions.example.bonds" />
                </h4>
                <PositionsExampleList funds={funds} fundType={FundType.BONDS} />
              </PositionsExpandableCard>
            </div>
          )}
        </div>
      </Retry>

      <Disclaimer>
        <FormattedMessage id="accountPage.positions.example.disclaimer" />
      </Disclaimer>
    </section>
  );
};
