import { CardList, CardListItem, LysaLink } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserContext } from "../../../context/UserContext";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { CHANGE_EMAIL_PAGE_URL } from "../../changeEmail/ChangeEmailPage";
import { CHANGE_PASSWORD_PAGE_URL } from "../../changePassword/ChangePasswordPage";
import { CHANGE_TWO_FA_PAGE_URL } from "../../changeTwoFa/ChangeTwoFaPage";
import { END_INVESTMENT_URL } from "../../endInvestment/EndInvestment";

export const ProfileLinks = () => {
  const userContext = useContext(UserContext);
  return (
    <React.Fragment>
      <h2>
        <TranslatedText id="settings.profileLinks.header" />
      </h2>
      <CardList>
        {!userContext.state.readOnly && (
          <RouteAwareToggle path={getNavLink(CHANGE_EMAIL_PAGE_URL)}>
            <CardListItem>
              <LysaLink component={Link} to={getNavLink(CHANGE_EMAIL_PAGE_URL)}>
                <FormattedMessage id="settings.profileLinks.changeEmail" />
              </LysaLink>
            </CardListItem>
          </RouteAwareToggle>
        )}
        <RouteAwareToggle path={getNavLink(CHANGE_PASSWORD_PAGE_URL)}>
          <CardListItem>
            <LysaLink
              component={Link}
              to={getNavLink(CHANGE_PASSWORD_PAGE_URL)}
            >
              <FormattedMessage id="settings.profileLinks.changePassword" />
            </LysaLink>
          </CardListItem>
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(CHANGE_TWO_FA_PAGE_URL)}>
          <CardListItem>
            <LysaLink component={Link} to={getNavLink(CHANGE_TWO_FA_PAGE_URL)}>
              <FormattedMessage id="settings.profileLinks.changeTwoFa" />
            </LysaLink>
          </CardListItem>
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(END_INVESTMENT_URL)}>
          <CardListItem>
            <LysaLink component={Link} to={getNavLink(END_INVESTMENT_URL)}>
              <FormattedMessage id="settings.profileLinks.endInvestment" />
            </LysaLink>
          </CardListItem>
        </RouteAwareToggle>
      </CardList>
    </React.Fragment>
  );
};
