import React, { ElementType, FunctionComponent } from "react";
import cx from "classnames";

import "./Grid.scss";

export enum JustifyContentValues {
  START = "flex-start",
  END = "flex-end",
  CENTER = "center",
  SPACE_AROUND = "space-around",
  SPACE_BETWEEN = "space-between",
  SPACE_EVENLY = "space-evenly",
}

export enum AlignValues {
  STRETCH = "stretch",
  START = "flex-start",
  END = "flex-end",
  CENTER = "center",
  BASELINE = "baseline",
}
interface Props {
  as?: ElementType;
  justifyContent?: JustifyContentValues;
  alignItems?: AlignValues;
  className?: string;
  flexEvenly?: boolean;
}

export const GridRow: FunctionComponent<Props> = ({
  children,
  as: Component = "div",
  justifyContent,
  alignItems,
  className,
  flexEvenly,
}) => {
  const classes = cx(
    "lysa-ui-row",
    className,
    {
      "justify-content-start": justifyContent === JustifyContentValues.START,
      "justify-content-end": justifyContent === JustifyContentValues.END,
      "justify-content-center": justifyContent === JustifyContentValues.CENTER,
      "justify-content-around":
        justifyContent === JustifyContentValues.SPACE_AROUND,
      "justify-content-between":
        justifyContent === JustifyContentValues.SPACE_BETWEEN,
      "justify-content-evenly":
        justifyContent === JustifyContentValues.SPACE_EVENLY,
    },
    {
      "align-items-start": alignItems === AlignValues.START,
      "align-items-end": alignItems === AlignValues.END,
      "align-items-center": alignItems === AlignValues.CENTER,
      "align-items-baseline": alignItems === AlignValues.BASELINE,
      "align-items-stretch": alignItems === AlignValues.STRETCH,
    },
    {
      [`flex-evenly`]: !!flexEvenly,
    }
  );

  return <Component className={classes}>{children}</Component>;
};
