import { encode } from "@lysaab/ui-2";
import { API } from "@lysaab/ui-2/network/API";
import { ExternalSavingsAccountId, SavingsAccountId } from "./dataAccounts";

export interface ExternalAccruedInterestResponse {
  accruedInterest: number;
  accountId: SavingsAccountId;
  fee: number;
}

interface ExternalPendingFundTransferResponse {
  amount: number;
  sendingAccount: ExternalSavingsAccountId;
  receivingAccount: ExternalSavingsAccountId;
}

export interface ExternalRebalancingTransferResponse {
  accountId: SavingsAccountId;
  amount: number;
  createdAt: string;
}

export const dataSavingsAccountDistribution = {
  getMultipleAccruedInterest: (accountIds: SavingsAccountId[]) => {
    const params = new URLSearchParams();

    accountIds.forEach((accountId) => {
      params.append("accountIds", encodeURIComponent(accountId));
    });
    return API.get<ExternalAccruedInterestResponse[]>(
      `/savings-account-distribution/interest/accrued?${params.toString()}`
    );
  },
  getAccruedInterest: (accountId: SavingsAccountId) => {
    return API.get<ExternalAccruedInterestResponse>(
      encode`/savings-account-distribution/interest/accrued/${accountId}`
    );
  },
  getPendingTransactions: (accountId: SavingsAccountId) => {
    return API.get<ExternalPendingFundTransferResponse[]>(
      encode`/savings-account-distribution/transactions/pending/${accountId}`
    );
  },
  getOngoingBalancing: (accountId: SavingsAccountId) => {
    return API.get<ExternalRebalancingTransferResponse | null>(
      encode`/savings-account-distribution/account-balancing/ongoing/${accountId}`
    );
  },
};
