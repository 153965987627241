import React, {
  FunctionComponent,
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  LysaFormRef,
  Form,
  Button,
  RadioGroup,
  Alternative,
  RequiredValidator,
  SwedishTinValidator,
  Card,
  CircleButton,
  Select,
  TextInput,
} from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";
import { dataCreateAccount } from "../../../../../../data/dataCreateAccount";
import { Retry, Status } from "../../../../../../components/retry/Retry";
import { countries } from "../../../../countries";
import { useHistory } from "react-router";
import "./Candidate.scss";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";

const countryAlternatives = countries.map((country) => ({
  text: country.label,
  value: country.value,
}));

export const ROUTE = "/horizon";
interface Props {
  futurKycRoute: string;
}

export const messages = defineMessages({
  required: {
    id: "sweden.kf.candidate.required",
  },
  otherInsuree: {
    id: "sweden.kf.candidate.otherInsuree",
  },
  id: {
    id: "sweden.kf.candidate.id",
  },
  incorrectId: {
    id: "sweden.kf.candidate.incorrectId",
  },
  addId: {
    id: "sweden.kf.candidate.addId",
  },
  citizenship: {
    id: "sweden.kf.candidate.citizenship",
  },
  country: {
    id: "sweden.kf.candidate.country",
  },
  countryRequired: {
    id: "sweden.kf.candidate.countryRequired",
  },
  other: {
    id: "sweden.kf.candidate.other",
  },
});

export const Candidate: FunctionComponent<Props> = ({ futurKycRoute }) => {
  const formRef = useRef<LysaFormRef>();

  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  const [alternatives, setAlternatives] = useState<Alternative<string>[]>([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const history = useHistory();
  const intl = useIntl();
  const OTHER = useMemo(
    () => ({
      text: intl.formatMessage(messages.other),
      value: "other",
    }),
    [intl]
  );

  const load = useCallback(() => {
    dataCreateAccount
      .getKFCandidates()
      .then((data) => {
        const alternatives = data.map((candidate) => ({
          text: `${candidate.name} - ${candidate.tin}`,
          value: candidate.tin,
        }));
        alternatives.push(OTHER);
        setAlternatives(alternatives);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [OTHER]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    const timer = setTimeout(load, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [load]);

  useEffect(load, [load]);

  let value;

  if (isManualInput) {
    value = OTHER;
  } else {
    value = alternatives.find(
      (alternative) => alternative.value === kfState.identificationNumber
    );
  }

  return (
    <div className="create-account-corporation-candidate">
      <Retry retry={retry} status={status}>
        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();
            if (formRef.current?.isValid) {
              history.push(futurKycRoute);
            }
          }}
        >
          <div className="create-account-corporation-candidate-top">
            <h1>
              <TranslatedText id="sweden.kf.candidate.header" />
            </h1>
            <p className="candidate-subtitle">
              <TranslatedText id="sweden.kf.candidate.ingress" />
            </p>
          </div>
          <Card>
            <RadioGroup
              header=""
              onChange={(candidate) => {
                if (candidate.value === OTHER.value) {
                  setIsManualInput(true);
                  setKFState({
                    identificationNumber: "",
                  });
                } else {
                  setIsManualInput(false);
                  setKFState({
                    identificationNumber: candidate.value,
                  });
                }
              }}
              value={value}
              alternatives={alternatives}
              validators={[
                new RequiredValidator(intl.formatMessage(messages.required)),
              ]}
            />

            {isManualInput ? (
              <TextInput
                label={intl.formatMessage(messages.required)}
                value={kfState.identificationNumber || ""}
                onChange={(event: string) =>
                  setKFState({ identificationNumber: event })
                }
                placeholder={intl.formatMessage(messages.otherInsuree)}
                validators={[
                  new SwedishTinValidator(
                    intl.formatMessage(messages.incorrectId)
                  ),
                  new RequiredValidator(
                    intl.formatMessage(messages.incorrectId)
                  ),
                ]}
              />
            ) : null}
          </Card>
          <Card>
            {kfState.citizenships.map((citizenship, idx, arr) => (
              <div className="repeat-country" key={idx + citizenship}>
                <Select
                  label={intl.formatMessage(messages.citizenship)}
                  placeholder={intl.formatMessage(messages.country)}
                  alternatives={countryAlternatives.filter(
                    (country) =>
                      !kfState.citizenships
                        .filter((country) => country !== citizenship)
                        .includes(country.value)
                  )}
                  value={countryAlternatives.find(
                    (country) => country.value === citizenship
                  )}
                  onChange={(value) => {
                    const citizenships = [...(kfState.citizenships || [])];
                    citizenships[idx] = value.value;
                    setKFState({ citizenships });
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage(messages.countryRequired)
                    ),
                  ]}
                />
                {arr.length > 1 && (
                  <CircleButton
                    type="button"
                    onClick={() => {
                      const citizenships = [...(kfState.citizenships || [])];
                      citizenships.splice(idx, 1);
                      setKFState({ citizenships });
                    }}
                    icon="Minus"
                  />
                )}
                {idx === arr.length - 1 && arr.length < 3 && (
                  <CircleButton
                    type="button"
                    onClick={() => {
                      const citizenships = [...(kfState.citizenships || [])];
                      citizenships.push("");
                      setKFState({ citizenships });
                    }}
                    icon="Plus"
                  />
                )}
              </div>
            ))}
          </Card>
          <div className="bottom-nav">
            <Button
              block
              type="submit"
              label={<TranslatedText id="sweden.kf.candidate.next" />}
            />
          </div>
        </Form>
      </Retry>
    </div>
  );
};
