import React, { FunctionComponent, useReducer } from "react";
import {
  Alternative,
  InvestmentType,
  PendingHintCode,
  FailedHintCode,
} from "@lysaab/ui-2";
import {
  KFPresaleInfo,
  KFSigningStatusResponse,
  KFSigningStatusPendingResponse,
  KFSigningStatusRejectResponse,
  KFFuturSignResponse,
  KFLysaSignResponse,
} from "../../../../../data/dataCreateAccount";
import { AccountQuestions } from "../../../../../data/dataInvestments";
import { LysaCountry } from "@lysaab/countries";

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

export enum RiskPropensityValues {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

export enum InsurancePurpose {
  PENSION = "PENSION",
  OBJECTIVE = "OBJECTIVE",
  INVESTMENTS = "INVESTMENTS",
  OTHER = "OTHER",
}

export enum InitialPremiumAmount {
  AMOUNT0TO100K = "AMOUNT0TO100K",
  AMOUNT100KTO500K = "AMOUNT100KTO500K",
  AMOUNT500KTO1M = "AMOUNT500KTO1M",
  AMOUNT1MTO5M = "AMOUNT1MTO5M",
  AMOUNTABOVE5M = "AMOUNTABOVE5M",
}

export enum YearlyPremiumAmount {
  AMOUNT0TO100K = "AMOUNT0TO100K",
  AMOUNT100KTO500K = "AMOUNT100KTO500K",
  AMOUNT500KTO1M = "AMOUNT500KTO1M",
  AMOUNT1MTO5M = "AMOUNT1MTO5M",
  AMOUNTABOVE5M = "AMOUNTABOVE5M",
}

export enum SourceOfFunds {
  ONGOINGBUSINESS = "ONGOINGBUSINESS",
  RETURNONINVESTMENT = "RETURNONINVESTMENT",
  CORPORATIONSALE = "CORPORATIONSALE",
  DIVIDENDS = "DIVIDENDS",
  PROPERTYSALE = "PROPERTYSALE",
  OTHER = "OTHER",
}

export enum TypeOfBusiness {
  ACTIVEBUSINESS = "ACTIVEBUSINESS",
  FINANCIALINSTITUTE = "FINANCIALINSTITUTE",
  LISTEDCOMPANY = "LISTEDCOMPANY",
  NEWLYSTARTED = "NEWLYSTARTED",
  CHARITY = "CHARITY",
  OTHER = "OTHER",
}

export enum Purpose {
  SURPLUS_LIQUIDITY = "SURPLUS_LIQUIDITY",
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS",
}

export enum DepositInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",
}

export enum WithdrawalInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",
}

export const MIN_RECOMMENDED_ALLOCATION = 20;

/**
 * This is the state for all generic sweden data.
 */
export type KFAccountState = Partial<AccountQuestions> & {
  ongoing: boolean;
  presaleInfo?: KFPresaleInfo;

  /** Intro */
  taxConfirmation: Alternative<boolean>[];
  hasInvested?: Alternative<boolean>;
  hasTaxKnowledge?: Alternative<boolean>;
  hasTaxKnowledgeCheckbox?: boolean;
  hasBeneficiaryKnowledge?: Alternative<boolean>;
  hasBeneficiaryKnowledgeCheckbox?: boolean;
  hasOwnershipKnowledge?: Alternative<boolean>;
  hasOwnershipKnowledgeCheckbox?: boolean;

  /** Lysa kyc */
  depositInterval?: Alternative<DepositInterval>;
  withdrawalInterval?: Alternative<WithdrawalInterval>;
  purpose: Alternative<Purpose>[];

  /** Risk */
  riskImportance?: Alternative<RiskImportance>;
  riskReaction?: Alternative<RiskReaction>;
  riskPropensity?: Alternative<RiskPropensityValues>;

  /** Allocation */
  allocationSelectedRisk?: number;
  allocationInvestment: string;
  allocationMonthlyInvestment: string;
  allocationSelectedYear?: Alternative<string>;

  /** Investment Type */
  investmentType?: InvestmentType;

  /** Advice */
  takenRisk?: number;
  advicedRisk?: number;
  declaration?: string;

  /** Candidate */
  identificationNumber?: string;
  citizenships: string[];

  /** Futur kyc */
  insurancePurpose?: Alternative<InsurancePurpose>;
  insurancePurposeDescription: string;
  initialPremiumAmount?: Alternative<InitialPremiumAmount>;
  initialPremiumApproximateAmount?: string;
  yearlyPremiumAmount?: Alternative<YearlyPremiumAmount>;
  yearlyPremiumApproximateAmount?: string;
  sourceOfFunds: Alternative<SourceOfFunds>[];
  sourceOfFundsOtherDescription: string;
  typeOfBusiness?: Alternative<TypeOfBusiness>;
  corporateIsPassive?: Alternative<boolean>;
  legalEntityWith25PercentControl?: Alternative<boolean>;
  controllingEntityHasSoleNaturalOwner?: Alternative<boolean>;
  holderIsListedCorporation?: Alternative<boolean>;
  holderIsMajorityOwnedByListedCorporation?: Alternative<boolean>;

  signingId?: string;
  lysaSignResponse?: KFLysaSignResponse;
  signingStatus?:
    | KFSigningStatusResponse
    | KFSigningStatusPendingResponse
    | KFSigningStatusRejectResponse;

  futurSignResponse?: KFFuturSignResponse;
};

export function getBankIDMessages() {
  return () => ({
    qrInfo1: "Öppna BankID-appen",
    qrInfo2: 'Klicka på "QR-kod"',
    qrInfo3: "Rikta kamerna mot QR-koden",
    divider: "",
    buttonOpen: "Öppna BankID på denna enhet",
    buttonErrorHeader: "Något gick fel!",
    buttonRetry: "Försök igen",
  });
}

export const pendingBankIDMessages = {
  [PendingHintCode.NOCLIENT]: "Väntar på legitimering...",
  [PendingHintCode.OUTSTANDINGTRANSACTION]: "Väntar på legitimering...",
  [PendingHintCode.STARTED]: "Väntar på legitimering...",
  [PendingHintCode.USERSIGN]: "Väntar på legitimering...",
};

export const failedBankIDMessages = {
  [FailedHintCode.CANCELLED]: " ",
  [FailedHintCode.CERTIFICATEERR]: " ",
  [FailedHintCode.EXPIREDTRANSACTION]: " ",
  [FailedHintCode.STARTFAILED]: " ",
  [FailedHintCode.USERCANCEL]: " ",
};

// All types for the context. This includes setter methods
export interface KFAccountContextProps {
  state: KFAccountState;
  setState: (newState: Partial<KFAccountState>) => void;
}

// Create the context. (This will just create a template)
export const KFAccountContext = React.createContext<KFAccountContextProps>(
  {} as KFAccountContextProps
);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withKFAccount =
  <P extends object>(
    Component: React.ComponentType<P>
  ): React.FC<Omit<P, keyof KFAccountContextProps>> =>
  (props) =>
    (
      <KFAccountContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </KFAccountContext.Consumer>
    );

function stateReducer(
  state: KFAccountState,
  newState: Partial<KFAccountState>
) {
  return { ...state, ...newState } as KFAccountState;
}

export const KFAccountContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    ...defaultKFState,
  });

  return (
    <KFAccountContext.Provider value={{ state, setState }}>
      {children}
    </KFAccountContext.Provider>
  );
};

export const defaultKFState = {
  ongoing: false,
  taxConfirmation: [],
  allocationInvestment: "10000",
  allocationMonthlyInvestment: "1000",
  citizenships: [LysaCountry.SWEDEN],
  insurancePurposeDescription: "",
  sourceOfFunds: [],
  sourceOfFundsOtherDescription: "",
  purpose: [],
};
