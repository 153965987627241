import { Spinner } from "@lysaab/ui-2";
import React from "react";
import { CHUNK_LOAD_ERROR, handleChunkLoadError } from "./PreloadableImport";

interface Props {}

interface State {
  hasError: boolean;
}

export class ChunkLoadErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    return {
      hasError: error instanceof Error && error.name === CHUNK_LOAD_ERROR,
    };
  }

  componentDidCatch(error: Error) {
    handleChunkLoadError(error);
  }

  render() {
    if (this.state.hasError) {
      return <Spinner />;
    }

    return this.props.children;
  }
}
