import { useIntl } from "react-intl";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { VIRTUAL_ACCOUNT_PAGE_URL } from "../../../../pages/deposits/virtualAccount/VirtualAccountStory";
import {
  DepositMethod,
  RecommendableChannel,
} from "../../../../pages/deposits/overview/Recommendation";
import { Description } from "./Description";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { TranslatedText } from "../../../TranslatedText";
import { useContext } from "react";
import { LysaCountry } from "@lysaab/countries";
import { ExpandableContent } from "../../../expandableContent/ExpandableContent";
import { VirtualAccountIcon } from "./icons/VirtualAccountIcon";

const MIN_DEPOSITS: Record<LysaCountry, number> = {
  [LysaCountry.SWEDEN]: 1_000,
  [LysaCountry.DENMARK]: 1_000,
  [LysaCountry.FINLAND]: 100,
  [LysaCountry.GERMANY]: 100,
  [LysaCountry.SPAIN]: 100,
};

export function useVirtualAccountAlternative(
  isRecommended = false
): DepositMethod {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return {
    alternative: {
      header: intl.formatMessage({
        id: "deposits.overview.virtual-account.header",
      }),
      description: (
        <Description
          isRecommended={isRecommended}
          text={intl.formatMessage({
            id: "deposits.overview.virtual-account.body",
          })}
        />
      ),
      value: RecommendableChannel.VIRTUAL_ACCOUNT,
      logo: <VirtualAccountIcon size={40} />,
      expandableContent: (
        <ExpandableContent>
          <ul>
            <li>
              <TranslatedText
                id="deposits.overview.virtual-account.list.item-1"
                values={{
                  minDeposit: intl.formatNumber(
                    MIN_DEPOSITS[
                      localizationContext.state.country ?? LysaCountry.SWEDEN
                    ],
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  ),
                }}
              />
            </li>
            <li>
              <TranslatedText id="deposits.overview.virtual-account.list.item-2" />
            </li>
          </ul>
        </ExpandableContent>
      ),
    },
    link: getNavLink(VIRTUAL_ACCOUNT_PAGE_URL),
  };
}
