import React, { useContext, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { KFAccountContext } from "../../KFAccountContext";
import { SustainabilityImportance } from "../../../../../../../data/dataInvestments";
import { SustainabilityPreferenceQuestion } from "../../../../../../../pageComponents/sustainability/SustainabilityPreferenceQuestion";

interface Props {
  next(): void;
}

export const Preference: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const accountContext = useContext(KFAccountContext);

  if (
    accountContext.state.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return null;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityPreferenceQuestion
        sustainabilityPreference={accountContext.state.sustainabilityPreference}
        setSustainabilityPreference={(sustainabilityPreference) =>
          accountContext.setState({ sustainabilityPreference })
        }
      />
      <Button
        block
        type="submit"
        label={<FormattedMessage id="sweden.createAccount.preference.next" />}
      />
    </Form>
  );
};
