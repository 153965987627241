import { defineMessages } from "react-intl";

export const messages = defineMessages({
  investmentTypeBroadDesc: {
    id: "investmentType.broad.desc",
  },
  investmentTypeBroadAdditional: {
    id: "investmentType.broad.additional",
  },
  investmentTypeSustainableDesc: {
    id: "investmentType.sustainable.desc",
  },
  investmentTypeSustainableAdditional: {
    id: "investmentType.sustainable.additional",
  },
  investmentTypeSelect: {
    id: "investmentType.select.label",
  },
});
