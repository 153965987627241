import React, { VoidFunctionComponent } from "react";
import {
  Card,
  RequiredValidator,
  CheckboxGroup,
  Alternative,
} from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { MoneyOriginCorporation } from "../../../../data/dataKyc";

export const messages = defineMessages({
  moneyOriginHeader: {
    id: "kyc.moneyOrigin.corp.header",
  },
  moneyOriginRequired: {
    id: "kyc.moneyOrigin.corp.required",
  },
});

interface MessageWithId {
  id: string;
}

export const moneyOriginMessages = defineMessages<
  MoneyOriginCorporation,
  MessageWithId,
  Record<MoneyOriginCorporation, MessageWithId>
>({
  [MoneyOriginCorporation.TRADE]: {
    id: "kyc.MoneyOrigin.corp.alt.TRADE",
  },
  [MoneyOriginCorporation.CORPORATION_SALE]: {
    id: "kyc.MoneyOrigin.corp.alt.CORPORATION_SALE",
  },
  [MoneyOriginCorporation.PROPERTY_SALE]: {
    id: "kyc.MoneyOrigin.corp.alt.PROPERTY_SALE",
  },
  [MoneyOriginCorporation.DIVIDEND]: {
    id: "kyc.MoneyOrigin.corp.alt.DIVIDEND",
  },
});

interface Props {
  moneyOrigin?: MoneyOriginCorporation[];
  setMoneyOrigin: (moneyOrigin: MoneyOriginCorporation[]) => void;
}

export const MoneyOriginQuestion: VoidFunctionComponent<Props> = ({
  moneyOrigin,
  setMoneyOrigin,
}) => {
  const intl = useIntl();

  const alternatives = Object.values(MoneyOriginCorporation).map(
    (moneyOrigin) => {
      return {
        text: intl.formatMessage(moneyOriginMessages[moneyOrigin]),
        value: moneyOrigin,
      } as Alternative<MoneyOriginCorporation>;
    }
  );

  return (
    <Card>
      <CheckboxGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.moneyOriginHeader)}
        validators={[
          new RequiredValidator(
            intl.formatMessage(messages.moneyOriginRequired)
          ),
        ]}
        onChange={(moneyOrigin) =>
          setMoneyOrigin(moneyOrigin.map((origin) => origin.value))
        }
        values={alternatives.filter((alt) =>
          (moneyOrigin ?? []).includes(alt.value)
        )}
      />
    </Card>
  );
};
