import React, {
  VoidFunctionComponent,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  EntityType,
  PepState,
  PepType,
  requestToState,
  EMPTY_PEP_STATE,
} from "@lysaab/lysa-pep";
import {
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { UserContext } from "../../../../context/UserContext";
import { dataCorporate } from "../../../../data/dataCorporate";
import { dataKyc } from "../../../../data/dataKyc";
import { LegalEntityType } from "../../../../data/dataLogin";
import { IsPepQuestionCard } from "./PepCard";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../messages/MessagesPage";
import { useMultiPepContext } from "../../contexts/MultiPepContext";

interface Props {
  next: () => void;
}

export const IsPepPageWrapper: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);
  const [multiPepState, setMultiPepState] = useMultiPepContext();
  const [loading, setLoading] = useState(true);
  const country = localizationContext.state.country;
  const legalEntityType = userContext.state.legalEntityType;

  useEffect(() => {
    if (multiPepState.pepStates || !country) {
      setLoading(false);
      return;
    }

    dataKyc.getPep().then((pepResponseList) => {
      if (legalEntityType === LegalEntityType.PERSON) {
        if (pepResponseList.length === 1) {
          setMultiPepState({
            pepStates: [
              {
                ...requestToState(pepResponseList[0].answers, country),
                legalEntityType: EntityType.PERSON,
                locked: pepResponseList[0].locked,
              },
            ],
          });
          return setLoading(false);
        } else {
          setMultiPepState({
            pepStates: [
              {
                ...EMPTY_PEP_STATE,
                type: PepType.NOT_PEP,
                legalEntityType: EntityType.PERSON,
              },
            ],
          });
        }
      }

      if (legalEntityType === LegalEntityType.CORPORATION) {
        dataCorporate.getCompanyData().then((companyData) => {
          const pepStates = companyData.owners.reduce((pepStateList, owner) => {
            const ownerPepAnswers = pepResponseList.find(
              (answer) => answer.tin === owner.tin
            );

            if (ownerPepAnswers) {
              return [
                ...pepStateList,
                {
                  ...requestToState(ownerPepAnswers.answers, country),
                  tin: owner.tin,
                  ownerName: owner.name,
                  legalEntityType: EntityType.CORPORATION,
                  locked: ownerPepAnswers.locked,
                },
              ];
            }

            return [
              ...pepStateList,
              {
                ...EMPTY_PEP_STATE,
                type: PepType.NOT_PEP,
                tin: owner.tin,
                ownerName: owner.name,
                legalEntityType: EntityType.CORPORATION,
              },
            ];
          }, [] as PepState[]);

          setMultiPepState({ pepStates });
          return setLoading(false);
        });
      }
    });
  }, [country, legalEntityType, setMultiPepState, multiPepState.pepStates]);

  if (loading) {
    return <Spinner />;
  }

  const isLockedDueToPepAnswer =
    multiPepState.pepStates?.some((pepState) => pepState.locked) ?? false;

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <h2>
        <FormattedMessage id="pep.ispep.wrapper.header" />
      </h2>
      {multiPepState.pepStates?.map((pepState, index) => (
        <IsPepQuestionCard
          key={index}
          pepState={pepState}
          display={isLockedDueToPepAnswer}
          onChange={(type: PepType) => {
            const pepStates = [...(multiPepState.pepStates || [])];
            pepStates[index] = { ...pepStates[index], type };
            setMultiPepState({ pepStates });
          }}
        />
      ))}
      {isLockedDueToPepAnswer && (
        <Snackbar type={SNACKBAR_TYPES.WARNING}>
          <FormattedMessage
            id="pep.ispep.wrapper.locked"
            values={{
              link: (
                <Link to={getNavLink(MESSAGES_PAGE_URL)}>
                  <FormattedMessage id="pep.ispep.wrapper.locked.link" />
                </Link>
              ),
            }}
          />
        </Snackbar>
      )}
      {!isLockedDueToPepAnswer && (
        <Button
          block
          type="submit"
          label={<FormattedMessage id="pep.ispep.wrapper.button.next" />}
        />
      )}
    </Form>
  );
};
