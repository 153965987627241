import React, { useContext, useRef, VoidFunctionComponent } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { KFAccountContext } from "../../KFAccountContext";
import { SustainabilityQuestion } from "../../../../../../../pageComponents/sustainability/SustainabilityQuestion";

interface Props {
  next(): void;
}

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const accountContext = useContext(KFAccountContext);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <SustainabilityQuestion
        sustainability={accountContext.state.sustainability}
        setSustainability={(sustainability) =>
          accountContext.setState({ sustainability })
        }
      />
      <Button
        block
        type="submit"
        label={
          <FormattedMessage id="sweden.createAccount.sustainability.next" />
        }
      />
    </Form>
  );
};
