import React from "react";
import cx from "classnames";
import { banks } from "../../../../../../data/dataBanks";
import { defineMessages, useIntl } from "react-intl";

import "./BankTableCell.scss";

const messages = defineMessages({
  unknownBank: {
    id: "sweden.monthlyAgreement.autogiroConsents.bankTableCell.unknownBank",
  },
});

interface Props {
  bank: keyof typeof banks;
}

export const BankTableCell = ({ bank }: Props) => {
  const intl = useIntl();

  const isBankUnknown = bank === "UNKNOWN" || !((bank as string) in banks);

  return (
    <span className="bank-table-cell">
      <div
        className={cx(bank, {
          "bank-table-cell__logo bank-logo": !isBankUnknown,
        })}
      />
      <b>
        {isBankUnknown
          ? intl.formatMessage(messages.unknownBank)
          : banks[bank].long}
      </b>
    </span>
  );
};
