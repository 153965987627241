import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../../data/dataAutogiro";
import { KlarnaAccount } from "../../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { AutogiroConditions } from "../../../../components/autogiroConditions/AutogiroConditions";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { Link } from "react-router-dom";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "sweden.deposits.monthly.story.account-selection.account.label",
  },
  accountRequired: {
    id: "sweden.deposits.monthly.story.account-selection.account.required",
  },
});

export function AccountSelection({ next }: Props) {
  const monthlyContext = useContext(MonthlyContext);
  const [consents, setConsents] = useState<Consent[]>([]);
  const [showConsentConditions, setShowConsentConditions] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = monthlyContext.state.selectedKlarnaAccount;
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (!monthlyContext.state.klarnaAccounts) {
      history.push(getNavLink(MONTHLY_DEPOSITS_URL));
    }
    dataAutogiro.getConsents().then((consents) => {
      setConsents(consents);
    });
  }, [history, monthlyContext.state.klarnaAccounts]);

  useEffect(() => {
    if (
      typeof monthlyContext.state.selectedKlarnaAccount !== "undefined" &&
      !consents.some(
        (consent) =>
          consent.externalBankAccount ===
          monthlyContext.state.selectedKlarnaAccount?.bankAccount
      )
    ) {
      setShowConsentConditions(true);
    } else {
      setShowConsentConditions(false);
    }
  }, [consents, monthlyContext.state.selectedKlarnaAccount]);

  if (!monthlyContext.state.klarnaAccounts) {
    return <Spinner />;
  }

  if (monthlyContext.state.klarnaAccounts.length === 0) {
    return (
      <div>
        <h2>
          <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography component="span">
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.text" />
          </Typography>
        </Snackbar>
        <Button
          component={Link}
          to={getNavLink(BASE_ROUTES.BANK_SELECTION)}
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );
  }

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.account-selection.header" />
      </h2>
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <TranslatedText id="sweden.deposits.monthly.story.account-selection.information" />
      </Snackbar>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_KLARNA_ACCOUNT,
            message: monthlyContext.state.selectedKlarnaAccount?.bankAccount,
          });
          next();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={monthlyContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) =>
              monthlyContext.setState({
                selectedKlarnaAccount:
                  monthlyContext.state.klarnaAccounts?.find(
                    (account) => account.id === alt.value
                  ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
          <AutogiroConditions showConsentConditions={showConsentConditions} />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: string
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        currency: currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
}
