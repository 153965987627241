import React, { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const BankgiroIcon: VoidFunctionComponent<Props> = ({ size = 40 }) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6489_10631)">
        <circle cx="20" cy="20" r="20" fill="#E40230" />
        <path
          d="M29.2305 32.3279C29.2305 32.3279 29.2305 36 25.5584 36H14.4756C14.4756 36 10.8035 36 10.8035 32.3279V7.67214C10.8035 7.67214 10.8035 4 14.4756 4H25.5584C25.5584 4 29.2305 4 29.2305 7.67214V32.3279Z"
          fill="#E40230"
        />
        <path
          d="M24.1805 11.5169C24.1539 11.9784 24.1937 12.4632 24.4428 12.9346V12.9313C25.1865 13.9573 25.6546 15.3219 25.6546 16.8126C25.6546 20.2225 23.2774 23.0015 20.3423 23.0015C20.2992 23.0015 20.256 22.9948 20.2095 22.9948C20.2095 22.9948 19.3363 23.0081 18.9113 23.0413C18.4896 23.0712 17.8322 23.2571 17.8322 23.2571C17.0885 23.5095 16.6071 24.0739 16.6071 24.7047C16.6071 25.5182 17.2745 26.0063 18.4133 26.0063C18.981 26.0063 19.5953 25.9299 20.2527 25.8502C21.013 25.7539 21.7999 25.6576 22.6598 25.6576C25.2861 25.6576 26.8599 27.1916 26.8599 29.768C26.8599 32.7695 24.8611 34.4263 21.2288 34.4263C17.3907 34.4263 15.3786 32.723 15.3786 31.0397C15.3786 29.7747 16.2983 29.2135 17.4106 28.8616C16.1257 28.4101 15.4517 27.3974 15.4517 25.89C15.4517 24.8309 15.9198 23.8116 16.461 23.2737C16.8661 22.9384 16.9126 22.9218 17.218 22.8122C17.467 22.7226 18.1643 22.6462 18.151 22.6429C17.799 22.6495 17.8754 22.6462 18.0945 22.6429C18.1211 22.6363 18.151 22.6363 18.151 22.6429C18.224 22.6363 18.3236 22.6363 18.4432 22.6329C16.2684 21.7199 15.3487 19.4389 15.3487 19.1667C15.3487 18.8247 15.2325 8.61841 15.2325 7.91121V7.58582C15.2325 6.63957 15.2325 6.63957 13.8049 6.58977L12.915 6.55656V5.79292L13.0744 5.7896C14.6648 5.7398 16.2684 5.7398 17.8588 5.58707L18.0348 5.57047V13.2534C18.6191 11.7593 19.582 11.0521 21.023 11.0521C21.7966 11.0521 22.5436 11.2978 23.1844 11.7294L23.1545 11.6962C23.1545 11.6962 23.6227 11.932 23.6393 11.5036C23.6658 10.4113 24.4826 9.34553 25.6214 9.39201C26.5112 9.43185 27.1554 10.1258 27.1122 11.0156C27.0823 11.6829 26.6142 12.1245 25.9866 12.1013C25.3027 12.0714 24.8213 11.6431 24.8478 10.9691C24.8611 10.823 24.8943 10.6836 24.9375 10.5474C24.632 10.5972 24.2103 10.9259 24.1805 11.5169Z"
          fill="white"
        />
        <path
          d="M21.2318 33.6228C23.9178 33.6228 25.5082 32.6134 25.5082 30.9301C25.5082 29.5456 24.3395 28.8716 21.9456 28.8716C18.9973 28.8716 17.4966 29.6418 17.4966 31.1592C17.4966 33.1978 19.5252 33.6228 21.2318 33.6228Z"
          fill="#E40230"
        />
        <path
          d="M20.5347 11.8025C18.9875 11.8025 18.0977 13.9041 18.0977 17.5663C18.0977 20.8865 18.7418 22.3075 20.2724 22.3075C21.876 22.3075 22.55 20.7271 22.55 17.0185C22.5467 13.5555 21.8727 11.8025 20.5347 11.8025Z"
          fill="#E40230"
        />
      </g>
      <defs>
        <clipPath id="clip0_6489_10631">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
