import {
  BankAccountNumberValidator,
  Form,
  LysaFormRef,
  TextInput,
  accountNumberInfo,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  Card,
  Button,
} from "@lysaab/ui-2";
import React, { useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { UniqueExternalAccountValidator } from "../../../../../../components/validators/UniqueExternalAccountValidator";
import { dataAutogiro } from "../../../../../../data/dataAutogiro";
import { AccountInformationInputHint } from "../../../../components/AccountInformationInputHint/AccountInformationInputHint";
import {
  isBankSupported,
  UnsupportedBankInformation,
} from "../../../../components/unsupportedBankInformation/UnsupportedBankInformation";
import { AutogiroConditions } from "../../../../components/autogiroConditions/AutogiroConditions";

const messages = defineMessages({
  accountLabel: {
    id: "withdrawal.add.autogiro.account-information.account.label",
  },
  accountValid: {
    id: "withdrawal.add.autogiro.account-information.account.valid",
  },
  accountRequired: {
    id: "withdrawal.add.autogiro.account-information.account.required",
  },
  accountExisting: {
    id: "withdrawal.add.autogiro.account-information.account.existing",
  },
  termsLabel: {
    id: "withdrawal.add.autogiro.account-information.terms.label",
  },
  termsRequired: {
    id: "withdrawal.add.autogiro.account-information.terms.required",
  },
});

interface Props {
  next: () => void;
}

export const AccountInformation: React.VFC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const [accountNumber, setAccountNumber] = useState("");
  const parsedAccount = accountNumberInfo(accountNumber);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (
          formRef.current?.isValid &&
          parsedAccount &&
          isBankSupported(parsedAccount)
        ) {
          dataAutogiro.addAutogiro(accountNumber).then(next);
          // Don't catch, let ErrorBoundry handle it
        }
      }}
    >
      <h1>
        <FormattedMessage id="withdrawal.add.autogiro.account-information.header" />
      </h1>
      <Card>
        <p>
          <FormattedMessage id="withdrawal.add.autogiro.account-information.ingress" />
        </p>
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <FormattedMessage id="withdrawal.add.autogiro.account-information.transaction-account-warning" />
        </Snackbar>
        <TextInput
          label={intl.formatMessage(messages.accountLabel)}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.accountRequired)),
            new BankAccountNumberValidator(
              intl.formatMessage(messages.accountValid)
            ),
            new UniqueExternalAccountValidator(
              intl.formatMessage(messages.accountExisting)
            ),
          ]}
          value={accountNumber}
          onChange={setAccountNumber}
        />
        {!parsedAccount && (
          <AccountInformationInputHint value={accountNumber} />
        )}
        <dl>
          <div className="flex">
            <dt>
              <FormattedMessage id="withdrawal.add.autogiro.account-information.account.bank-label" />
              :
            </dt>
            <dd>{parsedAccount && parsedAccount.bank_name}</dd>
          </div>
          <div className="flex">
            <dt>
              <FormattedMessage id="withdrawal.add.autogiro.account-information.account.account-label" />
              :
            </dt>
            <dd>
              {parsedAccount && parsedAccount.clearing_number}{" "}
              {parsedAccount && parsedAccount.account_number}
            </dd>
          </div>
        </dl>

        {parsedAccount && (
          <UnsupportedBankInformation accountInformation={parsedAccount} />
        )}

        <AutogiroConditions showConsentConditions hideDescription />
      </Card>
      <Button
        type="submit"
        block
        label={
          <FormattedMessage id="withdrawal.add.autogiro.account-information.submit-button" />
        }
      />
    </Form>
  );
};
