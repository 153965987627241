import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
} from "@lysaab/ui-2";
import { dataAutogiro } from "../../../data/dataAutogiro";

export class ExistingConsentValidator extends BaseValidator {
  protected name = "ExistingConsentValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise((resolve, reject) => {
      dataAutogiro
        .getConsents()
        .then((consents) => {
          if (
            consents.find((consent) => consent.externalBankAccount === value)
          ) {
            resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
          } else {
            resolve({ status: VALIDATION_STATE.SUCCESS });
          }
        })
        .catch((error) => reject(error));
    });
  }
}
