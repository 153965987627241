import { Card, Spinner } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LysaTable } from "../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  CommentCode,
  Consent,
  dataAutogiro,
  HistoricConsent,
} from "../../../../../data/dataAutogiro";
import {
  dataMonthlyPayments,
  MonthlyPaymentWithBankInfo,
} from "../../../../../data/dataMonthlyPayments";
import { Amount } from "../../../../../components/amount/Amount";
import { DateTime } from "luxon";
import { BankTableCell } from "./components/BankTableCell";

import "./AutogiroConsents.scss";

export const messages = defineMessages({
  bank: {
    id: "sweden.monthlyAgreement.autogiroConsents.bank",
  },
  externalAccountNumber: {
    id: "sweden.monthlyAgreement.autogiroConsents.externalAccountNumber",
  },
  created: {
    id: "sweden.monthlyAgreement.autogiroConsents.created",
  },
  monthlyPayment: {
    id: "sweden.monthlyAgreement.autogiroConsents.monthlyPayment",
  },
  deleted: {
    id: "sweden.monthlyAgreement.autogiroConsents.deleted",
  },
  commentCode: {
    id: "sweden.monthlyAgreement.autogiroConsents.commentCode",
  },
});

export const commentCodeMessages = defineMessages({
  [CommentCode.ACCOUNT_TYPE_NOT_VALID_FOR_AUTOGIRO]: {
    id: "sweden.monthlyAgreement.autogiroConsents.accountTypeNotValidForAutogiro",
  },
  [CommentCode.REMOVED_BY_PAYER_OR_HIS_BANK]: {
    id: "sweden.monthlyAgreement.autogiroConsents.removedByPayerOrHisBank",
  },
  [CommentCode.INVALID_ACCOUNT_DETAILS]: {
    id: "sweden.monthlyAgreement.autogiroConsents.invalidAccountDetails",
  },
  [CommentCode.REMOVED_DUE_TO_MISSING_ACCOUNT_REPLY]: {
    id: "sweden.monthlyAgreement.autogiroConsents.removedDueToMissingAccountReply",
  },
  [CommentCode.INVALID_ACCOUNT_NUMBER]: {
    id: "sweden.monthlyAgreement.autogiroConsents.invalidAccountNumber",
  },
  [CommentCode.REMOVED_CONSENT]: {
    id: "sweden.monthlyAgreement.autogiroConsents.removedConsent",
  },
  [CommentCode.CUSTOMER_CLOSED]: {
    id: "sweden.monthlyAgreement.autogiroConsents.customerClosed",
  },
  [CommentCode.INTERNAL]: {
    id: "sweden.monthlyAgreement.autogiroConsents.internal",
  },
});

export const AutogiroConsents = () => {
  const [monthlyAgreements, setMonthlyAgreements] = useState<Consent[]>([]);
  const [historicConsents, setHistoricConsents] = useState<HistoricConsent[]>(
    []
  );
  const [monthlyPayments, setMonthlyPayments] = useState<
    MonthlyPaymentWithBankInfo[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    Promise.all([
      dataAutogiro.getConsents(),
      dataAutogiro.getHistoricConsents(),
      dataMonthlyPayments.getMonthlyPayments(),
    ])
      .then(([consents, historicConsents, monthlyPayments]) => {
        setMonthlyAgreements(consents);
        setHistoricConsents(historicConsents);
        setMonthlyPayments(monthlyPayments);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const filteredHistoricConsents = historicConsents.filter((consent) => {
    const twoMonthsAgo = DateTime.now().minus({ months: 2 });
    const removedDate = DateTime.fromISO(consent.deleted);
    return twoMonthsAgo < removedDate;
  });

  const approvedConsents = monthlyAgreements.filter(
    (agreements) => agreements.approved
  );

  return (
    <div className="autogiro-consents">
      <h2 className="autogiro-consents__header">
        <TranslatedText id="sweden.monthlyAgreement.autogiroConsents.activeHeader" />
      </h2>
      <Card>
        {approvedConsents.length > 0 ? (
          <LysaTable>
            <thead>
              <tr>
                <LysaTableTextCell
                  value={intl.formatMessage(messages.bank)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.externalAccountNumber)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.created)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.monthlyPayment)}
                  header
                />
              </tr>
            </thead>
            <tbody>
              {approvedConsents.map((consent) => {
                const monthlyPayment = monthlyPayments?.find(
                  (monthlyPayment) =>
                    monthlyPayment.externalBankAccount ===
                    consent.externalBankAccount
                );
                return (
                  <tr>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.bank)}
                    >
                      <BankTableCell bank={consent.bank} />
                    </LysaTableTextCell>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.externalAccountNumber)}
                      value={consent.externalBankAccount}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.created)}
                      value={intl.formatDate(consent.created)}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.monthlyPayment)}
                    >
                      <Amount amount={monthlyPayment?.amount || 0} />
                    </LysaTableTextCell>
                  </tr>
                );
              })}
            </tbody>
          </LysaTable>
        ) : (
          <TranslatedText id="sweden.monthlyAgreement.autogiroConsents.noActiveConsents" />
        )}
      </Card>
      {monthlyAgreements.some((consent) => !consent.approved) && (
        <React.Fragment>
          <h2 className="autogiro-consents__header">
            <TranslatedText id="sweden.monthlyAgreement.autogiroConsents.pendingHeader" />
          </h2>
          <Card>
            <LysaTable>
              <thead>
                <tr>
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.bank)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.externalAccountNumber)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.created)}
                    header
                  />
                </tr>
              </thead>
              <tbody>
                {monthlyAgreements
                  .filter((consent) => !consent.approved)
                  .map((consent) => {
                    return (
                      <tr>
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.bank)}
                        >
                          <BankTableCell bank={consent.bank} />
                        </LysaTableTextCell>
                        <LysaTableTextCell
                          label={intl.formatMessage(
                            messages.externalAccountNumber
                          )}
                          value={consent.externalBankAccount}
                        />
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.created)}
                          value={intl.formatDate(consent.created)}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </LysaTable>
          </Card>
        </React.Fragment>
      )}
      {filteredHistoricConsents.length > 0 && (
        <React.Fragment>
          <h2 className="autogiro-consents__header">
            <TranslatedText id="sweden.monthlyAgreement.autogiroConsents.removedHeader" />
          </h2>
          <Card>
            <LysaTable>
              <thead>
                <tr>
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.bank)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.externalAccountNumber)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.created)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.deleted)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.commentCode)}
                    header
                  />
                </tr>
              </thead>
              <tbody>
                {filteredHistoricConsents.map((consent) => {
                  return (
                    <tr>
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.bank)}
                      >
                        <BankTableCell bank={consent.bank} />
                      </LysaTableTextCell>
                      <LysaTableTextCell
                        label={intl.formatMessage(
                          messages.externalAccountNumber
                        )}
                        value={consent.externalBankAccount}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.created)}
                        value={intl.formatDate(consent.created)}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.deleted)}
                        value={intl.formatDate(consent.deleted)}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.commentCode)}
                        value={intl.formatMessage(
                          commentCodeMessages[consent.commentCode]
                        )}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </LysaTable>
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};
