import {
  API,
  BankidResponse,
  cache,
  FailedHintCode,
  PendingHintCode,
  Status,
} from "@lysaab/ui-2";
import { encode } from "@lysaab/ui-2";
import { Isin } from "./dataFunds";
import { InvestmentAccountId, AccountType } from "./dataAccounts";
import { CashTransactionType, FundTransactionType } from "./dataTransactions";
import { LysaCountry } from "@lysaab/countries";

export enum ControllingShare {
  SHARES_0_25 = "SHARES_0_25",
  SHARES_25_50 = "SHARES_25_50",
  SHARES_50_75 = "SHARES_50_75",
  SHARES_75_100 = "SHARES_75_100",
  SHARES_100 = "SHARES_100",
}

export enum ControlType {
  ART_10 = "ART_10",
  ART_14 = "ART_14",
  ART_17 = "ART_17",
  ART_20 = "ART_20",
  ART_25 = "ART_25",
  ART_30 = "ART_30",
  ART_40 = "ART_40",
  ART_74 = "ART_74",
}

export interface CoporateUser {
  identificationNumber: string;
  email: string;
  readOnly: boolean;
  name: string;
  currentUser: boolean;
  admin: boolean;
}

export interface CorporateOwner {
  tin: string;
  controllingShare: ControllingShare;
  taxResidence: LysaCountry;
  citizenships: LysaCountry[];
  controlTypes: ControlType[];
  controllingOrganisations: { name: string; organisationNumber: string }[];
  name: string;
  designatedOwner?: string;
  exactOwnedShare?: number;
  pep?: { registered: string; position: string; relation: string };
}

export interface CompanyInformation {
  users: CoporateUser[];
  owners: CorporateOwner[];
  feeExternalBankAccount: string;
  bank: string;
}

export enum FeeType {
  DISC = "DISC",
  INSURANCE = "INSURANCE",
}

export interface DeclarationDocumentation {
  organisationNumber: string;
  accountType: AccountType;
  start: Holding[];
  end: Holding[];
  fundTransactions: FundTransaction[];
  cashTransactions: CashTransaction[];
  feeTransactions: FeeTransaction[];
}

export interface Holding {
  gav: number;
  isin: Isin;
  price: number;
  shares: number;
}

export interface FundTransaction {
  booked: string;
  date: string;
  fund: Isin;
  orderId: string;
  price: number;
  type: FundTransactionType;
  volume: number;
  worth: number;
  gav?: number;
  profit?: number;
}

export interface CashTransaction {
  amount: number;
  booked: string;
  counterpart: InvestmentAccountId;
  transactionId: string;
  transactionType: CashTransactionType;
}

export interface FeeTransaction {
  booked: string;
  fee: number;
  feeExcludingVat: number;
  feeType: FeeType;
  transactionId: string;
}

export interface OldUpsertCompanyUser {
  transactionId: string;
  autostartToken: string;
  status: OldBankidStatuses;
}
export interface UpsertCompanyUser extends BankidResponse {
  transactionId: string;
  autostartToken: string;
}

export interface OldBankidStatus {
  status: OldBankidStatuses;
}

type OldBankidStatusesSuccess = "COMPLETED" | "COMPLETE";

type OldBankidStatusesFailed =
  | "ABORTED"
  | "BANKID_NOT_AVAILABLE"
  | "INTERNAL_ERROR"
  | "ERROR"
  | "SIGNING_ERROR"
  | "USER_NOT_FOUND"
  | "ABORTED_PAYMENTS_PENDING"
  | "ALREADY_IN_PROGRESS"
  | "INVALID_PARAMETERS"
  | "LOAD_FAILURE"
  | "CUSTOMER_NOT_FOUND"
  | "ALREADY_COMPLETED"
  | "CLIENT_ERR"
  | "USER_CANCEL"
  | "CANCELLED"
  | "EXPIRED_TRANSACTION"
  | "CERTIFICATE_ERR";

type OldBankidStatusesPending =
  | "UNKNOWN"
  | "WAITING"
  | "WORKING"
  | "PENDING"
  | "PENDING_CREATION"
  | "OUTSTANDING_TRANSACTION"
  | "NO_CLIENT"
  | "USER_SIGN";

type OldBankidStatuses = OldBankidStatusesSuccess &
  OldBankidStatusesFailed &
  OldBankidStatusesPending;

export const dataCorporate = {
  saveFeeAccount: function (externalBankAccount: string) {
    return API.post("/corporate/feeExternalBankAccount", {
      externalBankAccount,
    }).then((response) => {
      cache.delete("/corporate");
      return response;
    });
  },

  getAccountDeclarationDocumentation: (
    accountId: string,
    start: string,
    end: string
  ) => {
    return API.get<DeclarationDocumentation>(
      encode`/corporate/tax?accountId=${accountId}&start=${start}&end=${end}`
    );
  },

  getCompanyData: () => {
    return API.get<CompanyInformation>("/corporate");
  },

  upsertCompanyUser: (
    identificationNumber: string,
    email: string,
    admin: boolean
  ): Promise<UpsertCompanyUser> => {
    return API.post<OldUpsertCompanyUser>(`/corporate/users`, {
      identificationNumber,
      email,
      admin,
    }).then((response) => {
      return {
        ...response,
        ...oldBankIdStatusToNew(response.status),
      };
    });
  },

  upsertCompanyUserPoll: (transactionId: string): Promise<BankidResponse> => {
    return API.get<OldBankidStatus>(
      encode`/corporate/users?transactionId=${transactionId}`,
      true
    ).then((response) => {
      const bankidStatus = oldBankIdStatusToNew(response.status);

      if (bankidStatus.status === Status.COMPLETE) {
        cache.delete("/corporate");
      }

      return bankidStatus;
    });
  },

  deleteCompanyUser: (
    identificationNumber: string
  ): Promise<UpsertCompanyUser> => {
    return API.delete<OldUpsertCompanyUser>(
      `/corporate/users/${identificationNumber}`
    ).then((response) => {
      cache.delete("/corporate");
      return {
        ...response,
        ...oldBankIdStatusToNew(response.status),
      };
    });
  },
};

function oldBankIdStatusToNew(status: OldBankidStatuses): BankidResponse {
  switch (status) {
    // COMPLETE
    case "COMPLETED":
    case "COMPLETE": {
      return {
        status: Status.COMPLETE,
        hintCode: PendingHintCode.USERSIGN,
      };
    }

    // FAILED
    case "ALREADY_IN_PROGRESS":
    case "INVALID_PARAMETERS":
    case "USER_NOT_FOUND":
    case "CUSTOMER_NOT_FOUND":
    case "ALREADY_COMPLETED":
    case "LOAD_FAILURE":
    case "SIGNING_ERROR":
    case "CLIENT_ERR":
    case "INTERNAL_ERROR":
    case "BANKID_NOT_AVAILABLE": {
      return {
        status: Status.FAILED,
        hintCode: FailedHintCode.STARTFAILED,
      };
    }
    case "CERTIFICATE_ERR": {
      return {
        status: Status.FAILED,
        hintCode: FailedHintCode.CERTIFICATEERR,
      };
    }
    case "USER_CANCEL": {
      return {
        status: Status.FAILED,
        hintCode: FailedHintCode.USERCANCEL,
      };
    }
    case "CANCELLED": {
      return {
        status: Status.FAILED,
        hintCode: FailedHintCode.CANCELLED,
      };
    }
    case "EXPIRED_TRANSACTION": {
      return {
        status: Status.FAILED,
        hintCode: FailedHintCode.EXPIREDTRANSACTION,
      };
    }

    // PENDING
    case "UNKNOWN":
    case "WAITING":
    case "WORKING":
    case "PENDING":
    case "PENDING_CREATION": {
      return {
        status: Status.PENDING,
        hintCode: PendingHintCode.STARTED,
      };
    }
    case "OUTSTANDING_TRANSACTION": {
      return {
        status: Status.PENDING,
        hintCode: PendingHintCode.OUTSTANDINGTRANSACTION,
      };
    }
    case "NO_CLIENT": {
      return {
        status: Status.PENDING,
        hintCode: PendingHintCode.NOCLIENT,
      };
    }
    case "USER_SIGN": {
      return {
        status: Status.PENDING,
        hintCode: PendingHintCode.USERSIGN,
      };
    }
  }

  return {
    status: Status.PENDING,
    hintCode: PendingHintCode.STARTED,
  };
}
