import React from "react";

export const Disclaimer = () => (
  <div className="disclaimer">
    Investering i värdepapper är förknippat med risk. En investering kan både
    öka och minska i värde och det är inte säkert att du får tillbaka det
    investerade kapitalet. Förväntad avkastning utgör inte en tillförlitlig
    indikation om framtida resultat. Förväntad avkastning och risk beräknas
    enligt Lysas modellantaganden
    {/* TODO: DocLink component */}{" "}
    <a
      href={`${process.env.REACT_APP_DOCUMENT_ROOT}/legal-information/modellantaganden`}
      target="_blank"
      rel="noreferrer noopener"
    >
      modellantaganden
    </a>
    .
  </div>
);
