import React, { useReducer, createContext, FunctionComponent } from "react";
import { InvestmentAccount } from "../../../../../../data/dataAccounts";

export interface MonthlyState {
  selectedLysaAccount?: InvestmentAccount;
  amount?: string;
  withdrawalDay?: number;
  phone?: string;
}

export interface MonthlyContextProps {
  state: MonthlyState;
  setState: (newState: Partial<MonthlyState>) => void;
}

export interface withMonthlyProps {
  Monthly: MonthlyContextProps;
}

export const MonthlyContext = createContext<MonthlyContextProps>(
  {} as MonthlyContextProps
);

function stateReducer(state: MonthlyState, newState: Partial<MonthlyState>) {
  return { ...state, ...newState };
}

export const MonthlyContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <MonthlyContext.Provider value={{ state, setState }}>
      {children}
    </MonthlyContext.Provider>
  );
};
