import React, { useContext, VoidFunctionComponent } from "react";
import { UserContext } from "../../../context/UserContext";
import { LegalEntityType } from "../../../data/dataLogin";
import { KycCorporation } from "./KycCorporation";
import { KycPerson } from "./KycPerson";

export const CustomerKyc: VoidFunctionComponent = () => {
  const userContext = useContext(UserContext);
  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return <KycCorporation />;
  }
  return <KycPerson />;
};
