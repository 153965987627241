import { Story } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Route } from "../../../../components/route/Route";
import { Switch } from "../../../../components/route/Switch";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { PageStripped } from "../../../../pages/PageStripped";
import { END_INVESTMENT_URL } from "../../../../pages/endInvestment/EndInvestment";
import { CloseSavingsAccountConfirmation } from "./closeSavingsAccountConfirmation/CloseSavingsAccountConfirmation";
import { Done } from "./done/Done";

export const CLOSE_SAVINGS_ACCOUNT_URL = "/close-savings-account";

export const BASE_ROUTES = {
  CLOSE_SAVINGS_ACCOUNT_CONFIRMATION: `${CLOSE_SAVINGS_ACCOUNT_URL}/confirm/:accountId`,
  DONE: `${CLOSE_SAVINGS_ACCOUNT_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "closeSavingsAccountStory.header",
  },
  ariaProgressLabel: {
    id: "closeSavingsAccountStory.ariaProgressLabel",
  },
});

interface Props {
  addWithdrawalAccountUrl?: string;
}

export const CloseSavingsAccountStory: React.VoidFunctionComponent<Props> = ({
  addWithdrawalAccountUrl,
}) => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={false}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => safeNavigation(getNavLink(END_INVESTMENT_URL))}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.CLOSE_SAVINGS_ACCOUNT_CONFIRMATION} exact>
            <CloseSavingsAccountConfirmation
              next={() => {
                history.replace(ROUTES.DONE);
              }}
              addWithdrawalAccountUrl={addWithdrawalAccountUrl}
            />
          </Route>
          <Route path={ROUTES.DONE} exact>
            <Done />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};
