import {
  Typography,
  NewIcon,
  Snackbar,
  SNACKBAR_TYPES,
  LysaLink,
} from "@lysaab/ui-2";
import React, { useState } from "react";
import { Modal } from "../../../../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../../../../components/TranslatedText";
import { TransparentButton } from "../../../../../../../../components/transparentButton/TransparentButton";
import { LysaLinkButton } from "../../../openAccount/components/documentsCard/components/LysaLinkButton";
import { client as elevioClient } from "elevio";
import { OPEN_SAVINGS_ACCOUNT_ELEVIO_CATEGORY_ID } from "../../../../../../components/savingsInterestCardContent/SavingsInterestCard";

import "./InfoModal.scss";

export const InfoModal = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <LysaLinkButton
        onClick={() => setShowModal(true)}
        className="create-savings-info__modal-link"
      >
        <TranslatedText id="sweden.createSavingsAccount.info.ingress.link" />
      </LysaLinkButton>
      <Modal
        showModal={showModal}
        hideHeader
        width={500}
        closeOnOverlayClick
        onClose={() => setShowModal(false)}
        className="create-savings-modal"
      >
        <div className="create-savings-modal__header-wrapper">
          <Typography type="h2">
            <TranslatedText id="sweden.createSavingsAccount.info.modal.header" />
          </Typography>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="create-savings-modal__close"
          >
            <NewIcon.Cancel />
          </button>
        </div>
        <Typography type="label-large" component="p">
          <TranslatedText id="sweden.createSavingsAccount.info.modal.ingress" />
        </Typography>
        <Typography type="body">
          <TranslatedText
            id="sweden.createSavingsAccount.info.modal.body1"
            values={{
              sup: (...parts: string[]) => <sup>{parts}</sup>,
            }}
          />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.createSavingsAccount.info.modal.body2" />
        </Typography>
        <div className="create-savings-modal__elevio-link">
          <LysaLink
            component={TransparentButton}
            onClick={() => {
              setShowModal(false);
              elevioClient.openCategory(
                OPEN_SAVINGS_ACCOUNT_ELEVIO_CATEGORY_ID
              );
            }}
          >
            <TranslatedText id="sweden.createSavingsAccount.info.modal.elevioLink" />
          </LysaLink>
        </div>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <TranslatedText id="sweden.createSavingsAccount.info.modal.snackbarText" />
        </Snackbar>
        <Typography
          type="h4"
          component="h3"
          className="create-savings-modal__disclaimer-header"
        >
          <TranslatedText
            id="sweden.createSavingsAccount.info.disclaimer.guarantee.header"
            values={{
              sup: (...parts: string[]) => <sup>{parts}</sup>,
            }}
          />
        </Typography>
        <Typography type="body-small">
          <TranslatedText
            id="sweden.createSavingsAccount.info.disclaimer.guarantee.body"
            values={{
              link: (...text: string[]) => {
                return (
                  <LysaLink
                    external
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://www.insättningsgarantin.se"
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </Typography>
      </Modal>
    </>
  );
};
