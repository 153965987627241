import { LysaCountry } from "@lysaab/countries";
import { VoidFunctionComponent, useContext } from "react";
import { LocalizationContext } from "../../context/LocalizationContext";
import { UserContext } from "../../context/UserContext";
import { LegalEntityType } from "../../data/dataLogin";
import { DeclarationCorporation } from "./DeclarationCorporation";

export const Banner: VoidFunctionComponent = () => {
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);

  if (
    localizationContext.state.country === LysaCountry.SWEDEN &&
    userContext.state.legalEntityType === LegalEntityType.CORPORATION
  ) {
    return <DeclarationCorporation />;
  }

  return null;
};
