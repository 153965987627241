import { Card } from "@lysaab/ui-2";
import React, { VoidFunctionComponent } from "react";
import { TranslatedText } from "../../../components/TranslatedText";

import "./ReadOnlyMarketing.scss";

interface Props {
  commercialEmail: boolean;
  notificationEmail: boolean;
}

export const ReadOnlyMarketing: VoidFunctionComponent<Props> = ({
  commercialEmail,
  notificationEmail,
}) => {
  return (
    <section>
      <h2>
        <TranslatedText id="settings.readOnlyMarketing.header" />
      </h2>
      <Card>
        <dl className="readonly-marketing">
          <div className="flex">
            <dt>
              <TranslatedText id="settings.readOnlyMarketing.marketing" />
            </dt>
            <dd>
              {commercialEmail ? (
                <TranslatedText id="settings.readOnlyMarketing.yes" />
              ) : (
                <TranslatedText id="settings.readOnlyMarketing.no" />
              )}
            </dd>
          </div>
          <div className="flex">
            <dt>
              <TranslatedText id="settings.readOnlyMarketing.notifications" />
            </dt>
            <dd>
              {notificationEmail ? (
                <TranslatedText id="settings.readOnlyMarketing.yes" />
              ) : (
                <TranslatedText id="settings.readOnlyMarketing.no" />
              )}
            </dd>
          </div>
        </dl>
      </Card>
    </section>
  );
};
