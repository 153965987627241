import { accountNumberInfo } from "@lysaab/ui-2";
import React from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { dataBanks } from "../../../../../../data/dataBanks";
import {
  CashTransaction,
  CashTransactionType,
} from "../../../../../../data/dataTransactions";

import "../../TransactionCard.scss";

export const WithdrawalTransactionRows = ({
  transaction,
}: {
  transaction: CashTransaction;
}) => {
  if (transaction.type !== CashTransactionType.WITHDRAWAL) return null;
  const parsedAccount = accountNumberInfo(transaction.externalBankAccount);
  return (
    <React.Fragment>
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.bank" />
        </dt>
        <dd>{dataBanks.getBank(transaction.bank).long}</dd>
      </div>
      <div className="transaction-card-row ">
        <dt>
          <TranslatedText id="historicTransactionsPage.historicTransactions.transactionCard.bankAccount" />
        </dt>
        <dd>
          {parsedAccount
            ? `${parsedAccount.clearing_number}-${parsedAccount.account_number}`
            : transaction.externalBankAccount}
        </dd>
      </div>
    </React.Fragment>
  );
};
