import React from "react";
import { Button, Card } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";
import { TranslatedText } from "../../../../components/TranslatedText";
import { Link } from "react-router-dom";
import { DEPOSITS_OVERVIEW_URL } from "../../overview/Recommendation";
import { MONTHLY_DEPOSITS_URL } from "../../monthly/create/MonthlyStory";

export function Done() {
  return (
    <div>
      <h1>
        <FormattedMessage
          id="deposits.klarna.story.done.header"
          defaultMessage="Deposit initiated"
        />
      </h1>
      <Card>
        <p>
          <FormattedMessage
            id="deposits.klarna.story.done.text"
            defaultMessage="Your deposit has been successfully initiated. The money should arrive in 1-2 banking days and we will notify you when your money has been deposited"
          />
        </p>
      </Card>
      <Button
        component={Link}
        block
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<TranslatedText id="deposits.klarna.story.done.button" />}
      />
      <Button
        component={Link}
        block
        to={getNavLink(DEPOSITS_OVERVIEW_URL)}
        variant="secondary"
        label={
          <TranslatedText id="deposits.klarna.story.done.button-deposit" />
        }
      />
      <Button
        component={Link}
        block
        to={getNavLink(MONTHLY_DEPOSITS_URL)}
        variant="secondary"
        label={
          <TranslatedText id="deposits.klarna.story.done.button-monthly" />
        }
      />
    </div>
  );
}
