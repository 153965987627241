import React from "react";
import { StoryDisclaimer } from "../../../../../components/storyDisclaimer/StoryDisclaimer";
import { TranslatedText } from "../../../../../components/TranslatedText";

export const Disclaimer = () => {
  return (
    <StoryDisclaimer>
      <TranslatedText id="withdrawal.request.story.disclaimer" />
    </StoryDisclaimer>
  );
};
