import React from "react";
import cx from "classnames";
import { AllocationBarIntl, Button, Card } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";
import { InvestmentAccount, AccountType } from "../../data/dataAccounts";
import {
  AdviseAccount,
  getEligibility,
  EligibilityResponse,
} from "../../data/dataInvestments";
import "./EditAllocationSummary.scss";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { SuitabilityDownload } from "../../pageComponents/advise/SuitabilityDownload";

const messages = defineMessages({
  bonds: {
    id: "editAllocationSummary.bonds",
    description: "allocation bonds allocation",
    defaultMessage: "{bonds} % bonds",
  },
  stocks: {
    id: "editAllocationSummary.stocks",
    description: "allocation stocks allocation",
    defaultMessage: "{stocks} % stocks",
  },
  advice: {
    id: "editAllocationSummary.advice",
    description: "allocation page - Lysa's advice",
    defaultMessage: "Lysa's advice",
  },
  taken: {
    id: "editAllocationSummary.taken",
    description: "allocation page - current risk",
    defaultMessage: "Chosen allocation",
  },
});

interface Props {
  account?: InvestmentAccount;
  adviseAccount?: AdviseAccount;
  profile?: EligibilityResponse;
}

export const EditAllocationSummary: React.VFC<Props> = ({
  adviseAccount,
  account,
  profile,
}) => {
  const intl = useIntl();

  if (!adviseAccount || !account || !profile) {
    return <div className="edit-allocation-summary" />;
  }

  return (
    <div
      className={cx("edit-allocation-summary", {
        "is-insurance-account": account.type === AccountType.DANICA_KF,
      })}
    >
      <div className="edit-allocation-summary-header">
        <h3>
          <TranslatedText id="editAllocationSummary.title" />
        </h3>

        <div>
          <SuitabilityDownload
            esgQuestions={adviseAccount.esgResult}
            accountQuestions={adviseAccount}
            advisedRisk={adviseAccount.advisedRisk}
            eligibility={getEligibility(profile)}
            render={(url, filename) => (
              <Button
                component="a"
                href={url}
                download={filename}
                variant="secondary"
                size="small"
                label={
                  <FormattedMessage id="suitabilityPdfDownload.download" />
                }
                icon="Deposit"
              />
            )}
          />
        </div>
      </div>
      <Card>
        <div>
          <div>
            <strong>{intl.formatMessage(messages.taken)}</strong>
            <AllocationBarIntl
              messages={{
                stocks: intl.formatMessage(messages.stocks, {
                  stocks: adviseAccount.takenRisk,
                }),
                bonds: intl.formatMessage(messages.bonds, {
                  bonds: 100 - adviseAccount.takenRisk,
                }),
              }}
              risk={adviseAccount.takenRisk || 0}
              data-test-id="taken-risk"
            />
          </div>
          <div>
            <strong>{intl.formatMessage(messages.advice)}</strong>
            <AllocationBarIntl
              messages={{
                stocks: intl.formatMessage(messages.stocks, {
                  stocks: adviseAccount.advisedRisk,
                }),
                bonds: intl.formatMessage(messages.bonds, {
                  bonds: 100 - adviseAccount.advisedRisk,
                }),
              }}
              risk={adviseAccount.advisedRisk || 0}
              data-test-id="advised-risk"
            />
          </div>
        </div>
        <div>
          <p data-test-id="account-advised-declaration">
            {adviseAccount?.declaration ?? ""}
          </p>
        </div>

        {account.type === AccountType.DANICA_KF && (
          <div className="kf-info">
            <h4>
              <TranslatedText id="editAllocationSummary.declaration.insurance" />
            </h4>
            <p>
              <TranslatedText id="editAllocationSummary.declaration.insurance.paragraph" />
            </p>
          </div>
        )}
      </Card>
    </div>
  );
};
