import React, { useState } from "react";
import { InvestmentType, DocModal } from "@lysaab/ui-2";
import "./Docs.scss";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  DocumentInformation,
  getLatestDocuments,
} from "../../../../../../data/dataDocuments";

interface Props {
  risk: number;
  investmentType: InvestmentType;
  name?: string;
  documentsInformation: DocumentInformation[];
}

export const messages = defineMessages({
  [InvestmentType.BROAD]: {
    id: "sweden.kf.docs.broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "sweden.kf.docs.sustainable",
  },
  announcement: {
    id: "sweden.kf.docs.announcement",
  },
  placeholder: {
    id: "sweden.kf.docs.placeholder",
  },
  header: {
    id: "sweden.kf.docs.header",
  },
});

const documents = (information: DocumentInformation[]) =>
  getLatestDocuments(
    information,
    ...[
      ["legal/se/sv/avtal-om-kapitalförsäkringsdepå.md"],
      ["legal/se/sv/placeringsriktlinjer.md"],
      ["legal/se/sv/allmänna-bestämmelser-för-kapitalförsäkringsdepå.md"],
      ["legal/se/sv/förhandsinformation-om-försäkringsförmedling-v-2021-2.md"],
      [
        "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
      ],
      ["legal/se/sv/bästa-orderutförande.md"],
      ["legal/se/sv/depositor-information.md"],
      [
        "legal/se/sv/information-om-lysa-v-2021-1.md",
        "legal/se/sv/information-om-lysa-v-2021-2.md",
        "legal/se/sv/information-om-lysa-v-2022-1.md",
      ],
    ]
  );

const depositorInformation = (information: DocumentInformation[]) =>
  getLatestDocuments(information, ["legal/se/sv/depositor-information.md"]);

export const Docs: React.VFC<Props> = ({
  risk,
  investmentType,
  name,
  documentsInformation,
}) => {
  const intl = useIntl();
  const [showDoc, setShowDoc] = useState<string>();

  const varValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: name ?? "",
    STOCKS: risk ? risk.toString() : "",
    BONDS: risk ? (100 - risk).toString() : "",
    INVESTMENT_TYPE: intl.formatMessage(messages[investmentType]),
  };

  return (
    <div className="signup-docs">
      <DocModal
        onRequestClose={() => setShowDoc(undefined)}
        active={!!showDoc}
        modalAnnouncement={intl.formatMessage(messages.announcement)}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={documents(documentsInformation)}
        variableValues={varValues}
        header={intl.formatMessage(messages.header)}
        docName={showDoc}
      />

      <div className="link-container">
        <button
          onClick={(ev) => {
            ev.preventDefault();
            setShowDoc(depositorInformation(documentsInformation)[0]);
          }}
          className="as-link"
        >
          <TranslatedText id="sweden.kf.docs.terms" />
        </button>

        <button
          onClick={(ev) => {
            ev.preventDefault();
            setShowDoc("legal/se/sv/depositor-information.md");
          }}
          className="as-link"
        >
          <TranslatedText id="sweden.kf.docs.depositor" />
        </button>
      </div>
    </div>
  );
};
