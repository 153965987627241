import {
  BaseValidator,
  ValidationResponse,
  VALIDATION_STATE,
  Alternative,
} from "@lysaab/ui-2";

export class PassiveCorpValidator extends BaseValidator {
  protected name = "PassiveCorpValidator";

  validate(
    alternative: Alternative<boolean> | undefined
  ): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (!alternative || !alternative.value) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true,
        });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
