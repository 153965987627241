import React, { useContext, VoidFunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { depositsPerson, DepositTypePerson } from "../../../../data/dataKyc";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/countries";

export const messages = defineMessages({
  depositsHeader: { id: "sweden.kyc.deposits.header" },
  depositsRequired: { id: "sweden.kyc.deposits.required" },
});

interface MessageWithId {
  id: string;
}

type CountryMessages =
  | Record<DepositTypePerson<LysaCountry.SWEDEN>, MessageWithId>
  | Record<DepositTypePerson<LysaCountry.DENMARK>, MessageWithId>
  | Record<DepositTypePerson<LysaCountry.FINLAND>, MessageWithId>
  | Record<DepositTypePerson<LysaCountry.GERMANY>, MessageWithId>
  | Record<DepositTypePerson<LysaCountry.SPAIN>, MessageWithId>;

const swedenMessages = defineMessages<
  DepositTypePerson<LysaCountry.SWEDEN>,
  MessageWithId,
  Record<DepositTypePerson<LysaCountry.SWEDEN>, MessageWithId>
>({
  SE_0_100K_SEK: {
    id: `sweden.kyc.deposits.alt.SE_0_100K_SEK`,
  },
  SE_100K_1M_SEK: {
    id: `sweden.kyc.deposits.alt.SE_100K_1M_SEK`,
  },
  SE_1M_5M_SEK: {
    id: `sweden.kyc.deposits.alt.SE_1M_5M_SEK`,
  },
  SE_5M_SEK: {
    id: `sweden.kyc.deposits.alt.SE_5M_SEK`,
  },
});

const denmarkMessages = defineMessages<
  DepositTypePerson<LysaCountry.DENMARK>,
  MessageWithId,
  Record<DepositTypePerson<LysaCountry.DENMARK>, MessageWithId>
>({
  DK_0_100K_DKK: {
    id: `denmark.kyc.deposits.alt.DK_0_100K_DKK`,
  },
  DK_100K_1M_DKK: {
    id: `denmark.kyc.deposits.alt.DK_100K_1M_DKK`,
  },
  DK_1M_5M_DKK: {
    id: `denmark.kyc.deposits.alt.DK_1M_5M_DKK`,
  },
  DK_5M_DKK: {
    id: `denmark.kyc.deposits.alt.DK_5M_DKK`,
  },
});

const finlandMessages = defineMessages<
  DepositTypePerson<LysaCountry.FINLAND>,
  MessageWithId,
  Record<DepositTypePerson<LysaCountry.FINLAND>, MessageWithId>
>({
  FI_0_10K_EUR: {
    id: `finland.kyc.deposits.alt.FI_0_10K_EUR`,
  },
  FI_10K_100K_EUR: {
    id: `finland.kyc.deposits.alt.FI_10K_100K_EUR`,
  },
  FI_100K_500K_EUR: {
    id: `finland.kyc.deposits.alt.FI_100K_500K_EUR`,
  },
  FI_500K_EUR: {
    id: `finland.kyc.deposits.alt.FI_500K_EUR`,
  },
});

const germanyMessages = defineMessages<
  DepositTypePerson<LysaCountry.GERMANY>,
  MessageWithId,
  Record<DepositTypePerson<LysaCountry.GERMANY>, MessageWithId>
>({
  DE_0_10K_EUR: {
    id: `germany.kyc.deposits.alt.DE_0_10K_EUR`,
  },
  DE_10K_100K_EUR: {
    id: `germany.kyc.deposits.alt.DE_10K_100K_EUR`,
  },
  DE_100K_500K_EUR: {
    id: `germany.kyc.deposits.alt.DE_100K_500K_EUR`,
  },
  DE_500K_EUR: {
    id: `germany.kyc.deposits.alt.DE_500K_EUR`,
  },
});

const spainMessages = defineMessages<
  DepositTypePerson<LysaCountry.SPAIN>,
  MessageWithId,
  Record<DepositTypePerson<LysaCountry.SPAIN>, MessageWithId>
>({
  ES_0_10K_EUR: {
    id: "spain.kyc.deposits.alt.DE_0_10K_EUR",
  },
  ES_10K_100K_EUR: {
    id: "spain.kyc.deposits.alt.DE_10K_100K_EUR",
  },
  ES_100K_500K_EUR: {
    id: "spain.kyc.deposits.alt.DE_100K_500K_EUR",
  },
  ES_500K_EUR: {
    id: "spain.kyc.deposits.alt.DE_500K_EUR",
  },
});

const countryMessages: Record<LysaCountry, CountryMessages> = {
  [LysaCountry.SWEDEN]: swedenMessages,
  [LysaCountry.DENMARK]: denmarkMessages,
  [LysaCountry.FINLAND]: finlandMessages,
  [LysaCountry.GERMANY]: germanyMessages,
  [LysaCountry.SPAIN]: spainMessages,
};

interface Props {
  deposit?: DepositTypePerson;
  setDeposit: (deposit: DepositTypePerson) => void;
}

export const DepositsQuestionPerson: VoidFunctionComponent<Props> = ({
  deposit,
  setDeposit,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  const alternatives = depositsPerson[country].map((value) => ({
    text: intl.formatMessage(
      (countryMessages[country] as Record<typeof value, MessageWithId>)[value],
      {
        currency: (currencyStr) =>
          intl.formatNumber(Number(currencyStr) || 0, {
            currency: localizationContext.state.currency,
            style: "currency",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
      }
    ),
    value,
  }));

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.depositsHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
        ]}
        onChange={(newDeposits) => setDeposit(newDeposits.value)}
        value={alternatives.find((alt) => deposit === alt.value)}
      />
    </Card>
  );
};
