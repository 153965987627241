import React, { useReducer, createContext, FunctionComponent } from "react";
import { useCallback } from "react";
import { matchPath, RouteProps } from "react-router";

enum Action {
  ADD,
  REMOVE,
}

interface RouteConfig extends Pick<RouteProps, "path" | "exact" | "strict"> {
  id: string;
}

interface RouteState {
  routes: RouteConfig[];
}

export interface RouteContextProps {
  /**
   * @returns a method that removes this route
   */
  addRoute: (props: RouteProps) => () => void;
  exists: (path: string) => boolean;
}

export interface withRouteProps {
  Route: RouteContextProps;
}

export const RouteContext = createContext<RouteContextProps>(
  {} as RouteContextProps
);

function stateReducer(
  state: RouteState,
  action: {
    type: Action;
    payload: RouteConfig;
  }
) {
  if (action.type === Action.ADD) {
    return { routes: [...state.routes, action.payload] };
  } else if (action.type === Action.REMOVE) {
    const routes = [...state.routes];
    const idx = routes.findIndex((r) => r.id === action.payload.id);
    routes.splice(idx, 1);
    return { routes: routes };
  } else {
    return state;
  }
}

export const RouteContextProvider: FunctionComponent = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, { routes: [] });

  const addRoute = useCallback((props: RouteProps) => {
    const payload = { ...props, id: uuidv4() };
    setState({ type: Action.ADD, payload: payload });
    return () => {
      setState({ type: Action.REMOVE, payload: payload });
    };
  }, []);

  const exists = useCallback(
    (path: string) => {
      return state.routes.some((props) => matchPath(path, props));
    },
    [state.routes]
  );

  return (
    <RouteContext.Provider value={{ addRoute, exists }}>
      {children}
    </RouteContext.Provider>
  );
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
