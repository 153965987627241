import React, { useEffect, useRef, useState } from "react";
import { Page, PageHeader } from "../Page";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  Card,
  Form,
  LysaFormRef,
  NewPasswordInput,
  PasswordConfirmValidator,
  PasswordInput,
  PasswordValidator,
  Button,
  RequiredValidator,
  SNACKBAR_TYPES,
  Spinner,
  TextInput,
  useFlash,
} from "@lysaab/ui-2";
import { ChangePasswordResult, dataLogin } from "../../data/dataLogin";
import "./ChangePasswordPage.scss";

export const CHANGE_PASSWORD_PAGE_URL = "/profile/change-password";

const messages = defineMessages({
  currentPasswordLabel: { id: "change_password.current_password.label" },
  currentPasswordRequired: {
    id: "change_password.current_password.error.required",
  },
  submitButton: { id: "change_password.button.submit" },
  newPasswordLabel: { id: "change_password.new_password.label" },
  newPasswordConfirmLabel: { id: "change_password.new_password_confirm.label" },
  newPasswordRequired: { id: "change_password.new_password.error.required" },
  newPasswordInvalid: { id: "change_password.new_password.error.invalid" },
  newPasswordConfirmRequired: {
    id: "change_password.new_password_confirm.error.required",
  },
  newPasswordConfirmMismatch: {
    id: "change_password.new_password_confirm.error.mismatch",
  },
  changePasswordSuccess: { id: "change_password.flash.success" },
  changePasswordMismatch: { id: "change_password.flash.mismatch" },
  changePasswordFailed: { id: "change_password.flash.fail" },
  username: { id: "change_password.username" },
});

export const ChangePasswordPage: React.FC = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");
  const lysaForm = useRef<LysaFormRef>();
  const pushFlash = useFlash();
  const [username, setUsername] = useState("");

  useEffect(() => {
    setIsLoading(true);

    dataLogin
      .getUsername()
      .then((username) => {
        setUsername(username);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);

        pushFlash({
          text: intl.formatMessage(messages.changePasswordMismatch),
          type: SNACKBAR_TYPES.ERROR,
        });
      });
  }, [intl, pushFlash]);

  return (
    <Page className="change-password-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="change_password.page.header" />
        </h1>
      </PageHeader>

      <Form
        lysaFormRef={lysaForm}
        onSubmit={(event) => {
          event.preventDefault();
          if (lysaForm.current?.isValid && currentPassword && newPassword) {
            setIsLoading(true);

            dataLogin
              .passwordChange(currentPassword, newPassword)
              .then(({ result }) => {
                if (result === ChangePasswordResult.OK) {
                  setNewPassword("");
                  setPasswordCopy("");

                  pushFlash({
                    text: intl.formatMessage(messages.changePasswordSuccess),
                    type: SNACKBAR_TYPES.SUCCESS,
                  });
                } else if (result === ChangePasswordResult.MISMATCH) {
                  pushFlash({
                    text: intl.formatMessage(messages.changePasswordMismatch),
                    type: SNACKBAR_TYPES.ERROR,
                  });
                } else {
                  pushFlash({
                    text: intl.formatMessage(messages.changePasswordFailed),
                    type: SNACKBAR_TYPES.ERROR,
                  });
                }
              })
              .catch((e) => {
                console.error("e", e);
                pushFlash({
                  text: intl.formatMessage(messages.changePasswordFailed),
                  type: SNACKBAR_TYPES.ERROR,
                });
              })
              .finally(() => setIsLoading(false));
          }
        }}
        className="content-wrapper"
      >
        <Card>
          {isLoading && <Spinner />}

          <TextInput
            label={intl.formatMessage(messages.username)}
            name="username"
            autoComplete="username"
            disabled
            value={username || ""}
          />

          <PasswordInput
            label={intl.formatMessage(messages.currentPasswordLabel)}
            value={currentPassword}
            onChange={setCurrentPassword}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.currentPasswordRequired)
              ),
            ]}
          />

          <NewPasswordInput
            label={intl.formatMessage(messages.newPasswordLabel)}
            value={newPassword}
            onChange={setNewPassword}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.newPasswordRequired)
              ),
              new PasswordValidator(
                intl.formatMessage(messages.newPasswordInvalid)
              ),
            ]}
          />
          <NewPasswordInput
            label={intl.formatMessage(messages.newPasswordConfirmLabel)}
            value={passwordCopy}
            onChange={setPasswordCopy}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.newPasswordConfirmRequired)
              ),
              new PasswordConfirmValidator(
                intl.formatMessage(messages.newPasswordConfirmMismatch),
                newPassword
              ),
            ]}
          />
        </Card>

        <Button
          block
          type="submit"
          disabled={isLoading}
          label={intl.formatMessage(messages.submitButton)}
        />
      </Form>
    </Page>
  );
};
