import React, { useState } from "react";
import cx from "classnames";
import "./PercentageCurrencySwitch.scss";
import { useCurrencySymbol } from "../../hooks/useCurrencySymbol";

const PRIMARY = "primary";
const SECONDARY = "secondary";

interface Props {
  id?: string;
  value: boolean;
  onChange: () => void;
  variant?: typeof PRIMARY | typeof SECONDARY;
}

export function PercentageCurrencySwitch({
  value,
  onChange,
  id,
  variant = PRIMARY,
}: Props) {
  const [idState] = useState(
    id || "toggle_" + Math.random().toString(36).substr(2, 9)
  );
  const contentLeft = "%";
  const contentRight = useCurrencySymbol();

  const isUpperCase = (content: string) => {
    return /^[A-Z]*$/.test(content);
  };

  return (
    <div
      className={`lysa-ui-toggle percentage-currency-toggle toggle-${variant}`}
    >
      <input id={idState} type="checkbox" checked={value} onChange={onChange} />
      <label htmlFor={idState}>
        <div className="toggle-text-wrapper">
          <div className="toggle-text-inner-wrapper">
            <span
              className={cx(`toggle-text`, {
                active: value,
              })}
            >
              {contentLeft}
            </span>
          </div>
          <div className="toggle-text-inner-wrapper">
            <span
              className={cx(`toggle-text`, {
                active: !value,
                "toggle-sm-text": isUpperCase(contentRight),
                "toggle-md-text":
                  !isUpperCase(contentRight) && contentRight.length > 2,
              })}
            >
              {contentRight}
            </span>
          </div>
        </div>
        <span className="toggle-handle" />
      </label>
    </div>
  );
}
