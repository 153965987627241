import React, { useContext } from "react";
import {
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Checkbox,
} from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";

const alternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

export const TaxQuestion: React.FC = () => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);

  return (
    <div className="experience-question">
      <RadioGroup
        header="Kapitalförsäkring är en sparform där det inte utgår skatt vid försäljning eller byten av värdepapper men där det istället utgår en årlig schablonskatt på hela kapitalet. Stämmer detta?"
        alternatives={alternatives}
        value={kfState.hasTaxKnowledge}
        onChange={(value) =>
          setKFState({
            hasTaxKnowledge: value,
          })
        }
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
      {kfState.hasTaxKnowledge?.value === false && (
        <>
          <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
            Observera att kapitalförsäkring är en sparform där det inte utgår
            skatt vid försäljning eller byten av värdepapper men att det
            istället utgår en årlig schablonskatt på hela kapitalet.
          </Snackbar>
          <Checkbox
            alternative={{
              text: "Jag förstår.",
              value: true,
            }}
            checked={!!kfState.hasTaxKnowledgeCheckbox}
            onChange={() =>
              setKFState({
                hasTaxKnowledgeCheckbox: !kfState.hasTaxKnowledgeCheckbox,
              })
            }
            validators={[
              new RequiredValidator("Du måste bekräfta att du förstår"),
            ]}
          />
        </>
      )}
    </div>
  );
};
