import { StatusLabel, STATUS_TYPE, Card } from "@lysaab/ui-2";
import React, { useState, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { FormattedDate } from "react-intl";
import { Amount } from "../../../../../../components/amount/Amount";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { TransparentButton } from "../../../../../../components/transparentButton/TransparentButton";
import { ElevioLink } from "../../../../../../components/elevio/ElevioLink";
import {
  dataDirectDebit,
  PendingMonthlyDepositTransactionResponse,
} from "../../../../../../data/dataDirectDebit";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import { CardBottom } from "../../../../../account/CardBottom";

import "./PendingDirectDebitTransactions.scss";

export const PendingDirectDebitTransactions = () => {
  const [pendingDirectDebitTransactions, setPendingDirectDebitTransactions] =
    useState<PendingMonthlyDepositTransactionResponse[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { accounts } = useAccounts();

  useEffect(() => {
    dataDirectDebit
      .getPendingTransactions()
      .then(setPendingDirectDebitTransactions);
  }, []);

  if (
    pendingDirectDebitTransactions.length === 0 ||
    accounts?.investmentAccounts.length === 0
  ) {
    return null;
  }

  return (
    <section>
      <GridRow>
        <GridCol
          xsmall={12}
          small={6}
          large={4}
          className="pending-direct-debit-transactions"
        >
          <h2 className="title">
            <TranslatedText id="monthly.overview.pending-table.header" />
            <StatusLabel icon={false} type={STATUS_TYPE.INFO} className="badge">
              {pendingDirectDebitTransactions.length}
            </StatusLabel>
          </h2>
          <p>
            <TranslatedText
              id="monthly.overview.pending-table.intro"
              values={{
                elevio: (parts: string) => (
                  <ElevioLink articleId="169" linkText={parts} />
                ),
              }}
            />
          </p>
          <Card>
            <AnimateHeight
              height={isOpen ? "auto" : 200}
              animateOpacity
              id="dd-transactions"
            >
              <div className="pending-direct-debit-transactions__content">
                {pendingDirectDebitTransactions.map(
                  (pendingDirectDebitTransaction) => {
                    const accountName = accounts?.investmentAccounts.find(
                      (account) =>
                        account.accountId ===
                        pendingDirectDebitTransaction.accountId
                    )?.name;

                    if (typeof accountName === "undefined") {
                      return null;
                    }
                    return (
                      <div
                        className="pending-direct-debit-transactions__transaction"
                        key={
                          pendingDirectDebitTransaction.directDebitReferenceId
                        }
                      >
                        <TranslatedText id="monthly.overview.pending-table.from-account.header" />
                        <div className="pending-direct-debit-transactions__detail">
                          {pendingDirectDebitTransaction.externalAccountNumber}
                        </div>
                        <TranslatedText id="monthly.overview.pending-table.to-account.header" />
                        <div className="pending-direct-debit-transactions__detail">
                          {accountName}
                        </div>
                        <TranslatedText id="monthly.overview.pending-table.expecte-investment-date.header" />
                        <div className="pending-direct-debit-transactions__detail">
                          <FormattedDate
                            value={
                              pendingDirectDebitTransaction.calculateConcludeDate
                            }
                          />
                        </div>
                        <TranslatedText id="monthly.overview.pending-table.ongoing-transaction.header" />
                        <div className="pending-direct-debit-transactions__detail">
                          <Amount
                            amount={Number(
                              pendingDirectDebitTransaction.amount
                            )}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </AnimateHeight>
            <CardBottom>
              <TransparentButton
                aria-expanded={isOpen}
                aria-controls="dd-transactions"
                onClick={() => setIsOpen(!isOpen)}
                className="pending-direct-debit-transactions__bottom-button"
              >
                {isOpen ? (
                  <TranslatedText id="monthly.overview.pending-table.showLess" />
                ) : (
                  <TranslatedText id="monthly.overview.pending-table.showMore" />
                )}
              </TransparentButton>
            </CardBottom>
          </Card>
        </GridCol>
      </GridRow>
    </section>
  );
};
