import React, { useReducer } from "react";
import { CrsSwedishResponse } from "../../../../../data/dataCrs";

export interface CrsState {
  crsInformation?: CrsSwedishResponse;
}

export interface CrsContextProps {
  state: CrsState;
  setState: (newState: Partial<CrsState>) => void;
}

export const CrsContext = React.createContext<CrsContextProps>(
  {} as CrsContextProps
);

function stateReducer(state: CrsState, newState: Partial<CrsState>) {
  return { ...state, ...newState };
}

export const CrsContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <CrsContext.Provider value={{ state, setState }}>
      {children}
    </CrsContext.Provider>
  );
};
