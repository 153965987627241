import React from "react";
import cx from "classnames";
import {
  CashTransactionType,
  CashTransaction,
} from "../../data/dataTransactions";
import { dataPerformance } from "../../data/dataPerformance";
import { Amount } from "../../components/amount/Amount";
import { FormattedMessage } from "react-intl";
import "./TransactionEntry.scss";

interface Props {
  transaction: CashTransaction;
}

export const TransactionEntry: React.SFC<Props> = ({ transaction }) => {
  const isDeposit = transaction.type === CashTransactionType.DEPOSIT;
  const classes = cx(
    "transaction-entry",
    `transaction-entry-${transaction.type.toLowerCase()}`
  );

  return (
    <div className={classes}>
      <div>
        <div className="transaction-entry-header">
          <i>
            {dataPerformance.toStringDate(
              dataPerformance.toJsDate(transaction.booked)
            )}
          </i>
        </div>
        <div className="transaction-entry-type">
          <span className="transaction-entry-indicator" />{" "}
          {isDeposit ? (
            <FormattedMessage id="accountPage.transactions.deposit" />
          ) : (
            <FormattedMessage id="accountPage.transactions.withdrawal" />
          )}
        </div>
      </div>

      <div className="text-right">
        <div className="transaction-entry-header">
          <FormattedMessage id="accountPage.transactions.amount" />
        </div>
        <div className="transaction-entry-type">
          <Amount amount={transaction.amount} />
        </div>
      </div>
    </div>
  );
};
