import React, { VoidFunctionComponent } from "react";
import { DocModal } from "@lysaab/ui-2";
import { useContext } from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { TranslatedText } from "../TranslatedText";
import { LysaCountry } from "@lysaab/countries";

interface Props {}

const documents: Record<LysaCountry, string[]> = {
  [LysaCountry.DENMARK]: ["legal/all/en/model-assumptions.md"],
  [LysaCountry.SWEDEN]: ["legal/se/sv/modellantaganden.md"],
  [LysaCountry.FINLAND]: ["legal/all/en/model-assumptions.md"],
  [LysaCountry.GERMANY]: ["legal/de/de/model-assumptions.md"],
  [LysaCountry.SPAIN]: ["legal/es/en/model-assumptions.md"],
};

const messages = defineMessages({
  link: {
    id: "investment-disclaimer.model-link",
  },
  announcement: {
    id: "investment-disclaimer.modal.announcement",
  },
  placeholder: {
    id: "investment-disclaimer.modal.placeholder",
  },
  header: {
    id: "investment-disclaimer.modal.header",
  },
});

export const InvestmentDisclaimer: VoidFunctionComponent<Props> = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <React.Fragment>
      <TranslatedText
        id="investment-disclaimer.text"
        values={{
          link: (...chunks: string[]) => {
            return (
              <button
                onClick={() => setIsModalOpen(true)}
                className="transparent-button as-link"
              >
                {chunks.map((chunk, idx) => (
                  <React.Fragment key={idx}>{chunk}</React.Fragment>
                ))}
              </button>
            );
          },
        }}
      />
      <DocModal
        active={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        docList={documents[localizationContext.state.country]}
        modalAnnouncement={intl.formatMessage(messages.announcement)}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        variableValues={{}}
        header={intl.formatMessage(messages.header)}
      />
    </React.Fragment>
  );
};
