import React, { FunctionComponent, useState, useEffect } from "react";
import Linkify from "react-linkify";
interface Props {
  text: string;
}
export const HtmlEntityDecoder: FunctionComponent<Props> = ({ text }) => {
  const [decodedText, setDecodedText] = useState("");
  useEffect(() => {
    const txt = document.createElement("textarea");
    txt.innerHTML = text;
    setDecodedText(txt.value);
  }, [setDecodedText, text]);
  return <Linkify>{decodedText}</Linkify>;
};
