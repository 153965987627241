import { defineMessages } from "react-intl";
import { FailedHintCode, PendingHintCode } from "@lysaab/ui-2";

export const signingMessages = defineMessages({
  qrInfo1: {
    id: "sweden.isk.move.bankid.qrInfo1",
    description: "",
    defaultMessage: "Open the BankID app on your phone",
  },
  qrInfo2: {
    id: "sweden.isk.move.bankid.qrInfo2",
    description: "",
    defaultMessage: "Click the QR code in the app",
  },
  qrInfo3: {
    id: "sweden.isk.move.bankid.qrInfo3",
    description: "",
    defaultMessage: "Point the camera at the QR code on the screen",
  },
  divider: {
    id: "sweden.isk.move.bankid.divider",
    description: "",
    defaultMessage: "OR",
  },
  buttonOpen: {
    id: "sweden.isk.move.bankid.buttonOpen",
    description: "",
    defaultMessage: "Open BankID on this device",
  },
  buttonErrorHeader: {
    id: "sweden.isk.move.bankid.buttonErrorHeader",
    description: "",
    defaultMessage: "Something went wrong",
  },
  buttonRetry: {
    id: "sweden.isk.move.bankid.buttonRetry",
    description: "",
    defaultMessage: "Retry",
  },
});

export const signPendingMessages = defineMessages({
  [PendingHintCode.NOCLIENT]: {
    id: "sweden.isk.move.hint.pending.noclient",
    description: "BankID pending hint code no client",
    defaultMessage: "Cancelled",
  },
  [PendingHintCode.OUTSTANDINGTRANSACTION]: {
    id: "sweden.isk.move.hint.pending.outstandingtransaction",
    description: "BankID pending hint code outstanding transaction",
    defaultMessage: "Cancelled",
  },
  [PendingHintCode.STARTED]: {
    id: "sweden.isk.move.hint.pending.started",
    description: "BankID pending hint code started",
    defaultMessage: "Cancelled",
  },
  [PendingHintCode.USERSIGN]: {
    id: "sweden.isk.move.hint.pending.usersign",
    description: "BankID pending hint code user signing",
    defaultMessage: "User signing",
  },
});

export const signFailedMessages = defineMessages({
  [FailedHintCode.CANCELLED]: {
    id: "sweden.isk.move.hint.failed.cancelled",
    description: "BankID failed hint code cancelled",
    defaultMessage: "Cancelled",
  },
  [FailedHintCode.CERTIFICATEERR]: {
    id: "sweden.isk.move.hint.failed.certificateerr",
    description: "BankID failed hint code certificate error",
    defaultMessage: "Certificate error",
  },
  [FailedHintCode.EXPIREDTRANSACTION]: {
    id: "sweden.isk.move.hint.failed.expiredtransaction",
    description: "BankID failed hint code expired transaction",
    defaultMessage: "Expired transaction",
  },
  [FailedHintCode.STARTFAILED]: {
    id: "sweden.isk.move.hint.failed.startfailed",
    description: "BankID failed hint code start failed",
    defaultMessage: "Start failed",
  },
  [FailedHintCode.USERCANCEL]: {
    id: "sweden.isk.move.hint.failed.usercancel",
    description: "BankID failed hint code user canceled",
    defaultMessage: "User canceled",
  },
});
