import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { Button, Typography } from "@lysaab/ui-2";
import cx from "classnames";
import { dataBanks } from "../../../data/dataBanks";
import { ExternalAccount } from "../../../data/dataWithdrawals";
import { TranslatedText } from "../../../components/TranslatedText";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { ADD_EXTERNAL_KLARNA_URL } from "../addAccountKlarna/AddAccountKlarnaStory";
import {
  ConfirmActionOverlay,
  ConfirmRef,
} from "../../../components/confirmActionOverlay/ConfirmActionOverlay";
import { defineMessages, useIntl } from "react-intl";
import { BankLogo } from "../../../components/bankLogo/BankLogo";

import "./ExternalAccounts.scss";

const messages = defineMessages({
  cancelButton: {
    id: "withdrawals.accounts.abort",
  },
  confirmButton: {
    id: "withdrawals.accounts.removeConfirm",
  },
});

interface Props {
  waitingAccountsComponent?: ReactNode;
  externalAccounts: ExternalAccount[];
  removeAccount: (id: string) => void;
}

export const ExternalAccounts: React.FunctionComponent<Props> = ({
  externalAccounts,
  removeAccount,
  waitingAccountsComponent,
}) => {
  const [confirmWarning, setConfirmWarning] = useState<string>("");

  useEffect(() => {
    setConfirmWarning("");
  }, [externalAccounts]);

  if (!externalAccounts.length) {
    return <div className="withdrawal-page-external-accounts" />;
  }

  return (
    <div
      className={cx("withdrawal-page-external-accounts", {
        "show-overlay": !!confirmWarning,
      })}
    >
      <Typography type="h3">
        <TranslatedText id="withdrawals.accounts.header" />
      </Typography>

      {waitingAccountsComponent}

      <div className="relative">
        <div className="withdrawal-page-external-accounts-overlay" />
        {externalAccounts.map((item) => (
          <ExternalAccountRow
            key={item.externalBankAccount}
            externalAccount={item}
            removeAccount={() => removeAccount(item.externalBankAccount)}
          />
        ))}
      </div>
    </div>
  );
};

const ExternalAccountRow: VoidFunctionComponent<{
  externalAccount: ExternalAccount;
  removeAccount: () => void;
}> = ({ externalAccount, removeAccount }) => {
  const intl = useIntl();
  const bank = dataBanks.getBank(externalAccount.bank);
  const confirmRef = useRef<ConfirmRef>();
  return (
    <div className="external-account-item-wrapper">
      <ConfirmActionOverlay
        confirmRef={confirmRef}
        negative
        cancelButtonText={intl.formatMessage(messages.cancelButton)}
        confirmButtonText={intl.formatMessage(messages.confirmButton)}
        onConfirm={() => removeAccount()}
      >
        <div className="external-account-item">
          <div className="external-account-item-logo-and-number">
            <BankLogo className="logo" bank={bank} size={40} />
            <div className="external-account-item-bank-and-number">
              <strong>{bank.short}</strong> -{" "}
              {externalAccount.externalBankAccount}
            </div>
          </div>
          {/**
           * Countries that can't add accounts can't remove them either
           */}
          <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_KLARNA_URL)}>
            <Button
              size="small"
              variant="negative"
              onClick={() => {
                confirmRef.current?.setConfirm(true);
              }}
              label={<TranslatedText id="withdrawals.accounts.remove" />}
              inline
              className="external-account-item-button"
            />
          </RouteAwareToggle>
        </div>
      </ConfirmActionOverlay>
    </div>
  );
};
