import {
  BaseValidator,
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2";

export class TextLengthValidator extends BaseValidator {
  protected name = "TextLengthValidator";
  private min: number;
  private max: number;

  constructor(min: number, max: number, error: string) {
    super(error);
    this.min = min;
    this.max = max;
  }

  validate(value: string = ""): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length < this.min) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true,
        });
      } else if (value.length > this.max) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true,
        });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
