import React from "react";
import {
  KFSigningStatusPendingResponse,
  KFSignStatus,
} from "../../../../../../data/dataCreateAccount";
import { Button, Icon } from "@lysaab/ui-2";
import { useHistory } from "react-router";

interface Props {
  signingStatusPendingResponse: KFSigningStatusPendingResponse;
  futurSignRoute: string;
  signingId?: string;
}

const rolesMap = {
  BENEFICIALOWNER: "förmånstagare",
  INSURED: "försäkrad",
  SIGNATORY: "undertecknare",
};

function getRoles(roles: string[]) {
  const mappedRoles = roles.map(
    (role) => rolesMap[role as keyof typeof rolesMap]
  );
  return mappedRoles.join(", ");
}

export const Signers: React.FC<Props> = ({
  signingStatusPendingResponse,
  futurSignRoute,
  signingId,
}) => {
  const { signRequests } = signingStatusPendingResponse;
  const history = useHistory();

  return (
    <div className="signers">
      <ul>
        {signRequests.map((signer) => {
          return (
            <li key={signer.userId}>
              <span>
                {signer.fullName} (<i>{getRoles(signer.roles)}</i>)
              </span>
              {signer.signStatus === KFSignStatus.COMPLETED ? (
                <Icon.Checkmark />
              ) : (
                <div>
                  <Button
                    size="small"
                    onClick={() => {
                      history.push(
                        futurSignRoute
                          .replace(":signingId", signingId || "")
                          .replace(":userId", `${signer.userId}`)
                      );
                    }}
                    label="Signera"
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
