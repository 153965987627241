import React, { useContext } from "react";
import {
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Checkbox,
} from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";

const alternatives = [
  {
    text: "Ja",
    value: true,
  },
  {
    text: "Nej",
    value: false,
  },
];

export const BeneficiaryQuestion: React.FC = () => {
  const { state: kfState, setState: setKFState } = useContext(KFAccountContext);

  return (
    <div className="experience-question">
      <RadioGroup
        header="Kapitalförsäkring är en sparform där kapitalet utbetalas till den fastställda förmånstagaren om den försäkrade skulle avlida. Stämmer detta?"
        alternatives={alternatives}
        value={kfState.hasBeneficiaryKnowledge}
        onChange={(value) =>
          setKFState({
            hasBeneficiaryKnowledge: value,
          })
        }
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
      />
      {kfState.hasBeneficiaryKnowledge?.value === false && (
        <>
          <Snackbar icon={true} type={SNACKBAR_TYPES.WARNING}>
            Observera att kapitalförsäkring är en sparform där kapitalet
            utbetalas till förmånstagaren om den försäkrade skulle avlida.
          </Snackbar>
          <Checkbox
            alternative={{
              text: "Jag förstår.",
              value: true,
            }}
            checked={!!kfState.hasBeneficiaryKnowledgeCheckbox}
            onChange={() =>
              setKFState({
                hasBeneficiaryKnowledgeCheckbox: !kfState.hasBeneficiaryKnowledgeCheckbox,
              })
            }
            validators={[
              new RequiredValidator("Du måste bekräfta att du förstår"),
            ]}
          />
        </>
      )}
    </div>
  );
};
