import { CardListItem } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { SkeletonThread } from "../../../components/messages/SkeltonThread";
import { Thread } from "../../../components/messages/Thread";
import { MessagesContext } from "../../../context/MessagesContext";
import { MessagesStatus } from "../../../data/dataMessages";

interface Props {
  isLoading: boolean;
  reloadMessages: () => void;
  setIsLoading: () => void;
}

export const ActiveMessages: React.VFC<Props> = ({
  isLoading,
  reloadMessages,
  setIsLoading,
}) => {
  const messagesContext = useContext(MessagesContext);

  if (isLoading) {
    return (
      <CardListItem>
        <SkeletonThread />
      </CardListItem>
    );
  }

  return (
    <>
      {messagesContext.state.messages
        .filter((thread) => thread.status !== MessagesStatus.CLOSED)
        .map((thread) => {
          return (
            <CardListItem key={thread.id}>
              <Thread
                thread={thread}
                reloadMessages={reloadMessages}
                setIsLoading={setIsLoading}
              />
            </CardListItem>
          );
        })}
    </>
  );
};
