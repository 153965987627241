import {
  Card,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  WorldCountry,
} from "@lysaab/ui-2";
import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import {
  CrsSwedishResponse,
  dataCrs,
  instanceOfCrsSwedishResponseWithAddress,
} from "../../../../../data/dataCrs";
import {
  dataLegalEntity,
  EntityDetailsResponse,
} from "../../../../../data/dataLegalEntity";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";
import { UPDATE_CRS_URL } from "../../../../../pages/updateCrsStory/UpdateCrsStory";
import { getCountryFromCountryCode } from "../../../../../utils/getCountryFromCountryCode";

import "./CurrentCrsInformation.scss";
import { EntityDetailsInformation } from "./EntityDetailsInformation";

export const CurrentCrsInformation: VoidFunctionComponent = () => {
  const [serverCrs, setServerCrs] = useState<CrsSwedishResponse>();
  const [entityDetails, setEntityDetails] = useState<EntityDetailsResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const language = useContext(LocalizationContext).state.language;
  const history = useHistory();
  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    setIsLoading(true);

    const profileRequest = dataLegalEntity
      .getProfileSettings()
      .then((response) => {
        setEntityDetails(response);
      });

    const crsRequest = dataCrs
      .getLatestCrsSwedish()
      .then((response) => {
        setServerCrs(response);
      })
      .catch(() => setError(true));

    Promise.all([profileRequest, crsRequest]).finally(() =>
      setIsLoading(false)
    );
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div className="current-crs-information">
      <h2>
        <TranslatedText id="sweden.crs.currentCrsInformation.header" />
      </h2>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.crs.currentCrsInformation.error" />
          </Snackbar>
        )}

        {serverCrs !== undefined && (
          <dl>
            {entityDetails && (
              <EntityDetailsInformation
                entityDetails={entityDetails.details}
                entityType={entityDetails.entityType}
              />
            )}
            {serverCrs.countries.map(({ country, tin }) => (
              <React.Fragment key={country}>
                <div className="list-row">
                  <dt>
                    <TranslatedText id="sweden.crs.currentCrsInformation.taxResidence" />
                  </dt>
                  <dd>
                    {getCountryFromCountryCode({
                      country,
                      language,
                    }) + " "}
                    ({country})
                  </dd>
                </div>
                <div className="list-row">
                  <dt>
                    <TranslatedText
                      id="sweden.crs.currentCrsInformation.tin"
                      values={{ country }}
                    />
                  </dt>
                  <dd>{tin}</dd>
                </div>
              </React.Fragment>
            ))}

            {instanceOfCrsSwedishResponseWithAddress(serverCrs) && (
              <React.Fragment>
                <div className="list-row">
                  <dt>
                    <TranslatedText id="sweden.crs.currentCrsInformation.countryOfResidence" />
                  </dt>
                  <dd>
                    {getCountryFromCountryCode({
                      country: serverCrs.residentCountry || WorldCountry.SWEDEN,
                      language,
                    }) + " "}
                    ({serverCrs.residentCountry})
                  </dd>
                </div>

                <div className="list-row">
                  <dt>
                    <TranslatedText id="sweden.crs.currentCrsInformation.street" />
                  </dt>
                  <dd>{serverCrs.street}</dd>
                </div>

                <div className="list-row">
                  <dt>
                    <TranslatedText id="sweden.crs.currentCrsInformation.postalCode" />
                  </dt>
                  <dd>{serverCrs.zipCode}</dd>
                </div>

                <div className="list-row">
                  <dt>
                    <TranslatedText id="sweden.crs.currentCrsInformation.city" />
                  </dt>
                  <dd>{serverCrs.city}</dd>
                </div>
              </React.Fragment>
            )}
          </dl>
        )}
        <Button
          onClick={() => history.push(getNavLink(UPDATE_CRS_URL))}
          block
          disabled={isReadOnly}
          label={
            <TranslatedText id="sweden.crs.currentCrsInformation.updateButton" />
          }
        />
      </Card>
    </div>
  );
};
