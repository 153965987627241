import React, { FunctionComponent, useContext } from "react";
import { Slider } from "@lysaab/ui-2";
import { FormattedMessage, FormattedNumber } from "react-intl";
import "./Slider.scss";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useAssetSteps } from "../../hooks/useAssetSteps";

interface Props {
  value: number;
  setValue: (newValue: number) => void;
}

export const DebtsSlider: FunctionComponent<Props> = ({ value, setValue }) => {
  const localizationContext = useContext(LocalizationContext);
  const steps = useAssetSteps();

  if (!steps) {
    return null;
  }

  const label = (
    <div>
      <div className="situation-slider-text">
        <span>
          <FormattedMessage id="situation.slider.debt.label" />
        </span>
        <span>
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            value={value || 0}
            currency={localizationContext.state.currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </span>
      </div>
      <p>
        <FormattedMessage id="situation.slider.debt.description" />
      </p>
    </div>
  );

  return (
    <div className="situation-slider">
      <Slider
        min={0}
        max={steps.length - 1}
        step={1}
        value={steps.indexOf(value || 0)}
        onChange={(value) => setValue(steps[value])}
        label={label}
        data-test-id="debt-slider"
      />
    </div>
  );
};
