import React, { useContext, useRef, VoidFunctionComponent } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SustainabilityQuestion } from "../../../../pageComponents/sustainability/SustainabilityQuestion";
import { SustainabilityImportance } from "../../../../data/dataInvestments";
import { CreateAccountContext } from "../../CreateAccountContext";

interface Props {
  next(): void;
}

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const createAccountContext = useContext(CreateAccountContext);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (
          formRef.current?.isValid &&
          createAccountContext.state.sustainability !==
            SustainabilityImportance.IMPORTANT
        ) {
          next();
        }
      }}
    >
      <SustainabilityQuestion
        sustainability={createAccountContext.state.sustainability}
        setSustainability={(sustainability) =>
          createAccountContext.setState({ sustainability })
        }
      />
      {createAccountContext.state.sustainability ===
        SustainabilityImportance.IMPORTANT && (
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <FormattedMessage id="createAccount.sustainability.IMPORTANT.error" />
        </Snackbar>
      )}
      <Button
        block
        type="submit"
        label={<FormattedMessage id="createAccount.sustainability.next" />}
      />
    </Form>
  );
};
