import { Card, ConfirmCard, CONFIRM_BUTTON_TYPES, Spinner } from "@lysaab/ui-2";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { LysaTable } from "../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { UserContext } from "../../../../../context/UserContext";
import {
  AgreementStatus,
  dataMobilepay,
  GetAgreementResponse,
} from "../../../../../data/dataMobilepay";
import { MobilePayLogo } from "../../deposits/monthly/images/MobilePayLogo";

import "./OverviewAgreement.scss";

const messages = defineMessages({
  cancelButton: {
    id: "denmark.monthlyAgreements.overviewAgreement.cancelButton",
  },
  confirmButton: {
    id: "denmark.monthlyAgreements.overviewAgreement.confirmButton",
  },
  cancelText: {
    id: "denmark.monthlyAgreements.overviewAgreement.cancelText",
  },
  remove: {
    id: "denmark.monthlyAgreements.overviewAgreement.remove",
  },
  mobileNumber: {
    id: "denmark.monthlyAgreements.overviewAgreement.mobileNumber",
  },
  agreementDay: {
    id: "denmark.monthlyAgreements.overviewAgreement.day",
  },
  created: {
    id: "denmark.monthlyAgreements.overviewAgreement.created",
  },
  lastUpdated: {
    id: "denmark.monthlyAgreements.overviewAgreement.lastUpdated",
  },
});

export const OverviewAgreement = () => {
  const [agreementResponse, setAgreementResponse] = useState<
    GetAgreementResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const intl = useIntl();
  const { state: user } = useContext(UserContext);

  const getMonthlyAgreements = useCallback(() => {
    setIsLoading(true);
    dataMobilepay
      .getAllAgreements()
      .then(setAgreementResponse)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getMonthlyAgreements();
  }, [getMonthlyAgreements]);

  if (isLoading) {
    return <Spinner />;
  }

  const acceptedAgreements = agreementResponse.filter(
    (agreement) => agreement.status === AgreementStatus.ACCEPTED
  );

  return (
    <div className="overview-agreement">
      <h2>
        <TranslatedText id="denmark.monthlyAgreement.overviewAgreement.active" />
      </h2>
      <ConfirmCard
        cancelButtonText={intl.formatMessage(messages.cancelButton)}
        confirmButtonText={intl.formatMessage(messages.confirmButton)}
        confirmText={intl.formatMessage(messages.cancelText)}
        onConfirm={(status) => {
          setShowConfirm(false);
          if (!status || user.readOnly) {
            return;
          }
          dataMobilepay.cancelAgreement().then(() => {
            return getMonthlyAgreements();
          });
        }}
        showConfirm={showConfirm}
        confirmButtonType={CONFIRM_BUTTON_TYPES.NEGATIVE}
      >
        <div className="overview-agreement__header-logo">
          <MobilePayLogo size={125} />
        </div>
        {acceptedAgreements.length > 0 ? (
          <LysaTable>
            <thead>
              <tr>
                <LysaTableTextCell
                  value={intl.formatMessage(messages.mobileNumber)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.agreementDay)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.created)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.lastUpdated)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.remove)}
                  header
                />
              </tr>
            </thead>
            <tbody>
              {acceptedAgreements.map((agreement) => {
                return (
                  <tr key={agreement.agreementId + agreement.phone}>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.mobileNumber)}
                      value={agreement.phone}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.agreementDay)}
                      value={agreement.targetPaymentDay.toString()}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.created)}
                      value={intl.formatDate(agreement.requestedAt)}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.lastUpdated)}
                      value={intl.formatDate(agreement.lastUpdatedAt)}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.remove)}
                    >
                      <Button
                        size="small"
                        variant="negative"
                        onClick={() => {
                          setShowConfirm(true);
                        }}
                        label={
                          <TranslatedText id="denmark.monthlyAgreements.overviewAgreement.removeButton" />
                        }
                      />
                    </LysaTableTextCell>
                  </tr>
                );
              })}
            </tbody>
          </LysaTable>
        ) : (
          <p>
            <TranslatedText id="denmark.monthlyAgreements.overviewAgreement.noAgreements" />
          </p>
        )}
      </ConfirmCard>

      {agreementResponse.some(
        (agreement) => agreement.status === AgreementStatus.CANCELED
      ) && (
        <React.Fragment>
          <h2>
            <TranslatedText id="denmark.monthlyAgreements.overviewAgreement.canceled" />
          </h2>
          <Card>
            <div className="overview-agreement__header-logo">
              <MobilePayLogo size={125} />
            </div>
            <LysaTable>
              <thead>
                <tr>
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.mobileNumber)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.agreementDay)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.created)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.lastUpdated)}
                    header
                  />
                </tr>
              </thead>
              <tbody>
                {agreementResponse
                  .filter(
                    (agreement) => agreement.status === AgreementStatus.CANCELED
                  )
                  .map((agreement) => {
                    return (
                      <tr key={agreement.agreementId + agreement.phone}>
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.mobileNumber)}
                          value={agreement.phone}
                        />
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.agreementDay)}
                          value={agreement.targetPaymentDay.toString()}
                        />
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.created)}
                          value={intl.formatDate(agreement.requestedAt)}
                        />
                        <LysaTableTextCell
                          label={intl.formatMessage(messages.lastUpdated)}
                          value={intl.formatDate(agreement.lastUpdatedAt)}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </LysaTable>
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};
