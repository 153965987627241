import React, { VoidFunctionComponent } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Amount } from "../../../../../../../components/amount/Amount";
import { GridCol } from "../../../../../../../components/grid/gridCol/GridCol";
import { InvestmentAccountId } from "../../../../../../../data/dataAccounts";
import { CashTransaction } from "../../../../../../../data/dataCorporate";
import { CashTransactionType } from "../../../../../../../data/dataTransactions";
import { DataDisplay } from "../../../../../../../components/dataDisplay/DataDisplay";
import { cashTypesMessages } from "../TransactionUtils";

export const CashFeeTax: VoidFunctionComponent<{
  transaction: CashTransaction;
  getAccountName: (accountId: InvestmentAccountId) => string;
}> = ({ transaction, getAccountName }) => {
  const intl = useIntl();
  return (
    <>
      <GridCol xsmall={2} className="text-container">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashFeeTax.title.date" />
          }
          text={<FormattedDate value={transaction.booked} />}
        />
      </GridCol>

      <GridCol xsmall={5} className="text-container">
        {transaction.transactionType !== CashTransactionType.TAX ? (
          <DataDisplay
            type="list-item"
            title={
              <FormattedMessage id="sweden.accountingPage.dataDisplay.cashFeeTax.title.counterpart" />
            }
            text={getAccountName(transaction.counterpart)}
          />
        ) : null}
      </GridCol>

      <GridCol xsmall={3} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashFeeTax.title.amount" />
          }
          text={<Amount amount={transaction.amount} decimals={2} />}
        />
      </GridCol>

      <GridCol xsmall={2} className="text-container text-right">
        <DataDisplay
          type="list-item"
          title={
            <FormattedMessage id="sweden.accountingPage.dataDisplay.cashFeeTax.title.type" />
          }
          text={intl.formatMessage(
            cashTypesMessages[transaction.transactionType]
          )}
        />
      </GridCol>
    </>
  );
};
