import React, { useEffect, useState } from "react";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../data/dataAutogiro";

export const WaitingAccounts = () => {
  const [waitingAccounts, setWaitingAccounts] = useState<Consent[]>();

  useEffect(() => {
    dataAutogiro.getConsents().then((response) => {
      setWaitingAccounts([...response].filter((account) => !account.approved));
    });
  }, []);

  if (!waitingAccounts || waitingAccounts.length === 0) {
    return null;
  }

  return (
    <Snackbar type={SNACKBAR_TYPES.INFO} icon>
      <TranslatedText
        id="withdrawals.accounts.waiting.text"
        values={{
          accounts: waitingAccounts.length,
          accountNumbers: waitingAccounts
            .map((a) => a.externalBankAccount)
            .join(", "),
        }}
      />
    </Snackbar>
  );
};
