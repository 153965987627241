import { LysaCountry } from "@lysaab/countries";
import { generatePath } from "react-router";
import { InvestmentAccountId, AccountType } from "../../data/dataAccounts";
import { LegalEntityType } from "../../data/dataLogin";
import { getNavLink } from "../../hooks/useCountryUrls";
import { DOCUMENT_PAGE_URL } from "./DocumentsPage";

export const customerDocuments: Record<
  LegalEntityType,
  Record<LysaCountry, string[][]>
> = {
  [LegalEntityType.PERSON]: {
    [LysaCountry.DENMARK]: [["legal/all/en/portfolio-management-agreement"]],
    [LysaCountry.FINLAND]: [["legal/all/en/portfolio-management-agreement"]],
    [LysaCountry.GERMANY]: [["legal/de/de/portfolio-management-agreement"]],
    [LysaCountry.SPAIN]: [["legal/es/en/customer-agreement"]],
    [LysaCountry.SWEDEN]: [["legal/se/sv/customer-agreement"]],
  },
  [LegalEntityType.CORPORATION]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [["legal/se/sv/customer-agreement"]],
  },
};

export const otherDocuments: Record<
  LegalEntityType,
  Record<LysaCountry, string[][]>
> = {
  [LegalEntityType.PERSON]: {
    [LysaCountry.DENMARK]: [
      [
        "legal/all/en/information-about-lysa-v-2021-1",
        "legal/all/en/information-about-lysa-v-2021-2",
      ],
      [
        "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments",
      ],
      ["legal/all/en/best-execution-policy-1"],
      ["legal/dk/en/depositor-information"],
      [
        "legal/dk/en/information-regarding-withdrawal-of-funds-through-nemkonto",
      ],
      ["legal/dk/en/tax-reporting-agreement"],
    ],
    [LysaCountry.FINLAND]: [
      [
        "legal/all/en/information-about-lysa-v-2021-1",
        "legal/all/en/information-about-lysa-v-2021-2",
      ],
      [
        "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments",
      ],
      ["legal/all/en/best-execution-policy-1"],
      ["legal/fi/en/depositor-information"],
    ],
    [LysaCountry.GERMANY]: [
      ["legal/de/de/information-about-lysa-v-2021-1"],
      [
        "legal/de/de/information-regarding-characteristics-and-risks-relating-to-financial-instruments",
      ],
      ["legal/de/de/best-execution-policy-1"],
      ["legal/de/de/depositor-information"],
    ],
    [LysaCountry.SPAIN]: [
      ["legal/es/en/information-about-lysa-v-2021-1"],
      [
        "legal/es/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments",
      ],
      ["legal/es/en/best-execution-policy-1"],
      ["legal/es/en/depositor-information"],
    ],
    [LysaCountry.SWEDEN]: [
      [
        "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument",
      ],
      ["legal/se/sv/bästa-orderutförande-1"],
      [
        "legal/se/sv/information-om-lysa-v-2021-1",
        "legal/se/sv/information-om-lysa-v-2021-2",
        "legal/se/sv/information-om-lysa-v-2022-1",
      ],
      ["legal/se/sv/depositor-information"],
      ["legal/se/sv/förhandsinformation-om-investeringssparkonto"],
    ],
  },
  [LegalEntityType.CORPORATION]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      [
        "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument",
      ],
      ["legal/se/sv/bästa-orderutförande-1"],
      [
        "legal/se/sv/information-om-lysa-v-2021-1",
        "legal/se/sv/information-om-lysa-v-2021-2",
        "legal/se/sv/information-om-lysa-v-2022-1",
      ],
      ["legal/se/sv/depositor-information"],
    ],
  },
};

export const otherSavingsAccountDocuments: Record<LysaCountry, string[][]> = {
  [LysaCountry.DENMARK]: [],
  [LysaCountry.FINLAND]: [],
  [LysaCountry.GERMANY]: [],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [
    ["legal/se/sv/viktigt-om-insättningsgaranti"],
    ["legal/se/sv/information-till-insättare"],
    ["legal/se/sv/information-till-insättare-borgo"],
  ],
};

const accountAgreementDocuments: Record<
  AccountType,
  Record<LysaCountry, string[]>
> = {
  [AccountType.VP]: {
    [LysaCountry.DENMARK]: ["legal/all/en/investment-account-agreement"],
    [LysaCountry.FINLAND]: ["legal/all/en/investment-account-agreement"],
    [LysaCountry.GERMANY]: ["legal/de/de/investment-account-agreement"],
    [LysaCountry.SPAIN]: ["legal/es/en/investment-account-agreement"],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.DANICA_KF]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/avtal-om-kapitalförsäkringsdepå"],
  },
  [AccountType.KF_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/avtal-om-kapitalförsäkringsdepå"],
  },
  [AccountType.ISK_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/avtal-om-isk"],
  },
  [AccountType.TJP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.VP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/depåavtal-1"],
  },
};

export const investmentGuidelinesDocuments: Record<
  AccountType,
  Record<LysaCountry, string[]>
> = {
  [AccountType.VP]: {
    [LysaCountry.DENMARK]: ["legal/all/en/investment-guidelines"],
    [LysaCountry.FINLAND]: ["legal/all/en/investment-guidelines"],
    [LysaCountry.GERMANY]: ["legal/de/de/investment-guidelines"],
    [LysaCountry.SPAIN]: ["legal/es/en/investment-guidelines"],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.DANICA_KF]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/placeringsriktlinjer"],
  },
  [AccountType.KF_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/placeringsriktlinjer"],
  },
  [AccountType.ISK_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/placeringsriktlinjer"],
  },
  [AccountType.TJP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.VP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: ["legal/se/sv/placeringsriktlinjer"],
  },
};

// Account documents that are versioned
export const versionedAccountDocuments: Record<
  AccountType,
  Record<LysaCountry, string[][]>
> = {
  [AccountType.VP]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.DANICA_KF]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      [
        "legal/se/sv/förhandsinformation-om-försäkringsförmedling",
        "legal/se/sv/förhandsinformation-om-försäkringsförmedling-v-2021-2",
      ],
    ],
  },
  [AccountType.KF_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      [
        "legal/se/sv/förhandsinformation-om-försäkringsförmedling",
        "legal/se/sv/förhandsinformation-om-försäkringsförmedling-v-2021-2",
      ],
    ],
  },
  [AccountType.ISK_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.TJP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.VP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
};

// Account documents that has no versioning (latest document should always apply)
export const accountDocuments: Record<
  AccountType,
  Record<LysaCountry, string[][]>
> = {
  [AccountType.VP]: {
    [LysaCountry.DENMARK]: [
      accountAgreementDocuments[AccountType.VP][LysaCountry.DENMARK],
      investmentGuidelinesDocuments[AccountType.VP][LysaCountry.DENMARK],
      ["legal/all/en/general-terms-and-conditions-for-investment-accounts"],
    ],
    [LysaCountry.FINLAND]: [
      accountAgreementDocuments[AccountType.VP][LysaCountry.FINLAND],
      investmentGuidelinesDocuments[AccountType.VP][LysaCountry.FINLAND],
      ["legal/all/en/general-terms-and-conditions-for-investment-accounts"],
    ],
    [LysaCountry.GERMANY]: [
      accountAgreementDocuments[AccountType.VP][LysaCountry.GERMANY],
      investmentGuidelinesDocuments[AccountType.VP][LysaCountry.GERMANY],
      ["legal/de/de/general-terms-and-conditions-for-investment-accounts"],
    ],
    [LysaCountry.SPAIN]: [
      accountAgreementDocuments[AccountType.VP][LysaCountry.SPAIN],
      investmentGuidelinesDocuments[AccountType.VP][LysaCountry.SPAIN],
      ["legal/es/en/general-terms-and-conditions-for-investment-accounts"],
    ],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.DANICA_KF]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      accountAgreementDocuments[AccountType.DANICA_KF][LysaCountry.SWEDEN],
      investmentGuidelinesDocuments[AccountType.DANICA_KF][LysaCountry.SWEDEN],
      ["legal/se/sv/allmänna-bestämmelser-för-kapitalförsäkringsdepå"],
    ],
  },
  [AccountType.KF_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      accountAgreementDocuments[AccountType.DANICA_KF][LysaCountry.SWEDEN],
      investmentGuidelinesDocuments[AccountType.DANICA_KF][LysaCountry.SWEDEN],
      ["legal/se/sv/allmänna-bestämmelser-för-kapitalförsäkringsdepå"],
    ],
  },
  [AccountType.ISK_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      accountAgreementDocuments[AccountType.ISK_SWE][LysaCountry.SWEDEN],
      investmentGuidelinesDocuments[AccountType.ISK_SWE][LysaCountry.SWEDEN],
      ["legal/se/en/generic-terms-isk"],
      ["legal/se/sv/account-terms"],
    ],
  },
  [AccountType.TJP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [],
  },
  [AccountType.VP_SWE]: {
    [LysaCountry.DENMARK]: [],
    [LysaCountry.FINLAND]: [],
    [LysaCountry.GERMANY]: [],
    [LysaCountry.SPAIN]: [],
    [LysaCountry.SWEDEN]: [
      accountAgreementDocuments[AccountType.VP_SWE][LysaCountry.SWEDEN],
      investmentGuidelinesDocuments[AccountType.VP_SWE][LysaCountry.SWEDEN],
      ["legal/se/sv/account-terms"],
    ],
  },
};

export const savingsAccountDocuments: Record<LysaCountry, string[][]> = {
  [LysaCountry.DENMARK]: [],
  [LysaCountry.FINLAND]: [],
  [LysaCountry.GERMANY]: [],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [
    ["legal/se/sv/avtal-och-allmänna-villkor-för-sparkonto"],
    ["legal/se/sv/viktigt-om-insättningsgaranti"],
    ["legal/se/sv/information-till-insättare"],
    ["legal/se/sv/information-till-insättare-borgo"],
    ["legal/se/sv/distansavtalsinformation-lysa-sparkonto"],
  ],
};

export const sharedAccountDocuments: Record<LysaCountry, string[][]> = {
  [LysaCountry.DENMARK]: [["legal/all/en/shared-account"]],
  [LysaCountry.FINLAND]: [["legal/all/en/shared-account"]],
  [LysaCountry.GERMANY]: [["legal/all/en/shared-account"]],
  [LysaCountry.SPAIN]: [],
  [LysaCountry.SWEDEN]: [["legal/se/sv/shared-account"]],
};

export function getAccountDocumentRoute(
  accountId: InvestmentAccountId,
  accountType: AccountType,
  country: LysaCountry
) {
  return getNavLink(
    generatePath(DOCUMENT_PAGE_URL, {
      group: accountId,
      document: accountAgreementDocuments[accountType][country][0],
    })
  );
}

export function getInvestmentGuidelinesLink(
  accountId: InvestmentAccountId,
  accountType: AccountType,
  country: LysaCountry
) {
  return getNavLink(
    generatePath(DOCUMENT_PAGE_URL, {
      group: accountId,
      document: investmentGuidelinesDocuments[accountType][country][0],
    })
  );
}

export function getAccountAgreementLink(
  accountId: InvestmentAccountId,
  accountType: AccountType,
  country: LysaCountry
) {
  return getNavLink(
    generatePath(DOCUMENT_PAGE_URL, {
      group: accountId,
      document: accountAgreementDocuments[accountType][country][0],
    })
  );
}
