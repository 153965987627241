import React, { useEffect, useRef } from "react";
import { cache, Spinner } from "@lysaab/ui-2";
import { dataLogout } from "../../data/dataLogout";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useHistory } from "react-router";
import { useLocalizationContext } from "../../context/LocalizationContext";
import { loginPages } from "../../components/router/loginPages";
import { Store, YEARLY_REVIEW_FORCE_CLOSE } from "../../Store";

export const LOGOUT_PAGE_URL = "/logout";

export function LogoutPage() {
  const history = useHistory();
  const loading = useRef(false);
  const localizationContext = useLocalizationContext();
  const country = localizationContext.state.country;

  useEffect(() => {
    if (loading.current) {
      return;
    }

    Store.setValue(YEARLY_REVIEW_FORCE_CLOSE, null);
    loading.current = true;
    dataLogout
      .logout()
      .catch(() => {})
      .finally(() => {
        cache.clear();
        window.location.replace(
          window.location.origin +
            getNavLink(country ? loginPages[country] : "/") +
            history.location.search
        );
      });
  }, [history.location.search, country]);
  return (
    <div>
      <Spinner />
    </div>
  );
}
