import {
  Card,
  encode,
  Button,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, {
  useEffect,
  VoidFunctionComponent,
  useState,
  useContext,
} from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserContext } from "../../../context/UserContext";
import {
  InvestmentAccountId,
  dataAccounts,
  InvestmentAccount,
  AccountType,
} from "../../../data/dataAccounts";
import { LegalEntityType } from "../../../data/dataLogin";
import { dataProfile } from "../../../data/dataProfile";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { END_INVESTMENT_URL } from "../../endInvestment/EndInvestment";
import { NOT_FOUND_URL } from "../../NotFoundPage";

import "./CloseAccountConfirmation.scss";

interface Props {
  next: () => void;
}

export const CloseAccountConfirmation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState<InvestmentAccount>();
  const [closeAccountError, setCloseAccountError] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [isLastVPAccount, setIsLastVPAccount] = useState(false);
  const userContext = useContext(UserContext);
  const loadPendingDeposits = usePendingDeposits();

  const accountId = useParams<{ accountId: InvestmentAccountId }>().accountId;

  useEffect(() => {
    if (typeof loadPendingDeposits === "undefined") {
      return;
    }
    Promise.all([dataAccounts.getAccounts(), loadPendingDeposits()])
      .then(([accounts, pendingDeposits]) => {
        const account = accounts.find(
          (account) => account.accountId === accountId
        );
        if (!account || userContext.state.readOnly) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        } else if (accounts.length === 1 || account.worth > 0) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        }
        if (
          pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          )
        ) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        }
        setAccount(account);
        setIsLoading(false);
        if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
          const allVPAccounts = accounts.filter(
            (account) => account.type === AccountType.VP_SWE
          );
          setIsLastVPAccount(
            allVPAccounts.length === 1 && account.type === AccountType.VP_SWE
          );
        }
      })
      .catch((error: ServerError<unknown>) => {
        setError(true);
        setIsLoading(false);
      });
  }, [
    accountId,
    history,
    loadPendingDeposits,
    userContext.state.legalEntityType,
    userContext.state.readOnly,
  ]);

  if (error) {
    return (
      <>
        <h1>
          <TranslatedText id="closeAccount.confirmation.header" />
        </h1>
        <Card className="close-account-confirmation-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.text" />
          </Snackbar>
        </Card>
        <Link
          className="lysa-ui-button button-primary block"
          to={getNavLink(END_INVESTMENT_URL)}
        >
          <TranslatedText id="endInvestment.closeAccount.story.confirm.back" />
        </Link>
      </>
    );
  }

  const closeAccount = (accountId: InvestmentAccountId) => {
    setIsLoading(true);
    dataProfile
      .closeInvestmentAccount(accountId)
      .then(() => next())
      .catch((error: ServerError<unknown>) => {
        setCloseAccountError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading || !account) return <Spinner />;

  const isCloseDisabledForAccount =
    userContext.state.legalEntityType === LegalEntityType.CORPORATION &&
    (isLastVPAccount || account.type === AccountType.DANICA_KF);

  return (
    <>
      <h1>
        <TranslatedText id="closeAccount.confirmation.header" />
      </h1>

      {isCloseDisabledForAccount ? (
        <Card className="close-account-confirmation">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.closeDisabled" />
          </Snackbar>
        </Card>
      ) : (
        <React.Fragment>
          <Card className="close-account-confirmation">
            {closeAccountError && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="endInvestment.closeAccount.error.text" />
              </Snackbar>
            )}
            <p>
              <TranslatedText
                id="closeAccount.confirmation.ingress"
                values={{
                  accountName: account.name,
                }}
              />
            </p>
            <p>
              <TranslatedText id="closeAccount.confirmation.account.download.textBefore" />
            </p>
            <div className="close-account-confirmation-rows">
              <div className="flex">
                <span>
                  <TranslatedText id="closeAccount.confirmation.download.accountPerformance" />
                </span>
                <Button
                  download
                  variant="secondary"
                  size="small"
                  inline
                  component="a"
                  href={
                    process.env.REACT_APP_API +
                    encode`/gdpr-export/performance/${account.accountId}`
                  }
                  label={
                    <TranslatedText id="closeAccount.confirmation.account.download" />
                  }
                />
              </div>
              <div className="flex">
                <span>
                  <TranslatedText id="closeAccount.confirmation.download.accountTransactions" />
                </span>
                <Button
                  download
                  variant="secondary"
                  size="small"
                  component="a"
                  inline
                  href={
                    process.env.REACT_APP_API +
                    encode`/gdpr-export/transactions/${account.accountId}`
                  }
                  label={
                    <TranslatedText id="closeAccount.confirmation.account.download.link" />
                  }
                />
              </div>
            </div>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="closeAccount.confirmation.account.download.textAfter" />
            </Snackbar>
          </Card>

          <Button
            onClick={() => {
              closeAccount(account.accountId);
            }}
            block
            label={<TranslatedText id="closeAccount.confirmation.button" />}
          />
        </React.Fragment>
      )}
    </>
  );
};
