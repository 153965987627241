import React, { useContext } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";
import { NeedEarlierProbability } from "../../../../../../data/dataInvestments";

const needEarlierAlternatives = [
  {
    text: "Mycket osannolikt",
    value: NeedEarlierProbability.VERY_UNLIKELY,
  },
  {
    text: "Osannolikt",
    value: NeedEarlierProbability.UNLIKELY,
  },
  {
    text: "Ganska osannolikt",
    value: NeedEarlierProbability.SOMEWHAT_UNLIKELY,
  },
  {
    text: "Ganska troligt",
    value: NeedEarlierProbability.SOMEWHAT_LIKELY,
  },
  {
    text: "Sannolikt",
    value: NeedEarlierProbability.LIKELY,
  },
  {
    text: "Mycket troligt",
    value: NeedEarlierProbability.VERY_LIKELY,
  },
];

export const Probability = () => {
  const kfAccountContext = useContext(KFAccountContext);
  const value = needEarlierAlternatives.find(
    (alt) => alt.value === kfAccountContext.state.needEarlier
  );

  return (
    <Card>
      <RadioGroup
        alternatives={needEarlierAlternatives}
        header="Hur sannolikt är det att du får behov av mer än hälften av pengarna tidigare än planerat?"
        onChange={(needEarlier) =>
          kfAccountContext.setState({ needEarlier: needEarlier.value })
        }
        value={value}
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
        data-test-id="horizon-probability"
      />
    </Card>
  );
};
