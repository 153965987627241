import React, { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { AccountLoading } from "../../../../components/klarna/accountLoading/AccountLoading";
import { TranslatedText } from "../../../../components/TranslatedText";
import { KlarnaAccount } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { KlarnaContext } from "../KlarnaContext";
import {
  BASE_ROUTES,
  fallbackLocationDescriptorFunction,
} from "../KlarnaStory";

interface Props {
  next: () => void;
  getErrorLoadingAccountsLocation?: fallbackLocationDescriptorFunction;
}

export function AccountLoadingWrapper({
  next,
  getErrorLoadingAccountsLocation,
}: Props) {
  const klarnaContext = useContext(KlarnaContext);
  const bank = klarnaContext.state.bank;
  const setState = klarnaContext.setState;

  const onComplete = useCallback(
    (accounts: KlarnaAccount[]) => {
      setState({ klarnaAccounts: accounts });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  if (!bank) {
    return <Redirect to={getNavLink(BASE_ROUTES.LYSA_ACCOUNT)} />;
  }
  return (
    <AccountLoading
      bank={bank}
      onComplete={onComplete}
      fallbackOptions={
        getErrorLoadingAccountsLocation && {
          locationDescriptor: getErrorLoadingAccountsLocation({
            accountId: klarnaContext.state.selectedLysaAccount?.accountId,
          }),
          buttonText: (
            <TranslatedText id="klarna.account-loading.error-button" />
          ),
        }
      }
    />
  );
}
