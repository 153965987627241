import {
  LysaFormRef,
  Form,
  Card,
  Button,
  MoneyInput,
  RequiredValidator,
  MinValidator,
  MaxValidator,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, { useEffect, useContext, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { StoryDisclaimer } from "../../../../components/storyDisclaimer/StoryDisclaimer";
import { TranslatedText } from "../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../components/withdrawalItem/WithdrawalItem";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../data/dataCustomerTracking";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../../hooks/useIsReadOnly";
import { useWithdrawalContext } from "../WithdrawalContext";
import { WITHDRAWALS_REQUEST_PAGE } from "../WithdrawalStory";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { WithdrawalHelp } from "../components/withdrawalHelp/WithdrawalHelp";
import { WithdrawalType } from "../amount/Amount";

interface Props {
  next: (withdrawalType: WithdrawalType) => void;
}

const messages = defineMessages({
  amountLabel: {
    id: "withdrawal.story.savingsAmount.label",
  },
  amountRequired: {
    id: "withdrawal.story.savingsAmount.required",
  },
  amountMin: {
    id: "withdrawal.story.savingsAmount.min",
  },
  amountMax: {
    id: "withdrawal.story.savingsAmount.max",
  },
});

export function SavingsAccountAmount({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const withdrawalContext = useWithdrawalContext();
  const [isError, setIsError] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    if (
      !withdrawalContext.state.selectedLysaSavingsAccount ||
      !withdrawalContext.state.selectedExternalAccount
    ) {
      history.push(getNavLink(WITHDRAWALS_REQUEST_PAGE));
    }
  }, [
    history,
    withdrawalContext.state.selectedExternalAccount,
    withdrawalContext.state.selectedLysaSavingsAccount,
  ]);

  if (
    typeof withdrawalContext.state.selectedLysaSavingsAccount === "undefined" ||
    typeof withdrawalContext.state.selectedExternalAccount === "undefined"
  ) {
    return null;
  }

  const selectedLysaSavingsAccount =
    withdrawalContext.state.selectedLysaSavingsAccount;
  const selectedExternalAccount =
    withdrawalContext.state.selectedExternalAccount;

  return (
    <div>
      <h2>
        <TranslatedText id="withdrawal.story.savingsAmount.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !selectedLysaSavingsAccount ||
            !selectedExternalAccount ||
            isReadOnly
          ) {
            return;
          }
          dataWithdrawals
            .postSavingsAccountWithdrawal(
              selectedLysaSavingsAccount.accountId,
              Number(withdrawalContext.state.amount),
              selectedExternalAccount
            )
            .then(() => {
              EventTracker.track({
                event: TrackerEvent.WITHDRAWAL,
                message: `${
                  selectedLysaSavingsAccount.name
                } - ${intl.formatNumber(
                  Number(withdrawalContext.state.amount),
                  {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }
                )}`,
              });
              dataCustomerTrackingService.postEvent({
                eventName: "clickedConfirmWithdrawal",
                domain: FeatureDomain.TRANSFERS,
                subDomain: SubDomain.WITHDRAWAL,
                payload: {
                  amount: withdrawalContext.state.amount
                    ? withdrawalContext.state.amount.toString()
                    : "",
                  is_drain: "False",
                },
              });
              next(WithdrawalType.AMOUNT);
            })
            .catch(() => setIsError(true));
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="withdrawal.story.savingsAmount.error" />
            </Snackbar>
          )}
          <MoneyInput
            decimalScale={2}
            currency={localizationContext.state.currency}
            value={withdrawalContext.state.amount?.toString()}
            onChange={(value) => withdrawalContext.setState({ amount: value })}
            label={intl.formatMessage(messages.amountLabel)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                0.01,
                intl.formatMessage(messages.amountMin, { amount: 0.01 })
              ),
              new MaxValidator(
                selectedLysaSavingsAccount.totalBalance,
                intl.formatMessage(messages.amountMax, {
                  amount: intl.formatNumber(
                    selectedLysaSavingsAccount.totalBalance,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                    }
                  ),
                })
              ),
            ]}
          />
        </Card>
        <WithdrawalItem
          moneyOnAccount={selectedLysaSavingsAccount.totalBalance}
          name={selectedLysaSavingsAccount.name}
          externalBank={selectedExternalAccount.bank}
          externalBankAccount={selectedExternalAccount.externalBankAccount}
          withdrawalAmount={withdrawalContext.state.amount}
        />
        <Button
          type="submit"
          block
          label={<TranslatedText id="withdrawal.story.savingsAmount.button" />}
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <StoryDisclaimer>
        <TranslatedText id="withdrawal.story.savingsAmount.disclaimer" />
      </StoryDisclaimer>
    </div>
  );
}
