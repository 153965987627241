import { API, BankidResponse, encode, Alternative } from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";
import { OrderRef } from "./dataBankid";

export interface SaveTransferRequest {
  accountId: InvestmentAccountId;
  institute: string;
  accountNumber: string;
  fullTransfer: boolean;
  amount?: number;
  closeAccount?: boolean;
  bankOffice?: string;
}

export interface SavedTransfer {
  transferId: number;
  accountId: InvestmentAccountId;
  institute: string;
  accountNumber: string;
  amount?: number;
  fullTransfer: boolean;
  created: string;
  signed: string;
  sent?: string;
  completed?: string;
}

export interface SaveTransferResponse {
  transferId: number;
}

export interface BankidInitResponse extends BankidResponse {
  orderRef?: OrderRef;
  autoStartToken?: string;
}

export const dataIsk = {
  saveTransfer: (request: SaveTransferRequest) => {
    return API.post<SaveTransferResponse>("/isk/transfer", request);
  },
  startSigning: (transferId: number) => {
    return API.post<BankidInitResponse>("/bankid/isk-transfer", { transferId });
  },
  pollSigning: (orderRef: OrderRef, transferId: number) => {
    return API.get<BankidResponse>(
      encode`/bankid/isk-transfer/${orderRef}?transferId=${transferId}`,
      true
    );
  },
  getInstitutions: () => {
    return API.get<Alternative<string>[]>(`/isk/transfer/institutes`);
  },
  getTransferHistory: () => {
    return API.get<SavedTransfer[]>(`/isk/transfer`);
  },
};
