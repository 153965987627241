import React, { VoidFunctionComponent } from "react";
import { Alternative, Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { SavingsHorizonLength } from "../../../data/dataInvestments";
import { useIsPerson } from "../../../hooks/useIsPerson";

const messages = defineMessages({
  header: {
    id: "horizon.length.header",
  },
  headerCorp: {
    id: "horizon.corp.length.header",
  },
  required: {
    id: "horizon.length.required",
  },
});

interface MessageWithId {
  id: string;
}

export const savingsHorizonLengthMessages = defineMessages<
  SavingsHorizonLength,
  MessageWithId,
  Record<SavingsHorizonLength, MessageWithId>
>({
  [SavingsHorizonLength.VERY_LONG]: {
    id: "horizon.length.alt.verylong",
  },
  [SavingsHorizonLength.LONG]: {
    id: "horizon.length.alt.long",
  },
  [SavingsHorizonLength.KINDA_LONG]: {
    id: "horizon.length.alt.kindalong",
  },
  [SavingsHorizonLength.MIDDLE]: {
    id: "horizon.length.alt.middle",
  },
  [SavingsHorizonLength.SHORT]: {
    id: "horizon.length.alt.short",
  },
});

interface Props {
  savingsHorizonLength?:
    | SavingsHorizonLength
    | Alternative<SavingsHorizonLength>;
  setSavingsHorizonLength: (
    horizonLength: Alternative<SavingsHorizonLength>
  ) => void;
}

export const Length: VoidFunctionComponent<Props> = ({
  savingsHorizonLength,
  setSavingsHorizonLength,
}) => {
  const intl = useIntl();
  const isPerson = useIsPerson();

  const alternatives = Object.values(SavingsHorizonLength).map((key) => ({
    text: intl.formatMessage(savingsHorizonLengthMessages[key]),
    value: key,
  }));

  const value =
    typeof savingsHorizonLength === "string"
      ? {
          text: intl.formatMessage(
            savingsHorizonLengthMessages[savingsHorizonLength]
          ),
          value: savingsHorizonLength,
        }
      : savingsHorizonLength;

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={
          isPerson
            ? intl.formatMessage(messages.header)
            : intl.formatMessage(messages.headerCorp)
        }
        onChange={setSavingsHorizonLength}
        value={value}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
        data-test-id="horizon-length"
      />
    </Card>
  );
};
