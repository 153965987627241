import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import { Page, PageHeader } from "../Page";
import { NOT_FOUND_URL } from "../NotFoundPage";
import { getNavLink } from "../../hooks/useCountryUrls";
import {
  CompoundAccount,
  InvestmentAccountId,
  isSavingsAccount,
} from "../../data/dataAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { dataAccounts } from "../../data/dataAccounts";
import { GraphCard } from "../overview/GraphCard";
import { Allocation } from "./Allocation";
import { Transactions } from "./Transactions";
import { AccountName } from "../../components/accountName/AccountName";
import { Monthly } from "./Monthly";
import { Fees } from "./fees/Fees";
import { Positions } from "./positions/Positions";
import { Docs } from "./Docs";
import { ChangeName } from "./ChangeName";
import { AccountActions } from "./AccountActions";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { MONTHLY_OVERVIEW_PAGE } from "../deposits/monthly/overview/MonthlyOverview";
import { CloseAccount } from "./CloseAccount";
import "./AccountPage.scss";
import { ShareAccountCard } from "./ShareAccountCard";
import { SHARE_ACCOUNT_URL } from "../shareAccountStory/ShareAccountStory";
import { FormattedMessage } from "react-intl";

export const ACCOUNT_PAGE_URL = "/account/:accountId";

export function AccountPage() {
  const match = useRouteMatch<{
    country: string;
    accountId: InvestmentAccountId;
  }>({
    path: "/:country/account/:accountId",
  });
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [accounts, setAccounts] = useState<CompoundAccount[]>([]);
  const [numberOfInvestmentAccounts, setNumberOfInvestmentAccounts] =
    useState<number>(1);

  const load = useCallback(() => {
    dataAccounts
      .getAllAccounts()
      .then((data) => {
        setNumberOfInvestmentAccounts(data.investmentAccounts.length);
        setAccounts([...data.investmentAccounts, ...data.savingsAccounts]);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(load, 500);
  }, [load]);

  const onUpdate = useCallback(() => {
    dataAccounts.clearGetAccounts();
    load();
  }, [load]);

  useEffect(load, [load]);

  const accountId = match?.params.accountId;
  let account: CompoundAccount | undefined;
  if (accounts.length > 0) {
    account = accounts.find((item) => item.accountId === accountId);
    if (!account) {
      return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
    }
  }
  if (account && isSavingsAccount(account)) {
    return <Redirect to={getNavLink(NOT_FOUND_URL)} />;
  }

  return (
    <Page className="account-page">
      <Retry retry={retry} status={status}>
        <PageHeader>
          <h1>
            {account ? (
              <AccountName
                account={account}
                showAccountType={true}
                showAmount={false}
              />
            ) : null}
          </h1>
        </PageHeader>
        <GraphCard accounts={accounts} account={account} />
      </Retry>
      <AccountActions account={account} accounts={accounts} />
      <div className="account-page-grid">
        <Allocation account={account} />
        <Transactions account={account} accounts={accounts} />
        <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
          <Monthly account={account} />
        </RouteAwareToggle>
        <Positions account={account} accounts={accounts} />
        <Fees account={account} accounts={accounts} />
        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
          <section>
            <h2>
              <FormattedMessage id="accountPage.sharedAccount.header" />
            </h2>
            <ShareAccountCard account={account} />
          </section>
        </RouteAwareToggle>
        <Docs account={account} />
        <ChangeName account={account} onUpdate={onUpdate} />
        {accounts.length > 1 && numberOfInvestmentAccounts > 1 && (
          <CloseAccount account={account} />
        )}
      </div>
    </Page>
  );
}
