import { DateTime } from "luxon";
import React, { useContext } from "react";
import { RouteAwareToggle } from "../../../../components/route/RouteAwareToggle";
import { UserContext, UserState } from "../../../../context/UserContext";
import { useUserStorage } from "../../../../context/UserStorageContext";
import { CREATE_SAVINGS_ACCOUNT_URL } from "../../../../countries/sweden/pages/createAccount/savingsAccount/CreateSavingsAccountStory";
import { StateName } from "../../../../data/dataUserState";
import { useAccounts } from "../../../../hooks/useAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { SavingsAccountAdjustment } from "./banners/SavingsAccountAdjustment";

import "./NewsBanner.scss";

export const IS_NEWSBANNER_CLOSED_SESSION_KEY = "IS_NEWSBANNER_CLOSED";

const dateOfPublishing: Record<StateName, DateTime> = {
  SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V5: DateTime.utc(2023, 6, 22),
};

export const NewsBanner = () => {
  const { state: customer } = useContext(UserContext);
  const { userStorage } = useUserStorage();
  const { accounts } = useAccounts();

  if (window.sessionStorage.getItem(IS_NEWSBANNER_CLOSED_SESSION_KEY)) {
    return null;
  }
  if (typeof accounts === "undefined" || isCustomerCreatedToday(customer)) {
    return null;
  }

  let newsBanner;
  if (
    userStorage[StateName.SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V5] &&
    isCustomerCreatedBeforeDate(
      customer,
      dateOfPublishing.SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V5
    )
  ) {
    newsBanner = (
      <RouteAwareToggle path={getNavLink(CREATE_SAVINGS_ACCOUNT_URL)}>
        <SavingsAccountAdjustment />
      </RouteAwareToggle>
    );
  }
  return <div className="news-banner">{newsBanner}</div>;
};

const isCustomerCreatedBeforeDate = (
  customer: UserState,
  publishDate: DateTime
) => {
  if (typeof customer.created === "undefined") {
    return false;
  }
  return DateTime.fromISO(customer.created) < publishDate;
};

const isCustomerCreatedToday = (customer: UserState) => {
  if (typeof customer.created === "undefined") {
    return false;
  }
  const customerCreated = DateTime.fromISO(customer.created);
  const today = DateTime.now();
  return (
    customerCreated.hasSame(today, "day") &&
    customerCreated.hasSame(today, "month") &&
    customerCreated.hasSame(today, "year")
  );
};
