import { Page, PageHeader } from "./Page";
import { defineMessages, FormattedMessage } from "react-intl";
import { Spinner } from "@lysaab/ui-2";
import { useFeatureContext } from "../context/FeatureContext";

export const NOT_FOUND_URL = "/404";

const messages = defineMessages({
  header: {
    id: "not-found.header",
    description: "header for missing page",
    defaultMessage: "Ooh, the irony, a missing header for a missing page.",
  },
  body: {
    id: "not-found.body",
    description: "body for missing page",
    defaultMessage:
      "A missing page. Maybe this page has not yes been implemented, sorry. Or, maybe we've a broken link.",
  },
});

export function NotFoundPage() {
  const [features] = useFeatureContext();

  if (
    Object.values(features).some((feature) => {
      return typeof feature === "undefined";
    })
  ) {
    return <Spinner />;
  }
  return (
    <Page className="not-found-page">
      <PageHeader>
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
      </PageHeader>
      <p>
        <FormattedMessage {...messages.body} />
      </p>
    </Page>
  );
}
