import React, { useContext, useState, useEffect } from "react";
import { PerformanceContext } from "../../context/PerformanceContext";
import { dataPerformance, IndexId, Markets } from "../../data/dataPerformance";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import "./GraphMenu.scss";
import { Icon, CircleButton, Spinner } from "@lysaab/ui-2";
import { InvestmentAccount } from "../../data/dataAccounts";

interface Props {
  setShowMenu: (showMenu: boolean) => void;
  account?: InvestmentAccount;
}

interface Id {
  id: string;
}

const comparisonMarkets = defineMessages<IndexId, Id>({
  [IndexId.F00000VPET]: {
    id: "performance.graph.index.F00000VPET",
  },
  [IndexId.SSEACT]: { id: "performance.graph.index.SSEACT" },
  [IndexId.F000011PMT]: { id: "performance.graph.index.F000011PMT" },
  [IndexId.F00000VPF2]: { id: "performance.graph.index.F00000VPF2" },
  [IndexId.F00000T62Q]: { id: "performance.graph.index.F00000T62Q" },
  [IndexId.F00000VPER]: { id: "performance.graph.index.F00000VPER" },
  [IndexId.F000011P2R]: { id: "performance.graph.index.F000011P2R" },
  [IndexId.F000011C20]: { id: "performance.graph.index.F000011C20" },
  [IndexId.F00000VN9H]: { id: "performance.graph.index.F00000VN9H" },
  [IndexId.F000011P2V]: { id: "performance.graph.index.F000011P2V" },
  [IndexId.F000011C24]: { id: "performance.graph.index.F000011C24" },
});

export const GraphMenu: React.FC<Props> = ({ setShowMenu, account }) => {
  const { setState, indexes } = useContext(PerformanceContext);
  const [markets, setMarkets] = useState<Markets>();
  const intl = useIntl();

  useEffect(() => {
    if (account) {
      dataPerformance
        .getComparisonMarketsForAccount(account.accountId)
        .then(setMarkets)
        .catch(() => {});
      return;
    }
    dataPerformance
      .getComparisonMarkets()
      .then(setMarkets)
      .catch(() => {});
  }, [account]);

  return (
    <>
      <div className="graph-menu">
        <div className="flex">
          <b>
            <FormattedMessage
              id="overview.graphMenu.chooseIndex"
              description="Graph menu, choose compare indexes"
              defaultMessage="Choose compare indexes"
            />
          </b>
          <div className="graph-menu-actions">
            <CircleButton
              onClick={() => {
                if (window._elev) {
                  window._elev.openArticle("124");
                }
              }}
              aria-label="Index comparison help"
              icon="Help"
            />
            <CircleButton
              onClick={() => setShowMenu(false)}
              aria-label="Close selectable index graph menu"
              icon="CancelAlt"
            />
          </div>
        </div>

        <div>
          <button
            onClick={() => {
              setState({
                indexes: [],
              });
            }}
            className={
              indexes.length === 0 ? "active market-item" : "market-item"
            }
          >
            <span style={{ backgroundColor: "transparent" }} />
            <FormattedMessage
              id="overview.graphMenu.noIndex"
              description="Graph menu, no selected index"
              defaultMessage="No index chosen"
            />
            <Icon.Checkmark />
          </button>

          {markets ? (
            Object.values(markets).map((item, idx) => {
              const id = item.id as IndexId;

              return (
                <button
                  key={item.id}
                  className={
                    indexes.indexOf(id) > -1
                      ? "active market-item"
                      : "market-item"
                  }
                  onClick={() => {
                    const clone = [...indexes];
                    const idx = indexes.indexOf(id);
                    if (idx > -1) {
                      clone.splice(idx, 1);
                    } else {
                      clone.unshift(id);
                    }
                    setState({
                      indexes: clone.slice(0, 3),
                    });
                  }}
                >
                  <div>
                    <span style={{ backgroundColor: item.color }} />
                    {intl.formatMessage(comparisonMarkets[item.id])}
                  </div>
                  <Icon.Checkmark />
                </button>
              );
            })
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};
