import React, {
  useRef,
  useState,
  useEffect,
  MutableRefObject,
  useCallback,
} from "react";
import * as d3 from "d3";
import cx from "classnames";
import { DateTime } from "luxon";
import { GraphDataPoint, GraphDataIndex } from "../../data/dataPerformance";
import { useWidth } from "../../hooks/useWidth";
import { interpolatePath } from "d3-interpolate-path";
import { PerformanceState } from "../../context/PerformanceContext";
import {
  GraphTooltip,
  IndexTooltip,
  Events,
  EventsTooltip,
} from "./PerformanceGraphTypes";
import { OverlayLegend } from "./OverlayLegend";
import { InvestmentAccount } from "../../data/dataAccounts";
import "./Graph.scss";
import { TransactionType } from "../../data/dataTransactions";

interface Props {
  points: GraphDataPoint[];
  indexes: GraphDataIndex[];
  yMin: number;
  yMax: number;
  xMin: Date;
  xMax: Date;
  showOverlay: boolean;
  showEvents: boolean;
  setPerformanceState: (state: Partial<PerformanceState>) => void;
  graphTooltip: GraphTooltip;
  indexTooltip: IndexTooltip;
  account?: InvestmentAccount;
  events: Events;
  eventsTooltip: EventsTooltip;
}

interface Point {
  date: Date;
  change: number;
}

interface Scale {
  x: d3.ScaleTime<number, number>;
  y: d3.ScaleLinear<number, number>;
}

interface Area {
  w: number;
  h: number;
}

interface Tick {
  value: number | string;
  offset: string;
  labelOffset?: string;
}

interface EventElem {
  id?: string;
  radius: number;
  className: string;
  cx: number;
  cy: number;
}

const PADDING = 5;
const PADDING_LIMIT = 15;

function padDomain(domain: number[]) {
  if (
    typeof domain[1] === "undefined" ||
    Math.abs(domain[1]) + Math.abs(domain[1]) > PADDING_LIMIT
  ) {
    return domain;
  }
  const newDomain = [...domain];
  newDomain[0] -= PADDING;
  newDomain[1] += PADDING;
  return newDomain;
}

function interpolate(startValue: number, endValue: number, rest: number) {
  const interpolator = d3.interpolateNumber(startValue, endValue);
  return interpolator(rest);
}

const EventElement: React.FC<EventElem> = ({ radius, className, cx, cy }) => (
  <circle className={`event-element ${className}`} r={radius} cx={cx} cy={cy} />
);

const INTERPOLATE_BELOW_POINTS = 200;
const APPROXIMATE_YEAR = 365 * 24 * 60 * 60 * 1000;
const APPROXIMATE_HALF_LABEL_WIDTH = 26;
const HALF_OVERLAY = 225;
const RIGHT_TICKS_PADDING = 20;
const EVENTS_VERTICAL_OFFSET = 28;
const EVENTS_VERTICAL_OFFSET_TABLET = 36;
const bisectDate = d3.bisector<Point, any>((d) => d.date).right;

function getDateTicks(scale: MutableRefObject<Scale>, points: Point[]): Date[] {
  const startDate = points[0].date;
  const endDate = points[points.length - 1].date;

  // if points span 1,5 years
  if (endDate.getTime() - startDate.getTime() > 1.5 * APPROXIMATE_YEAR) {
    return d3.scaleTime().domain(scale.current.x.domain()).ticks(d3.timeYear);
  }

  return d3.scaleTime().domain(scale.current.x.domain()).ticks(2);
}
export const Graph: React.FC<Props> = ({
  points,
  indexes,
  yMin,
  yMax,
  xMin,
  xMax,
  setPerformanceState,
  showOverlay,
  showEvents,
  graphTooltip,
  indexTooltip,
  account,
  events,
  eventsTooltip,
}) => {
  const plot = useRef<HTMLDivElement>(null);
  const width = useWidth();
  const [indexPaths, setIndexPaths] = useState<string[]>([]);
  const [lysaPath, setLysaPath] = useState<string | undefined>();
  const [lysaShadow, setLysaShadow] = useState<string | undefined>();
  const [animating, setAnimating] = useState<boolean>(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const xAxisTicks = useRef<Tick[]>([]);
  const yAxisTicks = useRef<Tick[]>([]);
  const eventsVerticalOffset = useRef(EVENTS_VERTICAL_OFFSET);
  const scale = useRef<Scale>({
    x: d3.scaleTime(),
    y: d3.scaleLinear(),
  });
  const [area, setArea] = useState<Area>({
    w: 0,
    h: 0,
  });
  const eventsScale = useRef<d3.ScaleLinear<number, number>>(d3.scaleLinear());
  const eventElems = useRef<EventElem[]>([]);

  useEffect(() => {
    if (!plot.current) {
      return;
    }
    const box = plot.current.getBoundingClientRect();
    const w = box.width;
    const h = box.height;
    scale.current.x.range([0, w]);
    scale.current.y.range([h, 0]);
    if (width >= 768) {
      eventsVerticalOffset.current = EVENTS_VERTICAL_OFFSET_TABLET;
    } else {
      eventsVerticalOffset.current = EVENTS_VERTICAL_OFFSET;
    }
    setArea({ w, h });
  }, [width]);

  scale.current.y.domain(padDomain([yMin, yMax]));
  scale.current.x.domain([xMin, xMax]);

  useEffect(() => {
    if (area.w === 0) {
      return;
    }

    const line = d3
      .line<Point>()
      .x((d: Point) => scale.current.x(d.date) || 0)
      .y((d: Point) => scale.current.y(d.change) || 0);

    const shadowLine = d3
      .line<Point>()
      .x((d: Point) => scale.current.x(d.date) || 0)
      .y((d: Point) => 10 + (scale.current.y(d.change) || 0))
      .curve(d3.curveBasisOpen);

    const defaultPath = `M0 ${area.h} L${area.w} ${area.h}`;

    const interpolatedIndexes = indexes.map((indexGraph, idx) => {
      return interpolatePath(
        indexPaths[idx] || defaultPath,
        line(indexes[idx].points) || defaultPath
      );
    });

    const interpolateLysaPath = interpolatePath(
      lysaPath || defaultPath,
      line(points) || defaultPath
    );

    const interpolateLysaShadow = interpolatePath(
      lysaShadow || defaultPath,
      shadowLine(points) || defaultPath
    );

    d3.selection()
      .transition()
      .duration(500)
      .tween("animate-path", () => {
        return (t: number) => {
          setIndexPaths(interpolatedIndexes.map((func) => func(t)));
          setLysaPath(interpolateLysaPath(t));
          setLysaShadow(interpolateLysaShadow(t));
        };
      })
      .on("end", () => {
        setAnimating(true);
      });
  }, [points, indexes, area, events]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!plot.current) {
      return;
    }
    const elem = plot.current;

    const onTransitionEnd = (ev: TransitionEvent) => {
      if (ev.propertyName !== "opacity") {
        return;
      }
      if (!plot.current?.classList.contains("is-animating")) {
        return;
      }

      const yTicks = d3.scaleLinear().domain(scale.current.y.domain()).ticks(5);

      const eventOffset = area.h + eventsVerticalOffset.current;
      const range = [3, 10];

      eventsScale.current.domain(events.yDomain).range(range);

      const result: EventElem[] = [];
      events.events.forEach((item, idx) => {
        /* Turns Tue Jan 04 2022 00:30:20 GMT+0100 ("2022-01-03T23:30:20.810Z")
           into Mon Jan 03 2022 23:30:20 GMT+0100 ("2022-01-03T22:30:20.000Z") 
           to show events in "UTC" */
        const date =
          item.date.getUTCDate() === item.date.getDate()
            ? item.date
            : new Date(
                item.date.getUTCFullYear(),
                item.date.getUTCMonth(),
                item.date.getUTCDate(),
                item.date.getUTCHours()
              );

        result.push({
          id: item.date.toString() + item.type + idx,
          radius: eventsScale.current(item.amount) || 0,
          className: item.type,
          cx: scale.current.x(date) || 0,
          cy: eventOffset,
        });
      });

      eventElems.current = result;
      yAxisTicks.current = yTicks.map<Tick>((tick) => ({
        value: tick,
        offset: `translateY(${scale.current.y(tick)}px)`,
      }));

      const xTicks = getDateTicks(scale, points);

      xAxisTicks.current = xTicks.map<Tick>((tick) => {
        const offset = scale.current.x(tick) || 0;
        let labelOffset = offset;
        if (offset < APPROXIMATE_HALF_LABEL_WIDTH) {
          labelOffset = APPROXIMATE_HALF_LABEL_WIDTH;
        } else if (area.w - offset < APPROXIMATE_HALF_LABEL_WIDTH) {
          labelOffset = area.w - APPROXIMATE_HALF_LABEL_WIDTH;
        }

        return {
          value: DateTime.fromJSDate(tick).toISODate() || "",
          offset: `translateX(${offset}px)`,
          labelOffset: `translate(${labelOffset}px, 2px)`,
        };
      });

      setAnimating(false);
    };

    elem.addEventListener("transitionend", onTransitionEnd);

    return () => {
      elem.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [scale, area, points, events]);

  const eventShowOverlay = useCallback(
    () => setPerformanceState({ showOverlay: true }),
    [setPerformanceState]
  );
  const eventHideOverlay = useCallback(
    () => setPerformanceState({ showOverlay: false }),
    [setPerformanceState]
  );
  const onMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const boundingBox = event.currentTarget.getBoundingClientRect();
    const cursorPosition = {
      x: event.clientX - boundingBox.left,
      y: event.clientY - boundingBox.top,
    };
    setMousePosition(cursorPosition);
  };
  const onTouchMove = useCallback((event: React.TouchEvent<SVGRectElement>) => {
    const boundingBox = event.currentTarget.getBoundingClientRect();
    const cursorPosition = {
      x: event.touches[0].clientX - boundingBox.left,
      y: event.touches[0].clientY - boundingBox.top,
    };
    setMousePosition(cursorPosition);
  }, []);

  let indicatorOffsetTop;
  let hoveredDate = scale.current.x.invert(mousePosition.x);

  if (hoveredDate < points[0].date) {
    hoveredDate = points[0].date;
  } else if (hoveredDate > points[points.length - 1].date) {
    hoveredDate = points[points.length - 1].date;
  }

  const hoverIdx = Math.min(bisectDate(points, hoveredDate), points.length - 1);
  const indicatorOffsetLeft = Math.min(Math.max(mousePosition.x, 0), area.w);
  const indexOpacity = showOverlay ? 0.4 : 0.7;

  // So, if we're dealing with a short array of points we
  // need to interpolate on our own in order to avoid the
  // marker to jump in a discrete manner
  if (
    points.length < INTERPOLATE_BELOW_POINTS &&
    hoverIdx > 0 &&
    hoverIdx < points.length - 1
  ) {
    const endPoint = points[hoverIdx];
    const startPoint = points[hoverIdx - 1];
    const diff = endPoint.date.getTime() - startPoint.date.getTime();
    const rest = (hoveredDate.getTime() - startPoint.date.getTime()) / diff;
    indicatorOffsetTop = scale.current.y(
      interpolate(startPoint.change, endPoint.change, rest)
    );
  } else {
    indicatorOffsetTop = scale.current.y(points[hoverIdx].change);
  }

  let tooltipOffset = "0px";
  if (width >= 768) {
    if (plot.current) {
      const box = plot.current.getBoundingClientRect();
      tooltipOffset = `translate(${Math.min(
        Math.max(HALF_OVERLAY, indicatorOffsetLeft),
        box.width - HALF_OVERLAY + RIGHT_TICKS_PADDING
      )}px)`;
    }
  }

  const shortDate = hoveredDate.toLocaleDateString();
  const showEventIndicator =
    eventsTooltip[TransactionType.WITHDRAWAL][shortDate] ||
    eventsTooltip[TransactionType.DEPOSIT][shortDate] ||
    eventsTooltip["INTERNAL"][shortDate];

  return (
    <div
      className={cx("performance-graph", {
        "is-animating": animating,
      })}
      ref={plot}
    >
      <div className="y-axis-lines">
        {yAxisTicks.current.map((item, idx) => (
          <div
            key={`${item.value}-${idx}`}
            className="y-axis-line"
            style={{ transform: item.offset }}
          />
        ))}
      </div>

      <div className="x-axis-lines">
        {xAxisTicks.current.map((item, idx) => (
          <div
            key={`${item.value}-${idx}`}
            className="x-axis-line"
            style={{ transform: item.offset }}
          />
        ))}
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width={area.w} height={area.h}>
        <filter id="line-shadow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
        </filter>

        <g className="graph-events">
          <rect
            width="20"
            height="20"
            x={-10}
            y={-10}
            className={cx("event-indicator", {
              active: showEventIndicator,
            })}
            transform={`translate(${indicatorOffsetLeft},${
              eventElems.current[0]?.cy ?? 0
            }
              )rotate(45)`}
          />

          {eventElems.current.map((item) => (
            <EventElement
              cx={item.cx}
              cy={item.cy}
              radius={item.radius}
              className={item.className}
              key={item.id}
            />
          ))}
        </g>

        <path
          className="index-performance-line-0"
          d={indexPaths[0]}
          stroke={indexes[0] ? indexes[0].color : "transparent"}
          strokeOpacity={indexOpacity}
        />
        <path
          className="index-performance-line-1"
          d={indexPaths[1]}
          stroke={indexes[1] ? indexes[1].color : "transparent"}
          strokeOpacity={indexOpacity}
        />
        <path
          className="index-performance-line-2"
          d={indexPaths[2]}
          stroke={indexes[2] ? indexes[2].color : "transparent"}
          strokeOpacity={indexOpacity}
        />

        <path d={lysaPath} className="lysa-performance-line" />
        <path
          d={lysaShadow}
          className="lysa-performance-shadow"
          filter="url(#line-shadow)"
        />
        <rect
          width={area.w}
          height={area.h}
          onMouseOver={eventShowOverlay}
          onMouseOut={eventHideOverlay}
          onMouseMove={onMouseMove}
          onTouchStart={eventShowOverlay}
          onTouchEnd={eventHideOverlay}
          onTouchMove={onTouchMove}
        />
      </svg>

      <div className="performance-graph-overlay">
        <div style={{ transform: tooltipOffset }}>
          <OverlayLegend
            showEvents={showEvents}
            account={account}
            date={hoveredDate}
            graphTooltip={graphTooltip}
            indexTooltip={indexTooltip}
            eventsTooltip={eventsTooltip}
          />
        </div>

        <div
          className="performance-line-indicator"
          style={{ transform: `translate(${indicatorOffsetLeft}px)` }}
        />

        <div
          className="performance-marker-indicator"
          style={{
            transform: `translate(${indicatorOffsetLeft}px, ${indicatorOffsetTop}px)`,
          }}
        >
          <div />
        </div>
      </div>

      <div className="y-axis-ticks">
        {yAxisTicks.current.map((item, idx) => (
          <div key={`${item.value}-${idx}`} style={{ transform: item.offset }}>
            {item.value}%
          </div>
        ))}
      </div>

      <div className="x-axis-ticks">
        {xAxisTicks.current.map((item, idx) => (
          <div
            key={`${item.value}-${idx}`}
            style={{ transform: item.labelOffset }}
          >
            {item.value}
          </div>
        ))}
      </div>
    </div>
  );
};
