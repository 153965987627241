import React, { useState } from "react";
import {
  Button,
  RadioGroupCard,
  RadioGroupCardItem,
  Story,
} from "@lysaab/ui-2";
import "./Recommendation.scss";
import { TranslatedText } from "../../../components/TranslatedText";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { VoidFunctionComponent } from "react";
import {
  CompoundAccountId,
  InvestmentAccountId,
} from "../../../data/dataAccounts";
import { PendingDepositCard } from "../../../components/deposits/pendingDeposits/PendingDepositCard";
import { defineMessages, useIntl } from "react-intl";
import { PageStripped } from "../../PageStripped";
import { useHistory, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../data/dataCustomerTracking";
import { useAccountFilterParams } from "../../../hooks/useAccountFilterParams";

export const DEPOSITS_OVERVIEW_URL = "/deposits";

export interface WithAccountId {
  accountId?: InvestmentAccountId;
  returnUrl?: string;
}

export enum RecommendableChannel {
  SWISH = "SWISH",
  AUTOGIRO = "AUTOGIRO",
  BANKGIRO = "BANKGIRO",
  KLARNA = "DIRECT_DEPOSIT",
  VIRTUAL_ACCOUNT = "VIRTUAL_ACCOUNT",
  ISK_TRANSFER = "ISK_TRANSFER",
  MONTHLY_DEPOSIT = "MONTHLY_DEPOSIT",
}

export interface DepositMethod {
  alternative: RadioGroupCardItem<RecommendableChannel>;
  link: string;
}

interface Props {
  alternatives: DepositMethod[];
}

export interface FallbackTransferState {
  accountId?: CompoundAccountId;
  amount?: number;
  returnUrl?: string;
}

const messages = defineMessages({
  ariaProgressLabel: {
    id: "deposits.guided.story.ariaProgressLabel",
  },
});

export const Recommendation: VoidFunctionComponent<Props> = ({
  alternatives,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [selectedAlternative, setSelectedAlternative] = useState(
    alternatives[0].alternative.value
  );
  const location = useLocation<FallbackTransferState | undefined>();
  const safeNavigation = useSafeNavigation();
  const { hasAccountFilter, accountFilterParams } = useAccountFilterParams();

  const exit = () => {
    if (location.state && location.state?.returnUrl) {
      safeNavigation(location.state.returnUrl);
    } else {
      safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
    }
  };

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: 1,
            total: 1,
          })
        }
        header={intl.formatMessage({ id: "deposits.overview.header" })}
        onExit={() => exit()}
        showBack={false}
        showClose={true}
        transitionKey={"random"}
        progress={10}
      >
        <div className="deposit-recommendation-content">
          <GridRow>
            <GridCol xsmall={12}>
              <PendingDepositCard />
            </GridCol>
          </GridRow>
          <RadioGroupCard
            legend={
              <h2>
                <TranslatedText id="deposits.guided.story.recommendation.recommended.header" />
              </h2>
            }
            alternatives={alternatives.map((alt) => alt.alternative)}
            onChange={setSelectedAlternative}
            value={selectedAlternative}
          />
          <Button
            block
            variant="primary"
            onClick={() => {
              const link = alternatives.find(
                (alternative) =>
                  alternative.alternative.value === selectedAlternative
              )?.link;
              if (link) {
                window.plausible("DEPOSIT_SELECTED_ALTERNATIVE", {
                  props: {
                    alternative: selectedAlternative,
                  },
                });
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.TRANSFERS,
                  subDomain: SubDomain.DEPOSIT,
                  eventName: selectedAlternative,
                });
                history.push({
                  pathname: link,
                  search:
                    hasAccountFilter &&
                    selectedAlternative !== RecommendableChannel.ISK_TRANSFER
                      ? accountFilterParams
                      : undefined,
                  state: location.state,
                });
              }
            }}
            label={
              <TranslatedText id="deposits.guided.story.recommendation.recommended.button" />
            }
          />
        </div>
      </Story>
    </PageStripped>
  );
};
