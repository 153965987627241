import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
  useContext,
} from "react";
import { CardList, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { InvestmentAccount, dataAccounts } from "../../../../data/dataAccounts";
import { Retry, Status } from "../../../../components/retry/Retry";
import {
  dataFees,
  FeeEstimation,
  FeesEstimatedSignedIn,
} from "../../../../data/dataFees";
import { useInView } from "react-intersection-observer";
import { Disclaimer } from "../../Disclaimer";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { FormattedPercentage } from "../../../../components/formattedPercentage/FormattedPercentage";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import "./FeesExample.scss";
import { useAccountAllocation } from "../../../../hooks/useAccountAllocation";
import { AdviseAccount } from "../../../../data/dataInvestments";

interface Props {
  account: InvestmentAccount | undefined;
}

function getFutureFees(
  costData: FeeEstimation | undefined,
  showInsurance: boolean
) {
  return (
    <dl>
      <dt>
        Lysa -{" "}
        {costData ? (
          <FormattedPercentage
            value={costData.discretionary + costData.fundManagement}
            decimals={3}
          />
        ) : (
          "-"
        )}
      </dt>
      {showInsurance && costData && costData.insurancePremium ? (
        <>
          <dt>
            <FormattedMessage id="accountPage.fees.example.list.future.insurance" />{" "}
            -{" "}
            {costData ? (
              <FormattedPercentage
                value={costData.insurancePremium}
                decimals={3}
              />
            ) : (
              "-"
            )}
          </dt>
        </>
      ) : null}
      <dt>
        <FormattedMessage id="accountPage.fees.example.list.future.funds" /> -{" "}
        {costData ? (
          <FormattedPercentage value={costData.fundAssets} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
      <dt>
        <FormattedMessage id="accountPage.fees.example.list.future.transactions" />{" "}
        -{" "}
        {costData ? (
          <FormattedPercentage value={costData.transactionFees} decimals={3} />
        ) : (
          "-"
        )}
      </dt>
    </dl>
  );
}

export const FeesExample: FunctionComponent<Props> = ({ account }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [fees, setFees] = useState<FeesEstimatedSignedIn>();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const accountAllocation = useAccountAllocation(account?.accountId);

  const load = useCallback(
    (account: InvestmentAccount, accountAllocation: AdviseAccount) => {
      dataFees
        .getEstimatedFeesSignedIn({
          amount: 100,
          risk: accountAllocation.takenRisk,
          investmentType: accountAllocation.investmentType,
          country: localizationContext.state.country,
          accountType: account.type,
        })
        .then(setFees)
        .then(() => setStatus(Status.SUCCESS))
        .catch(() => setStatus(Status.ERROR));
    },
    [localizationContext.state.country]
  );

  const retry = useCallback(() => {
    if (!account || !accountAllocation) {
      return;
    }
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(account, accountAllocation);
    }, 600);
  }, [account, accountAllocation, load]);

  useEffect(() => {
    if (!inView) {
      return;
    }
    if (!account || !accountAllocation) {
      return;
    }
    load(account, accountAllocation);
  }, [inView, load, account, accountAllocation]);

  const renderInsurance = !!(
    dataAccounts.isInsuranceAccount(account) &&
    fees &&
    fees.cost.insurancePremium !== undefined
  );

  return (
    <section className="account-page-fees-example" ref={ref}>
      <h2>
        <FormattedMessage id="accountPage.fees.example.header" />
      </h2>
      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              {fees && fees.rebate && (
                <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                  <span>
                    <FormattedMessage
                      id="accountPage.fees.example.discount"
                      values={{
                        discount: intl.formatNumber(fees.rebate / 100, {
                          style: "percent",
                        }),
                        endDate: fees.rebateExpiry
                          ? intl.formatDate(fees.rebateExpiry)
                          : "-",
                      }}
                    />
                  </span>
                </Snackbar>
              )}
              <strong className="account-page-card-subheader">
                <FormattedMessage id="accountPage.fees.example.estimation" />{" "}
                <FormattedDate value={new Date()} year="numeric" /> -{" "}
                {fees?.cost.total ? (
                  <FormattedPercentage value={fees.cost.total} decimals={3} />
                ) : (
                  "-"
                )}
              </strong>
              {getFutureFees(fees?.cost, renderInsurance)}
            </div>
          </div>
        </Retry>
      </CardList>
      <Disclaimer>
        <FormattedMessage id="accountPage.fees.example.disclaimer" />
      </Disclaimer>
    </section>
  );
};
