import { Card, Spinner } from "@lysaab/ui-2";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { LocalizationContext } from "../../context/LocalizationContext";
import { AccountShareWithMe } from "../../data/dataAttorney";
import { dataPerformance } from "../../data/dataPerformance";
import { getNavLink } from "../../hooks/useCountryUrls";
import { sharedAccountDocuments } from "../documents/DocumentLists";
import {
  DocumentGroupType,
  generateDocumentLink,
} from "../documents/DocumentsPage";
import {
  SharedAccountsView,
  SHARED_ACCOUNTS_VIEW_URL,
} from "../sharedAccountsPage/SharedAccountsPage";
import { CardBottom } from "./CardBottom";
import "./FollowedAccount.scss";

interface Props {
  accountShare?: AccountShareWithMe;
}

export const FollowedAccount: React.VFC<Props> = ({ accountShare }) => {
  const {
    state: { country },
  } = useContext(LocalizationContext);

  return (
    <Card className="account-page-followed-account">
      <div className="account-page-card-body">
        {typeof accountShare === "undefined" ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              <div>
                <strong>
                  <FormattedMessage id="accountPage.followedAccount.nameLabel" />
                </strong>
              </div>
              <div>{accountShare.ownerName}</div>
            </div>
            <div className="row">
              <div>
                <strong>
                  <FormattedMessage id="accountPage.followedAccount.followedLabel" />
                </strong>
              </div>
              <div>
                {dataPerformance.toStringDate(
                  dataPerformance.toJsDate(accountShare.created)
                )}
              </div>
            </div>
            {country && (
              <div className="row">
                <div>
                  <strong>
                    <FormattedMessage id="accountPage.followedAccount.agreementLabel" />
                  </strong>
                </div>
                <div>
                  <Link
                    to={generateDocumentLink(
                      accountShare.accountShareId,
                      sharedAccountDocuments[country][0][0],
                      DocumentGroupType.Share
                    )}
                  >
                    <FormattedMessage id="accountPage.followedAccount.showAgreement" />
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CardBottom>
        <Link
          to={getNavLink(
            SHARED_ACCOUNTS_VIEW_URL(SharedAccountsView.SHARED_WITH_ME)
          )}
        >
          <FormattedMessage id="accountPage.followedAccount.handleFollowedAccounts" />
        </Link>
      </CardBottom>
    </Card>
  );
};
