import { Button } from "@lysaab/ui-2";
import React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { SUITABILITY_ASSESSMENT_EDIT_PAGE } from "../../suitabilityAssessment/SuitabilityAssessmentStory";

interface Props {
  label: React.ReactNode;
}

export const UpdateSuitabilityButton: React.VFC<Props> = ({ label }) => {
  return (
    <Button
      block
      component={Link}
      to={{
        pathname: getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE),
        state: { returnUrl: window.location.pathname },
      }}
      label={
        <TranslatedText
          id="edit-allocation.investmentBasis.suitability.update.label"
          values={{
            label,
          }}
        />
      }
    />
  );
};
