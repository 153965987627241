import React, { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const KlarnaRound: VoidFunctionComponent<Props> = ({ size = 40 }) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_902_1636)">
        <circle cx="20" cy="20" r="20" fill="#FFB3C7" />
        <path d="M13.1726 29.3516H9V11H13.1726V29.3516Z" fill="#0A0B09" />
        <path
          d="M19.3261 21.4159C22.0601 18.6688 23.5945 14.973 23.5945 11L19.5112 11C19.5112 14.3771 17.9724 17.4768 15.2896 19.5047L13.6718 20.7272L19.9401 29.3522H25.0938L19.3261 21.4159Z"
          fill="#0A0B09"
        />
        <path
          d="M26.4336 27.255C26.4336 25.938 27.4915 24.8705 28.7966 24.8705C30.1016 24.8705 31.1597 25.9379 31.1597 27.255C31.1597 28.5717 30.1016 29.6394 28.7966 29.6394C27.4915 29.6394 26.4336 28.5717 26.4336 27.255Z"
          fill="#0A0B09"
        />
      </g>
      <defs>
        <clipPath id="clip0_902_1636">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
