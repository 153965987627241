import {
  Alternative,
  Button,
  Card,
  encode,
  Form,
  LysaFormRef,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, {
  useEffect,
  VoidFunctionComponent,
  useState,
  useRef,
} from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  SavingsAccountId,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import { dataProfile } from "../../../../../data/dataProfile";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";
import { usePendingDeposits } from "../../../../../hooks/usePendingDeposits";
import { END_INVESTMENT_URL } from "../../../../../pages/endInvestment/EndInvestment";
import { NOT_FOUND_URL } from "../../../../../pages/NotFoundPage";
import { ExternalAccounts } from "./components/externalAccounts/ExternalAccounts";
import { dataSavingsAccountDistribution } from "../../../../../data/dataSavingsAccountDistribution";

import "./CloseSavingsAccountConfirmation.scss";

interface Props {
  next: () => void;
  addWithdrawalAccountUrl?: string;
}

export const CloseSavingsAccountConfirmation: VoidFunctionComponent<Props> = ({
  next,
  addWithdrawalAccountUrl,
}) => {
  const [selectedExternalAccount, setSelectedExternalAccount] =
    useState<Alternative<string>>();
  const { accounts } = useAccounts();
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState<SavingsAccount>();
  const [closeAccountError, setCloseAccountError] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const isReadonly = useIsReadOnly();
  const loadPendingDeposits = usePendingDeposits();
  const [hasAccruedInterest, setHasAccruedInterest] = useState(false);
  const { accountId } = useParams<{ accountId: SavingsAccountId }>();
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (
      typeof loadPendingDeposits === "undefined" ||
      typeof accounts === "undefined"
    ) {
      return;
    }
    Promise.all([
      loadPendingDeposits(),
      dataSavingsAccountDistribution.getAccruedInterest(accountId),
    ])
      .then(([pendingDeposits, accruedInterest]) => {
        const account = accounts.savingsAccounts.find(
          (account) => account.accountId === accountId
        );
        if (typeof account === "undefined" || isReadonly) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        } else if (account.totalBalance > 0) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        }
        if (
          pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          )
        ) {
          return history.replace(getNavLink(NOT_FOUND_URL));
        }
        setHasAccruedInterest(accruedInterest.accruedInterest > 0);
        setAccount(account);
        setIsLoading(false);
      })
      .catch((error: ServerError<unknown>) => {
        setError(true);
        setIsLoading(false);
      });
  }, [accountId, accounts, history, isReadonly, loadPendingDeposits]);

  if (error) {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="closeSavingsAccountStory.confirmation.header" />
        </Typography>
        <Card className="close-account-confirmation-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="closeSavingsAccountStory.confirmation.error" />
          </Snackbar>
        </Card>
        <Button
          block
          component={Link}
          to={getNavLink(END_INVESTMENT_URL)}
          label={
            <TranslatedText id="closeSavingsAccountStory.confirmation.back" />
          }
        />
      </>
    );
  }

  if (isLoading || typeof account === "undefined") {
    return <Spinner />;
  }

  return (
    <>
      <Typography type="h2">
        <TranslatedText id="closeSavingsAccountStory.confirmation.header" />
      </Typography>

      {isReadonly ? (
        <Card className="close-savings-account-confirmation">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="closeSavingsAccountStory.confirmation.error.closeDisabled" />
          </Snackbar>
        </Card>
      ) : (
        <Form
          lysaFormRef={formRef}
          onSubmit={() => {
            if (
              isReadonly ||
              (hasAccruedInterest &&
                typeof selectedExternalAccount === "undefined")
            ) {
              return;
            }
            setIsLoading(true);
            dataProfile
              .closeSavingsAccount(accountId, selectedExternalAccount?.value)
              .then(next)
              .catch((error: ServerError<unknown>) => {
                setCloseAccountError(true);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          {hasAccruedInterest && (
            <Card>
              <ExternalAccounts
                addWithdrawalAccountUrl={addWithdrawalAccountUrl}
                selectedExternalAccount={selectedExternalAccount}
                setSelectedExternalAccount={setSelectedExternalAccount}
              />
            </Card>
          )}
          <Card className="close-savings-account-confirmation">
            {closeAccountError && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <TranslatedText id="closeSavingsAccountStory.confirmation.error" />
              </Snackbar>
            )}
            <Typography type="body">
              <TranslatedText
                id="closeSavingsAccountStory.confirmation.ingress"
                values={{
                  accountName: account.name,
                }}
              />
            </Typography>
            <Typography type="body">
              <TranslatedText id="closeSavingsAccountStory.confirmation.download.textBefore" />
            </Typography>
            <div className="close-savings-account-confirmation-rows">
              <div className="flex">
                <span>
                  <TranslatedText id="closeSavingsAccountStory.confirmation.download.accountPerformance" />
                </span>
                <Button
                  component="a"
                  variant="secondary"
                  size="small"
                  inline
                  download
                  href={
                    process.env.REACT_APP_API +
                    encode`/gdpr-export/performance/${account.accountId}`
                  }
                  label={
                    <TranslatedText id="closeSavingsAccountStory.confirmation.account.download" />
                  }
                />
              </div>

              <div className="flex">
                <span>
                  <TranslatedText id="closeSavingsAccountStory.confirmation.download.accountTransactions" />
                </span>
                <Button
                  component="a"
                  variant="secondary"
                  size="small"
                  download
                  inline
                  href={
                    process.env.REACT_APP_API +
                    encode`/gdpr-export/transactions/${account.accountId}`
                  }
                  label={
                    <TranslatedText id="closeSavingsAccountStory.confirmation.account.download.link" />
                  }
                />
              </div>
            </div>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="closeSavingsAccountStory.confirmation.account.download.textAfter" />
            </Snackbar>
          </Card>

          <Button
            type="submit"
            block
            label={
              <TranslatedText id="closeSavingsAccountStory.confirmation.button" />
            }
          />
        </Form>
      )}
    </>
  );
};
