import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../../../data/dataAutogiro";
import { KlarnaAccount } from "../../../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { LocalizationContext } from "../../../../../../../context/LocalizationContext";
import { AutogiroConditions } from "../../../../../components/autogiroConditions/AutogiroConditions";
import { AutogiroDepositContext } from "../AutogiroDepositContext";
import { AUTOGIRO_DEPOSIT_PAGE_URL, BASE_ROUTES } from "../AutogiroDepositPage";
import { TrackerEvent } from "../../../../../../../data/dataCustomerTracking";
import { Link } from "react-router-dom";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "sweden.deposits.autogiro.story.klarna-account-selection.account.label",
  },
  accountRequired: {
    id: "sweden.deposits.autogiro.story.klarna-account-selection.account.required",
  },
});

export function KlarnaAccountSelection({ next }: Props) {
  const autogiroContext = useContext(AutogiroDepositContext);
  const [consents, setConsents] = useState<Consent[]>([]);
  const [showConsentConditions, setShowConsentConditions] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = autogiroContext.state.selectedKlarnaAccount;
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (!autogiroContext.state.klarnaAccounts) {
      history.push(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
    }
    dataAutogiro.getConsents().then((consents) => {
      setConsents(consents);
    });
  }, [history, autogiroContext.state.klarnaAccounts]);

  useEffect(() => {
    if (
      typeof autogiroContext.state.selectedKlarnaAccount !== "undefined" &&
      !consents.some(
        (consent) =>
          consent.externalBankAccount ===
          autogiroContext.state.selectedKlarnaAccount?.bankAccount
      )
    ) {
      setShowConsentConditions(true);
    } else {
      setShowConsentConditions(false);
    }
  }, [consents, autogiroContext.state.selectedKlarnaAccount]);

  if (!autogiroContext.state.klarnaAccounts) {
    return <Spinner />;
  }

  if (autogiroContext.state.klarnaAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography component="span">
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.text" />
          </Typography>
        </Snackbar>
        <Button
          component={Link}
          to={getNavLink(BASE_ROUTES.BANK_SELECTION)}
          block
          label={
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.no-accounts.try-again-button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.header" />
      </h2>
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.information" />
      </Snackbar>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_KLARNA_ACCOUNT,
            message: autogiroContext.state.selectedKlarnaAccount?.bankAccount,
          });
          next();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={autogiroContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) =>
              autogiroContext.setState({
                selectedKlarnaAccount:
                  autogiroContext.state.klarnaAccounts?.find(
                    (account) => account.id === alt.value
                  ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
          <AutogiroConditions showConsentConditions={showConsentConditions} />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.autogiro.story.klarna-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: string
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        currency: currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
}
