import { Story } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Route, Switch, useLocation } from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { PageStripped } from "../../PageStripped";
import { WITHDRAWAL_PAGE_URL } from "../overview/WithdrawalPage";
import { WithdrawalIskToSavingsContextProvider } from "./WithdrawalIskToSavingsContext";
import { SelectSavingsAccount } from "./selectSavingsAccount/SelectSavingsAccount";
import { Amount } from "./Amount/Amount";
import { Done } from "./Done/Done";
import { SelectInvestmentAccount } from "./selectInvestmentAccount/SelectInvestmentAccount";
import "./WithdrawalIskToSavingsStory.scss";
import { TransferType } from "../../../data/dataTransfer";

export const WITHDRAWAL_ISK_TO_SAVINGS_PAGE = "/withdrawal-to-savings/request";

export const BASE_ROUTES = {
  LYSA_ACCOUNT_SELECTION: `${WITHDRAWAL_ISK_TO_SAVINGS_PAGE}/`,
  SAVINGS_ACCOUNT_SELECTION: `${WITHDRAWAL_ISK_TO_SAVINGS_PAGE}/to-account`,
  AMOUNT: `${WITHDRAWAL_ISK_TO_SAVINGS_PAGE}/amount`,
  DONE: `${WITHDRAWAL_ISK_TO_SAVINGS_PAGE}/done`,
};

const messages = defineMessages({
  header: {
    id: "withdrawal.isk-to-savings.story.header",
  },
  ariaProgressLabel: {
    id: "withdrawal.isk-to-savings.story.ariaProgressLabel",
  },
});

export function WithdrawalIskToSavingsStory() {
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();

  return (
    <PageStripped>
      <div className="withdrawal-isk-to-savings-story">
        <WithdrawalIskToSavingsContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={storyProgress}
            showBack={
              currentIndex > 0 &&
              currentIndex < Object.values(ROUTES).length - 1
            }
            showClose={true}
            transitionKey={currentIndex.toString()}
            onExit={() => {
              safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
            }}
            onBack={() =>
              safeNavigation(Object.values(ROUTES)[currentIndex - 1])
            }
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route
                path={ROUTES.LYSA_ACCOUNT_SELECTION}
                exact
                render={() => (
                  <SelectInvestmentAccount
                    next={() =>
                      safeNavigation(ROUTES.SAVINGS_ACCOUNT_SELECTION)
                    }
                  />
                )}
              />
              <Route
                path={ROUTES.SAVINGS_ACCOUNT_SELECTION}
                exact
                render={() => (
                  <SelectSavingsAccount
                    next={() => safeNavigation(ROUTES.AMOUNT)}
                  />
                )}
              />
              <Route
                path={ROUTES.AMOUNT}
                exact
                render={() => (
                  <Amount
                    next={(transferType: TransferType) =>
                      safeNavigation({
                        pathname: ROUTES.DONE,
                        state: { transferType },
                      })
                    }
                  />
                )}
              />
              <Route path={ROUTES.DONE} exact render={() => <Done />} />
            </Switch>
          </Story>
        </WithdrawalIskToSavingsContextProvider>
      </div>
    </PageStripped>
  );
}
