import React, { useContext } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { KFAccountContext } from "../KFAccountContext";
import { SavingsHorizonLength } from "../../../../../../data/dataInvestments";

export const savingsHorizonAlternatives = [
  {
    text: "Mer än 15 år",
    value: SavingsHorizonLength.VERY_LONG,
  },
  {
    text: "10-15 år",
    value: SavingsHorizonLength.LONG,
  },
  {
    text: "5-10 år",
    value: SavingsHorizonLength.KINDA_LONG,
  },
  {
    text: "3-5 år",
    value: SavingsHorizonLength.MIDDLE,
  },
  {
    text: "1-3 år",
    value: SavingsHorizonLength.SHORT,
  },
];

export const Length = () => {
  const kfAccountContext = useContext(KFAccountContext);

  const value = savingsHorizonAlternatives.find(
    (alt) => alt.value === kfAccountContext.state.savingsHorizon
  );

  return (
    <Card>
      <RadioGroup
        alternatives={savingsHorizonAlternatives}
        header="Hur lång är din investeringshorisont för kontot?"
        onChange={(savingsHorizon) =>
          kfAccountContext.setState({ savingsHorizon: savingsHorizon.value })
        }
        value={value}
        validators={[new RequiredValidator("Du måste välja ett alternativ")]}
        data-test-id="horizon-length"
      />
    </Card>
  );
};
