import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  HistoricFeeAccountResponse,
  HistoricFeeClosedAccountResponse,
} from "../../../../../data/dataFees";
import { DiscretionaryFeesTable } from "./discretionaryFeesTable/DiscretionaryFeesTable";
import { FundFeesTable } from "./fundFeesTable/FundFeesTable";
import { ShowMoreButton } from "../../components/showMoreButton/ShowMoreButton";
import { defineMessages, useIntl } from "react-intl";
import { isClosedAccount } from "../AccountTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { accountTableMessages } from "../AccountTableMessages";
import { dataAccounts } from "../../../../../data/dataAccounts";
import { InvestmentAccount } from "../../../../../data/dataAccounts";
import { CostBodyCell } from "../../components/costBodyCell/CostBodyCell";
import { FeeBodyCell } from "../../components/feeBodyCell/FeeBodyCell";

const messages = defineMessages({
  noFeePaid: {
    id: "fees.feeTables.accountTables.accountRow.noFeePaid",
  },
  openButtonText: {
    id: "fees.feeTables.accountTables.accountRow.openButtonText",
  },
  closeButtonText: {
    id: "fees.feeTables.accountTables.accountRow.closeButtonText",
  },
});
interface Props {
  accountData: HistoricFeeAccountResponse | HistoricFeeClosedAccountResponse;
}

const NBR_OF_CLOSED_COLUMNS = 6;
const NBR_OF_ACTIVE_COLUMNS = 4;

export const AccountRow: VoidFunctionComponent<Props> = ({ accountData }) => {
  const intl = useIntl();
  const [showFundData, setShowFundData] = useState(false);
  const [showDiscretionaryData, setShowDiscretionaryData] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();

  useEffect(() => {
    dataAccounts.getAccounts().then((response) => {
      setAccounts(response);
    });
  }, []);

  useEffect(() => {
    setShowFundData(false);
    setShowDiscretionaryData(false);

    if (isClosedAccount(accountData)) {
      setAccountName(accountData.accountName);
    } else {
      setAccountName(
        accounts?.find((account) => {
          return account.accountId === accountData.accountId;
        })?.name || accountData.accountId
      );
    }
  }, [accountData, accounts]);

  return (
    <React.Fragment>
      <tr>
        <LysaTableTextCell
          value={accountName}
          label={intl.formatMessage(accountTableMessages.accountHeader)}
        />
        {isClosedAccount(accountData) && (
          <React.Fragment>
            <LysaTableTextCell
              value={intl.formatDate(accountData.created)}
              label={intl.formatMessage(accountTableMessages.opened)}
            />
            <LysaTableTextCell
              value={intl.formatDate(accountData.closed)}
              label={intl.formatMessage(accountTableMessages.closed)}
            />
          </React.Fragment>
        )}
        {accountData.total > 0 ? (
          <React.Fragment>
            <LysaTableTextCell
              label={intl.formatMessage(accountTableMessages.discretionaryFees)}
            >
              <ShowMoreButton
                isOpen={showDiscretionaryData}
                onClick={() => {
                  if (showFundData) {
                    setShowFundData(false);
                  }
                  setShowDiscretionaryData(!showDiscretionaryData);
                }}
                openText={intl.formatMessage(messages.openButtonText)}
                closedText={intl.formatMessage(messages.closeButtonText)}
              />
            </LysaTableTextCell>
            <LysaTableTextCell
              label={intl.formatMessage(accountTableMessages.fundFees)}
            >
              <ShowMoreButton
                isOpen={showFundData}
                onClick={() => {
                  if (showDiscretionaryData) {
                    setShowDiscretionaryData(false);
                  }
                  setShowFundData(!showFundData);
                }}
                openText={intl.formatMessage(messages.openButtonText)}
                closedText={intl.formatMessage(messages.closeButtonText)}
              />
            </LysaTableTextCell>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <LysaTableTextCell
              value={intl.formatMessage(messages.noFeePaid)}
              label={intl.formatMessage(accountTableMessages.discretionaryFees)}
            />
            <LysaTableTextCell
              value={intl.formatMessage(messages.noFeePaid)}
              label={intl.formatMessage(accountTableMessages.fundFees)}
            />
          </React.Fragment>
        )}
        <CostBodyCell
          value={accountData.averageTotalCost}
          label={intl.formatMessage(accountTableMessages.total)}
        />
        <FeeBodyCell
          value={accountData.total}
          label={intl.formatMessage(accountTableMessages.total)}
        />
      </tr>
      {showFundData && (
        <tr>
          <FundFeesTable
            accountData={accountData.fund}
            isOpen={showFundData}
            columns={
              isClosedAccount(accountData)
                ? NBR_OF_CLOSED_COLUMNS
                : NBR_OF_ACTIVE_COLUMNS
            }
          />
        </tr>
      )}
      {showDiscretionaryData && (
        <tr>
          <DiscretionaryFeesTable
            accountData={accountData.discretionary}
            isOpen={showDiscretionaryData}
            columns={
              isClosedAccount(accountData)
                ? NBR_OF_CLOSED_COLUMNS
                : NBR_OF_ACTIVE_COLUMNS
            }
          />
        </tr>
      )}
    </React.Fragment>
  );
};
