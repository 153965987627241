import { Button } from "@lysaab/ui-2";
import { DateTime } from "luxon";
import React, { useCallback, useRef, useState } from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import {
  ConfirmRef,
  ConfirmActionOverlay,
} from "../../../../../../../components/confirmActionOverlay/ConfirmActionOverlay";
import { EventTracker } from "../../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { WithdrawalItem } from "../../../../../../../components/withdrawalItem/WithdrawalItem";
import {
  InvestmentAccount,
  getAccountAmount,
  isInvestmentAccount,
  SavingsAccount,
} from "../../../../../../../data/dataAccounts";
import { TrackerEvent } from "../../../../../../../data/dataCustomerTracking";
import {
  Withdrawal,
  ExternalPendingSavingsWithdrawalResponse,
  dataWithdrawals,
} from "../../../../../../../data/dataWithdrawals";

const messages = defineMessages({
  info: {
    id: "withdrawals.pending.remove.confirm",
  },
  cancelButton: {
    id: "withdrawals.pending.abort",
  },
  confirmButton: {
    id: "withdrawals.pending.removeConfirm",
  },
});

interface Props {
  account: InvestmentAccount | SavingsAccount;
  loadPendingAccountWithdrawals: () => void;
  pendingWithdrawal: Withdrawal | ExternalPendingSavingsWithdrawalResponse;
}

export const PendingWithdrawal: React.FunctionComponent<Props> = ({
  pendingWithdrawal,
  account,
  loadPendingAccountWithdrawals,
}) => {
  const intl = useIntl();
  const confirmRef = useRef<ConfirmRef>();
  const [isError, setIsError] = useState(false);

  const removeWithdrawal = useCallback(() => {
    return isInvestmentAccount(account)
      ? dataWithdrawals.deleteWithdrawal(account.accountId)
      : dataWithdrawals.deletePendingSavingsAccountWithdrawals(
          account.accountId
        );
  }, [account]);

  if (!account) {
    return null;
  }

  return (
    <div className="pending-withdrawal">
      <ConfirmActionOverlay
        confirmRef={confirmRef}
        negative
        info={intl.formatMessage(messages.info)}
        cancelButtonText={intl.formatMessage(messages.cancelButton)}
        confirmButtonText={intl.formatMessage(messages.confirmButton)}
        errorMessage={
          isError ? (
            <TranslatedText id="withdrawals.pending.error" />
          ) : undefined
        }
        onConfirm={() =>
          removeWithdrawal()
            .then(() => {
              loadPendingAccountWithdrawals();
              EventTracker.track({
                event: TrackerEvent.DELETE_WITHDRAWAL,
                message: `Cancelled withdrawal for account ${account.accountId}`,
              });
            })
            .catch(() => {
              setIsError(true);
              setTimeout(() => setIsError(false), 5000);
            })
        }
      >
        <WithdrawalItem
          date={new Date(pendingWithdrawal.requested)}
          name={account.name}
          moneyOnAccount={getAccountAmount(account)}
          externalBank={pendingWithdrawal.bank}
          externalBankAccount={pendingWithdrawal.externalBankAccount}
          withdrawalAmount={
            isDrainWithdrawal(pendingWithdrawal)
              ? getAccountAmount(account).toString()
              : pendingWithdrawal.amount.toString()
          }
        />
        {pendingWithdrawal.cancellableUntil &&
        DateTime.fromISO(pendingWithdrawal.cancellableUntil).diffNow(
          "milliseconds"
        ).milliseconds > 0 ? (
          <div className="pending-withdrawal-abort">
            <Button
              size="small"
              variant="negative"
              onClick={() => {
                confirmRef.current?.setConfirm(true);
              }}
              label={<FormattedMessage id="withdrawals.pending.remove" />}
            />
          </div>
        ) : (
          <p>
            <i>
              <FormattedMessage id="withdrawals.pending.remove.disable" />
            </i>
          </p>
        )}
      </ConfirmActionOverlay>
    </div>
  );
};

const isDrainWithdrawal = (
  pendingWithdrawal: Withdrawal | ExternalPendingSavingsWithdrawalResponse
): pendingWithdrawal is Withdrawal => {
  return (pendingWithdrawal as Withdrawal).drain === true;
};
