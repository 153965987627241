import {
  MotionConfig,
  AnimatePresence,
  motion,
  Variants,
  HTMLMotionProps,
} from "framer-motion";
import { Stringifiable } from "query-string";
import React, {
  Children,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { DeviceType, useDeviceType } from "../../../hooks/useDeviceType";
import { TabProps } from "../tab/Tab";

enum XDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

interface Props<T extends Stringifiable> {
  activeTab: T;
  children: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
  onTabChange: (tab: T) => void;
}

export const TabView = <T extends Stringifiable>({
  activeTab,
  onTabChange,
  children,
}: PropsWithChildren<Props<T>>) => {
  const [lastIndex, setLastIndex] = useState<number>();
  const deviceType = useDeviceType();
  const [animationDirection, setAnimationDirection] = useState<XDirection>(
    XDirection.RIGHT
  );

  const numberOfRoutes = useMemo(
    () => Children.toArray(children).length,
    [children]
  );

  const currentIndex = useMemo(
    () =>
      Children.toArray(children).findIndex(
        (child) =>
          (child as React.ReactElement<TabProps>).props.tabroute === activeTab
      ),
    [activeTab, children]
  );

  const getTabRoute = useCallback(
    (direction: XDirection): T => {
      const index = currentIndex + (direction === XDirection.RIGHT ? 1 : -1);
      return (Children.toArray(children) as React.ReactElement<TabProps>[])[
        Math.min(Math.max(index, 0), numberOfRoutes - 1)
      ].props.tabroute as T;
    },
    [children, currentIndex, numberOfRoutes]
  );

  if (lastIndex !== currentIndex) {
    setLastIndex(currentIndex);
  }

  if (lastIndex !== undefined && lastIndex < currentIndex) {
    setAnimationDirection(XDirection.RIGHT);
  }
  if (lastIndex !== undefined && lastIndex > currentIndex) {
    setAnimationDirection(XDirection.LEFT);
  }

  const isFirst = (index: number) => index === 0;
  const isLast = (index: number) => index === numberOfRoutes - 1;

  return (
    <div>
      <MotionConfig
        transition={{
          x:
            deviceType === DeviceType.MOBILE
              ? { type: "spring", stiffness: 250, damping: 25 }
              : { duration: 0 },
          opacity: {
            duration: deviceType === DeviceType.MOBILE ? 0.3 : 0.15,
          },
        }}
      >
        <AnimatePresence
          exitBeforeEnter={deviceType !== DeviceType.MOBILE}
          initial={false}
          custom={animationDirection}
        >
          {Children.map(
            children,
            (child: React.ReactElement<TabProps>, index) =>
              activeTab === child.props.tabroute && (
                <motion.div
                  style={{ minWidth: "100%", maxWidth: "100%" }}
                  variants={variants(deviceType)}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  custom={animationDirection}
                  key={child.props.tabroute}
                  {...getDragProps(
                    (direction) => {
                      const newTab = getTabRoute(direction);
                      newTab !== activeTab && onTabChange(newTab);
                    },
                    isFirst(index),
                    isLast(index),
                    deviceType
                  )}
                  {...child.props}
                >
                  {child}
                </motion.div>
              )
          )}
        </AnimatePresence>
      </MotionConfig>
    </div>
  );
};

const variants = (deviceType?: DeviceType): Variants => {
  if (deviceType === DeviceType.MOBILE) {
    return {
      initial: (direction: XDirection) => ({
        opacity: 1,
        x: direction === XDirection.LEFT ? "-100%" : "100%",
        position: "absolute",
      }),
      animate: {
        opacity: 1,
        x: 0,
        position: "relative",
      },
      exit: (direction: XDirection) => ({
        opacity: 0,
        x: direction === XDirection.LEFT ? "100%" : "-100%",
        position: "absolute",
      }),
    };
  }

  return {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: { opacity: 0 },
  };
};

const getDragProps = (
  onChange: (direction: XDirection) => void,
  isFirst: boolean,
  isLast: boolean,
  deviceType?: DeviceType
): HTMLMotionProps<"div"> => ({
  // Disable drag on all devices for now, is a bit buggy in Safari iOS
  drag: deviceType === DeviceType.MOBILE ? false : false,
  dragConstraints: { left: 0, right: 0 },
  dragElastic: {
    right: isFirst ? 0.1 : 0.5,
    left: isLast ? 0.1 : 0.5,
  },
  onDragEnd: (_, info) => {
    if (info.offset.x < -200) {
      onChange(XDirection.RIGHT);
    }
    if (info.offset.x > 200) {
      onChange(XDirection.LEFT);
    }
  },
});
