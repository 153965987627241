import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useMonthlyContext } from "..//MonthlyContext";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { BASE_ROUTES, MONTHLY_DEPOSITS_URL } from "../MonthlyStory";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { KlarnaAccount } from "../../../../../data/dataKlarna";
import { dataWithdrawals } from "../../../../../data/dataWithdrawals";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "deposits.monthly.story.account-selection.account.label",
  },
  accountRequired: {
    id: "deposits.monthly.story.account-selection.account.required",
  },
});

export function AccountSelection({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const localizationContext = useContext(LocalizationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = monthlyContext.state.selectedKlarnaAccount;
  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (!monthlyContext.state.klarnaAccounts) {
      history.push(getNavLink(MONTHLY_DEPOSITS_URL));
    }
  }, [history, monthlyContext.state.klarnaAccounts]);

  if (!monthlyContext.state.klarnaAccounts || isLoading) {
    return <Spinner />;
  }

  if (monthlyContext.state.klarnaAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.text" />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText id="deposits.monthly.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            typeof monthlyContext.state.selectedKlarnaAccount === "undefined"
          ) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_KLARNA_ACCOUNT,
            message: monthlyContext.state.selectedKlarnaAccount.iban,
          });
          setIsLoading(true);
          dataWithdrawals
            .addWithdrawalAccountKlarna([
              monthlyContext.state.selectedKlarnaAccount.accountJWT,
            ])
            .then(next)
            .catch(() => {
              setIsError(true);
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.account-selection.error" />
            </Snackbar>
          )}
          <RadioGroup
            alternatives={monthlyContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) => {
              const selectedAccount = monthlyContext.state.klarnaAccounts?.find(
                (account) => account.id === alt.value
              );
              monthlyContext.setState({
                selectedKlarnaAccount: selectedAccount,
                externalAccount: selectedAccount?.iban,
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.monthly.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: string
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        currency: currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
}
