import { Button, Typography } from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useUserStorage } from "../../../../../context/UserStorageContext";
import { SAVINGS_ACCOUNT_BASE_ROUTES } from "../../../../../countries/sweden/pages/createAccount/savingsAccount/CreateSavingsAccountStory";
import {
  dataSavingsAccount,
  EffectiveInterestRateResponse,
} from "../../../../../data/dataSavingsAccount";
import { StateName } from "../../../../../data/dataUserState";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { DEPOSITS_OVERVIEW_URL } from "../../../../deposits/overview/Recommendation";
import { NewsBannerContent } from "../../components/NewsBannerContent";

export const SavingsAccountAdjustment = () => {
  const intl = useIntl();
  const { setUserStorageSyncedWithBackend } = useUserStorage();
  const [interestRate, setInterestRate] =
    useState<EffectiveInterestRateResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { accounts } = useAccounts();

  useEffect(() => {
    dataSavingsAccount
      .getInterestRate()
      .then(setInterestRate)
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (
    isLoading ||
    isError ||
    typeof interestRate === "undefined" ||
    typeof accounts === "undefined"
  ) {
    return null;
  }

  const hasSavingsAccount = accounts.savingsAccounts.length > 0;
  return (
    <NewsBannerContent
      onClose={() => {
        setUserStorageSyncedWithBackend({
          stateName: StateName.SAVINGS_ACCOUNT_INTEREST_ADJUSTMENT_V5,
          stateValue: false,
        });
      }}
      primaryButton={
        hasSavingsAccount ? (
          <Button
            component={Link}
            to={{
              pathname: getNavLink(DEPOSITS_OVERVIEW_URL),
              search: "filter=savings",
            }}
            size="small"
            label={
              <TranslatedText id="newsBanner.savingsAccountAdjustment.depositButton" />
            }
          />
        ) : (
          <Button
            component={Link}
            to={getNavLink(SAVINGS_ACCOUNT_BASE_ROUTES.INFO)}
            size="small"
            label={
              <TranslatedText id="newsBanner.savingsAccountAdjustment.openButton" />
            }
          />
        )
      }
    >
      <Typography type="body" component="p">
        <TranslatedText
          id="newsBanner.savingsAccountAdjustment.body"
          values={{
            break: () => <br />,
            interest: intl.formatNumber(
              Math.floor(interestRate.interestRate * 100_00) / 100_00, // To get two decimals but floor on third decimal ex. 0.01259 -> 1.25 %
              {
                style: "percent",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }
            ),
          }}
        />
      </Typography>
    </NewsBannerContent>
  );
};
