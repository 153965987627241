import { Story } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { Route } from "../../components/route/Route";
import { Switch } from "../../components/route/Switch";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { PageStripped } from "../../pages/PageStripped";
import { END_INVESTMENT_URL } from "../endInvestment/EndInvestment";
import { CloseAccountConfirmation } from "./closeAccountConfirmation/CloseAccountConfirmation";
import { Done } from "./done/Done";

export const CLOSE_ACCOUNT_URL = "/close-account";

export const BASE_ROUTES = {
  CLOSE_ACCOUNT_CONFIRMATION: `${CLOSE_ACCOUNT_URL}/confirm/:accountId`,
  DONE: `${CLOSE_ACCOUNT_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "closeAccount.story.header",
  },
  ariaProgressLabel: {
    id: "closeAccount.story.ariaProgressLabel",
  },
});

export function CloseAccountStory() {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={storyProgress}
        showBack={false}
        showClose={true}
        transitionKey={currentIndex.toString()}
        onExit={() => safeNavigation(getNavLink(END_INVESTMENT_URL))}
        onBack={() => {}}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route path={ROUTES.CLOSE_ACCOUNT_CONFIRMATION} exact>
            <CloseAccountConfirmation
              next={() => {
                history.replace(ROUTES.DONE);
              }}
            />
          </Route>
          <Route path={ROUTES.DONE} exact>
            <Done />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}
