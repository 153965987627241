import React, { useContext, VoidFunctionComponent } from "react";
import { Button, Card, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { SwishDepositContext } from "../SwishDepositContext";
import { useAccountName } from "../../../../../../hooks/useAccountName";
import { Amount } from "../../../../../../components/amount/Amount";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { CompoundAccountId } from "../../../../../../data/dataAccounts";
import "./Error.scss";
import { useLocation } from "react-router";

export interface ErrorLocationState {
  error: string;
}

export enum SwishError {
  TM01 = "TM01",
  RF07 = "RF07",
  AM21 = "AM21",
  AM02 = "AM02",
  BANKIDONGOING = "BANKIDONGOING",
  BANKIDCL = "BANKIDCL",
  DECLINED = "DECLINED",
}

interface MessageWithId {
  id: string;
}

const messages = defineMessages<
  SwishError,
  MessageWithId,
  Record<SwishError, MessageWithId>
>({
  [SwishError.TM01]: {
    id: "sweden.deposits.swish.story.error.description.TM01",
  },
  [SwishError.RF07]: {
    id: "sweden.deposits.swish.story.error.description.RF07",
  },
  [SwishError.AM21]: {
    id: "sweden.deposits.swish.story.error.description.AM21",
  },
  [SwishError.AM02]: {
    id: "sweden.deposits.swish.story.error.description.AM02",
  },
  [SwishError.BANKIDONGOING]: {
    id: "sweden.deposits.swish.story.error.description.BANKIDONGOING",
  },
  [SwishError.BANKIDCL]: {
    id: "sweden.deposits.swish.story.error.description.BANKIDCL",
  },
  [SwishError.DECLINED]: {
    id: "sweden.deposits.swish.story.error.description.DECLINED",
  },
});

interface Props {
  tryAgain: () => void;
  changeAmount: () => void;
  fallbackMethod: (amount?: number, accountId?: CompoundAccountId) => void;
}

export const Error: VoidFunctionComponent<Props> = ({
  changeAmount,
  fallbackMethod,
  tryAgain,
}) => {
  const intl = useIntl();
  const location = useLocation<ErrorLocationState>();
  const error = location.state.error;
  const swishDepositContext = useContext(SwishDepositContext);
  const accountName = useAccountName(swishDepositContext.state.accountId);

  const isUnknownError =
    Object.values(SwishError).includes(error as SwishError) === false;

  return (
    <div className="swish-error-page">
      <h2>
        <TranslatedText id="sweden.deposits.swish.story.error.header" />
      </h2>
      <Card>
        <Snackbar icon type={SNACKBAR_TYPES.ERROR}>
          {!isUnknownError &&
            Object.values(SwishError).includes(error as SwishError) &&
            intl.formatMessage(messages[error as SwishError])}
          {isUnknownError && (
            <TranslatedText id="sweden.deposits.swish.story.error.description.UNKNOWN" />
          )}
        </Snackbar>
        {accountName && (
          <GridRow>
            <GridCol>
              <TranslatedText id="sweden.deposits.swish.story.error.account" />
            </GridCol>
            <GridCol className="text-right">
              <strong>{accountName}</strong>
            </GridCol>
          </GridRow>
        )}
        {swishDepositContext.state.amount && (
          <GridRow>
            <GridCol>
              <TranslatedText id="sweden.deposits.swish.story.error.amount" />
            </GridCol>
            <GridCol className="text-right">
              <Amount amount={swishDepositContext.state.amount} />
            </GridCol>
          </GridRow>
        )}
      </Card>
      {(error === SwishError.TM01 ||
        error === SwishError.BANKIDCL ||
        error === SwishError.BANKIDONGOING ||
        error === SwishError.DECLINED ||
        isUnknownError) && (
        <Button
          block
          onClick={tryAgain}
          label={
            <TranslatedText id="sweden.deposits.swish.story.error.cta.reopen" />
          }
        />
      )}
      {(error === SwishError.RF07 ||
        error === SwishError.AM21 ||
        error === SwishError.AM02) && (
        <Button
          block
          onClick={changeAmount}
          label={
            <TranslatedText id="sweden.deposits.swish.story.error.cta.change-amount" />
          }
        />
      )}
      {(error === SwishError.RF07 ||
        error === SwishError.AM21 ||
        error === SwishError.AM02 ||
        error === SwishError.DECLINED ||
        isUnknownError) && (
        <Button
          variant="secondary"
          block
          onClick={() =>
            fallbackMethod(
              swishDepositContext.state.amount,
              swishDepositContext.state.accountId
            )
          }
          label={
            <TranslatedText id="sweden.deposits.swish.story.error.cta.other-method" />
          }
        ></Button>
      )}
    </div>
  );
};
