import { LysaCountry } from "@lysaab/countries";
import { defineMessages } from "react-intl";

interface Message {
  id: string;
  defaultMessage?: string;
}

export const ContactEmailAddresses = defineMessages<
  LysaCountry,
  Message,
  Record<LysaCountry, Message>
>({
  [LysaCountry.SWEDEN]: {
    id: "contact.email.se",
    defaultMessage: "kontakt@lysa.se",
  },
  [LysaCountry.DENMARK]: {
    id: "contact.email.dk",
    defaultMessage: "support@lysa.dk",
  },
  [LysaCountry.FINLAND]: {
    id: "contact.email.fi",
    defaultMessage: "support@lysa.fi",
  },
  [LysaCountry.GERMANY]: {
    id: "contact.email.de",
    defaultMessage: "support@lysa.de",
  },
  [LysaCountry.SPAIN]: {
    id: "contact.email.es",
    defaultMessage: "support@lysa.es",
  },
});

export const ContactPhoneNumbers = defineMessages<
  LysaCountry,
  Message,
  Record<LysaCountry, Message>
>({
  [LysaCountry.SWEDEN]: {
    id: "contact.phone.se",
  },
  [LysaCountry.DENMARK]: {
    id: "contact.phone.dk",
  },
  [LysaCountry.FINLAND]: {
    id: "contact.phone.fi",
  },
  [LysaCountry.GERMANY]: {
    id: "contact.phone.de",
  },
  [LysaCountry.SPAIN]: {
    id: "contact.phone.es",
  },
});

interface OpeningHours {
  OPENING: string;
  CLOSING: string;
}

export const OpeningHoursUTC = defineMessages<
  LysaCountry,
  OpeningHours,
  Record<LysaCountry, OpeningHours>
>({
  [LysaCountry.SWEDEN]: {
    OPENING: "Mon Sep 13 2021 07:00:00 UTC",
    CLOSING: "Mon Sep 13 2021 16:00:00 UTC",
  },

  [LysaCountry.DENMARK]: {
    OPENING: "Mon Sep 13 2021 07:00:00 UTC",
    CLOSING: "Mon Sep 13 2021 16:00:00 UTC",
  },

  [LysaCountry.FINLAND]: {
    OPENING: "Mon Sep 13 2021 07:00:00 UTC",
    CLOSING: "Mon Sep 13 2021 16:00:00 UTC",
  },

  [LysaCountry.GERMANY]: {
    OPENING: "Mon Sep 13 2021 08:00:00 UTC",
    CLOSING: "Mon Sep 13 2021 10:00:00 UTC",
  },

  [LysaCountry.SPAIN]: {
    OPENING: "Mon Sep 13 2021 07:00:00 UTC",
    CLOSING: "Mon Sep 13 2021 16:00:00 UTC",
  },
});
