import { useCallback, useEffect, useState } from "react";
import { InvestmentAccountId, SavingsAccountId } from "../data/dataAccounts";
import { dataSavingsAccountDistribution } from "../data/dataSavingsAccountDistribution";
import { useAccounts } from "./useAccounts";

interface AccountsWorth {
  investmentAccounts?: Record<InvestmentAccountId, number>;
  savingsAccounts?: Record<SavingsAccountId, number>;
  sharedInvestmentAccounts?: Record<InvestmentAccountId, number>;
  sharedSavingsAccounts?: Record<SavingsAccountId, number>;
  totalWorth: number;
}

export function useAccountsWorth() {
  const { accounts } = useAccounts();
  const [withdrawableAmounts, setWithdrawableAmounts] = useState<AccountsWorth>(
    { totalWorth: 0 }
  );

  const load = useCallback(async () => {
    if (typeof accounts === "undefined") {
      return;
    }
    let investmentAccounts: AccountsWorth["investmentAccounts"] = {};
    let sharedInvestmentAccounts: AccountsWorth["sharedInvestmentAccounts"] =
      {};
    let savingsAccounts: AccountsWorth["savingsAccounts"] = {};
    let sharedSavingsAccounts: AccountsWorth["sharedSavingsAccounts"] = {};

    if (accounts.investmentAccounts.length !== 0) {
      investmentAccounts = accounts.investmentAccounts.reduce<
        AccountsWorth["investmentAccounts"]
      >((investmentAccounts, account) => {
        return { ...investmentAccounts, [account.accountId]: account.worth };
      }, {});
    }

    if (accounts.savingsAccounts.length !== 0) {
      const interests =
        await dataSavingsAccountDistribution.getMultipleAccruedInterest(
          accounts.savingsAccounts.map(({ accountId }) => accountId)
        );

      savingsAccounts = interests.reduce<AccountsWorth["savingsAccounts"]>(
        (savingsAccounts, interest) => {
          const account = accounts.savingsAccounts.find(
            (account) => account.accountId === interest.accountId
          );

          if (typeof account === "undefined") {
            throw new Error(
              `Missing savings account with id: ${interest.accountId}`
            );
          }

          return {
            ...savingsAccounts,
            [interest.accountId]:
              account.totalBalance + interest.accruedInterest - interest.fee,
          };
        },
        {}
      );
    }

    //Shared accounts
    if (accounts.sharedInvestmentAccounts.length !== 0) {
      sharedInvestmentAccounts = accounts.sharedInvestmentAccounts.reduce<
        AccountsWorth["sharedInvestmentAccounts"]
      >((sharedInvestmentAccounts, account) => {
        return {
          ...sharedInvestmentAccounts,
          [account.accountId]: account.worth,
        };
      }, {});
    }

    if (accounts.sharedSavingsAccounts.length !== 0) {
      const interests =
        await dataSavingsAccountDistribution.getMultipleAccruedInterest(
          accounts.sharedSavingsAccounts.map(({ accountId }) => accountId)
        );

      sharedSavingsAccounts = interests.reduce<
        AccountsWorth["sharedSavingsAccounts"]
      >((sharedSavingsAccounts, interest) => {
        const account = accounts.sharedSavingsAccounts.find(
          (account) => account.accountId === interest.accountId
        );

        if (typeof account === "undefined") {
          throw new Error(
            `Missing savings account with id: ${interest.accountId}`
          );
        }

        return {
          ...sharedSavingsAccounts,
          [interest.accountId]:
            account.totalBalance + interest.accruedInterest - interest.fee,
        };
      }, {});
    }

    setWithdrawableAmounts({
      investmentAccounts,
      savingsAccounts,
      totalWorth: [
        ...Object.values(investmentAccounts ?? {}),
        ...Object.values(savingsAccounts ?? {}),
      ].reduce((totalBalance, worth) => totalBalance + worth, 0),
      //Shared Accounts
      sharedInvestmentAccounts,
      sharedSavingsAccounts,
    });
  }, [accounts]);

  useEffect(() => {
    load();
  }, [load]);

  return withdrawableAmounts;
}
