import {
  Card,
  Form,
  LysaFormRef,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Select,
} from "@lysaab/ui-2";
import React, { useContext, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { MonthlyContext } from "../MonthlyContext";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  amountLabel: {
    id: "sweden.deposits.monthly.story.amount.label",
  },
  amountRequired: {
    id: "sweden.deposits.monthly.story.amount.required",
  },
  amountMin: {
    id: "sweden.deposits.monthly.story.amount.min",
  },
  dayLabel: {
    id: "sweden.deposits.monthly.story.day.label",
  },
  dayPlaceholder: {
    id: "sweden.deposits.monthly.story.day.placeHolder",
  },
  dayRequired: {
    id: "sweden.deposits.monthly.story.day.required",
  },
});

const MIN_AMOUNT = 200;

const daysOfMonth = Array.from(Array(31).keys()).map((day) => day + 1);

export const MonthlyAmount = ({ next }: Props) => {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const monthlyContext = useContext(MonthlyContext);
  const intl = useIntl();

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.amount.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <Card>
          <MoneyInput
            currency={localizationContext.state.currency}
            value={monthlyContext.state.amount}
            onChange={(amount) => monthlyContext.setState({ amount })}
            label={intl.formatMessage(messages.amountLabel)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                MIN_AMOUNT,
                intl.formatMessage(messages.amountMin, {
                  minAmount: intl.formatNumber(MIN_AMOUNT, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
            ]}
          />
          <Select
            alternatives={daysOfMonth.map((day) => {
              return {
                text: day.toString(),
                value: day,
              };
            })}
            label={intl.formatMessage(messages.dayLabel)}
            placeholder={intl.formatMessage(messages.dayPlaceholder)}
            onChange={(day) =>
              monthlyContext.setState({ withdrawalDay: day.value })
            }
            value={{
              text: monthlyContext.state.withdrawalDay.toString(),
              value: monthlyContext.state.withdrawalDay,
            }}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.dayRequired)),
            ]}
          />
          <p>
            <TranslatedText id="sweden.deposits.monthly.story.day.info" />
          </p>
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.amount.next" />
          }
        />
      </Form>
    </div>
  );
};
