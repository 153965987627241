import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  LysaLink,
  Button,
  RadioGroup,
  RequiredValidator,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../components/TranslatedText";
import { KlarnaAccount } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";
import { ADD_EXTERNAL_KLARNA_URL, BASE_ROUTES } from "../AddAccountKlarnaStory";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import {
  Currency,
  LocalizationContext,
} from "../../../../context/LocalizationContext";
import { Link } from "react-router-dom";
import { ADD_EXTERNAL_MANUAL_URL } from "../../addAccountManual/AddAccountManualStory";
import { ADD_EXTERNAL_AUTOGIRO_URL } from "../../../../countries/sweden/pages/withdrawal/addAccountAutogiro/AddAccountAutogiroStory";
import { RouteAwareToggle } from "../../../../components/route/RouteAwareToggle";

interface Props {
  next: () => void;
}

interface ErrorData {
  code: number;
  message: string;
  timestamp: string;
}

const messages = defineMessages({
  accountLabel: {
    id: "widthdrawals.account.klarna.story.account-selection.account.label",
  },
  accountRequired: {
    id: "widthdrawals.account.klarna.story.account-selection.account.required",
  },
});

enum ErrorMessages {
  VERIFY_OWNER_ERROR = "Could not verify owner",
  OWNER_NOT_MATCHING = "Owner not matching",
}

export function AccountSelection({ next }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = klarnaContext.state.selectedAccount;
  const formRef = useRef<LysaFormRef>();
  const [isLoading, setIsLoading] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const [error, setError] = useState<ServerError<ErrorData>>();

  useEffect(() => {
    if (!klarnaContext.state.klarnaAccounts) {
      history.push(getNavLink(ADD_EXTERNAL_KLARNA_URL));
    }
  }, [history, klarnaContext.state.klarnaAccounts]);

  if (!klarnaContext.state.klarnaAccounts || isLoading) {
    return <Spinner />;
  }

  if (klarnaContext.state.klarnaAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.text" />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText id="widthdrawals.account.klarna.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !selectedKlarnaAccount ||
            typeof error !== "undefined"
          ) {
            return;
          }

          setIsLoading(true);

          dataWithdrawals
            .addWithdrawalAccountKlarna([selectedKlarnaAccount.accountJWT])
            .then(() => {
              setIsLoading(false);
              next();
            })
            .catch((error: ServerError<ErrorData>) => {
              setError(error);
              setIsLoading(false);
            });
        }}
      >
        <Card>
          {error && error.data?.message === ErrorMessages.OWNER_NOT_MATCHING && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}>
                <TranslatedText
                  id="sweden.widthdrawals.account.klarna.story.error-owner-not-matching"
                  values={{
                    link: (...text: string[]) => {
                      return (
                        <LysaLink
                          component={Link}
                          to={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}
                        >
                          {text}
                        </LysaLink>
                      );
                    },
                  }}
                />
              </RouteAwareToggle>
              <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_MANUAL_URL)}>
                <TranslatedText id="widthdrawals.account.klarna.story.error-owner-not-matching" />
              </RouteAwareToggle>
            </Snackbar>
          )}
          {error && error.data?.message === ErrorMessages.VERIFY_OWNER_ERROR && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}>
                <TranslatedText
                  id="sweden.widthdrawals.account.klarna.story.error-not-verified"
                  values={{
                    link: (...text: string[]) => {
                      return (
                        <LysaLink
                          component={Link}
                          to={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}
                        >
                          {text}
                        </LysaLink>
                      );
                    },
                  }}
                />
              </RouteAwareToggle>
              <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_MANUAL_URL)}>
                <TranslatedText
                  id="widthdrawals.account.klarna.story.error-not-verified"
                  values={{
                    link: (...text: string[]) => {
                      return (
                        <LysaLink
                          component={Link}
                          to={getNavLink(ADD_EXTERNAL_MANUAL_URL)}
                        >
                          {text}
                        </LysaLink>
                      );
                    },
                  }}
                />
              </RouteAwareToggle>
            </Snackbar>
          )}
          <RadioGroup
            alternatives={klarnaContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) => {
              if (error) {
                setError(undefined);
              }
              klarnaContext.setState({
                selectedAccount: klarnaContext.state.klarnaAccounts?.find(
                  (account) => account.id === alt.value
                ),
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: Currency
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        currency: currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
}
