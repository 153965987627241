import {
  Alternative,
  Card,
  LysaLink,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  dataTax,
  FiTaxHolding,
  RedemptionPart,
} from "../../../../data/dataTax";
import { Page, PageHeader } from "../../../../pages/Page";
import { TaxDetails } from "./TaxDetails";

import "./TaxInformation.scss";
import "./TaxPrint.scss";

const messages = defineMessages({
  yearsLabel: {
    id: "finland.tax.years.label",
  },
});

export const TaxInformation: React.VFC = () => {
  const [years, setYears] = useState<string[]>([]);
  const [year, setYear] = useState<string>();
  const [taxHoldings, setTaxHoldings] = useState<FiTaxHolding[]>();
  const [redemptions, setRedemptions] = useState<RedemptionPart[]>();
  const [fees, setFees] = useState<number>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const handleYearChange = ({ value }: Alternative<string>) => {
    setYear(value);
  };

  useEffect(() => {
    dataTax.getFiTaxYears().then((years) => {
      if (years.length > 0) {
        setYears(years.reverse());
        setYear(years[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (typeof year === "undefined") {
      return;
    }

    setLoading(true);
    Promise.all([
      dataTax.getFiTaxFees(year),
      dataTax.getFiTaxHoldings(year),
      dataTax.getFiTaxRedemptions(year),
    ])
      .then(([{ fees }, { holdings }, { redemptionParts }]) => {
        setFees(fees);
        setTaxHoldings(holdings);
        setRedemptions(redemptionParts);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [year]);

  if (error) {
    return (
      <Page>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="finland.tax.error" />
        </Snackbar>
      </Page>
    );
  }

  return (
    <Page className="finland-tax-page">
      <PageHeader>
        <h1>
          <TranslatedText id="finland.tax.page-header" />
        </h1>
        <div className="external-tax-information-text">
          <TranslatedText
            id="finland.tax.additional-information"
            values={{
              link: (...text: string[]) => {
                return (
                  <LysaLink
                    external
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://lysa.fi/tax-information"
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </div>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} medium={12} className="select-column">
          <h2>
            <TranslatedText id="finland.tax.select-header" />
          </h2>
          <Card>
            <Select
              alternatives={years.map((year) => ({ value: year, text: year }))}
              placeholder={intl.formatMessage(messages.yearsLabel)}
              label={intl.formatMessage(messages.yearsLabel)}
              onChange={handleYearChange}
              value={year ? { value: year, text: year } : undefined}
            />
          </Card>
        </GridCol>
        <GridCol xsmall={12} medium={12}>
          <TaxDetails
            holdings={taxHoldings}
            fees={fees}
            redemptions={redemptions}
            year={year}
            loading={loading}
          />
        </GridCol>
      </GridRow>
    </Page>
  );
};
