import React, { useCallback, VoidFunctionComponent } from "react";
import { Story } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { Switch, Route, useHistory, generatePath } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { UserManagementSelectUser } from "./userManagementSelectUser/UserManagementSelectUser";
import { UserManagementSign } from "./userManagementSign/UserManagementSign";
import { UserManagementDone } from "./userManagementDone/UserManagementDone";
import { UserManagementContextProvider } from "./UserManagementContext";
import { PageStripped } from "../../../../../pages/PageStripped";
import { messages } from "./Messages";
import { UserManagementDelete } from "./userManagementDelete/UserManagementDelete";
import { UserManagementEdit } from "./userManagementEdit/UserManagementEdit";
import { COMPANY_SETTINGS_PAGE_URL } from "../companySettings/CompanySettingsPage";
import { useStoryValues } from "../../../../../hooks/useStoryValues";

export const USER_MANAGEMENT_URL = "/user-management";

export const BASE_ROUTES = {
  HOME: USER_MANAGEMENT_URL,
  EDIT: `${USER_MANAGEMENT_URL}/:action(add|update)`,
  DELETE: `${USER_MANAGEMENT_URL}/delete`,
  SIGN: `${USER_MANAGEMENT_URL}/:action(add|update|delete)/sign`,
  DONE: `${USER_MANAGEMENT_URL}/:action(add|update|delete)/done`,
};

export enum UserManagementBasePaths {
  ADD = "add",
  UPDATE = "update",
  DELETE = "delete",
}

export const UserManagementStory: VoidFunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const next = useCallback(
    (path: string) => (action?: UserManagementBasePaths) =>
      history.push(action ? generatePath(path, { action }) : path),
    [history]
  );

  return (
    <PageStripped className="user-management-story">
      <UserManagementContextProvider>
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            currentIndex > 0 &&
            currentIndex < Object.values(BASE_ROUTES).indexOf(BASE_ROUTES.SIGN)
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            history.push(getNavLink(COMPANY_SETTINGS_PAGE_URL));
          }}
          onBack={onBack}
        >
          <Switch
            location={history.location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.HOME} exact>
              <UserManagementSelectUser
                add={() => next(ROUTES.EDIT)(UserManagementBasePaths.ADD)}
                edit={() => next(ROUTES.EDIT)(UserManagementBasePaths.UPDATE)}
              />
            </Route>

            <Route path={ROUTES.EDIT} exact>
              <UserManagementEdit
                next={next(ROUTES.SIGN)}
                remove={next(ROUTES.DELETE)}
              />
            </Route>

            <Route path={ROUTES.DELETE} exact>
              <UserManagementDelete next={next(ROUTES.SIGN)} />
            </Route>

            <Route path={ROUTES.SIGN} exact>
              <UserManagementSign next={next(ROUTES.DONE)} />
            </Route>

            <Route path={ROUTES.DONE} exact>
              <UserManagementDone home={next(ROUTES.HOME)} />
            </Route>
          </Switch>
        </Story>
      </UserManagementContextProvider>
    </PageStripped>
  );
};
