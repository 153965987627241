import React from "react";
import cx from "classnames";
import {
  AccountType as AccountTypeEnum,
  CompoundAccount,
  isSavingsAccount,
} from "../../data/dataAccounts";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import "./AccountType.scss";
import { ClosedAccount } from "../../data/dataProfile";

interface Props {
  account: CompoundAccount | ClosedAccount;
  long?: boolean;
  capitalized?: boolean;
}

interface MessageWithId {
  id: string;
}

const savingAccountMessages = defineMessages({
  long: {
    id: "account.type.savings.long",
  },
  short: {
    id: "account.type.savings",
  },
});

const shortAccountTypeMessages = defineMessages<
  AccountTypeEnum,
  MessageWithId,
  Record<AccountTypeEnum, MessageWithId>
>({
  [AccountTypeEnum.ISK_SWE]: {
    id: "account.type.isk_swe",
  },
  [AccountTypeEnum.VP_SWE]: {
    id: "account.type.vp_swe",
  },
  [AccountTypeEnum.TJP_SWE]: {
    id: "account.type.tjp_swe",
  },
  [AccountTypeEnum.DANICA_KF]: {
    id: "account.type.danica_kf",
  },
  [AccountTypeEnum.VP]: {
    id: "account.type.vp",
  },
  [AccountTypeEnum.KF_SWE]: {
    id: "account.type.kf_swe",
  },
});

const longAccountTypeMessages = defineMessages<
  AccountTypeEnum,
  MessageWithId,
  Record<AccountTypeEnum, MessageWithId>
>({
  [AccountTypeEnum.ISK_SWE]: {
    id: "account.type.isk_swe.long",
  },
  [AccountTypeEnum.VP_SWE]: {
    id: "account.type.vp_swe.long",
  },
  [AccountTypeEnum.TJP_SWE]: {
    id: "account.type.tjp_swe.long",
  },
  [AccountTypeEnum.DANICA_KF]: {
    id: "account.type.danica_kf.long",
  },
  [AccountTypeEnum.VP]: {
    id: "account.type.vp.long",
  },
  [AccountTypeEnum.KF_SWE]: {
    id: "account.type.kf_swe.long",
  },
});

export const AccountType: React.FC<Props> = ({
  account,
  long,
  capitalized,
}) => {
  const intl = useIntl();

  return (
    <span
      className={cx("account-type", {
        capitalized,
      })}
    >
      {getAccountTypeText(account, long, intl)}
    </span>
  );
};

export function getAccountTypeText(
  account: CompoundAccount | ClosedAccount,
  long: boolean = false,
  intl: IntlShape
) {
  if (isSavingsAccount(account)) {
    if (long) {
      return intl.formatMessage(savingAccountMessages.long);
    } else {
      return intl.formatMessage(savingAccountMessages.short);
    }
  }

  const { type } = account;

  if (long) {
    return intl.formatMessage(longAccountTypeMessages[type]);
  }

  return intl.formatMessage(shortAccountTypeMessages[type]);
}
