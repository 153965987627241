import { Card } from "@lysaab/ui-2";
import { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TransparentButton } from "../../../../components/transparentButton/TransparentButton";
import { CardBottom } from "../../CardBottom";
import "./PositionsExpandableCard.scss";

const MAX_HEIGHT = 600;

export const PositionsExpandableCard: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const content = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(MAX_HEIGHT);

  useEffect(() => {
    const height =
      content.current && content.current.getBoundingClientRect().height;
    if (!height) {
      return;
    }

    if (height > MAX_HEIGHT) {
      setMaxHeight(MAX_HEIGHT);
    } else {
      setMaxHeight(undefined);
    }
  }, []);

  return (
    <Card className="positions-expandable-card">
      <AnimateHeight
        height={isOpen ? "auto" : maxHeight ?? "auto"}
        animateOpacity
        duration={500}
      >
        <div className="content" ref={content}>
          {children}
        </div>
      </AnimateHeight>
      {maxHeight && (
        <CardBottom>
          <TransparentButton
            onClick={() => setIsOpen(!isOpen)}
            className="expand-button"
          >
            {isOpen ? (
              <TranslatedText id="accountPage.positions.position-card.showLess" />
            ) : (
              <TranslatedText id="accountPage.positions.position-card.showMore" />
            )}
          </TransparentButton>
        </CardBottom>
      )}
    </Card>
  );
};
