import React from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { EntityDetails } from "../../../../../../data/dataLegalEntity";
import { LegalEntityType } from "../../../../../../data/dataLogin";
import NumberFormat from "react-number-format";

interface Props {
  entityDetails: EntityDetails;
  entityType: LegalEntityType;
}

export const EntityDetailsInformation: React.VFC<Props> = ({
  entityDetails,
  entityType,
}) => {
  return (
    <React.Fragment>
      <div className="list-row">
        <dt>
          <TranslatedText id="settings.details.corp.primaryTaxResidence" />
        </dt>
        <dd>{entityDetails.taxResidence}</dd>
      </div>
      {entityType === LegalEntityType.CORPORATION && (
        <div className="list-row">
          <dt>
            <TranslatedText id="settings.details.corp.coroprateId" />
          </dt>
          <dd>{entityDetails.tin}</dd>
        </div>
      )}
      {entityType === LegalEntityType.PERSON && (
        <div className="list-row">
          <dt>
            <TranslatedText id="settings.details.corp.personId" />
          </dt>
          <dd>
            {entityDetails.tin.length === 12 ? (
              <NumberFormat
                displayType="text"
                format={"########-####"}
                value={entityDetails.tin}
              />
            ) : (
              entityDetails.tin
            )}
          </dd>
        </div>
      )}
    </React.Fragment>
  );
};
