import {
  Card,
  ConfirmCard,
  CONFIRM_BUTTON_TYPES,
  DocModalLink,
  LysaLink,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Amount } from "../../../../../components/amount/Amount";
import { LysaTable } from "../../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  dataDirectDebit,
  MandateResponse,
} from "../../../../../data/dataDirectDebit";
import {
  dataMonthlyPayments,
  MonthlyPaymentWithBankInfo,
} from "../../../../../data/dataMonthlyPayments";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MONTHLY_DEPOSITS_URL } from "../../create/MonthlyStory";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { DateTime } from "luxon";
import { UserContext } from "../../../../../context/UserContext";

import "./Mandates.scss";
import { LysaCountry } from "@lysaab/countries";
import { LocalizationContext } from "../../../../../context/LocalizationContext";

export const messages = defineMessages({
  mandateId: {
    id: "monthlyAgreement.mandates.mandateId",
  },
  externalAccountNumber: {
    id: "monthlyAgreement.mandates.externalAccountNumber",
  },
  created: {
    id: "monthlyAgreement.mandates.created",
  },
  monthlyPayment: {
    id: "monthlyAgreement.mandates.monthlyPayment",
  },
  removed: {
    id: "monthlyAgreement.mandates.removed",
  },
  showPrint: {
    id: "monthlyAgreement.mandates.showPrint",
  },
  delete: {
    id: "monthlyAgreement.mandates.delete",
  },
  cancelButton: {
    id: "monthlyAgreements.mandates.mandate.cancelButton",
  },
  confirmButton: {
    id: "monthlyAgreements.mandates.mandate.confirmButton",
  },
  cancelText: {
    id: "monthlyAgreements.mandates.mandate.cancelText",
  },
  modalAnnouncement: {
    id: "monthlyAgreements.mandates.mandate.modalAnnouncement",
  },
});

const mandateDocument: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "",
  [LysaCountry.DENMARK]: "",
  [LysaCountry.FINLAND]: "legal/all/en/sepa-direct-debit-mandate.md",
  [LysaCountry.GERMANY]: "legal/de/de/sepa-direct-debit-mandate-GE.md",
  [LysaCountry.SPAIN]: "",
};

export const Mandates = () => {
  const [activeMandates, setActiveMandates] = useState<MandateResponse[]>([]);
  const [removedMandates, setRemovedMandates] = useState<MandateResponse[]>([]);
  const [monthlyPayments, setMonthlyPayments] = useState<
    MonthlyPaymentWithBankInfo[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [nonRemovableMandateId, setNonRemovableMandateId] = useState<number>();
  const [selectedMandate, setSelectedMandate] = useState<MandateResponse>();
  const intl = useIntl();
  const { state: user } = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);

  const getMonthlyAgreements = useCallback(() => {
    setIsLoading(true);
    Promise.all([
      dataDirectDebit.getLoadAllActiveMandates(),
      dataDirectDebit.getLoadAllRemovedMandates(),
      dataMonthlyPayments.getMonthlyPayments(),
    ])
      .then(([activeMandates, removedMandates, monthlyPayments]) => {
        setActiveMandates(activeMandates);
        setRemovedMandates(
          removedMandates.filter((mandate) => {
            if (!mandate.removed) {
              return false;
            }
            const twoMonthsAgo = DateTime.now().minus({ months: 2 });
            const removedDate = DateTime.fromISO(mandate.removed);
            return twoMonthsAgo < removedDate;
          })
        );
        setMonthlyPayments(monthlyPayments);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getMonthlyAgreements();
  }, [getMonthlyAgreements]);

  if (isLoading) {
    return <Spinner />;
  }

  if (activeMandates.length === 0 && removedMandates.length === 0) {
    return (
      <TranslatedText
        id="monthlyAgreement.mandates.noMandates"
        values={{
          link: (...parts: string[]) => (
            <LysaLink component={Link} to={getNavLink(MONTHLY_DEPOSITS_URL)}>
              {parts}
            </LysaLink>
          ),
        }}
      />
    );
  }

  return (
    <div className="mandates">
      <h2>
        <TranslatedText id="monthlyAgreement.mandates.activeHeader" />
      </h2>
      <ConfirmCard
        cancelButtonText={intl.formatMessage(messages.cancelButton)}
        confirmButtonText={intl.formatMessage(messages.confirmButton)}
        confirmText={intl.formatMessage(messages.cancelText)}
        onConfirm={(status) => {
          setShowConfirm(false);
          if (!status || typeof selectedMandate === "undefined") {
            return;
          }
          dataDirectDebit.deleteMandate(selectedMandate.id).then(() => {
            setSelectedMandate(undefined);
            return getMonthlyAgreements();
          });
        }}
        showConfirm={showConfirm}
        confirmButtonType={CONFIRM_BUTTON_TYPES.NEGATIVE}
      >
        {typeof nonRemovableMandateId !== "undefined" && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText
              id="monthlyAgreements.mandates.mandate.nonRemovable"
              values={{ mandateId: nonRemovableMandateId }}
            />
          </Snackbar>
        )}
        {activeMandates.length === 0 && (
          <p>
            <TranslatedText
              id="monthlyAgreement.mandates.noActiveMandate"
              values={{
                link: (...parts: string[]) => (
                  <Link
                    to={getNavLink(MONTHLY_DEPOSITS_URL)}
                    className="lysa-link"
                  >
                    {parts}
                  </Link>
                ),
              }}
            />
          </p>
        )}
        {activeMandates.length > 0 && (
          <LysaTable>
            <thead>
              <tr>
                <LysaTableTextCell
                  value={intl.formatMessage(messages.mandateId)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.externalAccountNumber)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.created)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.monthlyPayment)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.showPrint)}
                  header
                />
                <LysaTableTextCell
                  value={intl.formatMessage(messages.delete)}
                  header
                />
              </tr>
            </thead>
            <tbody>
              {activeMandates.map((mandate) => {
                const monthlyPayment = monthlyPayments?.find(
                  (monthlyPayment) => {
                    return (
                      monthlyPayment.externalBankAccount ===
                      mandate.externalAccountNumber
                    );
                  }
                );
                const varValues: Record<string, string> = {
                  CUSTOMER_NAME: user.name ?? "",
                  CUSTOMER_TIN: user.tin ?? "",
                  ACCOUNT_NUMBER: mandate.externalAccountNumber,
                  SIGNING_DATE: intl.formatDate(mandate.created),
                  MANDATE_REFERENCE: mandate.id.toString(),
                };

                return (
                  <tr key={mandate.id + mandate.created}>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.mandateId)}
                      value={String(mandate.id)}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.externalAccountNumber)}
                      value={mandate.externalAccountNumber}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.created)}
                      value={intl.formatDate(mandate.created)}
                    />
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.monthlyPayment)}
                    >
                      <Amount amount={monthlyPayment?.amount || 0} />
                    </LysaTableTextCell>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.showPrint)}
                    >
                      <DocModalLink
                        document={
                          mandateDocument[
                            localizationContext.state.country ||
                              LysaCountry.FINLAND
                          ]
                        }
                        modalAnnouncement={intl.formatMessage(
                          messages.modalAnnouncement
                        )}
                        variableValues={varValues}
                        className="mandates__print-link"
                      >
                        <span className="lysa-link">
                          <TranslatedText id="monthlyAgreements.mandates.mandate.show" />
                        </span>
                      </DocModalLink>
                    </LysaTableTextCell>
                    <LysaTableTextCell
                      label={intl.formatMessage(messages.delete)}
                    >
                      <Button
                        size="small"
                        variant="negative"
                        onClick={() => {
                          if (
                            typeof monthlyPayment !== "undefined" &&
                            monthlyPayment.amount > 0
                          ) {
                            setNonRemovableMandateId(mandate.id);
                          } else {
                            setSelectedMandate(mandate);
                            setShowConfirm(true);
                          }
                        }}
                        label={
                          <TranslatedText id="monthlyAgreements.mandates.mandate.removeButtone" />
                        }
                      />
                    </LysaTableTextCell>
                  </tr>
                );
              })}
            </tbody>
          </LysaTable>
        )}
      </ConfirmCard>
      {removedMandates.length > 0 && (
        <React.Fragment>
          <h2>
            <TranslatedText id="monthlyAgreement.mandates.removedHeader" />
          </h2>
          <Card>
            <LysaTable>
              <thead>
                <tr>
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.mandateId)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.externalAccountNumber)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.created)}
                    header
                  />
                  <LysaTableTextCell
                    value={intl.formatMessage(messages.removed)}
                    header
                  />
                </tr>
              </thead>
              <tbody>
                {removedMandates.map((mandate) => {
                  if (!mandate.removed) {
                    return null;
                  }
                  return (
                    <tr key={mandate.id + mandate.removed}>
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.mandateId)}
                        value={String(mandate.id)}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(
                          messages.externalAccountNumber
                        )}
                        value={mandate.externalAccountNumber}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.created)}
                        value={intl.formatDate(mandate.created)}
                      />
                      <LysaTableTextCell
                        label={intl.formatMessage(messages.removed)}
                        value={intl.formatDate(mandate.removed)}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </LysaTable>
          </Card>
        </React.Fragment>
      )}
    </div>
  );
};
