import React, { VoidFunctionComponent } from "react";
import { Spinner } from "@lysaab/ui-2";
import { AccountKyc } from "../../../components/kyc/accountType/AccountKyc";
import { AccountCorporationKyc } from "../../../components/kyc/accountType/AccountCorporationKyc";
import {
  AccountKycAccountCorporationInternal,
  AccountKycAccountInteral,
  AccountKycInternal,
  AccountKycSavingsAccountInternal,
  useAccountKyc,
} from "../../../hooks/useAccountsKyc";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { KYC_PAGE_URL } from "../KycViewPage";
import { FormattedMessage } from "react-intl";
import { AccountKycType } from "../../../data/dataKyc";
import { SavingsAccountKyc } from "../../../components/kyc/accountType/SavingsAccountKyc";

const typeMapping: Record<
  AccountKycType,
  (accountKyc: AccountKycInternal) => React.ReactElement
> = {
  [AccountKycType.ACCOUNT]: (accountKyc: AccountKycInternal) => (
    <AccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountInteral}
      display={true}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
  [AccountKycType.SAVINGS_ACCOUNT]: (accountKyc: AccountKycInternal) => (
    <SavingsAccountKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycSavingsAccountInternal}
      display={true}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
  [AccountKycType.ACCOUNT_CORPORATION]: (accountKyc: AccountKycInternal) => (
    <AccountCorporationKyc
      key={accountKyc.accountId}
      accountKyc={accountKyc as AccountKycAccountCorporationInternal}
      display={true}
      editReturnUrl={getNavLink(KYC_PAGE_URL)}
    />
  ),
};

export const AccountsKyc: VoidFunctionComponent = () => {
  const [accountsKyc] = useAccountKyc();

  if (!accountsKyc) {
    return <Spinner />;
  }

  return (
    <>
      <h2>
        <FormattedMessage id="kyc.account.header" />
      </h2>
      {accountsKyc?.map((accountKyc) =>
        typeMapping[accountKyc.accountKycType](accountKyc)
      )}
    </>
  );
};
