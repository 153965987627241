import { useContext, useEffect, useState } from "react";
import {
  Button,
  NewIcon,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
  useFlash,
} from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../../../components/TranslatedText";
import { isInvestmentAccount } from "../../../../data/dataAccounts";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../overview/OverviewPage";

import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useWithdrawalContext } from "../WithdrawalContext";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import { BankLogo } from "../../../../components/bankLogo/BankLogo";
import { dataBanks } from "../../../../data/dataBanks";
import { AccountTypeLogo } from "../../../../components/accountType/AccountTypeLogo";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { Link, useHistory, useLocation } from "react-router-dom";

import "./Done.scss";
import { WithdrawalType } from "../amount/Amount";

export function Done() {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const withdrawalContext = useWithdrawalContext();
  const [abortWithdrawalInitiated, setAbortWithdrawalInitiated] =
    useState<boolean>(false);
  const pushFlash = useFlash();
  const history = useHistory();
  const location = useLocation<{ withdrawalType: WithdrawalType }>();
  const [isDrain, setIsDrain] = useState<boolean>(false);
  const fromAccount = withdrawalContext.state.selectedLysaAccount
    ? withdrawalContext.state.selectedLysaAccount
    : withdrawalContext.state.selectedLysaSavingsAccount;
  const toAccount = withdrawalContext.state.selectedExternalAccount;

  useEffect(() => {
    if (isInvestmentAccount(fromAccount)) {
      setIsDrain(location.state.withdrawalType === WithdrawalType.DRAIN);
    }
  }, [fromAccount, location.state.withdrawalType]);

  const handleCancelWithdrawal = async () => {
    if (!fromAccount) {
      return;
    }
    setAbortWithdrawalInitiated(true);
    try {
      if (isInvestmentAccount(fromAccount)) {
        await dataWithdrawals.deleteWithdrawal(fromAccount.accountId);
      } else {
        await dataWithdrawals.deletePendingSavingsAccountWithdrawals(
          fromAccount.accountId
        );
      }
    } catch (error) {
      throw error;
    }
  };

  if (!withdrawalContext || !fromAccount || !toAccount) {
    return <Spinner />;
  }

  return (
    <div className="external-withdrawal-done-wrapper">
      <NewIcon.Check size={56} className="done-page__check-icon" />
      <Typography type="h2">
        <TranslatedText id="withdrawal.story.done.header" />
      </Typography>
      <div className="info-wrapper">
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.story.done.time.header" />
          </Typography>
          <Typography type="label">
            <TranslatedText id="withdrawal.story.done.time.body" />
          </Typography>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.story.done.amount.header" />
          </Typography>
          <Typography type="label">
            {isDrain ? (
              <TranslatedText id="withdrawal.story.done.amount.drain" />
            ) : (
              typeof withdrawalContext.state.amount !== "undefined" &&
              intl.formatNumber(Number(withdrawalContext.state.amount), {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            )}
          </Typography>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.story.done.from-account.header" />
          </Typography>
          <div className="account-wrapper">
            <AccountTypeLogo account={fromAccount} size={24} />
            <Typography type="label">{fromAccount.name}</Typography>
          </div>
        </div>
        <div className="row">
          <Typography type="label">
            <TranslatedText id="withdrawal.story.done.to-account.header" />
          </Typography>
          <div className="account-wrapper">
            <BankLogo bank={dataBanks.getBank(toAccount.bank)} size={24} />
            <Typography type="label">
              {toAccount.externalBankAccount}
            </Typography>
          </div>
        </div>
        <HorizontalDivider />
        <Typography type="body" variant="secondary" component="span">
          {isDrain ? (
            <TranslatedText id="withdrawal.story.done.drain-disclaimer" />
          ) : (
            <TranslatedText id="withdrawal.story.done.disclaimer" />
          )}
        </Typography>
      </div>
      <div className="external-withdrawal-done__button-wrapper">
        <Button
          block
          component={Link}
          to={getNavLink(OVERVIEW_PAGE_URL)}
          label={<FormattedMessage id="withdrawal.story.done.button" />}
        />

        <Button
          type="button"
          variant="secondary"
          disabled={abortWithdrawalInitiated}
          block
          onClick={() => {
            handleCancelWithdrawal().then(() => {
              pushFlash({
                text: (
                  <FormattedMessage id="withdrawal.story.done.cancel-flash-message" />
                ),
                type: SNACKBAR_TYPES.INFO,
                timer: 3000,
              });
              setTimeout(() => {
                history.push(getNavLink(OVERVIEW_PAGE_URL));
              }, 1500);
            });
            window.plausible("ABORT_WITHDRAWAL", {
              props: {
                from_type: withdrawalContext.state.selectedLysaAccount
                  ? "INVESTMENT_ACCOUNT"
                  : "SAVINGS_ACCOUNT",
              },
            });
          }}
          label={<FormattedMessage id="withdrawal.story.done.cancel-button" />}
        />
      </div>
    </div>
  );
}
