import { LysaCountry } from "@lysaab/countries";
import { PepRequest } from "@lysaab/lysa-pep";
import { API, cache } from "@lysaab/ui-2";
import { CompoundAccountId, InvestmentAccountId } from "./dataAccounts";
import { LegalEntityType } from "./dataLogin";
import { LegalEntityId } from "./dataUser";

/**
 * Maps to backend enum types for kyc questions (kycQuestionType)
 * This only includes kyc for account types
 */
export enum AccountKycType {
  ACCOUNT,
  ACCOUNT_CORPORATION,
  SAVINGS_ACCOUNT,
}

export enum SwedenBanks {
  SE_SWEDBANK = "SE_SWEDBANK",
  SE_HB = "SE_HB",
  SE_NORDEA = "SE_NORDEA",
  SE_SEB = "SE_SEB",
  SE_LF = "SE_LF",
  SE_SKANDIA = "SE_SKANDIA",
  SE_DB = "SE_DB",
  SE_OTHER = "SE_OTHER",
}
export enum DenmarkBanks {
  DK_DB = "DK_DB",
  DK_JYSKE = "DK_JYSKE",
  DK_NORDEA = "DK_NORDEA",
  DK_NYKREDIT = "DK_NYKREDIT",
  DK_SYDBANK = "DK_SYDBANK",
  DK_OTHER = "DK_OTHER",
}
export enum FinlandBanks {
  FI_OP = "FI_OP",
  FI_NORDEA = "FI_NORDEA",
  FI_DB = "FI_DB",
  FI_HB = "FI_HB",
  FI_SP = "FI_SP",
  FI_OTHER = "FI_OTHER",
}
export enum GermanyBanks {
  DE_DB = "DE_DB",
  DE_DZ = "DE_DZ",
  DE_KFW = "DE_KFW",
  DE_COMMERZ = "DE_COMMERZ",
  DE_HYPO = "DE_HYPO",
  DE_OTHER = "DE_OTHER",
}
export enum SpainBanks {
  ES_SAN = "ES_SAN",
  ES_BBVA = "ES_BBVA",
  ES_CAIXA = "ES_CAIXA",
  ES_SABADELL = "ES_SABADELL",
  ES_BANKIA = "ES_BANKIA",
  ES_OTHER = "ES_OTHER",
}

// Map used in isCountryBank
export const banks: Record<string, BankType[]> = {
  [LysaCountry.SWEDEN]: Object.values(SwedenBanks),
  [LysaCountry.DENMARK]: Object.values(DenmarkBanks),
  [LysaCountry.FINLAND]: Object.values(FinlandBanks),
  [LysaCountry.GERMANY]: Object.values(GermanyBanks),
  [LysaCountry.SPAIN]: Object.values(SpainBanks),
};

// Default all banks, pass T to get banks for country
export type BankType<T = unknown> = T extends LysaCountry.SWEDEN
  ? SwedenBanks
  : T extends LysaCountry.DENMARK
  ? DenmarkBanks
  : T extends LysaCountry.FINLAND
  ? FinlandBanks
  : T extends LysaCountry.GERMANY
  ? GermanyBanks
  : T extends LysaCountry.SPAIN
  ? SpainBanks
  : SwedenBanks | DenmarkBanks | FinlandBanks | GermanyBanks | SpainBanks;

export enum SwedenPersonDeposits {
  SE_0_100K_SEK = "SE_0_100K_SEK",
  SE_100K_1M_SEK = "SE_100K_1M_SEK",
  SE_1M_5M_SEK = "SE_1M_5M_SEK",
  SE_5M_SEK = "SE_5M_SEK",
}
export enum DenmarkPersonDeposits {
  DK_0_100K_DKK = "DK_0_100K_DKK",
  DK_100K_1M_DKK = "DK_100K_1M_DKK",
  DK_1M_5M_DKK = "DK_1M_5M_DKK",
  DK_5M_DKK = "DK_5M_DKK",
}
export enum FinlandPersonDeposits {
  FI_0_10K_EUR = "FI_0_10K_EUR",
  FI_10K_100K_EUR = "FI_10K_100K_EUR",
  FI_100K_500K_EUR = "FI_100K_500K_EUR",
  FI_500K_EUR = "FI_500K_EUR",
}
export enum GermanyPersonDeposits {
  DE_0_10K_EUR = "DE_0_10K_EUR",
  DE_10K_100K_EUR = "DE_10K_100K_EUR",
  DE_100K_500K_EUR = "DE_100K_500K_EUR",
  DE_500K_EUR = "DE_500K_EUR",
}
export enum SpainPersonDeposits {
  ES_0_10K_EUR = "ES_0_10K_EUR",
  ES_10K_100K_EUR = "ES_10K_100K_EUR",
  ES_100K_500K_EUR = "ES_100K_500K_EUR",
  ES_500K_EUR = "ES_500K_EUR",
}

// Map used in isCountryDepositPerson
export const depositsPerson: Record<LysaCountry, DepositTypePerson[]> = {
  [LysaCountry.SWEDEN]: Object.values(
    SwedenPersonDeposits
  ) as SwedenPersonDeposits[],
  [LysaCountry.DENMARK]: Object.values(
    DenmarkPersonDeposits
  ) as DenmarkPersonDeposits[],
  [LysaCountry.FINLAND]: Object.values(
    FinlandPersonDeposits
  ) as FinlandPersonDeposits[],
  [LysaCountry.GERMANY]: Object.values(
    GermanyPersonDeposits
  ) as GermanyPersonDeposits[],
  [LysaCountry.SPAIN]: Object.values(
    SpainPersonDeposits
  ) as SpainPersonDeposits[],
};

// Default all deposits for LegalEntityType.PERSON , pass T to get banks for country
export type DepositTypePerson<T = unknown> = T extends LysaCountry.SWEDEN
  ? SwedenPersonDeposits
  : T extends LysaCountry.DENMARK
  ? DenmarkPersonDeposits
  : T extends LysaCountry.FINLAND
  ? FinlandPersonDeposits
  : T extends LysaCountry.GERMANY
  ? GermanyPersonDeposits
  : T extends LysaCountry.SPAIN
  ? SpainPersonDeposits
  :
      | SwedenPersonDeposits
      | DenmarkPersonDeposits
      | FinlandPersonDeposits
      | GermanyPersonDeposits
      | SpainPersonDeposits;

export enum SwedenCorporationDeposits {
  SE_0_5M_SEK = "SE_0_5M_SEK",
  SE_5M_SEK = "SE_5M_SEK",
}

export const depositsCorporation: Record<
  LysaCountry,
  DepositTypeCorporation[]
> = {
  [LysaCountry.SWEDEN]: Object.values(
    SwedenCorporationDeposits
  ) as SwedenCorporationDeposits[],
  [LysaCountry.DENMARK]: [] as never,
  [LysaCountry.FINLAND]: [] as never,
  [LysaCountry.GERMANY]: [] as never,
  [LysaCountry.SPAIN]: [] as never,
};

// Default all deposits for LegalEntityType.CORPORATION , pass T to get banks for country
export type DepositTypeCorporation<T = unknown> = T extends LysaCountry.SWEDEN
  ? SwedenCorporationDeposits
  : T extends LysaCountry.DENMARK
  ? never
  : T extends LysaCountry.FINLAND
  ? never
  : T extends LysaCountry.GERMANY
  ? never
  : T extends LysaCountry.SPAIN
  ? never
  : SwedenCorporationDeposits;

// Deposits for each LegalEntityType
const deposits: Record<
  LegalEntityType,
  Record<LysaCountry, (DepositTypePerson | DepositTypeCorporation)[]>
> = {
  [LegalEntityType.PERSON]: depositsPerson,
  [LegalEntityType.CORPORATION]: depositsCorporation,
};

// Default all deposits, pass L to get deposits for LegalEntityTypem pass T to get banks for country
export type DepositType<L = {}, T = unknown> = L extends LegalEntityType.PERSON
  ? DepositTypePerson<T>
  : L extends LegalEntityType.CORPORATION
  ? DepositTypeCorporation<T>
  : DepositTypePerson<T> | DepositTypeCorporation<T>;

export type KycDeposits =
  | DepositTypePerson<LysaCountry.SWEDEN>[]
  | DepositTypePerson<LysaCountry.DENMARK>[]
  | DepositTypePerson<LysaCountry.FINLAND>[]
  | DepositTypePerson<LysaCountry.GERMANY>[]
  | DepositTypePerson<LysaCountry.SPAIN>[]
  | DepositTypeCorporation<LysaCountry.SWEDEN>[];

export type KycBanks =
  | BankType<LysaCountry.SWEDEN>[]
  | BankType<LysaCountry.DENMARK>[]
  | BankType<LysaCountry.FINLAND>[]
  | BankType<LysaCountry.GERMANY>[]
  | BankType<LysaCountry.SPAIN>[];

export enum Employment {
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  OTHER = "OTHER",
}

export enum MoneyOriginPerson {
  SAVINGS = "SAVINGS",
  INHERITANCE = "INHERITANCE",
  INCOME = "INCOME",
  PROPERTY_SALE = "PROPERTY_SALE",
  CORPORATION_SALE = "CORPORATION_SALE",
  TRADE = "TRADE",
  DIVIDEND = "DIVIDEND",
}

export enum MoneyOriginCorporation {
  TRADE = "TRADE",
  CORPORATION_SALE = "CORPORATION_SALE",
  PROPERTY_SALE = "PROPERTY_SALE",
  DIVIDEND = "DIVIDEND",
}

const moneyOrigin: Record<LegalEntityType, string[]> = {
  [LegalEntityType.PERSON]: Object.values(MoneyOriginPerson),
  [LegalEntityType.CORPORATION]: Object.values(MoneyOriginCorporation),
};

// Default all moneyOrigins, pass L to get purposes for LegalEntityType
export type MoneyOrigin<T = unknown> = T extends LegalEntityType.PERSON
  ? MoneyOriginPerson
  : T extends LegalEntityType.CORPORATION
  ? MoneyOriginCorporation
  : MoneyOriginPerson | MoneyOriginCorporation;

export enum PurposeAccount {
  FINANCIAL_SECURITY = "FINANCIAL_SECURITY",
  PENSION = "PENSION",
  CONSUMPTION = "CONSUMPTION",
  GIFT = "GIFT",
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS",
}

export enum SavingsAccountPurpose {
  FINANCIAL_SECURITY = "FINANCIAL_SECURITY",
  CONSUMPTION = "CONSUMPTION",
  GIFT = "GIFT",
}

export enum PurposeAccountCorporation {
  SURPLUS_LIQUIDITY = "SURPLUS_LIQUIDITY",
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS",
}

const purpose: Record<AccountKycType, string[]> = {
  [AccountKycType.ACCOUNT]: Object.values(PurposeAccount) as PurposeAccount[],
  [AccountKycType.ACCOUNT_CORPORATION]: Object.values(
    PurposeAccountCorporation
  ) as PurposeAccountCorporation[],
  [AccountKycType.SAVINGS_ACCOUNT]: Object.values(
    SavingsAccountPurpose
  ) as SavingsAccountPurpose[],
};

// Default all purposes, pass L to get purposes for LegalEntityType
export type Purpose<T = unknown> = T extends AccountKycType.ACCOUNT
  ? PurposeAccount
  : T extends AccountKycType.ACCOUNT_CORPORATION
  ? PurposeAccountCorporation
  : PurposeAccount | PurposeAccountCorporation;

export enum DepositInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",
}

export enum SavingsAccountDepositInterval {
  MORE_THAN_FIVE_TIMES_MONTHLY = "MORE_THAN_FIVE_TIMES_MONTHLY",
  ONE_TO_FIVE_TIMES_MONTHLY = "ONE_TO_FIVE_TIMES_MONTHLY",
  LESS_THAN_ONE_TIME_MONTHLY = "LESS_THAN_ONE_TIME_MONTHLY",
}

export enum WithdrawalInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",
}

export enum SavingsAccountWithdrawalInterval {
  MORE_THAN_FIVE_TIMES_MONTHLY = "MORE_THAN_FIVE_TIMES_MONTHLY",
  ONE_TO_FIVE_TIMES_MONTHLY = "ONE_TO_FIVE_TIMES_MONTHLY",
  LESS_THAN_ONE_TIME_MONTHLY = "LESS_THAN_ONE_TIME_MONTHLY",
}

export interface CustomerKyc {
  kycId: LegalEntityId;
  /**
   * Timestamp
   */
  answered: string;
  questionAnswers: QuestionAnswer[];
}

export interface QuestionAnswer {
  question: KycQuestion;
  answers: KycAnswer[];
}

export interface AccountKyc {
  kycId: InvestmentAccountId;
  /**
   * Timestamp
   */
  answered: string;
  questionAnswers: QuestionAnswer[];
}

export interface KycResponse {
  customerKyc: CustomerKyc;
  accountsKyc: AccountKyc[];
}

export interface AccountKycRequestAccount {
  accountKycType: AccountKycType.ACCOUNT;
  accountKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.PURPOSE;
        answers: PurposeAccount[];
      },
      {
        question: KycQuestion.DEPOSIT_INTERVAL;
        answers: [DepositInterval];
      },
      {
        question: KycQuestion.WITHDRAWAL_INTERVAL;
        answers: WithdrawalInterval[];
      }
    ];
  };
}

export interface AccountKycRequestSavingsAccount {
  accountKycType: AccountKycType.SAVINGS_ACCOUNT;
  accountKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.PURPOSE;
        answers: SavingsAccountPurpose[];
      },
      {
        question: KycQuestion.DEPOSIT_INTERVAL;
        answers: [SavingsAccountDepositInterval];
      },
      {
        question: KycQuestion.WITHDRAWAL_INTERVAL;
        answers: SavingsAccountWithdrawalInterval[];
      }
    ];
  };
}

export interface AccountKycRequestAccountCorporation {
  accountKycType: AccountKycType.ACCOUNT_CORPORATION;
  accountKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.PURPOSE;
        answers: PurposeAccountCorporation[];
      },
      {
        question: KycQuestion.DEPOSIT_INTERVAL;
        answers: [DepositInterval];
      },
      {
        question: KycQuestion.WITHDRAWAL_INTERVAL;
        answers: WithdrawalInterval[];
      }
    ];
  };
}

export type SaveAccountRequest =
  | AccountKycRequestAccount
  | AccountKycRequestAccountCorporation
  | AccountKycRequestSavingsAccount;

export interface CustomerKycPerson {
  legalEntityType: LegalEntityType.PERSON;
  customerKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.DEPOSIT_YEARLY_VALUE;
        answers: [DepositType<LegalEntityType.PERSON>];
      },
      {
        question: KycQuestion.EMPLOYMENT;
        answers: [Employment];
      },
      {
        question: KycQuestion.MONEY_ORIGIN;
        answers: MoneyOrigin<LegalEntityType.PERSON>[];
      },
      {
        question: KycQuestion.MONEY_BANK_ORIGIN;
        answers: BankType[];
      }
    ];
  };
}

export interface CustomerKycCorporation {
  legalEntityType: LegalEntityType.CORPORATION;
  customerKyc: {
    version: "3";
    questionAnswers: [
      {
        question: KycQuestion.DEPOSIT_YEARLY_VALUE;
        answers: [DepositType<LegalEntityType.CORPORATION>];
      },
      {
        question: KycQuestion.MONEY_ORIGIN;
        answers: MoneyOrigin<LegalEntityType.CORPORATION>[];
      },
      {
        question: KycQuestion.MONEY_BANK_ORIGIN;
        answers: BankType[];
      }
    ];
  };
}

export type SaveCustomerKycRequest<T = unknown> =
  T extends LegalEntityType.PERSON
    ? CustomerKycPerson
    : T extends LegalEntityType.CORPORATION
    ? CustomerKycCorporation
    : CustomerKycPerson | CustomerKycCorporation;

export interface PepAnswer {
  answers: PepRequest;
  date: string;
  tin: string;
  locked: boolean;
}

export const dataKyc = {
  getKyc: () => {
    return API.get<KycResponse>(`/kyc/v3`);
  },
  saveCustomerKyc: (kyc: SaveCustomerKycRequest) => {
    return API.post<CustomerKyc>("/kyc/v3", kyc.customerKyc).then(
      (response) => {
        cache.delete("/kyc/v3");
        return response;
      }
    );
  },
  saveAccountKyc: (accountId: CompoundAccountId, kyc: SaveAccountRequest) => {
    return API.post<CustomerKyc>(
      `/kyc/v3/accounts/${accountId}`,
      kyc.accountKyc
    ).then((response) => {
      cache.delete("/kyc/v3");
      return response;
    });
  },
  getPep: () => {
    return API.get<PepAnswer[]>("/kyc/pep");
  },
  updatePep: (data: PepRequest) => {
    return API.post("/kyc/pep", data).then((response) => {
      cache.delete("/kyc/pep");
      return response;
    });
  },
};

export enum NewKycAnswer {
  // Sweden banks
  SE_SWEDBANK = "SE_SWEDBANK",
  SE_HB = "SE_HB",
  SE_NORDEA = "SE_NORDEA",
  SE_SEB = "SE_SEB",
  SE_LF = "SE_LF",
  SE_SKANDIA = "SE_SKANDIA",
  SE_DB = "SE_DB",
  SE_OTHER = "SE_OTHER",

  // Denmark banks
  DK_DB = "DK_DB",
  DK_JYSKE = "DK_JYSKE",
  DK_NORDEA = "DK_NORDEA",
  DK_NYKREDIT = "DK_NYKREDIT",
  DK_SYDBANK = "DK_SYDBANK",
  DK_OTHER = "DK_OTHER",

  // Finland banks
  FI_OP = "FI_OP",
  FI_NORDEA = "FI_NORDEA",
  FI_DB = "FI_DB",
  FI_HB = "FI_HB",
  FI_SP = "FI_SP",
  FI_OTHER = "FI_OTHER",

  // German banks
  DE_DB = "DE_DB",
  DE_DZ = "DE_DZ",
  DE_KFW = "DE_KFW",
  DE_COMMERZ = "DE_COMMERZ",
  DE_HYPO = "DE_HYPO",
  DE_OTHER = "DE_OTHER",

  // Sweden deposits PERSON
  SE_0_100K_SEK = "SE_0_100K_SEK",
  SE_100K_1M_SEK = "SE_100K_1M_SEK",
  SE_1M_5M_SEK = "SE_1M_5M_SEK",

  // Sweden deposits CORPORATION
  SE_0_5M_SEK = "SE_0_5M_SEK",

  // Sweden deposits PERSON & CORPORATION
  SE_5M_SEK = "SE_5M_SEK",

  // Denmark deposits PERSON
  DK_0_100K_DKK = "DK_0_100K_DKK",
  DK_100K_1M_DKK = "DK_100K_1M_DKK",
  DK_1M_5M_DKK = "DK_1M_5M_DKK",
  DK_5M_DKK = "DK_5M_DKK",

  // Finland deposits PERSON
  FI_0_10K_EUR = "FI_0_10K_EUR",
  FI_10K_100K_EUR = "FI_10K_100K_EUR",
  FI_100K_500K_EUR = "FI_100K_500K_EUR",
  FI_500K_EUR = "FI_500K_EUR",

  // German deposits PERSON
  DE_0_10K_EUR = "DE_0_10K_EUR",
  DE_10K_100K_EUR = "DE_10K_100K_EUR",
  DE_100K_500K_EUR = "DE_100K_500K_EUR",
  DE_500K_EUR = "DE_500K_EUR",

  // Employment
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  OTHER = "OTHER",

  // MoneyOrigin PERSON
  SAVINGS = "SAVINGS",
  INHERITANCE = "INHERITANCE",
  INCOME = "INCOME",

  // MoneyOrigin PERSON & CORPORATION
  PROPERTY_SALE = "PROPERTY_SALE",
  CORPORATION_SALE = "CORPORATION_SALE",
  TRADE = "TRADE",
  DIVIDEND = "DIVIDEND",

  // PurposeAccount
  FINANCIAL_SECURITY = "FINANCIAL_SECURITY",
  PENSION = "PENSION",
  CONSUMPTION = "CONSUMPTION",
  GIFT = "GIFT",

  // PurposeAccountCorporation
  SURPLUS_LIQUIDITY = "SURPLUS_LIQUIDITY",

  // PurposeAccount & PurposeAccountCorporation
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS",

  // DepositInterval & WithdrawalInterval
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",
}

export enum KycAnswer {
  // Employment
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  OTHER = "OTHER",

  // Money Origin
  SAVINGS = "SAVINGS",
  INHERITANCE = "INHERITANCE",
  INCOME = "INCOME",
  PROPERTY_SALE = "PROPERTY_SALE",
  CORPORATION_SALE = "CORPORATION_SALE",
  TRADE = "TRADE",
  DIVIDEND = "DIVIDEND",
  DONATION = "DONATION",
  DUES = "DUES",
  RETURN_ON_INVESTMENT = "RETURN_ON_INVESTMENT",

  // Purpose
  FINANCIAL_SECURITY = "FINANCIAL_SECURITY",
  PENSION = "PENSION",
  CONSUMPTION = "CONSUMPTION",
  GIFT = "GIFT",
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS",
  SURPLUS_LIQUIDITY = "SURPLUS_LIQUIDITY",

  // Deposit/Withdrawal Interval
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM",

  // Type Of Company
  ACTIVE_BUSINESS = "ACTIVE_BUSINESS",
  FINANCIAL_INSTITUTION = "FINANCIAL_INSTITUTION",
  LISTED_COMPANY = "LISTED_COMPANY",
  NEWLY_STARTED_COMPANY = "NEWLY_STARTED_COMPANY",
  CHARITY = "CHARITY",

  // Legal Person
  YES = "YES",
  NO = "NO",

  // Banks SE
  SE_SWEDBANK = "SE_SWEDBANK",
  SE_HB = "SE_HB",
  SE_NORDEA = "SE_NORDEA",
  SE_SEB = "SE_SEB",
  SE_LF = "SE_LF",
  SE_SKANDIA = "SE_SKANDIA",
  SE_DB = "SE_DB",
  SE_OTHER = "SE_OTHER",

  // Deposits SE
  SE_0_15K_SEK = "SE_0_15K_SEK",
  SE_15_50K_SEK = "SE_15_50K_SEK",
  SE_50_100K_SEK = "SE_50_100K_SEK",
  SE_100K_SEK = "SE_100K_SEK",

  SE_0_100K_SEK = "SE_0_100K_SEK",
  SE_100_500K_SEK = "SE_100_500K_SEK",
  SE_500_1M_SEK = "SE_500_1M_SEK",
  SE_500K_SEK = "SE_500K_SEK",

  SE_100K_1M_SEK = "SE_100K_1M_SEK",
  SE_1M_5M_SEK = "SE_1M_5M_SEK",
  SE_5M_SEK = "SE_5M_SEK",

  SE_0_5M_SEK = "SE_0_5M_SEK",
  SE_0_1M_SEK = "SE_0_1M_SEK",
  SE_1M_SEK = "SE_1M_SEK",

  // Deposits DK
  DK_0_100K_DKK = "DK_0_100K_DKK",
  DK_100K_1M_DKK = "DK_100K_1M_DKK",
  DK_1M_5M_DKK = "DK_1M_5M_DKK",
  DK_5M_DKK = "DK_5M_DKK",

  // Banks DK
  DK_DB = "DK_DB",
  DK_JYSKE = "DK_JYSKE",
  DK_NORDEA = "DK_NORDEA",
  DK_NYKREDIT = "DK_NYKREDIT",
  DK_SYDBANK = "DK_SYDBANK",
  DK_OTHER = "DK_OTHER",

  // Deposit FI
  FI_0_10K_EUR = "FI_0_10K_EUR",
  FI_10K_100K_EUR = "FI_10K_100K_EUR",
  FI_100K_500K_EUR = "FI_100K_500K_EUR",
  FI_500K_EUR = "FI_500K_EUR",

  // Banks FI
  FI_OP = "FI_OP",
  FI_NORDEA = "FI_NORDEA",
  FI_DB = "FI_DB",
  FI_HB = "FI_HB",
  FI_SP = "FI_SP",
  FI_OTHER = "FI_OTHER",
}

export enum KycQuestion {
  DEPOSIT_SIZE = "DEPOSIT_SIZE",
  DEPOSIT_YEARLY_VALUE = "DEPOSIT_YEARLY_VALUE",
  DEPOSIT_INTERVAL = "DEPOSIT_INTERVAL",
  WITHDRAWAL_INTERVAL = "WITHDRAWAL_INTERVAL",
  PURPOSE = "PURPOSE",
  EMPLOYMENT = "EMPLOYMENT",
  MONEY_ORIGIN = "MONEY_ORIGIN",
  MONEY_BANK_ORIGIN = "MONEY_BANK_ORIGIN",
  TYPE_OF_COMPANY = "TYPE_OF_COMPANY",
  LEGAL_PERSON = "LEGAL_PERSON",
  INITIAL_DEPOSIT = "INITIAL_DEPOSIT",
}

export function isDepositInterval(value: string): value is DepositInterval {
  return Object.values(DepositInterval).includes(value as DepositInterval);
}

export function isWithdrawalInterval(
  value: string
): value is WithdrawalInterval {
  return Object.values(WithdrawalInterval).includes(
    value as WithdrawalInterval
  );
}

export function isSavingsAccountDepositInterval(
  value: string
): value is SavingsAccountDepositInterval {
  return Object.values(SavingsAccountDepositInterval).includes(
    value as SavingsAccountDepositInterval
  );
}

export function isSavingsAccountWithdrawalInterval(
  value: string
): value is SavingsAccountWithdrawalInterval {
  return Object.values(SavingsAccountWithdrawalInterval).includes(
    value as SavingsAccountWithdrawalInterval
  );
}

export function isEmployment(value: string): value is Employment {
  return Object.values(Employment).includes(value as Employment);
}

export const isMoneyOrigin = <T extends LegalEntityType>(
  legalEntityType: T,
  value: string
): value is MoneyOrigin<T> => {
  return moneyOrigin[legalEntityType].includes(value as MoneyOrigin<T>);
};

export const isPurpose = <T extends AccountKycType>(
  accountKycType: T,
  value: string
): value is Purpose<T> => {
  return purpose[accountKycType].includes(value as Purpose<T>);
};

export const isCountryBank = <T extends LysaCountry>(
  country: T,
  value: string
): value is BankType<T> => {
  return banks[country].includes(value as BankType<T>);
};

export const isDeposit = <L extends LegalEntityType, T extends LysaCountry>(
  legalEntityType: L,
  country: T,
  value: string
): value is DepositType<L, T> => {
  return deposits[legalEntityType][country].includes(value as DepositType<T>);
};
