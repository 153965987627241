import React, { useContext, useRef } from "react";
import { HiddenInput } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { CrsContext } from "../../pages/updateCrsStory/context/CrsContext";
import { messages } from "./CrsReportingCountryValidationMessages";
import { BooleanValidator } from "../validators/BooleanValidator";

export const CrsReportingCountryValidation = () => {
  const intl = useIntl();
  const crsContext = useContext(CrsContext).state.crsInformation;
  const validatorRef = useRef<HTMLDivElement>(null);

  const crsResidenceIsAlsoTaxReportingCountry =
    crsContext?.countries?.some((reportingCountry) => {
      return reportingCountry.country === crsContext.residentCountry;
    }) === true;

  return (
    <div ref={validatorRef}>
      <HiddenInput
        label={intl.formatMessage(messages.needToAddReportingCountryWarning)}
        value={crsResidenceIsAlsoTaxReportingCountry}
        validators={[
          new BooleanValidator(
            intl.formatMessage(messages.needToAddReportingCountryWarning)
          ),
        ]}
        scrollToRef={validatorRef}
      />
    </div>
  );
};
