import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { useAccountsContext } from "../../../context/AccountsContext";
import {
  CloseAccountState,
  CLOSE_LYSA_CUSTOMER_URL,
} from "../../closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import { SuitabilityAssessmentReturnState } from "../AccessGuardRoute";
import { useEligibilityContext } from "../../../context/EligibilityContext";
import { useReviewAccountContext } from "../../reviewAccount/ReviewAccountContext";
import { useSuitabilityAssessmentContext } from "../contexts/SuitabilityAssessmentContext";

export const useNavigateToCloseAccount = () => {
  const safeNavigation = useSafeNavigation();
  const history = useHistory<SuitabilityAssessmentReturnState | undefined>();
  const [accountsState] = useAccountsContext();
  const [eligibilityState] = useEligibilityContext();
  const [reviewAccountState] = useReviewAccountContext();
  const [suitabilityAssessmentState] = useSuitabilityAssessmentContext();

  const navigateToCloseAccount = useCallback(() => {
    const state: SuitabilityAssessmentReturnState = {
      accountsState,
      eligibilityState,
      reviewAccountState,
      suitabilityAssessmentState,
    };

    history.replace({
      pathname: history.location.pathname,
      state,
    });

    // Replace current history state with saved contexts so if user uses browser back it doesn't crash
    const returnState: CloseAccountState<SuitabilityAssessmentReturnState> = {
      // scenario: CloseCustomerScenario.<this>, //TODO: Add scenario maybe?
      returnUrl: history.location.pathname,
      returnState: state,
    };
    safeNavigation({
      pathname: getNavLink(CLOSE_LYSA_CUSTOMER_URL),
      state: returnState,
    });
  }, [
    accountsState,
    eligibilityState,
    history,
    reviewAccountState,
    suitabilityAssessmentState,
    safeNavigation,
  ]);

  return navigateToCloseAccount;
};
