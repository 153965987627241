import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  VoidFunctionComponent,
} from "react";
import {
  Alternative,
  Card,
  Datepicker,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RadioGroup,
  RequiredValidator,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { WithdrawalPeriodicContext } from "../WithdrawalPeriodicContext";
import { DateTime } from "luxon";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { WITHDRAWALS_PERIODIC_STORY_URL } from "../WithdrawalPeriodicStory";
import { PERIODIC_TYPE } from "../../../../data/dataWithdrawals";
import "./AmountPeriodConfiguration.scss";
import { PeriodicWithdrawalExamples } from "./periodicWithdrawalExamples/PeriodicWithdrawalExamples";
import { LysaCountry } from "@lysaab/countries";

const messages = defineMessages({
  alternativeAmount: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.type.amount",
  },
  alternativePercent: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.type.percent",
  },
  alternativeTargetDate: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.type.targetDate",
  },
  alternativeHeader: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.type.header",
  },
  alternativeRequired: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.type.required",
  },
  paymentDateLabel: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.paymentDate.label",
  },
  paymentDatePlaceholder: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.paymentDate.placeholder",
  },
  paymentDateErrorRequired: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.paymentDate.error.required",
  },
  paymentDateText: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.paymentDate.text",
  },
  amountLabel: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.label",
  },
  amountPlaceholder: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.placeholder",
  },
  amountErrorMin: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.error.min",
  },
  amountErrorMax: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.error.max",
  },
  amountErrorMoreThanWorth: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.error.moreThanWorth",
  },
  amountErrorRequired: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.amount.error.required",
  },
  percentLabel: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.percent.label",
  },
  percentPlaceholder: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.percent.placeholder",
  },
  percentErrorRequired: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.percent.error.required",
  },
  dateLabel: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.date.label",
  },
  datePlaceholder: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.date.placeholder",
  },
  dateErrorRequired: {
    id: "withdrawalPeriodic.amountPeriodConfiguration.date.error.required",
  },
});

interface Props {
  next: () => void;
}

export const paymentDateAlternatives: Alternative<number>[] = Array.from(
  Array(31).keys()
).map((index) => ({
  text: (index + 1).toString(),
  value: index + 1,
}));

const MIN_AMOUNTS: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 1000,
  [LysaCountry.FINLAND]: 100,
  [LysaCountry.GERMANY]: 100,
  [LysaCountry.SPAIN]: 100,
  [LysaCountry.SWEDEN]: 1000,
};

const MAX_AMOUNTS: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 300000,
  [LysaCountry.FINLAND]: 30000,
  [LysaCountry.GERMANY]: 30000,
  [LysaCountry.SPAIN]: 30000,
  [LysaCountry.SWEDEN]: 300000,
};

export const AmountPeriodConfiguration: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const safeNavigation = useSafeNavigation();
  const withdrawalPeriodicContext = useContext(WithdrawalPeriodicContext);
  const localizationContext = useContext(LocalizationContext);

  const percentAlternatives: Alternative<number>[] = Array.from(
    Array(10).keys()
  ).map((index) => ({
    text: intl.formatNumber((index + 1) / 100, { style: "percent" }),
    value: index + 1,
  }));

  const alternatives: Alternative<PERIODIC_TYPE>[] = [
    {
      text: intl.formatMessage(messages.alternativeAmount),
      value: PERIODIC_TYPE.FIXED_AMOUNT,
    },
    {
      text: intl.formatMessage(messages.alternativePercent),
      value: PERIODIC_TYPE.PERCENTAGE,
    },
    {
      text: intl.formatMessage(messages.alternativeTargetDate),
      value: PERIODIC_TYPE.TARGET_DATE,
    },
  ];

  const onSubmit = useCallback(() => {
    if (!formRef.current?.isValid) {
      return;
    }
    next();
  }, [next]);

  useEffect(() => {
    if (
      !withdrawalPeriodicContext.state.lysaAccount ||
      !withdrawalPeriodicContext.state.externalAccount
    ) {
      safeNavigation(getNavLink(WITHDRAWALS_PERIODIC_STORY_URL));
    }
  }, [safeNavigation, withdrawalPeriodicContext]);

  const minDate = useMemo(() => {
    let minDate = DateTime.now()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .plus({ months: 6 });

    /**
     * If the number returned from daysInMonth is less then today (for example 31), another day
     * needs to be added to minDate. For example if today is august the 31th, the
     * minDate will be february the 28th, and therefore not 6 months.
     */
    if (minDate.get("daysInMonth") < DateTime.now().get("day")) {
      minDate = minDate.plus({
        days: 1,
      });
    }

    return minDate;
  }, []);

  useEffect(() => {
    if (!withdrawalPeriodicContext.state.TARGET_DATE) {
      return;
    }
    const lastPayment = DateTime.fromJSDate(
      withdrawalPeriodicContext.state.TARGET_DATE
    );
    if (
      minDate.get("month") !== lastPayment.get("month") &&
      lastPayment.diff(minDate, "months").months < 0
    ) {
      withdrawalPeriodicContext.setState({
        TARGET_DATE: DateTime.fromJSDate(
          withdrawalPeriodicContext.state.TARGET_DATE
        )
          .plus({ months: 1 })
          .toJSDate(),
      });
    } else if (
      lastPayment.get("daysInMonth") !== lastPayment.get("day") &&
      lastPayment < minDate
    ) {
      withdrawalPeriodicContext.setState({
        TARGET_DATE: DateTime.fromJSDate(
          withdrawalPeriodicContext.state.TARGET_DATE
        )
          .set({
            day:
              lastPayment.get("daysInMonth") <
              withdrawalPeriodicContext.state.paymentDay.value
                ? lastPayment.get("daysInMonth")
                : minDate.get("day"),
          })
          .toJSDate(),
      });
    }
  }, [
    minDate,
    withdrawalPeriodicContext,
    withdrawalPeriodicContext.state.TARGET_DATE,
    withdrawalPeriodicContext.state.paymentDay.value,
  ]);

  if (
    !withdrawalPeriodicContext.state.lysaAccount ||
    !withdrawalPeriodicContext.state.externalAccount ||
    !localizationContext.state.country
  ) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={onSubmit}
      className="amount-period-configuration"
    >
      <h2>
        <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.header" />
      </h2>
      <Card>
        <RadioGroup
          alternatives={alternatives}
          value={withdrawalPeriodicContext.state.paymentMethod}
          header={intl.formatMessage(messages.alternativeHeader)}
          onChange={(paymentMethod) =>
            withdrawalPeriodicContext.setState({ paymentMethod })
          }
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.alternativeRequired)
            ),
          ]}
        />

        {withdrawalPeriodicContext.state.paymentMethod && (
          <>
            <Snackbar type={SNACKBAR_TYPES.INFO} icon>
              <>
                {withdrawalPeriodicContext.state.paymentMethod.value ===
                  PERIODIC_TYPE.FIXED_AMOUNT && (
                  <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.type.info.amount" />
                )}
                {withdrawalPeriodicContext.state.paymentMethod.value ===
                  PERIODIC_TYPE.PERCENTAGE && (
                  <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.type.info.percent" />
                )}
                {withdrawalPeriodicContext.state.paymentMethod.value ===
                  PERIODIC_TYPE.TARGET_DATE && (
                  <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.type.info.targetDate" />
                )}
              </>
            </Snackbar>

            <Select
              label={intl.formatMessage(messages.paymentDateLabel)}
              placeholder={intl.formatMessage(messages.paymentDatePlaceholder)}
              alternatives={paymentDateAlternatives}
              value={withdrawalPeriodicContext.state.paymentDay}
              onChange={(paymentDay) =>
                withdrawalPeriodicContext.setState({ paymentDay })
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.paymentDateErrorRequired)
                ),
              ]}
            />

            <Snackbar type={SNACKBAR_TYPES.INFO} icon>
              <FormattedMessage
                id="withdrawalPeriodic.amountPeriodConfiguration.paymentDate.description"
                values={{
                  date: withdrawalPeriodicContext.state.paymentDay.value,
                }}
              />
            </Snackbar>

            {withdrawalPeriodicContext.state.paymentMethod.value ===
              PERIODIC_TYPE.FIXED_AMOUNT && (
              <MoneyInput
                decimalScale={0}
                label={intl.formatMessage(messages.amountLabel)}
                placeholder={intl.formatMessage(messages.amountPlaceholder)}
                value={withdrawalPeriodicContext.state.FIXED_AMOUNT}
                onChange={(FIXED_AMOUNT) =>
                  withdrawalPeriodicContext.setState({ FIXED_AMOUNT })
                }
                currency={localizationContext.state.currency}
                validators={[
                  new MinValidator(
                    MIN_AMOUNTS[localizationContext.state.country],
                    intl.formatMessage(messages.amountErrorMin, {
                      amount: intl.formatNumber(
                        MIN_AMOUNTS[localizationContext.state.country],
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    })
                  ),
                  new MaxValidator(
                    MAX_AMOUNTS[localizationContext.state.country],
                    intl.formatMessage(messages.amountErrorMax, {
                      amount: intl.formatNumber(
                        MAX_AMOUNTS[localizationContext.state.country],
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    })
                  ),
                  new MaxValidator(
                    withdrawalPeriodicContext.state.lysaAccount.worth,
                    intl.formatMessage(messages.amountErrorMoreThanWorth)
                  ),
                  new RequiredValidator(
                    intl.formatMessage(messages.amountErrorRequired)
                  ),
                ]}
              />
            )}

            {withdrawalPeriodicContext.state.paymentMethod.value ===
              PERIODIC_TYPE.PERCENTAGE && (
              <Select
                label={intl.formatMessage(messages.percentLabel)}
                placeholder={intl.formatMessage(messages.percentPlaceholder)}
                alternatives={percentAlternatives}
                value={withdrawalPeriodicContext.state.PERCENTAGE}
                onChange={(PERCENTAGE) =>
                  withdrawalPeriodicContext.setState({ PERCENTAGE })
                }
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.percentErrorRequired)
                  ),
                ]}
              />
            )}

            {withdrawalPeriodicContext.state.paymentMethod.value ===
              PERIODIC_TYPE.TARGET_DATE && (
              <Datepicker
                label={intl.formatMessage(messages.dateLabel)}
                placeholderText={intl.formatMessage(messages.datePlaceholder)}
                selected={withdrawalPeriodicContext.state.TARGET_DATE}
                minDate={minDate.toJSDate()}
                onChange={(TARGET_DATE: Date) =>
                  withdrawalPeriodicContext.setState({ TARGET_DATE })
                }
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.amountErrorRequired)
                  ),
                ]}
              />
            )}
          </>
        )}
      </Card>
      <PeriodicWithdrawalExamples />
      <Button
        type="submit"
        block
        label={
          <FormattedMessage id="withdrawalPeriodic.amountPeriodConfiguration.next" />
        }
      />
    </Form>
  );
};
