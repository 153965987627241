import React, { useRef, useState, VoidFunctionComponent } from "react";
import { dataKyc, KycQuestion } from "../../../../data/dataKyc";
import { Form, LysaFormRef, Button, Spinner } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import {
  mapCustomerKycToExternal,
  useCustomerKyc,
} from "../../../../hooks/useCustomerKyc";
import { BankQuestion } from "../../../../components/kyc/customer/BankQuestion";
import { DepositsQuestionPerson } from "../../../../components/kyc/customer/person/DepositsQuestionPerson";
import { EmploymentQuestion } from "../../../../components/kyc/customer/person/EmploymentQuestion";
import { MoneyOriginQuestion } from "../../../../components/kyc/customer/person/MoneyOriginQuestionPerson";
import { LegalEntityType } from "../../../../data/dataLogin";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
}

export const KycPerson: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const [loading, setLoading] = useState(false);
  const [kyc, setKyc] = useCustomerKyc(LegalEntityType.PERSON);

  if (!kyc || loading) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid && kyc) {
          setLoading(true);

          dataKyc
            .saveCustomerKyc(
              mapCustomerKycToExternal(LegalEntityType.PERSON, kyc)
            )
            .then(() => {
              EventTracker.track({
                event: TrackerEvent.YEARLY_REVIEW_CUSTOMER_KYC,
              });
              next();
            })
            .finally(() => setLoading(false));
        }
      }}
    >
      <h2>
        <FormattedMessage id="yearly.kyc.person.header" />
      </h2>
      <p>
        <FormattedMessage id="yearly.kyc.person.description" />
      </p>
      <div>
        <EmploymentQuestion
          employment={kyc[KycQuestion.EMPLOYMENT]}
          setEmployment={(value) => setKyc({ [KycQuestion.EMPLOYMENT]: value })}
        />
        <DepositsQuestionPerson
          deposit={kyc[KycQuestion.DEPOSIT_YEARLY_VALUE]}
          setDeposit={(value) =>
            setKyc({ [KycQuestion.DEPOSIT_YEARLY_VALUE]: value })
          }
        />
        <MoneyOriginQuestion
          moneyOrigin={kyc[KycQuestion.MONEY_ORIGIN]}
          setMoneyOrigin={(value) =>
            setKyc({ [KycQuestion.MONEY_ORIGIN]: value })
          }
        />
        <BankQuestion
          banks={kyc[KycQuestion.MONEY_BANK_ORIGIN]}
          setBanks={(value) =>
            setKyc({ [KycQuestion.MONEY_BANK_ORIGIN]: value })
          }
        />
        <Button
          type="submit"
          block
          label={<FormattedMessage id="yearly.kyc.person.next" />}
        />
      </div>
    </Form>
  );
};
