import React, { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Alternative,
  Card,
  CheckboxGroup,
  RequiredValidator,
} from "@lysaab/ui-2";
import { SavingsAccountPurpose } from "../../../data/dataKyc";
import { DataDisplay } from "../../dataDisplay/DataDisplay";

export const messages = defineMessages({
  header: {
    id: "kyc.savingsAccountPurpose.header.savingsAccount",
  },
  required: {
    id: "kyc.savingsAccountPurpose.required",
  },
});

interface MessageWithId {
  id: string;
}

export const purposeMessages = defineMessages<
  SavingsAccountPurpose,
  MessageWithId,
  Record<SavingsAccountPurpose, MessageWithId>
>({
  [SavingsAccountPurpose.FINANCIAL_SECURITY]: {
    id: "kyc.savingsAccountPurpose.alt.FINANCIAL_SECURITY",
  },
  [SavingsAccountPurpose.CONSUMPTION]: {
    id: "kyc.savingsAccountPurpose.alt.CONSUMPTION",
  },
  [SavingsAccountPurpose.GIFT]: {
    id: "kyc.savingsAccountPurpose.alt.GIFT",
  },
});

interface Props {
  value?: SavingsAccountPurpose[];
  setValue: (purpose: SavingsAccountPurpose[]) => void;
  display?: boolean;
}

export const SavingsAccountPurposeQuestionAccount: VoidFunctionComponent<Props> =
  ({ value, setValue, display }) => {
    const intl = useIntl();

    const alternatives = Object.values(SavingsAccountPurpose).map((purpose) => {
      return {
        text: intl.formatMessage(purposeMessages[purpose]),
        value: purpose,
      } as Alternative<SavingsAccountPurpose>;
    });
    const valueAlternatives = alternatives.filter((alt) =>
      (value ?? []).includes(alt.value)
    );

    if (display) {
      return (
        <DataDisplay
          hasContainer
          type="input"
          title={intl.formatMessage(messages.header)}
          text={valueAlternatives.map((value) => value.text).join(", ")}
        />
      );
    }

    return (
      <Card>
        <CheckboxGroup
          alternatives={alternatives}
          values={valueAlternatives}
          onChange={(purposes) =>
            setValue(purposes.map((purpose) => purpose.value))
          }
          header={intl.formatMessage(messages.header)}
          validators={[
            new RequiredValidator(intl.formatMessage(messages.required)),
          ]}
        />
      </Card>
    );
  };
