import {
  CountrySelect,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  TextInput,
  MaxLengthValidator,
} from "@lysaab/ui-2";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import { CrsResponse } from "../../data/dataCrs";
import { CrsReportingCountryValidation } from "../crsReportingCountryValidation/CrsReportingCountryValidation";
import { TaxResidenceList } from "../taxResidenceList/TaxResidenceList";
import { TranslatedText } from "../TranslatedText";
import { DisallowedCountry, UsaValidator } from "../validators/UsaValidator";

import { messages } from "./CrsFormFieldsMessages";

interface Props {
  data: CrsResponse;
  setData: ({ crsInformation }: { crsInformation: CrsResponse }) => void;
}

export const CrsFormFields = ({ data, setData }: Props) => {
  const intl = useIntl();
  const language = useContext(LocalizationContext).state.language;
  return (
    <React.Fragment>
      <TaxResidenceList
        data={data.countries}
        setData={(countries) =>
          setData({ crsInformation: { ...data, countries } })
        }
      />
      <CrsReportingCountryValidation />
      <CountrySelect
        label={intl.formatMessage(messages.residenceCountryLabel)}
        placeholder={intl.formatMessage(messages.residenceCountryPlaceholder)}
        value={data.residentCountry}
        onChange={(alternative) => {
          const currentCrs = {
            ...data,
          };
          currentCrs.residentCountry = alternative.value;
          setData({ crsInformation: currentCrs });
        }}
        validators={[
          new RequiredValidator(
            intl.formatMessage(messages.residenceCountryRequired)
          ),
          new UsaValidator(" "),
        ]}
        language={language}
      />
      {data.residentCountry === DisallowedCountry.UNITED_STATES_OF_AMERICA && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="crsFormFields.residentCountry.usa" />
        </Snackbar>
      )}
      <TextInput
        label={intl.formatMessage(messages.streetLabel)}
        value={data.street}
        onChange={(value) => {
          const currentCrs = {
            ...data,
          } as CrsResponse;
          currentCrs.street = value;
          setData({ crsInformation: currentCrs });
        }}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.streetRequired)),
          new MaxLengthValidator(
            300,
            intl.formatMessage(messages.streetLength)
          ),
        ]}
      />
      <TextInput
        label={intl.formatMessage(messages.postCodeLabel)}
        value={data.zipCode}
        onChange={(value) => {
          const currentCrs = {
            ...data,
          } as CrsResponse;
          currentCrs.zipCode = value;
          setData({ crsInformation: currentCrs });
        }}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.postCodeRequired)),
        ]}
      />
      <TextInput
        label={intl.formatMessage(messages.cityLabel)}
        value={data.city}
        onChange={(value) => {
          const currentCrs = {
            ...data,
          } as CrsResponse;
          currentCrs.city = value;
          setData({ crsInformation: currentCrs });
        }}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.cityRequired)),
          new MaxLengthValidator(300, intl.formatMessage(messages.cityLength)),
        ]}
      />
    </React.Fragment>
  );
};
