import React from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { RiskImportance } from "../../../data/dataInvestments";
import { messagesRiskImportance } from "./messages";

interface Props {
  value?: RiskImportance;
  setValue: (newValue: RiskImportance) => void;
  messages: {
    header: { id: string };
    required: { id: string };
  };
}

export const Important: React.VFC<Props> = ({ value, setValue, messages }) => {
  const intl = useIntl();

  return (
    <Card>
      <RadioGroup
        alternatives={Object.values(RiskImportance)
          .filter((val) => typeof val === "number")
          .map((value) => {
            return {
              text: intl.formatMessage(
                messagesRiskImportance[Number(value) as RiskImportance]
              ),
              value: Number(value),
            };
          })}
        header={intl.formatMessage(messages.header)}
        onChange={(riskImportance) => setValue(riskImportance.value)}
        value={
          typeof value !== "undefined"
            ? {
                text: intl.formatMessage(
                  messagesRiskImportance[value as RiskImportance]
                ),
                value: value,
              }
            : undefined
        }
        validators={[
          new RequiredValidator(intl.formatMessage(messages.required)),
        ]}
      />
    </Card>
  );
};
