import {
  Button,
  Card,
  NewIcon,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  dataSavingsAccount,
  NewAccountRequest,
  CreationId,
} from "../../../../../../data/dataSavingsAccount";
import { CreateSavingsAccountContext } from "../CreateSavingsAccountContext";
import { DocumentsCard } from "./components/documentsCard/DocumentsCard";
import { useIsReadOnly } from "../../../../../../hooks/useIsReadOnly";

import "./OpenAccount.scss";

interface Props {
  next: () => void;
}

const POLL_INTERVAL = 3000;

export const OpenAccount: VoidFunctionComponent<Props> = ({ next }) => {
  const creationId = useRef<CreationId>();
  const timeout = useRef<NodeJS.Timeout>();
  const createAccountContext = useContext(CreateSavingsAccountContext);
  const isReadOnly = useIsReadOnly();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const poll = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (!creationId.current) {
        return;
      }
      dataSavingsAccount
        .getNewAccountStatus(creationId.current)
        .then((resp) => {
          if (resp.isCompleted) {
            next();
          } else {
            poll();
          }
        })
        .catch(() => {
          setError(true);
          setIsLoading(false);
        });
    }, POLL_INTERVAL);
  }, [next]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="open-account">
      {error && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="sweden.createAccount.createSavingsAccount.openAccount.openAccount.error" />
        </Snackbar>
      )}
      <div className="open-account__icon-wrapper">
        <NewIcon.SavingsAccount size={100} />
      </div>
      <Typography type="h2">
        <TranslatedText id="sweden.createSavingsAccount.openAccount.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.createSavingsAccount.openAccount.ingress" />
      </Typography>
      <DocumentsCard />
      <Card>
        <Typography type="h3">
          <TranslatedText id="sweden.createSavingsAccount.openAccount.confirmHeader" />
        </Typography>
        <ul className="open-account__list">
          <Typography component="li" type="body">
            <TranslatedText id="sweden.createSavingsAccount.openAccount.documentListItem" />
          </Typography>
          <Typography component="li" type="body">
            <TranslatedText id="sweden.createSavingsAccount.openAccount.lysaPartners" />
          </Typography>
          <Typography component="li" type="body">
            <TranslatedText id="sweden.createSavingsAccount.openAccount.iAgree" />
          </Typography>
        </ul>
      </Card>
      <Button
        block
        onClick={() => {
          if (
            isReadOnly ||
            createAccountContext.state.accountName === "undefined" ||
            typeof createAccountContext.state.purpose === "undefined" ||
            typeof createAccountContext.state.depositInterval === "undefined" ||
            typeof createAccountContext.state.withdrawalInterval === "undefined"
          ) {
            return;
          }
          const data: NewAccountRequest = {
            accountName: createAccountContext.state.accountName,
            kyc: {
              accountKyc: {
                version: "3",
                questionAnswers: [
                  {
                    question: "PURPOSE",
                    answers: createAccountContext.state.purpose,
                  },
                  {
                    question: "DEPOSIT_INTERVAL",
                    answers: [createAccountContext.state.depositInterval],
                  },
                  {
                    question: "WITHDRAWAL_INTERVAL",
                    answers: [createAccountContext.state.withdrawalInterval],
                  },
                ],
              },
            },
          };
          setIsLoading(true);
          dataSavingsAccount
            .addNewAccount(data)
            .then((resp) => {
              creationId.current = resp.creationId;
              poll();
            })
            .catch(() => {
              setError(true);
              setIsLoading(false);
            });
        }}
        label={
          <TranslatedText id="sweden.createSavingsAccount.openAccount.button.next" />
        }
      />
    </div>
  );
};
