import React, { useContext, useEffect } from "react";
import { useLocation, useHistory, matchPath, Redirect } from "react-router-dom";
import { Story } from "@lysaab/ui-2";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { KFAccountContextProvider, KFAccountContext } from "./KFAccountContext";
import { Intro } from "./intro/Intro";
import { Horizon } from "./horizon/Horizon";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Advise } from "./advise/Advise";
import { Candidate } from "./candidate/Candidate";
import { FuturKyc } from "./futurKyc/FuturKyc";
import { LysaSummary } from "./lysaSummary/LysaSummary";
import { Done } from "./done/Done";
import { LysaKyc } from "./lysaKyc/LysaKyc";
import { UserContext } from "../../../../../context/UserContext";
import { EditAllocation } from "./editAllocation/EditAllocation";
import { Warning } from "./warning/Warning";
import { LysaSign } from "./lysaSign/LysaSign";
import { FuturSummary } from "./futurSummary/FuturSummary";
import { FuturSign } from "./futurSign/FuturSign";
import { LayoutContext } from "../../../../../context/LayoutContext";
import { LegalEntityType } from "../../../../../data/dataLogin";
import "./CreateAccountCorporationPage.scss";
import { Route } from "../../../../../components/route/Route";
import { Switch } from "../../../../../components/route/Switch";
import { defineMessage, useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/countries";
import {
  getAccountQuestions,
  isSustainabilityImportantSpecific,
  SustainabilityImportance,
} from "../../../../../data/dataInvestments";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { Sustainability } from "./kfAccount/sustainability/Sustainability";
import { SustainabilityImportantQuestions } from "./kfAccount/sustainabilityImportantQuestions/SustainabilityImportantQuestions";
import { Preference } from "./kfAccount/preference/Preference";
import {
  dataCreateAccount,
  isValidSuitabilityAssessmentRequest,
  SuitabilityAssessmentRequest,
} from "../../../../../data/dataCreateAccount";
import { ConfirmEsgUpdateWrapper } from "./confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { PageStripped } from "../../../../../pages/PageStripped";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { SuitabilityDownloadLazy } from "../../../../../pageComponents/advise/SuitabilityDownload";

interface LocationStatePrevPath {
  prevPath?: string;
}

export const CREATE_ACCOUNT_CORPORATION_PAGE_URL =
  "/create-account/corporation";

const PREFIX = `/${LysaCountry.SWEDEN.toLocaleLowerCase()}${CREATE_ACCOUNT_CORPORATION_PAGE_URL}`;

export const ORDER_REF_IDENTIFIER = ":orderRef";

export const ROUTES = {
  INTRO: `${PREFIX}/`,
  LYSA_KYC: `${PREFIX}/lysa-kyc`,
  HORIZON: `${PREFIX}/horizon`,
  SUSTAINABILITY: `${PREFIX}/sustainability`,
  PREFERENCE: `${PREFIX}/preference`,
  SUSTAINABILITY_QUESTIONS: `${PREFIX}/sustainability-questions`,
  CONFIRM_ESG_UPDATE: `${PREFIX}/confirm-esg-update`,
  ADVICE: `${PREFIX}/advice`,
  EDIT_ALLOCATION: `${PREFIX}/edit-allocation`,
  WARNING: `${PREFIX}/warning`,
  CANDIDATE: `${PREFIX}/candidate`,
  FUTUR_KYC: `${PREFIX}/futur-kyc`,
  LYSA_SUMMARY: `${PREFIX}/lysa/summary`,
  LYSA_SIGN: `${PREFIX}/lysa/sign/:signingId/${ORDER_REF_IDENTIFIER}`,
  FUTUR_SUMMARY: `${PREFIX}/futur/summary/:signingId`,
  FUTUR_SIGN: `${PREFIX}/futur/sign/:signingId/:userId`,
  DONE: `${PREFIX}/done`,
};

const privateRoutesIndex = Object.values(ROUTES).findIndex(
  (path) => path === ROUTES.FUTUR_SUMMARY
);

const ariaProgressLabel = defineMessage({
  id: "sweden.CreateAccountCorporationPage.story.ariaProgressLabel",
});

function CreateAccountCorporationPageInner() {
  const location = useLocation();
  const history = useHistory();
  const kfAccountContext = useContext(KFAccountContext);
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  const storyLength = Object.values(ROUTES).length;

  const currentIndex = Object.values(ROUTES).findIndex((path) => {
    const match = matchPath(location.pathname, {
      path,
      exact: true,
    });
    return match !== null;
  });

  const userContext = useContext(UserContext);
  const user = userContext.state;

  if (currentIndex < privateRoutesIndex && !user?.name) {
    return (
      <Redirect
        to={{
          pathname: getNavLink(OVERVIEW_PAGE_URL),
        }}
      />
    );
  }

  const exitRoute =
    (location.state as LocationStatePrevPath)?.prevPath ||
    getNavLink(OVERVIEW_PAGE_URL);

  return (
    <PageStripped className="create-account-corporation">
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header="Öppna företagskonto"
        onExit={() => {
          history.push(exitRoute);
        }}
        showBack={
          !kfAccountContext.state.ongoing &&
          currentIndex > 0 &&
          currentIndex < Object.values(ROUTES).indexOf(ROUTES.LYSA_SIGN)
        }
        showClose={
          currentIndex !== Object.values(ROUTES).indexOf(ROUTES.LYSA_SIGN)
        }
        onBack={history.goBack}
        transitionKey={currentIndex.toString()}
        progress={(100 / storyLength) * (currentIndex + 1)}
      >
        <Switch {...{ order: currentIndex }} location={location}>
          <Route path={ROUTES.INTRO} exact>
            <Intro
              lysaKycRoute={ROUTES.LYSA_KYC}
              lysaSummaryRoute={ROUTES.LYSA_SUMMARY}
              futurSummaryRoute={ROUTES.FUTUR_SUMMARY}
              exitRoute={exitRoute}
            />
          </Route>
          <Route path={ROUTES.LYSA_KYC}>
            <LysaKyc horizonRoute={ROUTES.HORIZON} />
          </Route>
          <Route path={ROUTES.HORIZON}>
            <Horizon investmentTypeSelectionRoute={ROUTES.SUSTAINABILITY} />
          </Route>
          <Route exact path={ROUTES.SUSTAINABILITY}>
            <Sustainability
              next={() => {
                if (
                  kfAccountContext.state.sustainability ===
                  SustainabilityImportance.IMPORTANT
                ) {
                  safeNavigation(ROUTES.PREFERENCE);
                } else {
                  safeNavigation(ROUTES.ADVICE);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.PREFERENCE}>
            <Preference
              next={() => {
                if (isSustainabilityImportantSpecific(kfAccountContext.state)) {
                  safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
                } else {
                  safeNavigation(ROUTES.ADVICE);
                }
              }}
            />
          </Route>
          <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
            <SustainabilityImportantQuestions
              next={() => {
                const data: Partial<SuitabilityAssessmentRequest> = {
                  language: localizationContext.state.language,
                  ...getAccountQuestions(kfAccountContext.state),
                };

                if (!isValidSuitabilityAssessmentRequest(data)) {
                  throw new Error(
                    "kf account - CreateAccountCorporationPage - not valid data in isValidAccountQuestions"
                  );
                }

                dataCreateAccount
                  .getKFSuitabilityAssessment(data)
                  .then((advise) => {
                    if (advise.esgResult.esgBestMatch) {
                      safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                    } else {
                      safeNavigation(ROUTES.ADVICE);
                    }
                  });
              }}
            />
          </Route>
          <Route path={ROUTES.CONFIRM_ESG_UPDATE}>
            <ConfirmEsgUpdateWrapper
              next={() => {
                safeNavigation(ROUTES.ADVICE);
              }}
            />
          </Route>
          <Route path={ROUTES.ADVICE}>
            <Advise
              editAllocationRoute={ROUTES.EDIT_ALLOCATION}
              candidateRoute={ROUTES.CANDIDATE}
            />
          </Route>
          <Route path={ROUTES.EDIT_ALLOCATION}>
            <EditAllocation
              horizonRoute={ROUTES.HORIZON}
              warningRoute={ROUTES.WARNING}
              candidateRoute={ROUTES.CANDIDATE}
            />
          </Route>
          <Route path={ROUTES.WARNING}>
            <Warning
              candidateRoute={ROUTES.CANDIDATE}
              editAllocationRoute={ROUTES.EDIT_ALLOCATION}
            />
          </Route>
          <Route path={ROUTES.CANDIDATE}>
            <Candidate futurKycRoute={ROUTES.FUTUR_KYC} />
          </Route>
          <Route path={ROUTES.FUTUR_KYC}>
            <FuturKyc lysaSummaryRoute={ROUTES.LYSA_SUMMARY} />
          </Route>

          <Route path={ROUTES.LYSA_SUMMARY}>
            <LysaSummary lysaSignRoute={ROUTES.LYSA_SIGN} />
          </Route>
          <Route path={ROUTES.LYSA_SIGN}>
            <LysaSign futurSummaryRoute={ROUTES.FUTUR_SUMMARY} />
          </Route>
          <Route path={ROUTES.FUTUR_SUMMARY}>
            <FuturSummary
              futurSignRoute={ROUTES.FUTUR_SIGN}
              doneRoute={ROUTES.DONE}
            />
          </Route>
          <Route path={ROUTES.FUTUR_SIGN}>
            <FuturSign futurSummaryRoute={ROUTES.FUTUR_SUMMARY} />
          </Route>

          <Route path={ROUTES.DONE}>
            <Done />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
}

export function CreateAccountCorporationPage() {
  const layoutContext = useContext(LayoutContext);
  const { setState } = layoutContext;
  const userContext = useContext(UserContext);
  const user = userContext.state;

  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  useEffect(() => {
    setState({
      hideNav: true,
    });
    return () => {
      setState({
        hideNav: false,
      });
    };
  }, [setState]);

  if (user.legalEntityType === LegalEntityType.PERSON) {
    return <Redirect to={getNavLink(OVERVIEW_PAGE_URL)} />;
  }

  return (
    <KFAccountContextProvider>
      <CreateAccountCorporationPageInner />
    </KFAccountContextProvider>
  );
}
