import { LysaCountry } from "@lysaab/countries";
import { DateTime } from "luxon";
import { Currency } from "../context/LocalizationContext";
import { LegalEntityType } from "../data/dataLogin";
import { CloseCustomerReason } from "../pages/closeLysaCustomerAccountStory/closeLysaCustomerConfirmation/closeLysaCustomerReasons/CloseLysaCustomerReasons";

const uuidRegExp =
  /\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi;

export enum AnalyticsEvent {
  WITHDRAWAL = "WITHDRAWAL",
  CUSTOMER_CLOSE = "CUSTOMER_CLOSE",
  DEPOSIT_ERROR = "DEPOSIT_ERROR",
  BUTTON_EVENT = "BUTTON_EVENT",
  DEPOSIT = "DEPOSIT",
  MONTHLY_DEPOSIT = "MONTHLY_DEPOSIT",
  MENU_TRANSFERS = "MENU_TRANSFERS",
  MENU_PROFILE = "MENU_PROFILE",
  BURGER_MENU = "BURGER_MENU",
  BANNER = "BANNER",
}

interface WithdrawalEvent {
  event: AnalyticsEvent.WITHDRAWAL;
  amount: number;
  currency: Currency;
  isEmptying: boolean;
}

interface CustomerCloseEvent {
  event: AnalyticsEvent.CUSTOMER_CLOSE;
  reason: CloseCustomerReason | "No reason selected";
}

interface DepositErrorEvent {
  event: AnalyticsEvent.DEPOSIT_ERROR;
  depositType: string;
  error: string;
}

interface ButtonEvent {
  event:
    | AnalyticsEvent.BUTTON_EVENT
    | AnalyticsEvent.MENU_TRANSFERS
    | AnalyticsEvent.MENU_PROFILE
    | AnalyticsEvent.BANNER
    | AnalyticsEvent.BURGER_MENU;
  buttonName: string;
}

interface DepositEvent {
  event: AnalyticsEvent.DEPOSIT;
  depositType: string;
  amount: number;
  currency: Currency;
}

interface MonthlyDepositEvent {
  event: AnalyticsEvent.MONTHLY_DEPOSIT;
  depositType: string;
  amount: number;
  currency: Currency;
}

export type EventData =
  | WithdrawalEvent
  | CustomerCloseEvent
  | DepositErrorEvent
  | ButtonEvent
  | DepositEvent
  | MonthlyDepositEvent;

export enum Dimension {
  CUSTOMER_TYPE = "dimension1",
  COUNTRY = "dimension2",
  INVESTED = "dimension3",
  MONTHLY_SAVINGS = "dimension4",
}

export enum Metric {
  NUMBER_OF_ACCOUNTS = "metric1",
  ASSETS = "metric2",
  REGISTERED = "metric3",
}

interface DimensionCountry {
  dimension: Dimension.COUNTRY;
  value: LysaCountry;
}

interface DimensionType {
  dimension: Dimension.CUSTOMER_TYPE;
  value: LegalEntityType;
}

interface DimensionInvested {
  dimension: Dimension.INVESTED;
  value: Boolean;
}

interface DimensionMonthlySavings {
  dimension: Dimension.MONTHLY_SAVINGS;
  value: boolean;
}

type DimensionData =
  | DimensionCountry
  | DimensionType
  | DimensionInvested
  | DimensionMonthlySavings;

interface MetricNumberOfAccounts {
  metric: Metric.NUMBER_OF_ACCOUNTS;
  value: number;
}

interface MetricAssets {
  metric: Metric.ASSETS;
  value: number;
}

interface MetricRegistered {
  metric: Metric.REGISTERED;
  value: string;
}

type MetricData = MetricNumberOfAccounts | MetricAssets | MetricRegistered;

export class Tracker {
  static track(url: string) {
    if (typeof url !== "string") {
      return;
    }

    // Track page visis
    // eslint-disable-next-line
    const sanitizedUrl = url.replace(uuidRegExp, ""); // This is done to sanitize urls since they can contain both account and customer uuids
  }

  static event(data: EventData) {
    if (data.event === AnalyticsEvent.WITHDRAWAL) {
      // Track withdrawal
    } else if (data.event === AnalyticsEvent.CUSTOMER_CLOSE) {
      // Track customer close event and reason
    } else if (data.event === AnalyticsEvent.DEPOSIT_ERROR) {
    } else if (
      data.event === AnalyticsEvent.BUTTON_EVENT ||
      data.event === AnalyticsEvent.BANNER ||
      data.event === AnalyticsEvent.MENU_PROFILE ||
      data.event === AnalyticsEvent.MENU_TRANSFERS ||
      data.event === AnalyticsEvent.BURGER_MENU
    ) {
      // Track button event
    } else if (data.event === AnalyticsEvent.DEPOSIT) {
      // Track desposit event
    } else if (data.event === AnalyticsEvent.MONTHLY_DEPOSIT) {
      // Track monthly deposit event
    }
  }

  static setDimension(dimension: DimensionData) {
    // Track dimension
  }

  static setMetric(metric: MetricData) {
    if (metric.metric === Metric.ASSETS) {
      // Track assets
      // eslint-disable-next-line
      const assetRepresentation = getAssetRepresentation(metric.value); // This grou
    } else if (metric.metric === Metric.REGISTERED) {
      // Track registrations
      // eslint-disable-next-line
      const yearAndweek = getYearAndWeek(metric.value);
    } else {
      // Track generic metric
    }
  }
}

function getYearAndWeek(date: string) {
  return DateTime.fromISO(date).set({ weekday: 1 }).toMillis();
}

function getAssetRepresentation(assets: number) {
  if (assets === 0) {
    return 0;
  } else if (assets >= 1 && assets <= 10_000) {
    return 1;
  } else if (assets >= 10_001 && assets <= 50_000) {
    return 2;
  } else if (assets >= 50_001 && assets <= 100_000) {
    return 3;
  } else if (assets >= 100_001 && assets <= 500_000) {
    return 4;
  } else if (assets >= 500_001 && assets <= 1_000_000) {
    return 5;
  } else if (assets >= 1_000_001 && assets <= 2_000_000) {
    return 6;
  } else if (assets >= 2_000_001) {
    return 7;
  }
}
