import React from "react";
import { Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import { FormattedMessage, defineMessages } from "react-intl";
import "./Error.scss";

interface Props {
  retry?: () => void;
  text?: React.ReactNode | string;
  buttonText?: React.ReactNode | string;
}

const messages = defineMessages({
  ouch: {
    id: "retry.ouch",
  },
  text: {
    id: "retry.text",
  },
  button: {
    id: "retry.button",
  },
});

export const Error: React.FC<Props> = (props) => {
  const ouch = props.text ? null : (
    <b>
      <FormattedMessage {...messages.ouch} />
    </b>
  );
  const text = props.text || (
    <span>
      {ouch} <FormattedMessage {...messages.text} />
    </span>
  );
  const buttonText = props.buttonText || (
    <FormattedMessage {...messages.button} />
  );

  return (
    <div className="retry-error">
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        {text}
        {props.retry ? (
          <div>
            <Button
              variant="primary"
              size="small"
              onClick={props.retry}
              label={buttonText}
            />
          </div>
        ) : null}
      </Snackbar>
    </div>
  );
};
