import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { dataLogin, TwoFaType } from "../../data/dataLogin";
import "./ChangeTwoFaPage.scss";
import { Page, PageHeader } from "../Page";
import { TwoFaAppSection } from "./TwoFaAppSection";
import { TwoFaEmailSection } from "./TwoFaEmailSection";
import { UserContext } from "../../context/UserContext";

export const CHANGE_TWO_FA_PAGE_URL = "/profile/change-2fa";

export const ChangeTwoFaPage: React.VFC = () => {
  const userContext = useContext(UserContext);
  const [secret, setSecret] = useState("");
  const [available, setAvailable] = useState<TwoFaType[]>([]);

  useEffect(() => {
    dataLogin.changeTotp().then((resp) => {
      setSecret(resp.secret);
    });
    dataLogin.changeTotpAvailable().then((resp) => {
      setAvailable(resp.availableTwoFaType);
    });
  }, []);

  return (
    <Page className="change-twofa-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="change_twofa.page.header" />
        </h1>
      </PageHeader>

      {!userContext.state.tin && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <FormattedMessage
            id="change_twofa.error.no_tin"
            defaultMessage="Invalid state. Please re-login"
          />
        </Snackbar>
      )}

      {!secret ||
      !available ||
      available.length === 0 ||
      !userContext.state.tin ? (
        <Spinner />
      ) : (
        <div className="content-wrapper">
          {available.includes(TwoFaType.EMAIL) && <TwoFaEmailSection />}
          <TwoFaAppSection />
        </div>
      )}
    </Page>
  );
};
