import { Story } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, Switch, useHistory } from "react-router";
import { Route } from "../../../../../components/route/Route";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../hooks/useSafeNavigation";
import { useStoryValues } from "../../../../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../../../../../pages/overview/OverviewPage";
import { PageStripped } from "../../../../../pages/PageStripped";
import { Intro } from "./intro/Intro";
import { CreateSavingsAccountContextProvider } from "./CreateSavingsAccountContext";
import { Done } from "./done/Done";
import { Kyc } from "./kyc/Kyc";
import { UpdateCrs } from "../updateCrs/UpdateCrs";
import { CrsContextProvider } from "../../updateCrsStory/context/CrsContext";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../data/dataCustomerTracking";
import { OpenAccount } from "./openAccount/OpenAccount";
import { Info } from "./info/Info";

export const CREATE_SAVINGS_ACCOUNT_URL = "/create-savings-account";

export const SAVINGS_ACCOUNT_BASE_ROUTES = {
  INTRO: `${CREATE_SAVINGS_ACCOUNT_URL}/`,
  INFO: `${CREATE_SAVINGS_ACCOUNT_URL}/information`,
  KYC: `${CREATE_SAVINGS_ACCOUNT_URL}/kyc`,
  UPDATE_CRS: `${CREATE_SAVINGS_ACCOUNT_URL}/crs`,
  OPEN_ACCOUNT: `${CREATE_SAVINGS_ACCOUNT_URL}/open-account`,
  DONE: `${CREATE_SAVINGS_ACCOUNT_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "sweden.createAccount.createSavingsAccount.story.header",
  },
  accountName: {
    id: "sweden.createAccount.createSavingsAccount.story.accountName",
  },
  ariaProgressLabel: {
    id: "sweden.createAccount.createSavingsAccount.story.ariaProgressLabel",
  },
});

export const CreateSavingsAccountStory = () => {
  const [currentIndex, ROUTES, storyProgress, storyLength] = useStoryValues(
    SAVINGS_ACCOUNT_BASE_ROUTES
  );
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <PageStripped>
      <CreateSavingsAccountContextProvider
        accountName={intl.formatMessage(messages.accountName)}
      >
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 1
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }}
          onBack={() => onBack()}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.INTRO} exact>
              <Intro
                next={() => {
                  dataCustomerTrackingService.postEvent({
                    eventName: "clickedOpenSavingsAccountButton",
                    domain: FeatureDomain.SAVINGS_ACCOUNT,
                    subDomain: SubDomain.CREATE_ACCOUNT,
                    payload: {
                      buttonOption: "savingsAccountOnlySelection",
                    },
                  });
                  safeNavigation(ROUTES.INFO);
                }}
              />
            </Route>
            <Route path={ROUTES.INFO} exact>
              <Info
                next={() => {
                  dataCustomerTrackingService.postEvent({
                    eventName: "clickedInformationNextButton",
                    domain: FeatureDomain.SAVINGS_ACCOUNT,
                    subDomain: SubDomain.CREATE_ACCOUNT,
                  });
                  safeNavigation(ROUTES.KYC);
                }}
              />
            </Route>
            <Route path={ROUTES.KYC} exact>
              <Kyc
                next={() => {
                  dataCustomerTrackingService.postEvent({
                    domain: FeatureDomain.SAVINGS_ACCOUNT,
                    eventName: "clickedKycNextButton",
                    subDomain: SubDomain.CREATE_ACCOUNT,
                  });
                  safeNavigation(ROUTES.UPDATE_CRS);
                }}
              />
            </Route>
            <Route path={ROUTES.UPDATE_CRS} exact>
              <CrsContextProvider>
                <UpdateCrs
                  next={() => {
                    dataCustomerTrackingService.postEvent({
                      domain: FeatureDomain.SAVINGS_ACCOUNT,
                      eventName: "clickedCrsNextButton",
                      subDomain: SubDomain.CREATE_ACCOUNT,
                    });
                    safeNavigation(ROUTES.OPEN_ACCOUNT);
                  }}
                />
              </CrsContextProvider>
            </Route>
            <Route path={ROUTES.OPEN_ACCOUNT} exact>
              <OpenAccount
                next={() => {
                  dataCustomerTrackingService.postEvent({
                    domain: FeatureDomain.SAVINGS_ACCOUNT,
                    eventName: "clickedConfirmSavingsAccountButton",
                    subDomain: SubDomain.CREATE_ACCOUNT,
                  });
                  safeNavigation(ROUTES.DONE);
                }}
              />
            </Route>
            <Route path={ROUTES.DONE} exact>
              <Done />
            </Route>
          </Switch>
        </Story>
      </CreateSavingsAccountContextProvider>
    </PageStripped>
  );
};
