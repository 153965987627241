import { CardList, CardListItem, NewIcon, Typography } from "@lysaab/ui-2";
import React, { useContext } from "react";
import "./WithdrawalHelp.scss";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { ContactPhoneNumbers } from "../../../../../components/Contact";
import { LysaCountry } from "@lysaab/countries";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../../messages/MessagesPage";
import { TransparentButton } from "../../../../../components/transparentButton/TransparentButton";

enum HelpType {
  FAQ = "FAQ",
  MESSAGE = "MESSAGE",
  CALL = "CALL",
}

export const WithdrawalHelp = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const localPhoneNumber = intl.formatMessage(
    ContactPhoneNumbers[localizationContext.state.country || LysaCountry.SWEDEN]
  );

  return (
    <div className="withdrawal-help">
      <Typography type="h3">
        <TranslatedText id="withdrawal.helper-section.header" />
      </Typography>
      <CardList shadowStyle={false}>
        <div className="card-list-item-wrapper">
          <Link
            to={getNavLink(MESSAGES_PAGE_URL)}
            onClick={() => {
              plausibleTrack(HelpType.MESSAGE);
            }}
          >
            <CardListItem>
              <div className="left-section">
                <NewIcon.Message />
                <div className="text-wrapper">
                  <Typography type="label">
                    <TranslatedText id="withdrawal.helper-section.message.header" />
                  </Typography>
                  <Typography type="body" variant="secondary" component="span">
                    <TranslatedText id="withdrawal.helper-section.message.description" />
                  </Typography>
                </div>
              </div>
              <NewIcon.ChevronRight />
            </CardListItem>
          </Link>
        </div>
        <div className="card-list-item-wrapper">
          <a
            href={`tel:${localPhoneNumber}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              plausibleTrack(HelpType.CALL);
            }}
          >
            <CardListItem>
              <div className="left-section">
                <NewIcon.Phone />
                <div className="text-wrapper">
                  <Typography type="label">
                    <TranslatedText
                      id="withdrawal.helper-section.call.header"
                      values={{
                        phoneNumber: localPhoneNumber,
                      }}
                    />
                  </Typography>
                  <Typography type="body" variant="secondary" component="span">
                    <TranslatedText id="withdrawal.helper-section.call.description" />
                  </Typography>
                </div>
              </div>
              <NewIcon.ChevronRight />
            </CardListItem>
          </a>
        </div>
        <TransparentButton
          className="card-list-item-wrapper"
          onClick={() => {
            if (window._elev) {
              window._elev.openHome();
            }
            plausibleTrack(HelpType.FAQ);
          }}
        >
          <CardListItem>
            <div className="left-section">
              <NewIcon.HelpOutline />
              <div className="text-wrapper">
                <Typography type="label">
                  <TranslatedText id="withdrawal.helper-section.faq.header" />
                </Typography>
                <Typography type="body" variant="secondary" component="span">
                  <TranslatedText id="withdrawal.helper-section.faq.description" />
                </Typography>
              </div>
            </div>
            <NewIcon.ChevronRight />
          </CardListItem>
        </TransparentButton>
      </CardList>
    </div>
  );
};

const plausibleTrack = (helpType: HelpType) => {
  if (window.plausible) {
    window.plausible("WITHDRAWAL_HELP_SECTION", {
      props: {
        alternative: helpType,
      },
    });
  }
};
