import {
  ValidationResponse,
  VALIDATION_STATE,
  BaseValidator,
} from "@lysaab/ui-2";
import { dataWithdrawals } from "../../data/dataWithdrawals";

export class UniqueExternalAccountValidator extends BaseValidator {
  protected name = "UniqueExternalAccountValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise((resolve, reject) => {
      dataWithdrawals
        .getExternalAccounts()
        .then((externalAccounts) => {
          if (
            externalAccounts.find(
              (consent) => consent.externalBankAccount === value
            )
          ) {
            resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
          } else {
            resolve({ status: VALIDATION_STATE.SUCCESS });
          }
        })
        .catch((error) => reject(error));
    });
  }
}
