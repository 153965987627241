import React, { FunctionComponent, ReactNode } from "react";
import { Card, Button, InvestmentType as type, Icon } from "@lysaab/ui-2";
import { InvestmentType } from "./InvestmentType";
import "./InvestmentTypeCard.scss";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentTypeMessages";

interface Props {
  onClick: () => void;
  buttonText: string | ReactNode;
}

export const InvestmentTypeCardSustainable: FunctionComponent<Props> = ({
  onClick,
  buttonText,
}) => {
  const intl = useIntl();

  return (
    <Card className="investment-type-card">
      <div className="leaf investment-type-selection-bg">
        <Icon.Leaf />
      </div>
      <div className="investment-type-selection-fg">
        <div>
          <h3>
            <InvestmentType
              investmentType={type.SUSTAINABLE}
              capitalize={true}
            />
          </h3>
          <p>{intl.formatMessage(messages.investmentTypeSustainableDesc)}</p>
          <p>
            {intl.formatMessage(messages.investmentTypeSustainableAdditional)}
          </p>
        </div>

        <Button block onClick={onClick} label={buttonText} />
      </div>
    </Card>
  );
};
