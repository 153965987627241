import React from "react";
import { Router as SwedenRouter } from "./countries/sweden/Router";
import { generatePath, Route, Switch } from "react-router-dom";
import { Router as FinlandRouter } from "./countries/finland/Router";
import { Router as DenmarkRouter } from "./countries/denmark/Router";
import { Router as GermanyRouter } from "./countries/germany/Router";
import { Router as SpainRouter } from "./countries/spain/Router";
import {
  IMPERSONATION_PAGE_URL,
  ImpersonationPage,
} from "./pages/impersonation/ImpersionationPage";
import {
  CountrySelectPage,
  COUNTRY_SELECT_PAGE_URL,
} from "./pages/countrySelect/CountrySelectPage";
import { CountryRedirect } from "./CountryRedirect";
import { NetsDonePage, netsDoneLogin } from "@lysaab/lysa-nets";
import { LysaCountry } from "@lysaab/countries";

export const ROUTES = {
  sweden: "/se",
  denmark: "/dk",
  finland: "/fi",
  germany: "/de",
  spain: "/es",
};

export const NETS_DONE = "/:country(\\w\\w)/login/nets/done";

export const countryRoutesMap: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: ROUTES.sweden,
  [LysaCountry.DENMARK]: ROUTES.denmark,
  [LysaCountry.FINLAND]: ROUTES.finland,
  [LysaCountry.GERMANY]: ROUTES.germany,
  [LysaCountry.SPAIN]: ROUTES.spain,
};

export const TopLevelRouter = React.memo(() => {
  const netsDoneFiPath = generatePath(NETS_DONE, { country: "fi" });
  const netsDoneDkPath = generatePath(NETS_DONE, { country: "dk" });

  // The only reason this is a Record, and not an Array is to get a type
  // error when adding a new country, so we don't forget to update here
  const countryRouter: Record<
    LysaCountry,
    { children: React.ReactNode; path: string }
  > = {
    [LysaCountry.SWEDEN]: {
      path: ROUTES.sweden,
      children: <SwedenRouter />,
    },
    [LysaCountry.DENMARK]: {
      path: ROUTES.denmark,
      children: <DenmarkRouter netsDonePath={netsDoneDkPath} />,
    },
    [LysaCountry.FINLAND]: {
      path: ROUTES.finland,
      children: <FinlandRouter netsDonePath={netsDoneFiPath} />,
    },
    [LysaCountry.GERMANY]: {
      path: ROUTES.germany,
      children: <GermanyRouter />,
    },
    [LysaCountry.SPAIN]: {
      path: ROUTES.spain,
      children: <SpainRouter />,
    },
  };

  return (
    <Switch>
      <Route path={NETS_DONE}>
        <NetsDonePage netsDone={netsDoneLogin} />
      </Route>
      {Object.values(countryRouter).map(({ path, children }) => {
        return (
          <Route path={path} key={path}>
            <>{children}</>
          </Route>
        );
      })}
      <Route
        path={IMPERSONATION_PAGE_URL}
        exact
        component={ImpersonationPage}
      />
      <Route path="/" exact>
        <CountryRedirect />
      </Route>
      <Route
        path={COUNTRY_SELECT_PAGE_URL}
        component={CountrySelectPage}
      ></Route>
    </Switch>
  );
});
