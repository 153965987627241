import React, {
  useCallback,
  useContext,
  useEffect,
  VoidFunctionComponent,
} from "react";
import { Card, Button, Spinner } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { useHistory } from "react-router";
import { BASE_ROUTES, UserManagementBasePaths } from "../UserManagementStory";
import { UserManagementContext } from "../UserManagementContext";
import "./UserManagementDelete.scss";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";

interface Props {
  next: (action: UserManagementBasePaths) => void;
}

export const UserManagementDelete: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const history = useHistory();
  const userManagementContext = useContext(UserManagementContext);

  const remove = useCallback(() => {
    if (!userManagementContext.state.selectedUser) {
      return;
    }

    userManagementContext.setState({
      updatedUser: {
        email: userManagementContext.state.selectedUser.email,
        admin: userManagementContext.state.selectedUser.admin,
        identificationNumber:
          userManagementContext.state.selectedUser.identificationNumber,
      },
    });
    next(UserManagementBasePaths.DELETE);
  }, [next, userManagementContext]);

  useEffect(() => {
    if (!userManagementContext.state.selectedUser) {
      history.push(getNavLink(BASE_ROUTES.HOME));
    }
  }, [history, userManagementContext.state.selectedUser]);

  if (!userManagementContext.state.selectedUser) {
    return <Spinner />;
  }

  return (
    <GridRow>
      <GridCol xsmall={12}>
        <h3>
          <FormattedMessage id="sweden.userManagement.delete.title" />
        </h3>
      </GridCol>
      <GridCol xsmall={12}>
        <Card>
          <p>
            <FormattedMessage
              id="sweden.userManagement.delete.text"
              values={{
                userName: (
                  <b>{userManagementContext.state.selectedUser.name}</b>
                ),
              }}
            />
          </p>
        </Card>
      </GridCol>

      <GridCol xsmall={12}>
        <Button
          block
          onClick={remove}
          label={
            <FormattedMessage id="sweden.userManagement.delete.button.continue" />
          }
        />

        <Button
          variant="secondary"
          block
          onClick={() => history.goBack()}
          label={
            <FormattedMessage id="sweden.userManagement.delete.button.cancel" />
          }
        />
      </GridCol>
    </GridRow>
  );
};
