import React from "react";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { GridRow } from "../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../components/TranslatedText";
import { Page, PageHeader } from "../Page";
import { CitizenshipInformation } from "./citizenshipInformation/CitizenshipInformation";
import { CurrentCrsInformation } from "./currentCrsInformation/CurrentCrsInformation";

export const CRS_PAGE_URL = "/personal-details";

export const CrsPage = () => (
  <Page>
    <PageHeader>
      <h1>
        <TranslatedText id="crs.header" />
      </h1>
    </PageHeader>
    <GridRow>
      <GridCol xsmall={12} large={6}>
        <CurrentCrsInformation />
      </GridCol>
      <GridCol xsmall={12} large={6}>
        <CitizenshipInformation />
      </GridCol>
    </GridRow>
  </Page>
);
