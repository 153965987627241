import { Button, Card, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import React, {
  useContext,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { generatePath, Link } from "react-router-dom";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { TranslatedText } from "../../components/TranslatedText";
import { UserContext } from "../../context/UserContext";
import { InvestmentAccount, AccountType } from "../../data/dataAccounts";
import { getNavLink } from "../../hooks/useCountryUrls";
import { usePendingDeposits } from "../../hooks/usePendingDeposits";
import {
  BASE_ROUTES as CLOSE_ACCOUNT_ROUTES,
  CLOSE_ACCOUNT_URL,
} from "../closeAccountStory/CloseAccountStory";
import "./CloseAccount.scss";

interface Props {
  account?: InvestmentAccount;
}

export const CloseAccount: VoidFunctionComponent<Props> = ({ account }) => {
  const [hasOngoingDeposit, setHasOngoingDeposit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { state: user } = useContext(UserContext);
  const loadPendingDeposits = usePendingDeposits();

  useEffect(() => {
    if (
      typeof account === "undefined" ||
      typeof loadPendingDeposits === "undefined"
    ) {
      return;
    }
    loadPendingDeposits()
      .then((pendingDeposits) => {
        setHasOngoingDeposit(
          pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          )
        );
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [account, loadPendingDeposits]);

  if (account?.type === AccountType.DANICA_KF || isError || user.readOnly) {
    return null;
  }

  if (isLoading || typeof account === "undefined") {
    return (
      <RouteAwareToggle path={getNavLink(CLOSE_ACCOUNT_URL)}>
        <section className="account-page-close-account">
          <h2>
            <TranslatedText id="accountPage.delete-account.header" />
          </h2>
          <Card>
            <Spinner />
          </Card>
        </section>
      </RouteAwareToggle>
    );
  }

  const isDeleteAllowed = account.worth === 0 && !hasOngoingDeposit;

  return (
    <RouteAwareToggle path={getNavLink(CLOSE_ACCOUNT_URL)}>
      <section className="account-page-close-account">
        <h2>
          <TranslatedText id="accountPage.delete-account.header" />
        </h2>
        <Card>
          {isDeleteAllowed ? (
            <React.Fragment>
              <p>
                <TranslatedText id="accountPage.delete-account.text" />
              </p>
              <Button
                component={Link}
                variant="negative"
                size="small"
                to={getNavLink(
                  generatePath(
                    CLOSE_ACCOUNT_ROUTES.CLOSE_ACCOUNT_CONFIRMATION,
                    {
                      accountId: account.accountId,
                    }
                  )
                )}
                block
                label={<TranslatedText id="accountPage.delete-account.link" />}
              />
            </React.Fragment>
          ) : (
            <Snackbar type={SNACKBAR_TYPES.INFO}>
              <p className="account-page-close__snackbar-close">
                <TranslatedText id="accountPage.delete-account.snackbar.information" />
              </p>
              <ul className="account-page-close-account__list">
                {account.worth > 0 && (
                  <li className="account-page-close-account__list-entry">
                    <TranslatedText id="accountPage.delete-account.snackbar.money-on-account" />
                  </li>
                )}
                {hasOngoingDeposit && (
                  <li className="account-page-close-account__list-entry">
                    <TranslatedText id="accountPage.delete-account.snackbar.ongoing-deposit-error" />
                  </li>
                )}
              </ul>
            </Snackbar>
          )}
        </Card>
      </section>
    </RouteAwareToggle>
  );
};
