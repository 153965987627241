import { Spinner, Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useContext,
} from "react";
import {
  dataKlarna,
  KlarnaState,
  KlarnaAccount,
  KlarnaTransferStatus,
} from "../../../data/dataKlarna";
import { KlarnaIntegrationClient } from "../../../utils/KlarnaIntegrationClient";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../TranslatedText";
import { EventTracker } from "../../eventTracker/EventTracker";
import { AnalyticsEvent, Tracker } from "../../../utils/Tracker";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { useIntl } from "react-intl";
import { FallbackTransferState } from "../../../pages/deposits/overview/Recommendation";
import { fallbackLocationDescriptorFunction } from "../../../pages/deposits/klarna/KlarnaStory";
import { TrackerEvent } from "../../../data/dataCustomerTracking";

const POLL_TIMER = 3 * 1000; // 3 Seconds

export interface OnCompleteData
  extends Omit<KlarnaTransferStatus, "error" | "state" | "clientToken"> {}

interface Props {
  amount: string;
  accountId: CompoundAccountId;
  klarnaAccount: KlarnaAccount;
  onComplete: (data: OnCompleteData) => void;
  getPaymentInitiationFailedDescriptor?: fallbackLocationDescriptorFunction;
}

export function PaymentInitiation({
  accountId,
  amount,
  klarnaAccount,
  onComplete,
  getPaymentInitiationFailedDescriptor,
}: Props) {
  const [clientToken, setClientToken] = useState<string>();
  const [klarnaState, setKlarnaState] = useState<KlarnaState>(
    KlarnaState.WAITING_TO_START
  );
  const timer = useRef<NodeJS.Timeout>();
  const isPageActive = useRef(true);
  const [klarnaIntegrationClient] = useState(new KlarnaIntegrationClient());
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const currency = useRef(localizationContext.state.currency);
  const formattedAmount = useRef(
    intl.formatNumber(Number(amount), {
      currency: localizationContext.state.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "currency",
    })
  );

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        isPageActive.current = false;
      }
    };
  }, []);

  const poll = useCallback(() => {
    dataKlarna.pollPayment().then((resp) => {
      setKlarnaState(resp.state);
      setClientToken(resp.clientToken);
      if (resp.state === KlarnaState.COMPLETE) {
        EventTracker.track({
          event: TrackerEvent.DEPOSIT_KLARNA,
          message: `Amount: ${formattedAmount.current}, From: ${resp.bankAccount}`,
        });
        Tracker.event({
          event: AnalyticsEvent.DEPOSIT,
          depositType: "KLARNA",
          amount: resp.amount,
          currency: currency.current,
        });
        onComplete(resp);
      } else if (resp.state !== KlarnaState.ERROR && isPageActive.current) {
        timer.current = setTimeout(poll, POLL_TIMER);
      } else if (resp.state === KlarnaState.ERROR) {
        EventTracker.track({ event: TrackerEvent.ERROR });
        Tracker.event({
          event: AnalyticsEvent.DEPOSIT_ERROR,
          depositType: "KLARNA",
          error: resp.error ?? "PIS failed",
        });
        setKlarnaState(KlarnaState.ERROR);
      }
    });
  }, [onComplete]);

  useEffect(() => {
    if (!clientToken) {
      return;
    }
    klarnaIntegrationClient.start(
      clientToken,
      () => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: "Klarna Integration Client - Aborted",
        });
        Tracker.event({
          event: AnalyticsEvent.DEPOSIT_ERROR,
          depositType: "KLARNA",
          error: "Client abort",
        });
        setKlarnaState(KlarnaState.ERROR);
      },
      (error) => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: `Klarna Integration Client - Error reason: ${error.reason}, Error Category: ${error.category}`,
        });
        Tracker.event({
          event: AnalyticsEvent.DEPOSIT_ERROR,
          depositType: "KLARNA",
          error: "Client error",
        });
        setKlarnaState(KlarnaState.ERROR);
      }
    );
  }, [clientToken, klarnaIntegrationClient]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    dataKlarna
      .initiatePayment(klarnaAccount.accountJWT, accountId, Number(amount))
      .then(() => {
        timer.current = setTimeout(poll, POLL_TIMER);
      })
      .catch((error) => {
        EventTracker.track({ event: TrackerEvent.ERROR });
        Tracker.event({
          event: AnalyticsEvent.DEPOSIT_ERROR,
          depositType: "KLARNA",
          error: error ?? "Init PIS error",
        });
        setKlarnaState(KlarnaState.ERROR);
      });
  }, [accountId, amount, klarnaAccount.accountJWT, poll]);

  if (klarnaState === KlarnaState.ERROR) {
    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <h2>
              <TranslatedText id="klarna.payment-initiation.error-header" />
            </h2>
            <p>
              <TranslatedText id="klarna.payment-initiation.error-body" />
            </p>
          </div>
        </Snackbar>
        {getPaymentInitiationFailedDescriptor && (
          <Button
            component={Link<FallbackTransferState>}
            to={getPaymentInitiationFailedDescriptor({
              accountId,
              amount: Number.parseInt(amount),
            })}
            block
            label={
              <TranslatedText id="klarna.payment-initiation.error-button" />
            }
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <Spinner />
    </div>
  );
}
