import React, { VoidFunctionComponent } from "react";

interface Props {
  size?: number;
}

export const IskTransferIcon: VoidFunctionComponent<Props> = ({
  size = 40,
}) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7274_12019)">
        <circle cx="20" cy="20" r="20" fill="#F1F1F1" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.4995 10.1323C10.9772 9.85991 11.588 10.0216 11.8638 10.4935L14.4459 14.9113C14.6612 14.6625 14.9223 14.4451 15.2256 14.2721L17.8204 12.7923C19.2535 11.975 21.0859 12.46 21.9133 13.8756L22.912 15.5844C23.1069 15.9178 23.2286 16.2731 23.2822 16.6322C24.5569 16.3002 25.9485 16.8334 26.6393 18.0154L28.1374 20.5786C28.3125 20.8782 28.4286 21.1954 28.4892 21.5172L29.5017 20.9397C29.9794 20.6673 30.5902 20.829 30.866 21.3008C31.1418 21.7727 30.9782 22.3761 30.5005 22.6485L24.1546 26.2677C24.2593 27.5551 23.6251 28.8481 22.4175 29.5368C20.7455 30.4903 18.6077 29.9245 17.6424 28.2729C16.9452 27.08 17.0504 25.647 17.7927 24.5837L10.134 11.48C9.85818 11.0082 10.0218 10.4048 10.4995 10.1323ZM20.3996 25.0904C20.8409 25.0105 21.2988 25.1317 21.6406 25.4189L21.6435 25.4214C21.7694 25.5278 21.8795 25.6567 21.9671 25.8065C21.9747 25.8196 21.9821 25.8328 21.9893 25.846C22.0398 25.9386 22.0792 26.0344 22.1081 26.1317C22.2386 26.5722 22.152 27.0453 21.886 27.4109C21.7668 27.5748 21.6116 27.7171 21.4238 27.8251L21.4187 27.828C20.7022 28.2367 19.786 27.9942 19.3723 27.2864C19.3199 27.1968 19.2781 27.1041 19.2465 27.0097C19.2118 26.906 19.1894 26.8003 19.1787 26.6943C19.1774 26.6812 19.1762 26.6681 19.1752 26.6549C19.1489 26.2974 19.2553 25.9397 19.4751 25.6527C19.5923 25.4997 19.7418 25.3668 19.9206 25.2648C19.9396 25.254 19.9587 25.2437 19.9779 25.2338C20.1124 25.1645 20.2532 25.1173 20.3957 25.0911L20.3996 25.0904ZM15.8588 17.3286C15.583 16.8567 15.7467 16.2533 16.2243 15.9809L18.8191 14.501C19.2968 14.2286 19.9076 14.3903 20.1834 14.8622L21.1822 16.571C21.458 17.0428 21.2943 17.6462 20.8166 17.9186L18.2218 19.3985C17.7441 19.6709 17.1333 19.5093 16.8575 19.0374L15.8588 17.3286ZM19.372 23.3397L18.8549 22.455C18.5791 21.9831 18.7428 21.3797 19.2205 21.1073L23.5451 18.6409C24.0228 18.3685 24.6336 18.5301 24.9094 19.002L26.4075 21.5652C26.6829 22.0364 26.5201 22.6386 26.0441 22.9116L23.4165 24.4102C22.4614 23.2087 20.8073 22.7709 19.372 23.3397Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7274_12019">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
