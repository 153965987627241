import React from "react";
import { FormattedNumber } from "react-intl";
import "./Change.scss";

interface Props {
  showPrefix?: boolean;
  change: number;
  digits?: number;
}

/**
 * @deprecated Do not use this anymore. It doesn't use a correct way of formatting the percentage.
 * instead have a look at
 */
export function Change(props: Props) {
  const { showPrefix = false, change, digits = 2 } = props;
  let prefix = null;
  let value = change;

  if (showPrefix) {
    prefix = <span className="change-prefix">{change < 0 ? "-" : "+"}</span>;
    value = Math.abs(change);
  }

  return (
    <span className="change">
      {prefix}
      <span className="change-value">
        <FormattedNumber
          value={value}
          minimumFractionDigits={digits}
          maximumFractionDigits={digits}
        />
      </span>
      <span className="change-entity">%</span>
    </span>
  );
}
