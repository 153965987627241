import { Spinner } from "@lysaab/ui-2";
import { FunctionComponent, Suspense } from "react";
import { ChunkLoadErrorBoundary } from "./ChunkLoadErrorBoundary";

export const SafeLazyImport: FunctionComponent = ({ children }) => {
  return (
    <ChunkLoadErrorBoundary>
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </ChunkLoadErrorBoundary>
  );
};
