import { LysaCountry } from "@lysaab/countries";
import {
  LOGIN_PAGE_URL_VARIABLE,
  LOGIN_SWEDEN_PAGE_URL,
} from "../../countries/sweden/pages/LoginPage";
import { LOGIN_NETS_PAGE_URL } from "../../pages/login/LoginNetsPage";
import { LOGIN_USERNAME_PASSWORD_PAGE_URL } from "../../pages/login/LoginUsernamePasswordPage";

export const loginPages: Record<LysaCountry, string> = {
  [LysaCountry.DENMARK]: LOGIN_NETS_PAGE_URL,
  [LysaCountry.FINLAND]: LOGIN_NETS_PAGE_URL,
  [LysaCountry.GERMANY]: LOGIN_USERNAME_PASSWORD_PAGE_URL,
  [LysaCountry.SPAIN]: LOGIN_USERNAME_PASSWORD_PAGE_URL,
  [LysaCountry.SWEDEN]: LOGIN_SWEDEN_PAGE_URL.replace(
    LOGIN_PAGE_URL_VARIABLE,
    ""
  ),
};
