import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router";
import { LysaCountry } from "@lysaab/countries";

export function useWrongCountryRedirect(country: LysaCountry) {
  const userContext = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (userContext.state.country && userContext.state.country !== country) {
      history.push(`/${userContext.state.country.toLocaleLowerCase()}`);
    }
  }, [country, history, userContext]);
}
