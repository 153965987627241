import {
  Button,
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, { useEffect, useState, VoidFunctionComponent } from "react";
import { Amount } from "../../../../../../components/amount/Amount";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import {
  dataTax,
  SavingsAccountTaxDetail,
} from "../../../../../../data/dataTax";
import { useAccounts } from "../../../../../../hooks/useAccounts";
import cx from "classnames";

import "./SavingsAccountTaxDetails.scss";
import {
  dataLegalEntity,
  LegalEntityResponse,
} from "../../../../../../data/dataLegalEntity";
import NumberFormat from "react-number-format";

export const SavingsAccountTaxDetails: VoidFunctionComponent<{
  year?: number;
}> = ({ year }) => {
  const [legalEntity, setLegalEntity] = useState<LegalEntityResponse>();
  const [taxDetails, setTaxDetails] = useState<SavingsAccountTaxDetail[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { accounts } = useAccounts();

  useEffect(() => {
    if (!year) {
      return;
    }
    setIsLoading(true);
    Promise.all([
      dataLegalEntity.getLegalEntity(),
      dataTax.getSavingsAccountTax(year),
    ])
      .then(([legalEntity, taxDetails]) => {
        setLegalEntity(legalEntity);
        setTaxDetails(taxDetails);
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
      });
  }, [year]);

  if (
    isLoading ||
    typeof year === "undefined" ||
    typeof legalEntity === "undefined"
  ) {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="sweden.tax.savingsAccountTaxDetails.header" />
        </Typography>
        <Card>
          <Spinner />
        </Card>
      </>
    );
  }

  return (
    <>
      <Typography type="h2">
        <TranslatedText id="sweden.tax.savingsAccountTaxDetails.header" />
      </Typography>
      <Card className="savings-account-tax-details">
        {isError && (
          <Snackbar icon type={SNACKBAR_TYPES.ERROR}>
            <TranslatedText id="sweden.tax.savingsAccountTaxDetails.error" />
          </Snackbar>
        )}
        <div className="savings-account-tax-details__section">
          <div className="savings-account-tax-details__section--right">
            <Typography type="body" component="div">
              {legalEntity.name}
            </Typography>
            <Typography type="body" component="span">
              {legalEntity.tin.length === 12 ? (
                <NumberFormat
                  displayType="text"
                  format={"########-####"}
                  value={legalEntity.tin}
                />
              ) : (
                legalEntity.tin
              )}
            </Typography>
          </div>
          <Typography type="h3">
            <TranslatedText
              id="sweden.tax.savingsAccountTaxDetails.label"
              values={{ year: () => year }}
            />
          </Typography>
          <Typography type="body">
            <TranslatedText
              id="sweden.tax.savingsAccountTaxDetails.description"
              values={{ year: () => year }}
            />
          </Typography>
        </div>
        <div className="savings-account-tax-details__section">
          <div className="savings-account-tax-details__account-row savings-account-tax-details__account-row-divider">
            <Typography
              type="body"
              component="span"
              className="savings-account-tax-details__account-row--bold"
            >
              <TranslatedText id="sweden.tax.savingsAccountTaxDetails.list.account" />
            </Typography>
            <Typography
              type="body"
              component="span"
              className="savings-account-tax-details__account-row--bold"
            >
              <TranslatedText id="sweden.tax.savingsAccountTaxDetails.list.accruedInterest" />
            </Typography>
          </div>
          {taxDetails.map((detail, index) => {
            const savingsAccount = accounts?.savingsAccounts.find(
              (acc) => acc.accountId === detail.accountId
            );
            return (
              <div
                className={cx("savings-account-tax-details__account-row", {
                  "savings-account-tax-details__account-row-divider":
                    index === taxDetails.length - 1,
                })}
                key={detail.accountId}
              >
                <Typography type="body" component="span">
                  {savingsAccount?.name || (
                    <TranslatedText id="sweden.tax.savingsAccountTaxDetails.list.closedAccount" />
                  )}
                </Typography>
                <Typography type="body" component="span">
                  <Amount amount={detail.interestIncome} decimals={2} />
                </Typography>
              </div>
            );
          })}
          <div className="savings-account-tax-details__account-row">
            <Typography
              type="body"
              component="span"
              className="savings-account-tax-details__account-row--bold"
            >
              <TranslatedText id="sweden.tax.savingsAccountTaxDetails.list.total" />
            </Typography>
            <Typography type="body" component="span">
              <Amount
                amount={Math.floor(
                  taxDetails.reduce(
                    (acc, element) => acc + element.interestIncome,
                    0
                  )
                )}
                decimals={2}
              />
            </Typography>
          </div>
        </div>
        <div className="savings-account-tax-details__print-button">
          <Button
            size="small"
            onClick={window.print}
            icon="Print"
            label={
              <TranslatedText id="sweden.tax.savingsAccountTaxDetails.print" />
            }
          />
        </div>
      </Card>
    </>
  );
};
