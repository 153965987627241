import { GetMessageFunction } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, IntlShape } from "react-intl";
import { Currency } from "../../context/LocalizationContext";

const messages = defineMessages({
  graphTooShort: {
    id: "generic.allocation.graph.tooShort",
    description: "Edit allocation page - graph too short interval",
    defaultMessage:
      "Profits can not be simulated for intervals shorter than a year",
  },
  graphProbability: {
    id: "generic.allocation.graph.probability",
    description: "Probability text",
    defaultMessage:
      "Estimated probability of you in {years, number} years and {months, number} months",
  },
  graphVeryGood: {
    id: "generic.allocation.graph.veryGood",
    description: "Very good development text",
    defaultMessage:
      "<bold>More</bold> than <bold>{sum}</bold> is <bold>{percent, number, ::percent}</bold>",
  },
  graphGood: {
    id: "generic.allocation.graph.good",
    description: "Period is to short",
    defaultMessage:
      "<bold>More</bold> than <bold>{sum}</bold> is <bold>{percent, number, ::percent}</bold>",
  },
  graphBad: {
    id: "generic.allocation.graph.bad",
    description: "Period is to short",
    defaultMessage:
      "<bold>Less</bold> than <bold>{sum}</bold> is <bold>{percent, number, ::percent}</bold>",
  },
  graphVeryBad: {
    id: "generic.allocation.graph.veryBad",
    description: "Period is to short",
    defaultMessage:
      "<bold>Less</bold> than <bold>{sum}</bold> is <bold>{percent, number, ::percent}</bold>",
  },
});

export function GetForecastGraphTexts(
  intl: IntlShape,
  currency: Currency
): GetMessageFunction {
  return (args) => {
    return {
      tooShort: intl.formatMessage(messages.graphTooShort),
      probability: intl.formatMessage(messages.graphProbability, {
        years: args.years,
        months: args.months,
        bold: (...str) => {
          return <b>{str}</b>;
        },
      }),
      veryGoodText: intl.formatMessage(messages.graphVeryGood, {
        sum: intl.formatNumber(args.veryGoodSum, {
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.veryGoodPercentage / 100,
        bold: (...str) => {
          return <b>{str}</b>;
        },
      }),
      goodText: intl.formatMessage(messages.graphGood, {
        sum: intl.formatNumber(args.goodSum, {
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.goodPercentage / 100,
        bold: (...str) => {
          return <b>{str}</b>;
        },
      }),
      badText: intl.formatMessage(messages.graphBad, {
        sum: intl.formatNumber(args.badSum, {
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.badPercentage / 100,
        bold: (...str) => {
          return <b>{str}</b>;
        },
      }),
      veryBadText: intl.formatMessage(messages.graphVeryBad, {
        sum: intl.formatNumber(args.veryBadSum, {
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.veryBadPercentage / 100,
        bold: (...str) => {
          return <b>{str}</b>;
        },
      }),
    };
  };
}
