import React from "react";
import { Button, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { UserState } from "../../../context/UserContext";
import { LegalEntityType } from "../../../data/dataLogin";
import { ExternalAccount } from "../../../data/dataWithdrawals";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { ADD_EXTERNAL_KLARNA_URL } from "../addAccountKlarna/AddAccountKlarnaStory";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { COMPANY_SETTINGS_PAGE_URL } from "../../../countries/sweden/pages/corporation/companySettings/CompanySettingsPage";

interface Props {
  user: UserState;
  externalAccounts: ExternalAccount[];
}

export const MissingExternalAccount: React.FunctionComponent<Props> = ({
  user,
  externalAccounts,
}) => {
  return (
    <div className="withdrawal-page-missing-external-account">
      {!externalAccounts.length && (
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <p>
              <TranslatedText id="withdrawals.missing" />
            </p>
          </div>
        </Snackbar>
      )}
      <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_KLARNA_URL)}>
        <AddExternalAccountButton user={user} />
      </RouteAwareToggle>
    </div>
  );
};

function AddExternalAccountButton({ user }: { user: UserState }) {
  const text = <TranslatedText id="withdrawals.add" />;
  if (user.legalEntityType === LegalEntityType.PERSON) {
    return (
      <div className="withdrawal-page-missing-external-account">
        <Button
          variant="secondary"
          component={Link}
          to={getNavLink(ADD_EXTERNAL_KLARNA_URL)}
          label={text}
          block
        />
      </div>
    );
  } else {
    return (
      <Button
        component={Link}
        variant="secondary"
        to={getNavLink(COMPANY_SETTINGS_PAGE_URL)}
        label={text}
      />
    );
  }
}
