import React, { useRef, useContext, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import {
  Card,
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  RequiredValidator,
  OtpInput,
  EmailValidator,
  EmailInput,
  OtpValidator,
  useFlash,
  Spinner,
} from "@lysaab/ui-2";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { useInterval } from "../../../../hooks/useInterval";
import { MailStatus, mailStatus } from "../../../../data/dataMail";
import {
  dataLegalEntity,
  EmailVerificationCodeResult,
  EntityDetailsResponse,
} from "../../../../data/dataLegalEntity";
import "./VerifyEmail.scss";
import { useYearlyReviewContext } from "../../contexts/YearlyReviewContext";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../../data/dataCustomerTracking";

const messages = defineMessages({
  label_email: { id: "yearly.verifyEmail.email.current.label" },
  email_required_msg: { id: "yearly.verifyEmail.email.req" },
  email_valid_msg: { id: "yearly.verifyEmail.email.valid" },
  email_suggestion: { id: "yearly.verifyEmail.email.suggestion" },
  label_otp: { id: "yearly.verifyEmail.otp.label" },
  otp_required_msg: { id: "yearly.verifyEmail.otp.req" },
  otp_valid_msg: { id: "yearly.verifyEmail.otp.valid" },
  error_not_valid: { id: "yearly.verifyEmail.otp.response.not_valid" },
  error_expired: { id: "yearly.verifyEmail.otp.response.expired" },
  error_invalid_state: { id: "yearly.verifyEmail.error.invalid_state" },
  error_finalize: { id: "yearly.verifyEmail.error.finalize" },
  error_send: { id: "yearly.verifyEmail.error.send" },
});

interface Props {
  next: () => void;
}

export const VerifyEmail: React.VFC<Props> = ({ next }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [yearlyReviewState, setYearlyReviewState] = useYearlyReviewContext();
  const formRef = useRef<LysaFormRef>();
  const formRef2 = useRef<LysaFormRef>();
  const isExpired = useRef<boolean>(true);
  const emailRef = useRef<HTMLInputElement>(null);
  const [mailStatusId, setMailStatusId] = useState("");
  const [confirmationId, setConfirmationId] = useState("");
  const [totp, setTotp] = useState("");
  const [error, setError] = useState("");
  const [sendError, setSendError] = useState("");
  const pushFlash = useFlash();
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<EntityDetailsResponse>();

  useInterval(
    () => {
      mailStatus(mailStatusId).then(({ state }) => {
        if (state === MailStatus.ERROR) {
          setSendError(intl.formatMessage(messages.error_send));
        }
      });
    },
    mailStatusId ? 1000 : null
  );

  useEffect(() => {
    if (settings) {
      return;
    }

    dataLegalEntity
      .getProfileSettings()
      .then((settings) => {
        setSettings(settings);
        setYearlyReviewState({ email: settings.contactDetails.email });
      })
      .finally(() => setIsLoading(false));
  }, [settings, setYearlyReviewState]);

  const finalizeChange = (signedEmail: string) => {
    if (!signedEmail) {
      pushFlash({
        text: intl.formatMessage(messages.error_invalid_state),
        type: SNACKBAR_TYPES.ERROR,
        timer: 3000,
      });

      return;
    }

    dataLegalEntity
      .finalizeEmailChange({ signedEmail })
      .then(() => {
        // We expire the cookie in one year + one month. We ask the customers to
        // login at least once a year to fill out the yearly-review, so this should
        // be long enough for them to login again and refresh the cookie
        const maxAge = 60 * 60 * 24 * 395;

        // signup.lysa.se -> lysa.se
        // signup.lysa-test.se -> lysa-test.se
        // localhost -> localhost
        const domain = window.location.hostname.split(".").slice(-2).join(".");
        document.cookie = `lysa_nets_email=${yearlyReviewState.email}; domain=${domain}; path=/; max-age=${maxAge}`;

        EventTracker.track({
          event: TrackerEvent.YEARLY_REVIEW_VERIFY_EMAIL,
          message: {
            email: signedEmail,
          },
        });

        next();
      })
      .catch((error) => {
        pushFlash({
          text: intl.formatMessage(messages.error_finalize),
          type: SNACKBAR_TYPES.ERROR,
          timer: 3000,
        });

        EventTracker.track({
          event: TrackerEvent.YEARLY_REVIEW_VERIFY_EMAIL,
          message: {
            email: signedEmail,
            error,
          },
        });

        console.error("error", error);
      })
      .finally(() => {
        setTotp("");
      });
  };

  useEffect(() => {
    setTimeout(() => emailRef.current?.focus());
  }, []);

  if (isLoading) {
    return (
      <div className="yearly-review-verify-email-page">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="yearly-review-verify-email-page">
      <div className="content-wrapper">
        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();
            setMailStatusId("");
            setSendError("");

            if (
              yearlyReviewState.email === settings?.contactDetails.email &&
              settings?.contactDetails.emailVerified
            ) {
              // No change to the email, so skip verification
              EventTracker.track({
                event: TrackerEvent.YEARLY_REVIEW_EMAIL,
                message: {
                  email: yearlyReviewState.email,
                  isNew: false,
                  isVerified: true,
                },
              });
              next();
              return;
            }

            if (
              isExpired.current &&
              formRef.current?.isValid &&
              localizationContext.state.country
            ) {
              dataLegalEntity
                .initEmailChange({
                  language: localizationContext.state.language,
                  country: localizationContext.state.country,
                  email: yearlyReviewState.email,
                })
                .then(({ confirmationId, statusId }) => {
                  setMailStatusId(statusId);
                  setConfirmationId(confirmationId);
                  isExpired.current = false;
                  setTotp("");
                  EventTracker.track({
                    event: TrackerEvent.YEARLY_REVIEW_EMAIL,
                    message: {
                      email: yearlyReviewState.email,
                      isNew: true,
                      isVerified: false,
                    },
                  });
                })
                .catch(() => {
                  setSendError(intl.formatMessage(messages.error_send));
                });
            }
          }}
        >
          <Card>
            <h2>
              <FormattedMessage id="yearly.email.header" />
            </h2>
            <p>
              <FormattedMessage id="yearly.email.intro" />
            </p>
            <EmailInput
              label={intl.formatMessage(messages.label_email)}
              value={yearlyReviewState.email}
              ref={emailRef}
              onChange={(email) => {
                if (!isExpired.current) {
                  isExpired.current = true;
                }
                setYearlyReviewState({ email });
              }}
              suggestionMessage={intl.formatMessage(messages.email_suggestion, {
                // This replacement is made inside <EmailInput>, so pass it along
                suggestion: "{suggestion}",
              })}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.email_required_msg)
                ),
                new EmailValidator(
                  intl.formatMessage(messages.email_valid_msg)
                ),
              ]}
            />

            {sendError && <p className="send-error">{sendError}</p>}

            <Button
              block
              type="submit"
              label={<FormattedMessage id="yearly.email.button.next" />}
            />
          </Card>
        </Form>

        <AnimateHeight
          key={confirmationId}
          duration={300}
          height={confirmationId ? "auto" : 0}
          animateOpacity
        >
          <Form
            lysaFormRef={formRef2}
            onSubmit={(event) => {
              event.preventDefault();

              if (formRef2.current?.isValid) {
                dataLegalEntity
                  .confirmEmailCode({
                    confirmationId,
                    code: totp,
                  })
                  .then((response) => {
                    if (response.result === EmailVerificationCodeResult.OK) {
                      finalizeChange(response.signedEmail);
                    } else if (
                      response.result === EmailVerificationCodeResult.EXPIRED
                    ) {
                      setError(intl.formatMessage(messages.error_expired));
                      setTotp("");
                      isExpired.current = true;
                    } else {
                      setError(intl.formatMessage(messages.error_not_valid));
                    }
                  });
              }
            }}
          >
            <Card>
              <h2>
                <FormattedMessage id="yearly.verifyEmail.code.header" />
              </h2>
              <p>
                <FormattedMessage id="yearly.verifyEmail.code.description" />
              </p>
              <OtpInput
                label={intl.formatMessage(messages.label_otp)}
                value={totp}
                onChange={(totp) => {
                  setTotp(totp);
                  setError("");
                }}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.otp_required_msg)
                  ),
                  new OtpValidator(intl.formatMessage(messages.otp_valid_msg)),
                ]}
              />
            </Card>

            {error && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                {error}
              </Snackbar>
            )}

            <Button
              block
              type="submit"
              label={<FormattedMessage id="yearly.verifyEmail.button.save" />}
            />
          </Form>
        </AnimateHeight>
      </div>
    </div>
  );
};
